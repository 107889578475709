export const keelmansWay = {
    'type': 'Feature',
    'properties': {
        'color': '#6A0DAD',
        'description': 'Paved Cycleway 😏</br> Keelmans Way'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.6105300, 54.9649700],
          [-1.6106900, 54.9650300],
          [-1.6109000, 54.9650200],
          [-1.6109100, 54.9650200],
          [-1.6116600, 54.9645867],
          [-1.6124100, 54.9641533],
          [-1.6131600, 54.9637200],
          [-1.6138300, 54.9633100],
          [-1.6143350, 54.9628750],
          [-1.6148400, 54.9624400],
          [-1.6150100, 54.9623400],
          [-1.6157900, 54.9619800],
          [-1.6168325, 54.9616000],
          [-1.6178750, 54.9612200],
          [-1.6189175, 54.9608400],
          [-1.6199600, 54.9604600],
          [-1.6210800, 54.9601433],
          [-1.6222000, 54.9598267],
          [-1.6233200, 54.9595100],
          [-1.6246034, 54.9591100],
          [-1.6258867, 54.9587100],
          [-1.6271700, 54.9583100],
          [-1.6272500, 54.9583700],
          [-1.6274500, 54.9584000],
          [-1.6285700, 54.9581000],
          [-1.6286000, 54.9579600],
          [-1.6289800, 54.9578500],
          [-1.6291800, 54.9578800],
          [-1.6301400, 54.9575900],
          [-1.6303600, 54.9572900],
          [-1.6311100, 54.9570100],
          [-1.6319700, 54.9568000],
          [-1.6326300, 54.9566800],
          [-1.6331400, 54.9566200],
          [-1.6335800, 54.9566100],
          [-1.6341700, 54.9566500],
          [-1.6348400, 54.9567500],
          [-1.6355200, 54.9569000],
          [-1.6364100, 54.9571400],
          [-1.6373100, 54.9571600],
          [-1.6381600, 54.9572200],
          [-1.6382700, 54.9572100],
          [-1.6383600, 54.9571600],
          [-1.6384200, 54.9570500]
        ]
    }
};