export const northBeamish = {
    'type': 'Feature',
    'properties': {
        'color': '#0D1F22',
        'description': 'Bridleway 😎</br> North Beamish'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.6436600, 54.8950400],
          [-1.6444000, 54.8948700],
          [-1.6455100, 54.8946425],
          [-1.6466200, 54.8944150],
          [-1.6477300, 54.8941875],
          [-1.6488400, 54.8939600],
          [-1.6495950, 54.8938250],
          [-1.6503500, 54.8936900],
          [-1.6512500, 54.8935800],
          [-1.6523700, 54.8932500],
          [-1.6529500, 54.8931600],
          [-1.6537300, 54.8930100],
          [-1.6542200, 54.8926500],
          [-1.6550700, 54.8924050],
          [-1.6559200, 54.8921600],
          [-1.6562000, 54.8918400],
          [-1.6562900, 54.8910700],
          [-1.6565700, 54.8908600],
          [-1.6565600, 54.8908700],
          [-1.6570400, 54.8907200],
          [-1.6576800, 54.8906600],
          [-1.6587100, 54.8907500],
          [-1.6596500, 54.8907600],
          [-1.6607300, 54.8906900],
          [-1.6612800, 54.8905800],
          [-1.6615100, 54.8904600],
          [-1.6619500, 54.8901600],
          [-1.6625600, 54.8896900],
          [-1.6627700, 54.8896000],
          [-1.6635500, 54.8893900],
          [-1.6646600, 54.8890633],
          [-1.6657700, 54.8887367],
          [-1.6668800, 54.8884100],
          [-1.6678500, 54.8882500],
          [-1.6678200, 54.8882100],
        ]
    }
};