import { southRisingSun } from './southRisingSun';
import { dirtyReiver22 } from './dirtyReiver22';
import { risingSunDukesPond } from './risingSunDukesPond';
import { wallsendHeaton } from './wallsendHeaton';
import { pontelandSouthEast } from './pontlandSouthEast';
import { dinningtonEast } from './dinningtonEast.js';
import { keelmansWayWest } from './keelmansWayWest';
import { glidingClub } from './glidingClub';
import { bradleyFell } from './bradleyFell';
import { blaydonBurnWest } from './blaydonBurnWest';
import { blaydonBurnPath } from './blaydonBurnPath';
import { blythSouth } from './blythSouth';
import { dinningtonSouth } from './dinningtonSouth';
import { nufcTrainingGrounds } from './nufcTrainingGround';
import { risingSunWest } from './risingSunWest';
import { wellLane } from './wellLane';

const dustyGravel = [
  southRisingSun,
  dirtyReiver22,
  risingSunDukesPond,
  wallsendHeaton,
  pontelandSouthEast,
  dinningtonSouth,
  dinningtonEast,
  keelmansWayWest,
  glidingClub,
  bradleyFell,
  blaydonBurnWest,
  blaydonBurnPath,
  blythSouth,
  nufcTrainingGrounds,
  risingSunWest,
  wellLane,
];

export default dustyGravel;
