export const quayside = {
    'type': 'Feature',
    'properties': {
        'color': '#6A0DAD',
        'description': 'Paved Cycleway 😏</br> Quayside'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.5887500, 54.9706500],
          [-1.5899200, 54.9709200],
          [-1.5907000, 54.9710400],
          [-1.5914800, 54.9711600],
          [-1.5922900, 54.9712000],
          [-1.5931000, 54.9712400],
          [-1.5945400, 54.9712200],
          [-1.5945300, 54.9712700],
          [-1.5946000, 54.9712800],
          [-1.5957400, 54.9711800],
          [-1.5968800, 54.9710800],
          [-1.5977000, 54.9709900],
          [-1.5981400, 54.9709200],
          [-1.5991700, 54.9706900],
          [-1.6002000, 54.9704000],
          [-1.6003400, 54.9702400],
          [-1.6004000, 54.9702000],
          [-1.6013750, 54.9699300],
          [-1.6023500, 54.9696600],
          [-1.6033300, 54.9694000],
          [-1.6033300, 54.9693300],
          [-1.6042733, 54.9690600],
          [-1.6052167, 54.9687900],
          [-1.6061600, 54.9685200],
          [-1.6069450, 54.9683550],
          [-1.6077300, 54.9681900],
          [-1.6081000, 54.9680600],
          [-1.6082100, 54.9680600],
          [-1.6084800, 54.9679600],
          [-1.6084800, 54.9678900],
          [-1.6094967, 54.9674600],
          [-1.6105134, 54.9670300],
          [-1.6115300, 54.9666000],
          [-1.6116400, 54.9664500],
          [-1.6123600, 54.9661400],
          [-1.6130800, 54.9658300],
          [-1.6142150, 54.9653800],
          [-1.6153500, 54.9649300],
          [-1.6154500, 54.9649100],
          [-1.6166050, 54.9643400],
          [-1.6177600, 54.9637700],
          [-1.6186400, 54.9633467],
          [-1.6195200, 54.9629233],
          [-1.6204000, 54.9625000],
          [-1.6204700, 54.9625300],
          [-1.6205700, 54.9624800],
          [-1.6205000, 54.9624400],
          [-1.6214300, 54.9620200],
          [-1.6224600, 54.9616100],
          [-1.6236500, 54.9612100],
          [-1.6249400, 54.9608700],
          [-1.6253900, 54.9607900],
          [-1.6260100, 54.9607100],
          [-1.6269700, 54.9605500],
          [-1.6277800, 54.9604550],
          [-1.6285900, 54.9603600],
          [-1.6285800, 54.9603700],
          [-1.6300600, 54.9603434],
          [-1.6315400, 54.9603167],
          [-1.6330200, 54.9602900],
          [-1.6339200, 54.9602900],
          [-1.6349500, 54.9602500],
          [-1.6350200, 54.9602200],
          [-1.6363600, 54.9602400],
          [-1.6363600, 54.9603100],
          [-1.6377900, 54.9603750],
          [-1.6392200, 54.9604400],
          [-1.6394500, 54.9605100],
          [-1.6407600, 54.9606100],
          [-1.6418900, 54.9607633],
          [-1.6430200, 54.9609167],
          [-1.6441500, 54.9610700],
          [-1.6456500, 54.9612950],
          [-1.6471500, 54.9615200],
          [-1.6483000, 54.9617800],
          [-1.6485100, 54.9618200],
          [-1.6486100, 54.9617200],
          [-1.6496550, 54.9619600],
          [-1.6507000, 54.9622000],
          [-1.6507300, 54.9624000],
          [-1.6507800, 54.9624200],
          [-1.6516700, 54.9627050],
          [-1.6525600, 54.9629900],
          [-1.6528700, 54.9630200],
          [-1.6532100, 54.9630900],
          [-1.6534800, 54.9631800],
          [-1.6545200, 54.9633900],
          [-1.6555600, 54.9636000],
          [-1.6561600, 54.9637600],
          [-1.6563700, 54.9638800],
          [-1.6565000, 54.9639800],
          [-1.6566000, 54.9639900],
          [-1.6566400, 54.9640600],
          [-1.6567000, 54.9641300],
          [-1.6566700, 54.9641900],
          [-1.6567000, 54.9643500],
          [-1.6568000, 54.9644700]
        ]
    }
};