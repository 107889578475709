import React, { FC } from 'react';
import { Routes, Route, NavLink, BrowserRouter } from 'react-router-dom';
import Map from '../../containers/Map';
import logo from '../../assets/negravellogo.png';
import NotFound from '../404';
import styled from 'styled-components';
import Explored from '../../containers/Explored';
import { GravelRoutes } from '../../containers/Routes';

const Navbar = styled.section`
  background: #083555;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;

  .logo {
    height: 7vh;
    display: block;
    padding: 2px 0;
  }

  .active {
    color: #bc8034;
  }

  a {
    color: white;
    text-decoration: none;

    :hover {
      color: #bc8034;
    }
  }
`;

const NavItem = styled.span`
  margin: 0 2rem;
`;

export const Nav: FC = () => {
  return (
    <div>
      <BrowserRouter>
        <div>
          <Navbar className='navbar'>
            <div>
              <span className='navbar-brand'>
                <NavLink to='/'>
                  <img className='logo' src={logo} alt='logo' />
                </NavLink>
              </span>
            </div>
            <div>
              <NavItem>
                <NavLink to='/'>Sections</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to='/gravelroutes'>Routes</NavLink>
              </NavItem>
            </div>
          </Navbar>
          <Routes>
            <Route path='/' Component={Map} />
            <Route path='/explored' Component={Explored} />
            <Route path='/gravelroutes' Component={GravelRoutes} />
            <Route Component={NotFound} />
          </Routes>
        </div>
      </BrowserRouter>
      {/* <Footer /> */}
    </div>
  );
};
