export const beamishSouthbound =     {
    'type': 'Feature',
    'properties': {
        'color': '#003B36',
        'description': 'Rooty and Muddy 🤯</br> Beamish Southbound'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.6503500, 54.8936800],
          [-1.6503100, 54.8935300],
          [-1.6503100, 54.8933300],
          [-1.6502900, 54.8931600],
          [-1.6502500, 54.8928200],
          [-1.6503500, 54.8924800],
          [-1.6503600, 54.8921900],
          [-1.6506800, 54.8917900],
          [-1.6509000, 54.8916100],
          [-1.6509200, 54.8914000],
          [-1.6506700, 54.8910700],
          [-1.6505100, 54.8906700],
          [-1.6505000, 54.8903600],
          [-1.6504800, 54.8900900],
          [-1.6504500, 54.8900000],
          [-1.6503100, 54.8898000],
          [-1.6501100, 54.8896800],
          [-1.6498700, 54.8896200],
          [-1.6494700, 54.8895300],
          [-1.6492500, 54.8894900],
          [-1.6491300, 54.8894300],
          [-1.6489300, 54.8893200],
          [-1.6489300, 54.8892700],
          [-1.6489000, 54.8891300],
          [-1.6488900, 54.8889500],
          [-1.6488800, 54.8888700],
          [-1.6488000, 54.8885800],
          [-1.6482700, 54.8881400],
          [-1.6481900, 54.8879100],
          [-1.6483400, 54.8875900],
          [-1.6479800, 54.8868100],
          [-1.6478300, 54.8866600],
          [-1.6477700, 54.8865300],
          [-1.6477900, 54.8863400],
          [-1.6477900, 54.8861800],
          [-1.6477400, 54.8856900],
          [-1.6477500, 54.8855600],
          [-1.6478800, 54.8854800],
          [-1.6480000, 54.8853300],
          [-1.6478800, 54.8848500],
          [-1.6477100, 54.8844600],
          [-1.6476900, 54.8839100],
          [-1.6471700, 54.8831900],
          [-1.6469800, 54.8831400],
        ]
    }
};