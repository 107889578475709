export const weetslade = {
  type: 'Feature',
  properties: {
    color: '#CB3709',
    description: 'Waggon Way 👌 </br> Weetslade Waggon Way',
  },
  geometry: {
    type: 'LineString',
    coordinates: [
      [-1.62634, 55.05587],
      [-1.62623, 55.05586],
      [-1.62616, 55.05576],
      [-1.62582, 55.05549],
      [-1.62554, 55.05533],
      [-1.62529, 55.05525],
      [-1.62488, 55.05497],
      [-1.62437, 55.054365],
      [-1.62386, 55.05376],
      [-1.62339, 55.05325],
      [-1.622775, 55.05288],
      [-1.62216, 55.05251],
      [-1.621415, 55.05204],
      [-1.62067, 55.05157],
      [-1.61986, 55.05101],
      [-1.61905, 55.05045],
      [-1.61826, 55.04997],
      [-1.61747, 55.04949],
      [-1.61668, 55.04901],
      [-1.61627, 55.04855],
      [-1.61596, 55.04807],
      [-1.6158, 55.04776],
      [-1.61539, 55.047095],
      [-1.61498, 55.04643],
      [-1.61464, 55.04621],
      [-1.6144, 55.04608],
      [-1.61363, 55.04582],
      [-1.61329, 55.04576],
      [-1.61247, 55.0457],
      [-1.611285, 55.04568],
      [-1.6101, 55.04566],
      [-1.609305, 55.04568],
      [-1.60851, 55.0457],
      [-1.60808, 55.04572],
      [-1.60664, 55.04575],
      [-1.60549, 55.04571],
      [-1.6043133, 55.04576],
      [-1.6031367, 55.04581],
      [-1.60196, 55.04586],
      [-1.60138, 55.04596],
      [-1.60084, 55.0461],
      [-1.60058, 55.04613],
      [-1.6002, 55.04594],
      [-1.59997, 55.04576],
      [-1.5997, 55.04566],
      [-1.5983533, 55.04543],
      [-1.5970067, 55.0452],
      [-1.59566, 55.04497],
      [-1.59516, 55.04462],
      [-1.59511, 55.04455],
      [-1.59494, 55.04452],
      [-1.594, 55.04456],
      [-1.59339, 55.04455],
      [-1.59284, 55.04443],
      [-1.59227, 55.04439],
      [-1.59182, 55.04441],
      [-1.59122, 55.04443],
      [-1.5898066, 55.044349],
      [-1.5883933, 55.0442679],
      [-1.5869799, 55.0441868],
      [-1.5855666, 55.0441057],
      [-1.5841533, 55.0440246],
      [-1.5827399, 55.0439435],
      [-1.5813266, 55.0438623],
    ],
  },
};
