export const blaydonBurnPath = {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Dusty Gravel 😎</br> Blaydon Burn Path'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.7417100, 54.9546800],
          [-1.7411200, 54.9547200],
          [-1.7409800, 54.9547800],
          [-1.7403300, 54.9549200],
          [-1.7395150, 54.9552200],
          [-1.7387000, 54.9555200],
          [-1.7387200, 54.9557400],
          [-1.7387100, 54.9557400],
          [-1.7384200, 54.9558600],
          [-1.7375800, 54.9561000],
          [-1.7368200, 54.9563900],
          [-1.7360600, 54.9566800],
          [-1.7353400, 54.9571300],
          [-1.7349300, 54.9575500],
          [-1.7349600, 54.9576500],
          [-1.7351400, 54.9578100],
          [-1.7351300, 54.9579000],
          [-1.7344900, 54.9584700],
          [-1.7343500, 54.9587700],
          [-1.7341300, 54.9591100],
          [-1.7341300, 54.9591000],
          [-1.7338800, 54.9594800],
          [-1.7337300, 54.9599750],
          [-1.7335800, 54.9604700],
          [-1.7333700, 54.9608400],
          [-1.7333000, 54.9610300],
          [-1.7330100, 54.9614400],
          [-1.7327600, 54.9617300],
          [-1.7324600, 54.9619400],
          [-1.7321000, 54.9621200],
          [-1.7319200, 54.9621600],
          [-1.7314600, 54.9623300],
          [-1.7309500, 54.9625700],
          [-1.7304500, 54.9629000],
          [-1.7302400, 54.9630000],
          [-1.7290800, 54.9633800],
          [-1.7287600, 54.9634600],
          [-1.7276867, 54.9636133],
          [-1.7266133, 54.9637667],
          [-1.7255400, 54.9639200],
          [-1.7249600, 54.9641400],
          [-1.7247900, 54.9642900],
          [-1.7246500, 54.9645100],
          [-1.7245000, 54.9648200],
          [-1.7244200, 54.9649400],
          [-1.7242500, 54.9650800],
          [-1.7240200, 54.9651800],
          [-1.7232700, 54.9654200],
          [-1.7224900, 54.9655900],
          [-1.7219500, 54.9656700],
          [-1.7213800, 54.9658200],
          [-1.7208700, 54.9658900],
          [-1.7203900, 54.9660000],
          [-1.7201000, 54.9662100],
          [-1.7200500, 54.9664800],
          [-1.7196700, 54.9665600],
          [-1.7194100, 54.9665800],
          [-1.7191700, 54.9665500],
          [-1.7190300, 54.9665600],
          [-1.7190000, 54.9665700],
          [-1.7190100, 54.9665700],
          [-1.7189700, 54.9665900],
          [-1.7188500, 54.9667700]
        ]
    }
};