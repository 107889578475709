export const monkton =     {
    'type': 'Feature',
    'properties': {
        'color': '#CB3709',
        'description': 'Gravel Cycle Way 👌 </br> Monkton Cycle Way'
    },
    'geometry':{
        //Bowes and Pontop (WaggonWay)
        'type': 'LineString',
        'coordinates': [
          [-1.5020300, 54.9643000],
          [-1.5029800, 54.9636600],
          [-1.5037200, 54.9631500],
          [-1.5044600, 54.9626400],
          [-1.5052000, 54.9621500],
          [-1.5057100, 54.9618600],
          [-1.5066900, 54.9611900],
          [-1.5076700, 54.9605200],
          [-1.5078300, 54.9604300],
          [-1.5079400, 54.9602200],
          [-1.5079400, 54.9601200],
          [-1.5080100, 54.9600600],
          [-1.5084000, 54.9599500],
          [-1.5093700, 54.9592767],
          [-1.5103400, 54.9586033],
          [-1.5113100, 54.9579300],
          [-1.5120350, 54.9574200],
          [-1.5127600, 54.9569100]
        ]
    }
}