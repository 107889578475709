export const risingSunDukesPond = {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Dusty Gravel 😎</br> Rising Sun Dukes Pond'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.5269600, 55.0141600],
          [-1.5265533, 55.0147533],
          [-1.5261467, 55.0153467],
          [-1.5257400, 55.0159400],
          [-1.5252767, 55.0165967],
          [-1.5248133, 55.0172533],
          [-1.5243500, 55.0179100],
          [-1.5253400, 55.0181600],
          [-1.5253600, 55.0181600],
          [-1.5251800, 55.0182700],
          [-1.5249000, 55.0183200],
          [-1.5246700, 55.0183000],
          [-1.5244700, 55.0182900],
          [-1.5242000, 55.0182300],
          [-1.5240400, 55.0182100],
          [-1.5239200, 55.0182100],
          [-1.5238000, 55.0182400],
          [-1.5237500, 55.0182700],
          [-1.5236800, 55.0182700],
          [-1.5236000, 55.0182500],
          [-1.5234500, 55.0182000],
          [-1.5233800, 55.0182000],
          [-1.5232400, 55.0182500],
          [-1.5232200, 55.0182900],
          [-1.5232300, 55.0183900],
          [-1.5232500, 55.0184600],
          [-1.5232400, 55.0186000],
          [-1.5232500, 55.0186900],
          [-1.5233000, 55.0188000],
          [-1.5234300, 55.0189000],
          [-1.5236000, 55.0189500],
          [-1.5238800, 55.0189900],
          [-1.5240100, 55.0190000],
          [-1.5244200, 55.0190100],
          [-1.5244700, 55.0190100],
          [-1.5248700, 55.0191400],
          [-1.5251400, 55.0192700],
          [-1.5252000, 55.0193600],
          [-1.5252200, 55.0195000],
          [-1.5253200, 55.0195600],
          [-1.5253500, 55.0195800],
          [-1.5254000, 55.0196100],
          [-1.5254500, 55.0196300],
          [-1.5258300, 55.0195800],
          [-1.5260400, 55.0195500],
          [-1.5262000, 55.0195000],
          [-1.5263600, 55.0194300],
          [-1.5264500, 55.0193500],
          [-1.5266000, 55.0191900],
          [-1.5266800, 55.0190800],
          [-1.5266900, 55.0189800],
          [-1.5267100, 55.0188900],
          [-1.5269700, 55.0189100],
          [-1.5273700, 55.0188600],
          [-1.5277300, 55.0187700],
          [-1.5279700, 55.0188300],
          [-1.5283100, 55.0188800],
          [-1.5290050, 55.0191300],
          [-1.5297000, 55.0193800],
          [-1.5298000, 55.0194200],
          [-1.5300700, 55.0194900],
          [-1.5303200, 55.0195700],
          [-1.5304000, 55.0195900],
          [-1.5304700, 55.0196500],
          [-1.5306000, 55.0196600],
          [-1.5308300, 55.0196900],
          [-1.5311200, 55.0197400],
          [-1.5312300, 55.0197600],
          [-1.5320300, 55.0199800],
          [-1.5320600, 55.0199600],
          [-1.5317400, 55.0198500],
          [-1.5317800, 55.0198300],
          [-1.5319900, 55.0198800],
          [-1.5323200, 55.0195700],
          [-1.5325500, 55.0193700],
          [-1.5327400, 55.0191800],
          [-1.5328300, 55.0190900],
          [-1.5329800, 55.0189100],
          [-1.5334300, 55.0183700],
          [-1.5336100, 55.0181400],
          [-1.5340500, 55.0174400],
          [-1.5340800, 55.0173800],
          [-1.5341100, 55.0173600],
        ]
    }
};