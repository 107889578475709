export const gosforthEast = {
    'type': 'Feature',
    'properties': {
        'color': '#481D24',
        'description': 'Dirt 🤗</br> Gosforth East'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          
[-1.6128100, 55.0256000],
[-1.6126800, 55.0255800],
[-1.6127300, 55.0252000],
[-1.6130100, 55.0248800],
[-1.6130000, 55.0247900],
[-1.6129800, 55.0246500],
[-1.6129800, 55.0245400],
[-1.6130000, 55.0243900],
[-1.6129900, 55.0242300],
[-1.6126400, 55.0235300],
[-1.6125400, 55.0233000],
[-1.6123900, 55.0231600],
[-1.6122500, 55.0230700],
[-1.6121300, 55.0229900],
[-1.6115600, 55.0228600],
[-1.6111700, 55.0227800],
[-1.6101800, 55.0225000],
[-1.6094600, 55.0222200],
[-1.6092400, 55.0221000],
[-1.6087250, 55.0213550],
[-1.6082100, 55.0206100],
[-1.6081400, 55.0204100],
[-1.6081100, 55.0202600],
[-1.6081000, 55.0199400],
[-1.6081300, 55.0193000],
[-1.6081600, 55.0186600],
[-1.6082400, 55.0182600],
[-1.6079700, 55.0174000],
[-1.6076700, 55.0167500],
[-1.6076150, 55.0160500],
[-1.6075600, 55.0153500],
[-1.6073800, 55.0147450],
[-1.6072000, 55.0141400],
[-1.6065900, 55.0135467],
[-1.6059800, 55.0129533],
[-1.6053700, 55.0123600],
        ]
    }
};