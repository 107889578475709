import React from 'react';
import styled from 'styled-components';

const MainSection = styled('div')`
    height: 90vh;
    text-align: center;
    color: #fff;
`;
const NotFound = () => (
    <MainSection>
        <h1>Error 404: Page Not Found</h1>
    </MainSection>
);

export default NotFound;