export const pontelandSouthEast =     {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Dusty Gravel 😎</br> Ponteland South East'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
            [-1.7421400, 55.0465000],
            [-1.7419900, 55.0463700],
            [-1.7415250, 55.0459300],
            [-1.7410600, 55.0454900],
            [-1.7402700, 55.0449750],
            [-1.7394800, 55.0444600],
            [-1.7385200, 55.0439400],
            [-1.7376700, 55.0435900],
            [-1.7369300, 55.0433000],
            [-1.7361900, 55.0430100],
            [-1.7350275, 55.0426650],
            [-1.7338650, 55.0423200],
            [-1.7327025, 55.0419750],
            [-1.7315400, 55.0416300],
            [-1.7313100, 55.0416300],
            [-1.7312300, 55.0415100],
            [-1.7310900, 55.0415200],
            [-1.7299375, 55.0411850],
            [-1.7287850, 55.0408500],
            [-1.7276325, 55.0405150],
            [-1.7264800, 55.0401800],
            [-1.7263600, 55.0401800],
            [-1.7262500, 55.0402200],
            [-1.7255700, 55.0400000],
            [-1.7253400, 55.0399700],
            [-1.7251900, 55.0399700],
            [-1.7251000, 55.0399500],
            [-1.7246600, 55.0398000],
            [-1.7242900, 55.0395900],
            [-1.7231700, 55.0392200],
            [-1.7228300, 55.0390700],
            [-1.7223500, 55.0389700],
            [-1.7215200, 55.0387150],
            [-1.7206900, 55.0384600],
            [-1.7203500, 55.0383600],
            [-1.7191033, 55.0378900],
            [-1.7178566, 55.0374200],
            [-1.7166100, 55.0369500],
            [-1.7163200, 55.0367900],
            [-1.7159800, 55.0365000],
            [-1.7158700, 55.0364900],

        ]
    }
};