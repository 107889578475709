export const chopwellOne =     {
    'type': 'Feature',
    'properties': {
        'color': '#481D24',
        'description': 'Packed Earth 🤗</br> Chopwell Woods'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.7696100, 54.9089900],
          [-1.7699500, 54.9090300],
          [-1.7707800, 54.9090600],
          [-1.7716100, 54.9090900],
          [-1.7728600, 54.9092300],
          [-1.7737400, 54.9095100],
          [-1.7746200, 54.9097900],
          [-1.7753950, 54.9100550],
          [-1.7761700, 54.9103200],
          [-1.7766000, 54.9104100],
          [-1.7769200, 54.9104700],
          [-1.7776900, 54.9107300],
          [-1.7779100, 54.9108800],
          [-1.7780000, 54.9109900],
          [-1.7780100, 54.9110900],
          [-1.7780500, 54.9112600],
          [-1.7781700, 54.9114100],
          [-1.7783500, 54.9114500],
          [-1.7784900, 54.9114400],
          [-1.7787000, 54.9113800],
          [-1.7788500, 54.9113400],
          [-1.7789100, 54.9113200],
          [-1.7792000, 54.9113500],
          [-1.7794700, 54.9114600],
          [-1.7797500, 54.9116600],
          [-1.7798000, 54.9120900],
          [-1.7794200, 54.9120700],
          [-1.7790200, 54.9122000],
          [-1.7788300, 54.9125900],
          [-1.7786800, 54.9133900],
          [-1.7787200, 54.9135000],
          [-1.7787200, 54.9138800],
          [-1.7788300, 54.9140900],
          [-1.7791900, 54.9145500],
          [-1.7792000, 54.9148200],
          [-1.7797500, 54.9149600],
          [-1.7801500, 54.9150900],
          [-1.7805400, 54.9151100],
          [-1.7810000, 54.9155500],
          [-1.7811200, 54.9158700],
          [-1.7810800, 54.9163550],
          [-1.7810400, 54.9168400],
          [-1.7809200, 54.9171600],
          [-1.7818700, 54.9174700],
          [-1.7824100, 54.9176000],
          [-1.7827900, 54.9177000],
          [-1.7834800, 54.9177600],
          [-1.7841300, 54.9177300],
          [-1.7848300, 54.9178400],
          [-1.7855100, 54.9180800],
          [-1.7857900, 54.9181700],
          [-1.7864800, 54.9183100],
          [-1.7869500, 54.9183600],
          [-1.7872400, 54.9183200],
          [-1.7876900, 54.9182000],
          [-1.7881700, 54.9181300],
          [-1.7885400, 54.9182200],
          [-1.7893800, 54.9186900],
          [-1.7895400, 54.9187600],
          [-1.7897100, 54.9187800],
          [-1.7898400, 54.9191100],
          [-1.7908650, 54.9188950],
          [-1.7918900, 54.9186800],
          [-1.7928900, 54.9185700],
          [-1.7930900, 54.9185200],
          [-1.7933200, 54.9184300],
          [-1.7934200, 54.9183200],
          [-1.7934100, 54.9181500],
          [-1.7936000, 54.9176300],
          [-1.7940700, 54.9173300],
          [-1.7953700, 54.9170700],
          [-1.7966700, 54.9168100],
          [-1.7971900, 54.9166200],
          [-1.7982250, 54.9172850],
          [-1.7992600, 54.9179500],
          [-1.7993400, 54.9182200],
          [-1.7994400, 54.9182600],
          [-1.7997000, 54.9182300],
          [-1.8000300, 54.9182500],
          [-1.8007100, 54.9182900],
          [-1.8010200, 54.9182700],
          [-1.8012800, 54.9183300],
          [-1.8017100, 54.9185700],
          [-1.8021100, 54.9187600],
          [-1.8020800, 54.9190200],
          [-1.8016900, 54.9196900],
          [-1.8013000, 54.9204500],
          [-1.7997400, 54.9204300],
          [-1.7981800, 54.9204100],
          [-1.7966200, 54.9203900],
          [-1.7966300, 54.9204000],
          [-1.7960000, 54.9203900],
          [-1.7952600, 54.9204500],
          [-1.7938700, 54.9206900],
          [-1.7932200, 54.9208700],
          [-1.7924800, 54.9211600],
          [-1.7916500, 54.9215500],
          [-1.7915000, 54.9217000],
          [-1.7910600, 54.9220400],
          [-1.7909600, 54.9221400],
          [-1.7909600, 54.9221300],
          [-1.7907200, 54.9223800],
          [-1.7904100, 54.9228300],
          [-1.7901700, 54.9232500],
          [-1.7900700, 54.9237900],
          [-1.7901000, 54.9241400],
          [-1.7901400, 54.9247400],
          [-1.7901800, 54.9253400],
          [-1.7898300, 54.9252800],
          [-1.7891800, 54.9252500],
          [-1.7886900, 54.9252000],
          [-1.7884100, 54.9252100],
          [-1.7877700, 54.9253100],
          [-1.7876300, 54.9253000],
          [-1.7875700, 54.9253000],
          [-1.7869200, 54.9251400],
          [-1.7867200, 54.9251400],
          [-1.7861400, 54.9252100],
          [-1.7854000, 54.9252700],
          [-1.7841300, 54.9252600],
          [-1.7834400, 54.9251000],
          [-1.7832800, 54.9250900]
        ]
    }
};