import { consettSunderlandRailwayPath } from '../WaggonWays/consettSunderlandRailwayPath.js';
import { beamishWest } from './beamishWest.js';
import { chopwellOne } from './chopwellOne.js';
import { chopwellDerwentWalk } from './chopwellToDerwentWalk.js';
import { gosforthEast } from './gosforthEast.js';
import { intoChopwellWest } from './intoChopwellWest.js';
import { risingSun } from './risingSun.js';
const dirt = [
  chopwellOne,
  chopwellDerwentWalk,
  beamishWest,
  intoChopwellWest,
  consettSunderlandRailwayPath,
  gosforthEast,
  risingSun,
];

export default dirt;
