export const hadriansRisingSun = {
    'type': 'Feature',
    'properties': {
        'color': '#000',
        'description': 'Link 🥱</br> Hadrians 👈👉 Rising Sun'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
            [-1.5054100, 54.9935700],
            [-1.5061700, 54.9938300],
            [-1.5069300, 54.9940900],
            [-1.5069300, 54.9941000],
            [-1.5077400, 54.9942450],
            [-1.5085500, 54.9943900],
            [-1.5098033, 54.9947567],
            [-1.5110566, 54.9951233],
            [-1.5123100, 54.9954900],
            [-1.5132100, 54.9956900],
            [-1.5135900, 54.9956800]
        ]
    }
}