export const killingWorth = {
    'type': 'Feature',
    'properties': {
        'color': '#CB3709',
        'description': 'Waggon Way 👌 </br> Killingworth'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
            [-1.5205900, 55.0113400],
            [-1.5217500, 55.0118600],
            [-1.5227920, 55.0123200],
            [-1.5238339, 55.0127800],
            [-1.5248759, 55.0132400],
            [-1.5259180, 55.0137000],
            [-1.5269600, 55.0141600],
            [-1.5281100, 55.0146900],
            [-1.5292600, 55.0152200],
            [-1.5301800, 55.0156200],
            [-1.5311000, 55.0160200],
            [-1.5317800, 55.0163450],
            [-1.5324600, 55.0166700],
            [-1.5332800, 55.0170150],
            [-1.5341000, 55.0173600],
            [-1.5351825, 55.0178550],
            [-1.5362649, 55.0183500],
            [-1.5373475, 55.0188450],
            [-1.5384300, 55.0193400],
            [-1.5392200, 55.0196800],
            [-1.5400100, 55.0200200],
            [-1.5402100, 55.0201300]
        ]
    }
}