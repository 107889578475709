export const wallsendHeaton = {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Dusty Gravel 😎</br> Wallsend to Heaton'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.5516600, 54.9949600],
          [-1.5517500, 54.9950700],
          [-1.5518300, 54.9951500],
          [-1.5520400, 54.9951700],
          [-1.5522200, 54.9951200],
          [-1.5524400, 54.9949800],
          [-1.5530100, 54.9946900],
          [-1.5532300, 54.9945300],
          [-1.5533700, 54.9944400],
          [-1.5543567, 54.9941000],
          [-1.5553433, 54.9937600],
          [-1.5563300, 54.9934200],
          [-1.5572650, 54.9931850],
          [-1.5582000, 54.9929500],
          [-1.5586500, 54.9928200],
          [-1.5590000, 54.9925800],
          [-1.5593900, 54.9922800],
          [-1.5600800, 54.9919200],
          [-1.5606600, 54.9916600],
          [-1.5612400, 54.9914400],
          [-1.5624600, 54.9908900]
        ]
    }
};