export const bowes =     {
    'type': 'Feature',
    'properties': {
        'color': '#CB3709',
        'description': 'Waggon Way 👌 </br> Bowes Waggon Way'
    },
    'geometry':{
        //Bowes and Pontop (WaggonWay)
        'type': 'LineString',
        'coordinates': [
            [-1.5079400, 54.9602200],
            [-1.5079400, 54.9601200],
            [-1.5080100, 54.9600600],
            [-1.5084000, 54.9599500],
            [-1.5093700, 54.9592767],
            [-1.5103400, 54.9586033],
            [-1.5113100, 54.9579300],
            [-1.5122100, 54.9572967],
            [-1.5131100, 54.9566633],
            [-1.5140100, 54.9560300],
            [-1.5150500, 54.9553800],
            [-1.5155400, 54.9549900],
            [-1.5160100, 54.9546800],
            [-1.5169500, 54.9540200],
            [-1.5179200, 54.9533600],
            [-1.5188901, 54.9527000],
            [-1.5198600, 54.9520400],
            [-1.5208300, 54.9513800],
            [-1.5209900, 54.9512900],
            [-1.5211600, 54.9512200],
            [-1.5213600, 54.9511100],
            [-1.5215300, 54.9509700],
            [-1.5217100, 54.9507800],
            [-1.5220400, 54.9505400],
            [-1.5222800, 54.9504200],
            [-1.5224800, 54.9503000],
            [-1.5225900, 54.9501100],
            [-1.5232650, 54.9496250],
            [-1.5239400, 54.9491400],
            [-1.5239400, 54.9491300],
            [-1.5246350, 54.9486250],
            [-1.5253300, 54.9481200],
            [-1.5259400, 54.9476950],
            [-1.5265500, 54.9472700],
            [-1.5265500, 54.9471700],
            [-1.5267500, 54.9471100],
            [-1.5274100, 54.9466300],
            [-1.5279600, 54.9462650],
            [-1.5285100, 54.9459000],
            [-1.5291300, 54.9454000],
            [-1.5292200, 54.9453000],
            [-1.5301675, 54.9446050],
            [-1.5311151, 54.9439100],
            [-1.5320625, 54.9432150],
            [-1.5330100, 54.9425200],
            [-1.5337500, 54.9419950],
            [-1.5344900, 54.9414700],
            [-1.5346900, 54.9414400],
            [-1.5346700, 54.9413100],
            [-1.5348800, 54.9411400],
            [-1.5356625, 54.9405850],
            [-1.5364450, 54.9400300],
            [-1.5372275, 54.9394750],
            [-1.5380100, 54.9389200],
            [-1.5389100, 54.9382433],
            [-1.5398100, 54.9375667],
            [-1.5407100, 54.9368900],
            [-1.5409300, 54.9366400],
            [-1.5414900, 54.9363500],
            [-1.5420100, 54.9359300],
            [-1.5428500, 54.9353900],
            [-1.5435600, 54.9348250],
            [-1.5442700, 54.9342600],
            [-1.5451201, 54.9336500],
            [-1.5459701, 54.9330400],
            [-1.5468201, 54.9324300],
            [-1.5476701, 54.9318200],
            [-1.5485200, 54.9312100],
            [-1.5493020, 54.9306420],
            [-1.5500841, 54.9300740],
            [-1.5508661, 54.9295060],
            [-1.5516480, 54.9289380],
            [-1.5524300, 54.9283700],
            [-1.5524300, 54.9283600],
            [-1.5529100, 54.9280000],
            [-1.5534100, 54.9276800],
            [-1.5543100, 54.9270000],
            [-1.5548750, 54.9266000],
            [-1.5554400, 54.9262000],
            [-1.5555900, 54.9261700],
            [-1.5564100, 54.9256200],
            [-1.5570200, 54.9252600],
            [-1.5576000, 54.9249900],
            [-1.5583000, 54.9245000],
            [-1.5587500, 54.9245100],
            [-1.5591100, 54.9244400],
            [-1.5583000, 54.9237800],
            [-1.5593567, 54.9233067],
            [-1.5604134, 54.9228333],
            [-1.5614700, 54.9223600],
            [-1.5616400, 54.9220200],
            [-1.5619300, 54.9216400],
            [-1.5626900, 54.9214500],
            [-1.5635200, 54.9208500],
            [-1.5643750, 54.9206100],
            [-1.5652300, 54.9203700],
            [-1.5651200, 54.9201000],
            [-1.5648700, 54.9197600],
            [-1.5648100, 54.9196900],
            [-1.5647500, 54.9193600],
            [-1.5648500, 54.9192400],
            [-1.5643000, 54.9186100],
            [-1.5643800, 54.9185300],
            [-1.5644200, 54.9183800],
            [-1.5643750, 54.9178700],
            [-1.5643300, 54.9173600],
            [-1.5642800, 54.9169000],
            [-1.5642700, 54.9166400],
            [-1.5642000, 54.9165200],
            [-1.5642000, 54.9164400],
            [-1.5643400, 54.9162000],
            [-1.5644500, 54.9160800],
            [-1.5645500, 54.9158800],
            [-1.5647800, 54.9156900],
            [-1.5649200, 54.9154600],
            [-1.5650400, 54.9153300],
            [-1.5651200, 54.9152800],
            [-1.5651500, 54.9152700],
            [-1.5657000, 54.9152900],
            [-1.5658000, 54.9152800],
            [-1.5661100, 54.9149900],
            [-1.5669400, 54.9142600],
            [-1.5677700, 54.9135300],
            [-1.5677300, 54.9134700],
            [-1.5675900, 54.9134100],
            [-1.5673900, 54.9133600],
            [-1.5670600, 54.9132000],
            [-1.5670200, 54.9131400],
            [-1.5670300, 54.9130700],
            [-1.5673000, 54.9125800],
            [-1.5675700, 54.9120900],
            [-1.5676400, 54.9119900],
            [-1.5679500, 54.9118000],
            [-1.5692000, 54.9115734],
            [-1.5704501, 54.9113467],
            [-1.5717001, 54.9111201],
            [-1.5729501, 54.9108934],
            [-1.5742000, 54.9106667],
            [-1.5754500, 54.9104400],
            [-1.5763100, 54.9102700],
            [-1.5774500, 54.9099900],
            [-1.5788600, 54.9096100],
            [-1.5802000, 54.9092400],
            [-1.5815400, 54.9088700],
            [-1.5815500, 54.9088700],
            [-1.5829075, 54.9084925],
            [-1.5842651, 54.9081150],
            [-1.5856225, 54.9077375],
            [-1.5869800, 54.9073600],
            [-1.5873200, 54.9072800],
            [-1.5883900, 54.9070700],
            [-1.5894600, 54.9068600],
            [-1.5908300, 54.9066375],
            [-1.5922000, 54.9064150],
            [-1.5935700, 54.9061925],
            [-1.5949400, 54.9059700],
            [-1.5958450, 54.9058100],
            [-1.5967500, 54.9056500],
            [-1.5979300, 54.9054300],
            [-1.5991100, 54.9052100],
            [-1.5998950, 54.9051050],
            [-1.6006800, 54.9050000],
            [-1.6016400, 54.9048200],
            [-1.6018600, 54.9048100],
            [-1.6020600, 54.9047700],
            [-1.6022600, 54.9047100],
            [-1.6027800, 54.9046100],
            [-1.6041067, 54.9043967],
            [-1.6054333, 54.9041833],
            [-1.6067600, 54.9039700],
            [-1.6081600, 54.9037267],
            [-1.6095600, 54.9034833],
            [-1.6109600, 54.9032400],
            [-1.6117150, 54.9031000],
            [-1.6124700, 54.9029600],
            [-1.6133700, 54.9028100],
            [-1.6142700, 54.9026600],
            [-1.6142900, 54.9026600],
            [-1.6156367, 54.9024367],
            [-1.6169834, 54.9022134],
            [-1.6183301, 54.9019901],
            [-1.6196767, 54.9017667],
            [-1.6210234, 54.9015434],
            [-1.6223700, 54.9013200],
            [-1.6238400, 54.9011600],
            [-1.6253100, 54.9010000],
            [-1.6263900, 54.9009500],
            [-1.6271800, 54.9009800],
            [-1.6280700, 54.9011000],
            [-1.6291950, 54.9013050],
            [-1.6303200, 54.9015100],
            [-1.6314450, 54.9017150],
            [-1.6325700, 54.9019200],
            [-1.6334050, 54.9020650],
            [-1.6342400, 54.9022100],
            [-1.6356844, 54.9024534],
            [-1.6371288, 54.9026968],
            [-1.6385732, 54.9029402],
            [-1.6400176, 54.9031835],
            [-1.6414620, 54.9034268],
            [-1.6429065, 54.9036702],
            [-1.6443510, 54.9039135],
            [-1.6457955, 54.9041567],
            [-1.6472400, 54.9044000],
            [-1.6484200, 54.9045867],
            [-1.6496000, 54.9047733],
            [-1.6507800, 54.9049600],
            [-1.6519980, 54.9051720],
            [-1.6532160, 54.9053840],
            [-1.6544340, 54.9055960],
            [-1.6556520, 54.9058080],
            [-1.6568700, 54.9060200],
            [-1.6569700, 54.9061000],
            [-1.6571700, 54.9061700],
            [-1.6586700, 54.9063800],
            [-1.6593800, 54.9063400],
            [-1.6606650, 54.9065150],
            [-1.6619500, 54.9066900],
            [-1.6625300, 54.9068200],
            [-1.6638800, 54.9072700],
            [-1.6646800, 54.9075850],
            [-1.6654800, 54.9079000],
            [-1.6661550, 54.9081350],
            [-1.6668300, 54.9083700],
            [-1.6670700, 54.9084200],
            [-1.6684100, 54.9086600],
            [-1.6688700, 54.9087000],
            [-1.6701200, 54.9088967],
            [-1.6713700, 54.9090933],
            [-1.6726200, 54.9092900],
            [-1.6730900, 54.9093300],
            [-1.6744500, 54.9093300]
        ]
    }
}