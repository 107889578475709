import React, { useRef, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import styled from '@emotion/styled';
import compiledRoutesData from '../../assets/routes_data/compiledRoutesData';
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;
const MapBoxMap = styled.div({
  height: '90vh',
  width: '100%',
});
export const GravelRoutes = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-1.6, 54.966667],
      zoom: 9,
    });

    for (const type in compiledRoutesData) {
      map.current.on('load', () => {
        map.current.addSource(type, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: compiledRoutesData[type],
          },
        });
        map.current.addLayer({
          id: type,
          type: 'line',
          source: type,
          paint: {
            'line-width': 3,
            // Use a get expression (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-get)
            // to set the line-color to a feature property value.
            'line-color': ['get', 'color'],
          },
        });
      });

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });
      map.current.on('mouseenter', type, (e) => {
        map.current.getCanvas().style.cursor = 'pointer';
        const description = e.features[0].properties.description;
        map.current.on('click', type, (e) => {
          popup.setLngLat(e.lngLat).setHTML(description).addTo(map.current);
        });
      });
      map.current.on('mouseleave', type, () => {
        map.current.getCanvas().style.cursor = '';
      });
      map.current.on('click', (e) => {
        popup.remove();
      });
    }
  });
  return (
    <div>
      <MapBoxMap ref={mapContainer} className='map-container' />
    </div>
  );
};
