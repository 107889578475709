export const bruntonNorth = {
    'type': 'Feature',
    'properties': {
        'color': '#0D1F22',
        'description': 'Bridleway 😎</br> Brunton North'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          
[-1.6665500, 55.0384400],
[-1.6660300, 55.0385000],
[-1.6652400, 55.0385600],
[-1.6648400, 55.0386900],
[-1.6644300, 55.0386300],
[-1.6642100, 55.0386600],
[-1.6641600, 55.0388600],
[-1.6641300, 55.0391400],
[-1.6640900, 55.0393900],
[-1.6637900, 55.0395400],
[-1.6634100, 55.0395500],
[-1.6625400, 55.0395000],
[-1.6621100, 55.0395200],
[-1.6621200, 55.0395200],
[-1.6607300, 55.0395900],
[-1.6604300, 55.0397600],
[-1.6597250, 55.0400800],
[-1.6590200, 55.0404000],
[-1.6582350, 55.0405100],
[-1.6574500, 55.0406200],
[-1.6566950, 55.0407850],
[-1.6559400, 55.0409500],
[-1.6548000, 55.0411300],
[-1.6533000, 55.0411400],
[-1.6525000, 55.0411900],
[-1.6513000, 55.0411800],
[-1.6498600, 55.0410900],
[-1.6489400, 55.0410500],
[-1.6478450, 55.0410700],
[-1.6467500, 55.0410900],
[-1.6468500, 55.0408500],
[-1.6468700, 55.0404700],
[-1.6468000, 55.0399000],
[-1.6468100, 55.0396300],
[-1.6468100, 55.0396200],
[-1.6468100, 55.0392700],
[-1.6469100, 55.0390000],
[-1.6478200, 55.0383767],
[-1.6487300, 55.0377533],
[-1.6496400, 55.0371300],
[-1.6506067, 55.0364467],
[-1.6515734, 55.0357633],
[-1.6525400, 55.0350800],
        ]
    }
};