export const wellLane = {
  type: 'Feature',
  properties: {
    color: '#C70039',
    description: 'Dusty Gravel 😎</br> Well Lane',
  },
  geometry: {
    type: 'LineString',
    coordinates: [
      [-1.48637, 55.03147],
      [-1.48619, 55.03152],
      [-1.48544, 55.031965],
      [-1.48469, 55.03241],
      [-1.484165, 55.032745],
      [-1.48364, 55.03308],
      [-1.48326, 55.03335],
      [-1.48314, 55.03336],
      [-1.483, 55.03333],
      [-1.48272, 55.03317],
      [-1.48201, 55.03271],
      [-1.48181, 55.03262],
      [-1.48154, 55.03255],
      [-1.48115, 55.03237],
      [-1.4800776, 55.0330225],
      [-1.4790051, 55.033675],
      [-1.4779326, 55.0343275],
      [-1.47686, 55.03498],
      [-1.4768, 55.03499],
      [-1.47652, 55.03525],
    ],
  },
};
