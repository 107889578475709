export const newburnToWylam =     {
    'type': 'Feature',
    'properties': {
        'color': '#6A0DAD',
        'description': 'Paved Cycleway 😏</br>Newburn to Wylam'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.7664600, 54.9835300],
          [-1.7668600, 54.9835100],
          [-1.7683500, 54.9835200],
          [-1.7691450, 54.9835650],
          [-1.7699400, 54.9836100],
          [-1.7708400, 54.9837200],
          [-1.7718700, 54.9838900],
          [-1.7727900, 54.9840600],
          [-1.7739400, 54.9842300],
          [-1.7744100, 54.9842600],
          [-1.7753200, 54.9844100],
          [-1.7766800, 54.9846033],
          [-1.7780400, 54.9847967],
          [-1.7794000, 54.9849900],
          [-1.7805100, 54.9851700],
          [-1.7812300, 54.9852600],
          [-1.7824200, 54.9854600],
          [-1.7836100, 54.9856200],
          [-1.7842800, 54.9856900],
          [-1.7855600, 54.9857300],
          [-1.7859600, 54.9856900],
          [-1.7859700, 54.9856900],
          [-1.7874000, 54.9854400],
          [-1.7881400, 54.9852600],
          [-1.7894600, 54.9849000],
          [-1.7902000, 54.9846750],
          [-1.7909400, 54.9844500],
          [-1.7920600, 54.9840933],
          [-1.7931800, 54.9837367],
          [-1.7943000, 54.9833800],
          [-1.7953600, 54.9830050],
          [-1.7964200, 54.9826300],
          [-1.7972533, 54.9822567],
          [-1.7980867, 54.9818833],
          [-1.7989200, 54.9815100],
          [-1.7997150, 54.9811350],
          [-1.8005100, 54.9807600],
          [-1.8012900, 54.9804200],
          [-1.8021200, 54.9801100],
          [-1.8029500, 54.9798000],
          [-1.8040400, 54.9794800],
          [-1.8052600, 54.9790800],
          [-1.8060200, 54.9789000],
          [-1.8073600, 54.9786400],
          [-1.8082600, 54.9784300],
          [-1.8091600, 54.9782200],
          [-1.8101400, 54.9778800],
          [-1.8112600, 54.9775900],
          [-1.8123800, 54.9773000],
          [-1.8135000, 54.9770100],
          [-1.8143100, 54.9769100],
          [-1.8151200, 54.9768100],
          [-1.8155600, 54.9768000],
          [-1.8164400, 54.9766300],
          [-1.8166800, 54.9766000],
        ]
    }
};