export const blythSouth = {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Dusty Gravel 😎</br> Blyth South'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          
[-1.5081500, 55.0850200], 
[-1.5080700, 55.0851100], 
[-1.5080700, 55.0851800], 
[-1.5087075, 55.0857850], 
[-1.5093450, 55.0863900], 
[-1.5099825, 55.0869950], 
[-1.5106200, 55.0876000], 
[-1.5107500, 55.0877700], 
[-1.5109200, 55.0879400], 
[-1.5113600, 55.0882200], 
[-1.5115400, 55.0882700], 
[-1.5119000, 55.0882500], 
[-1.5129000, 55.0887500], 
[-1.5132300, 55.0889500], 
[-1.5135000, 55.0892100], 
[-1.5140750, 55.0900200], 
[-1.5146500, 55.0908300], 
[-1.5146500, 55.0908700], 
[-1.5146200, 55.0909100], 
[-1.5145500, 55.0909400], 
[-1.5139000, 55.0910200], 
[-1.5138100, 55.0910800], 
[-1.5138000, 55.0911300], 
[-1.5138100, 55.0911900], 
[-1.5140567, 55.0918200], 
[-1.5143033, 55.0924500], 
[-1.5145500, 55.0930800], 
[-1.5146200, 55.0931200], 
[-1.5149300, 55.0931200], 
[-1.5151200, 55.0931800], 
[-1.5153300, 55.0933900], 
[-1.5155600, 55.0937500], 
[-1.5156300, 55.0942700], 
[-1.5155800, 55.0943200], 
[-1.5159000, 55.0944900], 
[-1.5159700, 55.0944900], 
[-1.5163200, 55.0945300], 
[-1.5172650, 55.0942950], 
[-1.5182100, 55.0940600], 
[-1.5194350, 55.0935300], 
[-1.5206600, 55.0930000], 
[-1.5216200, 55.0924800], 
[-1.5216300, 55.0924800], 
[-1.5221100, 55.0922300], 
[-1.5222100, 55.0921600], 
[-1.5222100, 55.0920800], 
[-1.5221700, 55.0920000], 
[-1.5218400, 55.0915100], 
[-1.5215100, 55.0910200], 
[-1.5227450, 55.0910250], 
[-1.5239800, 55.0910300], 
[-1.5244100, 55.0910500], 
[-1.5248200, 55.0910000], 
[-1.5256500, 55.0909800], 
[-1.5264800, 55.0909600], 
[-1.5266900, 55.0909100], 
[-1.5277350, 55.0903250], 
[-1.5287800, 55.0897400], 
[-1.5296334, 55.0891767], 
[-1.5304867, 55.0886133], 
[-1.5313400, 55.0880500], 
[-1.5323100, 55.0877350], 
[-1.5332800, 55.0874200], 
[-1.5332900, 55.0874200], 
[-1.5343400, 55.0870850], 
[-1.5353900, 55.0867500], 
[-1.5363800, 55.0862100], 
[-1.5372400, 55.0856900], 
[-1.5373500, 55.0856300], 
[-1.5375614, 55.0864300], 
[-1.5377728, 55.0872300], 
[-1.5379842, 55.0880300], 
[-1.5381957, 55.0888300], 
[-1.5384071, 55.0896300], 
[-1.5386185, 55.0904300], 
[-1.5388300, 55.0912300], 
[-1.5390390, 55.0920409], 
[-1.5392481, 55.0928518], 
[-1.5394572, 55.0936627], 
[-1.5396662, 55.0944736], 
[-1.5398753, 55.0952846], 
[-1.5400844, 55.0960955], 
[-1.5402935, 55.0969064], 
[-1.5405026, 55.0977173], 
[-1.5407117, 55.0985282], 
[-1.5409209, 55.0993391], 
[-1.5411300, 55.1001500], 
[-1.5410600, 55.1004900], 
[-1.5411300, 55.1009000], 
[-1.5414700, 55.1012500], 
[-1.5416450, 55.1019325], 
[-1.5418200, 55.1026150], 
[-1.5419950, 55.1032975], 
[-1.5421700, 55.1039800], 
[-1.5419300, 55.1039600], 
        ]
    }
};