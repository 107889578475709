import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { Nav } from "./components/Nav";
import "mapbox-gl/dist/mapbox-gl.css";

ReactDOM.render(<Nav />, document.getElementById("root"));

serviceWorker.unregister();
