export const risingSunEast = {
  type: 'Feature',
  properties: {
    color: '#0D1F22',
    description: 'Bridleway 😎</br> Rising Sun East',
  },
  geometry: {
    type: 'LineString',
    coordinates: [
      [-1.52265, 55.00926],
      [-1.52256, 55.0093],
      [-1.52168, 55.00948],
      [-1.5204925, 55.00964],
      [-1.519305, 55.0098],
      [-1.5181175, 55.00996],
      [-1.51693, 55.01012],
      [-1.51656, 55.01023],
      [-1.51505, 55.010435],
      [-1.51354, 55.01064],
      [-1.512165, 55.01077],
      [-1.51079, 55.0109],
      [-1.51034, 55.01093],
      [-1.51006, 55.01091],
      [-1.50998, 55.01087],
      [-1.50927, 55.01109],
      [-1.50868, 55.01117],
      [-1.50732, 55.0114],
      [-1.50596, 55.01163],
      [-1.5046, 55.01186],
      [-1.50371, 55.01198],
      [-1.50273, 55.01216],
      [-1.50151, 55.0123567],
      [-1.50029, 55.0125533],
      [-1.49907, 55.01275],
      [-1.49791, 55.012955],
      [-1.49675, 55.01316],
      [-1.49534, 55.01335],
      [-1.49496, 55.01338],
      [-1.49448, 55.01338],
      [-1.49394, 55.01335],
      [-1.49357, 55.01331],
      [-1.49267, 55.01301],
      [-1.49253, 55.01293],
      [-1.49242, 55.0129],
    ],
  },
};
