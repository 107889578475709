import { bowes } from './bowes';
import { derwentWalk } from './derwentWalk';
import { hownsgill } from './hownsgill';
import { waskerlyWay } from './waskerleyWay';
import { tanfield } from './tanfield';
import { killingWorth } from './killingworth';
import { monkton } from './monkton';
import { shiremoor } from './shiremoor';
import { aveNorth } from './aveNorth';
import { coxlodge } from './coxlodge';
import { weetslade } from './weetslade';
const waggonWays = [
  derwentWalk,
  hownsgill,
  bowes,
  waskerlyWay,
  tanfield,
  killingWorth,
  monkton,
  shiremoor,
  aveNorth,
  coxlodge,
  weetslade,
];

export default waggonWays;
