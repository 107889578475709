import { stamfordhamRoadWest } from './stamfordhamRoadWest';
import { northBeamish } from './northBeamish';
import { eastBeamish } from './eastBeamish';
import { causeyBridleway } from './causeBridleway';
import { cramlingtonSouth } from './cramlingtonSouth';
import { bruntonNorth } from './bruntonNorth';
import { risingSunEast } from './risingSunEast';

const bridleways = [
  stamfordhamRoadWest,
  northBeamish,
  eastBeamish,
  causeyBridleway,
  cramlingtonSouth,
  bruntonNorth,
  risingSunEast,
];

export default bridleways;
