export const dinningtonSouth =     {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Dusty Gravel 😎</br> Dinnington South'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [  
            
[-1.6640000, 55.0564200],
[-1.6639500, 55.0561400],
[-1.6639500, 55.0561500],
[-1.6638700, 55.0553650],
[-1.6637900, 55.0545800],
[-1.6638200, 55.0542700],
[-1.6636500, 55.0542100],
[-1.6628700, 55.0537500],
[-1.6627650, 55.0531850],
[-1.6626600, 55.0526200],
[-1.6625300, 55.0518000],
[-1.6622200, 55.0510600],
[-1.6621300, 55.0507700],
[-1.6618900, 55.0499100],
[-1.6617800, 55.0493000],
[-1.6618200, 55.0485300],
[-1.6617700, 55.0482900],
[-1.6615200, 55.0476900],
[-1.6615500, 55.0476600],
[-1.6617800, 55.0476400],
[-1.6629300, 55.0473000],
[-1.6628400, 55.0467400],
[-1.6625600, 55.0461700],
[-1.6624400, 55.0457900],
[-1.6623600, 55.0451600],
[-1.6623600, 55.0446900],
[-1.6621000, 55.0438067],
[-1.6618400, 55.0429233],
[-1.6615800, 55.0420400],
[-1.6614400, 55.0415800],

        ]
    }
};