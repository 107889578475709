export const kibblesworth = {
    'type': 'Feature',
    'properties': {
        'color': '#000',
        'description': 'Link 🥱</br> Kibblesworth 👈👉 Beamish'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
            [-1.6325700, 54.9019100],
            [-1.6332650, 54.9016250],
            [-1.6339600, 54.9013400],
            [-1.6344600, 54.9011600],
            [-1.6348600, 54.9010400],
            [-1.6359600, 54.9007567],
            [-1.6370600, 54.9004733],
            [-1.6381600, 54.9001900],
            [-1.6384000, 54.9000600],
            [-1.6385300, 54.8999500],
            [-1.6391167, 54.8991700],
            [-1.6397034, 54.8983900],
            [-1.6402900, 54.8976100],
            [-1.6410634, 54.8970033],
            [-1.6418367, 54.8963967],
            [-1.6426100, 54.8957900],
            [-1.6431300, 54.8954150],
            [-1.6436500, 54.8950400]
        ]
    }
}