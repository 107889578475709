export const coalwayPlantation = {
    'type': 'Feature',
    'properties': {
        'color': '#856084',
        'description': 'Baby Heads 🥵</br> Coalway Plantation'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.8024800, 54.9596800],
          [-1.8020700, 54.9590400],
          [-1.8017850, 54.9584600],
          [-1.8015000, 54.9578800],
          [-1.8013600, 54.9576800],
          [-1.8011600, 54.9570400],
          [-1.8011600, 54.9562000],
          [-1.8010600, 54.9557800],
          [-1.8008900, 54.9555400],
          [-1.8008300, 54.9553500],
          [-1.8008200, 54.9553600],
          [-1.8010200, 54.9548100],
          [-1.8012000, 54.9541400],
          [-1.8011200, 54.9540000],
          [-1.8008500, 54.9537200],
          [-1.8007900, 54.9535100],
          [-1.8008800, 54.9533600],
          [-1.8012900, 54.9529800],
          [-1.8013900, 54.9526500],
          [-1.8018300, 54.9521400],
          [-1.8019400, 54.9519500],
          [-1.8019000, 54.9518200],
          [-1.8014600, 54.9512600],
          [-1.8013300, 54.9510700],
          [-1.8014000, 54.9508400],
          [-1.8012900, 54.9506200],
          [-1.8010700, 54.9504500],
        ]
    }
};