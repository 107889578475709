export const derwentTanfield = {
    'type': 'Feature',
    'properties': {
        'color': '#000',
        'description': 'Link </br> Derwent 👈👉 Tanfield'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
            [-1.6755300, 54.9203300],
            [-1.6756900, 54.9203700],
            [-1.6761200, 54.9203400],
            [-1.6762900, 54.9203500],
            [-1.6769700, 54.9206700],
            [-1.6772100, 54.9208300],
            [-1.6774800, 54.9208800],
            [-1.6776000, 54.9209400],
            [-1.6776800, 54.9210200],
            [-1.6776800, 54.9210800],
            [-1.6780500, 54.9210900],
            [-1.6787000, 54.9211600],
            [-1.6789400, 54.9211700],
            [-1.6793200, 54.9210700],
            [-1.6793400, 54.9210700],
            [-1.6794800, 54.9210700],
            [-1.6795800, 54.9211100],
            [-1.6800500, 54.9215300],
            [-1.6802200, 54.9216800],
            [-1.6803900, 54.9217800],
            [-1.6808400, 54.9220300],
            [-1.6812500, 54.9220400],
            [-1.6812600, 54.9220400],
            [-1.6813400, 54.9220400],
            [-1.6817100, 54.9221000],
            [-1.6824250, 54.9223350],
            [-1.6831400, 54.9225700],
            [-1.6834100, 54.9226900],
            [-1.6835500, 54.9228900],
            [-1.6835400, 54.9230800],
            [-1.6832100, 54.9233500],
            [-1.6831500, 54.9234500],
            [-1.6831600, 54.9234500],
            [-1.6831000, 54.9235400],
            [-1.6831600, 54.9237800],
            [-1.6833400, 54.9240500],
            [-1.6833300, 54.9241400],
            [-1.6833100, 54.9244000],
            [-1.6832700, 54.9244900],
            [-1.6826300, 54.9251500],
            [-1.6825900, 54.9253200],
            [-1.6826300, 54.9254000],
            [-1.6827000, 54.9254600],
            [-1.6831800, 54.9258000],
            [-1.6837400, 54.9262000],
            [-1.6841200, 54.9264500],
            [-1.6842600, 54.9265800],
            [-1.6842900, 54.9266700],
            [-1.6842000, 54.9268400],
            [-1.6837900, 54.9272600],
            [-1.6833800, 54.9276800],
            [-1.6832000, 54.9277600],
            [-1.6829300, 54.9278100],
            [-1.6831200, 54.9282300],
            [-1.6839500, 54.9283800],
            [-1.6852940, 54.9286880],
            [-1.6866379, 54.9289960],
            [-1.6879819, 54.9293040],
            [-1.6893260, 54.9296120],
            [-1.6906700, 54.9299200],
            [-1.6915933, 54.9302200],
            [-1.6925167, 54.9305200],
            [-1.6934400, 54.9308200],
            [-1.6947150, 54.9313000],
            [-1.6959900, 54.9317800],
            [-1.6970100, 54.9320100],
            [-1.6984100, 54.9323000],
            [-1.6998100, 54.9325900],
            [-1.7000200, 54.9326600],
            [-1.7006600, 54.9329600],
            [-1.7013000, 54.9332600],
            [-1.7015200, 54.9334400],
            [-1.7017100, 54.9336600],
            [-1.7018500, 54.9338800],
            [-1.7019800, 54.9340100],
            [-1.7021900, 54.9340600],
            [-1.7025900, 54.9340900],
            [-1.7029700, 54.9341500],
            [-1.7032400, 54.9342200],
            [-1.7035500, 54.9343800],
            [-1.7036400, 54.9345300],
            [-1.7037800, 54.9346400],
            [-1.7045900, 54.9349300],
            [-1.7052000, 54.9352300],
            [-1.7057100, 54.9355200],
            [-1.7060900, 54.9358500],
            [-1.7063200, 54.9360700],
            [-1.7066300, 54.9365500],
            [-1.7071300, 54.9370600],
            [-1.7073800, 54.9372400],
            [-1.7077800, 54.9374700],
            [-1.7082300, 54.9376500],
            [-1.7086600, 54.9377900],
            [-1.7090700, 54.9379000],
            [-1.7093700, 54.9380900],
            [-1.7094700, 54.9382700],
            [-1.7094900, 54.9383700]
        ]
    }
}