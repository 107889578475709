export const toNufc = {
  type: 'Feature',
  properties: {
    color: '#6A0DAD',
    description: 'Paved Cycleway 😏',
  },
  geometry: {
    type: 'LineString',
    coordinates: [
      [-1.57049, 55.00053],
      [-1.56983, 55.00067],
      [-1.56902, 55.00074],
      [-1.56795, 55.00109],
      [-1.56659, 55.0015],
      [-1.56523, 55.00191],
      [-1.56438, 55.002145],
      [-1.56353, 55.00238],
      [-1.56317, 55.00241],
    ],
  },
};
