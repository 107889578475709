export const cramlingtonSouth = {
    'type': 'Feature',
    'properties': {
        'color': '#0D1F22',
        'description': 'Bridleway 😎</br> Cramlington South'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          
[-1.6088200, 55.0838400],
[-1.6089050, 55.0831400],
[-1.6089900, 55.0824400],
[-1.6089800, 55.0821600],
[-1.6091200, 55.0813900],
[-1.6090500, 55.0810800],
[-1.6088400, 55.0809800],
[-1.6084000, 55.0809100],
[-1.6082300, 55.0808600],
[-1.6081700, 55.0807600],
[-1.6082200, 55.0801800],
[-1.6082150, 55.0794550],
[-1.6082100, 55.0787300],
[-1.6081850, 55.0780900],
[-1.6081600, 55.0774500],
[-1.6080900, 55.0772800],
[-1.6081000, 55.0772900],
[-1.6079700, 55.0772200],
[-1.6078200, 55.0771800],
[-1.6072500, 55.0771300],
[-1.6070800, 55.0770800],
[-1.6069800, 55.0770000],
[-1.6068700, 55.0767300],
[-1.6064867, 55.0761133],
[-1.6061033, 55.0754967],
[-1.6057200, 55.0748800],
[-1.6051100, 55.0742333],
[-1.6045000, 55.0735867],
[-1.6038900, 55.0729400],
[-1.6032725, 55.0721775],
[-1.6026550, 55.0714150],
[-1.6020375, 55.0706525],
[-1.6014200, 55.0698900],
[-1.6013500, 55.0697500],


        ]
    }
};