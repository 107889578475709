export const coxlodge = {
  type: 'Feature',
  properties: {
    color: '#CB3709',
    description: 'Waggon Way 👌 </br> Coxlodge',
  },
  geometry: {
    //Bowes and Pontop (WaggonWay)
    type: 'LineString',
    coordinates: [
      [-1.60103, 55.00696],
      [-1.60072, 55.00691],
      [-1.60057, 55.00699],
      [-1.60054, 55.00698],
      [-1.60054, 55.0069],
      [-1.60052, 55.00688],
      [-1.59984, 55.00669],
      [-1.59951, 55.00669],
      [-1.59918, 55.0066],
      [-1.59866, 55.0064],
      [-1.59727, 55.00603],
      [-1.59588, 55.00566],
      [-1.59449, 55.00529],
      [-1.5931325, 55.00491],
      [-1.5917749, 55.00453],
      [-1.5904175, 55.00415],
      [-1.58906, 55.00377],
      [-1.58831, 55.003575],
      [-1.58756, 55.00338],
      [-1.5861539, 55.003006],
      [-1.5847479, 55.002632],
      [-1.5833419, 55.002258],
      [-1.5819359, 55.001884],
      [-1.58053, 55.00151],
      [-1.57999, 55.00139],
      [-1.57971, 55.00141],
      [-1.57947, 55.00135],
      [-1.5794, 55.00127],
      [-1.5779975, 55.00103],
      [-1.576595, 55.00079],
      [-1.5751925, 55.00055],
      [-1.57379, 55.00031],
      [-1.57291, 55.00023],
      [-1.57194, 55.00027],
      [-1.571155, 55.00032],
      [-1.57037, 55.00037],
    ],
  },
};
