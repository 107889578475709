export const aveNorth =     {
    'type': 'Feature',
    'properties': {
        'color': '#CB3709',
        'description': 'Waggon Way 👌 </br> The Ave Waggon Way'
    },
    'geometry':{
        //Bowes and Pontop (WaggonWay)
        'type': 'LineString',
        'coordinates': [
            
[-1.4925500, 55.0666500],
[-1.4926500, 55.0667300],
[-1.4926500, 55.0667400],
[-1.4934325, 55.0675175],
[-1.4942149, 55.0682950],
[-1.4949975, 55.0690725],
[-1.4957800, 55.0698500],
[-1.4965262, 55.0705837],
[-1.4972725, 55.0713173],
[-1.4980188, 55.0720510],
[-1.4987651, 55.0727846],
[-1.4995114, 55.0735183],
[-1.5002578, 55.0742519],
[-1.5010042, 55.0749855],
[-1.5017506, 55.0757191],
[-1.5024970, 55.0764528],
[-1.5032435, 55.0771864],
[-1.5039900, 55.0779200],
[-1.5047100, 55.0786700],
[-1.5054300, 55.0794200],
        ]
    }
}