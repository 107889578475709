import waggonWays from "./WaggonWays/data";
import links from "./Links/data";
import pavedCycleways from "./PavedCycleways/data";
import dustyGravel from "./DustyGravel/data";
import bridleways from "./Bridleways/data";
import dirt from "./Dirt/data";
import rooty from "./Rooty/data";
import babyHeads from "./BabyHeads/data";

const compiledData = {  waggonWays, links, pavedCycleways, dustyGravel, bridleways, dirt, rooty, babyHeads };
export default compiledData;