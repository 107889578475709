export const bowesBeamish =     {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Newcastle -> Bowes Railway -> Beamish -> Blaydon </br><a href="https://www.strava.com/routes/2964577341622326854" target="_blank">Strava Route</a>'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
            [-1.6002000, 54.9702000], 
            [-1.5997600, 54.9701200], 
            [-1.5993800, 54.9699700], 
            [-1.5992800, 54.9699000], 
            [-1.5991800, 54.9697900], 
            [-1.5991500, 54.9696300], 
            [-1.5991800, 54.9694200], 
            [-1.5993500, 54.9691700], 
            [-1.5994100, 54.9691300], 
            [-1.5994000, 54.9691200], 
            [-1.5986650, 54.9692800], 
            [-1.5979300, 54.9694400], 
            [-1.5968850, 54.9696000], 
            [-1.5958400, 54.9697600], 
            [-1.5955900, 54.9691800], 
            [-1.5950100, 54.9692800], 
            [-1.5935600, 54.9693500], 
            [-1.5927900, 54.9695200], 
            [-1.5924100, 54.9695900], 
            [-1.5918100, 54.9695700], 
            [-1.5911500, 54.9695200], 
            [-1.5903350, 54.9693750], 
            [-1.5895200, 54.9692300], 
            [-1.5892200, 54.9691500], 
            [-1.5883000, 54.9688300], 
            [-1.5878300, 54.9686300], 
            [-1.5876400, 54.9685200], 
            [-1.5876000, 54.9684600], 
            [-1.5876300, 54.9684100], 
            [-1.5882100, 54.9681000], 
            [-1.5878900, 54.9679000], 
            [-1.5878800, 54.9679000], 
            [-1.5877700, 54.9678400], 
            [-1.5871100, 54.9673400], 
            [-1.5866550, 54.9669600], 
            [-1.5862000, 54.9665800], 
            [-1.5859600, 54.9664000], 
            [-1.5857100, 54.9662800], 
            [-1.5851300, 54.9661500], 
            [-1.5850800, 54.9661600], 
            [-1.5846100, 54.9660700], 
            [-1.5845000, 54.9660300], 
            [-1.5844100, 54.9659800], 
            [-1.5839300, 54.9656200], 
            [-1.5838700, 54.9655300], 
            [-1.5838600, 54.9654600], 
            [-1.5838300, 54.9654000], 
            [-1.5835200, 54.9652200], 
            [-1.5827800, 54.9648200], 
            [-1.5825100, 54.9647100], 
            [-1.5823000, 54.9646500], 
            [-1.5819600, 54.9646200], 
            [-1.5816700, 54.9646300], 
            [-1.5813900, 54.9644400], 
            [-1.5808800, 54.9642000], 
            [-1.5796700, 54.9638200], 
            [-1.5784100, 54.9635600], 
            [-1.5771500, 54.9633000], 
            [-1.5768100, 54.9632600], 
            [-1.5762700, 54.9632400], 
            [-1.5750200, 54.9632400], 
            [-1.5747200, 54.9632600], 
            [-1.5742300, 54.9633500], 
            [-1.5744800, 54.9634700], 
            [-1.5746100, 54.9635600], 
            [-1.5745700, 54.9636900], 
            [-1.5744500, 54.9637600], 
            [-1.5734450, 54.9638750], 
            [-1.5724400, 54.9639900], 
            [-1.5721300, 54.9640100], 
            [-1.5721400, 54.9640100], 
            [-1.5716900, 54.9640400], 
            [-1.5703800, 54.9640500], 
            [-1.5700300, 54.9640800], 
            [-1.5696900, 54.9640400], 
            [-1.5694600, 54.9639900], 
            [-1.5693600, 54.9639400], 
            [-1.5690800, 54.9636500], 
            [-1.5690500, 54.9636000], 
            [-1.5690700, 54.9635500], 
            [-1.5688700, 54.9633000], 
            [-1.5683700, 54.9628700], 
            [-1.5683600, 54.9627200], 
            [-1.5682000, 54.9627000], 
            [-1.5679300, 54.9627300], 
            [-1.5671200, 54.9627650], 
            [-1.5663100, 54.9628000], 
            [-1.5660000, 54.9627700], 
            [-1.5656300, 54.9626900], 
            [-1.5650800, 54.9626300], 
            [-1.5644800, 54.9625000], 
            [-1.5639200, 54.9622900], 
            [-1.5635000, 54.9620700], 
            [-1.5630250, 54.9616900], 
            [-1.5625500, 54.9613100], 
            [-1.5621700, 54.9609800], 
            [-1.5617300, 54.9608600], 
            [-1.5613900, 54.9606900], 
            [-1.5610200, 54.9604400], 
            [-1.5609100, 54.9603400], 
            [-1.5608600, 54.9602300], 
            [-1.5608500, 54.9599400], 
            [-1.5606100, 54.9594800], 
            [-1.5606100, 54.9590300], 
            [-1.5606650, 54.9585150], 
            [-1.5607200, 54.9580000], 
            [-1.5602000, 54.9579700], 
            [-1.5600100, 54.9578800], 
            [-1.5600700, 54.9574500], 
            [-1.5600300, 54.9573200], 
            [-1.5599400, 54.9567800], 
            [-1.5599100, 54.9564300], 
            [-1.5599500, 54.9561500], 
            [-1.5600700, 54.9557100], 
            [-1.5600700, 54.9552900], 
            [-1.5600200, 54.9550300], 
            [-1.5591500, 54.9553100], 
            [-1.5582800, 54.9555900], 
            [-1.5577000, 54.9557200], 
            [-1.5573400, 54.9557700], 
            [-1.5573500, 54.9557700], 
            [-1.5569600, 54.9557800], 
            [-1.5565400, 54.9557500], 
            [-1.5563500, 54.9557800], 
            [-1.5559500, 54.9557200], 
            [-1.5557600, 54.9557500], 
            [-1.5553700, 54.9558900], 
            [-1.5552000, 54.9559300], 
            [-1.5550300, 54.9559100], 
            [-1.5546800, 54.9558100], 
            [-1.5545200, 54.9558100], 
            [-1.5543300, 54.9559000], 
            [-1.5543700, 54.9559800], 
            [-1.5543700, 54.9566700], 
            [-1.5542000, 54.9572800], 
            [-1.5542400, 54.9574300], 
            [-1.5543400, 54.9575500], 
            [-1.5546900, 54.9577300], 
            [-1.5545400, 54.9578100], 
            [-1.5541800, 54.9578800], 
            [-1.5538500, 54.9579600], 
            [-1.5534300, 54.9579200], 
            [-1.5529500, 54.9579100], 
            [-1.5527100, 54.9579400], 
            [-1.5521400, 54.9580500], 
            [-1.5516300, 54.9582100], 
            [-1.5507500, 54.9585400], 
            [-1.5501400, 54.9587900], 
            [-1.5498700, 54.9588600], 
            [-1.5496300, 54.9588900], 
            [-1.5494600, 54.9589500], 
            [-1.5490500, 54.9592800], 
            [-1.5484100, 54.9595000], 
            [-1.5482400, 54.9595000], 
            [-1.5479700, 54.9594700], 
            [-1.5476600, 54.9594100], 
            [-1.5468500, 54.9591400], 
            [-1.5464100, 54.9589500], 
            [-1.5462700, 54.9589100], 
            [-1.5461800, 54.9588900], 
            [-1.5456300, 54.9588600], 
            [-1.5450200, 54.9587600], 
            [-1.5447500, 54.9587800], 
            [-1.5442700, 54.9589100], 
            [-1.5427800, 54.9590000], 
            [-1.5417400, 54.9590000], 
            [-1.5408500, 54.9590600], 
            [-1.5399700, 54.9591500], 
            [-1.5390400, 54.9592700], 
            [-1.5390500, 54.9593600], 
            [-1.5389500, 54.9593800], 
            [-1.5382700, 54.9592900], 
            [-1.5378700, 54.9592900], 
            [-1.5377400, 54.9593200], 
            [-1.5375600, 54.9594300], 
            [-1.5374900, 54.9595000], 
            [-1.5374600, 54.9596200], 
            [-1.5373900, 54.9597000], 
            [-1.5372300, 54.9598300], 
            [-1.5366600, 54.9602600], 
            [-1.5361100, 54.9605800], 
            [-1.5357000, 54.9608800], 
            [-1.5354300, 54.9611300], 
            [-1.5352200, 54.9613500], 
            [-1.5350000, 54.9616200], 
            [-1.5348300, 54.9619300], 
            [-1.5344900, 54.9624000], 
            [-1.5341000, 54.9631200], 
            [-1.5337400, 54.9638400], 
            [-1.5330900, 54.9641200], 
            [-1.5324400, 54.9644000], 
            [-1.5327800, 54.9639400], 
            [-1.5331050, 54.9633650], 
            [-1.5334300, 54.9627900], 
            [-1.5339400, 54.9621900], 
            [-1.5343520, 54.9614760], 
            [-1.5347640, 54.9607620], 
            [-1.5351760, 54.9600480], 
            [-1.5355880, 54.9593340], 
            [-1.5360000, 54.9586200], 
            [-1.5361800, 54.9584300], 
            [-1.5362700, 54.9584300], 
            [-1.5363900, 54.9583000], 
            [-1.5366700, 54.9581400], 
            [-1.5365900, 54.9581100], 
            [-1.5365100, 54.9580100], 
            [-1.5366400, 54.9579900], 
            [-1.5365100, 54.9580100], 
            [-1.5354200, 54.9582500], 
            [-1.5352000, 54.9582900], 
            [-1.5342300, 54.9583700], 
            [-1.5332600, 54.9584500], 
            [-1.5327800, 54.9584600], 
            [-1.5324100, 54.9584000], 
            [-1.5311100, 54.9579900], 
            [-1.5309100, 54.9579400], 
            [-1.5307700, 54.9578800], 
            [-1.5306800, 54.9579400], 
            [-1.5300400, 54.9577300], 
            [-1.5299000, 54.9577400], 
            [-1.5296100, 54.9575900], 
            [-1.5285500, 54.9572700], 
            [-1.5281100, 54.9570100], 
            [-1.5280100, 54.9568400], 
            [-1.5279700, 54.9566100], 
            [-1.5265300, 54.9567400], 
            [-1.5250900, 54.9568700], 
            [-1.5236500, 54.9570000], 
            [-1.5236600, 54.9570000], 
            [-1.5226200, 54.9571000], 
            [-1.5220300, 54.9570300], 
            [-1.5218700, 54.9567900], 
            [-1.5216500, 54.9563000], 
            [-1.5214300, 54.9558100], 
            [-1.5210500, 54.9550300], 
            [-1.5210200, 54.9549900], 
            [-1.5208500, 54.9548600], 
            [-1.5206000, 54.9547800], 
            [-1.5191150, 54.9549050], 
            [-1.5176300, 54.9550300], 
            [-1.5171900, 54.9551000], 
            [-1.5163700, 54.9552000], 
            [-1.5163100, 54.9552900], 
            [-1.5160200, 54.9554400], 
            [-1.5158500, 54.9554600], 
            [-1.5152400, 54.9553700], 
            [-1.5150500, 54.9553800], 
            [-1.5155400, 54.9549900], 
            [-1.5164217, 54.9543883], 
            [-1.5173034, 54.9537867], 
            [-1.5181851, 54.9531850], 
            [-1.5190668, 54.9525834], 
            [-1.5199484, 54.9519817], 
            [-1.5208300, 54.9513800], 
            [-1.5209900, 54.9512900], 
            [-1.5211600, 54.9512200], 
            [-1.5213600, 54.9511100], 
            [-1.5215300, 54.9509700], 
            [-1.5217100, 54.9507800], 
            [-1.5220400, 54.9505400], 
            [-1.5222800, 54.9504200], 
            [-1.5224800, 54.9503000], 
            [-1.5225900, 54.9501100], 
            [-1.5230800, 54.9497700], 
            [-1.5238300, 54.9492200], 
            [-1.5245800, 54.9486700], 
            [-1.5253300, 54.9481200], 
            [-1.5259400, 54.9476950], 
            [-1.5265500, 54.9472700], 
            [-1.5265500, 54.9471700], 
            [-1.5267500, 54.9471100], 
            [-1.5274100, 54.9466300], 
            [-1.5279600, 54.9462650], 
            [-1.5285100, 54.9459000], 
            [-1.5291300, 54.9454000], 
            [-1.5292200, 54.9453000], 
            [-1.5301675, 54.9446050], 
            [-1.5311151, 54.9439100], 
            [-1.5320625, 54.9432150], 
            [-1.5330100, 54.9425200], 
            [-1.5337500, 54.9419950], 
            [-1.5344900, 54.9414700], 
            [-1.5346900, 54.9414400], 
            [-1.5346700, 54.9413100], 
            [-1.5348800, 54.9411400], 
            [-1.5356625, 54.9405850], 
            [-1.5364450, 54.9400300], 
            [-1.5372275, 54.9394750], 
            [-1.5380100, 54.9389200], 
            [-1.5389100, 54.9382433], 
            [-1.5398100, 54.9375667], 
            [-1.5407100, 54.9368900], 
            [-1.5409300, 54.9366400], 
            [-1.5414900, 54.9363500], 
            [-1.5420100, 54.9359300], 
            [-1.5428500, 54.9353900], 
            [-1.5435600, 54.9348250], 
            [-1.5442700, 54.9342600], 
            [-1.5452326, 54.9335663], 
            [-1.5461952, 54.9328725], 
            [-1.5471577, 54.9321788], 
            [-1.5481203, 54.9314851], 
            [-1.5490827, 54.9307913], 
            [-1.5500452, 54.9300975], 
            [-1.5510076, 54.9294038], 
            [-1.5519700, 54.9287100], 
            [-1.5529100, 54.9280000], 
            [-1.5534100, 54.9276800], 
            [-1.5543100, 54.9270000], 
            [-1.5548750, 54.9266000], 
            [-1.5554400, 54.9262000], 
            [-1.5555900, 54.9261700], 
            [-1.5564100, 54.9256200], 
            [-1.5570200, 54.9252600], 
            [-1.5576000, 54.9249900], 
            [-1.5583000, 54.9245000], 
            [-1.5587500, 54.9245100], 
            [-1.5591100, 54.9244400], 
            [-1.5583000, 54.9237800], 
            [-1.5593567, 54.9233067], 
            [-1.5604134, 54.9228333], 
            [-1.5614700, 54.9223600], 
            [-1.5616400, 54.9220200], 
            [-1.5619300, 54.9216400], 
            [-1.5626900, 54.9214500], 
            [-1.5635200, 54.9208500], 
            [-1.5645300, 54.9205700], 
            [-1.5645300, 54.9205600], 
            [-1.5652300, 54.9203700], 
            [-1.5651200, 54.9201000], 
            [-1.5648100, 54.9196900], 
            [-1.5647500, 54.9193600], 
            [-1.5648500, 54.9192400], 
            [-1.5643000, 54.9186100], 
            [-1.5643800, 54.9185300], 
            [-1.5644200, 54.9183800], 
            [-1.5643750, 54.9178700], 
            [-1.5643300, 54.9173600], 
            [-1.5642800, 54.9169000], 
            [-1.5642700, 54.9166400], 
            [-1.5642000, 54.9165200], 
            [-1.5642000, 54.9164400], 
            [-1.5643400, 54.9162000], 
            [-1.5644500, 54.9160800], 
            [-1.5645500, 54.9158800], 
            [-1.5647800, 54.9156900], 
            [-1.5649200, 54.9154600], 
            [-1.5650400, 54.9153300], 
            [-1.5651200, 54.9152800], 
            [-1.5651500, 54.9152700], 
            [-1.5657000, 54.9152900], 
            [-1.5658000, 54.9152800], 
            [-1.5661100, 54.9149900], 
            [-1.5669400, 54.9142600], 
            [-1.5677700, 54.9135300], 
            [-1.5677300, 54.9134700], 
            [-1.5675900, 54.9134100], 
            [-1.5673900, 54.9133600], 
            [-1.5670600, 54.9132000], 
            [-1.5670200, 54.9131400], 
            [-1.5670300, 54.9130700], 
            [-1.5673000, 54.9125800], 
            [-1.5675700, 54.9120900], 
            [-1.5676400, 54.9119900], 
            [-1.5679500, 54.9118000], 
            [-1.5692000, 54.9115734], 
            [-1.5704501, 54.9113467], 
            [-1.5717001, 54.9111201], 
            [-1.5729501, 54.9108934], 
            [-1.5742000, 54.9106667], 
            [-1.5754500, 54.9104400], 
            [-1.5763100, 54.9102700], 
            [-1.5774500, 54.9099900], 
            [-1.5788600, 54.9096100], 
            [-1.5802134, 54.9092350], 
            [-1.5815668, 54.9088601], 
            [-1.5829201, 54.9084851], 
            [-1.5842734, 54.9081101], 
            [-1.5856267, 54.9077350], 
            [-1.5869800, 54.9073600], 
            [-1.5873200, 54.9072800], 
            [-1.5883900, 54.9070700], 
            [-1.5894600, 54.9068600], 
            [-1.5908300, 54.9066375], 
            [-1.5922000, 54.9064150], 
            [-1.5935700, 54.9061925], 
            [-1.5949400, 54.9059700], 
            [-1.5958450, 54.9058100], 
            [-1.5967500, 54.9056500], 
            [-1.5979300, 54.9054300], 
            [-1.5991100, 54.9052100], 
            [-1.5998950, 54.9051050], 
            [-1.6006800, 54.9050000], 
            [-1.6016400, 54.9048200], 
            [-1.6018600, 54.9048100], 
            [-1.6020600, 54.9047700], 
            [-1.6022600, 54.9047100], 
            [-1.6027800, 54.9046100], 
            [-1.6041067, 54.9043967], 
            [-1.6054333, 54.9041833], 
            [-1.6067600, 54.9039700], 
            [-1.6081600, 54.9037267], 
            [-1.6095600, 54.9034833], 
            [-1.6109600, 54.9032400], 
            [-1.6117150, 54.9031000], 
            [-1.6124700, 54.9029600], 
            [-1.6138900, 54.9027250], 
            [-1.6153100, 54.9024900], 
            [-1.6167300, 54.9022550], 
            [-1.6181500, 54.9020200], 
            [-1.6181600, 54.9020200], 
            [-1.6195633, 54.9017867], 
            [-1.6209667, 54.9015533], 
            [-1.6223700, 54.9013200], 
            [-1.6238400, 54.9011600], 
            [-1.6253100, 54.9010000], 
            [-1.6263900, 54.9009500], 
            [-1.6271800, 54.9009800], 
            [-1.6280700, 54.9011000], 
            [-1.6291950, 54.9013050], 
            [-1.6303200, 54.9015100], 
            [-1.6314450, 54.9017150], 
            [-1.6325700, 54.9019200], 
            [-1.6332650, 54.9016300], 
            [-1.6339600, 54.9013400], 
            [-1.6344600, 54.9011600], 
            [-1.6348600, 54.9010400], 
            [-1.6359600, 54.9007567], 
            [-1.6370600, 54.9004733], 
            [-1.6381600, 54.9001900], 
            [-1.6384000, 54.9000600], 
            [-1.6385300, 54.8999500], 
            [-1.6391167, 54.8991700], 
            [-1.6397034, 54.8983900], 
            [-1.6402900, 54.8976100], 
            [-1.6410634, 54.8970033], 
            [-1.6418367, 54.8963967], 
            [-1.6426100, 54.8957900], 
            [-1.6431300, 54.8954150], 
            [-1.6436500, 54.8950400], 
            [-1.6444000, 54.8948700], 
            [-1.6455100, 54.8946425], 
            [-1.6466200, 54.8944150], 
            [-1.6477300, 54.8941875], 
            [-1.6488400, 54.8939600], 
            [-1.6500700, 54.8937400], 
            [-1.6503000, 54.8933500], 
            [-1.6503500, 54.8927600], 
            [-1.6506300, 54.8919700], 
            [-1.6508000, 54.8915900], 
            [-1.6506300, 54.8910400], 
            [-1.6504900, 54.8905900], 
            [-1.6504100, 54.8900400], 
            [-1.6504100, 54.8897800], 
            [-1.6500200, 54.8896200], 
            [-1.6494000, 54.8895300], 
            [-1.6490600, 54.8893600], 
            [-1.6489200, 54.8889600], 
            [-1.6488400, 54.8886700], 
            [-1.6484100, 54.8883000], 
            [-1.6481600, 54.8879400], 
            [-1.6483300, 54.8876000], 
            [-1.6481100, 54.8871500], 
            [-1.6478500, 54.8866300], 
            [-1.6478200, 54.8861500], 
            [-1.6478200, 54.8857300], 
            [-1.6480200, 54.8853400], 
            [-1.6477000, 54.8845900], 
            [-1.6475600, 54.8839700], 
            [-1.6474200, 54.8836000], 
            [-1.6469400, 54.8831300], 
            [-1.6468300, 54.8826300], 
            [-1.6467400, 54.8822000], 
            [-1.6471100, 54.8819900], 
            [-1.6472700, 54.8816300], 
            [-1.6475600, 54.8811100], 
            [-1.6484300, 54.8809300], 
            [-1.6492400, 54.8809500], 
            [-1.6502000, 54.8807900], 
            [-1.6501300, 54.8807100], 
            [-1.6503400, 54.8806400], 
            [-1.6505400, 54.8805200], 
            [-1.6507000, 54.8802500], 
            [-1.6507300, 54.8800700], 
            [-1.6507000, 54.8799100], 
            [-1.6505300, 54.8797800], 
            [-1.6502300, 54.8796800], 
            [-1.6501700, 54.8796700], 
            [-1.6499900, 54.8796500], 
            [-1.6491000, 54.8796200], 
            [-1.6478200, 54.8793200], 
            [-1.6465100, 54.8791100], 
            [-1.6456250, 54.8790450], 
            [-1.6447400, 54.8789800], 
            [-1.6434800, 54.8789800], 
            [-1.6428400, 54.8790800], 
            [-1.6421400, 54.8793000], 
            [-1.6411850, 54.8795500], 
            [-1.6402300, 54.8798000], 
            [-1.6400000, 54.8799000], 
            [-1.6398500, 54.8800500], 
            [-1.6397500, 54.8802000], 
            [-1.6396200, 54.8805100], 
            [-1.6394100, 54.8806600], 
            [-1.6393500, 54.8806800], 
            [-1.6393500, 54.8806700], 
            [-1.6392100, 54.8807200], 
            [-1.6386900, 54.8807900], 
            [-1.6381300, 54.8808900], 
            [-1.6377300, 54.8809900], 
            [-1.6374800, 54.8811400], 
            [-1.6373500, 54.8812700], 
            [-1.6373100, 54.8813800], 
            [-1.6373100, 54.8815000], 
            [-1.6374100, 54.8815900], 
            [-1.6374600, 54.8817000], 
            [-1.6374600, 54.8817800], 
            [-1.6373500, 54.8819100], 
            [-1.6371100, 54.8820600], 
            [-1.6366800, 54.8822600], 
            [-1.6364600, 54.8824100], 
            [-1.6362300, 54.8826000], 
            [-1.6362300, 54.8825300], 
            [-1.6360900, 54.8824100], 
            [-1.6353100, 54.8821400], 
            [-1.6360900, 54.8824100], 
            [-1.6362300, 54.8825300], 
            [-1.6362300, 54.8826000], 
            [-1.6362300, 54.8825300], 
            [-1.6360900, 54.8824100], 
            [-1.6353100, 54.8821400], 
            [-1.6343000, 54.8819000], 
            [-1.6335900, 54.8820000], 
            [-1.6334100, 54.8819600], 
            [-1.6333100, 54.8818800], 
            [-1.6331100, 54.8813500], 
            [-1.6331500, 54.8807400], 
            [-1.6332400, 54.8804300], 
            [-1.6333100, 54.8803700], 
            [-1.6333900, 54.8801800], 
            [-1.6333800, 54.8799700], 
            [-1.6332900, 54.8797300], 
            [-1.6330150, 54.8792950], 
            [-1.6327400, 54.8788600], 
            [-1.6326500, 54.8786500], 
            [-1.6326100, 54.8785500], 
            [-1.6326700, 54.8779300], 
            [-1.6328800, 54.8772200], 
            [-1.6329100, 54.8770500], 
            [-1.6327400, 54.8764200], 
            [-1.6325800, 54.8759700], 
            [-1.6323600, 54.8755100], 
            [-1.6318700, 54.8746800], 
            [-1.6319300, 54.8747700], 
            [-1.6320900, 54.8748300], 
            [-1.6326700, 54.8756600], 
            [-1.6337533, 54.8758533], 
            [-1.6348367, 54.8760467], 
            [-1.6359200, 54.8762400], 
            [-1.6368700, 54.8763500], 
            [-1.6377950, 54.8763900], 
            [-1.6387200, 54.8764300], 
            [-1.6402550, 54.8765000], 
            [-1.6417900, 54.8765700], 
            [-1.6429550, 54.8766050], 
            [-1.6441200, 54.8766400], 
            [-1.6452167, 54.8766900], 
            [-1.6463133, 54.8767400], 
            [-1.6474100, 54.8767900], 
            [-1.6486600, 54.8768200], 
            [-1.6499100, 54.8768500], 
            [-1.6511600, 54.8768800], 
            [-1.6524100, 54.8769100], 
            [-1.6536600, 54.8769400], 
            [-1.6544400, 54.8769400], 
            [-1.6557900, 54.8768900], 
            [-1.6572600, 54.8766800], 
            [-1.6577500, 54.8766400], 
            [-1.6585400, 54.8765100], 
            [-1.6595900, 54.8764300], 
            [-1.6611100, 54.8763700], 
            [-1.6625600, 54.8764300], 
            [-1.6629700, 54.8764700], 
            [-1.6638150, 54.8766250], 
            [-1.6646600, 54.8767800], 
            [-1.6648000, 54.8768100], 
            [-1.6645900, 54.8770700], 
            [-1.6658814, 54.8773386], 
            [-1.6671728, 54.8776072], 
            [-1.6684642, 54.8778758], 
            [-1.6697556, 54.8781444], 
            [-1.6710471, 54.8784129], 
            [-1.6723385, 54.8786815], 
            [-1.6736300, 54.8789500], 
            [-1.6738500, 54.8786000], 
            [-1.6746100, 54.8787550], 
            [-1.6753700, 54.8789100], 
            [-1.6753100, 54.8791800], 
            [-1.6752100, 54.8795000], 
            [-1.6755800, 54.8801400], 
            [-1.6755400, 54.8803700], 
            [-1.6754800, 54.8805700], 
            [-1.6753100, 54.8808400], 
            [-1.6752400, 54.8811500], 
            [-1.6753500, 54.8814100], 
            [-1.6755800, 54.8816400], 
            [-1.6755800, 54.8819200], 
            [-1.6753200, 54.8820500], 
            [-1.6752800, 54.8822400], 
            [-1.6754100, 54.8824300], 
            [-1.6756100, 54.8826800], 
            [-1.6757100, 54.8828500], 
            [-1.6755800, 54.8831300], 
            [-1.6754800, 54.8834000], 
            [-1.6755700, 54.8837900], 
            [-1.6758600, 54.8840400], 
            [-1.6759900, 54.8841800], 
            [-1.6762600, 54.8844400], 
            [-1.6770700, 54.8848700], 
            [-1.6772400, 54.8850500], 
            [-1.6776500, 54.8853000], 
            [-1.6779500, 54.8853600], 
            [-1.6784000, 54.8853300], 
            [-1.6787700, 54.8853300], 
            [-1.6791500, 54.8853600], 
            [-1.6796500, 54.8854700], 
            [-1.6799900, 54.8855200], 
            [-1.6801200, 54.8855000], 
            [-1.6808300, 54.8854900], 
            [-1.6811700, 54.8855900], 
            [-1.6813000, 54.8856800], 
            [-1.6814800, 54.8858100], 
            [-1.6818100, 54.8859900], 
            [-1.6824000, 54.8861000], 
            [-1.6826700, 54.8862800], 
            [-1.6824500, 54.8863700], 
            [-1.6824500, 54.8863600], 
            [-1.6816500, 54.8867000], 
            [-1.6814100, 54.8867800], 
            [-1.6812400, 54.8868600], 
            [-1.6811000, 54.8869600], 
            [-1.6807500, 54.8874300], 
            [-1.6804900, 54.8878400], 
            [-1.6804600, 54.8880200], 
            [-1.6806300, 54.8885600], 
            [-1.6808000, 54.8891000], 
            [-1.6807300, 54.8895900], 
            [-1.6806700, 54.8902600], 
            [-1.6807000, 54.8905400], 
            [-1.6807800, 54.8907500], 
            [-1.6809100, 54.8910200], 
            [-1.6809100, 54.8911100], 
            [-1.6808300, 54.8912300], 
            [-1.6805700, 54.8915300], 
            [-1.6804900, 54.8917200], 
            [-1.6804600, 54.8919800], 
            [-1.6804600, 54.8925550], 
            [-1.6804600, 54.8931300], 
            [-1.6804300, 54.8932700], 
            [-1.6799700, 54.8939600], 
            [-1.6799500, 54.8941500], 
            [-1.6799200, 54.8943000], 
            [-1.6795100, 54.8947300], 
            [-1.6793500, 54.8949800], 
            [-1.6793100, 54.8956700], 
            [-1.6792700, 54.8963600], 
            [-1.6792700, 54.8968600], 
            [-1.6793100, 54.8972400], 
            [-1.6792400, 54.8974600], 
            [-1.6791400, 54.8976500], 
            [-1.6784900, 54.8983900], 
            [-1.6783300, 54.8986600], 
            [-1.6783000, 54.8988600], 
            [-1.6782650, 54.8993450], 
            [-1.6782300, 54.8998300], 
            [-1.6781750, 54.9003150], 
            [-1.6781200, 54.9008000], 
            [-1.6792200, 54.9008400], 
            [-1.6792500, 54.9008200], 
            [-1.6796500, 54.9008500], 
            [-1.6799700, 54.9008400], 
            [-1.6802200, 54.9007900], 
            [-1.6806700, 54.9006500], 
            [-1.6813400, 54.9005100], 
            [-1.6821900, 54.9003900], 
            [-1.6823600, 54.9003900], 
            [-1.6824900, 54.9004300], 
            [-1.6825700, 54.9004700], 
            [-1.6826300, 54.9005700], 
            [-1.6826600, 54.9006600], 
            [-1.6826600, 54.9008900], 
            [-1.6827400, 54.9009400], 
            [-1.6829700, 54.9009100], 
            [-1.6833400, 54.9007600], 
            [-1.6835700, 54.9007200], 
            [-1.6835000, 54.9008100], 
            [-1.6835000, 54.9008000], 
            [-1.6831700, 54.9012500], 
            [-1.6827700, 54.9015300], 
            [-1.6827300, 54.9015900], 
            [-1.6827000, 54.9017100], 
            [-1.6827200, 54.9023050], 
            [-1.6827400, 54.9029000], 
            [-1.6827633, 54.9035167], 
            [-1.6827867, 54.9041333], 
            [-1.6828100, 54.9047500], 
            [-1.6828400, 54.9048000], 
            [-1.6829000, 54.9048300], 
            [-1.6831400, 54.9048600], 
            [-1.6839300, 54.9047900], 
            [-1.6837433, 54.9055600], 
            [-1.6835567, 54.9063300], 
            [-1.6833700, 54.9071000], 
            [-1.6828150, 54.9074250], 
            [-1.6822600, 54.9077500], 
            [-1.6821200, 54.9078100], 
            [-1.6818700, 54.9078500], 
            [-1.6805300, 54.9081300], 
            [-1.6799300, 54.9083200], 
            [-1.6792100, 54.9087000], 
            [-1.6782500, 54.9089700], 
            [-1.6772150, 54.9092550], 
            [-1.6761800, 54.9095400], 
            [-1.6747500, 54.9096200], 
            [-1.6747700, 54.9097500], 
            [-1.6748700, 54.9097600], 
            [-1.6748700, 54.9098000], 
            [-1.6747100, 54.9103600], 
            [-1.6747600, 54.9104000], 
            [-1.6758900, 54.9104900], 
            [-1.6759900, 54.9106300], 
            [-1.6760500, 54.9108300], 
            [-1.6760900, 54.9111300], 
            [-1.6761300, 54.9111800], 
            [-1.6764000, 54.9113300], 
            [-1.6766000, 54.9114800], 
            [-1.6767600, 54.9116600], 
            [-1.6768000, 54.9117900], 
            [-1.6769400, 54.9120000], 
            [-1.6770000, 54.9121900], 
            [-1.6772100, 54.9123800], 
            [-1.6775300, 54.9126300], 
            [-1.6776100, 54.9127000], 
            [-1.6778200, 54.9130900], 
            [-1.6778800, 54.9132800], 
            [-1.6778900, 54.9134500], 
            [-1.6777100, 54.9142500], 
            [-1.6776100, 54.9144000], 
            [-1.6775800, 54.9145000], 
            [-1.6775400, 54.9148500], 
            [-1.6775800, 54.9150300], 
            [-1.6776200, 54.9151700], 
            [-1.6776200, 54.9152800], 
            [-1.6775100, 54.9156700], 
            [-1.6774100, 54.9158600], 
            [-1.6773400, 54.9161100], 
            [-1.6770700, 54.9165900], 
            [-1.6770400, 54.9167200], 
            [-1.6770400, 54.9170900], 
            [-1.6768700, 54.9175100], 
            [-1.6768300, 54.9175800], 
            [-1.6767300, 54.9176600], 
            [-1.6767000, 54.9178500], 
            [-1.6766400, 54.9179900], 
            [-1.6765700, 54.9181000], 
            [-1.6765400, 54.9182000], 
            [-1.6765400, 54.9183300], 
            [-1.6767100, 54.9183800], 
            [-1.6765000, 54.9190100], 
            [-1.6762900, 54.9195100], 
            [-1.6761900, 54.9196600], 
            [-1.6757500, 54.9201500], 
            [-1.6755300, 54.9203300], 
            [-1.6752800, 54.9205400], 
            [-1.6750400, 54.9207000], 
            [-1.6747700, 54.9208400], 
            [-1.6742900, 54.9212000], 
            [-1.6734950, 54.9218950], 
            [-1.6727000, 54.9225900], 
            [-1.6720100, 54.9232300], 
            [-1.6713200, 54.9238700], 
            [-1.6706300, 54.9245100], 
            [-1.6701050, 54.9250150], 
            [-1.6695800, 54.9255200], 
            [-1.6693700, 54.9256500], 
            [-1.6689700, 54.9260600], 
            [-1.6687700, 54.9262200], 
            [-1.6679867, 54.9267300], 
            [-1.6672034, 54.9272400], 
            [-1.6664200, 54.9277500], 
            [-1.6664200, 54.9277400], 
            [-1.6654575, 54.9283675], 
            [-1.6644951, 54.9289950], 
            [-1.6635325, 54.9296225], 
            [-1.6625700, 54.9302500], 
            [-1.6617700, 54.9307500], 
            [-1.6609700, 54.9312500], 
            [-1.6609700, 54.9314000], 
            [-1.6610600, 54.9315300], 
            [-1.6610100, 54.9317000], 
            [-1.6608700, 54.9317300], 
            [-1.6606400, 54.9316800], 
            [-1.6604300, 54.9316800], 
            [-1.6602000, 54.9317800], 
            [-1.6598600, 54.9320000], 
            [-1.6588100, 54.9325500], 
            [-1.6578900, 54.9329400], 
            [-1.6570700, 54.9331900], 
            [-1.6563700, 54.9333600], 
            [-1.6553400, 54.9337300], 
            [-1.6550400, 54.9339700], 
            [-1.6543800, 54.9345600], 
            [-1.6540900, 54.9346900], 
            [-1.6536500, 54.9347900], 
            [-1.6532100, 54.9348400], 
            [-1.6530800, 54.9348800], 
            [-1.6521900, 54.9352900], 
            [-1.6515600, 54.9355300], 
            [-1.6511700, 54.9357000], 
            [-1.6501500, 54.9363500], 
            [-1.6496500, 54.9365700], 
            [-1.6487800, 54.9368000], 
            [-1.6479800, 54.9370400], 
            [-1.6471200, 54.9374300], 
            [-1.6466000, 54.9377200], 
            [-1.6457700, 54.9380950], 
            [-1.6449400, 54.9384700], 
            [-1.6441000, 54.9387700], 
            [-1.6434900, 54.9389500], 
            [-1.6430100, 54.9390600], 
            [-1.6418200, 54.9393300], 
            [-1.6413100, 54.9393300], 
            [-1.6406800, 54.9398200], 
            [-1.6404300, 54.9400700], 
            [-1.6399900, 54.9405700], 
            [-1.6397000, 54.9410900], 
            [-1.6394100, 54.9416100], 
            [-1.6393100, 54.9424900], 
            [-1.6394050, 54.9430300], 
            [-1.6395000, 54.9435700], 
            [-1.6395500, 54.9438800], 
            [-1.6396300, 54.9445900], 
            [-1.6395800, 54.9449400], 
            [-1.6393100, 54.9454500], 
            [-1.6391000, 54.9459400], 
            [-1.6388900, 54.9464300], 
            [-1.6390500, 54.9467300], 
            [-1.6396600, 54.9473000], 
            [-1.6392100, 54.9479200], 
            [-1.6386850, 54.9487050], 
            [-1.6381600, 54.9494900], 
            [-1.6372400, 54.9492100], 
            [-1.6364000, 54.9498100], 
            [-1.6364100, 54.9498000], 
            [-1.6369650, 54.9501250], 
            [-1.6375200, 54.9504500], 
            [-1.6378500, 54.9508600], 
            [-1.6380300, 54.9513500], 
            [-1.6381400, 54.9515700], 
            [-1.6384000, 54.9519600], 
            [-1.6386000, 54.9523100], 
            [-1.6386300, 54.9526300], 
            [-1.6386000, 54.9528600], 
            [-1.6385600, 54.9529200], 
            [-1.6385600, 54.9529100], 
            [-1.6381300, 54.9537500], 
            [-1.6377100, 54.9543600], 
            [-1.6371500, 54.9548700], 
            [-1.6371100, 54.9549800], 
            [-1.6371100, 54.9557500], 
            [-1.6370900, 54.9559000], 
            [-1.6371400, 54.9562400], 
            [-1.6372100, 54.9563900], 
            [-1.6373100, 54.9565000], 
            [-1.6374900, 54.9566800], 
            [-1.6375500, 54.9567800], 
            [-1.6375500, 54.9569600], 
            [-1.6375200, 54.9570200], 
            [-1.6374100, 54.9570700], 
            [-1.6371000, 54.9571600], 
            [-1.6368100, 54.9571600], 
            [-1.6364100, 54.9571400], 
            [-1.6355200, 54.9569000], 
            [-1.6348400, 54.9567500], 
            [-1.6341700, 54.9566500], 
            [-1.6335800, 54.9566100], 
            [-1.6331400, 54.9566200], 
            [-1.6326300, 54.9566800], 
            [-1.6319700, 54.9568000], 
            [-1.6311100, 54.9570100], 
            [-1.6303600, 54.9572900], 
            [-1.6301400, 54.9575900], 
            [-1.6291800, 54.9578800], 
            [-1.6289800, 54.9578500], 
            [-1.6286000, 54.9579600], 
            [-1.6285700, 54.9581000], 
            [-1.6274500, 54.9584000], 
            [-1.6272500, 54.9583700], 
            [-1.6271700, 54.9583100], 
            [-1.6258867, 54.9587100], 
            [-1.6246034, 54.9591100], 
            [-1.6233200, 54.9595100], 
            [-1.6225200, 54.9597400], 
            [-1.6223500, 54.9597200], 
            [-1.6213000, 54.9600100], 
            [-1.6213100, 54.9600100], 
            [-1.6223500, 54.9597200], 
            [-1.6225200, 54.9597400], 
            [-1.6212400, 54.9601000], 
            [-1.6199600, 54.9604600], 
            [-1.6189175, 54.9608400], 
            [-1.6178750, 54.9612200], 
            [-1.6168325, 54.9616000], 
            [-1.6157900, 54.9619800], 
            [-1.6150100, 54.9623400], 
            [-1.6148400, 54.9624400], 
            [-1.6143350, 54.9628750], 
            [-1.6138300, 54.9633100], 
            [-1.6131600, 54.9637200], 
            [-1.6124000, 54.9641600], 
            [-1.6116400, 54.9646000], 
            [-1.6108800, 54.9650400], 
            [-1.6106900, 54.9650300], 
            [-1.6105300, 54.9649700], 
            [-1.6103000, 54.9651500], 
            [-1.6099900, 54.9653300], 
            [-1.6096200, 54.9654700], 
            [-1.6087700, 54.9656900], 
            [-1.6079700, 54.9660400], 
            [-1.6072800, 54.9663700], 
            [-1.6069500, 54.9665700], 
            [-1.6068000, 54.9666900], 
            [-1.6068100, 54.9666900], 
            [-1.6067100, 54.9667700], 
            [-1.6065700, 54.9667300], 
            [-1.6058800, 54.9667500], 
            [-1.6058900, 54.9668600], 
            [-1.6058000, 54.9669200], 
            [-1.6049300, 54.9672700], 
            [-1.6044300, 54.9675200], 
            [-1.6039300, 54.9677300], 
            [-1.6036200, 54.9678200], 
            [-1.6030900, 54.9679400], 
            [-1.6026000, 54.9681300], 
            [-1.6018900, 54.9682900], 
            [-1.6005400, 54.9685300], 
            [-1.6006000, 54.9686300], 
            [-1.5999000, 54.9687600], 
            [-1.5998700, 54.9687900], 
            [-1.5998300, 54.9689000], 
            [-1.5997700, 54.9689500], 
            [-1.5994000, 54.9691200], 
            [-1.5994100, 54.9691300], 
            [-1.5993500, 54.9691700], 
            [-1.5991800, 54.9694200], 
            [-1.5991500, 54.9696300], 
            [-1.5991800, 54.9697900], 
            [-1.5992800, 54.9699000], 
            [-1.5993800, 54.9699700], 
            [-1.5997600, 54.9701200], 
            [-1.6002000, 54.9702000], 
            [-1.6003700, 54.9701600]            
        ]
    }
};