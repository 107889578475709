export const blaydonBurnWest = {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Dusty Gravel 😎</br> Blaydon Burn West'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.7663800, 54.9482600],
          [-1.7663900, 54.9482800],
          [-1.7658400, 54.9482400],
          [-1.7643650, 54.9484250],
          [-1.7628900, 54.9486100],
          [-1.7626200, 54.9486900],
          [-1.7626200, 54.9487000],
          [-1.7624500, 54.9489100],
          [-1.7623500, 54.9496000],
          [-1.7622500, 54.9502900],
          [-1.7621600, 54.9504000],
          [-1.7621500, 54.9504100],
          [-1.7614200, 54.9507300],
          [-1.7606900, 54.9510500],
          [-1.7593800, 54.9514900],
          [-1.7589600, 54.9515300],
          [-1.7586200, 54.9516100],
          [-1.7581800, 54.9516000],
          [-1.7580200, 54.9516200],
          [-1.7577100, 54.9517700],
          [-1.7576400, 54.9518500],
          [-1.7574700, 54.9520900],
          [-1.7570900, 54.9521900],
          [-1.7568600, 54.9522700],
          [-1.7563500, 54.9525400],
          [-1.7561400, 54.9526700],
          [-1.7554300, 54.9529600],
          [-1.7547200, 54.9532500],
          [-1.7536700, 54.9536000],
          [-1.7525000, 54.9536100],
          [-1.7513300, 54.9536200],
          [-1.7500100, 54.9537700],
          [-1.7495100, 54.9538800],
          [-1.7493400, 54.9539600],
          [-1.7492600, 54.9541200],
          [-1.7491300, 54.9545500],
          [-1.7488900, 54.9547500],
          [-1.7478400, 54.9547900],
          [-1.7469500, 54.9550600]
        ]
    }
};