export const risingSun = {
  type: 'Feature',
  properties: {
    color: '#481D24',
    description: 'Dirt 🤗</br> Rising Sun Country Park',
  },
  geometry: {
    type: 'LineString',
    coordinates: [
      [-1.5418, 55.00975],
      [-1.54173, 55.00989],
      [-1.5415267, 55.0106467],
      [-1.5413233, 55.0114033],
      [-1.54112, 55.01216],
      [-1.54079, 55.0127],
      [-1.540515, 55.01337],
      [-1.54024, 55.01404],
      [-1.54015, 55.01462],
      [-1.54006, 55.01468],
      [-1.53984, 55.01444],
      [-1.53982, 55.01438],
      [-1.539415, 55.013735],
      [-1.53901, 55.01309],
      [-1.53876, 55.01271],
      [-1.53859, 55.01255],
      [-1.53808, 55.01231],
      [-1.53785, 55.01186],
      [-1.53777, 55.01163],
      [-1.53774, 55.01131],
      [-1.53798, 55.01084],
      [-1.53746, 55.01002],
      [-1.5372, 55.00984],
      [-1.53683, 55.0097],
      [-1.5367, 55.00967],
      [-1.53632, 55.00961],
      [-1.53588, 55.00957],
      [-1.53546, 55.00959],
      [-1.53431, 55.0097],
      [-1.53397, 55.00965],
      [-1.53367, 55.00946],
      [-1.53322, 55.00919],
      [-1.53329, 55.0088],
      [-1.53332, 55.00832],
      [-1.53332, 55.00831],
      [-1.53336, 55.00764],
      [-1.53343, 55.00726],
      [-1.53337, 55.00714],
      [-1.53431, 55.00718],
      [-1.53499, 55.00731],
      [-1.53577, 55.00754],
      [-1.53641, 55.00785],
      [-1.5364, 55.00785],
      [-1.53716, 55.00824],
      [-1.5376, 55.0083],
      [-1.53849, 55.00832],
      [-1.53892, 55.00837],
      [-1.53966, 55.00858],
      [-1.53996, 55.00872],
      [-1.53977, 55.00863],
      [-1.53996, 55.00872],
      [-1.54047, 55.00866],
      [-1.54009, 55.00922],
      [-1.54008, 55.00926],
      [-1.53987, 55.0096],
      [-1.5392, 55.01008],
      [-1.53827, 55.01064],
      [-1.53828, 55.01064],
      [-1.53798, 55.01084],
      [-1.53774, 55.01131],
      [-1.53777, 55.01163],
      [-1.53785, 55.01186],
      [-1.53804, 55.01172],
      [-1.53805, 55.01172],
      [-1.53865, 55.01128],
      [-1.53976, 55.01072],
      [-1.540665, 55.01034],
      [-1.54157, 55.00996],
      [-1.54157, 55.00995],
      [-1.54173, 55.00989],
      [-1.5418, 55.00975],
      [-1.54187, 55.00957],
      [-1.54183, 55.00942],
      [-1.54155, 55.00875],
      [-1.54127, 55.00808],
      [-1.54122, 55.00817],
      [-1.54092, 55.00845],
      [-1.54069, 55.00859],
      [-1.54047, 55.00866],
      [-1.53996, 55.00872],
      [-1.53848, 55.00886],
      [-1.53787, 55.00907],
      [-1.53746, 55.00916],
      [-1.53722, 55.00916],
      [-1.53687, 55.00906],
      [-1.53688, 55.00907],
      [-1.53639, 55.00895],
      [-1.53618, 55.00886],
      [-1.53573, 55.00851],
      [-1.53549, 55.00847],
      [-1.535, 55.00847],
      [-1.53353, 55.00849],
      [-1.53205, 55.00844],
      [-1.53082, 55.00854],
      [-1.52964, 55.00866],
      [-1.52882, 55.00862],
      [-1.5276333, 55.00869],
      [-1.5264467, 55.00876],
      [-1.52526, 55.00883],
      [-1.52504, 55.00886],
      [-1.52505, 55.00886],
      [-1.52417, 55.00899],
      [-1.52341, 55.009125],
      [-1.52265, 55.00926],
      [-1.52264, 55.00931],
      [-1.52268, 55.00937],
      [-1.52261, 55.0095],
      [-1.52223, 55.0101],
      [-1.52196, 55.01053],
      [-1.5216, 55.01086],
      [-1.52144, 55.01103],
      [-1.52122, 55.01116],
      [-1.52113, 55.01122],
      [-1.52108, 55.01132],
      [-1.52111, 55.01138],
      [-1.52163, 55.01163],
      [-1.52167, 55.01167],
      [-1.52172, 55.01172],
      [-1.5217, 55.01177],
      [-1.52166, 55.01181],
      [-1.5228119, 55.012318],
      [-1.5239639, 55.012826],
      [-1.5251159, 55.013334],
      [-1.5262679, 55.013842],
      [-1.52742, 55.01435],
      [-1.527935, 55.01363],
      [-1.52845, 55.01291],
      [-1.52859, 55.01275],
      [-1.52867, 55.01267],
      [-1.52882, 55.0127],
      [-1.52895, 55.01271],
      [-1.52904, 55.0127],
      [-1.5291, 55.01269],
      [-1.52923, 55.01264],
      [-1.52937, 55.01258],
      [-1.52936, 55.01256],
      [-1.53, 55.01272],
      [-1.53085, 55.013],
      [-1.53169, 55.01331],
      [-1.53288, 55.01371],
      [-1.5338, 55.01385],
      [-1.53316, 55.01454],
      [-1.532605, 55.01507],
      [-1.53205, 55.0156],
      [-1.53149, 55.01621],
      [-1.53205, 55.0156],
      [-1.532605, 55.01507],
      [-1.53316, 55.01454],
      [-1.5338, 55.01385],
      [-1.534, 55.01386],
      [-1.53398, 55.01375],
      [-1.53404, 55.01371],
      [-1.53427, 55.01368],
      [-1.53556, 55.01341],
      [-1.53649, 55.01328],
      [-1.53696, 55.01316],
      [-1.53697, 55.01316],
      [-1.53772, 55.01289],
      [-1.53787, 55.01281],
      [-1.53823, 55.01257],
      [-1.53834, 55.01242],
      [-1.53872, 55.01201],
      [-1.53929, 55.01155],
      [-1.53943, 55.01139],
      [-1.53963, 55.01093],
      [-1.53963, 55.01092],
      [-1.53976, 55.01072],
      [-1.540745, 55.010305],
      [-1.54173, 55.00989],
      [-1.5415267, 55.0106467],
      [-1.5413233, 55.0114033],
      [-1.54112, 55.01216],
      [-1.54079, 55.0127],
      [-1.540515, 55.01337],
      [-1.54024, 55.01404],
      [-1.54015, 55.01462],
      [-1.54006, 55.01468],
      [-1.54008, 55.0148],
      [-1.54016, 55.01483],
      [-1.54008, 55.0148],
      [-1.53928, 55.014665],
      [-1.53848, 55.01453],
      [-1.53788, 55.01449],
      [-1.53757, 55.01446],
      [-1.536725, 55.01433],
      [-1.53588, 55.0142],
      [-1.53479, 55.01398],
      [-1.534, 55.01386],
      [-1.53479, 55.01398],
      [-1.53588, 55.0142],
      [-1.536725, 55.01433],
      [-1.53757, 55.01446],
      [-1.53788, 55.01449],
      [-1.53848, 55.01453],
      [-1.53928, 55.014665],
      [-1.54008, 55.0148],
      [-1.54147, 55.01521],
      [-1.54214, 55.01535],
      [-1.54276, 55.01543],
      [-1.54353, 55.01537],
      [-1.54509, 55.01529],
      [-1.54574, 55.01528],
      [-1.54576, 55.01535],
      [-1.54621, 55.01565],
      [-1.54618, 55.01587],
      [-1.54607, 55.01613],
      [-1.545425, 55.016555],
      [-1.54478, 55.01698],
      [-1.54399, 55.01748],
      [-1.5432, 55.01798],
      [-1.54321, 55.01798],
      [-1.54241, 55.01849],
      [-1.54161, 55.019],
      [-1.54081, 55.01951],
      [-1.54001, 55.02002],
    ],
  },
};
