export const southRisingSun =     {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Dusty Gravel 😎</br> South Rising Sun'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
            [-1.5275000, 54.9970000],
            [-1.5274100, 54.9970500],
            [-1.5271900, 54.9972300],
            [-1.5271000, 54.9973700],
            [-1.5270000, 54.9975100],
            [-1.5269000, 54.9979300],
            [-1.5268900, 54.9981400],
            [-1.5266500, 54.9986700],
            [-1.5265900, 54.9991600],
            [-1.5265600, 54.9994400],
            [-1.5265600, 54.9997700],
            [-1.5265800, 55.0000500],
            [-1.5266500, 55.0004000],
            [-1.5266300, 55.0004700],
            [-1.5265600, 55.0005900],
            [-1.5262700, 55.0007800],
            [-1.5260100, 55.0009300],
            [-1.5259100, 55.0009700],
            [-1.5249800, 55.0013400],
            [-1.5244300, 55.0018000],
            [-1.5237900, 55.0024400],
            [-1.5239500, 55.0027300],
            [-1.5240600, 55.0028600],
            [-1.5240900, 55.0028800],
            [-1.5241300, 55.0030000],
            [-1.5242000, 55.0031200],
            [-1.5242000, 55.0031800],
            [-1.5241800, 55.0034400],
            [-1.5241100, 55.0035200]
        ]
    }
};