export const chopwellDerwentWalk =     {
    'type': 'Feature',
    'properties': {
        'color': '#481D24',
        'description': 'Packed Earth 🤗</br> Chopwell to Derwent Walk'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.7757500, 54.9287700],
          [-1.7753300, 54.9289900],
          [-1.7743100, 54.9291400],
          [-1.7740700, 54.9293000],
          [-1.7731600, 54.9296000],
          [-1.7723000, 54.9296800],
          [-1.7714400, 54.9297600],
          [-1.7702800, 54.9298200],
          [-1.7692600, 54.9297700],
          [-1.7680800, 54.9293500],
          [-1.7670650, 54.9289800],
          [-1.7660500, 54.9286100],
          [-1.7653000, 54.9283250],
          [-1.7645500, 54.9280400],
          [-1.7633067, 54.9279633],
          [-1.7620633, 54.9278867],
          [-1.7608200, 54.9278100],
          [-1.7599250, 54.9277000],
          [-1.7590300, 54.9275900],
          [-1.7583150, 54.9273950],
          [-1.7576000, 54.9272000],
          [-1.7564900, 54.9268600],
          [-1.7556750, 54.9265800],
          [-1.7548600, 54.9263000],
          [-1.7540400, 54.9261300],
          [-1.7535900, 54.9258600],
          [-1.7532100, 54.9253000],
          [-1.7528300, 54.9247400],
          [-1.7525900, 54.9245500],
          [-1.7518400, 54.9241300],
          [-1.7508333, 54.9237300],
          [-1.7498266, 54.9233300],
          [-1.7488200, 54.9229300],
          [-1.7481500, 54.9225000],
          [-1.7474800, 54.9220700],
          [-1.7469000, 54.9222000],
          [-1.7464900, 54.9218900],
          [-1.7461000, 54.9216900],
          [-1.7448000, 54.9219300],
          [-1.7435000, 54.9221700],
          [-1.7422000, 54.9224100],
          [-1.7420400, 54.9225900],
          [-1.7407900, 54.9226700],
          [-1.7405600, 54.9231450],
          [-1.7403300, 54.9236200],
          [-1.7405500, 54.9239800],
          [-1.7404200, 54.9240900],
        ]
    }
};