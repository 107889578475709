export const tanfield =     {
    'type': 'Feature',
    // Tanfield Way (WaggonWay)
    'properties': {
        'color': '#CB3709',
        'description': 'Waggon Way 👌</br> Tanfield Waggon Way'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
            [-1.6388900, 54.9464300],
            [-1.6391000, 54.9459400],
            [-1.6393100, 54.9454500],
            [-1.6395800, 54.9449400],
            [-1.6396300, 54.9445900],
            [-1.6395500, 54.9438800],
            [-1.6394300, 54.9431850],
            [-1.6393100, 54.9424900],
            [-1.6394100, 54.9416100],
            [-1.6397000, 54.9410900],
            [-1.6399900, 54.9405700],
            [-1.6404300, 54.9400700],
            [-1.6406800, 54.9398200],
            [-1.6413100, 54.9393300],
            [-1.6416300, 54.9391800],
            [-1.6423500, 54.9389650],
            [-1.6430700, 54.9387500],
            [-1.6437200, 54.9385200],
            [-1.6445733, 54.9381733],
            [-1.6454267, 54.9378267],
            [-1.6462800, 54.9374800],
            [-1.6466000, 54.9377200],
            [-1.6471200, 54.9374300],
            [-1.6479800, 54.9370400],
            [-1.6487800, 54.9368000],
            [-1.6496500, 54.9365700],
            [-1.6501500, 54.9363500],
            [-1.6511700, 54.9357000],
            [-1.6515600, 54.9355300],
            [-1.6521900, 54.9352900],
            [-1.6530800, 54.9348800],
            [-1.6532100, 54.9348400],
            [-1.6536500, 54.9347900],
            [-1.6540900, 54.9346900],
            [-1.6543800, 54.9345600],
            [-1.6550400, 54.9339700],
            [-1.6553400, 54.9337300],
            [-1.6563700, 54.9333600],
            [-1.6570700, 54.9331900],
            [-1.6578900, 54.9329400],
            [-1.6588100, 54.9325500],
            [-1.6598600, 54.9320000],
            [-1.6602000, 54.9317800],
            [-1.6604400, 54.9316800],
            [-1.6606400, 54.9316800],
            [-1.6608700, 54.9317300],
            [-1.6610100, 54.9317000],
            [-1.6610600, 54.9315300],
            [-1.6609700, 54.9314000],
            [-1.6609700, 54.9312500],
            [-1.6616300, 54.9308350],
            [-1.6622900, 54.9304200],
            [-1.6623000, 54.9304200],
            [-1.6632244, 54.9298200],
            [-1.6641487, 54.9292200],
            [-1.6650730, 54.9286200],
            [-1.6659973, 54.9280200],
            [-1.6669216, 54.9274200],
            [-1.6678458, 54.9268200],
            [-1.6687700, 54.9262200],
            [-1.6689700, 54.9260600],
            [-1.6693700, 54.9256500],
            [-1.6695800, 54.9255200],
            [-1.6701050, 54.9250150],
            [-1.6706300, 54.9245100],
            [-1.6713200, 54.9238700],
            [-1.6720100, 54.9232300],
            [-1.6727000, 54.9225900],
            [-1.6734950, 54.9218950],
            [-1.6742900, 54.9212000],
            [-1.6747700, 54.9208400],
            [-1.6750400, 54.9207000],
            [-1.6752800, 54.9205400],
            [-1.6757500, 54.9201500],
            [-1.6761900, 54.9196600],
            [-1.6762900, 54.9195100],
            [-1.6765000, 54.9190100],
            [-1.6767100, 54.9183800],
            [-1.6765400, 54.9183300],
            [-1.6765400, 54.9182000],
            [-1.6765700, 54.9181000],
            [-1.6766400, 54.9179900],
            [-1.6767000, 54.9178500],
            [-1.6767300, 54.9176600],
            [-1.6768300, 54.9175800],
            [-1.6768700, 54.9175100],
            [-1.6770400, 54.9170900],
            [-1.6770400, 54.9167200],
            [-1.6770700, 54.9165900],
            [-1.6773400, 54.9161100],
            [-1.6774100, 54.9158600],
            [-1.6775100, 54.9156700],
            [-1.6776200, 54.9152800],
            [-1.6776200, 54.9151700],
            [-1.6775800, 54.9150300],
            [-1.6775400, 54.9148500],
            [-1.6775800, 54.9145000],
            [-1.6776100, 54.9144000],
            [-1.6777100, 54.9142500],
            [-1.6777500, 54.9140900],
            [-1.6778900, 54.9134500],
            [-1.6778800, 54.9132800],
            [-1.6778200, 54.9130900],
            [-1.6776100, 54.9127000],
            [-1.6772100, 54.9123800],
            [-1.6770000, 54.9121900],
            [-1.6769400, 54.9120000],
            [-1.6768000, 54.9117900],
            [-1.6767600, 54.9116600],
            [-1.6766000, 54.9114800],
            [-1.6764000, 54.9113300],
            [-1.6761300, 54.9111800],
            [-1.6760900, 54.9111300],
            [-1.6760500, 54.9108300],
            [-1.6759900, 54.9106300],
            [-1.6758900, 54.9104900],
            [-1.6747600, 54.9104000],
            [-1.6747100, 54.9103600],
            [-1.6748700, 54.9098000],
            [-1.6748700, 54.9097600],
            [-1.6747700, 54.9097500]
        ]
    }
};