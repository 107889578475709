export const keelmansWayWest = {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Dusty Gravel 😎</br> Keelman\'s Way'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.7444500, 54.9802300],
          [-1.7445500, 54.9802900],
          [-1.7455700, 54.9806200],
          [-1.7464150, 54.9808650],
          [-1.7472600, 54.9811100],
          [-1.7482600, 54.9813400],
          [-1.7492600, 54.9815700],
          [-1.7503500, 54.9817400],
          [-1.7515000, 54.9818400],
          [-1.7522900, 54.9818900],
          [-1.7531000, 54.9818800],
          [-1.7541000, 54.9818400],
          [-1.7547200, 54.9817900],
          [-1.7557500, 54.9816500],
          [-1.7567350, 54.9814550],
          [-1.7577200, 54.9812600],
          [-1.7584900, 54.9811050],
          [-1.7592600, 54.9809500],
          [-1.7600100, 54.9807500],
          [-1.7604500, 54.9807000],
          [-1.7615100, 54.9804600],
          [-1.7623850, 54.9803200],
          [-1.7632600, 54.9801800],
          [-1.7642100, 54.9800700],
          [-1.7648800, 54.9800300],
          [-1.7655100, 54.9799800],
          [-1.7656500, 54.9800000],
          [-1.7661100, 54.9800300],
          [-1.7662800, 54.9799400],
          [-1.7669900, 54.9800200],
          [-1.7682100, 54.9801000],
          [-1.7694300, 54.9801800],
          [-1.7706200, 54.9802800],
          [-1.7716000, 54.9803300],
          [-1.7723500, 54.9804100],
          [-1.7734600, 54.9804700],
          [-1.7742100, 54.9805600],
          [-1.7752600, 54.9807800],
          [-1.7759700, 54.9809100],
          [-1.7760700, 54.9809600],
          [-1.7768200, 54.9812000],
          [-1.7775700, 54.9814400],
          [-1.7788550, 54.9819150],
          [-1.7801400, 54.9823900],
          [-1.7804200, 54.9824200],
          [-1.7806500, 54.9825000],
          [-1.7808500, 54.9826200],
          [-1.7815950, 54.9828700],
          [-1.7823400, 54.9831200],
          [-1.7830750, 54.9833150],
          [-1.7838100, 54.9835100],
          [-1.7850000, 54.9837900],
          [-1.7861500, 54.9838900],
          [-1.7866200, 54.9839000],
          [-1.7881100, 54.9838800],
          [-1.7892300, 54.9837500],
          [-1.7892400, 54.9837500],
          [-1.7895000, 54.9837200],
          [-1.7906850, 54.9833950],
          [-1.7918700, 54.9830700],
          [-1.7921400, 54.9829800],
          [-1.7929900, 54.9826050],
          [-1.7938400, 54.9822300],
          [-1.7946967, 54.9818133],
          [-1.7955534, 54.9813967],
          [-1.7964100, 54.9809800],
          [-1.7966800, 54.9808100],
          [-1.7972200, 54.9803700],
          [-1.7975000, 54.9801700],
          [-1.7981400, 54.9799600],
          [-1.7985500, 54.9797500],
          [-1.7991300, 54.9794300],
          [-1.7995100, 54.9791900],
          [-1.7995200, 54.9791900],
          [-1.7998700, 54.9789700],
          [-1.8002800, 54.9786900],
          [-1.8005800, 54.9786300],
          [-1.8012600, 54.9784500],
          [-1.8016300, 54.9783200],
          [-1.8023600, 54.9781000],
          [-1.8030700, 54.9779200],
          [-1.8035700, 54.9777900],
          [-1.8038700, 54.9776800],
          [-1.8045400, 54.9775900],
          [-1.8049600, 54.9775200],
          [-1.8054900, 54.9774200],
          [-1.8059700, 54.9772700],
          [-1.8067750, 54.9770850],
          [-1.8075800, 54.9769000],
          [-1.8078900, 54.9767900],
          [-1.8081500, 54.9767500],
          [-1.8091000, 54.9764100],
          [-1.8094500, 54.9763200],
          [-1.8097800, 54.9762600],
          [-1.8110500, 54.9758900],
          [-1.8115100, 54.9758600],
          [-1.8120800, 54.9756800],
          [-1.8125200, 54.9755500],
          [-1.8126700, 54.9754400],
          [-1.8127100, 54.9753800],
          [-1.8132000, 54.9752400],
          [-1.8134400, 54.9752800]
        ]
    }
};