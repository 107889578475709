export const intoChopwellWest = {
  type: 'Feature',
  properties: {
    color: '#481D24',
    description: 'Packed Earth 🤗</br> into Chopwell Woods',
  },
  geometry: {
    type: 'LineString',
    coordinates: [
      [-1.81068, 54.92113],
      [-1.81065, 54.92108],
      [-1.81065, 54.92109],
      [-1.81031, 54.92078],
      [-1.8088, 54.920735],
      [-1.80729, 54.92069],
      [-1.8059, 54.92058],
      [-1.80451, 54.92047],
      [-1.8044, 54.92048],
      [-1.80345, 54.920465],
      [-1.8025, 54.92045],
      [-1.80132, 54.92046],
    ],
  },
};
