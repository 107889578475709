export const risingSunWest = {
  type: 'Feature',
  properties: {
    color: '#C70039',
    description: 'Dusty Gravel 😎</br> Rising Sun West',
  },
  geometry: {
    type: 'LineString',
    coordinates: [
      [-1.56243, 55.01061],
      [-1.561625, 55.01049],
      [-1.56082, 55.01037],
      [-1.559795, 55.01034],
      [-1.55877, 55.01031],
      [-1.5584, 55.01034],
      [-1.557575, 55.01035],
      [-1.55675, 55.01036],
      [-1.5562, 55.01028],
      [-1.55567, 55.0101],
      [-1.55549, 55.01006],
      [-1.55488, 55.01],
      [-1.553835, 55.009945],
      [-1.55279, 55.00989],
      [-1.552005, 55.009875],
      [-1.55122, 55.00986],
      [-1.54972, 55.00978],
      [-1.54855, 55.00976],
      [-1.54849, 55.00976],
      [-1.54851, 55.00983],
      [-1.54833, 55.00985],
      [-1.5483, 55.00978],
      [-1.54741, 55.00986],
      [-1.54676, 55.00988],
      [-1.5455233, 55.0098567],
      [-1.5442867, 55.0098333],
      [-1.54305, 55.00981],
      [-1.54197, 55.00977],
      [-1.5418, 55.00975],
    ],
  },
};
