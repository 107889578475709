export const hadriansWayEast =     {
    'type': 'Feature',
    'properties': {
        'color': '#6A0DAD',
        'description': 'Paved Cycleway 😏</br>Hadrians Way East'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
            [-1.5839900, 54.9751700],
            [-1.5840100, 54.9750200],
            [-1.5838600, 54.9746700],
            [-1.5834900, 54.9740400],
            [-1.5832500, 54.9731700],
            [-1.5830100, 54.9723000],
            [-1.5828500, 54.9718600],
            [-1.5827100, 54.9712500],
            [-1.5826000, 54.9709000],
            [-1.5824100, 54.9704000],
            [-1.5821800, 54.9700400],
            [-1.5820700, 54.9699100],
            [-1.5814200, 54.9693200],
            [-1.5806500, 54.9688700],
            [-1.5801700, 54.9686600],
            [-1.5797400, 54.9684300],
            [-1.5792600, 54.9682700],
            [-1.5783100, 54.9680100],
            [-1.5773600, 54.9677500],
            [-1.5767800, 54.9676900],
            [-1.5761700, 54.9675600],
            [-1.5752300, 54.9673100],
            [-1.5749100, 54.9672800],
            [-1.5736200, 54.9671300],
            [-1.5722575, 54.9668075],
            [-1.5708950, 54.9664850],
            [-1.5695325, 54.9661625],
            [-1.5681700, 54.9658400],
            [-1.5671400, 54.9655800],
            [-1.5667500, 54.9653800],
            [-1.5655400, 54.9649500],
            [-1.5642500, 54.9645400],
            [-1.5629600, 54.9641300],
            [-1.5617850, 54.9637450],
            [-1.5606100, 54.9633600],
            [-1.5598300, 54.9629800],
            [-1.5589500, 54.9626000],
            [-1.5583400, 54.9622900],
            [-1.5576300, 54.9619900],
            [-1.5569500, 54.9617800],
            [-1.5557600, 54.9615300],
            [-1.5548800, 54.9614200],
            [-1.5541000, 54.9613450],
            [-1.5533200, 54.9612700],
            [-1.5517700, 54.9613900],
            [-1.5509200, 54.9614900],
            [-1.5501700, 54.9618400],
            [-1.5497000, 54.9619700],
            [-1.5486650, 54.9624100],
            [-1.5476300, 54.9628500],
            [-1.5469200, 54.9633400],
            [-1.5462100, 54.9638300],
            [-1.5453450, 54.9642500],
            [-1.5444800, 54.9646700],
            [-1.5444900, 54.9646700],
            [-1.5434900, 54.9651600],
            [-1.5429200, 54.9653300],
            [-1.5421500, 54.9657750],
            [-1.5413800, 54.9662200],
            [-1.5411300, 54.9665500],
            [-1.5409800, 54.9665900],
            [-1.5408500, 54.9666700],
            [-1.5406500, 54.9668700],
            [-1.5403200, 54.9672700],
            [-1.5403700, 54.9674000],
            [-1.5403700, 54.9674500],
            [-1.5402700, 54.9675700],
            [-1.5400700, 54.9679000],
            [-1.5398000, 54.9685100],
            [-1.5397550, 54.9690200],
            [-1.5397100, 54.9695300],
            [-1.5396600, 54.9697200],
            [-1.5396700, 54.9698200],
            [-1.5399200, 54.9700800],
            [-1.5398000, 54.9701600],
            [-1.5393300, 54.9705500],
            [-1.5392700, 54.9706300],
            [-1.5391200, 54.9713400],
            [-1.5392600, 54.9716900],
            [-1.5393000, 54.9721600],
            [-1.5393000, 54.9722800],
            [-1.5395700, 54.9725600],
            [-1.5396200, 54.9728400],
            [-1.5395900, 54.9733650],
            [-1.5395600, 54.9738900],
            [-1.5395300, 54.9741400],
            [-1.5392800, 54.9749000],
            [-1.5390300, 54.9756600],
            [-1.5389900, 54.9761300],
            [-1.5388600, 54.9762700],
            [-1.5387500, 54.9764400],
            [-1.5386500, 54.9768900],
            [-1.5388600, 54.9773700],
            [-1.5389800, 54.9774700],
            [-1.5389500, 54.9775700],
            [-1.5389900, 54.9780300],
            [-1.5388600, 54.9783500],
            [-1.5389800, 54.9787200],
            [-1.5391600, 54.9792400],
            [-1.5392900, 54.9796900],
            [-1.5393600, 54.9802000],
            [-1.5392200, 54.9809300],
            [-1.5390500, 54.9813800],
            [-1.5388700, 54.9817400],
            [-1.5384100, 54.9825100],
            [-1.5383700, 54.9826400],
            [-1.5383800, 54.9827400],
            [-1.5382000, 54.9830500],
            [-1.5380800, 54.9830600],
            [-1.5376400, 54.9832400],
            [-1.5368750, 54.9836100],
            [-1.5361100, 54.9839800],
            [-1.5355700, 54.9843300],
            [-1.5350450, 54.9846850],
            [-1.5345200, 54.9850400],
            [-1.5345300, 54.9850400],
            [-1.5335700, 54.9856900],
            [-1.5325300, 54.9863400],
            [-1.5314900, 54.9869900],
            [-1.5308500, 54.9874800],
            [-1.5305300, 54.9877000],
            [-1.5300400, 54.9881800],
            [-1.5298900, 54.9882600],
            [-1.5289600, 54.9886600],
            [-1.5280300, 54.9890600],
            [-1.5279800, 54.9891200],
            [-1.5279800, 54.9891900],
            [-1.5281200, 54.9893200],
            [-1.5281200, 54.9893400],
            [-1.5280400, 54.9893900],
            [-1.5275600, 54.9895600],
            [-1.5264800, 54.9898400],
            [-1.5253400, 54.9900700],
            [-1.5244500, 54.9900700],
            [-1.5239500, 54.9900400],
            [-1.5235000, 54.9899800],
            [-1.5230300, 54.9899800],
            [-1.5226800, 54.9898900],
            [-1.5224400, 54.9898700],
            [-1.5219400, 54.9899200],
            [-1.5214600, 54.9900200],
            [-1.5212600, 54.9900300],
            [-1.5208200, 54.9899600],
            [-1.5206500, 54.9899600],
            [-1.5203800, 54.9900300],
            [-1.5200700, 54.9901700],
            [-1.5196300, 54.9903100],
            [-1.5185900, 54.9906000],
            [-1.5184100, 54.9906000],
            [-1.5182500, 54.9906500],
            [-1.5180500, 54.9907600],
            [-1.5176000, 54.9909200],
            [-1.5170200, 54.9911600],
            [-1.5159100, 54.9917300],
            [-1.5155300, 54.9919000],
            [-1.5156500, 54.9920100],
            [-1.5152800, 54.9921500],
            [-1.5148900, 54.9922600],
            [-1.5142500, 54.9923700],
            [-1.5136300, 54.9924500],
            [-1.5131300, 54.9925500],
            [-1.5127200, 54.9926000],
            [-1.5124600, 54.9926800],
            [-1.5112400, 54.9928700],
            [-1.5103400, 54.9929200],
            [-1.5090700, 54.9930600],
            [-1.5090200, 54.9930900],
            [-1.5089200, 54.9932500],
            [-1.5086300, 54.9933500],
            [-1.5082500, 54.9934200],
            [-1.5081000, 54.9934300],
            [-1.5081100, 54.9934300],
            [-1.5076300, 54.9934700],
            [-1.5074300, 54.9934400],
            [-1.5070600, 54.9932700],
            [-1.5068700, 54.9934100],
            [-1.5067900, 54.9934500],
            [-1.5066800, 54.9934800],
            [-1.5054100, 54.9935700]
        ]
    }
};