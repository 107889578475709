import { derwentHounsgill } from './derwentHounsgill.js';
import { derwentTanfield } from './derwentTanfield.js';
import { hadriansRisingSun } from './hadriansRisingSun.js';
import { kibblesworth } from './kibblesworth.js';
import { risingSunKillingworthWaggon } from './risingSunKillingworthWaggon.js';
const links = [
    derwentHounsgill,
    derwentTanfield,
    hadriansRisingSun,
    kibblesworth,
    risingSunKillingworthWaggon
];

export default links;