export const stamfordhamRoadWest = {
    'type': 'Feature',
    'properties': {
        'color': '#0D1F22',
        'description': 'Bridleway 😎</br> Stamfordham Road West'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.8228200, 55.0072900],
          [-1.8239366, 55.0076300],
          [-1.8250533, 55.0079700],
          [-1.8261700, 55.0083100],
          [-1.8270700, 55.0084200],
          [-1.8279700, 55.0085300],
          [-1.8289800, 55.0089900],
          [-1.8294100, 55.0090900],
          [-1.8293900, 55.0090900],
          [-1.8302700, 55.0091100],
          [-1.8311900, 55.0090900],
          [-1.8320900, 55.0089850],
          [-1.8329900, 55.0088800],
          [-1.8332200, 55.0088800],
          [-1.8333900, 55.0089100],
          [-1.8335600, 55.0090900],
          [-1.8337300, 55.0091700],
          [-1.8339000, 55.0091900],
          [-1.8345800, 55.0091000],
          [-1.8349600, 55.0091300],
          [-1.8349500, 55.0091300],
          [-1.8351500, 55.0093000],
          [-1.8353200, 55.0098000],
          [-1.8354900, 55.0103000],
          [-1.8355800, 55.0104200],
          [-1.8358600, 55.0104600],
          [-1.8373833, 55.0103534],
          [-1.8389067, 55.0102467],
          [-1.8404300, 55.0101400]
        ]
    }
};