export const sunderlandBeamish =     {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Newcastle -> Sunderland -> Beamish -> Blaydon -> Newcastle</br><a href="https://www.strava.com/routes/3055228526986205370" target="_blank">Strava Route</a>'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
            [-1.5995700,54.9690400],
            [-1.5994000,54.9691200],
            [-1.5995600,54.9690500],
            [-1.5996100,54.9690900],
            [-1.5994100,54.9691300],
            [-1.5992100,54.9694500],
            [-1.5991800,54.9695200],
            [-1.5991800,54.9695700],
            [-1.5992100,54.9694500],
            [-1.5994000,54.9691200],
            [-1.5986650,54.9692800],
            [-1.5979300,54.9694400],
            [-1.5968850,54.9696000],
            [-1.5958400,54.9697600],
            [-1.5955900,54.9691800],
            [-1.5950100,54.9692800],
            [-1.5935600,54.9693500],
            [-1.5927900,54.9695200],
            [-1.5924100,54.9695900],
            [-1.5918100,54.9695700],
            [-1.5911500,54.9695200],
            [-1.5903350,54.9693750],
            [-1.5895200,54.9692300],
            [-1.5892200,54.9691500],
            [-1.5883000,54.9688300],
            [-1.5878300,54.9686300],
            [-1.5876400,54.9685200],
            [-1.5876000,54.9684600],
            [-1.5876300,54.9684100],
            [-1.5882100,54.9681000],
            [-1.5877700,54.9678400],
            [-1.5871100,54.9673400],
            [-1.5866550,54.9669600],
            [-1.5862000,54.9665800],
            [-1.5859600,54.9664000],
            [-1.5857100,54.9662800],
            [-1.5851300,54.9661500],
            [-1.5850800,54.9661600],
            [-1.5846100,54.9660700],
            [-1.5845000,54.9660300],
            [-1.5844100,54.9659800],
            [-1.5839300,54.9656200],
            [-1.5838700,54.9655300],
            [-1.5838600,54.9654600],
            [-1.5838300,54.9654000],
            [-1.5835200,54.9652200],
            [-1.5827800,54.9648200],
            [-1.5825100,54.9647100],
            [-1.5823000,54.9646500],
            [-1.5819600,54.9646200],
            [-1.5816700,54.9646300],
            [-1.5813900,54.9644400],
            [-1.5808800,54.9642000],
            [-1.5796700,54.9638200],
            [-1.5784100,54.9635600],
            [-1.5771500,54.9633000],
            [-1.5768100,54.9632600],
            [-1.5762700,54.9632400],
            [-1.5750200,54.9632400],
            [-1.5747200,54.9632600],
            [-1.5742300,54.9633500],
            [-1.5740100,54.9633200],
            [-1.5737900,54.9633500],
            [-1.5725100,54.9636200],
            [-1.5722400,54.9636400],
            [-1.5721200,54.9636100],
            [-1.5719400,54.9636400],
            [-1.5717500,54.9635900],
            [-1.5712800,54.9633300],
            [-1.5711100,54.9631300],
            [-1.5709000,54.9629400],
            [-1.5708300,54.9628500],
            [-1.5702700,54.9629900],
            [-1.5696900,54.9629600],
            [-1.5694700,54.9629700],
            [-1.5687900,54.9631900],
            [-1.5687300,54.9631900],
            [-1.5683700,54.9628700],
            [-1.5683600,54.9627200],
            [-1.5682000,54.9627000],
            [-1.5679300,54.9627300],
            [-1.5671200,54.9627650],
            [-1.5663100,54.9628000],
            [-1.5660000,54.9627700],
            [-1.5656300,54.9626900],
            [-1.5650800,54.9626300],
            [-1.5644800,54.9625000],
            [-1.5639200,54.9622900],
            [-1.5635000,54.9620700],
            [-1.5630250,54.9616900],
            [-1.5625500,54.9613100],
            [-1.5621700,54.9609800],
            [-1.5617300,54.9608600],
            [-1.5613900,54.9606900],
            [-1.5610200,54.9604400],
            [-1.5609100,54.9603400],
            [-1.5608600,54.9602300],
            [-1.5608500,54.9599400],
            [-1.5606100,54.9594800],
            [-1.5606100,54.9590300],
            [-1.5606650,54.9585150],
            [-1.5607200,54.9580000],
            [-1.5602000,54.9579700],
            [-1.5600100,54.9578800],
            [-1.5600700,54.9574500],
            [-1.5600300,54.9573200],
            [-1.5599400,54.9567800],
            [-1.5599100,54.9564300],
            [-1.5599500,54.9561500],
            [-1.5600700,54.9557100],
            [-1.5600700,54.9552900],
            [-1.5600200,54.9550300],
            [-1.5591500,54.9553100],
            [-1.5582800,54.9555900],
            [-1.5577000,54.9557200],
            [-1.5573200,54.9557800],
            [-1.5569600,54.9557800],
            [-1.5565400,54.9557500],
            [-1.5563500,54.9557800],
            [-1.5559500,54.9557200],
            [-1.5557600,54.9557500],
            [-1.5553700,54.9558900],
            [-1.5552000,54.9559300],
            [-1.5550300,54.9559100],
            [-1.5546800,54.9558100],
            [-1.5545200,54.9558100],
            [-1.5543300,54.9559000],
            [-1.5543700,54.9559800],
            [-1.5543700,54.9566700],
            [-1.5542000,54.9572800],
            [-1.5542400,54.9574300],
            [-1.5543400,54.9575500],
            [-1.5546900,54.9577300],
            [-1.5545400,54.9578100],
            [-1.5541800,54.9578800],
            [-1.5538500,54.9579600],
            [-1.5534300,54.9579200],
            [-1.5529500,54.9579100],
            [-1.5527100,54.9579400],
            [-1.5521400,54.9580500],
            [-1.5516300,54.9582100],
            [-1.5507500,54.9585400],
            [-1.5501400,54.9587900],
            [-1.5498700,54.9588600],
            [-1.5496300,54.9588900],
            [-1.5494600,54.9589500],
            [-1.5490500,54.9592800],
            [-1.5484100,54.9595000],
            [-1.5482400,54.9595000],
            [-1.5479700,54.9594700],
            [-1.5476600,54.9594100],
            [-1.5475100,54.9593500],
            [-1.5477000,54.9591200],
            [-1.5478100,54.9590100],
            [-1.5480400,54.9587400],
            [-1.5481400,54.9585500],
            [-1.5482900,54.9583800],
            [-1.5482800,54.9582900],
            [-1.5483400,54.9580900],
            [-1.5483400,54.9579800],
            [-1.5482400,54.9577500],
            [-1.5482200,54.9575400],
            [-1.5481200,54.9574500],
            [-1.5477700,54.9574700],
            [-1.5476100,54.9574400],
            [-1.5472500,54.9573500],
            [-1.5460900,54.9569600],
            [-1.5452600,54.9567600],
            [-1.5451400,54.9566000],
            [-1.5445100,54.9567400],
            [-1.5443700,54.9567500],
            [-1.5441700,54.9567200],
            [-1.5437300,54.9570200],
            [-1.5441700,54.9567200],
            [-1.5443700,54.9567500],
            [-1.5445100,54.9567400],
            [-1.5453000,54.9565600],
            [-1.5445100,54.9567400],
            [-1.5443700,54.9567500],
            [-1.5441700,54.9567200],
            [-1.5435100,54.9571700],
            [-1.5428500,54.9576200],
            [-1.5421200,54.9577800],
            [-1.5409800,54.9578900],
            [-1.5406800,54.9578700],
            [-1.5404400,54.9578000],
            [-1.5401100,54.9576400],
            [-1.5400700,54.9575100],
            [-1.5389967,54.9576567],
            [-1.5379233,54.9578033],
            [-1.5368500,54.9579500],
            [-1.5365100,54.9580100],
            [-1.5354200,54.9582500],
            [-1.5352000,54.9582900],
            [-1.5342300,54.9583700],
            [-1.5332600,54.9584500],
            [-1.5327800,54.9584600],
            [-1.5324100,54.9584000],
            [-1.5311100,54.9579900],
            [-1.5309100,54.9579400],
            [-1.5307700,54.9578800],
            [-1.5306800,54.9579400],
            [-1.5300400,54.9577300],
            [-1.5299000,54.9577400],
            [-1.5296100,54.9575900],
            [-1.5285500,54.9572700],
            [-1.5281100,54.9570100],
            [-1.5280100,54.9568400],
            [-1.5279700,54.9566100],
            [-1.5266325,54.9567325],
            [-1.5252950,54.9568550],
            [-1.5239575,54.9569775],
            [-1.5226200,54.9571000],
            [-1.5220300,54.9570300],
            [-1.5218700,54.9567900],
            [-1.5216500,54.9563000],
            [-1.5214300,54.9558100],
            [-1.5210200,54.9549900],
            [-1.5208500,54.9548600],
            [-1.5206000,54.9547800],
            [-1.5191150,54.9549050],
            [-1.5176300,54.9550300],
            [-1.5171900,54.9551000],
            [-1.5163700,54.9552000],
            [-1.5163100,54.9552900],
            [-1.5160200,54.9554400],
            [-1.5158500,54.9554600],
            [-1.5152400,54.9553700],
            [-1.5150500,54.9553800],
            [-1.5140100,54.9560300],
            [-1.5131100,54.9566633],
            [-1.5122100,54.9572967],
            [-1.5113100,54.9579300],
            [-1.5103400,54.9586033],
            [-1.5093700,54.9592767],
            [-1.5084000,54.9599500],
            [-1.5080100,54.9600600],
            [-1.5079400,54.9601200],
            [-1.5079400,54.9602200],
            [-1.5078300,54.9604300],
            [-1.5076700,54.9605200],
            [-1.5066900,54.9611900],
            [-1.5057100,54.9618600],
            [-1.5052000,54.9621500],
            [-1.5044600,54.9626400],
            [-1.5036467,54.9631933],
            [-1.5028334,54.9637467],
            [-1.5020200,54.9643000],
            [-1.5019600,54.9643100],
            [-1.5019500,54.9643100],
            [-1.5018000,54.9642900],
            [-1.5016300,54.9642100],
            [-1.5012300,54.9639300],
            [-1.5011700,54.9639300],
            [-1.5007900,54.9637300],
            [-1.5007300,54.9634700],
            [-1.5000700,54.9631000],
            [-1.5003100,54.9628500],
            [-1.5002500,54.9628300],
            [-1.4995200,54.9633100],
            [-1.4993400,54.9633900],
            [-1.4985200,54.9636200],
            [-1.4981800,54.9636900],
            [-1.4980100,54.9636900],
            [-1.4977100,54.9636300],
            [-1.4975100,54.9635400],
            [-1.4974400,54.9634800],
            [-1.4973300,54.9632800],
            [-1.4971350,54.9627700],
            [-1.4969400,54.9622600],
            [-1.4966100,54.9614900],
            [-1.4963800,54.9615700],
            [-1.4961900,54.9614800],
            [-1.4960100,54.9615600],
            [-1.4958600,54.9615200],
            [-1.4957700,54.9612800],
            [-1.4957400,54.9612100],
            [-1.4954000,54.9609400],
            [-1.4951300,54.9606600],
            [-1.4947900,54.9605200],
            [-1.4947300,54.9605500],
            [-1.4947500,54.9605700],
            [-1.4945400,54.9606000],
            [-1.4932133,54.9603933],
            [-1.4918867,54.9601867],
            [-1.4905600,54.9599800],
            [-1.4902400,54.9600200],
            [-1.4901400,54.9601700],
            [-1.4900800,54.9602100],
            [-1.4898900,54.9602700],
            [-1.4896400,54.9602900],
            [-1.4893600,54.9602200],
            [-1.4891900,54.9600800],
            [-1.4891600,54.9600000],
            [-1.4891300,54.9598300],
            [-1.4890900,54.9597100],
            [-1.4888700,54.9595300],
            [-1.4884800,54.9593000],
            [-1.4877100,54.9587400],
            [-1.4872600,54.9585400],
            [-1.4867600,54.9584700],
            [-1.4867700,54.9584700],
            [-1.4862800,54.9585000],
            [-1.4855550,54.9587000],
            [-1.4848300,54.9589000],
            [-1.4841300,54.9590100],
            [-1.4829550,54.9591650],
            [-1.4817800,54.9593200],
            [-1.4810300,54.9593700],
            [-1.4803800,54.9593800],
            [-1.4795100,54.9593600],
            [-1.4789400,54.9593300],
            [-1.4783600,54.9592000],
            [-1.4776800,54.9589500],
            [-1.4775500,54.9591500],
            [-1.4774000,54.9595000],
            [-1.4771800,54.9594800],
            [-1.4769900,54.9594800],
            [-1.4769100,54.9595200],
            [-1.4766500,54.9593400],
            [-1.4762800,54.9591800],
            [-1.4753300,54.9586400],
            [-1.4750300,54.9585100],
            [-1.4747600,54.9585300],
            [-1.4743200,54.9586700],
            [-1.4740100,54.9586800],
            [-1.4736400,54.9586500],
            [-1.4732300,54.9586700],
            [-1.4729900,54.9586900],
            [-1.4726200,54.9587600],
            [-1.4718500,54.9587300],
            [-1.4717700,54.9588900],
            [-1.4716700,54.9590100],
            [-1.4715000,54.9591100],
            [-1.4715400,54.9598800],
            [-1.4718700,54.9601800],
            [-1.4720100,54.9606400],
            [-1.4721500,54.9609700],
            [-1.4726500,54.9617300],
            [-1.4727200,54.9618000],
            [-1.4728400,54.9618700],
            [-1.4726400,54.9618900],
            [-1.4716000,54.9620500],
            [-1.4716000,54.9620300],
            [-1.4716400,54.9620200],
            [-1.4714700,54.9616400],
            [-1.4712000,54.9610100],
            [-1.4708900,54.9601600],
            [-1.4709000,54.9600400],
            [-1.4710600,54.9597200],
            [-1.4710650,54.9591550],
            [-1.4710700,54.9585900],
            [-1.4709200,54.9585800],
            [-1.4707900,54.9585500],
            [-1.4703300,54.9585400],
            [-1.4700800,54.9585800],
            [-1.4691650,54.9585450],
            [-1.4682500,54.9585100],
            [-1.4680600,54.9584800],
            [-1.4679300,54.9583300],
            [-1.4675000,54.9583500],
            [-1.4673400,54.9583200],
            [-1.4672300,54.9583900],
            [-1.4671300,54.9584000],
            [-1.4669600,54.9583100],
            [-1.4669600,54.9583800],
            [-1.4669300,54.9583800],
            [-1.4667600,54.9583300],
            [-1.4666900,54.9582100],
            [-1.4664200,54.9580000],
            [-1.4662300,54.9579800],
            [-1.4659400,54.9580400],
            [-1.4655100,54.9580500],
            [-1.4650700,54.9581100],
            [-1.4649300,54.9580600],
            [-1.4648100,54.9579700],
            [-1.4645200,54.9578400],
            [-1.4643900,54.9578300],
            [-1.4642800,54.9578400],
            [-1.4639900,54.9579200],
            [-1.4635400,54.9580200],
            [-1.4630300,54.9581800],
            [-1.4627000,54.9582400],
            [-1.4620800,54.9582900],
            [-1.4614500,54.9582800],
            [-1.4612000,54.9583100],
            [-1.4610000,54.9583000],
            [-1.4608900,54.9582500],
            [-1.4604500,54.9582400],
            [-1.4602200,54.9582800],
            [-1.4597500,54.9584000],
            [-1.4598700,54.9585300],
            [-1.4596700,54.9588900],
            [-1.4596400,54.9590200],
            [-1.4597300,54.9594200],
            [-1.4596000,54.9594100],
            [-1.4594000,54.9594500],
            [-1.4584300,54.9595000],
            [-1.4571800,54.9594400],
            [-1.4566700,54.9594800],
            [-1.4563900,54.9593800],
            [-1.4562600,54.9593600],
            [-1.4560500,54.9593600],
            [-1.4556400,54.9593200],
            [-1.4554000,54.9594000],
            [-1.4552700,54.9594000],
            [-1.4545500,54.9592200],
            [-1.4543200,54.9592000],
            [-1.4538800,54.9592300],
            [-1.4537900,54.9592700],
            [-1.4536300,54.9594400],
            [-1.4533400,54.9596800],
            [-1.4528300,54.9597300],
            [-1.4523700,54.9596700],
            [-1.4523800,54.9597800],
            [-1.4521100,54.9597800],
            [-1.4516800,54.9597000],
            [-1.4516400,54.9596800],
            [-1.4509600,54.9595300],
            [-1.4511900,54.9591000],
            [-1.4504100,54.9589100],
            [-1.4494150,54.9586550],
            [-1.4484200,54.9584000],
            [-1.4482200,54.9584000],
            [-1.4480000,54.9584800],
            [-1.4479800,54.9585100],
            [-1.4478200,54.9587700],
            [-1.4475450,54.9593050],
            [-1.4472700,54.9598400],
            [-1.4468300,54.9597500],
            [-1.4455700,54.9593900],
            [-1.4447300,54.9592400],
            [-1.4445700,54.9591600],
            [-1.4444900,54.9589400],
            [-1.4443900,54.9582000],
            [-1.4443200,54.9579700],
            [-1.4442800,54.9576900],
            [-1.4439800,54.9574800],
            [-1.4438400,54.9572700],
            [-1.4436900,54.9571800],
            [-1.4431300,54.9570700],
            [-1.4430300,54.9570000],
            [-1.4418800,54.9569233],
            [-1.4407300,54.9568467],
            [-1.4395800,54.9567700],
            [-1.4382500,54.9564000],
            [-1.4376800,54.9557700],
            [-1.4373400,54.9557400],
            [-1.4372300,54.9556300],
            [-1.4371300,54.9554400],
            [-1.4370700,54.9552200],
            [-1.4355600,54.9554300],
            [-1.4354400,54.9554600],
            [-1.4345100,54.9555000],
            [-1.4342200,54.9555500],
            [-1.4328600,54.9556000],
            [-1.4314250,54.9556350],
            [-1.4299900,54.9556700],
            [-1.4285700,54.9556800],
            [-1.4274600,54.9557150],
            [-1.4263500,54.9557500],
            [-1.4252550,54.9560750],
            [-1.4241600,54.9564000],
            [-1.4237800,54.9564400],
            [-1.4228000,54.9563500],
            [-1.4221900,54.9563500],
            [-1.4218100,54.9563900],
            [-1.4207834,54.9567467],
            [-1.4197567,54.9571033],
            [-1.4187300,54.9574600],
            [-1.4187967,54.9580633],
            [-1.4188633,54.9586667],
            [-1.4189300,54.9592700],
            [-1.4190500,54.9593400],
            [-1.4190800,54.9594100],
            [-1.4191100,54.9598400],
            [-1.4190700,54.9603000],
            [-1.4190800,54.9605800],
            [-1.4191400,54.9614500],
            [-1.4191400,54.9618200],
            [-1.4190800,54.9625100],
            [-1.4190500,54.9626000],
            [-1.4185200,54.9626700],
            [-1.4183900,54.9627500],
            [-1.4183200,54.9628200],
            [-1.4182800,54.9628700],
            [-1.4182500,54.9629600],
            [-1.4177500,54.9628800],
            [-1.4171400,54.9628600],
            [-1.4158350,54.9630250],
            [-1.4145300,54.9631900],
            [-1.4143600,54.9631900],
            [-1.4141300,54.9631000],
            [-1.4141200,54.9630600],
            [-1.4139900,54.9630500],
            [-1.4138800,54.9630700],
            [-1.4138800,54.9631100],
            [-1.4138100,54.9631200],
            [-1.4127950,54.9632150],
            [-1.4117800,54.9633100],
            [-1.4106300,54.9633867],
            [-1.4094800,54.9634633],
            [-1.4083300,54.9635400],
            [-1.4074900,54.9636400],
            [-1.4074900,54.9636800],
            [-1.4062350,54.9638500],
            [-1.4049800,54.9640200],
            [-1.4037250,54.9641900],
            [-1.4024700,54.9643600],
            [-1.4010567,54.9644433],
            [-1.3996433,54.9645267],
            [-1.3982300,54.9646100],
            [-1.3977700,54.9648200],
            [-1.3976200,54.9642700],
            [-1.3974700,54.9637200],
            [-1.3971900,54.9635400],
            [-1.3970000,54.9635800],
            [-1.3963500,54.9635800],
            [-1.3961800,54.9635700],
            [-1.3961900,54.9635400],
            [-1.3950800,54.9630400],
            [-1.3944600,54.9628900],
            [-1.3937800,54.9624500],
            [-1.3933800,54.9618800],
            [-1.3931200,54.9614200],
            [-1.3927700,54.9609800],
            [-1.3926500,54.9609100],
            [-1.3916300,54.9606500],
            [-1.3914200,54.9604900],
            [-1.3908000,54.9602700],
            [-1.3904300,54.9602500],
            [-1.3903000,54.9602100],
            [-1.3902700,54.9599900],
            [-1.3900000,54.9596400],
            [-1.3897800,54.9594900],
            [-1.3892200,54.9594500],
            [-1.3891100,54.9594100],
            [-1.3888700,54.9589000],
            [-1.3885700,54.9587300],
            [-1.3882600,54.9586200],
            [-1.3876800,54.9583200],
            [-1.3872800,54.9582600],
            [-1.3866100,54.9582300],
            [-1.3855267,54.9581533],
            [-1.3844433,54.9580767],
            [-1.3833600,54.9580000],
            [-1.3828400,54.9579300],
            [-1.3826400,54.9578200],
            [-1.3825400,54.9577100],
            [-1.3826000,54.9572500],
            [-1.3825300,54.9564500],
            [-1.3824700,54.9563600],
            [-1.3823300,54.9562900],
            [-1.3816500,54.9561400],
            [-1.3816300,54.9557400],
            [-1.3817200,54.9553900],
            [-1.3817500,54.9548900],
            [-1.3815600,54.9548000],
            [-1.3801200,54.9546900],
            [-1.3787100,54.9545967],
            [-1.3773000,54.9545034],
            [-1.3758900,54.9544101],
            [-1.3744800,54.9543167],
            [-1.3730700,54.9542234],
            [-1.3716600,54.9541300],
            [-1.3716300,54.9538100],
            [-1.3715700,54.9536100],
            [-1.3712700,54.9529933],
            [-1.3709700,54.9523767],
            [-1.3706700,54.9517600],
            [-1.3703800,54.9512000],
            [-1.3702500,54.9508900],
            [-1.3701200,54.9505200],
            [-1.3699700,54.9502100],
            [-1.3698300,54.9499700],
            [-1.3704200,54.9497600],
            [-1.3703300,54.9496700],
            [-1.3702800,54.9488500],
            [-1.3698400,54.9489300],
            [-1.3696200,54.9491100],
            [-1.3688000,54.9494100],
            [-1.3685600,54.9494700],
            [-1.3680600,54.9495700],
            [-1.3678500,54.9495500],
            [-1.3668000,54.9496250],
            [-1.3657500,54.9497000],
            [-1.3650700,54.9497300],
            [-1.3644900,54.9498000],
            [-1.3642400,54.9491400],
            [-1.3642400,54.9487700],
            [-1.3642900,54.9484400],
            [-1.3645000,54.9477000],
            [-1.3646350,54.9469600],
            [-1.3647700,54.9462200],
            [-1.3645000,54.9461900],
            [-1.3644200,54.9461600],
            [-1.3643600,54.9461000],
            [-1.3641050,54.9456250],
            [-1.3638500,54.9451500],
            [-1.3638500,54.9450000],
            [-1.3638600,54.9449200],
            [-1.3639600,54.9447600],
            [-1.3641000,54.9446500],
            [-1.3642900,54.9445600],
            [-1.3648100,54.9443600],
            [-1.3654100,54.9442200],
            [-1.3655500,54.9439000],
            [-1.3657300,54.9434000],
            [-1.3658700,54.9427067],
            [-1.3660100,54.9420133],
            [-1.3661500,54.9413200],
            [-1.3661900,54.9411500],
            [-1.3663900,54.9405800],
            [-1.3666100,54.9401600],
            [-1.3670900,54.9394600],
            [-1.3673600,54.9391700],
            [-1.3676300,54.9387700],
            [-1.3678800,54.9382500],
            [-1.3681300,54.9377300],
            [-1.3682400,54.9375600],
            [-1.3683000,54.9373100],
            [-1.3684800,54.9371200],
            [-1.3685300,54.9370100],
            [-1.3685700,54.9367800],
            [-1.3685700,54.9361100],
            [-1.3685700,54.9354400],
            [-1.3685100,54.9351500],
            [-1.3682567,54.9344400],
            [-1.3680033,54.9337300],
            [-1.3677500,54.9330200],
            [-1.3674500,54.9325600],
            [-1.3671000,54.9321450],
            [-1.3667500,54.9317300],
            [-1.3665400,54.9315500],
            [-1.3663700,54.9314400],
            [-1.3660300,54.9312900],
            [-1.3657700,54.9311100],
            [-1.3657900,54.9309400],
            [-1.3658300,54.9308600],
            [-1.3659900,54.9306900],
            [-1.3660400,54.9305500],
            [-1.3660200,54.9303600],
            [-1.3658500,54.9301200],
            [-1.3658300,54.9298700],
            [-1.3658900,54.9297600],
            [-1.3661200,54.9296800],
            [-1.3665700,54.9294900],
            [-1.3667900,54.9294800],
            [-1.3669000,54.9292500],
            [-1.3670900,54.9290900],
            [-1.3671900,54.9289800],
            [-1.3672900,54.9287800],
            [-1.3673100,54.9284000],
            [-1.3670100,54.9277400],
            [-1.3669400,54.9276400],
            [-1.3666300,54.9273700],
            [-1.3664000,54.9268700],
            [-1.3662300,54.9266100],
            [-1.3662400,54.9264700],
            [-1.3663100,54.9263000],
            [-1.3664300,54.9261900],
            [-1.3665800,54.9261000],
            [-1.3667900,54.9260400],
            [-1.3665600,54.9254850],
            [-1.3663300,54.9249300],
            [-1.3662150,54.9244850],
            [-1.3661000,54.9240400],
            [-1.3659600,54.9234600],
            [-1.3658200,54.9228800],
            [-1.3659000,54.9228800],
            [-1.3657600,54.9222650],
            [-1.3656200,54.9216500],
            [-1.3656200,54.9215700],
            [-1.3656800,54.9214700],
            [-1.3656500,54.9214500],
            [-1.3656500,54.9214100],
            [-1.3656900,54.9213700],
            [-1.3655500,54.9211600],
            [-1.3653500,54.9203900],
            [-1.3651900,54.9203300],
            [-1.3650200,54.9203000],
            [-1.3647100,54.9203200],
            [-1.3641700,54.9203900],
            [-1.3641600,54.9205200],
            [-1.3644000,54.9211900],
            [-1.3641600,54.9205200],
            [-1.3641700,54.9203900],
            [-1.3640600,54.9203000],
            [-1.3638600,54.9199100],
            [-1.3638300,54.9197400],
            [-1.3635800,54.9192800],
            [-1.3635800,54.9191600],
            [-1.3635300,54.9190600],
            [-1.3634000,54.9188800],
            [-1.3632200,54.9185500],
            [-1.3628700,54.9184600],
            [-1.3627100,54.9183600],
            [-1.3625700,54.9183000],
            [-1.3623900,54.9182700],
            [-1.3622700,54.9181100],
            [-1.3623000,54.9181000],
            [-1.3622800,54.9181100],
            [-1.3634900,54.9177200],
            [-1.3636900,54.9179100],
            [-1.3638000,54.9179200],
            [-1.3639200,54.9179500],
            [-1.3639900,54.9180000],
            [-1.3640900,54.9180100],
            [-1.3644600,54.9184000],
            [-1.3645300,54.9184100],
            [-1.3656300,54.9179800],
            [-1.3659500,54.9180700],
            [-1.3660200,54.9180800],
            [-1.3660900,54.9182400],
            [-1.3661200,54.9182400],
            [-1.3662700,54.9185600],
            [-1.3663700,54.9185800],
            [-1.3664100,54.9186100],
            [-1.3664000,54.9186800],
            [-1.3663400,54.9187100],
            [-1.3665800,54.9192400],
            [-1.3666800,54.9192600],
            [-1.3667400,54.9192900],
            [-1.3667100,54.9193500],
            [-1.3666400,54.9193900],
            [-1.3667400,54.9196100],
            [-1.3675100,54.9198500],
            [-1.3680750,54.9191600],
            [-1.3686400,54.9184700],
            [-1.3686400,54.9183800],
            [-1.3688200,54.9181400],
            [-1.3693500,54.9177600],
            [-1.3695500,54.9175700],
            [-1.3697500,54.9173000],
            [-1.3698900,54.9168300],
            [-1.3700000,54.9167400],
            [-1.3698800,54.9166800],
            [-1.3698200,54.9166200],
            [-1.3698100,54.9165200],
            [-1.3698500,54.9164400],
            [-1.3700600,54.9162500],
            [-1.3698900,54.9160100],
            [-1.3697500,54.9153400],
            [-1.3696500,54.9151300],
            [-1.3692800,54.9146600],
            [-1.3692400,54.9142200],
            [-1.3692500,54.9141000],
            [-1.3693900,54.9138800],
            [-1.3695100,54.9137700],
            [-1.3700900,54.9134100],
            [-1.3700900,54.9132700],
            [-1.3704750,54.9128050],
            [-1.3708600,54.9123400],
            [-1.3710700,54.9120500],
            [-1.3711200,54.9119300],
            [-1.3711100,54.9118100],
            [-1.3718900,54.9115100],
            [-1.3719200,54.9114500],
            [-1.3721300,54.9113600],
            [-1.3722400,54.9113600],
            [-1.3730700,54.9110300],
            [-1.3731700,54.9110300],
            [-1.3733100,54.9109700],
            [-1.3733400,54.9109100],
            [-1.3733600,54.9109000],
            [-1.3734200,54.9109000],
            [-1.3734900,54.9109600],
            [-1.3737000,54.9109800],
            [-1.3737900,54.9109300],
            [-1.3740700,54.9109600],
            [-1.3741600,54.9110100],
            [-1.3745400,54.9110400],
            [-1.3746400,54.9110100],
            [-1.3754900,54.9110900],
            [-1.3762800,54.9111500],
            [-1.3770700,54.9112100],
            [-1.3774500,54.9111200],
            [-1.3787400,54.9109500],
            [-1.3793500,54.9109000],
            [-1.3806250,54.9107300],
            [-1.3819000,54.9105600],
            [-1.3820200,54.9105600],
            [-1.3824400,54.9103300],
            [-1.3828300,54.9102800],
            [-1.3832900,54.9103300],
            [-1.3833500,54.9103900],
            [-1.3835500,54.9104300],
            [-1.3842800,54.9104800],
            [-1.3843300,54.9102900],
            [-1.3850600,54.9102800],
            [-1.3850500,54.9102800],
            [-1.3862300,54.9104000],
            [-1.3865400,54.9105400],
            [-1.3869400,54.9106300],
            [-1.3873400,54.9107500],
            [-1.3882400,54.9112400],
            [-1.3885700,54.9113600],
            [-1.3896600,54.9115500],
            [-1.3898200,54.9116000],
            [-1.3899300,54.9117300],
            [-1.3903300,54.9119100],
            [-1.3905300,54.9119500],
            [-1.3908100,54.9120800],
            [-1.3912400,54.9125500],
            [-1.3915600,54.9131600],
            [-1.3915600,54.9137700],
            [-1.3916500,54.9138500],
            [-1.3917150,54.9145500],
            [-1.3917800,54.9152500],
            [-1.3919600,54.9160300],
            [-1.3920900,54.9163100],
            [-1.3922400,54.9165400],
            [-1.3924300,54.9167500],
            [-1.3928100,54.9170400],
            [-1.3933400,54.9173200],
            [-1.3943400,54.9176700],
            [-1.3950950,54.9178350],
            [-1.3958500,54.9180000],
            [-1.3960000,54.9179900],
            [-1.3963100,54.9178100],
            [-1.3975400,54.9176800],
            [-1.3979800,54.9175900],
            [-1.3988700,54.9176400],
            [-1.3993500,54.9177200],
            [-1.3996500,54.9180000],
            [-1.4002950,54.9177450],
            [-1.4009400,54.9174900],
            [-1.4013400,54.9176500],
            [-1.4016400,54.9179100],
            [-1.4031000,54.9176600],
            [-1.4044400,54.9176500],
            [-1.4050400,54.9176900],
            [-1.4056900,54.9177600],
            [-1.4061500,54.9177500],
            [-1.4070500,54.9178300],
            [-1.4076500,54.9179300],
            [-1.4087600,54.9181733],
            [-1.4098700,54.9184167],
            [-1.4109800,54.9186600],
            [-1.4124400,54.9189600],
            [-1.4136900,54.9191300],
            [-1.4148850,54.9192250],
            [-1.4160800,54.9193200],
            [-1.4174900,54.9192900],
            [-1.4183450,54.9193000],
            [-1.4192000,54.9193100],
            [-1.4204900,54.9192300],
            [-1.4207300,54.9191500],
            [-1.4212400,54.9191900],
            [-1.4212400,54.9191200],
            [-1.4220800,54.9191800],
            [-1.4221000,54.9183600],
            [-1.4222700,54.9182100],
            [-1.4223600,54.9181800],
            [-1.4229100,54.9180900],
            [-1.4234200,54.9181900],
            [-1.4237900,54.9181800],
            [-1.4244000,54.9179500],
            [-1.4245600,54.9179400],
            [-1.4251700,54.9181600],
            [-1.4257500,54.9182700],
            [-1.4258500,54.9183100],
            [-1.4260600,54.9183300],
            [-1.4266900,54.9183600],
            [-1.4272800,54.9183200],
            [-1.4274200,54.9183500],
            [-1.4275800,54.9183400],
            [-1.4277600,54.9183300],
            [-1.4284000,54.9182000],
            [-1.4283900,54.9182100],
            [-1.4285200,54.9179500],
            [-1.4284900,54.9177700],
            [-1.4281900,54.9173800],
            [-1.4282200,54.9171700],
            [-1.4283200,54.9170000],
            [-1.4285600,54.9168300],
            [-1.4296200,54.9165700],
            [-1.4308300,54.9162100],
            [-1.4323500,54.9160200],
            [-1.4335500,54.9155700],
            [-1.4345600,54.9149900],
            [-1.4357800,54.9146800],
            [-1.4367600,54.9141000],
            [-1.4372500,54.9137100],
            [-1.4375900,54.9135900],
            [-1.4384350,54.9135400],
            [-1.4392800,54.9134900],
            [-1.4397800,54.9133300],
            [-1.4407600,54.9131450],
            [-1.4417400,54.9129600],
            [-1.4429600,54.9125600],
            [-1.4436400,54.9123200],
            [-1.4440100,54.9123300],
            [-1.4443300,54.9131600],
            [-1.4444800,54.9132900],
            [-1.4455800,54.9129500],
            [-1.4457800,54.9127800],
            [-1.4464300,54.9124700],
            [-1.4474800,54.9122300],
            [-1.4485300,54.9119900],
            [-1.4496500,54.9118400],
            [-1.4507700,54.9116900],
            [-1.4519600,54.9115900],
            [-1.4525700,54.9110050],
            [-1.4531800,54.9104200],
            [-1.4531900,54.9102900],
            [-1.4535333,54.9096400],
            [-1.4538767,54.9089900],
            [-1.4542200,54.9083400],
            [-1.4540800,54.9079400],
            [-1.4540800,54.9076300],
            [-1.4544950,54.9071100],
            [-1.4549100,54.9065900],
            [-1.4552000,54.9067000],
            [-1.4555400,54.9068500],
            [-1.4557400,54.9069000],
            [-1.4564500,54.9067600],
            [-1.4577750,54.9063800],
            [-1.4591000,54.9060000],
            [-1.4605100,54.9056700],
            [-1.4604500,54.9057300],
            [-1.4598900,54.9059100],
            [-1.4598800,54.9060100],
            [-1.4603750,54.9066850],
            [-1.4608700,54.9073600],
            [-1.4612700,54.9077900],
            [-1.4614800,54.9079500],
            [-1.4621100,54.9083300],
            [-1.4624200,54.9084500],
            [-1.4626600,54.9084800],
            [-1.4639300,54.9083000],
            [-1.4652675,54.9081000],
            [-1.4666050,54.9079000],
            [-1.4679425,54.9077000],
            [-1.4692800,54.9075000],
            [-1.4707425,54.9073175],
            [-1.4722050,54.9071350],
            [-1.4736675,54.9069525],
            [-1.4751300,54.9067700],
            [-1.4758200,54.9065700],
            [-1.4772550,54.9064400],
            [-1.4786900,54.9063100],
            [-1.4788800,54.9062600],
            [-1.4788900,54.9062500],
            [-1.4789100,54.9062500],
            [-1.4790900,54.9058800],
            [-1.4792700,54.9058700],
            [-1.4792500,54.9057300],
            [-1.4785550,54.9053600],
            [-1.4778600,54.9049900],
            [-1.4776700,54.9045000],
            [-1.4782800,54.9040500],
            [-1.4785900,54.9037500],
            [-1.4790400,54.9035100],
            [-1.4796400,54.9035700],
            [-1.4799800,54.9034600],
            [-1.4801800,54.9032200],
            [-1.4797400,54.9026800],
            [-1.4794300,54.9022100],
            [-1.4802934,54.9017667],
            [-1.4811567,54.9013233],
            [-1.4820200,54.9008800],
            [-1.4825500,54.9005400],
            [-1.4838400,54.9003100],
            [-1.4848000,54.9002300],
            [-1.4857750,54.9001800],
            [-1.4867500,54.9001300],
            [-1.4868600,54.9002600],
            [-1.4873300,54.8994900],
            [-1.4875700,54.8994400],
            [-1.4880800,54.8994700],
            [-1.4882800,54.8993900],
            [-1.4891500,54.8987300],
            [-1.4892600,54.8986400],
            [-1.4901800,54.8988300],
            [-1.4904500,54.8988400],
            [-1.4909200,54.8985600],
            [-1.4916000,54.8979300],
            [-1.4920900,54.8973967],
            [-1.4925800,54.8968633],
            [-1.4930700,54.8963300],
            [-1.4935950,54.8958350],
            [-1.4941200,54.8953400],
            [-1.4943600,54.8950600],
            [-1.4943900,54.8949600],
            [-1.4943500,54.8948100],
            [-1.4943100,54.8947700],
            [-1.4943300,54.8947100],
            [-1.4935500,54.8943100],
            [-1.4934700,54.8942200],
            [-1.4933700,54.8939400],
            [-1.4932800,54.8937900],
            [-1.4944400,54.8935500],
            [-1.4946600,54.8935000],
            [-1.4948200,54.8934400],
            [-1.4954450,54.8930250],
            [-1.4960700,54.8926100],
            [-1.4963400,54.8924600],
            [-1.4968800,54.8922100],
            [-1.4974850,54.8919050],
            [-1.4980900,54.8916000],
            [-1.4981700,54.8915300],
            [-1.4983500,54.8915900],
            [-1.4984500,54.8915000],
            [-1.4973000,54.8910700],
            [-1.4969200,54.8909900],
            [-1.4967500,54.8909800],
            [-1.4967300,54.8909200],
            [-1.4963300,54.8908000],
            [-1.4960800,54.8910100],
            [-1.4958000,54.8910300],
            [-1.4951700,54.8908800],
            [-1.4949600,54.8908500],
            [-1.4948100,54.8908600],
            [-1.4952100,54.8904250],
            [-1.4956100,54.8899900],
            [-1.4960450,54.8896050],
            [-1.4964800,54.8892200],
            [-1.4967500,54.8891600],
            [-1.4969300,54.8891500],
            [-1.4972100,54.8892000],
            [-1.4976100,54.8887100],
            [-1.4982600,54.8887100],
            [-1.4988300,54.8887400],
            [-1.4992000,54.8886300],
            [-1.5000700,54.8885000],
            [-1.5006500,54.8884400],
            [-1.5009000,54.8883300],
            [-1.5011900,54.8881200],
            [-1.5013600,54.8878900],
            [-1.5023500,54.8874250],
            [-1.5033400,54.8869600],
            [-1.5037400,54.8868200],
            [-1.5043200,54.8865300],
            [-1.5051700,54.8862200],
            [-1.5051600,54.8862300],
            [-1.5056000,54.8861700],
            [-1.5060400,54.8862400],
            [-1.5064800,54.8863600],
            [-1.5065800,54.8863600],
            [-1.5066800,54.8863300],
            [-1.5070900,54.8860500],
            [-1.5071200,54.8859400],
            [-1.5072900,54.8859300],
            [-1.5076000,54.8860100],
            [-1.5076300,54.8861100],
            [-1.5075700,54.8863200],
            [-1.5076300,54.8864400],
            [-1.5081200,54.8867700],
            [-1.5086200,54.8874900],
            [-1.5088450,54.8880450],
            [-1.5090700,54.8886000],
            [-1.5092000,54.8887700],
            [-1.5095000,54.8889900],
            [-1.5101100,54.8892600],
            [-1.5105100,54.8893400],
            [-1.5108000,54.8893300],
            [-1.5110000,54.8894100],
            [-1.5116100,54.8891700],
            [-1.5117700,54.8891500],
            [-1.5120400,54.8891400],
            [-1.5122400,54.8890800],
            [-1.5124300,54.8890000],
            [-1.5125800,54.8888800],
            [-1.5128900,54.8886900],
            [-1.5136700,54.8884250],
            [-1.5144500,54.8881600],
            [-1.5153100,54.8879600],
            [-1.5161700,54.8877600],
            [-1.5173467,54.8875100],
            [-1.5185233,54.8872600],
            [-1.5197000,54.8870100],
            [-1.5198300,54.8870100],
            [-1.5198400,54.8869600],
            [-1.5200400,54.8868900],
            [-1.5212740,54.8866220],
            [-1.5225080,54.8863540],
            [-1.5237420,54.8860860],
            [-1.5249760,54.8858180],
            [-1.5262100,54.8855500],
            [-1.5269800,54.8853650],
            [-1.5277500,54.8851800],
            [-1.5291600,54.8849200],
            [-1.5303200,54.8846100],
            [-1.5314400,54.8844000],
            [-1.5325600,54.8841900],
            [-1.5333850,54.8840850],
            [-1.5342100,54.8839800],
            [-1.5357350,54.8838634],
            [-1.5372600,54.8837467],
            [-1.5387850,54.8836301],
            [-1.5403100,54.8835134],
            [-1.5418350,54.8833967],
            [-1.5433600,54.8832800],
            [-1.5446500,54.8832300],
            [-1.5457150,54.8831000],
            [-1.5467800,54.8829700],
            [-1.5483250,54.8828650],
            [-1.5498700,54.8827600],
            [-1.5507650,54.8826650],
            [-1.5516600,54.8825700],
            [-1.5524550,54.8825250],
            [-1.5532500,54.8824800],
            [-1.5543050,54.8823600],
            [-1.5553600,54.8822400],
            [-1.5562400,54.8822400],
            [-1.5571200,54.8822400],
            [-1.5574200,54.8821800],
            [-1.5585150,54.8818750],
            [-1.5596100,54.8815700],
            [-1.5603600,54.8813400],
            [-1.5613900,54.8809200],
            [-1.5621300,54.8806800],
            [-1.5634350,54.8801950],
            [-1.5647400,54.8797100],
            [-1.5660450,54.8792625],
            [-1.5673501,54.8788150],
            [-1.5686550,54.8783675],
            [-1.5699600,54.8779200],
            [-1.5705800,54.8776600],
            [-1.5716667,54.8773067],
            [-1.5727534,54.8769533],
            [-1.5738400,54.8766000],
            [-1.5748350,54.8762650],
            [-1.5758300,54.8759300],
            [-1.5762400,54.8758400],
            [-1.5764800,54.8757400],
            [-1.5766400,54.8756100],
            [-1.5767900,54.8754300],
            [-1.5770700,54.8749900],
            [-1.5773500,54.8745500],
            [-1.5777000,54.8741700],
            [-1.5778700,54.8738000],
            [-1.5781300,54.8734900],
            [-1.5781300,54.8731800],
            [-1.5782200,54.8729800],
            [-1.5787200,54.8728200],
            [-1.5787500,54.8727800],
            [-1.5787200,54.8726300],
            [-1.5789500,54.8721600],
            [-1.5793200,54.8717300],
            [-1.5800150,54.8710900],
            [-1.5807100,54.8704500],
            [-1.5814050,54.8698100],
            [-1.5821000,54.8691700],
            [-1.5828800,54.8686500],
            [-1.5830100,54.8683900],
            [-1.5831500,54.8682200],
            [-1.5831600,54.8681200],
            [-1.5836200,54.8677800],
            [-1.5845200,54.8672200],
            [-1.5854200,54.8666600],
            [-1.5860800,54.8662900],
            [-1.5867400,54.8659200],
            [-1.5870500,54.8657800],
            [-1.5872700,54.8657800],
            [-1.5878600,54.8656600],
            [-1.5883700,54.8655100],
            [-1.5892150,54.8653050],
            [-1.5900600,54.8651000],
            [-1.5911100,54.8649400],
            [-1.5925600,54.8648688],
            [-1.5940100,54.8647976],
            [-1.5954600,54.8647264],
            [-1.5969100,54.8646551],
            [-1.5983600,54.8645839],
            [-1.5998100,54.8645126],
            [-1.6012600,54.8644413],
            [-1.6027100,54.8643700],
            [-1.6039150,54.8643050],
            [-1.6051200,54.8642400],
            [-1.6062000,54.8642300],
            [-1.6065900,54.8642400],
            [-1.6072600,54.8643100],
            [-1.6076700,54.8644100],
            [-1.6076600,54.8644000],
            [-1.6086700,54.8646800],
            [-1.6092800,54.8649800],
            [-1.6098600,54.8653700],
            [-1.6103800,54.8659800],
            [-1.6108400,54.8667020],
            [-1.6113000,54.8674240],
            [-1.6117600,54.8681460],
            [-1.6122200,54.8688680],
            [-1.6126800,54.8695900],
            [-1.6129500,54.8699400],
            [-1.6133500,54.8703900],
            [-1.6137900,54.8708000],
            [-1.6140300,54.8710000],
            [-1.6147800,54.8715100],
            [-1.6154350,54.8718400],
            [-1.6160900,54.8721700],
            [-1.6167700,54.8724350],
            [-1.6174500,54.8727000],
            [-1.6186400,54.8730700],
            [-1.6197900,54.8733800],
            [-1.6211599,54.8736251],
            [-1.6225299,54.8738701],
            [-1.6238999,54.8741151],
            [-1.6252699,54.8743601],
            [-1.6266399,54.8746051],
            [-1.6280099,54.8748501],
            [-1.6293799,54.8750951],
            [-1.6307500,54.8753400],
            [-1.6320425,54.8755650],
            [-1.6333350,54.8757900],
            [-1.6346275,54.8760150],
            [-1.6359200,54.8762400],
            [-1.6368700,54.8763500],
            [-1.6379633,54.8764000],
            [-1.6390567,54.8764500],
            [-1.6401500,54.8765000],
            [-1.6409700,54.8765350],
            [-1.6417900,54.8765700],
            [-1.6429550,54.8766050],
            [-1.6441200,54.8766400],
            [-1.6452167,54.8766900],
            [-1.6463133,54.8767400],
            [-1.6474100,54.8767900],
            [-1.6486600,54.8768200],
            [-1.6499100,54.8768500],
            [-1.6511600,54.8768800],
            [-1.6524100,54.8769100],
            [-1.6536600,54.8769400],
            [-1.6544400,54.8769400],
            [-1.6557900,54.8768900],
            [-1.6566700,54.8769100],
            [-1.6575500,54.8769300],
            [-1.6576300,54.8769900],
            [-1.6576700,54.8774000],
            [-1.6576800,54.8774800],
            [-1.6577300,54.8775500],
            [-1.6580000,54.8775400],
            [-1.6580200,54.8776400],
            [-1.6580200,54.8781100],
            [-1.6580200,54.8785800],
            [-1.6581300,54.8789700],
            [-1.6582300,54.8792300],
            [-1.6585100,54.8793100],
            [-1.6588000,54.8793600],
            [-1.6597300,54.8794400],
            [-1.6606600,54.8795200],
            [-1.6611400,54.8796100],
            [-1.6614400,54.8797500],
            [-1.6623900,54.8803900],
            [-1.6627700,54.8808350],
            [-1.6631500,54.8812800],
            [-1.6634100,54.8815200],
            [-1.6638600,54.8816700],
            [-1.6647000,54.8818700],
            [-1.6649400,54.8819600],
            [-1.6649300,54.8819500],
            [-1.6650700,54.8820700],
            [-1.6652700,54.8822700],
            [-1.6654600,54.8828400],
            [-1.6655600,54.8831200],
            [-1.6659200,54.8835500],
            [-1.6659700,54.8838000],
            [-1.6661700,54.8845850],
            [-1.6663700,54.8853700],
            [-1.6665000,54.8858400],
            [-1.6666300,54.8863100],
            [-1.6670700,54.8871400],
            [-1.6672100,54.8873500],
            [-1.6676700,54.8880200],
            [-1.6681850,54.8887000],
            [-1.6687000,54.8893800],
            [-1.6687400,54.8894100],
            [-1.6688000,54.8894100],
            [-1.6697150,54.8892400],
            [-1.6706300,54.8890700],
            [-1.6712400,54.8889200],
            [-1.6713300,54.8894000],
            [-1.6714200,54.8898800],
            [-1.6713300,54.8903900],
            [-1.6712400,54.8912600],
            [-1.6711400,54.8919400],
            [-1.6712050,54.8925000],
            [-1.6712700,54.8930600],
            [-1.6713400,54.8933800],
            [-1.6713400,54.8935600],
            [-1.6712100,54.8939600],
            [-1.6710800,54.8940600],
            [-1.6710400,54.8941500],
            [-1.6710400,54.8942500],
            [-1.6711000,54.8944300],
            [-1.6712700,54.8946500],
            [-1.6719350,54.8954100],
            [-1.6726000,54.8961700],
            [-1.6729250,54.8966000],
            [-1.6732500,54.8970300],
            [-1.6741600,54.8974250],
            [-1.6750700,54.8978200],
            [-1.6752100,54.8978500],
            [-1.6753100,54.8979200],
            [-1.6756500,54.8980600],
            [-1.6761750,54.8985800],
            [-1.6767000,54.8991000],
            [-1.6772800,54.8994000],
            [-1.6782300,54.8998300],
            [-1.6781750,54.9003150],
            [-1.6781200,54.9008000],
            [-1.6792200,54.9008400],
            [-1.6792500,54.9008200],
            [-1.6796500,54.9008500],
            [-1.6799700,54.9008400],
            [-1.6802200,54.9007900],
            [-1.6806700,54.9006500],
            [-1.6813400,54.9005100],
            [-1.6821900,54.9003900],
            [-1.6823600,54.9003900],
            [-1.6824900,54.9004300],
            [-1.6825700,54.9004700],
            [-1.6826300,54.9005700],
            [-1.6826600,54.9006600],
            [-1.6826600,54.9008900],
            [-1.6827400,54.9009400],
            [-1.6829700,54.9009100],
            [-1.6833400,54.9007600],
            [-1.6835700,54.9007200],
            [-1.6831700,54.9012500],
            [-1.6827700,54.9015300],
            [-1.6827300,54.9015900],
            [-1.6827000,54.9017100],
            [-1.6827275,54.9024700],
            [-1.6827550,54.9032300],
            [-1.6827825,54.9039900],
            [-1.6828100,54.9047500],
            [-1.6828400,54.9048000],
            [-1.6829000,54.9048300],
            [-1.6831400,54.9048600],
            [-1.6839300,54.9047900],
            [-1.6837433,54.9055600],
            [-1.6835567,54.9063300],
            [-1.6833700,54.9071000],
            [-1.6828150,54.9074250],
            [-1.6822600,54.9077500],
            [-1.6821200,54.9078100],
            [-1.6813250,54.9079700],
            [-1.6805300,54.9081300],
            [-1.6799300,54.9083200],
            [-1.6792100,54.9087000],
            [-1.6782500,54.9089700],
            [-1.6772150,54.9092550],
            [-1.6761800,54.9095400],
            [-1.6750800,54.9096000],
            [-1.6750800,54.9096700],
            [-1.6754800,54.9096600],
            [-1.6754300,54.9099300],
            [-1.6755100,54.9101500],
            [-1.6756500,54.9102900],
            [-1.6757600,54.9103400],
            [-1.6759900,54.9106300],
            [-1.6760500,54.9108300],
            [-1.6760900,54.9111300],
            [-1.6761300,54.9111800],
            [-1.6764000,54.9113300],
            [-1.6766000,54.9114800],
            [-1.6767600,54.9116600],
            [-1.6768000,54.9117900],
            [-1.6769400,54.9120000],
            [-1.6770000,54.9121900],
            [-1.6772100,54.9123800],
            [-1.6776100,54.9127000],
            [-1.6778200,54.9130900],
            [-1.6778800,54.9132800],
            [-1.6778900,54.9134500],
            [-1.6777100,54.9142500],
            [-1.6776100,54.9144000],
            [-1.6775800,54.9145000],
            [-1.6775400,54.9148500],
            [-1.6775800,54.9150300],
            [-1.6776200,54.9151700],
            [-1.6776200,54.9152800],
            [-1.6775100,54.9156700],
            [-1.6774100,54.9158600],
            [-1.6773400,54.9161100],
            [-1.6770700,54.9165900],
            [-1.6770400,54.9167200],
            [-1.6770400,54.9170900],
            [-1.6768700,54.9175100],
            [-1.6768300,54.9175800],
            [-1.6767300,54.9176600],
            [-1.6767000,54.9178500],
            [-1.6766400,54.9179900],
            [-1.6765700,54.9181000],
            [-1.6765400,54.9182000],
            [-1.6765400,54.9183300],
            [-1.6767100,54.9183800],
            [-1.6765000,54.9190100],
            [-1.6762900,54.9195100],
            [-1.6761900,54.9196600],
            [-1.6757500,54.9201500],
            [-1.6755300,54.9203300],
            [-1.6756900,54.9203700],
            [-1.6761200,54.9203400],
            [-1.6762900,54.9203500],
            [-1.6769700,54.9206700],
            [-1.6772100,54.9208300],
            [-1.6774800,54.9208800],
            [-1.6776000,54.9209400],
            [-1.6776800,54.9210200],
            [-1.6776800,54.9210800],
            [-1.6780500,54.9210900],
            [-1.6787000,54.9211600],
            [-1.6789400,54.9211700],
            [-1.6793400,54.9210700],
            [-1.6794800,54.9210700],
            [-1.6795800,54.9211100],
            [-1.6802200,54.9216800],
            [-1.6804600,54.9218300],
            [-1.6807000,54.9218600],
            [-1.6807800,54.9218500],
            [-1.6807800,54.9220500],
            [-1.6813400,54.9220800],
            [-1.6816400,54.9221200],
            [-1.6819800,54.9222100],
            [-1.6826950,54.9224500],
            [-1.6834100,54.9226900],
            [-1.6835100,54.9227800],
            [-1.6835800,54.9228600],
            [-1.6835800,54.9230000],
            [-1.6835100,54.9231100],
            [-1.6831400,54.9234600],
            [-1.6831000,54.9235400],
            [-1.6831000,54.9236700],
            [-1.6831800,54.9238000],
            [-1.6833100,54.9239900],
            [-1.6833700,54.9242200],
            [-1.6833100,54.9244000],
            [-1.6832700,54.9244900],
            [-1.6826300,54.9251500],
            [-1.6825900,54.9253200],
            [-1.6826300,54.9254000],
            [-1.6827000,54.9254600],
            [-1.6832200,54.9258300],
            [-1.6837400,54.9262000],
            [-1.6841200,54.9264500],
            [-1.6842600,54.9265800],
            [-1.6842600,54.9267600],
            [-1.6842200,54.9268300],
            [-1.6838000,54.9272550],
            [-1.6833800,54.9276800],
            [-1.6832000,54.9277600],
            [-1.6829300,54.9278100],
            [-1.6831200,54.9282300],
            [-1.6839500,54.9283800],
            [-1.6852940,54.9286880],
            [-1.6866379,54.9289960],
            [-1.6879819,54.9293040],
            [-1.6893260,54.9296120],
            [-1.6906700,54.9299200],
            [-1.6915933,54.9302200],
            [-1.6925167,54.9305200],
            [-1.6934400,54.9308200],
            [-1.6947150,54.9313000],
            [-1.6959900,54.9317800],
            [-1.6970100,54.9320100],
            [-1.6972700,54.9321700],
            [-1.6974700,54.9323500],
            [-1.6980800,54.9327900],
            [-1.6985900,54.9333300],
            [-1.6987000,54.9334200],
            [-1.6989700,54.9335400],
            [-1.6996500,54.9337500],
            [-1.6998800,54.9338500],
            [-1.7005900,54.9342800],
            [-1.7012000,54.9347900],
            [-1.7018100,54.9353000],
            [-1.7019900,54.9354900],
            [-1.7025600,54.9361700],
            [-1.7026300,54.9363500],
            [-1.7028100,54.9368600],
            [-1.7028200,54.9370000],
            [-1.7028200,54.9369900],
            [-1.7027600,54.9371000],
            [-1.7021400,54.9377500],
            [-1.7015200,54.9384000],
            [-1.7010400,54.9389800],
            [-1.7005600,54.9395600],
            [-1.7002300,54.9398300],
            [-1.6995100,54.9401900],
            [-1.6983000,54.9406600],
            [-1.6977100,54.9409100],
            [-1.6967500,54.9414400],
            [-1.6957900,54.9419700],
            [-1.6950450,54.9424100],
            [-1.6943000,54.9428500],
            [-1.6937100,54.9433200],
            [-1.6929400,54.9436900],
            [-1.6926300,54.9438600],
            [-1.6922200,54.9441400],
            [-1.6919200,54.9444000],
            [-1.6911400,54.9449700],
            [-1.6908700,54.9452000],
            [-1.6905900,54.9455400],
            [-1.6898800,54.9461600],
            [-1.6893400,54.9465700],
            [-1.6888700,54.9468600],
            [-1.6887600,54.9469700],
            [-1.6887000,54.9470800],
            [-1.6882300,54.9476400],
            [-1.6877800,54.9481300],
            [-1.6874200,54.9485600],
            [-1.6870100,54.9489850],
            [-1.6866000,54.9494100],
            [-1.6863700,54.9495500],
            [-1.6863200,54.9497100],
            [-1.6862900,54.9498900],
            [-1.6863900,54.9500500],
            [-1.6866000,54.9502100],
            [-1.6872900,54.9505500],
            [-1.6877500,54.9507500],
            [-1.6878600,54.9508500],
            [-1.6879200,54.9509200],
            [-1.6878800,54.9510500],
            [-1.6877500,54.9511900],
            [-1.6874500,54.9514800],
            [-1.6872500,54.9516200],
            [-1.6870600,54.9516900],
            [-1.6863700,54.9520400],
            [-1.6862000,54.9519000],
            [-1.6858800,54.9519600],
            [-1.6849300,54.9520500],
            [-1.6846900,54.9520300],
            [-1.6842000,54.9521100],
            [-1.6832150,54.9522050],
            [-1.6822300,54.9523000],
            [-1.6808167,54.9524567],
            [-1.6794033,54.9526133],
            [-1.6779900,54.9527700],
            [-1.6769400,54.9528750],
            [-1.6758900,54.9529800],
            [-1.6751100,54.9530400],
            [-1.6748500,54.9530800],
            [-1.6746300,54.9532400],
            [-1.6733433,54.9527667],
            [-1.6720566,54.9522933],
            [-1.6707700,54.9518200],
            [-1.6697500,54.9515800],
            [-1.6693300,54.9514500],
            [-1.6687300,54.9511700],
            [-1.6684300,54.9510200],
            [-1.6679500,54.9508700],
            [-1.6674800,54.9507500],
            [-1.6667500,54.9504800],
            [-1.6664900,54.9509750],
            [-1.6662300,54.9514700],
            [-1.6661900,54.9517200],
            [-1.6661800,54.9517300],
            [-1.6661300,54.9519900],
            [-1.6654800,54.9523600],
            [-1.6651700,54.9526100],
            [-1.6643700,54.9528900],
            [-1.6630000,54.9530700],
            [-1.6626400,54.9530300],
            [-1.6625400,54.9533300],
            [-1.6626100,54.9535200],
            [-1.6625400,54.9537500],
            [-1.6624600,54.9543600],
            [-1.6623800,54.9549700],
            [-1.6623000,54.9555800],
            [-1.6622900,54.9563700],
            [-1.6619900,54.9564100],
            [-1.6620000,54.9564100],
            [-1.6607700,54.9566900],
            [-1.6605000,54.9566800],
            [-1.6604300,54.9567400],
            [-1.6603300,54.9569200],
            [-1.6600200,54.9576600],
            [-1.6598550,54.9581200],
            [-1.6596900,54.9585800],
            [-1.6596200,54.9585700],
            [-1.6594500,54.9586000],
            [-1.6594100,54.9587900],
            [-1.6582750,54.9587250],
            [-1.6571400,54.9586600],
            [-1.6562600,54.9586700],
            [-1.6550750,54.9588950],
            [-1.6538900,54.9591200],
            [-1.6531500,54.9592400],
            [-1.6529500,54.9591500],
            [-1.6522600,54.9592000],
            [-1.6516900,54.9591900],
            [-1.6512500,54.9591600],
            [-1.6505700,54.9590700],
            [-1.6498900,54.9589000],
            [-1.6490700,54.9586100],
            [-1.6484400,54.9584700],
            [-1.6474850,54.9582450],
            [-1.6465300,54.9580200],
            [-1.6458800,54.9579100],
            [-1.6458500,54.9578100],
            [-1.6450600,54.9575600],
            [-1.6450700,54.9574200],
            [-1.6450400,54.9573900],
            [-1.6446800,54.9573200],
            [-1.6435900,54.9571900],
            [-1.6424000,54.9571000],
            [-1.6414500,54.9569700],
            [-1.6413100,54.9569800],
            [-1.6411200,54.9569500],
            [-1.6409800,54.9568900],
            [-1.6400800,54.9566900],
            [-1.6394900,54.9565400],
            [-1.6389300,54.9563600],
            [-1.6386600,54.9567500],
            [-1.6385500,54.9568500],
            [-1.6383600,54.9571600],
            [-1.6382700,54.9572100],
            [-1.6381600,54.9572200],
            [-1.6373100,54.9571600],
            [-1.6364100,54.9571400],
            [-1.6355200,54.9569000],
            [-1.6348400,54.9567500],
            [-1.6341700,54.9566500],
            [-1.6335800,54.9566100],
            [-1.6331400,54.9566200],
            [-1.6326300,54.9566800],
            [-1.6319700,54.9568000],
            [-1.6311100,54.9570100],
            [-1.6303600,54.9572900],
            [-1.6301400,54.9575900],
            [-1.6291800,54.9578800],
            [-1.6289800,54.9578500],
            [-1.6286000,54.9579600],
            [-1.6285700,54.9581000],
            [-1.6274500,54.9584000],
            [-1.6272500,54.9583700],
            [-1.6271700,54.9583100],
            [-1.6258867,54.9587100],
            [-1.6246034,54.9591100],
            [-1.6233200,54.9595100],
            [-1.6222000,54.9598267],
            [-1.6210800,54.9601433],
            [-1.6199600,54.9604600],
            [-1.6189175,54.9608400],
            [-1.6178750,54.9612200],
            [-1.6168325,54.9616000],
            [-1.6157900,54.9619800],
            [-1.6150100,54.9623400],
            [-1.6148400,54.9624400],
            [-1.6143350,54.9628750],
            [-1.6138300,54.9633100],
            [-1.6131600,54.9637200],
            [-1.6124000,54.9641600],
            [-1.6116400,54.9646000],
            [-1.6108800,54.9650400],
            [-1.6106900,54.9650300],
            [-1.6105300,54.9649700],
            [-1.6103000,54.9651500],
            [-1.6099900,54.9653300],
            [-1.6096200,54.9654700],
            [-1.6087700,54.9656900],
            [-1.6079700,54.9660400],
            [-1.6072800,54.9663700],
            [-1.6069500,54.9665700],
            [-1.6067100,54.9667700],
            [-1.6065700,54.9667300],
            [-1.6058800,54.9667500],
            [-1.6058900,54.9668600],
            [-1.6058000,54.9669200],
            [-1.6049300,54.9672700],
            [-1.6044300,54.9675200],
            [-1.6039300,54.9677300],
            [-1.6036200,54.9678200],
            [-1.6030900,54.9679400],
            [-1.6026000,54.9681300],
            [-1.6018900,54.9682900],
            [-1.6005400,54.9685300],
            [-1.6006000,54.9686300],
            [-1.5999000,54.9687600],
            [-1.5998700,54.9687900],
            [-1.5998300,54.9689000],
            [-1.5997700,54.9689500],
            [-1.5994000,54.9691200],
        ]
    }
};