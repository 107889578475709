export const glidingClub = {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Dusty Gravel 😎</br> Gliding Club'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.8337400, 54.9385800],
          [-1.8340200, 54.9381100],
          [-1.8342700, 54.9376700],
          [-1.8345800, 54.9372700],
          [-1.8348100, 54.9371000],
          [-1.8350200, 54.9369800],
          [-1.8354600, 54.9367900],
          [-1.8355300, 54.9367300],
          [-1.8359350, 54.9362150],
          [-1.8363400, 54.9357000],
          [-1.8367100, 54.9351900],
          [-1.8372900, 54.9344400],
          [-1.8377300, 54.9339200],
          [-1.8379000, 54.9336600],
          [-1.8380700, 54.9334700],
          [-1.8381400, 54.9333200],
          [-1.8382500, 54.9329200],
          [-1.8382700, 54.9327500],
          [-1.8383400, 54.9324500],
          [-1.8391100, 54.9317200],
          [-1.8398800, 54.9309900],
          [-1.8406500, 54.9302600],
          [-1.8408200, 54.9301100],
          [-1.8409500, 54.9300400],
          [-1.8409900, 54.9299700],
          [-1.8410200, 54.9298500],
        ]
    }
};