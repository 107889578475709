export const hownsgill = {
    'type': 'Feature',
    'properties': {
        'color': '#CB3709',
        'description': 'Waggon Way 👌</br> Hownsgill Waggonway'
    },
    'geometry':{
        //Hownsgill (Waggonway)
        'type': 'LineString',
        'coordinates': [
            [-1.8666900, 54.8251800],
            [-1.8663600, 54.8252900],
            [-1.8656200, 54.8256000],
            [-1.8653300, 54.8256800],
            [-1.8650800, 54.8258000],
            [-1.8650800, 54.8258300],
            [-1.8651800, 54.8260100],
            [-1.8650600, 54.8260500],
            [-1.8649400, 54.8260600],
            [-1.8645700, 54.8261500],
            [-1.8643000, 54.8262000],
            [-1.8638400, 54.8262500],
            [-1.8638500, 54.8262500],
            [-1.8631500, 54.8263300],
            [-1.8623700, 54.8263850],
            [-1.8615900, 54.8264400],
            [-1.8609500, 54.8265500],
            [-1.8600000, 54.8267500],
            [-1.8593500, 54.8269900],
            [-1.8590500, 54.8271200],
            [-1.8583000, 54.8275500],
            [-1.8575300, 54.8280900],
            [-1.8572900, 54.8283400],
            [-1.8569800, 54.8288100],
            [-1.8568100, 54.8290600],
            [-1.8567100, 54.8293100],
            [-1.8566400, 54.8298000],
            [-1.8566100, 54.8302800],
            [-1.8566500, 54.8306500],
            [-1.8566700, 54.8310200],
            [-1.8565550, 54.8315400],
            [-1.8564400, 54.8320600],
            [-1.8561300, 54.8326600],
            [-1.8556900, 54.8332200],
            [-1.8553200, 54.8335800],
            [-1.8545800, 54.8341800],
            [-1.8542000, 54.8344500],
            [-1.8542100, 54.8344400],
            [-1.8534475, 54.8349850],
            [-1.8526850, 54.8355300],
            [-1.8519225, 54.8360750],
            [-1.8511600, 54.8366200],
            [-1.8503400, 54.8372150],
            [-1.8495200, 54.8378100],
            [-1.8485434, 54.8383833],
            [-1.8475667, 54.8389567],
            [-1.8465900, 54.8395300],
            [-1.8464100, 54.8396000],
            [-1.8457000, 54.8401400],
            [-1.8446400, 54.8405700],
            [-1.8435401, 54.8411975],
            [-1.8424401, 54.8418250],
            [-1.8413401, 54.8424525],
            [-1.8402400, 54.8430800],
            [-1.8394950, 54.8435200],
            [-1.8387500, 54.8439600],
            [-1.8378650, 54.8445300],
            [-1.8369800, 54.8451000],
            [-1.8369100, 54.8452600],
            [-1.8369500, 54.8454400],
            [-1.8370200, 54.8455900],
            [-1.8370200, 54.8456600],
            [-1.8369200, 54.8460300],
            [-1.8367900, 54.8463100],
            [-1.8367100, 54.8464300],
            [-1.8359800, 54.8467700]
        ]
    }
}