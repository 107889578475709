export const causeyBridleway = {
    'type': 'Feature',
    'properties': {
        'color': '#0D1F22',
        'description': 'Bridleway 😎</br> Causey Bridleway'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.6835700, 54.9007200],
          [-1.6831700, 54.9012500],
          [-1.6827700, 54.9015300],
          [-1.6827300, 54.9015900],
          [-1.6827000, 54.9017100],
          [-1.6827275, 54.9024700],
          [-1.6827550, 54.9032300],
          [-1.6827825, 54.9039900],
          [-1.6828100, 54.9047500],
          [-1.6828400, 54.9048000],
          [-1.6829000, 54.9048300],
          [-1.6831400, 54.9048600],
          [-1.6839300, 54.9047900],
          [-1.6837433, 54.9055600],
          [-1.6835567, 54.9063300],
          [-1.6833700, 54.9071000],
          [-1.6828150, 54.9074250],
          [-1.6822600, 54.9077500],
          [-1.6821200, 54.9078100],
          [-1.6810300, 54.9080300],
          [-1.6810400, 54.9080200],
          [-1.6805300, 54.9081300],
          [-1.6799300, 54.9083200],
          [-1.6792100, 54.9087000],
          [-1.6782500, 54.9089700],
          [-1.6772150, 54.9092550],
          [-1.6761800, 54.9095400],
        ]
    }
};