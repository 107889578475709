export const nufcTrainingGrounds = {
  type: 'Feature',
  properties: {
    color: '#C70039',
    description: 'Dusty Gravel 😎</br> NUFC Training Ground',
  },
  geometry: {
    type: 'LineString',
    coordinates: [
      [-1.56317, 55.00241],
      [-1.56335, 55.00284],
      [-1.56335, 55.00295],
      [-1.56325, 55.00312],
      [-1.56319, 55.00319],
      [-1.56312, 55.00335],
      [-1.56309, 55.00342],
      [-1.56309, 55.00349],
      [-1.56306, 55.00352],
      [-1.56295, 55.00356],
      [-1.56288, 55.00361],
      [-1.56302, 55.00424],
      [-1.56308, 55.00472],
      [-1.56313, 55.0052],
      [-1.56326, 55.00576],
      [-1.56322, 55.00589],
      [-1.56251, 55.00598],
      [-1.56125, 55.00608],
      [-1.56115, 55.00607],
      [-1.56095, 55.00598],
      [-1.56085, 55.00601],
      [-1.56102, 55.00616],
      [-1.56105, 55.00626],
      [-1.56124, 55.0064],
      [-1.56142, 55.00658],
      [-1.56152, 55.00678],
      [-1.56156, 55.00719],
      [-1.56155, 55.00733],
      [-1.56165, 55.00771],
      [-1.56203, 55.00841],
      [-1.5622, 55.00911],
      [-1.56249, 55.00961],
      [-1.56278, 55.01011],
      [-1.56274, 55.0103],
      [-1.56243, 55.01061],
    ],
  },
};
