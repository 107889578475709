export const beamishWest = {
    'type': 'Feature',
    'properties': {
        'color': '#481D24',
        'description': 'Dirt 🤗</br> Beamish West'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.6658500, 54.8773700],
          [-1.6667100, 54.8775400],
          [-1.6676200, 54.8777900],
          [-1.6687650, 54.8779400],
          [-1.6699100, 54.8780900],
          [-1.6702000, 54.8781200],
          [-1.6712000, 54.8783500],
          [-1.6722000, 54.8785800],
          [-1.6732600, 54.8788500],
          [-1.6739000, 54.8790300],
          [-1.6742600, 54.8792700],
          [-1.6744700, 54.8795000],
          [-1.6746500, 54.8798200],
          [-1.6746300, 54.8803400],
          [-1.6746000, 54.8807500],
          [-1.6746700, 54.8811300],
          [-1.6748900, 54.8814300],
          [-1.6752700, 54.8819200],
          [-1.6753100, 54.8820800],
          [-1.6752800, 54.8822500],
          [-1.6757200, 54.8828400],
          [-1.6754900, 54.8833900],
          [-1.6755700, 54.8837900],
          [-1.6760000, 54.8842200],
          [-1.6762000, 54.8843900],
          [-1.6763800, 54.8845100],
          [-1.6766000, 54.8846100],
          [-1.6768400, 54.8847800],
          [-1.6769900, 54.8848700],
          [-1.6772400, 54.8850600],
          [-1.6773600, 54.8851500],
          [-1.6775600, 54.8852500],
          [-1.6776200, 54.8852900],
          [-1.6778100, 54.8853300],
          [-1.6779500, 54.8853600],
          [-1.6783500, 54.8853200],
          [-1.6786500, 54.8853300],
          [-1.6789400, 54.8853300],
          [-1.6794800, 54.8854400],
          [-1.6796500, 54.8854800],
          [-1.6799300, 54.8855100],
          [-1.6799800, 54.8855200],
          [-1.6801200, 54.8855000],
          [-1.6808300, 54.8854900],
          [-1.6811600, 54.8856000],
          [-1.6814600, 54.8858100],
          [-1.6818400, 54.8859900],
          [-1.6824100, 54.8860900],
          [-1.6826500, 54.8862700],
        ]
    }
};