export const risingSunKillingworthWaggon = {
    'type': 'Feature',
    'properties': {
        'color': '#000',
        'description': 'Link 🥱</br>  South Rising Sun 👈👉 KillingWorth Waggonway'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
            [-1.5244300,  55.0034900],
            [-1.5241400,  55.0035400],
            [-1.5241500,  55.0035700],
            [-1.5244200,  55.0035100],
            [-1.5246100,  55.0038200],
            [-1.5243800,  55.0038700],
            [-1.5243900,  55.0039000],
            [-1.5246800,  55.0038400],
            [-1.5246300,  55.0037800],
            [-1.5247000,  55.0037600],
            [-1.5247200,  55.0038600],
            [-1.5243800,  55.0039300],
            [-1.5236400,  55.0043400],
            [-1.5236500,  55.0045300],
            [-1.5236900,  55.0046700],
            [-1.5236700,  55.0049600],
            [-1.5237500,  55.0051400],
            [-1.5238600,  55.0052700],
            [-1.5240500,  55.0052800],
            [-1.5240600,  55.0053600],
            [-1.5240100,  55.0055300],
            [-1.5240100,  55.0055500],
            [-1.5241100,  55.0056000],
            [-1.5241000,  55.0057400],
            [-1.5238400,  55.0064800],
            [-1.5238300,  55.0065000],
            [-1.5230300,  55.0066200],
            [-1.5229500,  55.0067600],
            [-1.5228200,  55.0071300],
            [-1.5225400,  55.0073300],
            [-1.5222400,  55.0074700],
            [-1.5221700,  55.0075200],
            [-1.5218900,  55.0081000],
            [-1.5221500,  55.0084900],
            [-1.5225900,  55.0092100],
            [-1.5226800,  55.0093100],
            [-1.5226600,  55.0094000],
            [-1.5221700,  55.0101500],
            [-1.5219900,  55.0104800],
            [-1.5214600,  55.0110300],
            [-1.5211600,  55.0112200],
            [-1.5211100,  55.0113000],
            [-1.5211700,  55.0114000],
            [-1.5216900,  55.0116400],
            [-1.5217000,  55.0117300],
            [-1.5217300,  55.0118300]            
        ]
    }
}