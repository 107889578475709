export const dinningtonEast =     {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Dusty Gravel 😎</br> Dinnington East'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [  
            [-1.6422600, 55.0583800],
            [-1.6423700, 55.0583500],
            [-1.6429400, 55.0583400],
            [-1.6442880, 55.0584160],
            [-1.6456360, 55.0584920],
            [-1.6469840, 55.0585680],
            [-1.6483320, 55.0586440],
            [-1.6496800, 55.0587200],
            [-1.6499900, 55.0587000],
            [-1.6513200, 55.0588550],
            [-1.6526500, 55.0590100],
            [-1.6526400, 55.0590200],
            [-1.6538833, 55.0587867],
            [-1.6551267, 55.0585533],
            [-1.6563700, 55.0583200],
            [-1.6567000, 55.0583200],
            [-1.6577200, 55.0581900],
            [-1.6586550, 55.0581750],
            [-1.6595900, 55.0581600],
            [-1.6596800, 55.0581300],
            [-1.6596900, 55.0580800],
            [-1.6596550, 55.0574700],
            [-1.6596200, 55.0568600],
            [-1.6596100, 55.0568600],
            [-1.6610733, 55.0567134],
            [-1.6625367, 55.0565667],
            [-1.6640000, 55.0564200],
            [-1.6651133, 55.0563200],
            [-1.6662267, 55.0562200],
            [-1.6673400, 55.0561200],
            [-1.6679300, 55.0561200],
            [-1.6686600, 55.0560800],
            [-1.6693800, 55.0560800],
            [-1.6703300, 55.0561600],
            [-1.6712800, 55.0562400],
            [-1.6718500, 55.0562500],
            [-1.6722600, 55.0562400],
            [-1.6724600, 55.0562200],
            [-1.6732400, 55.0560800],
            [-1.6733800, 55.0560200],
        ]
    }
};