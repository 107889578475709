export const toRisingSun =     {
    'type': 'Feature',
    'properties': {
        'color': '#6A0DAD',
        'description': 'Paved Cycleway 😏</br>South Rising Sun'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
            [-1.5136100, 54.9957600],
            [-1.5142100, 54.9958200],
            [-1.5152900, 54.9960500],
            [-1.5161900, 54.9962000],
            [-1.5173850, 54.9963750],
            [-1.5185800, 54.9965500],
            [-1.5199700, 54.9965500],
            [-1.5202800, 54.9965900],
            [-1.5212600, 54.9968100],
            [-1.5222400, 54.9970300],
            [-1.5226300, 54.9970600],
            [-1.5226200, 54.9970700],
            [-1.5229900, 54.9970100],
            [-1.5234300, 54.9968400],
            [-1.5239500, 54.9967600],
            [-1.5243900, 54.9967800],
            [-1.5248200, 54.9968300],
            [-1.5250900, 54.9969200],
            [-1.5253400, 54.9971000],
            [-1.5255600, 54.9972200],
            [-1.5258000, 54.9972600],
            [-1.5261000, 54.9972400],
            [-1.5264800, 54.9971700],
            [-1.5268800, 54.9971300],
            [-1.5274800, 54.9970100]
        ]
    }
};