export const waskerlyWay =     {
    'type': 'Feature',
    'properties': { 
        'color': '#CB3709',
        'description': 'Waggon Way 👌</br> Waskerley Way'
    },
    'geometry':{
        //Waskerley Way (WaggonWay)
        'type': 'LineString',
        'coordinates': [
            [-1.9970800,54.7830800],
            [-1.9967450,54.7837050],
            [-1.9964100,54.7843300],
            [-1.9960100,54.7851617],
            [-1.9956101,54.7859933],
            [-1.9952101,54.7868250],
            [-1.9948101,54.7876567],
            [-1.9944100,54.7884883],
            [-1.9940100,54.7893200],
            [-1.9937100,54.7898400],
            [-1.9934100,54.7903600],
            [-1.9930600,54.7908800],
            [-1.9925900,54.7913150],
            [-1.9921200,54.7917500],
            [-1.9913575,54.7922900],
            [-1.9905950,54.7928300],
            [-1.9898325,54.7933700],
            [-1.9890700,54.7939100],
            [-1.9883750,54.7943200],
            [-1.9876800,54.7947300],
            [-1.9869600,54.7950600],
            [-1.9858500,54.7955100],
            [-1.9847700,54.7959000],
            [-1.9835400,54.7962000],
            [-1.9823700,54.7964350],
            [-1.9812000,54.7966700],
            [-1.9800300,54.7969050],
            [-1.9788600,54.7971400],
            [-1.9781000,54.7973650],
            [-1.9773400,54.7975900],
            [-1.9765400,54.7978600],
            [-1.9757400,54.7981300],
            [-1.9751000,54.7984050],
            [-1.9744600,54.7986800],
            [-1.9732100,54.7990800],
            [-1.9718275,54.7993621],
            [-1.9704449,54.7996442],
            [-1.9690623,54.7999263],
            [-1.9676798,54.8002083],
            [-1.9662971,54.8004904],
            [-1.9649145,54.8007724],
            [-1.9635319,54.8010544],
            [-1.9621492,54.8013364],
            [-1.9607665,54.8016184],
            [-1.9593838,54.8019004],
            [-1.9580011,54.8021823],
            [-1.9566183,54.8024643],
            [-1.9552356,54.8027462],
            [-1.9538528,54.8030281],
            [-1.9524700,54.8033100],
            [-1.9524600,54.8033200],
            [-1.9519000,54.8033600],
            [-1.9504425,54.8033659],
            [-1.9489850,54.8033718],
            [-1.9475275,54.8033777],
            [-1.9460700,54.8033836],
            [-1.9446125,54.8033895],
            [-1.9431550,54.8033953],
            [-1.9416975,54.8034011],
            [-1.9402400,54.8034069],
            [-1.9387825,54.8034127],
            [-1.9373250,54.8034185],
            [-1.9358675,54.8034243],
            [-1.9344100,54.8034300],
            [-1.9331100,54.8034325],
            [-1.9318100,54.8034350],
            [-1.9305100,54.8034375],
            [-1.9292100,54.8034400],
            [-1.9279800,54.8033900],
            [-1.9272400,54.8033600],
            [-1.9258966,54.8032100],
            [-1.9245533,54.8030601],
            [-1.9232100,54.8029101],
            [-1.9218666,54.8027601],
            [-1.9205233,54.8026100],
            [-1.9191800,54.8024600],
            [-1.9178200,54.8022600],
            [-1.9173800,54.8020900],
            [-1.9171100,54.8020600],
            [-1.9162600,54.8020800],
            [-1.9154100,54.8019600],
            [-1.9151800,54.8019600],
            [-1.9137420,54.8017920],
            [-1.9123040,54.8016241],
            [-1.9108660,54.8014561],
            [-1.9094280,54.8012880],
            [-1.9079900,54.8011200],
            [-1.9073600,54.8009700],
            [-1.9060000,54.8006000],
            [-1.9050400,54.8002200],
            [-1.9042600,54.7998400],
            [-1.9036200,54.7994800],
            [-1.9031800,54.7991000],
            [-1.9030400,54.7988800],
            [-1.9028400,54.7985300],
            [-1.9027550,54.7978600],
            [-1.9026700,54.7971900],
            [-1.9026500,54.7965800],
            [-1.9023300,54.7965400],
            [-1.9018600,54.7965300],
            [-1.9017100,54.7965500],
            [-1.9017200,54.7965500],
            [-1.9015800,54.7965900],
            [-1.9014100,54.7968000],
            [-1.9012500,54.7970300],
            [-1.9010800,54.7973800],
            [-1.9009100,54.7977500],
            [-1.9007400,54.7982500],
            [-1.9007000,54.7985300],
            [-1.9006000,54.7988400],
            [-1.9005300,54.7989800],
            [-1.9005200,54.7991300],
            [-1.9006700,54.7998100],
            [-1.9008700,54.7999600],
            [-1.9011750,54.8005350],
            [-1.9014800,54.8011100],
            [-1.9015600,54.8011800],
            [-1.9016900,54.8012400],
            [-1.9017500,54.8012900],
            [-1.9019200,54.8015900],
            [-1.9020300,54.8018300],
            [-1.9021600,54.8023300],
            [-1.9022300,54.8027400],
            [-1.9022700,54.8032100],
            [-1.9022300,54.8032700],
            [-1.9021300,54.8036400],
            [-1.9019600,54.8044300],
            [-1.9017200,54.8050600],
            [-1.9014500,54.8056400],
            [-1.9013100,54.8058800],
            [-1.9008833,54.8064267],
            [-1.9004567,54.8069733],
            [-1.9000300,54.8075200],
            [-1.8993775,54.8083150],
            [-1.8987251,54.8091100],
            [-1.8980725,54.8099050],
            [-1.8974200,54.8107000],
            [-1.8968434,54.8114667],
            [-1.8962667,54.8122333],
            [-1.8956900,54.8130000],
            [-1.8953800,54.8135500],
            [-1.8952600,54.8137000],
            [-1.8951500,54.8138600],
            [-1.8945000,54.8145100],
            [-1.8940000,54.8151200],
            [-1.8936750,54.8156000],
            [-1.8933500,54.8160800],
            [-1.8930100,54.8166750],
            [-1.8926700,54.8172700],
            [-1.8925700,54.8175100],
            [-1.8923850,54.8181200],
            [-1.8922000,54.8187300],
            [-1.8920300,54.8193600],
            [-1.8919700,54.8197400],
            [-1.8919300,54.8202100],
            [-1.8918900,54.8206800],
            [-1.8916500,54.8213500],
            [-1.8913800,54.8218100],
            [-1.8910100,54.8222300],
            [-1.8904900,54.8227100],
            [-1.8905000,54.8227100],
            [-1.8899600,54.8230600],
            [-1.8891100,54.8234700],
            [-1.8882900,54.8237700],
            [-1.8870900,54.8241050],
            [-1.8858900,54.8244400],
            [-1.8847800,54.8246900],
            [-1.8841600,54.8247800],
            [-1.8833500,54.8248700],
            [-1.8828400,54.8250000],
            [-1.8824000,54.8250300],
            [-1.8820900,54.8250700],
            [-1.8812800,54.8250500],
            [-1.8810400,54.8250800],
            [-1.8804200,54.8250300],
            [-1.8792800,54.8248300],
            [-1.8780600,54.8247000],
            [-1.8773000,54.8245400],
            [-1.8757650,54.8244000],
            [-1.8742300,54.8242600],
            [-1.8727700,54.8241000],
            [-1.8722700,54.8240700],
            [-1.8712200,54.8240900],
            [-1.8706400,54.8241400],
            [-1.8700600,54.8242200],
            [-1.8693500,54.8243500],
            [-1.8686700,54.8245000],
            [-1.8681300,54.8246600],
            [-1.8674150,54.8249200],
            [-1.8667000,54.8251800]
        ]
    }
};