export const shiremoor = {
  type: 'Feature',
  properties: {
    color: '#CB3709',
    description: 'Waggon Way 👌 </br> Shiremoor',
  },
  geometry: {
    //Bowes and Pontop (WaggonWay)
    type: 'LineString',
    coordinates: [
      [-1.49262, 55.01312],
      [-1.49272, 55.01324],
      [-1.49304, 55.01361],
      [-1.49343, 55.01416],
      [-1.49391, 55.01477],
      [-1.49463, 55.01544],
      [-1.495035, 55.015845],
      [-1.49544, 55.01625],
      [-1.495945, 55.01672],
      [-1.49645, 55.01719],
      [-1.49689, 55.01764],
      [-1.49692, 55.01771],
      [-1.49713, 55.01791],
      [-1.49774, 55.01848],
      [-1.4984633, 55.0191733],
      [-1.4991866, 55.0198667],
      [-1.49991, 55.02056],
      [-1.5004, 55.021015],
      [-1.50089, 55.02147],
      [-1.50126, 55.02181],
      [-1.50145, 55.02194],
      [-1.502185, 55.02261],
      [-1.50292, 55.02328],
      [-1.50306, 55.02351],
      [-1.50359, 55.024035],
      [-1.50412, 55.02456],
      [-1.50445, 55.0248],
      [-1.50512, 55.02548],
      [-1.5054, 55.02558],
      [-1.5061433, 55.0262767],
      [-1.5068866, 55.0269733],
      [-1.50763, 55.02767],
      [-1.50767, 55.02784],
      [-1.508095, 55.028315],
      [-1.50852, 55.02879],
      [-1.50894, 55.029225],
      [-1.50936, 55.02966],
      [-1.5100966, 55.0304467],
      [-1.5108333, 55.0312333],
      [-1.51157, 55.03202],
      [-1.51226, 55.0327175],
      [-1.51295, 55.033415],
      [-1.51364, 55.0341125],
      [-1.51433, 55.03481],
      [-1.514984, 55.035538],
      [-1.5156379, 55.036266],
      [-1.5162919, 55.036994],
      [-1.516946, 55.037722],
      [-1.5176, 55.03845],
      [-1.51767, 55.03855],
      [-1.51783, 55.03934],
      [-1.5181, 55.03953],
      [-1.51849, 55.03977],
      [-1.51912, 55.0404],
      [-1.51922, 55.04056],
      [-1.5192, 55.04068],
      [-1.51912, 55.04076],
      [-1.51892, 55.04082],
      [-1.51815, 55.04099],
      [-1.51666, 55.04121],
      [-1.51656, 55.04124],
      [-1.51638, 55.0412],
      [-1.51625, 55.0412],
      [-1.51582, 55.04162],
      [-1.515095, 55.0423125],
      [-1.5143701, 55.043005],
      [-1.513645, 55.0436975],
      [-1.51292, 55.04439],
      [-1.51312, 55.04436],
      [-1.51309, 55.04459],
      [-1.51316, 55.04505],
      [-1.51337, 55.04574],
      [-1.51354, 55.046355],
      [-1.51371, 55.04697],
      [-1.5138, 55.04717],
      [-1.51389, 55.04753],
      [-1.5139, 55.04767],
      [-1.514095, 55.048535],
      [-1.51429, 55.0494],
      [-1.51451, 55.0501],
      [-1.51448, 55.05018],
      [-1.51454, 55.0503],
      [-1.51441, 55.05033],
      [-1.51404, 55.05035],
      [-1.51404, 55.0505],
      [-1.51419, 55.05112],
      [-1.51434, 55.05174],
      [-1.51449, 55.05236],
      [-1.51476, 55.05244],
      [-1.5149067, 55.0530433],
      [-1.5150533, 55.0536467],
      [-1.5152, 55.05425],
      [-1.51535, 55.0549],
      [-1.5155, 55.05555],
      [-1.51562, 55.055995],
      [-1.51574, 55.05644],
      [-1.51588, 55.05709],
      [-1.51601, 55.05769],
      [-1.516095, 55.058465],
      [-1.51618, 55.05924],
      [-1.51632, 55.06],
      [-1.51633, 55.06036],
      [-1.51633, 55.06037],
      [-1.51573, 55.06045],
      [-1.51574, 55.06045],
      [-1.51543, 55.06049],
      [-1.51455, 55.060555],
      [-1.51367, 55.06062],
      [-1.513, 55.06065],
      [-1.51214, 55.06062],
      [-1.51173, 55.06062],
      [-1.51128, 55.06057],
      [-1.51128, 55.06058],
      [-1.51038, 55.0605],
      [-1.51002, 55.06048],
      [-1.50995, 55.06058],
      [-1.5098, 55.06103],
      [-1.50965, 55.06148],
      [-1.50945, 55.06211],
      [-1.50913, 55.06225],
      [-1.50882, 55.06244],
      [-1.50846, 55.06265],
      [-1.50724, 55.06268],
      [-1.50602, 55.06271],
      [-1.505045, 55.062495],
      [-1.50407, 55.06228],
      [-1.50379, 55.06235],
    ],
  },
};
