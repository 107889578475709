import { hadriansWayEast } from './hadriansWayEast';
import { toRisingSun } from './toRisingSun';
import { newburnToWylam } from './newburnToWylam';
import { quayside } from './quayside';
import { keelmansWay } from './keelmansWay';
import { toNufc } from './toRisingSun copy';

const pavedCycleways = [
  hadriansWayEast,
  newburnToWylam,
  toRisingSun,
  quayside,
  keelmansWay,
  toNufc,
];

export default pavedCycleways;
