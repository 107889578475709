export const derwentHounsgill = {
    'type': 'Feature',
    'properties': {
        'color': '#000',
        'description': 'Link </br> Derwent Walk 👈👉 Hounsgill'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
            [-1.8508200, 54.8442500],
            [-1.8504200, 54.8443900],
            [-1.8503400, 54.8442700],
            [-1.8503700, 54.8442500],
            [-1.8503800, 54.8442500],
            [-1.8507400, 54.8441300],
            [-1.8507300, 54.8440400],
            [-1.8507100, 54.8438500],
            [-1.8507300, 54.8434200],
            [-1.8507300, 54.8434100],
            [-1.8507400, 54.8432000],
            [-1.8508500, 54.8430900],
            [-1.8508100, 54.8429500],
            [-1.8509100, 54.8422800],
            [-1.8508100, 54.8419400],
            [-1.8503000, 54.8413000],
            [-1.8501800, 54.8410900],
            [-1.8493200, 54.8408400],
            [-1.8487800, 54.8407200],
            [-1.8484700, 54.8406100],
            [-1.8476900, 54.8402200],
            [-1.8471400, 54.8398750],
            [-1.8465900, 54.8395300]
        ]
    }
}