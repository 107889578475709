export const shiremoorBedlingtonDinningtonGosforth = {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Newcastle -> Shiremoor -> Bedlington -> Gosforth </br><a href="https://www.strava.com/routes/2967381311524370226" target="_blank">Strava Route</a>'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.5868400, 54.9803500],
          [-1.5858950, 54.9806600],
          [-1.5849500, 54.9809700],
          [-1.5838200, 54.9813700],
          [-1.5828200, 54.9817100],
          [-1.5818200, 54.9820500],
          [-1.5807900, 54.9822600],
          [-1.5797600, 54.9824700],
          [-1.5798500, 54.9829400],
          [-1.5790000, 54.9830400],
          [-1.5776100, 54.9831400],
          [-1.5762200, 54.9832400],
          [-1.5757200, 54.9832900],
          [-1.5756000, 54.9833800],
          [-1.5747400, 54.9835100],
          [-1.5749600, 54.9841250],
          [-1.5751800, 54.9847400],
          [-1.5742500, 54.9848550],
          [-1.5733200, 54.9849700],
          [-1.5729000, 54.9850300],
          [-1.5718333, 54.9852067],
          [-1.5707667, 54.9853833],
          [-1.5697000, 54.9855600],
          [-1.5690650, 54.9861400],
          [-1.5684300, 54.9867200],
          [-1.5677600, 54.9873000],
          [-1.5672300, 54.9877900],
          [-1.5666000, 54.9884700],
          [-1.5660800, 54.9890900],
          [-1.5655600, 54.9897100],
          [-1.5655600, 54.9897000],
          [-1.5650300, 54.9903400],
          [-1.5645800, 54.9909233],
          [-1.5641300, 54.9915067],
          [-1.5636800, 54.9920900],
          [-1.5632400, 54.9916500],
          [-1.5628200, 54.9912600],
          [-1.5624800, 54.9909100],
          [-1.5615075, 54.9914050],
          [-1.5605350, 54.9919000],
          [-1.5595625, 54.9923950],
          [-1.5585900, 54.9928900],
          [-1.5573281, 54.9933020],
          [-1.5560661, 54.9937140],
          [-1.5548041, 54.9941260],
          [-1.5535421, 54.9945380],
          [-1.5522800, 54.9949500],
          [-1.5517700, 54.9951200],
          [-1.5516800, 54.9949500],
          [-1.5515600, 54.9948300],
          [-1.5514500, 54.9946800],
          [-1.5510300, 54.9941500],
          [-1.5497400, 54.9936600],
          [-1.5486475, 54.9931825],
          [-1.5475549, 54.9927050],
          [-1.5464625, 54.9922275],
          [-1.5453700, 54.9917500],
          [-1.5438733, 54.9919134],
          [-1.5423767, 54.9920767],
          [-1.5408800, 54.9922400],
          [-1.5397580, 54.9926780],
          [-1.5386361, 54.9931160],
          [-1.5375141, 54.9935540],
          [-1.5363920, 54.9939920],
          [-1.5352700, 54.9944300],
          [-1.5340600, 54.9946200],
          [-1.5326234, 54.9949700],
          [-1.5311867, 54.9953200],
          [-1.5297500, 54.9956700],
          [-1.5284400, 54.9960800],
          [-1.5281200, 54.9968800],
          [-1.5273500, 54.9970200],
          [-1.5271100, 54.9975150],
          [-1.5268700, 54.9980100],
          [-1.5266100, 54.9985400],
          [-1.5265100, 54.9992100],
          [-1.5264350, 54.9997000],
          [-1.5263600, 55.0001900],
          [-1.5263800, 55.0005400],
          [-1.5256500, 55.0010267],
          [-1.5249200, 55.0015133],
          [-1.5241900, 55.0020000],
          [-1.5237000, 55.0024700],
          [-1.5240650, 55.0031850],
          [-1.5244300, 55.0039000],
          [-1.5235300, 55.0044300],
          [-1.5236550, 55.0049150],
          [-1.5237800, 55.0054000],
          [-1.5234200, 55.0060333],
          [-1.5230600, 55.0066667],
          [-1.5227000, 55.0073000],
          [-1.5220800, 55.0075100],
          [-1.5218400, 55.0081500],
          [-1.5222800, 55.0087400],
          [-1.5227200, 55.0093300],
          [-1.5212812, 55.0095379],
          [-1.5198425, 55.0097458],
          [-1.5184037, 55.0099537],
          [-1.5169649, 55.0101616],
          [-1.5155260, 55.0103694],
          [-1.5140872, 55.0105773],
          [-1.5126484, 55.0107851],
          [-1.5112095, 55.0109929],
          [-1.5097706, 55.0112007],
          [-1.5083317, 55.0114085],
          [-1.5068928, 55.0116162],
          [-1.5054539, 55.0118239],
          [-1.5040149, 55.0120317],
          [-1.5025760, 55.0122394],
          [-1.5011370, 55.0124470],
          [-1.4996980, 55.0126547],
          [-1.4982590, 55.0128624],
          [-1.4968200, 55.0130700],
          [-1.4953267, 55.0130500],
          [-1.4938333, 55.0130300],
          [-1.4923400, 55.0130100],
          [-1.4931132, 55.0137758],
          [-1.4938864, 55.0145416],
          [-1.4946596, 55.0153073],
          [-1.4954329, 55.0160731],
          [-1.4962062, 55.0168389],
          [-1.4969795, 55.0176046],
          [-1.4977528, 55.0183704],
          [-1.4985262, 55.0191361],
          [-1.4992996, 55.0199018],
          [-1.5000731, 55.0206676],
          [-1.5008465, 55.0214333],
          [-1.5016200, 55.0221990],
          [-1.5023935, 55.0229648],
          [-1.5031671, 55.0237305],
          [-1.5039407, 55.0244962],
          [-1.5047143, 55.0252619],
          [-1.5054880, 55.0260276],
          [-1.5062616, 55.0267933],
          [-1.5070353, 55.0275590],
          [-1.5078091, 55.0283247],
          [-1.5085828, 55.0290904],
          [-1.5093566, 55.0298560],
          [-1.5101304, 55.0306217],
          [-1.5109043, 55.0313874],
          [-1.5116782, 55.0321530],
          [-1.5124521, 55.0329187],
          [-1.5132260, 55.0336844],
          [-1.5140000, 55.0344500],
          [-1.5146687, 55.0352350],
          [-1.5153373, 55.0360200],
          [-1.5160061, 55.0368050],
          [-1.5166748, 55.0375900],
          [-1.5173436, 55.0383750],
          [-1.5180123, 55.0391600],
          [-1.5186812, 55.0399450],
          [-1.5193500, 55.0407300],
          [-1.5178600, 55.0409950],
          [-1.5163700, 55.0412600],
          [-1.5156000, 55.0418500],
          [-1.5148300, 55.0424400],
          [-1.5142267, 55.0430400],
          [-1.5136234, 55.0436400],
          [-1.5130200, 55.0442400],
          [-1.5132214, 55.0450886],
          [-1.5134228, 55.0459371],
          [-1.5136242, 55.0467857],
          [-1.5138257, 55.0476343],
          [-1.5140271, 55.0484829],
          [-1.5142285, 55.0493314],
          [-1.5144300, 55.0501800],
          [-1.5139400, 55.0506700],
          [-1.5141533, 55.0514600],
          [-1.5143666, 55.0522500],
          [-1.5145799, 55.0530400],
          [-1.5147932, 55.0538300],
          [-1.5150066, 55.0546200],
          [-1.5152199, 55.0554100],
          [-1.5154333, 55.0562000],
          [-1.5156466, 55.0569900],
          [-1.5158600, 55.0577800],
          [-1.5161300, 55.0586200],
          [-1.5164000, 55.0594600],
          [-1.5166700, 55.0603000],
          [-1.5152200, 55.0604634],
          [-1.5137700, 55.0606267],
          [-1.5123200, 55.0607900],
          [-1.5109350, 55.0607600],
          [-1.5095500, 55.0607300],
          [-1.5094000, 55.0614950],
          [-1.5092500, 55.0622600],
          [-1.5080600, 55.0627200],
          [-1.5072700, 55.0627450],
          [-1.5064800, 55.0627700],
          [-1.5050900, 55.0624900],
          [-1.5043000, 55.0624300],
          [-1.5035100, 55.0623700],
          [-1.5033100, 55.0631700],
          [-1.5038100, 55.0638500],
          [-1.5028250, 55.0642800],
          [-1.5018400, 55.0647100],
          [-1.5008350, 55.0650150],
          [-1.4998300, 55.0653200],
          [-1.4990300, 55.0654900],
          [-1.4982300, 55.0656600],
          [-1.4968050, 55.0658000],
          [-1.4953800, 55.0659400],
          [-1.4940750, 55.0661600],
          [-1.4927700, 55.0663800],
          [-1.4918200, 55.0664100],
          [-1.4925133, 55.0669200],
          [-1.4932066, 55.0674300],
          [-1.4939000, 55.0679400],
          [-1.4944866, 55.0686483],
          [-1.4950733, 55.0693567],
          [-1.4956599, 55.0700650],
          [-1.4962466, 55.0707733],
          [-1.4968333, 55.0714817],
          [-1.4974200, 55.0721900],
          [-1.4982300, 55.0729233],
          [-1.4990400, 55.0736567],
          [-1.4998500, 55.0743900],
          [-1.5005475, 55.0749950],
          [-1.5012450, 55.0756000],
          [-1.5019425, 55.0762050],
          [-1.5026400, 55.0768100],
          [-1.5033125, 55.0775725],
          [-1.5039849, 55.0783350],
          [-1.5046575, 55.0790975],
          [-1.5053300, 55.0798600],
          [-1.5057616, 55.0806900],
          [-1.5061933, 55.0815200],
          [-1.5066249, 55.0823500],
          [-1.5070566, 55.0831800],
          [-1.5074883, 55.0840100],
          [-1.5079200, 55.0848400],
          [-1.5086400, 55.0856075],
          [-1.5093599, 55.0863750],
          [-1.5100800, 55.0871425],
          [-1.5108000, 55.0879100],
          [-1.5115100, 55.0882500],
          [-1.5119800, 55.0882700],
          [-1.5126850, 55.0886850],
          [-1.5133900, 55.0891000],
          [-1.5138167, 55.0896900],
          [-1.5142433, 55.0902800],
          [-1.5146700, 55.0908700],
          [-1.5138000, 55.0910900],
          [-1.5140433, 55.0917633],
          [-1.5142867, 55.0924367],
          [-1.5145300, 55.0931100],
          [-1.5149400, 55.0931700],
          [-1.5152600, 55.0937450],
          [-1.5155800, 55.0943200],
          [-1.5159900, 55.0945200],
          [-1.5170350, 55.0943000],
          [-1.5180800, 55.0940800],
          [-1.5191650, 55.0936650],
          [-1.5202500, 55.0932500],
          [-1.5208300, 55.0929300],
          [-1.5214100, 55.0926100],
          [-1.5221700, 55.0920600],
          [-1.5218900, 55.0915100],
          [-1.5216100, 55.0909600],
          [-1.5228850, 55.0909675],
          [-1.5241600, 55.0909750],
          [-1.5254350, 55.0909825],
          [-1.5267100, 55.0909900],
          [-1.5266600, 55.0909100],
          [-1.5277200, 55.0903250],
          [-1.5287800, 55.0897400],
          [-1.5296167, 55.0891867],
          [-1.5304534, 55.0886333],
          [-1.5312900, 55.0880800],
          [-1.5310500, 55.0879600],
          [-1.5323150, 55.0876250],
          [-1.5335800, 55.0872900],
          [-1.5336200, 55.0873200],
          [-1.5345050, 55.0870350],
          [-1.5353900, 55.0867500],
          [-1.5363800, 55.0862100],
          [-1.5373500, 55.0856300],
          [-1.5375730, 55.0864780],
          [-1.5377959, 55.0873260],
          [-1.5380189, 55.0881740],
          [-1.5382419, 55.0890220],
          [-1.5384649, 55.0898700],
          [-1.5386879, 55.0907180],
          [-1.5389109, 55.0915660],
          [-1.5391339, 55.0924140],
          [-1.5393570, 55.0932620],
          [-1.5395800, 55.0941100],
          [-1.5398014, 55.0949729],
          [-1.5400228, 55.0958357],
          [-1.5402442, 55.0966986],
          [-1.5404657, 55.0975614],
          [-1.5406871, 55.0984243],
          [-1.5409085, 55.0992871],
          [-1.5411300, 55.1001500],
          [-1.5410600, 55.1004900],
          [-1.5411300, 55.1009000],
          [-1.5414700, 55.1012500],
          [-1.5416700, 55.1020233],
          [-1.5418700, 55.1027967],
          [-1.5420700, 55.1035700],
          [-1.5421700, 55.1039800],
          [-1.5419300, 55.1039600],
          [-1.5420600, 55.1042300],
          [-1.5420400, 55.1042600],
          [-1.5419800, 55.1044200],
          [-1.5418300, 55.1045700],
          [-1.5416700, 55.1046700],
          [-1.5414900, 55.1047300],
          [-1.5414300, 55.1047400],
          [-1.5412900, 55.1047300],
          [-1.5412100, 55.1046800],
          [-1.5403350, 55.1049300],
          [-1.5394600, 55.1051800],
          [-1.5384700, 55.1054150],
          [-1.5374800, 55.1056500],
          [-1.5365300, 55.1059000],
          [-1.5354050, 55.1062800],
          [-1.5342800, 55.1066600],
          [-1.5339100, 55.1067100],
          [-1.5325800, 55.1067200],
          [-1.5323800, 55.1067400],
          [-1.5314400, 55.1069600],
          [-1.5306200, 55.1071800],
          [-1.5298000, 55.1074000],
          [-1.5296200, 55.1074000],
          [-1.5296300, 55.1075400],
          [-1.5296700, 55.1076300],
          [-1.5299740, 55.1083940],
          [-1.5302780, 55.1091580],
          [-1.5305820, 55.1099220],
          [-1.5308860, 55.1106860],
          [-1.5311900, 55.1114500],
          [-1.5311900, 55.1115200],
          [-1.5311500, 55.1116000],
          [-1.5310700, 55.1116700],
          [-1.5310000, 55.1116900],
          [-1.5310700, 55.1116700],
          [-1.5311500, 55.1116000],
          [-1.5313600, 55.1116200],
          [-1.5326667, 55.1113933],
          [-1.5339733, 55.1111667],
          [-1.5352800, 55.1109400],
          [-1.5361100, 55.1108150],
          [-1.5369400, 55.1106900],
          [-1.5370300, 55.1107100],
          [-1.5383840, 55.1105780],
          [-1.5397380, 55.1104460],
          [-1.5410920, 55.1103140],
          [-1.5424460, 55.1101820],
          [-1.5438000, 55.1100500],
          [-1.5452600, 55.1098700],
          [-1.5463600, 55.1096000],
          [-1.5474600, 55.1093300],
          [-1.5481000, 55.1092300],
          [-1.5493400, 55.1091325],
          [-1.5505800, 55.1090350],
          [-1.5518200, 55.1089375],
          [-1.5530600, 55.1088400],
          [-1.5543900, 55.1085767],
          [-1.5557200, 55.1083133],
          [-1.5570500, 55.1080500],
          [-1.5575400, 55.1084400],
          [-1.5580300, 55.1088300],
          [-1.5586400, 55.1092700],
          [-1.5595150, 55.1099950],
          [-1.5603900, 55.1107200],
          [-1.5605900, 55.1107400],
          [-1.5608500, 55.1108100],
          [-1.5609900, 55.1108200],
          [-1.5617600, 55.1106100],
          [-1.5621300, 55.1105500],
          [-1.5626100, 55.1103800],
          [-1.5628600, 55.1103200],
          [-1.5631200, 55.1102800],
          [-1.5632900, 55.1103000],
          [-1.5633900, 55.1103400],
          [-1.5634600, 55.1104100],
          [-1.5634600, 55.1105100],
          [-1.5634300, 55.1106300],
          [-1.5630300, 55.1113300],
          [-1.5627100, 55.1117300],
          [-1.5624700, 55.1120000],
          [-1.5625100, 55.1120800],
          [-1.5625900, 55.1121400],
          [-1.5634700, 55.1123000],
          [-1.5635900, 55.1123200],
          [-1.5637400, 55.1122900],
          [-1.5638300, 55.1121900],
          [-1.5640800, 55.1115700],
          [-1.5641700, 55.1114800],
          [-1.5643200, 55.1113900],
          [-1.5643800, 55.1114300],
          [-1.5644500, 55.1117000],
          [-1.5645400, 55.1120300],
          [-1.5649800, 55.1128200],
          [-1.5651700, 55.1134650],
          [-1.5653600, 55.1141100],
          [-1.5656900, 55.1148333],
          [-1.5660200, 55.1155567],
          [-1.5663500, 55.1162800],
          [-1.5664100, 55.1164800],
          [-1.5663400, 55.1166100],
          [-1.5661700, 55.1166900],
          [-1.5651500, 55.1169750],
          [-1.5641300, 55.1172600],
          [-1.5635900, 55.1173400],
          [-1.5622400, 55.1174600],
          [-1.5620800, 55.1175200],
          [-1.5620700, 55.1175900],
          [-1.5621300, 55.1176700],
          [-1.5629900, 55.1183067],
          [-1.5638500, 55.1189433],
          [-1.5647100, 55.1195800],
          [-1.5647200, 55.1196800],
          [-1.5646400, 55.1197800],
          [-1.5639450, 55.1203950],
          [-1.5632500, 55.1210100],
          [-1.5631600, 55.1211500],
          [-1.5631900, 55.1212900],
          [-1.5637150, 55.1218900],
          [-1.5642400, 55.1224900],
          [-1.5643700, 55.1227300],
          [-1.5643700, 55.1229000],
          [-1.5647600, 55.1233850],
          [-1.5651500, 55.1238700],
          [-1.5654900, 55.1244600],
          [-1.5658300, 55.1250500],
          [-1.5659500, 55.1253500],
          [-1.5663400, 55.1252600],
          [-1.5663500, 55.1252600],
          [-1.5676829, 55.1249643],
          [-1.5690158, 55.1246686],
          [-1.5703487, 55.1243729],
          [-1.5716815, 55.1240772],
          [-1.5730144, 55.1237815],
          [-1.5743472, 55.1234858],
          [-1.5756800, 55.1231900],
          [-1.5762000, 55.1230400],
          [-1.5766400, 55.1228700],
          [-1.5774900, 55.1226800],
          [-1.5778600, 55.1225200],
          [-1.5788800, 55.1229250],
          [-1.5799000, 55.1233300],
          [-1.5808800, 55.1237700],
          [-1.5814500, 55.1241200],
          [-1.5819000, 55.1243900],
          [-1.5819600, 55.1244700],
          [-1.5819700, 55.1245500],
          [-1.5819000, 55.1251200],
          [-1.5819000, 55.1254400],
          [-1.5819400, 55.1257800],
          [-1.5820600, 55.1260700],
          [-1.5822300, 55.1263100],
          [-1.5823700, 55.1264400],
          [-1.5824800, 55.1264900],
          [-1.5826100, 55.1265200],
          [-1.5829500, 55.1265200],
          [-1.5841300, 55.1261500],
          [-1.5847300, 55.1259900],
          [-1.5849800, 55.1259700],
          [-1.5852200, 55.1259700],
          [-1.5855900, 55.1260300],
          [-1.5858600, 55.1261700],
          [-1.5864500, 55.1265600],
          [-1.5868150, 55.1271200],
          [-1.5871800, 55.1276800],
          [-1.5873900, 55.1278500],
          [-1.5877000, 55.1280100],
          [-1.5884250, 55.1283300],
          [-1.5891500, 55.1286500],
          [-1.5897000, 55.1289300],
          [-1.5900600, 55.1291400],
          [-1.5909500, 55.1295800],
          [-1.5918400, 55.1300200],
          [-1.5925400, 55.1303300],
          [-1.5932400, 55.1306400],
          [-1.5938200, 55.1308200],
          [-1.5943700, 55.1309200],
          [-1.5959000, 55.1310900],
          [-1.5972600, 55.1312000],
          [-1.5981100, 55.1312500],
          [-1.5989600, 55.1313000],
          [-1.5994500, 55.1313200],
          [-1.5995900, 55.1313500],
          [-1.5999100, 55.1313500],
          [-1.6000500, 55.1313400],
          [-1.6002300, 55.1313700],
          [-1.6003100, 55.1314400],
          [-1.6003300, 55.1315600],
          [-1.6002100, 55.1316600],
          [-1.6000000, 55.1316900],
          [-1.5998600, 55.1316600],
          [-1.5997500, 55.1315700],
          [-1.5997300, 55.1314200],
          [-1.5996900, 55.1313800],
          [-1.5995900, 55.1313500],
          [-1.5994500, 55.1313200],
          [-1.5989800, 55.1313000],
          [-1.5994500, 55.1313200],
          [-1.5995900, 55.1313500],
          [-1.5999100, 55.1313500],
          [-1.6000500, 55.1313400],
          [-1.6003700, 55.1311300],
          [-1.6005000, 55.1309600],
          [-1.6005400, 55.1306800],
          [-1.6005000, 55.1305800],
          [-1.6004300, 55.1300200],
          [-1.6003200, 55.1293400],
          [-1.6002100, 55.1286600],
          [-1.6001000, 55.1279800],
          [-1.5999900, 55.1273000],
          [-1.5999700, 55.1268900],
          [-1.6000700, 55.1262800],
          [-1.6004800, 55.1254900],
          [-1.6009800, 55.1249500],
          [-1.6013500, 55.1246500],
          [-1.6015600, 55.1245100],
          [-1.6025100, 55.1241100],
          [-1.6035500, 55.1238000],
          [-1.6049200, 55.1235720],
          [-1.6062900, 55.1233440],
          [-1.6076600, 55.1231160],
          [-1.6090300, 55.1228880],
          [-1.6104000, 55.1226600],
          [-1.6112850, 55.1224700],
          [-1.6121700, 55.1222800],
          [-1.6129800, 55.1218900],
          [-1.6137900, 55.1215000],
          [-1.6143350, 55.1209400],
          [-1.6148800, 55.1203800],
          [-1.6154550, 55.1196150],
          [-1.6160300, 55.1188500],
          [-1.6164700, 55.1183000],
          [-1.6168400, 55.1179700],
          [-1.6173100, 55.1176500],
          [-1.6183200, 55.1171175],
          [-1.6193301, 55.1165850],
          [-1.6203400, 55.1160525],
          [-1.6213500, 55.1155200],
          [-1.6222150, 55.1151300],
          [-1.6230800, 55.1147400],
          [-1.6232500, 55.1146500],
          [-1.6233200, 55.1145100],
          [-1.6233300, 55.1144500],
          [-1.6232800, 55.1143400],
          [-1.6230700, 55.1142000],
          [-1.6227000, 55.1140200],
          [-1.6223000, 55.1138700],
          [-1.6220300, 55.1138200],
          [-1.6211800, 55.1137700],
          [-1.6210200, 55.1137000],
          [-1.6208200, 55.1135500],
          [-1.6205300, 55.1132900],
          [-1.6201700, 55.1128500],
          [-1.6200300, 55.1125000],
          [-1.6199100, 55.1120500],
          [-1.6197900, 55.1116000],
          [-1.6196900, 55.1111800],
          [-1.6195300, 55.1108800],
          [-1.6191850, 55.1103700],
          [-1.6188400, 55.1098600],
          [-1.6187400, 55.1096800],
          [-1.6187400, 55.1095200],
          [-1.6188100, 55.1091900],
          [-1.6188700, 55.1086000],
          [-1.6188700, 55.1082700],
          [-1.6188100, 55.1078000],
          [-1.6187700, 55.1076500],
          [-1.6186800, 55.1074500],
          [-1.6183300, 55.1067200],
          [-1.6180800, 55.1062300],
          [-1.6177500, 55.1056800],
          [-1.6175300, 55.1055400],
          [-1.6172800, 55.1055100],
          [-1.6171600, 55.1054700],
          [-1.6169100, 55.1054700],
          [-1.6165000, 55.1054100],
          [-1.6150544, 55.1051856],
          [-1.6136088, 55.1049612],
          [-1.6121632, 55.1047368],
          [-1.6107176, 55.1045124],
          [-1.6092721, 55.1042879],
          [-1.6078265, 55.1040635],
          [-1.6063810, 55.1038390],
          [-1.6049355, 55.1036145],
          [-1.6034900, 55.1033900],
          [-1.6026400, 55.1032800],
          [-1.6017600, 55.1031900],
          [-1.6005400, 55.1031100],
          [-1.5993600, 55.1030900],
          [-1.5983400, 55.1031100],
          [-1.5973200, 55.1031300],
          [-1.5970500, 55.1031500],
          [-1.5966000, 55.1032600],
          [-1.5964500, 55.1033800],
          [-1.5963100, 55.1034600],
          [-1.5962000, 55.1035000],
          [-1.5959600, 55.1035400],
          [-1.5958300, 55.1035300],
          [-1.5956200, 55.1034800],
          [-1.5955000, 55.1034000],
          [-1.5954900, 55.1034000],
          [-1.5953300, 55.1033000],
          [-1.5952500, 55.1032000],
          [-1.5952200, 55.1025300],
          [-1.5953300, 55.1024000],
          [-1.5955800, 55.1022500],
          [-1.5958000, 55.1019900],
          [-1.5956950, 55.1012350],
          [-1.5955900, 55.1004800],
          [-1.5954450, 55.0998450],
          [-1.5953000, 55.0992100],
          [-1.5952300, 55.0987550],
          [-1.5951600, 55.0983000],
          [-1.5951000, 55.0976200],
          [-1.5950400, 55.0969400],
          [-1.5950400, 55.0969500],
          [-1.5950100, 55.0964300],
          [-1.5949100, 55.0963200],
          [-1.5947400, 55.0962800],
          [-1.5945000, 55.0961500],
          [-1.5944500, 55.0960900],
          [-1.5944700, 55.0954100],
          [-1.5945700, 55.0953100],
          [-1.5947400, 55.0952000],
          [-1.5949400, 55.0951300],
          [-1.5955300, 55.0945700],
          [-1.5956600, 55.0946500],
          [-1.5957900, 55.0945900],
          [-1.5966300, 55.0938500],
          [-1.5968500, 55.0936300],
          [-1.5971100, 55.0933500],
          [-1.5974900, 55.0928800],
          [-1.5974900, 55.0928500],
          [-1.5974900, 55.0928400],
          [-1.5975900, 55.0924400],
          [-1.5977133, 55.0918433],
          [-1.5978367, 55.0912467],
          [-1.5979600, 55.0906500],
          [-1.5981350, 55.0901350],
          [-1.5983100, 55.0896200],
          [-1.5983700, 55.0893200],
          [-1.5983700, 55.0891500],
          [-1.5983300, 55.0888800],
          [-1.5982500, 55.0888200],
          [-1.5982700, 55.0886800],
          [-1.5982200, 55.0885500],
          [-1.5981000, 55.0882700],
          [-1.5979800, 55.0881100],
          [-1.5984300, 55.0879300],
          [-1.5980400, 55.0874700],
          [-1.5981400, 55.0873500],
          [-1.5980500, 55.0871800],
          [-1.5979700, 55.0871300],
          [-1.5975600, 55.0870500],
          [-1.5972800, 55.0869500],
          [-1.5971600, 55.0868200],
          [-1.5970800, 55.0865500],
          [-1.5969800, 55.0862000],
          [-1.5969100, 55.0857600],
          [-1.5966800, 55.0857300],
          [-1.5965500, 55.0854300],
          [-1.5966500, 55.0853600],
          [-1.5975600, 55.0850700],
          [-1.5985000, 55.0849200],
          [-1.5986400, 55.0846500],
          [-1.5989550, 55.0838450],
          [-1.5992700, 55.0830400],
          [-1.6006343, 55.0831558],
          [-1.6019985, 55.0832715],
          [-1.6033628, 55.0833872],
          [-1.6047271, 55.0835029],
          [-1.6060914, 55.0836186],
          [-1.6074557, 55.0837343],
          [-1.6088200, 55.0838500],
          [-1.6088800, 55.0833900],
          [-1.6088800, 55.0833800],
          [-1.6089350, 55.0829100],
          [-1.6089900, 55.0824400],
          [-1.6089800, 55.0821600],
          [-1.6091200, 55.0813900],
          [-1.6090500, 55.0810800],
          [-1.6088400, 55.0809800],
          [-1.6084000, 55.0809100],
          [-1.6082300, 55.0808600],
          [-1.6081700, 55.0807600],
          [-1.6082200, 55.0801800],
          [-1.6082150, 55.0794550],
          [-1.6082100, 55.0787300],
          [-1.6081850, 55.0780900],
          [-1.6081600, 55.0774500],
          [-1.6081000, 55.0772900],
          [-1.6079700, 55.0772200],
          [-1.6078200, 55.0771800],
          [-1.6072500, 55.0771300],
          [-1.6070800, 55.0770800],
          [-1.6069800, 55.0770000],
          [-1.6068700, 55.0767300],
          [-1.6064867, 55.0761133],
          [-1.6061033, 55.0754967],
          [-1.6057200, 55.0748800],
          [-1.6051100, 55.0742333],
          [-1.6045000, 55.0735867],
          [-1.6038900, 55.0729400],
          [-1.6032725, 55.0721775],
          [-1.6026550, 55.0714150],
          [-1.6020375, 55.0706525],
          [-1.6014200, 55.0698900],
          [-1.6013400, 55.0697500],
          [-1.6013500, 55.0697500],
          [-1.6015900, 55.0697900],
          [-1.6021600, 55.0698300],
          [-1.6036550, 55.0698500],
          [-1.6051500, 55.0698700],
          [-1.6065500, 55.0698700],
          [-1.6079500, 55.0698700],
          [-1.6093500, 55.0698700],
          [-1.6106125, 55.0698525],
          [-1.6118750, 55.0698350],
          [-1.6131375, 55.0698175],
          [-1.6144000, 55.0698000],
          [-1.6156500, 55.0698300],
          [-1.6170400, 55.0698300],
          [-1.6176500, 55.0697900],
          [-1.6184000, 55.0697000],
          [-1.6191900, 55.0696800],
          [-1.6201550, 55.0697500],
          [-1.6211200, 55.0698200],
          [-1.6216500, 55.0698000],
          [-1.6219300, 55.0697500],
          [-1.6229300, 55.0695100],
          [-1.6229400, 55.0695100],
          [-1.6241600, 55.0692225],
          [-1.6253800, 55.0689350],
          [-1.6266000, 55.0686475],
          [-1.6278200, 55.0683600],
          [-1.6284700, 55.0681800],
          [-1.6289400, 55.0681000],
          [-1.6293800, 55.0680700],
          [-1.6298600, 55.0680800],
          [-1.6303200, 55.0681400],
          [-1.6311800, 55.0683200],
          [-1.6317700, 55.0676100],
          [-1.6320900, 55.0670600],
          [-1.6321500, 55.0669000],
          [-1.6324400, 55.0669500],
          [-1.6325700, 55.0666600],
          [-1.6326600, 55.0666300],
          [-1.6327400, 55.0667000],
          [-1.6329400, 55.0667800],
          [-1.6330500, 55.0667900],
          [-1.6332800, 55.0667500],
          [-1.6333300, 55.0666700],
          [-1.6333200, 55.0665700],
          [-1.6332300, 55.0664400],
          [-1.6342700, 55.0662000],
          [-1.6343900, 55.0662300],
          [-1.6348100, 55.0661300],
          [-1.6349500, 55.0660800],
          [-1.6350400, 55.0660000],
          [-1.6357200, 55.0662400],
          [-1.6360600, 55.0663900],
          [-1.6363700, 55.0665900],
          [-1.6369150, 55.0670950],
          [-1.6374600, 55.0676000],
          [-1.6376200, 55.0677800],
          [-1.6377300, 55.0679900],
          [-1.6378500, 55.0683400],
          [-1.6378600, 55.0685200],
          [-1.6379200, 55.0687100],
          [-1.6380700, 55.0689800],
          [-1.6382000, 55.0691000],
          [-1.6386100, 55.0693500],
          [-1.6388700, 55.0694600],
          [-1.6395700, 55.0696700],
          [-1.6402700, 55.0698800],
          [-1.6408700, 55.0701900],
          [-1.6413100, 55.0705100],
          [-1.6415500, 55.0707300],
          [-1.6428400, 55.0704100],
          [-1.6434100, 55.0702300],
          [-1.6436800, 55.0701800],
          [-1.6440300, 55.0701700],
          [-1.6451133, 55.0702600],
          [-1.6461967, 55.0703500],
          [-1.6472800, 55.0704400],
          [-1.6482900, 55.0705000],
          [-1.6492100, 55.0705100],
          [-1.6495500, 55.0705500],
          [-1.6496200, 55.0706800],
          [-1.6496600, 55.0711000],
          [-1.6498900, 55.0712300],
          [-1.6509200, 55.0712450],
          [-1.6519500, 55.0712600],
          [-1.6526100, 55.0712100],
          [-1.6536100, 55.0710600],
          [-1.6546700, 55.0708200],
          [-1.6554100, 55.0705600],
          [-1.6560900, 55.0702500],
          [-1.6566000, 55.0701500],
          [-1.6573100, 55.0701500],
          [-1.6583750, 55.0702050],
          [-1.6594400, 55.0702600],
          [-1.6597500, 55.0703100],
          [-1.6604000, 55.0704600],
          [-1.6606100, 55.0704900],
          [-1.6615350, 55.0705350],
          [-1.6624600, 55.0705800],
          [-1.6633100, 55.0706500],
          [-1.6641600, 55.0707200],
          [-1.6654300, 55.0708050],
          [-1.6667000, 55.0708900],
          [-1.6676300, 55.0708250],
          [-1.6685600, 55.0707600],
          [-1.6688800, 55.0707600],
          [-1.6697850, 55.0708450],
          [-1.6706900, 55.0709300],
          [-1.6715500, 55.0709500],
          [-1.6718500, 55.0709300],
          [-1.6727700, 55.0708250],
          [-1.6736900, 55.0707200],
          [-1.6748167, 55.0705533],
          [-1.6759433, 55.0703867],
          [-1.6770700, 55.0702200],
          [-1.6778500, 55.0700900],
          [-1.6780400, 55.0700800],
          [-1.6782200, 55.0701300],
          [-1.6791275, 55.0705850],
          [-1.6800350, 55.0710400],
          [-1.6809425, 55.0714950],
          [-1.6818500, 55.0719500],
          [-1.6828500, 55.0723700],
          [-1.6838500, 55.0727900],
          [-1.6840900, 55.0729400],
          [-1.6846250, 55.0733250],
          [-1.6851600, 55.0737100],
          [-1.6855000, 55.0736300],
          [-1.6855100, 55.0736300],
          [-1.6860500, 55.0735400],
          [-1.6874967, 55.0733234],
          [-1.6889433, 55.0731067],
          [-1.6903900, 55.0728900],
          [-1.6910100, 55.0728600],
          [-1.6908500, 55.0720933],
          [-1.6906900, 55.0713267],
          [-1.6905300, 55.0705600],
          [-1.6903900, 55.0703400],
          [-1.6896719, 55.0695600],
          [-1.6889539, 55.0687800],
          [-1.6882359, 55.0680000],
          [-1.6875179, 55.0672200],
          [-1.6868000, 55.0664400],
          [-1.6860900, 55.0657800],
          [-1.6854100, 55.0651600],
          [-1.6847300, 55.0645400],
          [-1.6846300, 55.0643600],
          [-1.6845700, 55.0640900],
          [-1.6844900, 55.0639800],
          [-1.6843600, 55.0638600],
          [-1.6837500, 55.0634200],
          [-1.6831400, 55.0629800],
          [-1.6829800, 55.0628300],
          [-1.6828700, 55.0626700],
          [-1.6826350, 55.0622100],
          [-1.6824000, 55.0617500],
          [-1.6821700, 55.0613700],
          [-1.6817900, 55.0609750],
          [-1.6814100, 55.0605800],
          [-1.6811700, 55.0603900],
          [-1.6809300, 55.0602900],
          [-1.6805600, 55.0601700],
          [-1.6800900, 55.0600700],
          [-1.6797500, 55.0599700],
          [-1.6787700, 55.0595900],
          [-1.6784600, 55.0595200],
          [-1.6778600, 55.0594500],
          [-1.6775100, 55.0593800],
          [-1.6772700, 55.0593100],
          [-1.6769000, 55.0591200],
          [-1.6765000, 55.0588600],
          [-1.6762900, 55.0586900],
          [-1.6760300, 55.0584200],
          [-1.6756100, 55.0578300],
          [-1.6755100, 55.0577000],
          [-1.6750400, 55.0573500],
          [-1.6749300, 55.0572100],
          [-1.6745300, 55.0565500],
          [-1.6744000, 55.0560600],
          [-1.6733800, 55.0560200],
          [-1.6732400, 55.0560800],
          [-1.6724600, 55.0562200],
          [-1.6722600, 55.0562400],
          [-1.6718500, 55.0562500],
          [-1.6712800, 55.0562400],
          [-1.6703300, 55.0561600],
          [-1.6693800, 55.0560800],
          [-1.6686600, 55.0560800],
          [-1.6679300, 55.0561200],
          [-1.6673400, 55.0561200],
          [-1.6662267, 55.0562200],
          [-1.6651133, 55.0563200],
          [-1.6640000, 55.0564200],
          [-1.6639500, 55.0561500],
          [-1.6638700, 55.0553650],
          [-1.6637900, 55.0545800],
          [-1.6638200, 55.0542700],
          [-1.6636500, 55.0542100],
          [-1.6628700, 55.0537500],
          [-1.6627650, 55.0531850],
          [-1.6626600, 55.0526200],
          [-1.6625300, 55.0518000],
          [-1.6622200, 55.0510600],
          [-1.6621300, 55.0507700],
          [-1.6618900, 55.0499100],
          [-1.6617800, 55.0493000],
          [-1.6618200, 55.0485300],
          [-1.6617700, 55.0482900],
          [-1.6615200, 55.0476900],
          [-1.6615500, 55.0476600],
          [-1.6617800, 55.0476400],
          [-1.6629300, 55.0473000],
          [-1.6628400, 55.0467400],
          [-1.6626000, 55.0462300],
          [-1.6624400, 55.0457900],
          [-1.6623600, 55.0451600],
          [-1.6623600, 55.0446900],
          [-1.6621000, 55.0438067],
          [-1.6618400, 55.0429233],
          [-1.6615800, 55.0420400],
          [-1.6614200, 55.0415500],
          [-1.6613800, 55.0412700],
          [-1.6599900, 55.0412600],
          [-1.6585500, 55.0413300],
          [-1.6585800, 55.0412300],
          [-1.6585800, 55.0412200],
          [-1.6587300, 55.0406400],
          [-1.6585400, 55.0404700],
          [-1.6587900, 55.0404300],
          [-1.6578750, 55.0405850],
          [-1.6569600, 55.0407400],
          [-1.6559600, 55.0408850],
          [-1.6549600, 55.0410300],
          [-1.6537267, 55.0410667],
          [-1.6524933, 55.0411033],
          [-1.6512600, 55.0411400],
          [-1.6497480, 55.0411760],
          [-1.6482360, 55.0412121],
          [-1.6467240, 55.0412481],
          [-1.6452120, 55.0412840],
          [-1.6437000, 55.0413200],
          [-1.6436700, 55.0406900],
          [-1.6429000, 55.0407300],
          [-1.6431550, 55.0401050],
          [-1.6434100, 55.0394800],
          [-1.6432050, 55.0390200],
          [-1.6430000, 55.0385600],
          [-1.6433500, 55.0378400],
          [-1.6432900, 55.0373700],
          [-1.6435125, 55.0365800],
          [-1.6437350, 55.0357900],
          [-1.6439575, 55.0350000],
          [-1.6441800, 55.0342100],
          [-1.6430600, 55.0340300],
          [-1.6419400, 55.0338500],
          [-1.6409150, 55.0339700],
          [-1.6398900, 55.0340900],
          [-1.6395100, 55.0342000],
          [-1.6393100, 55.0340200],
          [-1.6393100, 55.0334300],
          [-1.6392200, 55.0334500],
          [-1.6392800, 55.0335600],
          [-1.6392900, 55.0338300],
          [-1.6392100, 55.0339900],
          [-1.6390100, 55.0341100],
          [-1.6386000, 55.0342000],
          [-1.6378300, 55.0342200],
          [-1.6377500, 55.0342000],
          [-1.6377200, 55.0341700],
          [-1.6376200, 55.0341700],
          [-1.6376200, 55.0342700],
          [-1.6384300, 55.0342600],
          [-1.6388900, 55.0342000],
          [-1.6391200, 55.0341200],
          [-1.6392800, 55.0339900],
          [-1.6393700, 55.0338900],
          [-1.6393600, 55.0334000],
          [-1.6393500, 55.0329100],
          [-1.6393000, 55.0325900],
          [-1.6392200, 55.0323400],
          [-1.6390900, 55.0322900],
          [-1.6390200, 55.0322300],
          [-1.6389800, 55.0321600],
          [-1.6389800, 55.0320800],
          [-1.6390700, 55.0319700],
          [-1.6392800, 55.0316000],
          [-1.6392100, 55.0309900],
          [-1.6392100, 55.0309400],
          [-1.6392800, 55.0308600],
          [-1.6387600, 55.0307900],
          [-1.6379650, 55.0307700],
          [-1.6371700, 55.0307500],
          [-1.6360900, 55.0306300],
          [-1.6358500, 55.0306100],
          [-1.6347300, 55.0304000],
          [-1.6336100, 55.0301900],
          [-1.6324900, 55.0299800],
          [-1.6318000, 55.0298700],
          [-1.6308300, 55.0297400],
          [-1.6295100, 55.0295975],
          [-1.6281900, 55.0294550],
          [-1.6268700, 55.0293125],
          [-1.6255500, 55.0291700],
          [-1.6243100, 55.0290300],
          [-1.6235300, 55.0289200],
          [-1.6230300, 55.0289600],
          [-1.6227500, 55.0289400],
          [-1.6223300, 55.0289900],
          [-1.6221400, 55.0289900],
          [-1.6219900, 55.0289500],
          [-1.6214500, 55.0286400],
          [-1.6211800, 55.0287500],
          [-1.6209100, 55.0288000],
          [-1.6207100, 55.0287900],
          [-1.6205700, 55.0287400],
          [-1.6204400, 55.0286600],
          [-1.6202050, 55.0278150],
          [-1.6199700, 55.0269700],
          [-1.6197900, 55.0262600],
          [-1.6197500, 55.0261800],
          [-1.6190500, 55.0258800],
          [-1.6183000, 55.0255900],
          [-1.6177600, 55.0254000],
          [-1.6173000, 55.0252700],
          [-1.6167800, 55.0251700],
          [-1.6164300, 55.0251700],
          [-1.6155900, 55.0252350],
          [-1.6147500, 55.0253000],
          [-1.6147400, 55.0253000],
          [-1.6138400, 55.0255200],
          [-1.6129400, 55.0255600],
          [-1.6125700, 55.0254200],
          [-1.6128700, 55.0249700],
          [-1.6128100, 55.0246900],
          [-1.6128100, 55.0242400],
          [-1.6128100, 55.0237900],
          [-1.6122050, 55.0233050],
          [-1.6116000, 55.0228200],
          [-1.6105200, 55.0226500],
          [-1.6095500, 55.0223700],
          [-1.6092450, 55.0219350],
          [-1.6089400, 55.0215000],
          [-1.6084000, 55.0207400],
          [-1.6082200, 55.0200100],
          [-1.6080400, 55.0192800],
          [-1.6081000, 55.0187300],
          [-1.6082200, 55.0179000],
          [-1.6079200, 55.0171000],
          [-1.6078600, 55.0165800],
          [-1.6077050, 55.0160050],
          [-1.6075500, 55.0154300],
          [-1.6073400, 55.0146850],
          [-1.6071300, 55.0139400],
          [-1.6064000, 55.0132500],
          [-1.6058600, 55.0128000],
          [-1.6053800, 55.0123100],
          [-1.6047700, 55.0119700],
          [-1.6040500, 55.0113400],
          [-1.6033800, 55.0107200],
          [-1.6027800, 55.0109600],
          [-1.6023500, 55.0107900],
          [-1.6020500, 55.0106800],
          [-1.6015100, 55.0107200],
          [-1.6010200, 55.0107200],
          [-1.6004800, 55.0106100],
          [-1.6000550, 55.0099750],
          [-1.5996300, 55.0093400],
          [-1.5996300, 55.0086400],
          [-1.5997500, 55.0082300],
          [-1.6003000, 55.0075700],
          [-1.6011400, 55.0073300],
          [-1.6016900, 55.0069800],
          [-1.6028400, 55.0069800],
          [-1.6029800, 55.0061233],
          [-1.6031200, 55.0052667],
          [-1.6032600, 55.0044100],
          [-1.6035000, 55.0036500],
          [-1.6044700, 55.0030300],
          [-1.6048900, 55.0025100],
          [-1.6055000, 55.0019800],
          [-1.6056200, 55.0014000],
          [-1.6056200, 55.0009800],
          [-1.6059000, 55.0003900],
          [-1.6061800, 54.9998000],
          [-1.6064600, 54.9992100],
          [-1.6064000, 54.9988300],
          [-1.6055600, 54.9987900],
          [-1.6049500, 54.9986900],
          [-1.6040500, 54.9985200],
          [-1.6035600, 54.9986900],
          [-1.6024100, 54.9986900],
          [-1.6021700, 54.9986200],
          [-1.6012600, 54.9983800],
          [-1.6003250, 54.9980300],
          [-1.5993900, 54.9976800],
          [-1.5986300, 54.9973350],
          [-1.5978700, 54.9969900],
          [-1.5974450, 54.9965900],
          [-1.5970200, 54.9961900],
          [-1.5965400, 54.9957400],
          [-1.5960600, 54.9952900],
          [-1.5956650, 54.9947700],
          [-1.5952700, 54.9942500],
          [-1.5950300, 54.9934500],
          [-1.5950900, 54.9928300],
          [-1.5953900, 54.9925200],
          [-1.5953900, 54.9918900],
          [-1.5952700, 54.9913700],
          [-1.5954500, 54.9905400],
          [-1.5950300, 54.9900900],
          [-1.5944800, 54.9898100],
          [-1.5935200, 54.9891900],
          [-1.5932000, 54.9886300],
          [-1.5933200, 54.9883200],
          [-1.5924750, 54.9886150],
          [-1.5916300, 54.9889100],
          [-1.5907850, 54.9891700],
          [-1.5899400, 54.9894300],
          [-1.5884900, 54.9896400],
          [-1.5878800, 54.9897100],
          [-1.5872800, 54.9889100],
          [-1.5869100, 54.9884600],
          [-1.5864300, 54.9879400],
          [-1.5859500, 54.9873800],
          [-1.5860100, 54.9868600],
          [-1.5859500, 54.9860300],
          [-1.5861300, 54.9854000],
          [-1.5858200, 54.9850200],
          [-1.5852200, 54.9848800],
          [-1.5848550, 54.9841700],
          [-1.5844900, 54.9834600],
          [-1.5847300, 54.9829700],
          [-1.5853400, 54.9825200],
          [-1.5857600, 54.9822800],
          [-1.5866700, 54.9823800],
          [-1.5871500, 54.9822500],
          [-1.5880600, 54.9816200],
          [-1.5878800, 54.9812700],
          [-1.5875150, 54.9808200],
          [-1.5871500, 54.9803700],
        ]
    }
};