export const derwentWalk = {
    'type': 'Feature',
    'properties': {
        'color': '#CB3709',
        'description': 'Waggon Way 👌 </br> Derwent Walk'
    },
    'geometry':{
        //Derwent Walk (Waggonway)
        'type': 'LineString',
        'coordinates': [
            [-1.6918100, 54.9520200],
            [-1.6919200, 54.9512500],
            [-1.6919800, 54.9511800],
            [-1.6929100, 54.9507033],
            [-1.6938400, 54.9502267],
            [-1.6947700, 54.9497500],
            [-1.6958850, 54.9491450],
            [-1.6970000, 54.9485400],
            [-1.6977850, 54.9480850],
            [-1.6985700, 54.9476300],
            [-1.6994800, 54.9471633],
            [-1.7003900, 54.9466967],
            [-1.7013000, 54.9462300],
            [-1.7025600, 54.9457900],
            [-1.7034250, 54.9454550],
            [-1.7042900, 54.9451200],
            [-1.7050700, 54.9446500],
            [-1.7058500, 54.9441800],
            [-1.7063400, 54.9436700],
            [-1.7068300, 54.9431600],
            [-1.7072250, 54.9424250],
            [-1.7076200, 54.9416900],
            [-1.7079667, 54.9410900],
            [-1.7083133, 54.9404900],
            [-1.7086600, 54.9398900],
            [-1.7091467, 54.9390767],
            [-1.7096334, 54.9382633],
            [-1.7101200, 54.9374500],
            [-1.7106600, 54.9367800],
            [-1.7112900, 54.9362400],
            [-1.7121800, 54.9356600],
            [-1.7132400, 54.9351000],
            [-1.7140000, 54.9348600],
            [-1.7147600, 54.9346200],
            [-1.7161650, 54.9343300],
            [-1.7175700, 54.9340400],
            [-1.7187700, 54.9338033],
            [-1.7199700, 54.9335667],
            [-1.7211700, 54.9333300],
            [-1.7219550, 54.9331600],
            [-1.7227400, 54.9329900],
            [-1.7234500, 54.9328000],
            [-1.7247021, 54.9323320],
            [-1.7259541, 54.9318640],
            [-1.7272061, 54.9313960],
            [-1.7284581, 54.9309280],
            [-1.7297100, 54.9304600],
            [-1.7306800, 54.9301150],
            [-1.7316500, 54.9297700],
            [-1.7323050, 54.9295050],
            [-1.7329600, 54.9292400],
            [-1.7338650, 54.9288850],
            [-1.7347700, 54.9285300],
            [-1.7353900, 54.9281950],
            [-1.7360100, 54.9278600],
            [-1.7368400, 54.9273200],
            [-1.7376700, 54.9267800],
            [-1.7385350, 54.9261750],
            [-1.7394000, 54.9255700],
            [-1.7398500, 54.9251400],
            [-1.7403300, 54.9244100],
            [-1.7402400, 54.9241900],
            [-1.7400100, 54.9237900],
            [-1.7392800, 54.9230800],
            [-1.7391600, 54.9229500],
            [-1.7390300, 54.9227200],
            [-1.7390900, 54.9226900],
            [-1.7390400, 54.9225800],
            [-1.7389700, 54.9223300],
            [-1.7389900, 54.9221500],
            [-1.7390600, 54.9218800],
            [-1.7389700, 54.9218100],
            [-1.7389300, 54.9215900],
            [-1.7390650, 54.9209750],
            [-1.7392000, 54.9203600],
            [-1.7393850, 54.9196400],
            [-1.7395700, 54.9189200],
            [-1.7397800, 54.9182200],
            [-1.7401200, 54.9182300],
            [-1.7401800, 54.9180900],
            [-1.7406900, 54.9177200],
            [-1.7409600, 54.9173800],
            [-1.7414000, 54.9168100],
            [-1.7418400, 54.9162400],
            [-1.7422400, 54.9157700],
            [-1.7427700, 54.9153850],
            [-1.7433000, 54.9150000],
            [-1.7444500, 54.9144700],
            [-1.7452300, 54.9142300],
            [-1.7460100, 54.9139900],
            [-1.7469700, 54.9137400],
            [-1.7479300, 54.9134900],
            [-1.7488900, 54.9132400],
            [-1.7499600, 54.9128850],
            [-1.7510300, 54.9125300],
            [-1.7521379, 54.9119947],
            [-1.7532457, 54.9114593],
            [-1.7543535, 54.9109240],
            [-1.7554613, 54.9103886],
            [-1.7565691, 54.9098533],
            [-1.7576768, 54.9093179],
            [-1.7587845, 54.9087825],
            [-1.7598921, 54.9082471],
            [-1.7609998, 54.9077117],
            [-1.7621074, 54.9071763],
            [-1.7632149, 54.9066409],
            [-1.7643225, 54.9061054],
            [-1.7654300, 54.9055700],
            [-1.7656000, 54.9055200],
            [-1.7658500, 54.9055200],
            [-1.7666200, 54.9051400],
            [-1.7666700, 54.9050900],
            [-1.7667300, 54.9049800],
            [-1.7675800, 54.9045600],
            [-1.7687000, 54.9040350],
            [-1.7698200, 54.9035100],
            [-1.7707100, 54.9030625],
            [-1.7716000, 54.9026150],
            [-1.7724900, 54.9021675],
            [-1.7733800, 54.9017200],
            [-1.7742867, 54.9012800],
            [-1.7751934, 54.9008400],
            [-1.7761000, 54.9004000],
            [-1.7768350, 54.9000900],
            [-1.7775700, 54.8997800],
            [-1.7789600, 54.8994800],
            [-1.7792900, 54.8993300],
            [-1.7796200, 54.8993100],
            [-1.7807800, 54.8991467],
            [-1.7819400, 54.8989833],
            [-1.7831000, 54.8988200],
            [-1.7841400, 54.8987700],
            [-1.7855300, 54.8986033],
            [-1.7869200, 54.8984367],
            [-1.7883100, 54.8982700],
            [-1.7895167, 54.8981333],
            [-1.7907233, 54.8979967],
            [-1.7919300, 54.8978600],
            [-1.7919500, 54.8978600],
            [-1.7928250, 54.8977450],
            [-1.7937000, 54.8976300],
            [-1.7940400, 54.8976400],
            [-1.7948500, 54.8975400],
            [-1.7959700, 54.8974800],
            [-1.7967800, 54.8974800],
            [-1.7976700, 54.8975100],
            [-1.7986500, 54.8976100],
            [-1.7996600, 54.8977400],
            [-1.8010287, 54.8979788],
            [-1.8023974, 54.8982176],
            [-1.8037661, 54.8984564],
            [-1.8051349, 54.8986951],
            [-1.8065036, 54.8989339],
            [-1.8078724, 54.8991726],
            [-1.8092412, 54.8994113],
            [-1.8106100, 54.8996500],
            [-1.8114300, 54.8998400],
            [-1.8125800, 54.9000000],
            [-1.8137300, 54.9001600],
            [-1.8149800, 54.9002200],
            [-1.8163400, 54.9001800],
            [-1.8169500, 54.9001200],
            [-1.8179667, 54.8999267],
            [-1.8189833, 54.8997333],
            [-1.8200000, 54.8995400],
            [-1.8212340, 54.8992820],
            [-1.8224680, 54.8990240],
            [-1.8237020, 54.8987660],
            [-1.8249360, 54.8985080],
            [-1.8261700, 54.8982500],
            [-1.8267800, 54.8980800],
            [-1.8273200, 54.8978800],
            [-1.8280400, 54.8975400],
            [-1.8287100, 54.8971600],
            [-1.8293300, 54.8966900],
            [-1.8299000, 54.8961600],
            [-1.8302800, 54.8957200],
            [-1.8304650, 54.8952100],
            [-1.8306500, 54.8947000],
            [-1.8307200, 54.8941400],
            [-1.8306850, 54.8936550],
            [-1.8306500, 54.8931700],
            [-1.8306600, 54.8925200],
            [-1.8307800, 54.8920400],
            [-1.8309900, 54.8913800],
            [-1.8314300, 54.8908500],
            [-1.8320300, 54.8901800],
            [-1.8329250, 54.8894800],
            [-1.8338200, 54.8887800],
            [-1.8343350, 54.8884000],
            [-1.8348500, 54.8880200],
            [-1.8356025, 54.8874525],
            [-1.8363550, 54.8868850],
            [-1.8371075, 54.8863175],
            [-1.8378600, 54.8857500],
            [-1.8385400, 54.8850200],
            [-1.8388450, 54.8845800],
            [-1.8391500, 54.8841400],
            [-1.8395350, 54.8834250],
            [-1.8399200, 54.8827100],
            [-1.8401300, 54.8823500],
            [-1.8405100, 54.8815900],
            [-1.8407200, 54.8812200],
            [-1.8408800, 54.8809500],
            [-1.8412200, 54.8802400],
            [-1.8415900, 54.8795600],
            [-1.8416750, 54.8790250],
            [-1.8417600, 54.8784900],
            [-1.8417600, 54.8778200],
            [-1.8416250, 54.8773250],
            [-1.8414900, 54.8768300],
            [-1.8412200, 54.8763900],
            [-1.8409500, 54.8759500],
            [-1.8403933, 54.8751967],
            [-1.8398366, 54.8744433],
            [-1.8392800, 54.8736900],
            [-1.8389100, 54.8730000],
            [-1.8387100, 54.8721700],
            [-1.8386700, 54.8713700],
            [-1.8387800, 54.8705100],
            [-1.8389800, 54.8697900],
            [-1.8392900, 54.8692600],
            [-1.8396200, 54.8687700],
            [-1.8399800, 54.8683350],
            [-1.8403400, 54.8679000],
            [-1.8406000, 54.8676600],
            [-1.8408800, 54.8674200],
            [-1.8414200, 54.8670500],
            [-1.8419600, 54.8666800],
            [-1.8425500, 54.8663100],
            [-1.8431800, 54.8658900],
            [-1.8437250, 54.8654650],
            [-1.8442700, 54.8650400],
            [-1.8446300, 54.8644500],
            [-1.8449200, 54.8637800],
            [-1.8452200, 54.8630200],
            [-1.8452200, 54.8624500],
            [-1.8452300, 54.8624300],
            [-1.8452400, 54.8616850],
            [-1.8452500, 54.8609400],
            [-1.8453800, 54.8608000],
            [-1.8457100, 54.8608600],
            [-1.8457550, 54.8603650],
            [-1.8458000, 54.8598700],
            [-1.8461100, 54.8590100],
            [-1.8462400, 54.8588100],
            [-1.8462000, 54.8586600],
            [-1.8462000, 54.8584000],
            [-1.8463100, 54.8580500],
            [-1.8463400, 54.8579500],
            [-1.8464100, 54.8578200],
            [-1.8467400, 54.8575200],
            [-1.8469900, 54.8572300],
            [-1.8472900, 54.8566800],
            [-1.8473900, 54.8565700],
            [-1.8475200, 54.8563400],
            [-1.8476300, 54.8560800],
            [-1.8478300, 54.8556700],
            [-1.8479800, 54.8551500],
            [-1.8481300, 54.8546300],
            [-1.8482400, 54.8544400],
            [-1.8483700, 54.8541600],
            [-1.8484500, 54.8537900],
            [-1.8486000, 54.8534200],
            [-1.8489500, 54.8530500],
            [-1.8491300, 54.8529000],
            [-1.8494900, 54.8526700],
            [-1.8497000, 54.8525000],
            [-1.8500000, 54.8522100],
            [-1.8501300, 54.8520300],
            [-1.8502700, 54.8517800],
            [-1.8503100, 54.8515600],
            [-1.8503000, 54.8513700],
            [-1.8502700, 54.8512000],
            [-1.8501100, 54.8510200],
            [-1.8499600, 54.8508900],
            [-1.8495600, 54.8506400],
            [-1.8493900, 54.8504000],
            [-1.8493500, 54.8502400],
            [-1.8492600, 54.8501000],
            [-1.8491600, 54.8498500],
            [-1.8491600, 54.8497400],
            [-1.8492400, 54.8494100],
            [-1.8496600, 54.8486200],
            [-1.8498600, 54.8483500],
            [-1.8507800, 54.8478300],
            [-1.8511000, 54.8476100],
            [-1.8512200, 54.8474900],
            [-1.8514900, 54.8467800],
            [-1.8518300, 54.8463100],
            [-1.8519300, 54.8460900],
            [-1.8519700, 54.8459100],
            [-1.8519300, 54.8457200],
            [-1.8515400, 54.8452850],
            [-1.8511500, 54.8448500],
            [-1.8508200, 54.8442500]
        ]
    }
}