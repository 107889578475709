export const dirtyReiver22 =     {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Dusty Gravel 😎</br> Dirty Reiver 2022</br><a href="https://www.strava.com/routes/2954315093153394660" target="_blank">Strava Route</a>'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-2.5608500, 55.2493970],
          [-2.5607550, 55.2494640],
          [-2.5606600, 55.2495300],
          [-2.5605720, 55.2495880],
          [-2.5604840, 55.2496450],
          [-2.5603980, 55.2496980],
          [-2.5603140, 55.2497480],
          [-2.5602280, 55.2498010],
          [-2.5601350, 55.2498500],
          [-2.5600410, 55.2499040],
          [-2.5599490, 55.2499600],
          [-2.5598550, 55.2500140],
          [-2.5597630, 55.2500750],
          [-2.5596700, 55.2501320],
          [-2.5595710, 55.2501900],
          [-2.5594780, 55.2502460],
          [-2.5593840, 55.2503050],
          [-2.5592930, 55.2503650],
          [-2.5592010, 55.2504230],
          [-2.5591030, 55.2504760],
          [-2.5588060, 55.2506630],
          [-2.5564160, 55.2522250],
          [-2.5563740, 55.2522540],
          [-2.5562910, 55.2523070],
          [-2.5562110, 55.2523580],
          [-2.5561340, 55.2524070],
          [-2.5560580, 55.2524560],
          [-2.5559840, 55.2525060],
          [-2.5559140, 55.2525520],
          [-2.5558470, 55.2526010],
          [-2.5557820, 55.2526470],
          [-2.5557190, 55.2526890],
          [-2.5556560, 55.2527350],
          [-2.5555920, 55.2527800],
          [-2.5555290, 55.2528220],
          [-2.5554660, 55.2528640],
          [-2.5554010, 55.2529150],
          [-2.5553360, 55.2529640],
          [-2.5552630, 55.2530200],
          [-2.5551860, 55.2530850],
          [-2.5551010, 55.2531540],
          [-2.5550150, 55.2532260],
          [-2.5549290, 55.2533100],
          [-2.5548540, 55.2533980],
          [-2.5547980, 55.2534960],
          [-2.5547440, 55.2535920],
          [-2.5546800, 55.2536940],
          [-2.5545900, 55.2537940],
          [-2.5544750, 55.2538860],
          [-2.5543430, 55.2539630],
          [-2.5542040, 55.2540310],
          [-2.5540660, 55.2540850],
          [-2.5539330, 55.2541380],
          [-2.5537990, 55.2541880],
          [-2.5536700, 55.2542490],
          [-2.5535600, 55.2543100],
          [-2.5534420, 55.2543750],
          [-2.5533240, 55.2544360],
          [-2.5532100, 55.2545010],
          [-2.5531000, 55.2545690],
          [-2.5529930, 55.2546420],
          [-2.5528830, 55.2547180],
          [-2.5527680, 55.2548020],
          [-2.5526650, 55.2548860],
          [-2.5525560, 55.2549700],
          [-2.5524630, 55.2550560],
          [-2.5523610, 55.2551380],
          [-2.5522550, 55.2552170],
          [-2.5521540, 55.2552970],
          [-2.5520430, 55.2553820],
          [-2.5519490, 55.2554620],
          [-2.5518440, 55.2555340],
          [-2.5517390, 55.2556150],
          [-2.5516500, 55.2556910],
          [-2.5515480, 55.2557590],
          [-2.5514630, 55.2558360],
          [-2.5513780, 55.2559040],
          [-2.5513000, 55.2559780],
          [-2.5512120, 55.2560500],
          [-2.5511530, 55.2561010],
          [-2.5510600, 55.2561870],
          [-2.5509840, 55.2562520],
          [-2.5509040, 55.2563130],
          [-2.5508070, 55.2563620],
          [-2.5506910, 55.2564010],
          [-2.5505660, 55.2564200],
          [-2.5504410, 55.2564310],
          [-2.5503130, 55.2564390],
          [-2.5501820, 55.2564390],
          [-2.5500600, 55.2564470],
          [-2.5499410, 55.2564510],
          [-2.5498250, 55.2564540],
          [-2.5497110, 55.2564580],
          [-2.5496050, 55.2564580],
          [-2.5495020, 55.2564580],
          [-2.5493960, 55.2564580],
          [-2.5492810, 55.2564580],
          [-2.5491530, 55.2564620],
          [-2.5490170, 55.2564700],
          [-2.5488820, 55.2564730],
          [-2.5487530, 55.2564740],
          [-2.5486310, 55.2564770],
          [-2.5485140, 55.2564770],
          [-2.5483940, 55.2564810],
          [-2.5482870, 55.2564850],
          [-2.5481890, 55.2564850],
          [-2.5480870, 55.2564890],
          [-2.5479920, 55.2564930],
          [-2.5478940, 55.2564850],
          [-2.5478100, 55.2564590],
          [-2.5477470, 55.2564130],
          [-2.5477140, 55.2563560],
          [-2.5477250, 55.2562990],
          [-2.5477610, 55.2562340],
          [-2.5478110, 55.2561690],
          [-2.5478680, 55.2560970],
          [-2.5479230, 55.2560240],
          [-2.5479810, 55.2559480],
          [-2.5480390, 55.2558720],
          [-2.5480960, 55.2557950],
          [-2.5481530, 55.2557150],
          [-2.5482160, 55.2556390],
          [-2.5482790, 55.2555630],
          [-2.5483360, 55.2554940],
          [-2.5483900, 55.2554230],
          [-2.5484420, 55.2553500],
          [-2.5484970, 55.2552850],
          [-2.5485490, 55.2552210],
          [-2.5485980, 55.2551620],
          [-2.5486360, 55.2551050],
          [-2.5486740, 55.2550520],
          [-2.5487060, 55.2549940],
          [-2.5487330, 55.2549410],
          [-2.5487590, 55.2548920],
          [-2.5487830, 55.2548460],
          [-2.5488010, 55.2547930],
          [-2.5488210, 55.2547390],
          [-2.5488400, 55.2546900],
          [-2.5488530, 55.2546360],
          [-2.5488710, 55.2545840],
          [-2.5488860, 55.2545300],
          [-2.5489050, 55.2544710],
          [-2.5489210, 55.2544140],
          [-2.5489400, 55.2543570],
          [-2.5489600, 55.2543000],
          [-2.5489810, 55.2542400],
          [-2.5490050, 55.2541780],
          [-2.5490300, 55.2541130],
          [-2.5490610, 55.2540520],
          [-2.5490950, 55.2539880],
          [-2.5491330, 55.2539220],
          [-2.5491690, 55.2538540],
          [-2.5492070, 55.2537860],
          [-2.5492510, 55.2537190],
          [-2.5493020, 55.2536490],
          [-2.5493470, 55.2535770],
          [-2.5493960, 55.2535030],
          [-2.5494500, 55.2534240],
          [-2.5495160, 55.2533430],
          [-2.5495940, 55.2532550],
          [-2.5496790, 55.2531670],
          [-2.5497530, 55.2530780],
          [-2.5498320, 55.2529790],
          [-2.5499110, 55.2528770],
          [-2.5499790, 55.2527830],
          [-2.5500410, 55.2526910],
          [-2.5501160, 55.2525920],
          [-2.5501870, 55.2524960],
          [-2.5502710, 55.2524040],
          [-2.5503440, 55.2523110],
          [-2.5504070, 55.2522240],
          [-2.5504920, 55.2521300],
          [-2.5505600, 55.2520390],
          [-2.5506240, 55.2519470],
          [-2.5506900, 55.2518600],
          [-2.5507640, 55.2517720],
          [-2.5508430, 55.2516800],
          [-2.5509000, 55.2515900],
          [-2.5509420, 55.2514950],
          [-2.5509580, 55.2514090],
          [-2.5509340, 55.2513210],
          [-2.5509090, 55.2512380],
          [-2.5508870, 55.2511570],
          [-2.5508670, 55.2510810],
          [-2.5508470, 55.2510090],
          [-2.5508300, 55.2509400],
          [-2.5508160, 55.2508750],
          [-2.5507990, 55.2508190],
          [-2.5507800, 55.2507600],
          [-2.5507610, 55.2507070],
          [-2.5507440, 55.2506570],
          [-2.5507290, 55.2506080],
          [-2.5507170, 55.2505650],
          [-2.5507050, 55.2505230],
          [-2.5506970, 55.2504900],
          [-2.5506920, 55.2504540],
          [-2.5506870, 55.2504240],
          [-2.5506830, 55.2503940],
          [-2.5506830, 55.2503630],
          [-2.5506870, 55.2503320],
          [-2.5506920, 55.2503060],
          [-2.5506980, 55.2502750],
          [-2.5507080, 55.2502450],
          [-2.5507200, 55.2502110],
          [-2.5507310, 55.2501840],
          [-2.5507450, 55.2501500],
          [-2.5507610, 55.2501190],
          [-2.5507760, 55.2500850],
          [-2.5507900, 55.2500500],
          [-2.5508050, 55.2500160],
          [-2.5508220, 55.2499810],
          [-2.5508400, 55.2499470],
          [-2.5508580, 55.2499090],
          [-2.5508710, 55.2498710],
          [-2.5508860, 55.2498330],
          [-2.5509030, 55.2497940],
          [-2.5509160, 55.2497610],
          [-2.5509300, 55.2497220],
          [-2.5509430, 55.2496920],
          [-2.5509570, 55.2496570],
          [-2.5509660, 55.2496270],
          [-2.5509720, 55.2496000],
          [-2.5509750, 55.2495730],
          [-2.5509800, 55.2495470],
          [-2.5509880, 55.2495230],
          [-2.5509970, 55.2494970],
          [-2.5510000, 55.2494740],
          [-2.5510020, 55.2494470],
          [-2.5510020, 55.2494200],
          [-2.5510030, 55.2493940],
          [-2.5510050, 55.2493670],
          [-2.5510060, 55.2493370],
          [-2.5510070, 55.2493060],
          [-2.5510070, 55.2492760],
          [-2.5510070, 55.2492410],
          [-2.5510100, 55.2492120],
          [-2.5510100, 55.2491800],
          [-2.5510080, 55.2491420],
          [-2.5510080, 55.2491080],
          [-2.5510130, 55.2490740],
          [-2.5510200, 55.2490350],
          [-2.5510280, 55.2490050],
          [-2.5510350, 55.2489710],
          [-2.5510460, 55.2489400],
          [-2.5510560, 55.2489060],
          [-2.5510670, 55.2488750],
          [-2.5510770, 55.2488440],
          [-2.5510860, 55.2488150],
          [-2.5510980, 55.2487850],
          [-2.5511150, 55.2487490],
          [-2.5511380, 55.2487150],
          [-2.5511680, 55.2486730],
          [-2.5512080, 55.2486270],
          [-2.5512640, 55.2485740],
          [-2.5513370, 55.2485250],
          [-2.5514190, 55.2484760],
          [-2.5515040, 55.2484290],
          [-2.5515860, 55.2483870],
          [-2.5516620, 55.2483450],
          [-2.5517330, 55.2483050],
          [-2.5518090, 55.2482650],
          [-2.5518880, 55.2482230],
          [-2.5519710, 55.2481780],
          [-2.5520640, 55.2481240],
          [-2.5521620, 55.2480700],
          [-2.5522620, 55.2480210],
          [-2.5523620, 55.2479670],
          [-2.5524640, 55.2479100],
          [-2.5525650, 55.2478580],
          [-2.5526750, 55.2478000],
          [-2.5527840, 55.2477400],
          [-2.5529040, 55.2476640],
          [-2.5530450, 55.2475830],
          [-2.5531620, 55.2474890],
          [-2.5532500, 55.2473980],
          [-2.5533120, 55.2473080],
          [-2.5533720, 55.2472280],
          [-2.5534290, 55.2471540],
          [-2.5534840, 55.2470790],
          [-2.5535350, 55.2470120],
          [-2.5535820, 55.2469420],
          [-2.5536330, 55.2468760],
          [-2.5536790, 55.2468120],
          [-2.5537240, 55.2467510],
          [-2.5537660, 55.2466870],
          [-2.5538120, 55.2466260],
          [-2.5538630, 55.2465670],
          [-2.5539160, 55.2465060],
          [-2.5539740, 55.2464420],
          [-2.5540360, 55.2463730],
          [-2.5541000, 55.2463050],
          [-2.5541630, 55.2462260],
          [-2.5542300, 55.2461510],
          [-2.5543060, 55.2460720],
          [-2.5543890, 55.2460000],
          [-2.5544840, 55.2459230],
          [-2.5545960, 55.2458510],
          [-2.5547220, 55.2457780],
          [-2.5548470, 55.2457210],
          [-2.5549820, 55.2456560],
          [-2.5550960, 55.2455960],
          [-2.5552170, 55.2455310],
          [-2.5553310, 55.2454650],
          [-2.5554430, 55.2453970],
          [-2.5555720, 55.2453320],
          [-2.5557010, 55.2452600],
          [-2.5558240, 55.2451990],
          [-2.5559600, 55.2451380],
          [-2.5561220, 55.2450810],
          [-2.5562810, 55.2450230],
          [-2.5564440, 55.2449690],
          [-2.5566230, 55.2449190],
          [-2.5567880, 55.2448810],
          [-2.5569350, 55.2448510],
          [-2.5570650, 55.2448160],
          [-2.5571750, 55.2447780],
          [-2.5572690, 55.2447330],
          [-2.5573560, 55.2446870],
          [-2.5574350, 55.2446380],
          [-2.5575060, 55.2445870],
          [-2.5575820, 55.2445230],
          [-2.5576560, 55.2444580],
          [-2.5577310, 55.2443890],
          [-2.5578140, 55.2443210],
          [-2.5579110, 55.2442560],
          [-2.5580150, 55.2441880],
          [-2.5581190, 55.2441150],
          [-2.5582200, 55.2440430],
          [-2.5583230, 55.2439670],
          [-2.5584290, 55.2438940],
          [-2.5585380, 55.2438210],
          [-2.5586360, 55.2437480],
          [-2.5587230, 55.2436780],
          [-2.5588190, 55.2435960],
          [-2.5589130, 55.2435240],
          [-2.5590000, 55.2434560],
          [-2.5590900, 55.2433850],
          [-2.5591890, 55.2433060],
          [-2.5592990, 55.2432260],
          [-2.5594150, 55.2431500],
          [-2.5595220, 55.2430700],
          [-2.5596300, 55.2429970],
          [-2.5597440, 55.2429200],
          [-2.5598590, 55.2428450],
          [-2.5599670, 55.2427640],
          [-2.5600790, 55.2426850],
          [-2.5601920, 55.2426040],
          [-2.5603100, 55.2425200],
          [-2.5604180, 55.2424360],
          [-2.5605210, 55.2423570],
          [-2.5606340, 55.2422850],
          [-2.5607580, 55.2422090],
          [-2.5608830, 55.2421290],
          [-2.5610070, 55.2420480],
          [-2.5611270, 55.2419630],
          [-2.5612290, 55.2418840],
          [-2.5613380, 55.2418050],
          [-2.5614450, 55.2417340],
          [-2.5615460, 55.2416650],
          [-2.5616620, 55.2415860],
          [-2.5617760, 55.2415100],
          [-2.5618930, 55.2414310],
          [-2.5620190, 55.2413500],
          [-2.5621450, 55.2412740],
          [-2.5622670, 55.2411980],
          [-2.5623820, 55.2411250],
          [-2.5625020, 55.2410480],
          [-2.5626180, 55.2409570],
          [-2.5627270, 55.2408660],
          [-2.5628200, 55.2407740],
          [-2.5628830, 55.2406830],
          [-2.5629410, 55.2406000],
          [-2.5629980, 55.2405190],
          [-2.5630490, 55.2404460],
          [-2.5631010, 55.2403730],
          [-2.5631390, 55.2403080],
          [-2.5631840, 55.2402390],
          [-2.5632320, 55.2401790],
          [-2.5632780, 55.2401200],
          [-2.5633100, 55.2400650],
          [-2.5633460, 55.2400070],
          [-2.5633900, 55.2399580],
          [-2.5634380, 55.2399070],
          [-2.5634790, 55.2398610],
          [-2.5635160, 55.2398150],
          [-2.5635560, 55.2397700],
          [-2.5636010, 55.2397240],
          [-2.5636370, 55.2396870],
          [-2.5636970, 55.2396330],
          [-2.5637280, 55.2395790],
          [-2.5637710, 55.2395330],
          [-2.5637980, 55.2394830],
          [-2.5638410, 55.2394300],
          [-2.5638920, 55.2393660],
          [-2.5639390, 55.2393050],
          [-2.5640130, 55.2392240],
          [-2.5640830, 55.2391480],
          [-2.5641670, 55.2390640],
          [-2.5642780, 55.2389850],
          [-2.5643870, 55.2389040],
          [-2.5644970, 55.2388290],
          [-2.5646020, 55.2387490],
          [-2.5647150, 55.2386710],
          [-2.5648220, 55.2385990],
          [-2.5649390, 55.2385200],
          [-2.5650920, 55.2384380],
          [-2.5652400, 55.2383590],
          [-2.5653720, 55.2382740],
          [-2.5655190, 55.2381980],
          [-2.5656600, 55.2381220],
          [-2.5657950, 55.2380500],
          [-2.5659350, 55.2379770],
          [-2.5660690, 55.2379090],
          [-2.5662100, 55.2378430],
          [-2.5663830, 55.2377710],
          [-2.5665250, 55.2377020],
          [-2.5666770, 55.2376280],
          [-2.5668250, 55.2375470],
          [-2.5670090, 55.2374730],
          [-2.5671920, 55.2373970],
          [-2.5673800, 55.2373210],
          [-2.5675590, 55.2372490],
          [-2.5677250, 55.2371770],
          [-2.5679000, 55.2370960],
          [-2.5680590, 55.2370230],
          [-2.5682280, 55.2369510],
          [-2.5683900, 55.2368740],
          [-2.5685510, 55.2367950],
          [-2.5686950, 55.2367120],
          [-2.5688170, 55.2366200],
          [-2.5689340, 55.2365210],
          [-2.5690480, 55.2364140],
          [-2.5691440, 55.2362990],
          [-2.5692400, 55.2361850],
          [-2.5693310, 55.2360760],
          [-2.5694140, 55.2359610],
          [-2.5695090, 55.2358500],
          [-2.5696010, 55.2357400],
          [-2.5696830, 55.2356310],
          [-2.5697450, 55.2355330],
          [-2.5698180, 55.2354210],
          [-2.5698590, 55.2353180],
          [-2.5698700, 55.2352230],
          [-2.5698820, 55.2351390],
          [-2.5698930, 55.2350560],
          [-2.5699080, 55.2349790],
          [-2.5699200, 55.2349100],
          [-2.5699360, 55.2348460],
          [-2.5699530, 55.2347800],
          [-2.5699640, 55.2347220],
          [-2.5699750, 55.2346660],
          [-2.5699880, 55.2346070],
          [-2.5700000, 55.2345470],
          [-2.5700110, 55.2344900],
          [-2.5700260, 55.2344330],
          [-2.5700410, 55.2343720],
          [-2.5700600, 55.2343060],
          [-2.5700760, 55.2342430],
          [-2.5700920, 55.2341730],
          [-2.5701030, 55.2341060],
          [-2.5701160, 55.2340360],
          [-2.5701400, 55.2339600],
          [-2.5701750, 55.2338840],
          [-2.5702190, 55.2338040],
          [-2.5702740, 55.2337280],
          [-2.5703390, 55.2336510],
          [-2.5704140, 55.2335830],
          [-2.5704880, 55.2335180],
          [-2.5705600, 55.2334560],
          [-2.5706450, 55.2333940],
          [-2.5707260, 55.2333340],
          [-2.5708030, 55.2332730],
          [-2.5708790, 55.2332160],
          [-2.5709520, 55.2331630],
          [-2.5710210, 55.2331090],
          [-2.5710840, 55.2330590],
          [-2.5711410, 55.2330060],
          [-2.5711840, 55.2329530],
          [-2.5712220, 55.2329000],
          [-2.5712510, 55.2328460],
          [-2.5712750, 55.2327890],
          [-2.5713080, 55.2327280],
          [-2.5713450, 55.2326590],
          [-2.5713760, 55.2325950],
          [-2.5714110, 55.2325250],
          [-2.5714430, 55.2324490],
          [-2.5714600, 55.2323770],
          [-2.5714720, 55.2323000],
          [-2.5714800, 55.2322210],
          [-2.5714870, 55.2321410],
          [-2.5714960, 55.2320650],
          [-2.5714980, 55.2319920],
          [-2.5715000, 55.2319270],
          [-2.5714970, 55.2318660],
          [-2.5714940, 55.2318210],
          [-2.5714930, 55.2317780],
          [-2.5714970, 55.2317360],
          [-2.5715070, 55.2316940],
          [-2.5715050, 55.2316590],
          [-2.5715020, 55.2316250],
          [-2.5714900, 55.2315870],
          [-2.5714820, 55.2315490],
          [-2.5714820, 55.2315140],
          [-2.5714830, 55.2314800],
          [-2.5714880, 55.2314380],
          [-2.5714870, 55.2314050],
          [-2.5714830, 55.2313670],
          [-2.5714770, 55.2313280],
          [-2.5714720, 55.2312890],
          [-2.5714680, 55.2312480],
          [-2.5714700, 55.2312090],
          [-2.5714650, 55.2311720],
          [-2.5714590, 55.2311300],
          [-2.5714530, 55.2310910],
          [-2.5714530, 55.2310490],
          [-2.5714520, 55.2310110],
          [-2.5714520, 55.2309730],
          [-2.5714430, 55.2309340],
          [-2.5714380, 55.2309010],
          [-2.5714330, 55.2308620],
          [-2.5714320, 55.2308280],
          [-2.5714350, 55.2307940],
          [-2.5714400, 55.2307590],
          [-2.5714450, 55.2307250],
          [-2.5714480, 55.2306910],
          [-2.5714500, 55.2306520],
          [-2.5714450, 55.2306110],
          [-2.5714450, 55.2305740],
          [-2.5714430, 55.2305300],
          [-2.5714470, 55.2304930],
          [-2.5714470, 55.2304460],
          [-2.5714430, 55.2304020],
          [-2.5714400, 55.2303590],
          [-2.5714370, 55.2303090],
          [-2.5714300, 55.2302600],
          [-2.5714220, 55.2302110],
          [-2.5714100, 55.2301570],
          [-2.5714020, 55.2301090],
          [-2.5713970, 55.2300530],
          [-2.5713920, 55.2300050],
          [-2.5713870, 55.2299500],
          [-2.5713780, 55.2298980],
          [-2.5713700, 55.2298440],
          [-2.5713620, 55.2297950],
          [-2.5713540, 55.2297450],
          [-2.5713470, 55.2297000],
          [-2.5713430, 55.2296490],
          [-2.5713370, 55.2296040],
          [-2.5713290, 55.2295550],
          [-2.5713220, 55.2295050],
          [-2.5713140, 55.2294550],
          [-2.5713080, 55.2294050],
          [-2.5713000, 55.2293560],
          [-2.5712950, 55.2293100],
          [-2.5712930, 55.2292650],
          [-2.5712880, 55.2292190],
          [-2.5712820, 55.2291720],
          [-2.5712700, 55.2291300],
          [-2.5712580, 55.2290810],
          [-2.5712420, 55.2290320],
          [-2.5712300, 55.2289850],
          [-2.5712220, 55.2289350],
          [-2.5712120, 55.2288940],
          [-2.5712070, 55.2288450],
          [-2.5712070, 55.2287990],
          [-2.5712020, 55.2287500],
          [-2.5711970, 55.2286960],
          [-2.5711840, 55.2286440],
          [-2.5711670, 55.2285930],
          [-2.5711360, 55.2285550],
          [-2.5710860, 55.2285390],
          [-2.5710390, 55.2285420],
          [-2.5710060, 55.2285610],
          [-2.5709860, 55.2285870],
          [-2.5709710, 55.2286170],
          [-2.5709540, 55.2286410],
          [-2.5709390, 55.2286630],
          [-2.5709220, 55.2286860],
          [-2.5709050, 55.2287100],
          [-2.5708890, 55.2287330],
          [-2.5708720, 55.2287550],
          [-2.5708520, 55.2287780],
          [-2.5708370, 55.2288010],
          [-2.5708170, 55.2288240],
          [-2.5707980, 55.2288500],
          [-2.5707780, 55.2288730],
          [-2.5707570, 55.2289000],
          [-2.5707400, 55.2289260],
          [-2.5707210, 55.2289530],
          [-2.5707040, 55.2289800],
          [-2.5706810, 55.2290060],
          [-2.5706620, 55.2290370],
          [-2.5706460, 55.2290640],
          [-2.5706280, 55.2290940],
          [-2.5706080, 55.2291240],
          [-2.5705890, 55.2291550],
          [-2.5705710, 55.2291880],
          [-2.5705490, 55.2292240],
          [-2.5705250, 55.2292550],
          [-2.5705000, 55.2292860],
          [-2.5704790, 55.2293230],
          [-2.5704580, 55.2293600],
          [-2.5704370, 55.2293960],
          [-2.5704160, 55.2294350],
          [-2.5703950, 55.2294730],
          [-2.5703760, 55.2295130],
          [-2.5703560, 55.2295480],
          [-2.5703370, 55.2295890],
          [-2.5703170, 55.2296280],
          [-2.5702970, 55.2296670],
          [-2.5702770, 55.2297050],
          [-2.5702570, 55.2297440],
          [-2.5702370, 55.2297860],
          [-2.5702140, 55.2298270],
          [-2.5701920, 55.2298690],
          [-2.5701680, 55.2299180],
          [-2.5701380, 55.2299730],
          [-2.5701090, 55.2300290],
          [-2.5700740, 55.2300910],
          [-2.5700380, 55.2301490],
          [-2.5700020, 55.2302080],
          [-2.5699700, 55.2302580],
          [-2.5699410, 55.2303080],
          [-2.5699120, 55.2303500],
          [-2.5698890, 55.2303950],
          [-2.5698660, 55.2304340],
          [-2.5698440, 55.2304720],
          [-2.5698220, 55.2305100],
          [-2.5697990, 55.2305440],
          [-2.5697770, 55.2305820],
          [-2.5697550, 55.2306250],
          [-2.5697340, 55.2306630],
          [-2.5697120, 55.2307010],
          [-2.5696890, 55.2307390],
          [-2.5696620, 55.2307800],
          [-2.5696300, 55.2308230],
          [-2.5695990, 55.2308690],
          [-2.5695650, 55.2309150],
          [-2.5695300, 55.2309600],
          [-2.5694910, 55.2310050],
          [-2.5694440, 55.2310590],
          [-2.5693930, 55.2311040],
          [-2.5693350, 55.2311540],
          [-2.5692740, 55.2312000],
          [-2.5692080, 55.2312460],
          [-2.5691340, 55.2312960],
          [-2.5690610, 55.2313480],
          [-2.5689760, 55.2314060],
          [-2.5688860, 55.2314680],
          [-2.5687990, 55.2315280],
          [-2.5687110, 55.2315900],
          [-2.5686310, 55.2316530],
          [-2.5685500, 55.2317190],
          [-2.5684810, 55.2317800],
          [-2.5684120, 55.2318420],
          [-2.5683520, 55.2319010],
          [-2.5682920, 55.2319600],
          [-2.5682290, 55.2320200],
          [-2.5681670, 55.2320780],
          [-2.5680900, 55.2321390],
          [-2.5680130, 55.2321990],
          [-2.5679240, 55.2322600],
          [-2.5678310, 55.2323180],
          [-2.5677440, 55.2323750],
          [-2.5676510, 55.2324330],
          [-2.5675560, 55.2324960],
          [-2.5674540, 55.2325590],
          [-2.5673520, 55.2326260],
          [-2.5672350, 55.2326990],
          [-2.5671380, 55.2327750],
          [-2.5670470, 55.2328440],
          [-2.5669710, 55.2329060],
          [-2.5669100, 55.2329510],
          [-2.5668440, 55.2329860],
          [-2.5667720, 55.2330050],
          [-2.5666910, 55.2329940],
          [-2.5666300, 55.2329680],
          [-2.5665890, 55.2329340],
          [-2.5665570, 55.2328910],
          [-2.5665320, 55.2328460],
          [-2.5665120, 55.2328010],
          [-2.5664920, 55.2327520],
          [-2.5664740, 55.2327020],
          [-2.5664520, 55.2326550],
          [-2.5664300, 55.2326090],
          [-2.5664090, 55.2325640],
          [-2.5663860, 55.2325150],
          [-2.5663590, 55.2324720],
          [-2.5663300, 55.2324340],
          [-2.5663040, 55.2323960],
          [-2.5662790, 55.2323650],
          [-2.5662620, 55.2323310],
          [-2.5662490, 55.2323000],
          [-2.5662370, 55.2322740],
          [-2.5662270, 55.2322470],
          [-2.5662170, 55.2322200],
          [-2.5662060, 55.2321950],
          [-2.5661950, 55.2321700],
          [-2.5661800, 55.2321440],
          [-2.5661630, 55.2321170],
          [-2.5661440, 55.2320940],
          [-2.5661240, 55.2320710],
          [-2.5661050, 55.2320480],
          [-2.5660820, 55.2320250],
          [-2.5660630, 55.2320030],
          [-2.5660410, 55.2319840],
          [-2.5660200, 55.2319610],
          [-2.5659990, 55.2319380],
          [-2.5659820, 55.2319150],
          [-2.5659670, 55.2318920],
          [-2.5659510, 55.2318700],
          [-2.5659330, 55.2318460],
          [-2.5659170, 55.2318200],
          [-2.5659040, 55.2317960],
          [-2.5658890, 55.2317700],
          [-2.5658740, 55.2317430],
          [-2.5658570, 55.2317170],
          [-2.5658420, 55.2316900],
          [-2.5658250, 55.2316590],
          [-2.5658100, 55.2316330],
          [-2.5657960, 55.2316070],
          [-2.5657790, 55.2315750],
          [-2.5657620, 55.2315490],
          [-2.5657450, 55.2315220],
          [-2.5657310, 55.2314920],
          [-2.5657160, 55.2314650],
          [-2.5656990, 55.2314420],
          [-2.5656820, 55.2314150],
          [-2.5656670, 55.2313930],
          [-2.5656540, 55.2313700],
          [-2.5656420, 55.2313470],
          [-2.5656290, 55.2313240],
          [-2.5656140, 55.2313050],
          [-2.5655970, 55.2312850],
          [-2.5655790, 55.2312630],
          [-2.5655650, 55.2312400],
          [-2.5655600, 55.2312170],
          [-2.5655600, 55.2311940],
          [-2.5655550, 55.2311710],
          [-2.5655520, 55.2311490],
          [-2.5655490, 55.2311290],
          [-2.5655450, 55.2311100],
          [-2.5655420, 55.2310870],
          [-2.5655400, 55.2310610],
          [-2.5655370, 55.2310340],
          [-2.5655300, 55.2310070],
          [-2.5655230, 55.2309800],
          [-2.5655140, 55.2309500],
          [-2.5655070, 55.2309160],
          [-2.5654980, 55.2308850],
          [-2.5654850, 55.2308470],
          [-2.5654740, 55.2308090],
          [-2.5654620, 55.2307710],
          [-2.5654520, 55.2307250],
          [-2.5654400, 55.2306800],
          [-2.5654290, 55.2306340],
          [-2.5654150, 55.2305840],
          [-2.5654000, 55.2305310],
          [-2.5653840, 55.2304820],
          [-2.5653690, 55.2304270],
          [-2.5653510, 55.2303750],
          [-2.5653340, 55.2303170],
          [-2.5653170, 55.2302570],
          [-2.5653040, 55.2301910],
          [-2.5652870, 55.2301230],
          [-2.5652690, 55.2300550],
          [-2.5652440, 55.2299820],
          [-2.5652090, 55.2299130],
          [-2.5651600, 55.2298490],
          [-2.5650930, 55.2297910],
          [-2.5650140, 55.2297350],
          [-2.5649360, 55.2296830],
          [-2.5648580, 55.2296310],
          [-2.5647850, 55.2295810],
          [-2.5647160, 55.2295350],
          [-2.5646520, 55.2294900],
          [-2.5645890, 55.2294470],
          [-2.5645310, 55.2294010],
          [-2.5644700, 55.2293590],
          [-2.5644080, 55.2293180],
          [-2.5643500, 55.2292760],
          [-2.5642880, 55.2292300],
          [-2.5642260, 55.2291850],
          [-2.5641620, 55.2291420],
          [-2.5641010, 55.2291000],
          [-2.5640380, 55.2290550],
          [-2.5639730, 55.2290120],
          [-2.5639030, 55.2289590],
          [-2.5638260, 55.2289060],
          [-2.5637350, 55.2288420],
          [-2.5636370, 55.2287760],
          [-2.5635420, 55.2287130],
          [-2.5634490, 55.2286460],
          [-2.5633690, 55.2285900],
          [-2.5632950, 55.2285410],
          [-2.5632220, 55.2284870],
          [-2.5631510, 55.2284400],
          [-2.5630860, 55.2283910],
          [-2.5630190, 55.2283450],
          [-2.5629520, 55.2283010],
          [-2.5628800, 55.2282510],
          [-2.5628080, 55.2282000],
          [-2.5627350, 55.2281500],
          [-2.5626610, 55.2280980],
          [-2.5625850, 55.2280440],
          [-2.5625090, 55.2279910],
          [-2.5624300, 55.2279330],
          [-2.5623500, 55.2278760],
          [-2.5622710, 55.2278230],
          [-2.5621900, 55.2277650],
          [-2.5621100, 55.2277120],
          [-2.5620300, 55.2276550],
          [-2.5619480, 55.2275970],
          [-2.5618670, 55.2275410],
          [-2.5617840, 55.2274830],
          [-2.5617050, 55.2274260],
          [-2.5616240, 55.2273650],
          [-2.5615420, 55.2273090],
          [-2.5614570, 55.2272470],
          [-2.5613730, 55.2271830],
          [-2.5612850, 55.2271170],
          [-2.5611930, 55.2270520],
          [-2.5610990, 55.2269880],
          [-2.5610020, 55.2269180],
          [-2.5609060, 55.2268470],
          [-2.5608020, 55.2267750],
          [-2.5606970, 55.2267010],
          [-2.5605910, 55.2266230],
          [-2.5604670, 55.2265460],
          [-2.5603350, 55.2264660],
          [-2.5601760, 55.2264040],
          [-2.5600270, 55.2263430],
          [-2.5598820, 55.2263050],
          [-2.5597170, 55.2262700],
          [-2.5595490, 55.2262540],
          [-2.5594030, 55.2262570],
          [-2.5592780, 55.2262870],
          [-2.5591670, 55.2263280],
          [-2.5590760, 55.2263810],
          [-2.5590060, 55.2264420],
          [-2.5589480, 55.2265150],
          [-2.5589010, 55.2265900],
          [-2.5588440, 55.2266720],
          [-2.5587590, 55.2267290],
          [-2.5586400, 55.2267600],
          [-2.5585040, 55.2267680],
          [-2.5583650, 55.2267500],
          [-2.5582380, 55.2267160],
          [-2.5581260, 55.2266670],
          [-2.5580210, 55.2266100],
          [-2.5579220, 55.2265420],
          [-2.5578100, 55.2264770],
          [-2.5576870, 55.2264230],
          [-2.5575480, 55.2263840],
          [-2.5574020, 55.2263460],
          [-2.5572460, 55.2263050],
          [-2.5570870, 55.2262630],
          [-2.5569250, 55.2262350],
          [-2.5567760, 55.2262120],
          [-2.5566160, 55.2261970],
          [-2.5564560, 55.2261890],
          [-2.5563020, 55.2261890],
          [-2.5561400, 55.2261920],
          [-2.5559820, 55.2261960],
          [-2.5558370, 55.2262070],
          [-2.5556720, 55.2262300],
          [-2.5555090, 55.2262680],
          [-2.5553430, 55.2263090],
          [-2.5551890, 55.2263480],
          [-2.5550490, 55.2263790],
          [-2.5549330, 55.2264020],
          [-2.5548280, 55.2264170],
          [-2.5547320, 55.2264360],
          [-2.5546380, 55.2264480],
          [-2.5545330, 55.2264520],
          [-2.5544430, 55.2264630],
          [-2.5543710, 55.2264710],
          [-2.5543070, 55.2264890],
          [-2.5542510, 55.2265010],
          [-2.5542050, 55.2265120],
          [-2.5541700, 55.2265280],
          [-2.5541380, 55.2265360],
          [-2.5540940, 55.2265500],
          [-2.5540480, 55.2265690],
          [-2.5540060, 55.2265850],
          [-2.5539640, 55.2265960],
          [-2.5538940, 55.2265890],
          [-2.5537870, 55.2265670],
          [-2.5536970, 55.2265520],
          [-2.5536380, 55.2265400],
          [-2.5535720, 55.2265250],
          [-2.5534860, 55.2264940],
          [-2.5534170, 55.2264790],
          [-2.5533420, 55.2264640],
          [-2.5532470, 55.2264600],
          [-2.5531900, 55.2264520],
          [-2.5531320, 55.2264300],
          [-2.5530650, 55.2263990],
          [-2.5529710, 55.2263590],
          [-2.5528960, 55.2263200],
          [-2.5528210, 55.2262780],
          [-2.5527270, 55.2262290],
          [-2.5526330, 55.2261830],
          [-2.5525250, 55.2261360],
          [-2.5524320, 55.2260880],
          [-2.5523300, 55.2260420],
          [-2.5522310, 55.2259810],
          [-2.5521240, 55.2259310],
          [-2.5520110, 55.2258740],
          [-2.5518870, 55.2258070],
          [-2.5517530, 55.2257390],
          [-2.5516150, 55.2256630],
          [-2.5514860, 55.2255890],
          [-2.5513670, 55.2255170],
          [-2.5512550, 55.2254480],
          [-2.5511570, 55.2253850],
          [-2.5510570, 55.2253160],
          [-2.5509090, 55.2251990],
          [-2.5508280, 55.2251200],
          [-2.5506980, 55.2250120],
          [-2.5505650, 55.2249020],
          [-2.5503950, 55.2247610],
          [-2.5502460, 55.2246290],
          [-2.5501290, 55.2244990],
          [-2.5500280, 55.2243860],
          [-2.5498690, 55.2241900],
          [-2.5497090, 55.2239940],
          [-2.5496320, 55.2238500],
          [-2.5495430, 55.2237160],
          [-2.5494500, 55.2235900],
          [-2.5493490, 55.2234820],
          [-2.5492500, 55.2233690],
          [-2.5491290, 55.2232290],
          [-2.5490050, 55.2230960],
          [-2.5489160, 55.2229750],
          [-2.5488190, 55.2228650],
          [-2.5487280, 55.2227750],
          [-2.5486320, 55.2227020],
          [-2.5485210, 55.2226440],
          [-2.5484000, 55.2225910],
          [-2.5482700, 55.2225460],
          [-2.5481350, 55.2225080],
          [-2.5480060, 55.2224700],
          [-2.5478880, 55.2224430],
          [-2.5477780, 55.2224200],
          [-2.5476740, 55.2223970],
          [-2.5475790, 55.2223740],
          [-2.5474940, 55.2223550],
          [-2.5474190, 55.2223400],
          [-2.5473650, 55.2223280],
          [-2.5473370, 55.2223240],
          [-2.5473290, 55.2223170],
          [-2.5473270, 55.2223130],
          [-2.5473300, 55.2223130],
          [-2.5473320, 55.2223130],
          [-2.5473320, 55.2223130],
          [-2.5473320, 55.2223130],
          [-2.5473320, 55.2223130],
          [-2.5473320, 55.2223130],
          [-2.5473320, 55.2223130],
          [-2.5473320, 55.2223130],
          [-2.5473320, 55.2223130],
          [-2.5473320, 55.2223130],
          [-2.5473320, 55.2223130],
          [-2.5473320, 55.2223130],
          [-2.5473320, 55.2223130],
          [-2.5473320, 55.2223130],
          [-2.5473020, 55.2223090],
          [-2.5472530, 55.2223020],
          [-2.5471900, 55.2222870],
          [-2.5471190, 55.2222670],
          [-2.5470390, 55.2222480],
          [-2.5469510, 55.2222290],
          [-2.5468510, 55.2222030],
          [-2.5467450, 55.2221720],
          [-2.5466330, 55.2221380],
          [-2.5465140, 55.2220960],
          [-2.5463930, 55.2220540],
          [-2.5462770, 55.2220010],
          [-2.5461620, 55.2219410],
          [-2.5460180, 55.2218720],
          [-2.5458710, 55.2217990],
          [-2.5457180, 55.2217300],
          [-2.5455740, 55.2216550],
          [-2.5454230, 55.2215630],
          [-2.5452720, 55.2214750],
          [-2.5451150, 55.2213980],
          [-2.5449650, 55.2213300],
          [-2.5448150, 55.2212380],
          [-2.5446700, 55.2211550],
          [-2.5445440, 55.2210690],
          [-2.5444110, 55.2209740],
          [-2.5442620, 55.2208830],
          [-2.5441000, 55.2208120],
          [-2.5439280, 55.2207530],
          [-2.5437600, 55.2207040],
          [-2.5436190, 55.2206580],
          [-2.5435110, 55.2206240],
          [-2.5434100, 55.2205890],
          [-2.5433380, 55.2205700],
          [-2.5432970, 55.2205580],
          [-2.5432900, 55.2205540],
          [-2.5432930, 55.2205580],
          [-2.5432960, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432970, 55.2205580],
          [-2.5432590, 55.2205470],
          [-2.5432040, 55.2205280],
          [-2.5431420, 55.2205130],
          [-2.5430800, 55.2204900],
          [-2.5430140, 55.2204670],
          [-2.5429460, 55.2204440],
          [-2.5428760, 55.2204180],
          [-2.5427960, 55.2203910],
          [-2.5427010, 55.2203600],
          [-2.5425960, 55.2203300],
          [-2.5424870, 55.2202950],
          [-2.5423790, 55.2202500],
          [-2.5422800, 55.2201930],
          [-2.5421890, 55.2201240],
          [-2.5420960, 55.2200440],
          [-2.5419920, 55.2199570],
          [-2.5418690, 55.2198690],
          [-2.5417160, 55.2197910],
          [-2.5415620, 55.2197120],
          [-2.5414020, 55.2196400],
          [-2.5412330, 55.2195680],
          [-2.5410480, 55.2194950],
          [-2.5408670, 55.2194180],
          [-2.5407030, 55.2193380],
          [-2.5405550, 55.2192740],
          [-2.5404170, 55.2192090],
          [-2.5402990, 55.2191440],
          [-2.5402200, 55.2190830],
          [-2.5401650, 55.2190210],
          [-2.5401490, 55.2189650],
          [-2.5401710, 55.2189080],
          [-2.5402290, 55.2188540],
          [-2.5403040, 55.2188010],
          [-2.5403830, 55.2187470],
          [-2.5404600, 55.2186860],
          [-2.5405330, 55.2186220],
          [-2.5406130, 55.2185520],
          [-2.5406920, 55.2184840],
          [-2.5407760, 55.2184150],
          [-2.5408520, 55.2183460],
          [-2.5409360, 55.2182660],
          [-2.5410030, 55.2181900],
          [-2.5410550, 55.2181070],
          [-2.5410950, 55.2180180],
          [-2.5411190, 55.2179320],
          [-2.5411330, 55.2178430],
          [-2.5411330, 55.2177520],
          [-2.5411200, 55.2176600],
          [-2.5410890, 55.2175730],
          [-2.5410460, 55.2174860],
          [-2.5409910, 55.2173970],
          [-2.5409360, 55.2173140],
          [-2.5408850, 55.2172210],
          [-2.5408400, 55.2171330],
          [-2.5408060, 55.2170390],
          [-2.5407820, 55.2169430],
          [-2.5407650, 55.2168400],
          [-2.5407450, 55.2167330],
          [-2.5407220, 55.2166320],
          [-2.5406900, 55.2165310],
          [-2.5406410, 55.2164360],
          [-2.5405400, 55.2163600],
          [-2.5404380, 55.2162830],
          [-2.5403320, 55.2162120],
          [-2.5402190, 55.2161410],
          [-2.5401190, 55.2160740],
          [-2.5400170, 55.2160050],
          [-2.5399140, 55.2159360],
          [-2.5398060, 55.2158590],
          [-2.5396930, 55.2157830],
          [-2.5395730, 55.2156990],
          [-2.5394480, 55.2156150],
          [-2.5393230, 55.2155160],
          [-2.5391890, 55.2154210],
          [-2.5390700, 55.2153290],
          [-2.5389510, 55.2152410],
          [-2.5388450, 55.2151590],
          [-2.5387500, 55.2150630],
          [-2.5386490, 55.2149710],
          [-2.5385690, 55.2148770],
          [-2.5384930, 55.2147770],
          [-2.5384320, 55.2146750],
          [-2.5383730, 55.2145820],
          [-2.5383160, 55.2144870],
          [-2.5382540, 55.2144030],
          [-2.5381910, 55.2143150],
          [-2.5381270, 55.2142320],
          [-2.5380630, 55.2141440],
          [-2.5379960, 55.2140630],
          [-2.5379310, 55.2139790],
          [-2.5378660, 55.2138960],
          [-2.5378010, 55.2138110],
          [-2.5377450, 55.2137250],
          [-2.5377010, 55.2136400],
          [-2.5376750, 55.2135510],
          [-2.5376540, 55.2134680],
          [-2.5376320, 55.2133950],
          [-2.5376050, 55.2133380],
          [-2.5375590, 55.2133000],
          [-2.5374850, 55.2132880],
          [-2.5373920, 55.2133140],
          [-2.5373130, 55.2133660],
          [-2.5372380, 55.2134270],
          [-2.5371730, 55.2135000],
          [-2.5371080, 55.2135780],
          [-2.5370490, 55.2136640],
          [-2.5369900, 55.2137560],
          [-2.5369310, 55.2138540],
          [-2.5368700, 55.2139560],
          [-2.5367870, 55.2140740],
          [-2.5366910, 55.2141950],
          [-2.5365910, 55.2143090],
          [-2.5364730, 55.2144040],
          [-2.5363420, 55.2144770],
          [-2.5361900, 55.2145370],
          [-2.5360170, 55.2145690],
          [-2.5358100, 55.2145390],
          [-2.5356250, 55.2144940],
          [-2.5354290, 55.2144370],
          [-2.5352290, 55.2143760],
          [-2.5350160, 55.2143260],
          [-2.5347990, 55.2142840],
          [-2.5345890, 55.2142400],
          [-2.5343600, 55.2141880],
          [-2.5341510, 55.2141270],
          [-2.5339650, 55.2140600],
          [-2.5337870, 55.2139750],
          [-2.5336370, 55.2138810],
          [-2.5335070, 55.2137740],
          [-2.5333940, 55.2136670],
          [-2.5332750, 55.2135630],
          [-2.5331480, 55.2134720],
          [-2.5330030, 55.2133920],
          [-2.5328420, 55.2133230],
          [-2.5326720, 55.2132730],
          [-2.5324810, 55.2132420],
          [-2.5322860, 55.2132230],
          [-2.5321170, 55.2132060],
          [-2.5318920, 55.2131690],
          [-2.5317140, 55.2131350],
          [-2.5315500, 55.2130890],
          [-2.5313990, 55.2130440],
          [-2.5312580, 55.2129980],
          [-2.5311230, 55.2129520],
          [-2.5310010, 55.2129100],
          [-2.5308890, 55.2128690],
          [-2.5307810, 55.2128340],
          [-2.5306800, 55.2127990],
          [-2.5305810, 55.2127690],
          [-2.5304860, 55.2127430],
          [-2.5303930, 55.2127160],
          [-2.5303060, 55.2126960],
          [-2.5302230, 55.2126850],
          [-2.5301410, 55.2126730],
          [-2.5300610, 55.2126620],
          [-2.5299780, 55.2126540],
          [-2.5298910, 55.2126430],
          [-2.5298110, 55.2126350],
          [-2.5297300, 55.2126280],
          [-2.5296450, 55.2126200],
          [-2.5295620, 55.2126120],
          [-2.5294880, 55.2126080],
          [-2.5294150, 55.2126050],
          [-2.5293360, 55.2125970],
          [-2.5292590, 55.2125890],
          [-2.5291880, 55.2125820],
          [-2.5291120, 55.2125740],
          [-2.5290290, 55.2125660],
          [-2.5289540, 55.2125590],
          [-2.5288940, 55.2125550],
          [-2.5288310, 55.2125510],
          [-2.5287690, 55.2125470],
          [-2.5286990, 55.2125470],
          [-2.5286270, 55.2125470],
          [-2.5285500, 55.2125590],
          [-2.5284680, 55.2125700],
          [-2.5283860, 55.2125810],
          [-2.5282960, 55.2125970],
          [-2.5282020, 55.2126160],
          [-2.5281160, 55.2126310],
          [-2.5280240, 55.2126460],
          [-2.5279330, 55.2126620],
          [-2.5278340, 55.2126810],
          [-2.5277380, 55.2127000],
          [-2.5276430, 55.2127190],
          [-2.5275450, 55.2127410],
          [-2.5274470, 55.2127610],
          [-2.5273490, 55.2127720],
          [-2.5272540, 55.2127800],
          [-2.5271560, 55.2127690],
          [-2.5270740, 55.2127460],
          [-2.5269980, 55.2127080],
          [-2.5269330, 55.2126700],
          [-2.5268770, 55.2126090],
          [-2.5268260, 55.2125330],
          [-2.5267890, 55.2124530],
          [-2.5267560, 55.2123730],
          [-2.5267220, 55.2122890],
          [-2.5266950, 55.2122050],
          [-2.5266690, 55.2121220],
          [-2.5266450, 55.2120440],
          [-2.5266270, 55.2119660],
          [-2.5266120, 55.2118780],
          [-2.5266050, 55.2117890],
          [-2.5265970, 55.2117090],
          [-2.5266010, 55.2116140],
          [-2.5266070, 55.2115260],
          [-2.5266030, 55.2114320],
          [-2.5265920, 55.2113400],
          [-2.5265720, 55.2112400],
          [-2.5265490, 55.2111480],
          [-2.5265220, 55.2110610],
          [-2.5264920, 55.2109740],
          [-2.5264540, 55.2108850],
          [-2.5264170, 55.2108010],
          [-2.5263820, 55.2107250],
          [-2.5263420, 55.2106480],
          [-2.5263010, 55.2105690],
          [-2.5262530, 55.2104930],
          [-2.5262010, 55.2104160],
          [-2.5261440, 55.2103360],
          [-2.5260820, 55.2102560],
          [-2.5260160, 55.2101720],
          [-2.5259440, 55.2100920],
          [-2.5258680, 55.2100110],
          [-2.5257850, 55.2099210],
          [-2.5257020, 55.2098330],
          [-2.5256060, 55.2097560],
          [-2.5254980, 55.2096870],
          [-2.5253790, 55.2096180],
          [-2.5252590, 55.2095490],
          [-2.5251380, 55.2094890],
          [-2.5250160, 55.2094270],
          [-2.5248970, 55.2093710],
          [-2.5247760, 55.2093230],
          [-2.5246380, 55.2092710],
          [-2.5245110, 55.2092290],
          [-2.5243840, 55.2091880],
          [-2.5242510, 55.2091560],
          [-2.5241210, 55.2091270],
          [-2.5239800, 55.2090990],
          [-2.5238430, 55.2090770],
          [-2.5237070, 55.2090570],
          [-2.5235780, 55.2090380],
          [-2.5234510, 55.2090230],
          [-2.5233260, 55.2090080],
          [-2.5232040, 55.2089960],
          [-2.5230900, 55.2089850],
          [-2.5229790, 55.2089690],
          [-2.5228730, 55.2089580],
          [-2.5227710, 55.2089460],
          [-2.5226740, 55.2089310],
          [-2.5225760, 55.2089120],
          [-2.5224860, 55.2088970],
          [-2.5223990, 55.2088780],
          [-2.5223110, 55.2088590],
          [-2.5222270, 55.2088320],
          [-2.5221500, 55.2088060],
          [-2.5220690, 55.2087820],
          [-2.5219910, 55.2087560],
          [-2.5219120, 55.2087330],
          [-2.5218400, 55.2087030],
          [-2.5217640, 55.2086640],
          [-2.5216860, 55.2086300],
          [-2.5216130, 55.2085930],
          [-2.5215360, 55.2085620],
          [-2.5214610, 55.2085270],
          [-2.5213890, 55.2084930],
          [-2.5213160, 55.2084580],
          [-2.5212430, 55.2084240],
          [-2.5211710, 55.2083940],
          [-2.5210980, 55.2083560],
          [-2.5210220, 55.2083210],
          [-2.5209460, 55.2082870],
          [-2.5208670, 55.2082490],
          [-2.5207840, 55.2082160],
          [-2.5206960, 55.2081770],
          [-2.5206050, 55.2081420],
          [-2.5205100, 55.2081040],
          [-2.5204080, 55.2080700],
          [-2.5202940, 55.2080390],
          [-2.5201710, 55.2080160],
          [-2.5200430, 55.2080000],
          [-2.5199070, 55.2079960],
          [-2.5197600, 55.2079960],
          [-2.5196120, 55.2080000],
          [-2.5194610, 55.2080080],
          [-2.5193120, 55.2080150],
          [-2.5191700, 55.2080190],
          [-2.5190320, 55.2080230],
          [-2.5189060, 55.2080270],
          [-2.5187880, 55.2080310],
          [-2.5186770, 55.2080340],
          [-2.5185790, 55.2080380],
          [-2.5184910, 55.2080460],
          [-2.5184110, 55.2080530],
          [-2.5183380, 55.2080610],
          [-2.5182680, 55.2080650],
          [-2.5182000, 55.2080650],
          [-2.5181390, 55.2080690],
          [-2.5180790, 55.2080730],
          [-2.5180200, 55.2080730],
          [-2.5179590, 55.2080690],
          [-2.5179010, 55.2080610],
          [-2.5178360, 55.2080540],
          [-2.5177740, 55.2080460],
          [-2.5177130, 55.2080310],
          [-2.5176500, 55.2080120],
          [-2.5175840, 55.2079970],
          [-2.5175270, 55.2079890],
          [-2.5174670, 55.2079770],
          [-2.5174030, 55.2079660],
          [-2.5173320, 55.2079510],
          [-2.5172650, 55.2079360],
          [-2.5171860, 55.2079200],
          [-2.5171030, 55.2079050],
          [-2.5170160, 55.2078930],
          [-2.5169320, 55.2078820],
          [-2.5168390, 55.2078740],
          [-2.5167440, 55.2078630],
          [-2.5166570, 55.2078520],
          [-2.5165640, 55.2078400],
          [-2.5164710, 55.2078210],
          [-2.5163750, 55.2078060],
          [-2.5162740, 55.2077910],
          [-2.5161800, 55.2077790],
          [-2.5160940, 55.2077680],
          [-2.5160060, 55.2077560],
          [-2.5159190, 55.2077450],
          [-2.5158690, 55.2077380],
          [-2.5157700, 55.2077240],
          [-2.5156840, 55.2077100],
          [-2.5156190, 55.2077060],
          [-2.5155450, 55.2077030],
          [-2.5154770, 55.2076990],
          [-2.5154070, 55.2076950],
          [-2.5153470, 55.2076910],
          [-2.5152820, 55.2076870],
          [-2.5152130, 55.2076840],
          [-2.5151240, 55.2076720],
          [-2.5150470, 55.2076610],
          [-2.5149600, 55.2076490],
          [-2.5148840, 55.2076450],
          [-2.5148100, 55.2076380],
          [-2.5147390, 55.2076300],
          [-2.5146770, 55.2076190],
          [-2.5146010, 55.2076150],
          [-2.5145150, 55.2076040],
          [-2.5144380, 55.2075880],
          [-2.5143510, 55.2075770],
          [-2.5142570, 55.2075660],
          [-2.5141590, 55.2075510],
          [-2.5140590, 55.2075350],
          [-2.5139550, 55.2075200],
          [-2.5138550, 55.2075080],
          [-2.5137540, 55.2074890],
          [-2.5136630, 55.2074740],
          [-2.5135800, 55.2074660],
          [-2.5134970, 55.2074590],
          [-2.5134120, 55.2074510],
          [-2.5133280, 55.2074430],
          [-2.5132320, 55.2074250],
          [-2.5131350, 55.2074130],
          [-2.5130450, 55.2073980],
          [-2.5129610, 55.2073900],
          [-2.5128680, 55.2073820],
          [-2.5127750, 55.2073750],
          [-2.5126840, 55.2073630],
          [-2.5125810, 55.2073520],
          [-2.5124830, 55.2073370],
          [-2.5123870, 55.2073140],
          [-2.5123000, 55.2072950],
          [-2.5122220, 55.2072720],
          [-2.5121470, 55.2072460],
          [-2.5120660, 55.2072150],
          [-2.5119880, 55.2071810],
          [-2.5118920, 55.2071420],
          [-2.5117860, 55.2070900],
          [-2.5116750, 55.2070320],
          [-2.5115760, 55.2069680],
          [-2.5114990, 55.2069110],
          [-2.5114110, 55.2068450],
          [-2.5113420, 55.2067910],
          [-2.5112630, 55.2067320],
          [-2.5111840, 55.2066780],
          [-2.5111070, 55.2066210],
          [-2.5110240, 55.2065630],
          [-2.5109560, 55.2065070],
          [-2.5108940, 55.2064480],
          [-2.5108350, 55.2063880],
          [-2.5107780, 55.2063160],
          [-2.5107220, 55.2062420],
          [-2.5106680, 55.2061710],
          [-2.5106090, 55.2060980],
          [-2.5105560, 55.2060300],
          [-2.5105040, 55.2059560],
          [-2.5104610, 55.2058850],
          [-2.5104160, 55.2058080],
          [-2.5103750, 55.2057390],
          [-2.5103340, 55.2056670],
          [-2.5102970, 55.2055940],
          [-2.5102630, 55.2055220],
          [-2.5102300, 55.2054420],
          [-2.5102120, 55.2053620],
          [-2.5102070, 55.2052780],
          [-2.5101980, 55.2051860],
          [-2.5101880, 55.2050940],
          [-2.5101770, 55.2050070],
          [-2.5101620, 55.2049230],
          [-2.5101520, 55.2048210],
          [-2.5101580, 55.2047240],
          [-2.5101620, 55.2046250],
          [-2.5101670, 55.2045260],
          [-2.5101700, 55.2044230],
          [-2.5101770, 55.2043280],
          [-2.5101800, 55.2042330],
          [-2.5101770, 55.2041400],
          [-2.5101720, 55.2040500],
          [-2.5101630, 55.2039610],
          [-2.5101550, 55.2038770],
          [-2.5101430, 55.2038010],
          [-2.5101330, 55.2037260],
          [-2.5101220, 55.2036570],
          [-2.5101090, 55.2035880],
          [-2.5101020, 55.2035220],
          [-2.5100940, 55.2034590],
          [-2.5100820, 55.2033890],
          [-2.5100690, 55.2033210],
          [-2.5100470, 55.2032550],
          [-2.5100170, 55.2031900],
          [-2.5099760, 55.2031260],
          [-2.5099240, 55.2030610],
          [-2.5098690, 55.2030070],
          [-2.5098090, 55.2029650],
          [-2.5097500, 55.2029270],
          [-2.5096870, 55.2028930],
          [-2.5096160, 55.2028620],
          [-2.5095430, 55.2028360],
          [-2.5094710, 55.2028090],
          [-2.5093920, 55.2027790],
          [-2.5093070, 55.2027510],
          [-2.5092250, 55.2027210],
          [-2.5091400, 55.2026950],
          [-2.5090490, 55.2026680],
          [-2.5089630, 55.2026380],
          [-2.5088680, 55.2026110],
          [-2.5087710, 55.2025840],
          [-2.5086710, 55.2025570],
          [-2.5085720, 55.2025270],
          [-2.5084690, 55.2024920],
          [-2.5083620, 55.2024580],
          [-2.5082470, 55.2024210],
          [-2.5081240, 55.2023820],
          [-2.5080020, 55.2023440],
          [-2.5078810, 55.2023060],
          [-2.5077530, 55.2022680],
          [-2.5076280, 55.2022260],
          [-2.5075040, 55.2021840],
          [-2.5073800, 55.2021410],
          [-2.5072550, 55.2020960],
          [-2.5071200, 55.2020530],
          [-2.5069860, 55.2020080],
          [-2.5068480, 55.2019620],
          [-2.5067110, 55.2019130],
          [-2.5065620, 55.2018600],
          [-2.5064210, 55.2017990],
          [-2.5062710, 55.2017530],
          [-2.5061310, 55.2017040],
          [-2.5059870, 55.2016570],
          [-2.5058500, 55.2016080],
          [-2.5057140, 55.2015620],
          [-2.5055840, 55.2015200],
          [-2.5054530, 55.2014740],
          [-2.5053260, 55.2014320],
          [-2.5052010, 55.2013900],
          [-2.5050760, 55.2013480],
          [-2.5049520, 55.2013060],
          [-2.5048290, 55.2012640],
          [-2.5047070, 55.2012220],
          [-2.5045850, 55.2011800],
          [-2.5044650, 55.2011380],
          [-2.5043480, 55.2010960],
          [-2.5042280, 55.2010550],
          [-2.5041050, 55.2010130],
          [-2.5039770, 55.2009700],
          [-2.5038580, 55.2009280],
          [-2.5037330, 55.2008830],
          [-2.5036060, 55.2008370],
          [-2.5034700, 55.2007870],
          [-2.5033300, 55.2007420],
          [-2.5031880, 55.2006950],
          [-2.5030430, 55.2006460],
          [-2.5028980, 55.2006000],
          [-2.5027580, 55.2005520],
          [-2.5026110, 55.2005050],
          [-2.5025020, 55.2004680],
          [-2.5023370, 55.2004120],
          [-2.5022070, 55.2003640],
          [-2.5020770, 55.2003220],
          [-2.5019500, 55.2002780],
          [-2.5018200, 55.2002330],
          [-2.5016940, 55.2001920],
          [-2.5015710, 55.2001470],
          [-2.5014480, 55.2001010],
          [-2.5013200, 55.2000590],
          [-2.5011910, 55.2000130],
          [-2.5010710, 55.1999720],
          [-2.5009430, 55.1999290],
          [-2.5008150, 55.1998830],
          [-2.5006860, 55.1998420],
          [-2.5005490, 55.1998030],
          [-2.5004150, 55.1997650],
          [-2.5002790, 55.1997230],
          [-2.5001480, 55.1996850],
          [-2.5000170, 55.1996470],
          [-2.4998850, 55.1996120],
          [-2.4997550, 55.1995820],
          [-2.4996200, 55.1995470],
          [-2.4994860, 55.1995200],
          [-2.4993530, 55.1994900],
          [-2.4992200, 55.1994640],
          [-2.4990820, 55.1994360],
          [-2.4989450, 55.1994140],
          [-2.4988100, 55.1993880],
          [-2.4986730, 55.1993640],
          [-2.4985360, 55.1993490],
          [-2.4984030, 55.1993330],
          [-2.4982640, 55.1993220],
          [-2.4981220, 55.1993110],
          [-2.4979770, 55.1993100],
          [-2.4978310, 55.1993100],
          [-2.4976870, 55.1993140],
          [-2.4975470, 55.1993220],
          [-2.4974090, 55.1993330],
          [-2.4972640, 55.1993440],
          [-2.4971220, 55.1993560],
          [-2.4969810, 55.1993710],
          [-2.4968410, 55.1993900],
          [-2.4966960, 55.1994130],
          [-2.4965570, 55.1994390],
          [-2.4964120, 55.1994660],
          [-2.4962720, 55.1994930],
          [-2.4961260, 55.1995240],
          [-2.4959880, 55.1995530],
          [-2.4958490, 55.1995840],
          [-2.4957130, 55.1996190],
          [-2.4955850, 55.1996530],
          [-2.4954570, 55.1996910],
          [-2.4953300, 55.1997300],
          [-2.4952080, 55.1997670],
          [-2.4950900, 55.1998020],
          [-2.4949750, 55.1998440],
          [-2.4948650, 55.1998820],
          [-2.4947550, 55.1999270],
          [-2.4946480, 55.1999690],
          [-2.4945380, 55.2000190],
          [-2.4944370, 55.2000690],
          [-2.4943380, 55.2001130],
          [-2.4942390, 55.2001630],
          [-2.4941390, 55.2002100],
          [-2.4940360, 55.2002600],
          [-2.4939340, 55.2003060],
          [-2.4938320, 55.2003540],
          [-2.4937250, 55.2004040],
          [-2.4936210, 55.2004580],
          [-2.4935230, 55.2005110],
          [-2.4934270, 55.2005590],
          [-2.4933250, 55.2006140],
          [-2.4932310, 55.2006680],
          [-2.4931400, 55.2007290],
          [-2.4930500, 55.2007890],
          [-2.4929560, 55.2008510],
          [-2.4928560, 55.2009190],
          [-2.4927460, 55.2009970],
          [-2.4926000, 55.2010720],
          [-2.4924460, 55.2011210],
          [-2.4922850, 55.2011550],
          [-2.4921070, 55.2011790],
          [-2.4919420, 55.2011950],
          [-2.4917930, 55.2011950],
          [-2.4916470, 55.2011950],
          [-2.4915080, 55.2011950],
          [-2.4913700, 55.2011990],
          [-2.4912370, 55.2012060],
          [-2.4910980, 55.2012140],
          [-2.4909670, 55.2012320],
          [-2.4908350, 55.2012520],
          [-2.4907110, 55.2012740],
          [-2.4905830, 55.2013010],
          [-2.4904630, 55.2013310],
          [-2.4903430, 55.2013660],
          [-2.4902270, 55.2014040],
          [-2.4901110, 55.2014420],
          [-2.4900030, 55.2014840],
          [-2.4898960, 55.2015260],
          [-2.4897930, 55.2015720],
          [-2.4896920, 55.2016170],
          [-2.4895950, 55.2016630],
          [-2.4894840, 55.2017170],
          [-2.4893860, 55.2017690],
          [-2.4892810, 55.2018270],
          [-2.4891760, 55.2018810],
          [-2.4890730, 55.2019370],
          [-2.4889700, 55.2019950],
          [-2.4888700, 55.2020520],
          [-2.4887770, 55.2021100],
          [-2.4886890, 55.2021670],
          [-2.4886000, 55.2022270],
          [-2.4885080, 55.2022850],
          [-2.4884160, 55.2023450],
          [-2.4883230, 55.2024000],
          [-2.4882280, 55.2024550],
          [-2.4881200, 55.2025140],
          [-2.4880110, 55.2025710],
          [-2.4879020, 55.2026280],
          [-2.4877910, 55.2026890],
          [-2.4876720, 55.2027390],
          [-2.4875540, 55.2027870],
          [-2.4874260, 55.2028340],
          [-2.4872930, 55.2028760],
          [-2.4871550, 55.2029030],
          [-2.4869980, 55.2029150],
          [-2.4868430, 55.2029190],
          [-2.4866750, 55.2028970],
          [-2.4865120, 55.2028670],
          [-2.4863490, 55.2028360],
          [-2.4861950, 55.2028050],
          [-2.4860590, 55.2027640],
          [-2.4859180, 55.2027170],
          [-2.4857990, 55.2026680],
          [-2.4856890, 55.2026230],
          [-2.4855820, 55.2025840],
          [-2.4854840, 55.2025470],
          [-2.4853850, 55.2025200],
          [-2.4852890, 55.2024920],
          [-2.4852060, 55.2024740],
          [-2.4851250, 55.2024580],
          [-2.4850480, 55.2024500],
          [-2.4849790, 55.2024500],
          [-2.4849140, 55.2024500],
          [-2.4848540, 55.2024570],
          [-2.4847970, 55.2024690],
          [-2.4847480, 55.2024800],
          [-2.4847000, 55.2024950],
          [-2.4846480, 55.2025100],
          [-2.4845980, 55.2025300],
          [-2.4845500, 55.2025450],
          [-2.4845050, 55.2025670],
          [-2.4844550, 55.2025870],
          [-2.4844080, 55.2026090],
          [-2.4843650, 55.2026320],
          [-2.4843210, 55.2026520],
          [-2.4842840, 55.2026710],
          [-2.4842520, 55.2026930],
          [-2.4842240, 55.2027160],
          [-2.4842020, 55.2027390],
          [-2.4841820, 55.2027640],
          [-2.4841610, 55.2027880],
          [-2.4841470, 55.2028150],
          [-2.4841340, 55.2028420],
          [-2.4841220, 55.2028650],
          [-2.4841140, 55.2028910],
          [-2.4841070, 55.2029220],
          [-2.4841000, 55.2029520],
          [-2.4840940, 55.2029790],
          [-2.4840900, 55.2030140],
          [-2.4840880, 55.2030440],
          [-2.4840900, 55.2030770],
          [-2.4840930, 55.2031130],
          [-2.4840980, 55.2031470],
          [-2.4841000, 55.2031810],
          [-2.4841020, 55.2032150],
          [-2.4841020, 55.2032460],
          [-2.4841020, 55.2032790],
          [-2.4841020, 55.2033110],
          [-2.4841030, 55.2033450],
          [-2.4841030, 55.2033750],
          [-2.4841030, 55.2034090],
          [-2.4841050, 55.2034440],
          [-2.4841050, 55.2034750],
          [-2.4841030, 55.2035050],
          [-2.4841030, 55.2035360],
          [-2.4841030, 55.2035660],
          [-2.4841020, 55.2035960],
          [-2.4841020, 55.2036270],
          [-2.4841030, 55.2036580],
          [-2.4841000, 55.2036920],
          [-2.4841000, 55.2037220],
          [-2.4840970, 55.2037530],
          [-2.4840920, 55.2037840],
          [-2.4840850, 55.2038180],
          [-2.4840790, 55.2038480],
          [-2.4840700, 55.2038790],
          [-2.4840610, 55.2039100],
          [-2.4840520, 55.2039410],
          [-2.4840440, 55.2039670],
          [-2.4840350, 55.2039940],
          [-2.4840240, 55.2040210],
          [-2.4840120, 55.2040460],
          [-2.4839990, 55.2040740],
          [-2.4839850, 55.2041010],
          [-2.4839690, 55.2041270],
          [-2.4839540, 55.2041540],
          [-2.4839360, 55.2041800],
          [-2.4839190, 55.2042110],
          [-2.4839000, 55.2042420],
          [-2.4838810, 55.2042680],
          [-2.4838620, 55.2042950],
          [-2.4838410, 55.2043250],
          [-2.4838190, 55.2043520],
          [-2.4837960, 55.2043790],
          [-2.4837740, 55.2044080],
          [-2.4837520, 55.2044370],
          [-2.4837340, 55.2044620],
          [-2.4837110, 55.2044940],
          [-2.4836870, 55.2045200],
          [-2.4836670, 55.2045460],
          [-2.4836400, 55.2045730],
          [-2.4836120, 55.2046010],
          [-2.4835880, 55.2046270],
          [-2.4835620, 55.2046520],
          [-2.4835370, 55.2046770],
          [-2.4835130, 55.2047040],
          [-2.4834890, 55.2047310],
          [-2.4834640, 55.2047570],
          [-2.4834390, 55.2047800],
          [-2.4834120, 55.2048070],
          [-2.4833850, 55.2048340],
          [-2.4833590, 55.2048600],
          [-2.4833350, 55.2048870],
          [-2.4833100, 55.2049140],
          [-2.4832820, 55.2049400],
          [-2.4832540, 55.2049710],
          [-2.4832270, 55.2049980],
          [-2.4831990, 55.2050280],
          [-2.4831700, 55.2050550],
          [-2.4831440, 55.2050810],
          [-2.4831180, 55.2051080],
          [-2.4830870, 55.2051350],
          [-2.4830590, 55.2051580],
          [-2.4830320, 55.2051850],
          [-2.4830070, 55.2052110],
          [-2.4829850, 55.2052380],
          [-2.4829590, 55.2052650],
          [-2.4829320, 55.2052910],
          [-2.4829070, 55.2053180],
          [-2.4828840, 55.2053410],
          [-2.4828620, 55.2053680],
          [-2.4828390, 55.2053940],
          [-2.4828170, 55.2054210],
          [-2.4827950, 55.2054440],
          [-2.4827720, 55.2054710],
          [-2.4827490, 55.2054940],
          [-2.4827250, 55.2055160],
          [-2.4827020, 55.2055390],
          [-2.4826770, 55.2055620],
          [-2.4826540, 55.2055890],
          [-2.4826310, 55.2056120],
          [-2.4826050, 55.2056390],
          [-2.4825800, 55.2056610],
          [-2.4825520, 55.2056880],
          [-2.4825250, 55.2057150],
          [-2.4824990, 55.2057380],
          [-2.4824720, 55.2057640],
          [-2.4824450, 55.2057910],
          [-2.4824200, 55.2058180],
          [-2.4823920, 55.2058450],
          [-2.4823640, 55.2058690],
          [-2.4823370, 55.2058940],
          [-2.4823090, 55.2059170],
          [-2.4822790, 55.2059400],
          [-2.4822500, 55.2059670],
          [-2.4822220, 55.2059890],
          [-2.4821900, 55.2060160],
          [-2.4821590, 55.2060390],
          [-2.4821280, 55.2060620],
          [-2.4820940, 55.2060850],
          [-2.4820590, 55.2061080],
          [-2.4820250, 55.2061260],
          [-2.4819890, 55.2061500],
          [-2.4819540, 55.2061690],
          [-2.4819190, 55.2061880],
          [-2.4818840, 55.2062110],
          [-2.4818490, 55.2062300],
          [-2.4818130, 55.2062490],
          [-2.4817750, 55.2062680],
          [-2.4817410, 55.2062870],
          [-2.4817020, 55.2063060],
          [-2.4816670, 55.2063250],
          [-2.4816300, 55.2063480],
          [-2.4815940, 55.2063670],
          [-2.4815570, 55.2063890],
          [-2.4815190, 55.2064130],
          [-2.4814840, 55.2064360],
          [-2.4814490, 55.2064590],
          [-2.4814140, 55.2064840],
          [-2.4813820, 55.2065080],
          [-2.4813540, 55.2065350],
          [-2.4813270, 55.2065620],
          [-2.4813070, 55.2065880],
          [-2.4812850, 55.2066150],
          [-2.4812670, 55.2066420],
          [-2.4812490, 55.2066720],
          [-2.4812320, 55.2066990],
          [-2.4812150, 55.2067290],
          [-2.4812000, 55.2067560],
          [-2.4811890, 55.2067820],
          [-2.4811800, 55.2068090],
          [-2.4811680, 55.2068360],
          [-2.4811550, 55.2068630],
          [-2.4811440, 55.2068890],
          [-2.4811340, 55.2069160],
          [-2.4811220, 55.2069430],
          [-2.4811090, 55.2069700],
          [-2.4810920, 55.2069970],
          [-2.4810740, 55.2070230],
          [-2.4810550, 55.2070540],
          [-2.4810400, 55.2070840],
          [-2.4810250, 55.2071110],
          [-2.4810130, 55.2071380],
          [-2.4810030, 55.2071640],
          [-2.4809900, 55.2071910],
          [-2.4809780, 55.2072180],
          [-2.4809650, 55.2072480],
          [-2.4809530, 55.2072750],
          [-2.4809420, 55.2073020],
          [-2.4809320, 55.2073280],
          [-2.4809190, 55.2073590],
          [-2.4809050, 55.2073890],
          [-2.4808900, 55.2074160],
          [-2.4808750, 55.2074460],
          [-2.4808600, 55.2074730],
          [-2.4808440, 55.2075040],
          [-2.4808270, 55.2075330],
          [-2.4808100, 55.2075650],
          [-2.4807920, 55.2075990],
          [-2.4807770, 55.2076300],
          [-2.4807600, 55.2076640],
          [-2.4807420, 55.2076980],
          [-2.4807250, 55.2077320],
          [-2.4807090, 55.2077670],
          [-2.4806940, 55.2078000],
          [-2.4806750, 55.2078360],
          [-2.4806600, 55.2078700],
          [-2.4806450, 55.2079040],
          [-2.4806290, 55.2079340],
          [-2.4806120, 55.2079610],
          [-2.4806000, 55.2079920],
          [-2.4805870, 55.2080220],
          [-2.4805700, 55.2080490],
          [-2.4805540, 55.2080760],
          [-2.4805440, 55.2081030],
          [-2.4805340, 55.2081290],
          [-2.4805200, 55.2081560],
          [-2.4805080, 55.2081790],
          [-2.4804970, 55.2082060],
          [-2.4804820, 55.2082280],
          [-2.4804620, 55.2082510],
          [-2.4804420, 55.2082750],
          [-2.4804270, 55.2083010],
          [-2.4804140, 55.2083280],
          [-2.4803970, 55.2083540],
          [-2.4803780, 55.2083850],
          [-2.4803620, 55.2084120],
          [-2.4803450, 55.2084380],
          [-2.4803290, 55.2084690],
          [-2.4803100, 55.2084990],
          [-2.4802900, 55.2085260],
          [-2.4802690, 55.2085530],
          [-2.4802470, 55.2085870],
          [-2.4802240, 55.2086170],
          [-2.4802010, 55.2086480],
          [-2.4801770, 55.2086790],
          [-2.4801570, 55.2087130],
          [-2.4801370, 55.2087400],
          [-2.4801150, 55.2087660],
          [-2.4800940, 55.2087930],
          [-2.4800720, 55.2088200],
          [-2.4800530, 55.2088460],
          [-2.4800340, 55.2088730],
          [-2.4800150, 55.2089000],
          [-2.4799970, 55.2089300],
          [-2.4799770, 55.2089540],
          [-2.4799570, 55.2089800],
          [-2.4799370, 55.2090070],
          [-2.4799200, 55.2090340],
          [-2.4799000, 55.2090640],
          [-2.4798800, 55.2090910],
          [-2.4798600, 55.2091170],
          [-2.4798430, 55.2091430],
          [-2.4798240, 55.2091710],
          [-2.4798040, 55.2091980],
          [-2.4797850, 55.2092240],
          [-2.4797640, 55.2092510],
          [-2.4797420, 55.2092740],
          [-2.4797220, 55.2093000],
          [-2.4797040, 55.2093270],
          [-2.4796840, 55.2093540],
          [-2.4796640, 55.2093810],
          [-2.4796440, 55.2094070],
          [-2.4796250, 55.2094300],
          [-2.4796040, 55.2094570],
          [-2.4795820, 55.2094800],
          [-2.4795620, 55.2095070],
          [-2.4795390, 55.2095290],
          [-2.4795190, 55.2095560],
          [-2.4795000, 55.2095830],
          [-2.4794800, 55.2096060],
          [-2.4794570, 55.2096290],
          [-2.4794320, 55.2096550],
          [-2.4794090, 55.2096780],
          [-2.4793830, 55.2097050],
          [-2.4793590, 55.2097280],
          [-2.4793320, 55.2097540],
          [-2.4793040, 55.2097740],
          [-2.4792760, 55.2097960],
          [-2.4792440, 55.2098200],
          [-2.4792130, 55.2098410],
          [-2.4791790, 55.2098610],
          [-2.4791450, 55.2098840],
          [-2.4791090, 55.2099000],
          [-2.4790690, 55.2099180],
          [-2.4790260, 55.2099360],
          [-2.4789830, 55.2099530],
          [-2.4789410, 55.2099680],
          [-2.4788940, 55.2099800],
          [-2.4788490, 55.2099950],
          [-2.4788010, 55.2100100],
          [-2.4787510, 55.2100250],
          [-2.4786990, 55.2100370],
          [-2.4786480, 55.2100480],
          [-2.4785950, 55.2100600],
          [-2.4785400, 55.2100710],
          [-2.4784840, 55.2100870],
          [-2.4784290, 55.2100980],
          [-2.4783720, 55.2101100],
          [-2.4783180, 55.2101210],
          [-2.4782590, 55.2101360],
          [-2.4782010, 55.2101480],
          [-2.4781430, 55.2101620],
          [-2.4780770, 55.2101740],
          [-2.4780120, 55.2101900],
          [-2.4779460, 55.2102050],
          [-2.4778770, 55.2102200],
          [-2.4778110, 55.2102390],
          [-2.4777410, 55.2102540],
          [-2.4776700, 55.2102730],
          [-2.4775960, 55.2102920],
          [-2.4775240, 55.2103070],
          [-2.4774420, 55.2103270],
          [-2.4773620, 55.2103450],
          [-2.4772760, 55.2103650],
          [-2.4771860, 55.2103880],
          [-2.4770910, 55.2104110],
          [-2.4769960, 55.2104300],
          [-2.4769010, 55.2104530],
          [-2.4768080, 55.2104720],
          [-2.4767160, 55.2104950],
          [-2.4766280, 55.2105140],
          [-2.4765420, 55.2105290],
          [-2.4764600, 55.2105480],
          [-2.4763750, 55.2105670],
          [-2.4762920, 55.2105860],
          [-2.4762090, 55.2106090],
          [-2.4761260, 55.2106280],
          [-2.4760430, 55.2106510],
          [-2.4759660, 55.2106700],
          [-2.4758860, 55.2106850],
          [-2.4758100, 55.2106970],
          [-2.4757340, 55.2107120],
          [-2.4756670, 55.2107270],
          [-2.4756030, 55.2107430],
          [-2.4755470, 55.2107570],
          [-2.4754850, 55.2107690],
          [-2.4754270, 55.2107840],
          [-2.4753710, 55.2108000],
          [-2.4753200, 55.2108110],
          [-2.4752730, 55.2108250],
          [-2.4752170, 55.2108380],
          [-2.4751640, 55.2108530],
          [-2.4751130, 55.2108650],
          [-2.4750620, 55.2108760],
          [-2.4750110, 55.2108910],
          [-2.4749600, 55.2109030],
          [-2.4749060, 55.2109180],
          [-2.4748540, 55.2109300],
          [-2.4748030, 55.2109410],
          [-2.4747520, 55.2109520],
          [-2.4747010, 55.2109640],
          [-2.4746530, 55.2109750],
          [-2.4746060, 55.2109870],
          [-2.4745580, 55.2109980],
          [-2.4745100, 55.2110060],
          [-2.4744590, 55.2110170],
          [-2.4744080, 55.2110250],
          [-2.4743540, 55.2110330],
          [-2.4743030, 55.2110370],
          [-2.4742490, 55.2110400],
          [-2.4741950, 55.2110440],
          [-2.4741400, 55.2110440],
          [-2.4740840, 55.2110440],
          [-2.4740270, 55.2110410],
          [-2.4739680, 55.2110400],
          [-2.4739110, 55.2110400],
          [-2.4738530, 55.2110370],
          [-2.4737980, 55.2110330],
          [-2.4737450, 55.2110290],
          [-2.4736960, 55.2110250],
          [-2.4736470, 55.2110180],
          [-2.4735980, 55.2110140],
          [-2.4735480, 55.2110100],
          [-2.4734960, 55.2110100],
          [-2.4734470, 55.2110030],
          [-2.4734030, 55.2109910],
          [-2.4733570, 55.2109840],
          [-2.4733070, 55.2109830],
          [-2.4732540, 55.2109800],
          [-2.4732030, 55.2109720],
          [-2.4731520, 55.2109650],
          [-2.4731030, 55.2109570],
          [-2.4730530, 55.2109530],
          [-2.4730020, 55.2109450],
          [-2.4729510, 55.2109380],
          [-2.4728980, 55.2109300],
          [-2.4728480, 55.2109220],
          [-2.4727980, 55.2109150],
          [-2.4727470, 55.2109070],
          [-2.4726940, 55.2109000],
          [-2.4726400, 55.2108880],
          [-2.4725880, 55.2108810],
          [-2.4725360, 55.2108730],
          [-2.4724820, 55.2108650],
          [-2.4724320, 55.2108580],
          [-2.4723820, 55.2108460],
          [-2.4723330, 55.2108390],
          [-2.4722840, 55.2108270],
          [-2.4722330, 55.2108200],
          [-2.4721840, 55.2108120],
          [-2.4721350, 55.2108040],
          [-2.4720850, 55.2107970],
          [-2.4720350, 55.2107850],
          [-2.4719820, 55.2107780],
          [-2.4719310, 55.2107700],
          [-2.4718780, 55.2107620],
          [-2.4718280, 55.2107550],
          [-2.4717750, 55.2107470],
          [-2.4717230, 55.2107390],
          [-2.4716700, 55.2107320],
          [-2.4716180, 55.2107200],
          [-2.4715650, 55.2107130],
          [-2.4715100, 55.2107050],
          [-2.4714540, 55.2106940],
          [-2.4713960, 55.2106860],
          [-2.4713360, 55.2106780],
          [-2.4712760, 55.2106670],
          [-2.4712160, 55.2106550],
          [-2.4711590, 55.2106480],
          [-2.4711010, 55.2106400],
          [-2.4710430, 55.2106290],
          [-2.4709890, 55.2106210],
          [-2.4709330, 55.2106100],
          [-2.4708780, 55.2106020],
          [-2.4708240, 55.2105910],
          [-2.4707700, 55.2105830],
          [-2.4707120, 55.2105750],
          [-2.4706510, 55.2105640],
          [-2.4705910, 55.2105560],
          [-2.4705250, 55.2105450],
          [-2.4704560, 55.2105340],
          [-2.4703790, 55.2105180],
          [-2.4703000, 55.2105030],
          [-2.4702240, 55.2104880],
          [-2.4701420, 55.2104730],
          [-2.4700630, 55.2104570],
          [-2.4699810, 55.2104380],
          [-2.4698980, 55.2104230],
          [-2.4698130, 55.2104040],
          [-2.4697260, 55.2103850],
          [-2.4696370, 55.2103660],
          [-2.4695500, 55.2103470],
          [-2.4694600, 55.2103280],
          [-2.4693670, 55.2103050],
          [-2.4692720, 55.2102860],
          [-2.4691810, 55.2102600],
          [-2.4690770, 55.2102360],
          [-2.4689770, 55.2102130],
          [-2.4688740, 55.2101870],
          [-2.4687700, 55.2101610],
          [-2.4686590, 55.2101330],
          [-2.4685500, 55.2101070],
          [-2.4684420, 55.2100800],
          [-2.4683380, 55.2100540],
          [-2.4682310, 55.2100300],
          [-2.4681230, 55.2100040],
          [-2.4680140, 55.2099730],
          [-2.4679020, 55.2099470],
          [-2.4677880, 55.2099230],
          [-2.4676830, 55.2098980],
          [-2.4675710, 55.2098660],
          [-2.4674660, 55.2098400],
          [-2.4673580, 55.2098170],
          [-2.4672550, 55.2097910],
          [-2.4671450, 55.2097710],
          [-2.4670380, 55.2097450],
          [-2.4669260, 55.2097180],
          [-2.4668190, 55.2096910],
          [-2.4667070, 55.2096640],
          [-2.4665980, 55.2096390],
          [-2.4664700, 55.2096070],
          [-2.4663530, 55.2095770],
          [-2.4662300, 55.2095460],
          [-2.4661110, 55.2095120],
          [-2.4659890, 55.2094810],
          [-2.4658610, 55.2094510],
          [-2.4657370, 55.2094200],
          [-2.4656140, 55.2093860],
          [-2.4654920, 55.2093520],
          [-2.4653750, 55.2093220],
          [-2.4652630, 55.2092960],
          [-2.4651450, 55.2092650],
          [-2.4650150, 55.2092290],
          [-2.4648900, 55.2091960],
          [-2.4647610, 55.2091610],
          [-2.4646280, 55.2091310],
          [-2.4644980, 55.2091000],
          [-2.4643650, 55.2090690],
          [-2.4642370, 55.2090390],
          [-2.4641070, 55.2090050],
          [-2.4639800, 55.2089700],
          [-2.4638500, 55.2089400],
          [-2.4637040, 55.2089130],
          [-2.4635600, 55.2088790],
          [-2.4634060, 55.2088410],
          [-2.4632620, 55.2088070],
          [-2.4631210, 55.2087680],
          [-2.4629790, 55.2087370],
          [-2.4628350, 55.2087070],
          [-2.4626740, 55.2086730],
          [-2.4625080, 55.2086420],
          [-2.4623390, 55.2086080],
          [-2.4621470, 55.2085510],
          [-2.4619600, 55.2084980],
          [-2.4617410, 55.2084310],
          [-2.4615310, 55.2083690],
          [-2.4613390, 55.2083190],
          [-2.4611530, 55.2082730],
          [-2.4609540, 55.2082150],
          [-2.4607360, 55.2081610],
          [-2.4605330, 55.2081240],
          [-2.4603500, 55.2081040],
          [-2.4601680, 55.2080920],
          [-2.4599990, 55.2080770],
          [-2.4598330, 55.2080660],
          [-2.4596660, 55.2080540],
          [-2.4595090, 55.2080500],
          [-2.4593500, 55.2080390],
          [-2.4591900, 55.2080310],
          [-2.4590490, 55.2080280],
          [-2.4588730, 55.2080160],
          [-2.4587040, 55.2080080],
          [-2.4585450, 55.2080010],
          [-2.4583860, 55.2079970],
          [-2.4582260, 55.2079890],
          [-2.4580710, 55.2079850],
          [-2.4579240, 55.2079780],
          [-2.4577740, 55.2079700],
          [-2.4576230, 55.2079620],
          [-2.4574730, 55.2079580],
          [-2.4573290, 55.2079510],
          [-2.4571800, 55.2079430],
          [-2.4570320, 55.2079390],
          [-2.4568840, 55.2079350],
          [-2.4567380, 55.2079320],
          [-2.4565940, 55.2079240],
          [-2.4564550, 55.2079200],
          [-2.4563090, 55.2079130],
          [-2.4561660, 55.2079050],
          [-2.4560140, 55.2079050],
          [-2.4558680, 55.2079050],
          [-2.4557240, 55.2079050],
          [-2.4555850, 55.2079120],
          [-2.4554470, 55.2079200],
          [-2.4553060, 55.2079310],
          [-2.4551710, 55.2079420],
          [-2.4550430, 55.2079570],
          [-2.4549060, 55.2079730],
          [-2.4547850, 55.2079880],
          [-2.4546580, 55.2080070],
          [-2.4545390, 55.2080260],
          [-2.4544180, 55.2080490],
          [-2.4543090, 55.2080750],
          [-2.4541940, 55.2080990],
          [-2.4540880, 55.2081250],
          [-2.4539790, 55.2081560],
          [-2.4538770, 55.2081820],
          [-2.4537760, 55.2082150],
          [-2.4536740, 55.2082450],
          [-2.4535620, 55.2082780],
          [-2.4534600, 55.2083100],
          [-2.4533470, 55.2083420],
          [-2.4532450, 55.2083780],
          [-2.4531250, 55.2084150],
          [-2.4530240, 55.2084540],
          [-2.4529080, 55.2084980],
          [-2.4528060, 55.2085480],
          [-2.4526990, 55.2086150],
          [-2.4526210, 55.2086790],
          [-2.4525500, 55.2087600],
          [-2.4525010, 55.2088360],
          [-2.4524790, 55.2088940],
          [-2.4524700, 55.2089880],
          [-2.4524760, 55.2090500],
          [-2.4524880, 55.2091090],
          [-2.4524980, 55.2091630],
          [-2.4525030, 55.2092120],
          [-2.4525020, 55.2092580],
          [-2.4525020, 55.2093000],
          [-2.4524950, 55.2093380],
          [-2.4524870, 55.2093720],
          [-2.4524750, 55.2094070],
          [-2.4524640, 55.2094410],
          [-2.4524540, 55.2094760],
          [-2.4524450, 55.2095070],
          [-2.4524320, 55.2095400],
          [-2.4524150, 55.2095710],
          [-2.4524000, 55.2096050],
          [-2.4523840, 55.2096360],
          [-2.4523700, 55.2096740],
          [-2.4523550, 55.2097050],
          [-2.4523340, 55.2097360],
          [-2.4523180, 55.2097680],
          [-2.4523020, 55.2098070],
          [-2.4522870, 55.2098490],
          [-2.4522720, 55.2098880],
          [-2.4522600, 55.2099220],
          [-2.4522470, 55.2099600],
          [-2.4522320, 55.2099950],
          [-2.4522180, 55.2100270],
          [-2.4522020, 55.2100630],
          [-2.4521870, 55.2101010],
          [-2.4521720, 55.2101400],
          [-2.4521540, 55.2101740],
          [-2.4521390, 55.2102080],
          [-2.4521220, 55.2102430],
          [-2.4520990, 55.2102730],
          [-2.4520620, 55.2103040],
          [-2.4520110, 55.2103230],
          [-2.4519470, 55.2103350],
          [-2.4518780, 55.2103310],
          [-2.4518100, 55.2103200],
          [-2.4517450, 55.2102970],
          [-2.4516830, 55.2102780],
          [-2.4516220, 55.2102560],
          [-2.4515620, 55.2102240],
          [-2.4515140, 55.2101900],
          [-2.4514620, 55.2101560],
          [-2.4514090, 55.2101180],
          [-2.4513580, 55.2100800],
          [-2.4513100, 55.2100380],
          [-2.4512610, 55.2099920],
          [-2.4512060, 55.2099460],
          [-2.4511470, 55.2098980],
          [-2.4510830, 55.2098470],
          [-2.4510190, 55.2097940],
          [-2.4509540, 55.2097360],
          [-2.4508910, 55.2096800],
          [-2.4508260, 55.2096220],
          [-2.4507640, 55.2095620],
          [-2.4507020, 55.2095000],
          [-2.4506410, 55.2094350],
          [-2.4505770, 55.2093700],
          [-2.4505130, 55.2092980],
          [-2.4504420, 55.2092290],
          [-2.4503690, 55.2091570],
          [-2.4502950, 55.2090890],
          [-2.4502160, 55.2090200],
          [-2.4501440, 55.2089470],
          [-2.4500870, 55.2088690],
          [-2.4500390, 55.2087830],
          [-2.4499880, 55.2086960],
          [-2.4499190, 55.2086080],
          [-2.4498340, 55.2085210],
          [-2.4497160, 55.2084390],
          [-2.4495950, 55.2083560],
          [-2.4494750, 55.2082680],
          [-2.4493660, 55.2081690],
          [-2.4492760, 55.2080660],
          [-2.4492370, 55.2079560],
          [-2.4492430, 55.2078410],
          [-2.4493280, 55.2077370],
          [-2.4494530, 55.2076470],
          [-2.4495750, 55.2075620],
          [-2.4497100, 55.2074790],
          [-2.4498460, 55.2073800],
          [-2.4499650, 55.2072840],
          [-2.4500800, 55.2071810],
          [-2.4501960, 55.2070740],
          [-2.4503010, 55.2069640],
          [-2.4503980, 55.2068570],
          [-2.4505050, 55.2067390],
          [-2.4506030, 55.2066240],
          [-2.4507120, 55.2065100],
          [-2.4508060, 55.2064030],
          [-2.4508940, 55.2062960],
          [-2.4509970, 55.2061970],
          [-2.4511240, 55.2061060],
          [-2.4512770, 55.2060180],
          [-2.4514370, 55.2059300],
          [-2.4515950, 55.2058380],
          [-2.4517300, 55.2057440],
          [-2.4518520, 55.2056440],
          [-2.4519280, 55.2055450],
          [-2.4519600, 55.2054340],
          [-2.4519860, 55.2053120],
          [-2.4519980, 55.2051830],
          [-2.4520210, 55.2050490],
          [-2.4520350, 55.2049200],
          [-2.4520730, 55.2047900],
          [-2.4521030, 55.2046560],
          [-2.4521300, 55.2045270],
          [-2.4521450, 55.2044050],
          [-2.4521420, 55.2042750],
          [-2.4521290, 55.2041410],
          [-2.4520690, 55.2040180],
          [-2.4519880, 55.2039020],
          [-2.4518710, 55.2037970],
          [-2.4517450, 55.2037040],
          [-2.4515650, 55.2036180],
          [-2.4514000, 55.2035490],
          [-2.4512530, 55.2034920],
          [-2.4511080, 55.2034310],
          [-2.4509680, 55.2033700],
          [-2.4508400, 55.2033050],
          [-2.4507270, 55.2032410],
          [-2.4506240, 55.2031680],
          [-2.4505200, 55.2030920],
          [-2.4504180, 55.2030160],
          [-2.4503250, 55.2029530],
          [-2.4502170, 55.2028880],
          [-2.4500970, 55.2028260],
          [-2.4499660, 55.2027670],
          [-2.4498450, 55.2027020],
          [-2.4497300, 55.2026450],
          [-2.4496170, 55.2025840],
          [-2.4495070, 55.2025210],
          [-2.4493880, 55.2024580],
          [-2.4492670, 55.2024010],
          [-2.4491520, 55.2023360],
          [-2.4490350, 55.2022800],
          [-2.4489070, 55.2022140],
          [-2.4487980, 55.2021470],
          [-2.4487030, 55.2020730],
          [-2.4486200, 55.2019900],
          [-2.4485490, 55.2018970],
          [-2.4484750, 55.2018110],
          [-2.4483830, 55.2017190],
          [-2.4482900, 55.2016350],
          [-2.4481980, 55.2015580],
          [-2.4480950, 55.2014860],
          [-2.4479760, 55.2014130],
          [-2.4478610, 55.2013450],
          [-2.4477200, 55.2012770],
          [-2.4475880, 55.2012110],
          [-2.4474800, 55.2011460],
          [-2.4473750, 55.2010770],
          [-2.4472770, 55.2010060],
          [-2.4471800, 55.2009370],
          [-2.4470860, 55.2008680],
          [-2.4470080, 55.2007920],
          [-2.4469410, 55.2007150],
          [-2.4468780, 55.2006360],
          [-2.4468160, 55.2005500],
          [-2.4467570, 55.2004550],
          [-2.4467040, 55.2003650],
          [-2.4466400, 55.2002780],
          [-2.4465840, 55.2001890],
          [-2.4465310, 55.2000970],
          [-2.4464810, 55.1999980],
          [-2.4464140, 55.1998950],
          [-2.4463670, 55.1998000],
          [-2.4463200, 55.1997030],
          [-2.4462790, 55.1995970],
          [-2.4462390, 55.1994980],
          [-2.4461910, 55.1993990],
          [-2.4461370, 55.1993030],
          [-2.4460710, 55.1991930],
          [-2.4460060, 55.1990980],
          [-2.4459530, 55.1990000],
          [-2.4458980, 55.1989140],
          [-2.4458400, 55.1988170],
          [-2.4457910, 55.1987220],
          [-2.4457310, 55.1986180],
          [-2.4456760, 55.1985190],
          [-2.4456180, 55.1984120],
          [-2.4455540, 55.1982990],
          [-2.4454940, 55.1981820],
          [-2.4454240, 55.1980570],
          [-2.4453580, 55.1979320],
          [-2.4452850, 55.1978030],
          [-2.4452210, 55.1976750],
          [-2.4451530, 55.1975350],
          [-2.4450840, 55.1973970],
          [-2.4450080, 55.1972640],
          [-2.4449300, 55.1971320],
          [-2.4448550, 55.1970060],
          [-2.4447850, 55.1968760],
          [-2.4447130, 55.1967370],
          [-2.4446430, 55.1966080],
          [-2.4445890, 55.1964780],
          [-2.4445740, 55.1963560],
          [-2.4445720, 55.1962420],
          [-2.4446070, 55.1961440],
          [-2.4446180, 55.1960470],
          [-2.4446090, 55.1959550],
          [-2.4445730, 55.1958710],
          [-2.4444860, 55.1958030],
          [-2.4443610, 55.1957450],
          [-2.4442230, 55.1956950],
          [-2.4440540, 55.1956680],
          [-2.4438910, 55.1956450],
          [-2.4437210, 55.1956250],
          [-2.4435590, 55.1956330],
          [-2.4433920, 55.1956440],
          [-2.4432300, 55.1956630],
          [-2.4430720, 55.1956780],
          [-2.4429140, 55.1956900],
          [-2.4427610, 55.1957020],
          [-2.4426130, 55.1957160],
          [-2.4424660, 55.1957280],
          [-2.4423200, 55.1957360],
          [-2.4421870, 55.1957400],
          [-2.4420530, 55.1957430],
          [-2.4419290, 55.1957360],
          [-2.4418070, 55.1957250],
          [-2.4416820, 55.1956950],
          [-2.4415530, 55.1956600],
          [-2.4414270, 55.1956190],
          [-2.4412950, 55.1955760],
          [-2.4411650, 55.1955340],
          [-2.4410320, 55.1954890],
          [-2.4408950, 55.1954510],
          [-2.4407530, 55.1954080],
          [-2.4406160, 55.1953630],
          [-2.4404790, 55.1953170],
          [-2.4403470, 55.1952680],
          [-2.4402180, 55.1952100],
          [-2.4401030, 55.1951570],
          [-2.4399910, 55.1951080],
          [-2.4398930, 55.1950650],
          [-2.4398060, 55.1950230],
          [-2.4397230, 55.1949810],
          [-2.4396440, 55.1949390],
          [-2.4395690, 55.1949050],
          [-2.4394940, 55.1948700],
          [-2.4394210, 55.1948330],
          [-2.4393510, 55.1947990],
          [-2.4392770, 55.1947640],
          [-2.4392090, 55.1947370],
          [-2.4391380, 55.1947060],
          [-2.4390660, 55.1946800],
          [-2.4389970, 55.1946490],
          [-2.4389310, 55.1946150],
          [-2.4388610, 55.1945850],
          [-2.4387950, 55.1945540],
          [-2.4387280, 55.1945270],
          [-2.4386560, 55.1944970],
          [-2.4385880, 55.1944660],
          [-2.4385210, 55.1944360],
          [-2.4384580, 55.1943980],
          [-2.4383920, 55.1943670],
          [-2.4383260, 55.1943360],
          [-2.4382580, 55.1943060],
          [-2.4381870, 55.1942760],
          [-2.4381130, 55.1942450],
          [-2.4380390, 55.1942140],
          [-2.4379620, 55.1941840],
          [-2.4378850, 55.1941540],
          [-2.4378040, 55.1941190],
          [-2.4377220, 55.1940850],
          [-2.4376380, 55.1940500],
          [-2.4375610, 55.1940200],
          [-2.4374870, 55.1939890],
          [-2.4374110, 55.1939510],
          [-2.4373380, 55.1939090],
          [-2.4372680, 55.1938640],
          [-2.4372030, 55.1938140],
          [-2.4371370, 55.1937610],
          [-2.4370660, 55.1937070],
          [-2.4369940, 55.1936500],
          [-2.4369210, 55.1935890],
          [-2.4368540, 55.1935330],
          [-2.4367860, 55.1934710],
          [-2.4367100, 55.1934020],
          [-2.4366360, 55.1933260],
          [-2.4365570, 55.1932500],
          [-2.4364780, 55.1931660],
          [-2.4364000, 55.1930790],
          [-2.4363130, 55.1929900],
          [-2.4361950, 55.1928760],
          [-2.4360980, 55.1927770],
          [-2.4360060, 55.1926780],
          [-2.4359210, 55.1925710],
          [-2.4358210, 55.1924640],
          [-2.4357280, 55.1923580],
          [-2.4356310, 55.1922620],
          [-2.4355390, 55.1921640],
          [-2.4354300, 55.1920710],
          [-2.4353070, 55.1919870],
          [-2.4351860, 55.1919120],
          [-2.4350650, 55.1918340],
          [-2.4349620, 55.1917700],
          [-2.4348600, 55.1917080],
          [-2.4347570, 55.1916520],
          [-2.4346560, 55.1915940],
          [-2.4345610, 55.1915410],
          [-2.4344680, 55.1914870],
          [-2.4343820, 55.1914380],
          [-2.4342950, 55.1913880],
          [-2.4342110, 55.1913420],
          [-2.4341330, 55.1912930],
          [-2.4340680, 55.1912360],
          [-2.4340270, 55.1911700],
          [-2.4340300, 55.1911020],
          [-2.4340600, 55.1910290],
          [-2.4341020, 55.1909500],
          [-2.4341460, 55.1908690],
          [-2.4341860, 55.1907970],
          [-2.4342070, 55.1907320],
          [-2.4341920, 55.1906780],
          [-2.4341410, 55.1906440],
          [-2.4340700, 55.1906320],
          [-2.4339960, 55.1906320],
          [-2.4339210, 55.1906430],
          [-2.4338470, 55.1906540],
          [-2.4337630, 55.1906660],
          [-2.4336760, 55.1906770],
          [-2.4335830, 55.1906810],
          [-2.4334880, 55.1906850],
          [-2.4333940, 55.1906850],
          [-2.4332990, 55.1906820],
          [-2.4331980, 55.1906810],
          [-2.4331030, 55.1906700],
          [-2.4330030, 55.1906590],
          [-2.4329030, 55.1906470],
          [-2.4328040, 55.1906320],
          [-2.4327110, 55.1906170],
          [-2.4326140, 55.1906020],
          [-2.4325250, 55.1905860],
          [-2.4324330, 55.1905670],
          [-2.4323420, 55.1905520],
          [-2.4322500, 55.1905330],
          [-2.4321590, 55.1905140],
          [-2.4320610, 55.1904980],
          [-2.4319700, 55.1904800],
          [-2.4318750, 55.1904600],
          [-2.4317840, 55.1904420],
          [-2.4316910, 55.1904190],
          [-2.4315910, 55.1903960],
          [-2.4314860, 55.1903730],
          [-2.4313760, 55.1903390],
          [-2.4312620, 55.1903080],
          [-2.4311480, 55.1902770],
          [-2.4310320, 55.1902430],
          [-2.4309100, 55.1902090],
          [-2.4307790, 55.1901670],
          [-2.4306510, 55.1901300],
          [-2.4305170, 55.1900880],
          [-2.4303820, 55.1900500],
          [-2.4302380, 55.1900110],
          [-2.4301000, 55.1899720],
          [-2.4299660, 55.1899340],
          [-2.4298340, 55.1899010],
          [-2.4296950, 55.1898620],
          [-2.4295600, 55.1898240],
          [-2.4294360, 55.1897880],
          [-2.4292950, 55.1897510],
          [-2.4291630, 55.1897170],
          [-2.4290280, 55.1896900],
          [-2.4288890, 55.1896710],
          [-2.4287430, 55.1896630],
          [-2.4286010, 55.1896780],
          [-2.4284720, 55.1897080],
          [-2.4283560, 55.1897530],
          [-2.4282580, 55.1898070],
          [-2.4281770, 55.1898640],
          [-2.4281090, 55.1899170],
          [-2.4280510, 55.1899710],
          [-2.4280030, 55.1900200],
          [-2.4279570, 55.1900660],
          [-2.4279160, 55.1901120],
          [-2.4278770, 55.1901510],
          [-2.4278410, 55.1901880],
          [-2.4278010, 55.1902230],
          [-2.4277610, 55.1902610],
          [-2.4277240, 55.1902960],
          [-2.4276870, 55.1903330],
          [-2.4276510, 55.1903720],
          [-2.4276130, 55.1904100],
          [-2.4275740, 55.1904480],
          [-2.4275340, 55.1904860],
          [-2.4274940, 55.1905250],
          [-2.4274510, 55.1905660],
          [-2.4274060, 55.1906080],
          [-2.4273590, 55.1906500],
          [-2.4273250, 55.1907140],
          [-2.4272900, 55.1907790],
          [-2.4272560, 55.1908430],
          [-2.4271790, 55.1908540],
          [-2.4271200, 55.1908770],
          [-2.4270690, 55.1909060],
          [-2.4270250, 55.1909410],
          [-2.4269920, 55.1909750],
          [-2.4269540, 55.1910130],
          [-2.4269280, 55.1910550],
          [-2.4268920, 55.1910900],
          [-2.4268450, 55.1911240],
          [-2.4268030, 55.1911600],
          [-2.4267670, 55.1912040],
          [-2.4267300, 55.1912460],
          [-2.4266970, 55.1912880],
          [-2.4266590, 55.1913220],
          [-2.4266210, 55.1913560],
          [-2.4265790, 55.1913900],
          [-2.4265350, 55.1914210],
          [-2.4264900, 55.1914480],
          [-2.4264440, 55.1914750],
          [-2.4263960, 55.1915010],
          [-2.4263470, 55.1915280],
          [-2.4262950, 55.1915470],
          [-2.4262470, 55.1915700],
          [-2.4262010, 55.1915930],
          [-2.4261540, 55.1916160],
          [-2.4261060, 55.1916390],
          [-2.4260590, 55.1916650],
          [-2.4260120, 55.1916880],
          [-2.4259710, 55.1917110],
          [-2.4259340, 55.1917340],
          [-2.4258980, 55.1917610],
          [-2.4258710, 55.1917830],
          [-2.4258440, 55.1918060],
          [-2.4258170, 55.1918330],
          [-2.4257920, 55.1918600],
          [-2.4257720, 55.1918910],
          [-2.4257500, 55.1919170],
          [-2.4257270, 55.1919440],
          [-2.4257050, 55.1919700],
          [-2.4256840, 55.1919890],
          [-2.4256690, 55.1920090],
          [-2.4256530, 55.1920360],
          [-2.4256350, 55.1920620],
          [-2.4256200, 55.1920920],
          [-2.4256070, 55.1921230],
          [-2.4255930, 55.1921500],
          [-2.4255800, 55.1921760],
          [-2.4255650, 55.1922030],
          [-2.4255520, 55.1922300],
          [-2.4255350, 55.1922570],
          [-2.4255130, 55.1922800],
          [-2.4254920, 55.1923140],
          [-2.4254640, 55.1923470],
          [-2.4254350, 55.1923750],
          [-2.4254060, 55.1924050],
          [-2.4253740, 55.1924320],
          [-2.4253400, 55.1924540],
          [-2.4253030, 55.1924780],
          [-2.4252710, 55.1924960],
          [-2.4252390, 55.1925200],
          [-2.4252070, 55.1925430],
          [-2.4251710, 55.1925650],
          [-2.4251310, 55.1925850],
          [-2.4250890, 55.1926000],
          [-2.4250480, 55.1926300],
          [-2.4250050, 55.1926610],
          [-2.4249620, 55.1926910],
          [-2.4249160, 55.1927180],
          [-2.4248690, 55.1927370],
          [-2.4248290, 55.1927570],
          [-2.4247860, 55.1927750],
          [-2.4247390, 55.1927940],
          [-2.4246930, 55.1928170],
          [-2.4246480, 55.1928360],
          [-2.4246050, 55.1928510],
          [-2.4245590, 55.1928710],
          [-2.4245120, 55.1928940],
          [-2.4244620, 55.1929170],
          [-2.4244070, 55.1929320],
          [-2.4243510, 55.1929430],
          [-2.4242960, 55.1929660],
          [-2.4242430, 55.1929890],
          [-2.4241920, 55.1930160],
          [-2.4241430, 55.1930420],
          [-2.4240990, 55.1930620],
          [-2.4240540, 55.1930920],
          [-2.4240090, 55.1931110],
          [-2.4239620, 55.1931340],
          [-2.4239190, 55.1931570],
          [-2.4238780, 55.1931830],
          [-2.4238360, 55.1932100],
          [-2.4237940, 55.1932370],
          [-2.4237600, 55.1932600],
          [-2.4237290, 55.1932900],
          [-2.4236940, 55.1933130],
          [-2.4236640, 55.1933360],
          [-2.4236320, 55.1933590],
          [-2.4236040, 55.1933740],
          [-2.4235760, 55.1934010],
          [-2.4235470, 55.1934240],
          [-2.4235170, 55.1934470],
          [-2.4234890, 55.1934700],
          [-2.4234650, 55.1934960],
          [-2.4234360, 55.1935220],
          [-2.4234040, 55.1935500],
          [-2.4233720, 55.1935800],
          [-2.4233350, 55.1936220],
          [-2.4232970, 55.1936600],
          [-2.4232510, 55.1936940],
          [-2.4232040, 55.1937360],
          [-2.4231640, 55.1937780],
          [-2.4231200, 55.1938240],
          [-2.4230690, 55.1938780],
          [-2.4230130, 55.1939300],
          [-2.4229470, 55.1939920],
          [-2.4228880, 55.1940490],
          [-2.4228290, 55.1941170],
          [-2.4227660, 55.1941820],
          [-2.4226950, 55.1942430],
          [-2.4226170, 55.1943050],
          [-2.4225360, 55.1943620],
          [-2.4224580, 55.1944190],
          [-2.4223830, 55.1944760],
          [-2.4223020, 55.1945290],
          [-2.4222170, 55.1945800],
          [-2.4221280, 55.1946210],
          [-2.4220510, 55.1946620],
          [-2.4219760, 55.1947020],
          [-2.4219110, 55.1947440],
          [-2.4218440, 55.1947770],
          [-2.4217770, 55.1948050],
          [-2.4217100, 55.1948350],
          [-2.4216440, 55.1948660],
          [-2.4215900, 55.1949000],
          [-2.4215310, 55.1949340],
          [-2.4214760, 55.1949760],
          [-2.4214230, 55.1950140],
          [-2.4213660, 55.1950450],
          [-2.4213160, 55.1950790],
          [-2.4212760, 55.1951130],
          [-2.4212330, 55.1951510],
          [-2.4211810, 55.1951940],
          [-2.4211230, 55.1952310],
          [-2.4210680, 55.1952740],
          [-2.4210170, 55.1953080],
          [-2.4209650, 55.1953450],
          [-2.4209160, 55.1953770],
          [-2.4208640, 55.1954070],
          [-2.4208090, 55.1954420],
          [-2.4207510, 55.1954720],
          [-2.4206900, 55.1955060],
          [-2.4206320, 55.1955370],
          [-2.4205700, 55.1955640],
          [-2.4205070, 55.1955950],
          [-2.4204450, 55.1956210],
          [-2.4203790, 55.1956480],
          [-2.4203150, 55.1956740],
          [-2.4202500, 55.1957040],
          [-2.4201840, 55.1957320],
          [-2.4201280, 55.1957580],
          [-2.4200680, 55.1957850],
          [-2.4200120, 55.1958080],
          [-2.4199630, 55.1958350],
          [-2.4199150, 55.1958610],
          [-2.4198650, 55.1958880],
          [-2.4198180, 55.1959110],
          [-2.4197690, 55.1959380],
          [-2.4197220, 55.1959610],
          [-2.4196790, 55.1959910],
          [-2.4196360, 55.1960250],
          [-2.4195940, 55.1960520],
          [-2.4195560, 55.1960790],
          [-2.4195190, 55.1961020],
          [-2.4194820, 55.1961280],
          [-2.4194490, 55.1961550],
          [-2.4194160, 55.1961820],
          [-2.4193790, 55.1962090],
          [-2.4193440, 55.1962350],
          [-2.4193110, 55.1962620],
          [-2.4192720, 55.1962890],
          [-2.4192300, 55.1963160],
          [-2.4191870, 55.1963410],
          [-2.4191380, 55.1963690],
          [-2.4190840, 55.1963990],
          [-2.4190250, 55.1964220],
          [-2.4189660, 55.1964490],
          [-2.4189070, 55.1964680],
          [-2.4188500, 55.1964940],
          [-2.4187950, 55.1965100],
          [-2.4187400, 55.1965330],
          [-2.4186910, 55.1965520],
          [-2.4186400, 55.1965670],
          [-2.4185920, 55.1965830],
          [-2.4185460, 55.1966010],
          [-2.4185000, 55.1966130],
          [-2.4184590, 55.1966280],
          [-2.4184190, 55.1966400],
          [-2.4183750, 55.1966550],
          [-2.4183380, 55.1966740],
          [-2.4182960, 55.1966890],
          [-2.4182550, 55.1967050],
          [-2.4182150, 55.1967160],
          [-2.4181730, 55.1967280],
          [-2.4181340, 55.1967390],
          [-2.4180900, 55.1967540],
          [-2.4180510, 55.1967690],
          [-2.4180140, 55.1967850],
          [-2.4179780, 55.1968000],
          [-2.4179420, 55.1968120],
          [-2.4179070, 55.1968230],
          [-2.4178760, 55.1968420],
          [-2.4178450, 55.1968570],
          [-2.4178140, 55.1968690],
          [-2.4177770, 55.1968840],
          [-2.4177390, 55.1968990],
          [-2.4177000, 55.1969110],
          [-2.4176610, 55.1969220],
          [-2.4176270, 55.1969370],
          [-2.4175960, 55.1969490],
          [-2.4175600, 55.1969600],
          [-2.4175300, 55.1969750],
          [-2.4174970, 55.1969940],
          [-2.4174650, 55.1970100],
          [-2.4174290, 55.1970250],
          [-2.4173970, 55.1970400],
          [-2.4173600, 55.1970560],
          [-2.4173270, 55.1970750],
          [-2.4172940, 55.1970900],
          [-2.4172640, 55.1971050],
          [-2.4172290, 55.1971200],
          [-2.4171970, 55.1971360],
          [-2.4171630, 55.1971540],
          [-2.4171320, 55.1971700],
          [-2.4171000, 55.1971850],
          [-2.4170740, 55.1972010],
          [-2.4170420, 55.1972200],
          [-2.4170090, 55.1972350],
          [-2.4169820, 55.1972540],
          [-2.4169510, 55.1972690],
          [-2.4169220, 55.1972850],
          [-2.4168890, 55.1973000],
          [-2.4168510, 55.1973150],
          [-2.4168150, 55.1973300],
          [-2.4167840, 55.1973490],
          [-2.4167510, 55.1973640],
          [-2.4167180, 55.1973880],
          [-2.4166890, 55.1974070],
          [-2.4166570, 55.1974260],
          [-2.4166250, 55.1974490],
          [-2.4165920, 55.1974710],
          [-2.4165600, 55.1974910],
          [-2.4165270, 55.1975100],
          [-2.4164990, 55.1975320],
          [-2.4164720, 55.1975480],
          [-2.4164470, 55.1975670],
          [-2.4164200, 55.1975900],
          [-2.4163900, 55.1976160],
          [-2.4163600, 55.1976390],
          [-2.4163320, 55.1976620],
          [-2.4163020, 55.1976850],
          [-2.4162700, 55.1977080],
          [-2.4162410, 55.1977300],
          [-2.4162120, 55.1977540],
          [-2.4161800, 55.1977770],
          [-2.4161490, 55.1978030],
          [-2.4161140, 55.1978220],
          [-2.4160860, 55.1978410],
          [-2.4160550, 55.1978640],
          [-2.4160260, 55.1978910],
          [-2.4159940, 55.1979140],
          [-2.4159590, 55.1979370],
          [-2.4159240, 55.1979600],
          [-2.4158930, 55.1979820],
          [-2.4158570, 55.1980060],
          [-2.4158290, 55.1980320],
          [-2.4158000, 55.1980540],
          [-2.4157670, 55.1980860],
          [-2.4157400, 55.1981050],
          [-2.4157040, 55.1981310],
          [-2.4156670, 55.1981540],
          [-2.4156300, 55.1981730],
          [-2.4155920, 55.1981960],
          [-2.4155670, 55.1982150],
          [-2.4155320, 55.1982340],
          [-2.4154970, 55.1982530],
          [-2.4154610, 55.1982720],
          [-2.4154270, 55.1982920],
          [-2.4153920, 55.1983140],
          [-2.4153540, 55.1983300],
          [-2.4153190, 55.1983530],
          [-2.4152840, 55.1983710],
          [-2.4152520, 55.1983910],
          [-2.4152140, 55.1984100],
          [-2.4151760, 55.1984320],
          [-2.4151390, 55.1984550],
          [-2.4151020, 55.1984780],
          [-2.4150690, 55.1985010],
          [-2.4150300, 55.1985200],
          [-2.4149960, 55.1985390],
          [-2.4149590, 55.1985620],
          [-2.4149180, 55.1985810],
          [-2.4148810, 55.1986040],
          [-2.4148390, 55.1986240],
          [-2.4147970, 55.1986460],
          [-2.4147560, 55.1986690],
          [-2.4147290, 55.1986880],
          [-2.4146970, 55.1987150],
          [-2.4146650, 55.1987380],
          [-2.4146310, 55.1987640],
          [-2.4145990, 55.1987870],
          [-2.4145650, 55.1988100],
          [-2.4145320, 55.1988330],
          [-2.4145000, 55.1988600],
          [-2.4144690, 55.1988830],
          [-2.4144420, 55.1989060],
          [-2.4144120, 55.1989320],
          [-2.4143870, 55.1989590],
          [-2.4143610, 55.1989820],
          [-2.4143310, 55.1990110],
          [-2.4142940, 55.1990470],
          [-2.4142540, 55.1990810],
          [-2.4142190, 55.1991190],
          [-2.4141810, 55.1991530],
          [-2.4141440, 55.1991920],
          [-2.4141060, 55.1992370],
          [-2.4140670, 55.1992790],
          [-2.4140240, 55.1993250],
          [-2.4139790, 55.1993670],
          [-2.4139370, 55.1994040],
          [-2.4138950, 55.1994440],
          [-2.4138520, 55.1994780],
          [-2.4138110, 55.1995030],
          [-2.4137750, 55.1995280],
          [-2.4137370, 55.1995500],
          [-2.4136960, 55.1995730],
          [-2.4136580, 55.1995920],
          [-2.4136220, 55.1996110],
          [-2.4135900, 55.1996270],
          [-2.4135500, 55.1996420],
          [-2.4135120, 55.1996570],
          [-2.4134760, 55.1996690],
          [-2.4134380, 55.1996800],
          [-2.4133940, 55.1997030],
          [-2.4133460, 55.1997220],
          [-2.4132970, 55.1997410],
          [-2.4132490, 55.1997570],
          [-2.4132070, 55.1997680],
          [-2.4131680, 55.1997750],
          [-2.4131250, 55.1997910],
          [-2.4130790, 55.1998060],
          [-2.4130270, 55.1998290],
          [-2.4129710, 55.1998590],
          [-2.4129080, 55.1998900],
          [-2.4128510, 55.1999200],
          [-2.4127880, 55.1999690],
          [-2.4127380, 55.2000140],
          [-2.4126780, 55.2000610],
          [-2.4126230, 55.2001210],
          [-2.4125760, 55.2001860],
          [-2.4125440, 55.2002480],
          [-2.4125070, 55.2003240],
          [-2.4124740, 55.2004000],
          [-2.4124410, 55.2004880],
          [-2.4124150, 55.2005750],
          [-2.4123820, 55.2006750],
          [-2.4123470, 55.2007920],
          [-2.4123220, 55.2008910],
          [-2.4123100, 55.2009910],
          [-2.4122990, 55.2010870],
          [-2.4122890, 55.2011820],
          [-2.4122800, 55.2012730],
          [-2.4122730, 55.2013620],
          [-2.4122670, 55.2014490],
          [-2.4122620, 55.2015320],
          [-2.4122570, 55.2016140],
          [-2.4122500, 55.2016970],
          [-2.4122450, 55.2017770],
          [-2.4122400, 55.2018580],
          [-2.4122350, 55.2019410],
          [-2.4122320, 55.2020260],
          [-2.4122190, 55.2021090],
          [-2.4121900, 55.2021850],
          [-2.4121440, 55.2022510],
          [-2.4120830, 55.2023070],
          [-2.4120080, 55.2023380],
          [-2.4118940, 55.2023240],
          [-2.4117910, 55.2023010],
          [-2.4116960, 55.2022750],
          [-2.4116010, 55.2022400],
          [-2.4115040, 55.2021990],
          [-2.4114020, 55.2021610],
          [-2.4113010, 55.2021260],
          [-2.4112050, 55.2020880],
          [-2.4111090, 55.2020460],
          [-2.4110060, 55.2020080],
          [-2.4109040, 55.2019740],
          [-2.4107960, 55.2019350],
          [-2.4106850, 55.2019010],
          [-2.4105780, 55.2018590],
          [-2.4104630, 55.2018170],
          [-2.4103410, 55.2017790],
          [-2.4102040, 55.2017440],
          [-2.4100730, 55.2017060],
          [-2.4099330, 55.2016690],
          [-2.4097880, 55.2016340],
          [-2.4096420, 55.2016000],
          [-2.4095070, 55.2015630],
          [-2.4093500, 55.2015190],
          [-2.4092070, 55.2014780],
          [-2.4090520, 55.2014360],
          [-2.4089100, 55.2013980],
          [-2.4087630, 55.2013630],
          [-2.4086210, 55.2013290],
          [-2.4084780, 55.2012980],
          [-2.4083380, 55.2012680],
          [-2.4081900, 55.2012370],
          [-2.4080450, 55.2012140],
          [-2.4079120, 55.2011870],
          [-2.4077910, 55.2011610],
          [-2.4076860, 55.2011350],
          [-2.4075760, 55.2011110],
          [-2.4074790, 55.2010920],
          [-2.4073810, 55.2010730],
          [-2.4072840, 55.2010500],
          [-2.4071950, 55.2010200],
          [-2.4071060, 55.2009850],
          [-2.4070170, 55.2009510],
          [-2.4069260, 55.2009130],
          [-2.4068330, 55.2008790],
          [-2.4067460, 55.2008480],
          [-2.4066590, 55.2008140],
          [-2.4065710, 55.2007840],
          [-2.4064840, 55.2007530],
          [-2.4064040, 55.2007220],
          [-2.4063280, 55.2006920],
          [-2.4062670, 55.2006730],
          [-2.4062250, 55.2006570],
          [-2.4062180, 55.2006550],
          [-2.4062150, 55.2006540],
          [-2.4062130, 55.2006520],
          [-2.4062100, 55.2006500],
          [-2.4062080, 55.2006490],
          [-2.4062050, 55.2006470],
          [-2.4062030, 55.2006450],
          [-2.4062000, 55.2006440],
          [-2.4061980, 55.2006420],
          [-2.4061950, 55.2006410],
          [-2.4061930, 55.2006390],
          [-2.4061900, 55.2006370],
          [-2.4061880, 55.2006360],
          [-2.4061850, 55.2006340],
          [-2.4061830, 55.2006320],
          [-2.4061800, 55.2006310],
          [-2.4061780, 55.2006290],
          [-2.4061750, 55.2006270],
          [-2.4061730, 55.2006260],
          [-2.4061710, 55.2006240],
          [-2.4061680, 55.2006230],
          [-2.4061660, 55.2006210],
          [-2.4061630, 55.2006190],
          [-2.4061610, 55.2006180],
          [-2.4061580, 55.2006160],
          [-2.4061560, 55.2006140],
          [-2.4061530, 55.2006130],
          [-2.4061510, 55.2006110],
          [-2.4061480, 55.2006090],
          [-2.4061460, 55.2006080],
          [-2.4061160, 55.2005810],
          [-2.4060740, 55.2005470],
          [-2.4060230, 55.2005050],
          [-2.4059560, 55.2004600],
          [-2.4058780, 55.2004170],
          [-2.4057790, 55.2003760],
          [-2.4056780, 55.2003330],
          [-2.4055760, 55.2002880],
          [-2.4054620, 55.2002460],
          [-2.4053570, 55.2002050],
          [-2.4052410, 55.2001510],
          [-2.4051280, 55.2000930],
          [-2.4050120, 55.2000390],
          [-2.4049050, 55.1999910],
          [-2.4047830, 55.1999400],
          [-2.4046680, 55.1998880],
          [-2.4045480, 55.1998370],
          [-2.4044280, 55.1997840],
          [-2.4042970, 55.1997300],
          [-2.4041770, 55.1996810],
          [-2.4040470, 55.1996350],
          [-2.4038920, 55.1996160],
          [-2.4037350, 55.1996120],
          [-2.4035800, 55.1996150],
          [-2.4034090, 55.1996310],
          [-2.4032350, 55.1996530],
          [-2.4030480, 55.1996760],
          [-2.4028680, 55.1997060],
          [-2.4026700, 55.1997300],
          [-2.4024720, 55.1997520],
          [-2.4022690, 55.1997720],
          [-2.4020650, 55.1997980],
          [-2.4018500, 55.1998290],
          [-2.4016370, 55.1998550],
          [-2.4014170, 55.1998780],
          [-2.4012140, 55.1998980],
          [-2.4010290, 55.1998980],
          [-2.4008400, 55.1998830],
          [-2.4006670, 55.1998450],
          [-2.4005080, 55.1997920],
          [-2.4003570, 55.1997340],
          [-2.4002180, 55.1996700],
          [-2.4000680, 55.1996080],
          [-2.3999210, 55.1995470],
          [-2.3997770, 55.1994940],
          [-2.3996300, 55.1994370],
          [-2.3994820, 55.1993800],
          [-2.3993360, 55.1993230],
          [-2.3991980, 55.1992620],
          [-2.3990620, 55.1992040],
          [-2.3989210, 55.1991510],
          [-2.3987750, 55.1990940],
          [-2.3986350, 55.1990360],
          [-2.3984900, 55.1989790],
          [-2.3983460, 55.1989250],
          [-2.3982070, 55.1988690],
          [-2.3980680, 55.1988110],
          [-2.3979300, 55.1987540],
          [-2.3977920, 55.1987010],
          [-2.3976580, 55.1986440],
          [-2.3975180, 55.1985870],
          [-2.3973750, 55.1985330],
          [-2.3972360, 55.1984800],
          [-2.3970950, 55.1984220],
          [-2.3969570, 55.1983690],
          [-2.3968220, 55.1983120],
          [-2.3966850, 55.1982510],
          [-2.3965450, 55.1981930],
          [-2.3964060, 55.1981400],
          [-2.3962690, 55.1980840],
          [-2.3961380, 55.1980300],
          [-2.3960100, 55.1979780],
          [-2.3958720, 55.1979230],
          [-2.3957410, 55.1978690],
          [-2.3956150, 55.1978210],
          [-2.3954840, 55.1977660],
          [-2.3953560, 55.1977160],
          [-2.3952300, 55.1976630],
          [-2.3951110, 55.1976100],
          [-2.3949900, 55.1975560],
          [-2.3948710, 55.1975030],
          [-2.3947470, 55.1974530],
          [-2.3946240, 55.1974080],
          [-2.3945000, 55.1973590],
          [-2.3943750, 55.1973130],
          [-2.3942500, 55.1972630],
          [-2.3941280, 55.1972170],
          [-2.3940160, 55.1971710],
          [-2.3939000, 55.1971250],
          [-2.3937870, 55.1970790],
          [-2.3936800, 55.1970380],
          [-2.3935700, 55.1969950],
          [-2.3934570, 55.1969610],
          [-2.3933300, 55.1969380],
          [-2.3931930, 55.1969380],
          [-2.3930520, 55.1969490],
          [-2.3929030, 55.1969720],
          [-2.3927570, 55.1969940],
          [-2.3926120, 55.1970290],
          [-2.3924650, 55.1970560],
          [-2.3923150, 55.1970900],
          [-2.3921640, 55.1971200],
          [-2.3920080, 55.1971550],
          [-2.3918520, 55.1971850],
          [-2.3916950, 55.1972190],
          [-2.3915350, 55.1972540],
          [-2.3913700, 55.1972920],
          [-2.3912050, 55.1973300],
          [-2.3910460, 55.1973680],
          [-2.3908900, 55.1974060],
          [-2.3907440, 55.1974440],
          [-2.3905960, 55.1974790],
          [-2.3904580, 55.1975090],
          [-2.3903270, 55.1975430],
          [-2.3901990, 55.1975710],
          [-2.3900830, 55.1975970],
          [-2.3899730, 55.1976200],
          [-2.3898680, 55.1976430],
          [-2.3897600, 55.1976660],
          [-2.3896540, 55.1976850],
          [-2.3895470, 55.1977000],
          [-2.3894350, 55.1977080],
          [-2.3893190, 55.1977080],
          [-2.3891800, 55.1977080],
          [-2.3890530, 55.1976970],
          [-2.3889170, 55.1977040],
          [-2.3887770, 55.1977080],
          [-2.3886580, 55.1976970],
          [-2.3885440, 55.1976860],
          [-2.3884330, 55.1976700],
          [-2.3883250, 55.1976470],
          [-2.3882200, 55.1976280],
          [-2.3881220, 55.1976130],
          [-2.3880160, 55.1975940],
          [-2.3879190, 55.1975750],
          [-2.3878310, 55.1975600],
          [-2.3877520, 55.1975410],
          [-2.3876560, 55.1975330],
          [-2.3875810, 55.1975140],
          [-2.3875090, 55.1974880],
          [-2.3874260, 55.1974830],
          [-2.3873530, 55.1974600],
          [-2.3872840, 55.1974380],
          [-2.3872140, 55.1974180],
          [-2.3871320, 55.1974070],
          [-2.3870630, 55.1973810],
          [-2.3870010, 55.1973500],
          [-2.3869310, 55.1973350],
          [-2.3868700, 55.1973120],
          [-2.3868120, 55.1972770],
          [-2.3867510, 55.1972470],
          [-2.3866860, 55.1972170],
          [-2.3866180, 55.1971790],
          [-2.3865520, 55.1971480],
          [-2.3864870, 55.1971140],
          [-2.3864160, 55.1970720],
          [-2.3863450, 55.1970370],
          [-2.3862660, 55.1970030],
          [-2.3861980, 55.1969650],
          [-2.3861250, 55.1969270],
          [-2.3860460, 55.1968890],
          [-2.3859730, 55.1968580],
          [-2.3859040, 55.1968200],
          [-2.3858360, 55.1967860],
          [-2.3857720, 55.1967510],
          [-2.3856960, 55.1967200],
          [-2.3856190, 55.1966820],
          [-2.3855470, 55.1966450],
          [-2.3854740, 55.1966060],
          [-2.3854020, 55.1965720],
          [-2.3853280, 55.1965270],
          [-2.3852560, 55.1964800],
          [-2.3851810, 55.1964340],
          [-2.3851040, 55.1963930],
          [-2.3850220, 55.1963510],
          [-2.3849380, 55.1963090],
          [-2.3848550, 55.1962640],
          [-2.3847660, 55.1962170],
          [-2.3846780, 55.1961750],
          [-2.3845900, 55.1961300],
          [-2.3845010, 55.1960840],
          [-2.3844130, 55.1960380],
          [-2.3843280, 55.1959920],
          [-2.3842440, 55.1959470],
          [-2.3841570, 55.1959000],
          [-2.3840770, 55.1958520],
          [-2.3839940, 55.1958050],
          [-2.3839100, 55.1957560],
          [-2.3838220, 55.1957100],
          [-2.3837290, 55.1956640],
          [-2.3836360, 55.1956220],
          [-2.3835380, 55.1955760],
          [-2.3834410, 55.1955350],
          [-2.3833390, 55.1954920],
          [-2.3832390, 55.1954500],
          [-2.3831370, 55.1954080],
          [-2.3830320, 55.1953670],
          [-2.3829230, 55.1953280],
          [-2.3828110, 55.1952910],
          [-2.3826950, 55.1952520],
          [-2.3825770, 55.1952140],
          [-2.3824590, 55.1951770],
          [-2.3823310, 55.1951420],
          [-2.3822060, 55.1951030],
          [-2.3820800, 55.1950690],
          [-2.3819560, 55.1950310],
          [-2.3818340, 55.1950000],
          [-2.3817080, 55.1949700],
          [-2.3815870, 55.1949430],
          [-2.3814610, 55.1949130],
          [-2.3813300, 55.1948860],
          [-2.3811990, 55.1948590],
          [-2.3810650, 55.1948360],
          [-2.3809360, 55.1948130],
          [-2.3808040, 55.1947980],
          [-2.3806720, 55.1947900],
          [-2.3805400, 55.1947970],
          [-2.3804100, 55.1948200],
          [-2.3802750, 55.1948500],
          [-2.3801380, 55.1948810],
          [-2.3800000, 55.1949080],
          [-2.3798490, 55.1949380],
          [-2.3796750, 55.1949720],
          [-2.3795040, 55.1950030],
          [-2.3793440, 55.1950260],
          [-2.3791770, 55.1950530],
          [-2.3790250, 55.1950810],
          [-2.3788530, 55.1951060],
          [-2.3786930, 55.1951360],
          [-2.3785170, 55.1951750],
          [-2.3783530, 55.1952090],
          [-2.3781900, 55.1952470],
          [-2.3780230, 55.1952820],
          [-2.3778640, 55.1953160],
          [-2.3777050, 55.1953580],
          [-2.3775450, 55.1954000],
          [-2.3773790, 55.1954490],
          [-2.3772080, 55.1955030],
          [-2.3770360, 55.1955560],
          [-2.3768720, 55.1956280],
          [-2.3767270, 55.1956940],
          [-2.3765700, 55.1957690],
          [-2.3764200, 55.1958380],
          [-2.3762740, 55.1959030],
          [-2.3761160, 55.1959640],
          [-2.3759390, 55.1960210],
          [-2.3757600, 55.1960630],
          [-2.3755840, 55.1960940],
          [-2.3754170, 55.1961160],
          [-2.3752420, 55.1961360],
          [-2.3750790, 55.1961630],
          [-2.3749130, 55.1961970],
          [-2.3747580, 55.1962310],
          [-2.3746020, 55.1962690],
          [-2.3744440, 55.1963000],
          [-2.3742830, 55.1963270],
          [-2.3741220, 55.1963490],
          [-2.3739590, 55.1963650],
          [-2.3738020, 55.1963840],
          [-2.3736440, 55.1964030],
          [-2.3734930, 55.1964150],
          [-2.3733370, 55.1964260],
          [-2.3731830, 55.1964300],
          [-2.3730270, 55.1964340],
          [-2.3728750, 55.1964300],
          [-2.3727170, 55.1964300],
          [-2.3725580, 55.1964300],
          [-2.3723890, 55.1964300],
          [-2.3722260, 55.1964300],
          [-2.3720520, 55.1964300],
          [-2.3718690, 55.1964300],
          [-2.3716880, 55.1964190],
          [-2.3715000, 55.1964080],
          [-2.3713120, 55.1963960],
          [-2.3711190, 55.1963810],
          [-2.3709320, 55.1963620],
          [-2.3707410, 55.1963470],
          [-2.3705490, 55.1963310],
          [-2.3703580, 55.1963200],
          [-2.3701560, 55.1963080],
          [-2.3699590, 55.1962970],
          [-2.3697690, 55.1962970],
          [-2.3695850, 55.1962970],
          [-2.3693960, 55.1962930],
          [-2.3692120, 55.1962890],
          [-2.3690310, 55.1962700],
          [-2.3688530, 55.1962400],
          [-2.3686790, 55.1962060],
          [-2.3685090, 55.1961720],
          [-2.3683430, 55.1961420],
          [-2.3681740, 55.1961140],
          [-2.3680160, 55.1960950],
          [-2.3678520, 55.1960760],
          [-2.3676800, 55.1960610],
          [-2.3674990, 55.1960450],
          [-2.3673220, 55.1960190],
          [-2.3671320, 55.1959960],
          [-2.3669460, 55.1959770],
          [-2.3667610, 55.1959460],
          [-2.3665880, 55.1959050],
          [-2.3664110, 55.1958560],
          [-2.3662310, 55.1957980],
          [-2.3660650, 55.1957380],
          [-2.3658970, 55.1956760],
          [-2.3657270, 55.1956110],
          [-2.3655430, 55.1955570],
          [-2.3653440, 55.1955190],
          [-2.3651490, 55.1954960],
          [-2.3649430, 55.1954770],
          [-2.3647420, 55.1954580],
          [-2.3645410, 55.1954310],
          [-2.3643400, 55.1954040],
          [-2.3641420, 55.1953740],
          [-2.3639550, 55.1953470],
          [-2.3637710, 55.1953210],
          [-2.3635860, 55.1952940],
          [-2.3634010, 55.1952750],
          [-2.3632140, 55.1952590],
          [-2.3630220, 55.1952370],
          [-2.3628360, 55.1952140],
          [-2.3626310, 55.1951950],
          [-2.3624290, 55.1951720],
          [-2.3622220, 55.1951490],
          [-2.3620170, 55.1951300],
          [-2.3618100, 55.1951030],
          [-2.3616040, 55.1950690],
          [-2.3614070, 55.1950310],
          [-2.3612000, 55.1949890],
          [-2.3610040, 55.1949580],
          [-2.3607970, 55.1949310],
          [-2.3605780, 55.1949050],
          [-2.3603670, 55.1948740],
          [-2.3601620, 55.1948360],
          [-2.3599470, 55.1947940],
          [-2.3597370, 55.1947480],
          [-2.3595300, 55.1946960],
          [-2.3593120, 55.1946380],
          [-2.3591000, 55.1945920],
          [-2.3588860, 55.1945430],
          [-2.3586820, 55.1944890],
          [-2.3585000, 55.1944100],
          [-2.3583290, 55.1943180],
          [-2.3581700, 55.1942190],
          [-2.3580190, 55.1941360],
          [-2.3578670, 55.1940580],
          [-2.3577370, 55.1939940],
          [-2.3576070, 55.1939480],
          [-2.3574740, 55.1939060],
          [-2.3573400, 55.1938630],
          [-2.3572040, 55.1938290],
          [-2.3570470, 55.1937950],
          [-2.3568780, 55.1937640],
          [-2.3566930, 55.1937340],
          [-2.3565000, 55.1937070],
          [-2.3563130, 55.1936910],
          [-2.3561280, 55.1936840],
          [-2.3559430, 55.1936800],
          [-2.3557560, 55.1936870],
          [-2.3555610, 55.1936950],
          [-2.3553560, 55.1936950],
          [-2.3551220, 55.1936910],
          [-2.3548930, 55.1936840],
          [-2.3546630, 55.1936690],
          [-2.3544370, 55.1936500],
          [-2.3542140, 55.1936190],
          [-2.3539970, 55.1935850],
          [-2.3537790, 55.1935620],
          [-2.3535710, 55.1935470],
          [-2.3533700, 55.1935500],
          [-2.3531820, 55.1935880],
          [-2.3529990, 55.1936320],
          [-2.3528150, 55.1936740],
          [-2.3526200, 55.1937170],
          [-2.3524250, 55.1937480],
          [-2.3522370, 55.1937750],
          [-2.3520350, 55.1938010],
          [-2.3518210, 55.1938400],
          [-2.3516190, 55.1938780],
          [-2.3514130, 55.1939190],
          [-2.3512190, 55.1939770],
          [-2.3510490, 55.1940330],
          [-2.3508890, 55.1940760],
          [-2.3507410, 55.1941090],
          [-2.3506080, 55.1941410],
          [-2.3504950, 55.1941720],
          [-2.3504040, 55.1942060],
          [-2.3503550, 55.1942440],
          [-2.3503580, 55.1942740],
          [-2.3503980, 55.1943050],
          [-2.3504530, 55.1943390],
          [-2.3505240, 55.1943660],
          [-2.3506070, 55.1943970],
          [-2.3507050, 55.1944270],
          [-2.3508180, 55.1944570],
          [-2.3509420, 55.1944880],
          [-2.3510720, 55.1945260],
          [-2.3512130, 55.1945530],
          [-2.3513530, 55.1945840],
          [-2.3514840, 55.1946170],
          [-2.3516100, 55.1946410],
          [-2.3517320, 55.1946670],
          [-2.3518520, 55.1946980],
          [-2.3519740, 55.1947320],
          [-2.3520990, 55.1947630],
          [-2.3522100, 55.1947930],
          [-2.3523220, 55.1948240],
          [-2.3524310, 55.1948580],
          [-2.3525390, 55.1948890],
          [-2.3526410, 55.1949350],
          [-2.3527530, 55.1949720],
          [-2.3528690, 55.1950100],
          [-2.3529870, 55.1950410],
          [-2.3530920, 55.1950790],
          [-2.3531920, 55.1951060],
          [-2.3532850, 55.1951330],
          [-2.3533640, 55.1951630],
          [-2.3534500, 55.1951860],
          [-2.3535360, 55.1952170],
          [-2.3536170, 55.1952470],
          [-2.3536920, 55.1952850],
          [-2.3537620, 55.1953230],
          [-2.3538280, 55.1953730],
          [-2.3538800, 55.1954140],
          [-2.3539280, 55.1954530],
          [-2.3539630, 55.1954980],
          [-2.3539920, 55.1955460],
          [-2.3540260, 55.1955980],
          [-2.3540600, 55.1956520],
          [-2.3540870, 55.1956990],
          [-2.3541160, 55.1957540],
          [-2.3541510, 55.1958100],
          [-2.3541870, 55.1958740],
          [-2.3542310, 55.1959410],
          [-2.3542810, 55.1960100],
          [-2.3543390, 55.1960740],
          [-2.3543980, 55.1961420],
          [-2.3544540, 55.1962110],
          [-2.3545130, 55.1962840],
          [-2.3545750, 55.1963600],
          [-2.3546460, 55.1964370],
          [-2.3547200, 55.1965090],
          [-2.3548020, 55.1965820],
          [-2.3548840, 55.1966510],
          [-2.3549670, 55.1967170],
          [-2.3550540, 55.1967830],
          [-2.3551510, 55.1968520],
          [-2.3552470, 55.1969170],
          [-2.3553390, 55.1969810],
          [-2.3554300, 55.1970470],
          [-2.3555170, 55.1971110],
          [-2.3556060, 55.1971690],
          [-2.3557040, 55.1972300],
          [-2.3558100, 55.1972900],
          [-2.3559130, 55.1973500],
          [-2.3560200, 55.1974020],
          [-2.3561360, 55.1974480],
          [-2.3562500, 55.1974930],
          [-2.3563720, 55.1975430],
          [-2.3564900, 55.1975890],
          [-2.3566130, 55.1976310],
          [-2.3567300, 55.1976670],
          [-2.3568570, 55.1977080],
          [-2.3569750, 55.1977410],
          [-2.3570860, 55.1977760],
          [-2.3571960, 55.1978100],
          [-2.3573100, 55.1978450],
          [-2.3574180, 55.1978760],
          [-2.3575260, 55.1979020],
          [-2.3576250, 55.1979250],
          [-2.3577280, 55.1979520],
          [-2.3578270, 55.1979780],
          [-2.3579240, 55.1980020],
          [-2.3580210, 55.1980280],
          [-2.3581250, 55.1980550],
          [-2.3582310, 55.1980850],
          [-2.3583390, 55.1981270],
          [-2.3584370, 55.1981730],
          [-2.3585330, 55.1982140],
          [-2.3586230, 55.1982570],
          [-2.3587070, 55.1982910],
          [-2.3587840, 55.1983280],
          [-2.3588570, 55.1983670],
          [-2.3589270, 55.1984050],
          [-2.3589930, 55.1984480],
          [-2.3590640, 55.1984930],
          [-2.3591440, 55.1985390],
          [-2.3592260, 55.1985890],
          [-2.3593120, 55.1986380],
          [-2.3594000, 55.1986870],
          [-2.3594870, 55.1987320],
          [-2.3595770, 55.1987790],
          [-2.3596640, 55.1988220],
          [-2.3597510, 55.1988680],
          [-2.3598390, 55.1989090],
          [-2.3599260, 55.1989540],
          [-2.3600130, 55.1989970],
          [-2.3600970, 55.1990420],
          [-2.3601820, 55.1990890],
          [-2.3602700, 55.1991340],
          [-2.3603540, 55.1991840],
          [-2.3604300, 55.1992320],
          [-2.3605070, 55.1992860],
          [-2.3605820, 55.1993440],
          [-2.3606560, 55.1994050],
          [-2.3607280, 55.1994740],
          [-2.3607950, 55.1995420],
          [-2.3608620, 55.1996140],
          [-2.3609320, 55.1996900],
          [-2.3610000, 55.1997620],
          [-2.3610670, 55.1998300],
          [-2.3611390, 55.1998970],
          [-2.3612160, 55.1999580],
          [-2.3612930, 55.2000150],
          [-2.3613730, 55.2000730],
          [-2.3614480, 55.2001330],
          [-2.3615280, 55.2001900],
          [-2.3616170, 55.2002410],
          [-2.3617060, 55.2002830],
          [-2.3617940, 55.2003240],
          [-2.3618810, 55.2003630],
          [-2.3619600, 55.2004000],
          [-2.3620410, 55.2004310],
          [-2.3621250, 55.2004620],
          [-2.3622110, 55.2004930],
          [-2.3622880, 55.2005150],
          [-2.3623630, 55.2005420],
          [-2.3624320, 55.2005690],
          [-2.3624990, 55.2005960],
          [-2.3625670, 55.2006220],
          [-2.3626290, 55.2006480],
          [-2.3626970, 55.2006720],
          [-2.3627590, 55.2006950],
          [-2.3628190, 55.2007220],
          [-2.3628790, 55.2007400],
          [-2.3629360, 55.2007670],
          [-2.3629880, 55.2007930],
          [-2.3630350, 55.2008240],
          [-2.3630860, 55.2008510],
          [-2.3631420, 55.2008770],
          [-2.3632030, 55.2009010],
          [-2.3632610, 55.2009240],
          [-2.3633220, 55.2009460],
          [-2.3633820, 55.2009690],
          [-2.3634390, 55.2009920],
          [-2.3634910, 55.2010110],
          [-2.3635440, 55.2010340],
          [-2.3636000, 55.2010570],
          [-2.3636540, 55.2010800],
          [-2.3637070, 55.2011030],
          [-2.3637640, 55.2011290],
          [-2.3638240, 55.2011520],
          [-2.3638920, 55.2011720],
          [-2.3639650, 55.2011910],
          [-2.3640260, 55.2012060],
          [-2.3640890, 55.2012210],
          [-2.3641560, 55.2012330],
          [-2.3642220, 55.2012440],
          [-2.3642850, 55.2012520],
          [-2.3643480, 55.2012600],
          [-2.3644080, 55.2012630],
          [-2.3644650, 55.2012630],
          [-2.3645220, 55.2012630],
          [-2.3645790, 55.2012670],
          [-2.3646330, 55.2012710],
          [-2.3646880, 55.2012750],
          [-2.3647400, 55.2012780],
          [-2.3647970, 55.2012790],
          [-2.3648510, 55.2012820],
          [-2.3649050, 55.2012860],
          [-2.3649590, 55.2012860],
          [-2.3650090, 55.2012900],
          [-2.3650630, 55.2012900],
          [-2.3651140, 55.2012900],
          [-2.3651640, 55.2012900],
          [-2.3652160, 55.2012940],
          [-2.3652640, 55.2012940],
          [-2.3653140, 55.2012980],
          [-2.3653640, 55.2012980],
          [-2.3654150, 55.2012980],
          [-2.3654620, 55.2013010],
          [-2.3655130, 55.2013020],
          [-2.3655650, 55.2013050],
          [-2.3656180, 55.2013050],
          [-2.3656710, 55.2013090],
          [-2.3657240, 55.2013130],
          [-2.3657760, 55.2013170],
          [-2.3658280, 55.2013170],
          [-2.3658780, 55.2013170],
          [-2.3659290, 55.2013210],
          [-2.3659810, 55.2013240],
          [-2.3660320, 55.2013280],
          [-2.3660840, 55.2013280],
          [-2.3661340, 55.2013320],
          [-2.3661850, 55.2013320],
          [-2.3662380, 55.2013360],
          [-2.3662910, 55.2013400],
          [-2.3663420, 55.2013400],
          [-2.3663960, 55.2013400],
          [-2.3664490, 55.2013400],
          [-2.3665030, 55.2013440],
          [-2.3665530, 55.2013440],
          [-2.3666090, 55.2013400],
          [-2.3666600, 55.2013400],
          [-2.3667100, 55.2013430],
          [-2.3667610, 55.2013550],
          [-2.3668120, 55.2013620],
          [-2.3668610, 55.2013700],
          [-2.3669130, 55.2013820],
          [-2.3669610, 55.2013930],
          [-2.3670080, 55.2014080],
          [-2.3670530, 55.2014230],
          [-2.3670990, 55.2014390],
          [-2.3671430, 55.2014580],
          [-2.3671860, 55.2014800],
          [-2.3672250, 55.2015020],
          [-2.3672690, 55.2015260],
          [-2.3673140, 55.2015530],
          [-2.3673600, 55.2015760],
          [-2.3674100, 55.2016030],
          [-2.3674600, 55.2016210],
          [-2.3675090, 55.2016440],
          [-2.3675620, 55.2016640],
          [-2.3676100, 55.2016860],
          [-2.3676550, 55.2017120],
          [-2.3676980, 55.2017360],
          [-2.3677380, 55.2017670],
          [-2.3677790, 55.2017930],
          [-2.3678190, 55.2018200],
          [-2.3678620, 55.2018430],
          [-2.3679080, 55.2018700],
          [-2.3679530, 55.2018960],
          [-2.3679970, 55.2019190],
          [-2.3680430, 55.2019460],
          [-2.3680880, 55.2019720],
          [-2.3681330, 55.2019950],
          [-2.3681810, 55.2020190],
          [-2.3682300, 55.2020410],
          [-2.3682810, 55.2020640],
          [-2.3683320, 55.2020870],
          [-2.3683860, 55.2021140],
          [-2.3684360, 55.2021400],
          [-2.3684800, 55.2021700],
          [-2.3685240, 55.2021980],
          [-2.3685700, 55.2022280],
          [-2.3686190, 55.2022550],
          [-2.3686690, 55.2022810],
          [-2.3687220, 55.2023040],
          [-2.3687790, 55.2023310],
          [-2.3688310, 55.2023580],
          [-2.3688830, 55.2023880],
          [-2.3689390, 55.2024180],
          [-2.3689940, 55.2024450],
          [-2.3690510, 55.2024760],
          [-2.3691070, 55.2025100],
          [-2.3691640, 55.2025450],
          [-2.3692190, 55.2025710],
          [-2.3692750, 55.2026060],
          [-2.3693290, 55.2026400],
          [-2.3693870, 55.2026740],
          [-2.3694460, 55.2027050],
          [-2.3695030, 55.2027430],
          [-2.3695600, 55.2027730],
          [-2.3696190, 55.2028080],
          [-2.3696810, 55.2028420],
          [-2.3697440, 55.2028730],
          [-2.3698090, 55.2029070],
          [-2.3698730, 55.2029420],
          [-2.3699370, 55.2029750],
          [-2.3700020, 55.2030100],
          [-2.3700630, 55.2030450],
          [-2.3701220, 55.2030820],
          [-2.3701810, 55.2031210],
          [-2.3702420, 55.2031590],
          [-2.3703060, 55.2031960],
          [-2.3703720, 55.2032300],
          [-2.3704360, 55.2032700],
          [-2.3704950, 55.2033110],
          [-2.3705580, 55.2033500],
          [-2.3706250, 55.2033840],
          [-2.3706900, 55.2034180],
          [-2.3707540, 55.2034560],
          [-2.3708090, 55.2034940],
          [-2.3708710, 55.2035330],
          [-2.3709410, 55.2035670],
          [-2.3710150, 55.2036020],
          [-2.3710860, 55.2036430],
          [-2.3711540, 55.2036810],
          [-2.3712270, 55.2037230],
          [-2.3712940, 55.2037690],
          [-2.3713490, 55.2038180],
          [-2.3713980, 55.2038720],
          [-2.3714410, 55.2039300],
          [-2.3714890, 55.2039940],
          [-2.3715440, 55.2040550],
          [-2.3715990, 55.2041190],
          [-2.3716600, 55.2041800],
          [-2.3717310, 55.2042460],
          [-2.3718090, 55.2043100],
          [-2.3718910, 55.2043720],
          [-2.3719710, 55.2044330],
          [-2.3720510, 55.2044850],
          [-2.3721470, 55.2045400],
          [-2.3722530, 55.2046010],
          [-2.3723520, 55.2046630],
          [-2.3724490, 55.2047340],
          [-2.3725310, 55.2048020],
          [-2.3726070, 55.2048740],
          [-2.3726680, 55.2049460],
          [-2.3727160, 55.2050160],
          [-2.3727590, 55.2050920],
          [-2.3728000, 55.2051690],
          [-2.3728370, 55.2052480],
          [-2.3728730, 55.2053250],
          [-2.3729080, 55.2053980],
          [-2.3729430, 55.2054700],
          [-2.3729730, 55.2055280],
          [-2.3730050, 55.2055810],
          [-2.3730360, 55.2056230],
          [-2.3730610, 55.2056610],
          [-2.3730750, 55.2056730],
          [-2.3730780, 55.2056810],
          [-2.3730800, 55.2056850],
          [-2.3730800, 55.2056850],
          [-2.3730800, 55.2056850],
          [-2.3730800, 55.2056850],
          [-2.3730800, 55.2056850],
          [-2.3730800, 55.2056850],
          [-2.3730800, 55.2056850],
          [-2.3730800, 55.2056850],
          [-2.3730800, 55.2056850],
          [-2.3730800, 55.2056850],
          [-2.3730800, 55.2056850],
          [-2.3730800, 55.2056850],
          [-2.3730800, 55.2056850],
          [-2.3730800, 55.2056850],
          [-2.3731050, 55.2057100],
          [-2.3731200, 55.2057260],
          [-2.3731410, 55.2057530],
          [-2.3731630, 55.2057760],
          [-2.3731800, 55.2057990],
          [-2.3731960, 55.2058220],
          [-2.3732180, 55.2058450],
          [-2.3732460, 55.2058630],
          [-2.3732710, 55.2058820],
          [-2.3732960, 55.2059090],
          [-2.3733230, 55.2059360],
          [-2.3733560, 55.2059630],
          [-2.3733860, 55.2059930],
          [-2.3734180, 55.2060240],
          [-2.3734460, 55.2060580],
          [-2.3734750, 55.2060920],
          [-2.3735060, 55.2061300],
          [-2.3735390, 55.2061680],
          [-2.3735550, 55.2062080],
          [-2.3735480, 55.2062530],
          [-2.3735120, 55.2062830],
          [-2.3734490, 55.2063020],
          [-2.3733700, 55.2062990],
          [-2.3732880, 55.2062800],
          [-2.3731980, 55.2062570],
          [-2.3731020, 55.2062380],
          [-2.3730000, 55.2062150],
          [-2.3728920, 55.2061920],
          [-2.3727830, 55.2061740],
          [-2.3726630, 55.2061510],
          [-2.3725400, 55.2061350],
          [-2.3724190, 55.2061160],
          [-2.3722950, 55.2060970],
          [-2.3721740, 55.2060740],
          [-2.3720500, 55.2060550],
          [-2.3719280, 55.2060360],
          [-2.3718080, 55.2060170],
          [-2.3716870, 55.2059940],
          [-2.3715710, 55.2059750],
          [-2.3714490, 55.2059520],
          [-2.3713290, 55.2059260],
          [-2.3712030, 55.2058950],
          [-2.3710780, 55.2058720],
          [-2.3709530, 55.2058490],
          [-2.3708390, 55.2058230],
          [-2.3707150, 55.2057990],
          [-2.3705910, 55.2057760],
          [-2.3704680, 55.2057500],
          [-2.3703430, 55.2057190],
          [-2.3702150, 55.2056930],
          [-2.3700850, 55.2056700],
          [-2.3699600, 55.2056470],
          [-2.3698210, 55.2056210],
          [-2.3696730, 55.2055930],
          [-2.3695340, 55.2055670],
          [-2.3693840, 55.2055360],
          [-2.3692300, 55.2054990],
          [-2.3690610, 55.2054680],
          [-2.3688800, 55.2054380],
          [-2.3687010, 55.2054070],
          [-2.3685200, 55.2053720],
          [-2.3683440, 55.2053340],
          [-2.3681750, 55.2052790],
          [-2.3680100, 55.2052200],
          [-2.3678620, 55.2051520],
          [-2.3676880, 55.2050990],
          [-2.3675210, 55.2050600],
          [-2.3673620, 55.2050440],
          [-2.3672200, 55.2050470],
          [-2.3670880, 55.2050510],
          [-2.3669850, 55.2050740],
          [-2.3668910, 55.2051080],
          [-2.3668160, 55.2051530],
          [-2.3667700, 55.2052100],
          [-2.3667490, 55.2052760],
          [-2.3667450, 55.2053390],
          [-2.3667500, 55.2054130],
          [-2.3667610, 55.2054890],
          [-2.3667760, 55.2055530],
          [-2.3668000, 55.2056190],
          [-2.3668290, 55.2056790],
          [-2.3668630, 55.2057410],
          [-2.3668890, 55.2058040],
          [-2.3669130, 55.2058620],
          [-2.3669340, 55.2059210],
          [-2.3669610, 55.2059780],
          [-2.3669860, 55.2060340],
          [-2.3670100, 55.2060920],
          [-2.3670320, 55.2061470],
          [-2.3670540, 55.2062060],
          [-2.3670750, 55.2062610],
          [-2.3670980, 55.2063160],
          [-2.3671190, 55.2063700],
          [-2.3671410, 55.2064280],
          [-2.3671630, 55.2064770],
          [-2.3671860, 55.2065300],
          [-2.3672060, 55.2065790],
          [-2.3672260, 55.2066340],
          [-2.3672430, 55.2066830],
          [-2.3672590, 55.2067350],
          [-2.3672760, 55.2067860],
          [-2.3672910, 55.2068350],
          [-2.3673060, 55.2068880],
          [-2.3673150, 55.2069420],
          [-2.3673170, 55.2069950],
          [-2.3673120, 55.2070430],
          [-2.3673010, 55.2070970],
          [-2.3672780, 55.2071490],
          [-2.3672550, 55.2072020],
          [-2.3672270, 55.2072530],
          [-2.3671920, 55.2073050],
          [-2.3671580, 55.2073580],
          [-2.3671190, 55.2074100],
          [-2.3670800, 55.2074640],
          [-2.3670410, 55.2075080],
          [-2.3669990, 55.2075580],
          [-2.3669640, 55.2076010],
          [-2.3669300, 55.2076480],
          [-2.3669020, 55.2076910],
          [-2.3668840, 55.2077320],
          [-2.3668710, 55.2077700],
          [-2.3668560, 55.2078080],
          [-2.3668390, 55.2078530],
          [-2.3668030, 55.2078950],
          [-2.3667480, 55.2079370],
          [-2.3666780, 55.2079760],
          [-2.3666030, 55.2080060],
          [-2.3665310, 55.2080370],
          [-2.3664590, 55.2080670],
          [-2.3663910, 55.2081020],
          [-2.3663330, 55.2081320],
          [-2.3662800, 55.2081630],
          [-2.3662300, 55.2081930],
          [-2.3661900, 55.2082200],
          [-2.3661550, 55.2082510],
          [-2.3661280, 55.2082770],
          [-2.3661060, 55.2083070],
          [-2.3660860, 55.2083350],
          [-2.3660690, 55.2083650],
          [-2.3660570, 55.2083950],
          [-2.3660420, 55.2084220],
          [-2.3660290, 55.2084500],
          [-2.3660250, 55.2084830],
          [-2.3660270, 55.2085130],
          [-2.3660270, 55.2085440],
          [-2.3660270, 55.2085740],
          [-2.3660280, 55.2086080],
          [-2.3660310, 55.2086390],
          [-2.3660350, 55.2086740],
          [-2.3660380, 55.2087080],
          [-2.3660450, 55.2087390],
          [-2.3660510, 55.2087730],
          [-2.3660630, 55.2088030],
          [-2.3660750, 55.2088340],
          [-2.3660860, 55.2088650],
          [-2.3660990, 55.2088950],
          [-2.3661110, 55.2089290],
          [-2.3661280, 55.2089600],
          [-2.3661440, 55.2089900],
          [-2.3661600, 55.2090200],
          [-2.3661810, 55.2090550],
          [-2.3662040, 55.2090860],
          [-2.3662240, 55.2091160],
          [-2.3662440, 55.2091470],
          [-2.3662620, 55.2091770],
          [-2.3662810, 55.2092080],
          [-2.3662990, 55.2092390],
          [-2.3663170, 55.2092690],
          [-2.3663350, 55.2092990],
          [-2.3663560, 55.2093300],
          [-2.3663750, 55.2093600],
          [-2.3663960, 55.2093910],
          [-2.3664200, 55.2094210],
          [-2.3664420, 55.2094480],
          [-2.3664630, 55.2094790],
          [-2.3664810, 55.2095060],
          [-2.3664940, 55.2095360],
          [-2.3665060, 55.2095670],
          [-2.3665110, 55.2095970],
          [-2.3665070, 55.2096230],
          [-2.3664920, 55.2096540],
          [-2.3664710, 55.2096810],
          [-2.3664480, 55.2097040],
          [-2.3664210, 55.2097270],
          [-2.3663940, 55.2097470],
          [-2.3663690, 55.2097700],
          [-2.3663430, 55.2097920],
          [-2.3663100, 55.2098070],
          [-2.3662720, 55.2098190],
          [-2.3662320, 55.2098310],
          [-2.3661940, 55.2098420],
          [-2.3661540, 55.2098500],
          [-2.3661160, 55.2098610],
          [-2.3660770, 55.2098730],
          [-2.3660390, 55.2098770],
          [-2.3660020, 55.2098880],
          [-2.3659620, 55.2098960],
          [-2.3659290, 55.2099070],
          [-2.3658920, 55.2099150],
          [-2.3658530, 55.2099220],
          [-2.3658170, 55.2099260],
          [-2.3657800, 55.2099300],
          [-2.3657460, 55.2099300],
          [-2.3657100, 55.2099300],
          [-2.3656760, 55.2099300],
          [-2.3656400, 55.2099340],
          [-2.3656070, 55.2099340],
          [-2.3655710, 55.2099380],
          [-2.3655320, 55.2099420],
          [-2.3654960, 55.2099450],
          [-2.3654590, 55.2099490],
          [-2.3654240, 55.2099530],
          [-2.3653870, 55.2099530],
          [-2.3653500, 55.2099530],
          [-2.3653110, 55.2099530],
          [-2.3652680, 55.2099530],
          [-2.3652270, 55.2099530],
          [-2.3651850, 55.2099570],
          [-2.3651420, 55.2099570],
          [-2.3650990, 55.2099610],
          [-2.3650590, 55.2099610],
          [-2.3650160, 55.2099610],
          [-2.3649760, 55.2099570],
          [-2.3649340, 55.2099570],
          [-2.3648920, 55.2099610],
          [-2.3648510, 55.2099650],
          [-2.3648120, 55.2099680],
          [-2.3647700, 55.2099760],
          [-2.3647270, 55.2099760],
          [-2.3646840, 55.2099760],
          [-2.3646360, 55.2099760],
          [-2.3645910, 55.2099760],
          [-2.3645440, 55.2099800],
          [-2.3645000, 55.2099800],
          [-2.3644510, 55.2099800],
          [-2.3644020, 55.2099800],
          [-2.3643520, 55.2099840],
          [-2.3643040, 55.2099840],
          [-2.3642570, 55.2099870],
          [-2.3642040, 55.2099910],
          [-2.3641560, 55.2099950],
          [-2.3641080, 55.2099990],
          [-2.3640600, 55.2100030],
          [-2.3640090, 55.2100070],
          [-2.3639620, 55.2100100],
          [-2.3639070, 55.2100140],
          [-2.3638590, 55.2100180],
          [-2.3638070, 55.2100290],
          [-2.3637590, 55.2100330],
          [-2.3637080, 55.2100410],
          [-2.3636590, 55.2100490],
          [-2.3636160, 55.2100590],
          [-2.3635690, 55.2100710],
          [-2.3635210, 55.2100790],
          [-2.3634750, 55.2100900],
          [-2.3634270, 55.2101020],
          [-2.3633850, 55.2101130],
          [-2.3633390, 55.2101290],
          [-2.3632990, 55.2101430],
          [-2.3632520, 55.2101630],
          [-2.3632140, 55.2101770],
          [-2.3631700, 55.2101970],
          [-2.3631260, 55.2102160],
          [-2.3630790, 55.2102390],
          [-2.3630320, 55.2102580],
          [-2.3629860, 55.2102810],
          [-2.3629390, 55.2103070],
          [-2.3628960, 55.2103310],
          [-2.3628530, 55.2103530],
          [-2.3628100, 55.2103800],
          [-2.3627730, 55.2104060],
          [-2.3627360, 55.2104330],
          [-2.3627020, 55.2104680],
          [-2.3626690, 55.2104980],
          [-2.3626340, 55.2105330],
          [-2.3626000, 55.2105660],
          [-2.3625670, 55.2106010],
          [-2.3625340, 55.2106390],
          [-2.3625040, 55.2106770],
          [-2.3624760, 55.2107150],
          [-2.3624480, 55.2107530],
          [-2.3624160, 55.2107950],
          [-2.3623860, 55.2108340],
          [-2.3623590, 55.2108750],
          [-2.3623330, 55.2109160],
          [-2.3623070, 55.2109560],
          [-2.3622820, 55.2109960],
          [-2.3622560, 55.2110400],
          [-2.3622340, 55.2110810],
          [-2.3622120, 55.2111230],
          [-2.3621890, 55.2111660],
          [-2.3621660, 55.2112070],
          [-2.3621420, 55.2112460],
          [-2.3621190, 55.2112910],
          [-2.3620950, 55.2113340],
          [-2.3620690, 55.2113790],
          [-2.3620420, 55.2114170],
          [-2.3620170, 55.2114630],
          [-2.3619940, 55.2115050],
          [-2.3619690, 55.2115470],
          [-2.3619440, 55.2115880],
          [-2.3619170, 55.2116310],
          [-2.3618920, 55.2116690],
          [-2.3618670, 55.2117110],
          [-2.3618430, 55.2117560],
          [-2.3618170, 55.2117990],
          [-2.3617890, 55.2118410],
          [-2.3617540, 55.2118830],
          [-2.3617190, 55.2119210],
          [-2.3616910, 55.2119630],
          [-2.3616650, 55.2120090],
          [-2.3616410, 55.2120540],
          [-2.3616120, 55.2121000],
          [-2.3615890, 55.2121420],
          [-2.3615650, 55.2121870],
          [-2.3615390, 55.2122340],
          [-2.3615120, 55.2122800],
          [-2.3614850, 55.2123250],
          [-2.3614600, 55.2123670],
          [-2.3614350, 55.2124090],
          [-2.3614070, 55.2124550],
          [-2.3613760, 55.2124970],
          [-2.3613460, 55.2125390],
          [-2.3613140, 55.2125810],
          [-2.3612800, 55.2126220],
          [-2.3612420, 55.2126610],
          [-2.3612040, 55.2126990],
          [-2.3611660, 55.2127410],
          [-2.3611280, 55.2127790],
          [-2.3610790, 55.2128210],
          [-2.3610310, 55.2128630],
          [-2.3609780, 55.2129050],
          [-2.3609180, 55.2129510],
          [-2.3608510, 55.2129930],
          [-2.3607830, 55.2130310],
          [-2.3607150, 55.2130690],
          [-2.3606460, 55.2131000],
          [-2.3605870, 55.2131260],
          [-2.3605250, 55.2131490],
          [-2.3604610, 55.2131730],
          [-2.3604000, 55.2131990],
          [-2.3603390, 55.2132250],
          [-2.3602710, 55.2132490],
          [-2.3602030, 55.2132680],
          [-2.3601330, 55.2132950],
          [-2.3600610, 55.2133210],
          [-2.3599860, 55.2133480],
          [-2.3599140, 55.2133710],
          [-2.3598400, 55.2133970],
          [-2.3597680, 55.2134200],
          [-2.3596940, 55.2134400],
          [-2.3596240, 55.2134630],
          [-2.3595590, 55.2134850],
          [-2.3594910, 55.2135040],
          [-2.3594260, 55.2135270],
          [-2.3593580, 55.2135460],
          [-2.3592910, 55.2135690],
          [-2.3592260, 55.2135920],
          [-2.3591580, 55.2136150],
          [-2.3590890, 55.2136420],
          [-2.3590180, 55.2136650],
          [-2.3589450, 55.2136910],
          [-2.3588710, 55.2137140],
          [-2.3588010, 55.2137330],
          [-2.3587330, 55.2137560],
          [-2.3586650, 55.2137750],
          [-2.3585970, 55.2137950],
          [-2.3585310, 55.2138100],
          [-2.3584660, 55.2138290],
          [-2.3584030, 55.2138480],
          [-2.3583410, 55.2138670],
          [-2.3582860, 55.2138850],
          [-2.3582260, 55.2139010],
          [-2.3581660, 55.2139170],
          [-2.3581040, 55.2139350],
          [-2.3580410, 55.2139550],
          [-2.3579810, 55.2139700],
          [-2.3579210, 55.2139930],
          [-2.3578580, 55.2140160],
          [-2.3577940, 55.2140350],
          [-2.3577330, 55.2140610],
          [-2.3576720, 55.2140840],
          [-2.3576080, 55.2141070],
          [-2.3575470, 55.2141300],
          [-2.3574840, 55.2141570],
          [-2.3574230, 55.2141870],
          [-2.3573570, 55.2142140],
          [-2.3572930, 55.2142440],
          [-2.3572300, 55.2142710],
          [-2.3571680, 55.2142980],
          [-2.3571030, 55.2143250],
          [-2.3570400, 55.2143500],
          [-2.3569710, 55.2143770],
          [-2.3569010, 55.2144080],
          [-2.3568350, 55.2144350],
          [-2.3567680, 55.2144620],
          [-2.3567040, 55.2144960],
          [-2.3566400, 55.2145300],
          [-2.3565680, 55.2145610],
          [-2.3564960, 55.2145910],
          [-2.3564240, 55.2146250],
          [-2.3563480, 55.2146560],
          [-2.3562760, 55.2146940],
          [-2.3562050, 55.2147250],
          [-2.3561350, 55.2147590],
          [-2.3560670, 55.2147930],
          [-2.3559960, 55.2148280],
          [-2.3559240, 55.2148590],
          [-2.3558540, 55.2148890],
          [-2.3557830, 55.2149230],
          [-2.3557080, 55.2149580],
          [-2.3556330, 55.2149910],
          [-2.3555540, 55.2150300],
          [-2.3554790, 55.2150640],
          [-2.3554070, 55.2151010],
          [-2.3553300, 55.2151440],
          [-2.3552580, 55.2151830],
          [-2.3551910, 55.2152250],
          [-2.3551280, 55.2152700],
          [-2.3550710, 55.2153160],
          [-2.3550170, 55.2153580],
          [-2.3549680, 55.2154070],
          [-2.3549190, 55.2154540],
          [-2.3548790, 55.2155030],
          [-2.3548390, 55.2155560],
          [-2.3548000, 55.2156050],
          [-2.3547540, 55.2156590],
          [-2.3547140, 55.2157090],
          [-2.3546720, 55.2157620],
          [-2.3546290, 55.2158120],
          [-2.3545850, 55.2158650],
          [-2.3545440, 55.2159190],
          [-2.3545030, 55.2159750],
          [-2.3544590, 55.2160290],
          [-2.3544120, 55.2160790],
          [-2.3543600, 55.2161320],
          [-2.3543100, 55.2161830],
          [-2.3542650, 55.2162350],
          [-2.3542230, 55.2162920],
          [-2.3541790, 55.2163460],
          [-2.3541370, 55.2163990],
          [-2.3540960, 55.2164560],
          [-2.3540550, 55.2165060],
          [-2.3540140, 55.2165600],
          [-2.3539740, 55.2166110],
          [-2.3539310, 55.2166700],
          [-2.3538890, 55.2167240],
          [-2.3538450, 55.2167760],
          [-2.3538040, 55.2168310],
          [-2.3537650, 55.2168800],
          [-2.3537260, 55.2169330],
          [-2.3536860, 55.2169830],
          [-2.3536480, 55.2170320],
          [-2.3536070, 55.2170840],
          [-2.3535670, 55.2171340],
          [-2.3535270, 55.2171890],
          [-2.3534870, 55.2172390],
          [-2.3534440, 55.2172840],
          [-2.3534000, 55.2173380],
          [-2.3533620, 55.2173870],
          [-2.3533220, 55.2174410],
          [-2.3532810, 55.2174940],
          [-2.3532390, 55.2175480],
          [-2.3532010, 55.2176010],
          [-2.3531570, 55.2176550],
          [-2.3531110, 55.2177110],
          [-2.3530620, 55.2177690],
          [-2.3530120, 55.2178290],
          [-2.3529610, 55.2178910],
          [-2.3529060, 55.2179510],
          [-2.3528540, 55.2180130],
          [-2.3528100, 55.2180730],
          [-2.3527700, 55.2181340],
          [-2.3527270, 55.2181920],
          [-2.3526800, 55.2182450],
          [-2.3526330, 55.2183020],
          [-2.3525910, 55.2183600],
          [-2.3525540, 55.2184130],
          [-2.3525140, 55.2184660],
          [-2.3524740, 55.2185200],
          [-2.3524380, 55.2185730],
          [-2.3523990, 55.2186300],
          [-2.3523610, 55.2186840],
          [-2.3523200, 55.2187370],
          [-2.3522790, 55.2187880],
          [-2.3522380, 55.2188400],
          [-2.3521990, 55.2188940],
          [-2.3521540, 55.2189480],
          [-2.3521140, 55.2190000],
          [-2.3520740, 55.2190540],
          [-2.3520360, 55.2191040],
          [-2.3519980, 55.2191560],
          [-2.3519590, 55.2192110],
          [-2.3519200, 55.2192610],
          [-2.3518840, 55.2193100],
          [-2.3518460, 55.2193560],
          [-2.3518070, 55.2194020],
          [-2.3517720, 55.2194510],
          [-2.3517380, 55.2195000],
          [-2.3517030, 55.2195500],
          [-2.3516690, 55.2196000],
          [-2.3516320, 55.2196500],
          [-2.3515900, 55.2196990],
          [-2.3515490, 55.2197490],
          [-2.3515090, 55.2197970],
          [-2.3514660, 55.2198470],
          [-2.3514220, 55.2199010],
          [-2.3513840, 55.2199580],
          [-2.3513430, 55.2200110],
          [-2.3512990, 55.2200690],
          [-2.3512580, 55.2201220],
          [-2.3512180, 55.2201740],
          [-2.3511790, 55.2202290],
          [-2.3511370, 55.2202830],
          [-2.3510960, 55.2203360],
          [-2.3510500, 55.2203900],
          [-2.3510070, 55.2204430],
          [-2.3509640, 55.2205040],
          [-2.3509170, 55.2205610],
          [-2.3508740, 55.2206150],
          [-2.3508320, 55.2206680],
          [-2.3507920, 55.2207210],
          [-2.3507560, 55.2207710],
          [-2.3507210, 55.2208210],
          [-2.3506840, 55.2208700],
          [-2.3506490, 55.2209160],
          [-2.3506140, 55.2209690],
          [-2.3505750, 55.2210150],
          [-2.3505340, 55.2210610],
          [-2.3504920, 55.2211100],
          [-2.3504550, 55.2211600],
          [-2.3504190, 55.2212060],
          [-2.3503860, 55.2212550],
          [-2.3503550, 55.2213000],
          [-2.3503190, 55.2213470],
          [-2.3502840, 55.2213930],
          [-2.3502470, 55.2214390],
          [-2.3502120, 55.2214840],
          [-2.3501770, 55.2215260],
          [-2.3501460, 55.2215760],
          [-2.3501110, 55.2216220],
          [-2.3500760, 55.2216750],
          [-2.3500420, 55.2217250],
          [-2.3500050, 55.2217780],
          [-2.3499690, 55.2218290],
          [-2.3499270, 55.2218850],
          [-2.3498870, 55.2219420],
          [-2.3498490, 55.2220030],
          [-2.3498060, 55.2220680],
          [-2.3497610, 55.2221370],
          [-2.3497160, 55.2222090],
          [-2.3496690, 55.2222780],
          [-2.3496230, 55.2223490],
          [-2.3495760, 55.2224150],
          [-2.3495340, 55.2224760],
          [-2.3494990, 55.2225370],
          [-2.3494670, 55.2225940],
          [-2.3494370, 55.2226440],
          [-2.3494100, 55.2226940],
          [-2.3493900, 55.2227430],
          [-2.3493710, 55.2227850],
          [-2.3493530, 55.2228270],
          [-2.3493340, 55.2228610],
          [-2.3493120, 55.2228960],
          [-2.3492950, 55.2229270],
          [-2.3492840, 55.2229610],
          [-2.3492700, 55.2229880],
          [-2.3492590, 55.2230180],
          [-2.3492470, 55.2230480],
          [-2.3492300, 55.2230790],
          [-2.3492170, 55.2231100],
          [-2.3492070, 55.2231390],
          [-2.3492020, 55.2231740],
          [-2.3491980, 55.2232010],
          [-2.3491950, 55.2232320],
          [-2.3491900, 55.2232620],
          [-2.3491880, 55.2232930],
          [-2.3491870, 55.2233270],
          [-2.3491870, 55.2233580],
          [-2.3491880, 55.2233880],
          [-2.3491920, 55.2234150],
          [-2.3491930, 55.2234450],
          [-2.3491950, 55.2234760],
          [-2.3491980, 55.2235030],
          [-2.3492030, 55.2235300],
          [-2.3492100, 55.2235560],
          [-2.3492180, 55.2235830],
          [-2.3492230, 55.2236090],
          [-2.3492280, 55.2236360],
          [-2.3492350, 55.2236630],
          [-2.3492400, 55.2236900],
          [-2.3492470, 55.2237160],
          [-2.3492520, 55.2237430],
          [-2.3492570, 55.2237690],
          [-2.3492650, 55.2237930],
          [-2.3492730, 55.2238190],
          [-2.3492800, 55.2238420],
          [-2.3492880, 55.2238690],
          [-2.3493010, 55.2238880],
          [-2.3493080, 55.2239140],
          [-2.3493150, 55.2239410],
          [-2.3493270, 55.2239680],
          [-2.3493350, 55.2239910],
          [-2.3493430, 55.2240180],
          [-2.3493470, 55.2240410],
          [-2.3493530, 55.2240670],
          [-2.3493570, 55.2240900],
          [-2.3493580, 55.2241130],
          [-2.3493600, 55.2241400],
          [-2.3493680, 55.2241670],
          [-2.3493760, 55.2241930],
          [-2.3493830, 55.2242190],
          [-2.3493900, 55.2242500],
          [-2.3494000, 55.2242810],
          [-2.3494100, 55.2243110],
          [-2.3494200, 55.2243420],
          [-2.3494280, 55.2243720],
          [-2.3494370, 55.2243990],
          [-2.3494470, 55.2244290],
          [-2.3494550, 55.2244560],
          [-2.3494650, 55.2244900],
          [-2.3494750, 55.2245210],
          [-2.3494830, 55.2245560],
          [-2.3494940, 55.2245850],
          [-2.3495060, 55.2246200],
          [-2.3495160, 55.2246540],
          [-2.3495250, 55.2246890],
          [-2.3495350, 55.2247220],
          [-2.3495450, 55.2247610],
          [-2.3495570, 55.2248000],
          [-2.3495700, 55.2248380],
          [-2.3495800, 55.2248720],
          [-2.3495910, 55.2249130],
          [-2.3496050, 55.2249520],
          [-2.3496150, 55.2249900],
          [-2.3496250, 55.2250280],
          [-2.3496360, 55.2250670],
          [-2.3496460, 55.2251080],
          [-2.3496550, 55.2251470],
          [-2.3496620, 55.2251850],
          [-2.3496730, 55.2252230],
          [-2.3496860, 55.2252610],
          [-2.3497000, 55.2252990],
          [-2.3497080, 55.2253410],
          [-2.3497150, 55.2253790],
          [-2.3497250, 55.2254210],
          [-2.3497330, 55.2254600],
          [-2.3497430, 55.2254970],
          [-2.3497530, 55.2255300],
          [-2.3497620, 55.2255660],
          [-2.3497720, 55.2256010],
          [-2.3497820, 55.2256310],
          [-2.3497930, 55.2256650],
          [-2.3498030, 55.2256960],
          [-2.3498110, 55.2257260],
          [-2.3498210, 55.2257570],
          [-2.3498330, 55.2257870],
          [-2.3498460, 55.2258210],
          [-2.3498610, 55.2258560],
          [-2.3498730, 55.2258870],
          [-2.3498860, 55.2259170],
          [-2.3498980, 55.2259430],
          [-2.3499130, 55.2259740],
          [-2.3499310, 55.2260050],
          [-2.3499520, 55.2260310],
          [-2.3499710, 55.2260580],
          [-2.3499850, 55.2260930],
          [-2.3500000, 55.2261190],
          [-2.3500160, 55.2261540],
          [-2.3500330, 55.2261840],
          [-2.3500530, 55.2262150],
          [-2.3500740, 55.2262520],
          [-2.3500940, 55.2262870],
          [-2.3501160, 55.2263210],
          [-2.3501380, 55.2263590],
          [-2.3501580, 55.2263980],
          [-2.3501810, 55.2264360],
          [-2.3502060, 55.2264740],
          [-2.3502330, 55.2265160],
          [-2.3502610, 55.2265610],
          [-2.3502930, 55.2266150],
          [-2.3503290, 55.2266650],
          [-2.3503640, 55.2267120],
          [-2.3504050, 55.2267600],
          [-2.3504450, 55.2268090],
          [-2.3504890, 55.2268590],
          [-2.3505340, 55.2269090],
          [-2.3505790, 55.2269580],
          [-2.3506270, 55.2270070],
          [-2.3506780, 55.2270580],
          [-2.3507280, 55.2271040],
          [-2.3507790, 55.2271490],
          [-2.3508320, 55.2271980],
          [-2.3508880, 55.2272490],
          [-2.3509460, 55.2272940],
          [-2.3510040, 55.2273440],
          [-2.3510630, 55.2273930],
          [-2.3511270, 55.2274390],
          [-2.3511910, 55.2274890],
          [-2.3512540, 55.2275350],
          [-2.3513160, 55.2275800],
          [-2.3513810, 55.2276250],
          [-2.3514490, 55.2276710],
          [-2.3515190, 55.2277180],
          [-2.3515890, 55.2277630],
          [-2.3516570, 55.2278090],
          [-2.3517220, 55.2278510],
          [-2.3517890, 55.2278970],
          [-2.3518560, 55.2279390],
          [-2.3519210, 55.2279850],
          [-2.3519870, 55.2280300],
          [-2.3520550, 55.2280720],
          [-2.3521220, 55.2281140],
          [-2.3521870, 55.2281600],
          [-2.3522540, 55.2282060],
          [-2.3523190, 55.2282510],
          [-2.3523840, 55.2282930],
          [-2.3524520, 55.2283400],
          [-2.3525170, 55.2283810],
          [-2.3525820, 55.2284310],
          [-2.3526480, 55.2284730],
          [-2.3527160, 55.2285230],
          [-2.3527830, 55.2285680],
          [-2.3528470, 55.2286070],
          [-2.3529070, 55.2286480],
          [-2.3529670, 55.2286900],
          [-2.3530250, 55.2287280],
          [-2.3530850, 55.2287660],
          [-2.3531440, 55.2288090],
          [-2.3532030, 55.2288460],
          [-2.3532590, 55.2288850],
          [-2.3533120, 55.2289230],
          [-2.3533660, 55.2289580],
          [-2.3534210, 55.2289960],
          [-2.3534740, 55.2290300],
          [-2.3535240, 55.2290640],
          [-2.3535730, 55.2290990],
          [-2.3536210, 55.2291330],
          [-2.3536740, 55.2291680],
          [-2.3537240, 55.2292020],
          [-2.3537730, 55.2292360],
          [-2.3538210, 55.2292700],
          [-2.3538700, 55.2293010],
          [-2.3539200, 55.2293350],
          [-2.3539710, 55.2293700],
          [-2.3540240, 55.2294080],
          [-2.3540740, 55.2294420],
          [-2.3541260, 55.2294760],
          [-2.3541810, 55.2295140],
          [-2.3542380, 55.2295570],
          [-2.3542880, 55.2295950],
          [-2.3543340, 55.2296360],
          [-2.3543810, 55.2296790],
          [-2.3544290, 55.2297210],
          [-2.3544740, 55.2297660],
          [-2.3545230, 55.2298120],
          [-2.3545690, 55.2298580],
          [-2.3546210, 55.2299070],
          [-2.3546710, 55.2299610],
          [-2.3547190, 55.2300130],
          [-2.3547610, 55.2300670],
          [-2.3547990, 55.2301210],
          [-2.3548380, 55.2301820],
          [-2.3548760, 55.2302390],
          [-2.3549140, 55.2303040],
          [-2.3549530, 55.2303650],
          [-2.3549930, 55.2304260],
          [-2.3550360, 55.2304900],
          [-2.3550810, 55.2305480],
          [-2.3551280, 55.2306090],
          [-2.3551700, 55.2306720],
          [-2.3552150, 55.2307340],
          [-2.3552630, 55.2307960],
          [-2.3553090, 55.2308610],
          [-2.3553550, 55.2309160],
          [-2.3554010, 55.2309780],
          [-2.3554460, 55.2310400],
          [-2.3554900, 55.2311000],
          [-2.3555340, 55.2311620],
          [-2.3555790, 55.2312190],
          [-2.3556260, 55.2312800],
          [-2.3556710, 55.2313420],
          [-2.3557160, 55.2313980],
          [-2.3557590, 55.2314560],
          [-2.3558040, 55.2315170],
          [-2.3558480, 55.2315740],
          [-2.3558910, 55.2316350],
          [-2.3559310, 55.2316920],
          [-2.3559720, 55.2317530],
          [-2.3560120, 55.2318100],
          [-2.3560530, 55.2318680],
          [-2.3560870, 55.2319240],
          [-2.3561190, 55.2319800],
          [-2.3561490, 55.2320390],
          [-2.3561760, 55.2320960],
          [-2.3561990, 55.2321530],
          [-2.3562210, 55.2322180],
          [-2.3562400, 55.2322760],
          [-2.3562550, 55.2323370],
          [-2.3562700, 55.2323980],
          [-2.3562830, 55.2324590],
          [-2.3563000, 55.2325160],
          [-2.3563130, 55.2325770],
          [-2.3563240, 55.2326290],
          [-2.3563380, 55.2326880],
          [-2.3563500, 55.2327450],
          [-2.3563610, 55.2328030],
          [-2.3563710, 55.2328630],
          [-2.3563830, 55.2329210],
          [-2.3563920, 55.2329740],
          [-2.3564000, 55.2330310],
          [-2.3564080, 55.2330850],
          [-2.3564130, 55.2331380],
          [-2.3564180, 55.2331910],
          [-2.3564270, 55.2332410],
          [-2.3564350, 55.2332910],
          [-2.3564400, 55.2333440],
          [-2.3564400, 55.2333970],
          [-2.3564380, 55.2334470],
          [-2.3564350, 55.2335010],
          [-2.3564300, 55.2335540],
          [-2.3564230, 55.2336070],
          [-2.3564140, 55.2336600],
          [-2.3564040, 55.2337140],
          [-2.3563920, 55.2337630],
          [-2.3563800, 55.2338170],
          [-2.3563670, 55.2338690],
          [-2.3563520, 55.2339230],
          [-2.3563370, 55.2339780],
          [-2.3563200, 55.2340340],
          [-2.3563040, 55.2340960],
          [-2.3562860, 55.2341480],
          [-2.3562650, 55.2342100],
          [-2.3562460, 55.2342630],
          [-2.3562250, 55.2343210],
          [-2.3562050, 55.2343780],
          [-2.3561840, 55.2344350],
          [-2.3561620, 55.2344930],
          [-2.3561400, 55.2345530],
          [-2.3561190, 55.2346140],
          [-2.3560940, 55.2346750],
          [-2.3560730, 55.2347310],
          [-2.3560490, 55.2347970],
          [-2.3560240, 55.2348590],
          [-2.3559990, 55.2349200],
          [-2.3559750, 55.2349810],
          [-2.3559490, 55.2350450],
          [-2.3559240, 55.2351100],
          [-2.3559010, 55.2351740],
          [-2.3558740, 55.2352360],
          [-2.3558490, 55.2352960],
          [-2.3558270, 55.2353580],
          [-2.3558040, 55.2354190],
          [-2.3557810, 55.2354720],
          [-2.3557570, 55.2355300],
          [-2.3557360, 55.2355820],
          [-2.3557180, 55.2356340],
          [-2.3556970, 55.2356860],
          [-2.3556800, 55.2357360],
          [-2.3556650, 55.2357850],
          [-2.3556520, 55.2358350],
          [-2.3556400, 55.2358880],
          [-2.3556300, 55.2359380],
          [-2.3556230, 55.2359920],
          [-2.3556200, 55.2360450],
          [-2.3556230, 55.2360980],
          [-2.3556310, 55.2361510],
          [-2.3556500, 55.2362050],
          [-2.3556710, 55.2362540],
          [-2.3556960, 55.2363070],
          [-2.3557240, 55.2363610],
          [-2.3557550, 55.2364140],
          [-2.3557870, 55.2364670],
          [-2.3558240, 55.2365250],
          [-2.3558630, 55.2365790],
          [-2.3558990, 55.2366390],
          [-2.3559380, 55.2366970],
          [-2.3559760, 55.2367550],
          [-2.3560120, 55.2368110],
          [-2.3560490, 55.2368730],
          [-2.3560880, 55.2369340],
          [-2.3561260, 55.2369950],
          [-2.3561640, 55.2370590],
          [-2.3562070, 55.2371270],
          [-2.3562510, 55.2371930],
          [-2.3562950, 55.2372660],
          [-2.3563360, 55.2373340],
          [-2.3563780, 55.2373990],
          [-2.3564200, 55.2374680],
          [-2.3564590, 55.2375320],
          [-2.3565010, 55.2375970],
          [-2.3565430, 55.2376660],
          [-2.3565830, 55.2377310],
          [-2.3566240, 55.2377990],
          [-2.3566680, 55.2378680],
          [-2.3567110, 55.2379370],
          [-2.3567540, 55.2380050],
          [-2.3567980, 55.2380740],
          [-2.3568390, 55.2381380],
          [-2.3568810, 55.2382080],
          [-2.3569190, 55.2382750],
          [-2.3569560, 55.2383410],
          [-2.3569870, 55.2384090],
          [-2.3570140, 55.2384780],
          [-2.3570380, 55.2385470],
          [-2.3570580, 55.2386160],
          [-2.3570780, 55.2386870],
          [-2.3570960, 55.2387570],
          [-2.3571130, 55.2388250],
          [-2.3571310, 55.2388900],
          [-2.3571480, 55.2389560],
          [-2.3571630, 55.2390180],
          [-2.3571800, 55.2390810],
          [-2.3571950, 55.2391460],
          [-2.3572100, 55.2392150],
          [-2.3572230, 55.2392830],
          [-2.3572360, 55.2393520],
          [-2.3572480, 55.2394190],
          [-2.3572610, 55.2394890],
          [-2.3572710, 55.2395620],
          [-2.3572820, 55.2396300],
          [-2.3572880, 55.2396950],
          [-2.3572930, 55.2397600],
          [-2.3572970, 55.2398250],
          [-2.3573020, 55.2398860],
          [-2.3573000, 55.2399500],
          [-2.3573020, 55.2400120],
          [-2.3573000, 55.2400810],
          [-2.3572850, 55.2401460],
          [-2.3572470, 55.2402030],
          [-2.3571910, 55.2402640],
          [-2.3571180, 55.2403110],
          [-2.3570180, 55.2403630],
          [-2.3569180, 55.2404090],
          [-2.3568120, 55.2404510],
          [-2.3567010, 55.2404970],
          [-2.3565920, 55.2405460],
          [-2.3564830, 55.2405950],
          [-2.3563780, 55.2406490],
          [-2.3562800, 55.2407030],
          [-2.3561850, 55.2407600],
          [-2.3560950, 55.2408160],
          [-2.3560010, 55.2408740],
          [-2.3559080, 55.2409350],
          [-2.3558070, 55.2409880],
          [-2.3557000, 55.2410380],
          [-2.3555850, 55.2410840],
          [-2.3554760, 55.2411300],
          [-2.3553680, 55.2411720],
          [-2.3552700, 55.2412140],
          [-2.3551810, 55.2412560],
          [-2.3551010, 55.2412900],
          [-2.3550300, 55.2413250],
          [-2.3549670, 55.2413630],
          [-2.3549110, 55.2413970],
          [-2.3548610, 55.2414320],
          [-2.3548190, 55.2414660],
          [-2.3547840, 55.2415040],
          [-2.3547510, 55.2415380],
          [-2.3547240, 55.2415720],
          [-2.3546990, 55.2416070],
          [-2.3546770, 55.2416380],
          [-2.3546570, 55.2416760],
          [-2.3546390, 55.2417100],
          [-2.3546190, 55.2417440],
          [-2.3545990, 55.2417790],
          [-2.3545790, 55.2418130],
          [-2.3545610, 55.2418430],
          [-2.3545420, 55.2418780],
          [-2.3545220, 55.2419080],
          [-2.3545060, 55.2419430],
          [-2.3544870, 55.2419760],
          [-2.3544650, 55.2420080],
          [-2.3544470, 55.2420420],
          [-2.3544300, 55.2420760],
          [-2.3544100, 55.2421110],
          [-2.3543900, 55.2421490],
          [-2.3543720, 55.2421830],
          [-2.3543560, 55.2422160],
          [-2.3543370, 55.2422520],
          [-2.3543190, 55.2422860],
          [-2.3542960, 55.2423200],
          [-2.3542740, 55.2423550],
          [-2.3542540, 55.2423930],
          [-2.3542350, 55.2424310],
          [-2.3542140, 55.2424690],
          [-2.3541910, 55.2425060],
          [-2.3541710, 55.2425450],
          [-2.3541470, 55.2425870],
          [-2.3541220, 55.2426290],
          [-2.3540940, 55.2426750],
          [-2.3540670, 55.2427210],
          [-2.3540390, 55.2427670],
          [-2.3540100, 55.2428120],
          [-2.3539800, 55.2428620],
          [-2.3539530, 55.2429070],
          [-2.3539250, 55.2429570],
          [-2.3539040, 55.2430030],
          [-2.3538820, 55.2430490],
          [-2.3538580, 55.2430940],
          [-2.3538350, 55.2431350],
          [-2.3538130, 55.2431770],
          [-2.3537900, 55.2432240],
          [-2.3537670, 55.2432660],
          [-2.3537420, 55.2433120],
          [-2.3537150, 55.2433580],
          [-2.3536890, 55.2434080],
          [-2.3536630, 55.2434530],
          [-2.3536370, 55.2435030],
          [-2.3536100, 55.2435490],
          [-2.3535820, 55.2435980],
          [-2.3535560, 55.2436460],
          [-2.3535270, 55.2436930],
          [-2.3535000, 55.2437430],
          [-2.3534720, 55.2437930],
          [-2.3534420, 55.2438380],
          [-2.3534140, 55.2438840],
          [-2.3533870, 55.2439340],
          [-2.3533620, 55.2439840],
          [-2.3533320, 55.2440290],
          [-2.3533020, 55.2440790],
          [-2.3532740, 55.2441250],
          [-2.3532440, 55.2441740],
          [-2.3532120, 55.2442240],
          [-2.3531820, 55.2442680],
          [-2.3531490, 55.2443190],
          [-2.3531200, 55.2443690],
          [-2.3530910, 55.2444140],
          [-2.3530620, 55.2444640],
          [-2.3530340, 55.2445100],
          [-2.3530070, 55.2445560],
          [-2.3529790, 55.2446010],
          [-2.3529510, 55.2446470],
          [-2.3529210, 55.2446890],
          [-2.3528940, 55.2447350],
          [-2.3528670, 55.2447810],
          [-2.3528390, 55.2448260],
          [-2.3528140, 55.2448720],
          [-2.3527890, 55.2449140],
          [-2.3527630, 55.2449620],
          [-2.3527360, 55.2450050],
          [-2.3527090, 55.2450510],
          [-2.3526840, 55.2450970],
          [-2.3526600, 55.2451390],
          [-2.3526360, 55.2451810],
          [-2.3526110, 55.2452270],
          [-2.3525860, 55.2452720],
          [-2.3525610, 55.2453180],
          [-2.3525330, 55.2453640],
          [-2.3525010, 55.2454130],
          [-2.3524660, 55.2454580],
          [-2.3524320, 55.2455040],
          [-2.3523920, 55.2455480],
          [-2.3523460, 55.2455890],
          [-2.3522870, 55.2456200],
          [-2.3522280, 55.2456470],
          [-2.3521640, 55.2456690],
          [-2.3520930, 55.2456850],
          [-2.3520290, 55.2456930],
          [-2.3519680, 55.2457010],
          [-2.3519130, 55.2457120],
          [-2.3518590, 55.2457200],
          [-2.3518060, 55.2457200],
          [-2.3517530, 55.2457200],
          [-2.3517000, 55.2457200],
          [-2.3516480, 55.2457200],
          [-2.3515970, 55.2457200],
          [-2.3515450, 55.2457200],
          [-2.3514930, 55.2457200],
          [-2.3514390, 55.2457160],
          [-2.3513850, 55.2457120],
          [-2.3513320, 55.2457120],
          [-2.3512820, 55.2457090],
          [-2.3512310, 55.2457050],
          [-2.3511840, 55.2457010],
          [-2.3511380, 55.2456970],
          [-2.3510900, 55.2456930],
          [-2.3510420, 55.2456890],
          [-2.3509980, 55.2456860],
          [-2.3509510, 55.2456820],
          [-2.3509040, 55.2456820],
          [-2.3508590, 55.2456740],
          [-2.3508150, 55.2456700],
          [-2.3507700, 55.2456670],
          [-2.3507290, 55.2456630],
          [-2.3506830, 55.2456590],
          [-2.3506390, 55.2456550],
          [-2.3505990, 55.2456480],
          [-2.3505610, 55.2456400],
          [-2.3505190, 55.2456400],
          [-2.3504750, 55.2456320],
          [-2.3504280, 55.2456280],
          [-2.3503830, 55.2456250],
          [-2.3503400, 55.2456170],
          [-2.3503110, 55.2456140],
          [-2.3502570, 55.2456020],
          [-2.3502150, 55.2455980],
          [-2.3501770, 55.2455900],
          [-2.3501390, 55.2455860],
          [-2.3500970, 55.2455790],
          [-2.3500560, 55.2455680],
          [-2.3500120, 55.2455600],
          [-2.3499710, 55.2455490],
          [-2.3499310, 55.2455410],
          [-2.3498920, 55.2455290],
          [-2.3498540, 55.2455180],
          [-2.3498180, 55.2455100],
          [-2.3497830, 55.2454990],
          [-2.3497470, 55.2454870],
          [-2.3497140, 55.2454760],
          [-2.3496810, 55.2454680],
          [-2.3496480, 55.2454570],
          [-2.3496160, 55.2454490],
          [-2.3495840, 55.2454420],
          [-2.3495470, 55.2454300],
          [-2.3495080, 55.2454150],
          [-2.3494680, 55.2454000],
          [-2.3494290, 55.2453850],
          [-2.3493890, 55.2453660],
          [-2.3493480, 55.2453470],
          [-2.3493040, 55.2453240],
          [-2.3492590, 55.2453010],
          [-2.3492160, 55.2452810],
          [-2.3491740, 55.2452590],
          [-2.3491340, 55.2452360],
          [-2.3490940, 55.2452170],
          [-2.3490540, 55.2451940],
          [-2.3490180, 55.2451750],
          [-2.3489790, 55.2451560],
          [-2.3489420, 55.2451370],
          [-2.3489040, 55.2451170],
          [-2.3488730, 55.2451050],
          [-2.3488270, 55.2450870],
          [-2.3487880, 55.2450680],
          [-2.3487470, 55.2450530],
          [-2.3487080, 55.2450340],
          [-2.3486690, 55.2450180],
          [-2.3486320, 55.2449960],
          [-2.3485940, 55.2449800],
          [-2.3485530, 55.2449570],
          [-2.3485130, 55.2449380],
          [-2.3484680, 55.2449190],
          [-2.3484230, 55.2449000],
          [-2.3483740, 55.2448770],
          [-2.3483260, 55.2448590],
          [-2.3482770, 55.2448350],
          [-2.3482310, 55.2448090],
          [-2.3481840, 55.2447860],
          [-2.3481380, 55.2447590],
          [-2.3480920, 55.2447320],
          [-2.3480490, 55.2447060],
          [-2.3480110, 55.2446720],
          [-2.3479740, 55.2446410],
          [-2.3479390, 55.2446070],
          [-2.3479060, 55.2445730],
          [-2.3478740, 55.2445340],
          [-2.3478420, 55.2444960],
          [-2.3478070, 55.2444540],
          [-2.3477690, 55.2444160],
          [-2.3477310, 55.2443740],
          [-2.3476940, 55.2443360],
          [-2.3476590, 55.2442940],
          [-2.3476220, 55.2442520],
          [-2.3475860, 55.2442100],
          [-2.3475470, 55.2441640],
          [-2.3475060, 55.2441220],
          [-2.3474660, 55.2440760],
          [-2.3474270, 55.2440320],
          [-2.3473850, 55.2439850],
          [-2.3473460, 55.2439400],
          [-2.3473060, 55.2438970],
          [-2.3472670, 55.2438550],
          [-2.3472290, 55.2438130],
          [-2.3471870, 55.2437710],
          [-2.3471460, 55.2437250],
          [-2.3471050, 55.2436840],
          [-2.3470610, 55.2436380],
          [-2.3470150, 55.2435960],
          [-2.3469710, 55.2435540],
          [-2.3469270, 55.2435080],
          [-2.3468840, 55.2434660],
          [-2.3468400, 55.2434290],
          [-2.3467940, 55.2433900],
          [-2.3467470, 55.2433520],
          [-2.3467000, 55.2433140],
          [-2.3466510, 55.2432750],
          [-2.3466010, 55.2432370],
          [-2.3465540, 55.2432000],
          [-2.3465020, 55.2431610],
          [-2.3464550, 55.2431230],
          [-2.3464050, 55.2430890],
          [-2.3463540, 55.2430540],
          [-2.3463020, 55.2430160],
          [-2.3462560, 55.2429820],
          [-2.3462110, 55.2429510],
          [-2.3461660, 55.2429240],
          [-2.3461200, 55.2428980],
          [-2.3460750, 55.2428750],
          [-2.3460310, 55.2428520],
          [-2.3459860, 55.2428330],
          [-2.3459290, 55.2428130],
          [-2.3458640, 55.2428020],
          [-2.3458000, 55.2427900],
          [-2.3457380, 55.2427830],
          [-2.3456730, 55.2427750],
          [-2.3456110, 55.2427710],
          [-2.3455520, 55.2427750],
          [-2.3454910, 55.2427790],
          [-2.3454290, 55.2427820],
          [-2.3453650, 55.2427860],
          [-2.3452990, 55.2427940],
          [-2.3452380, 55.2428090],
          [-2.3451740, 55.2428240],
          [-2.3451110, 55.2428400],
          [-2.3450510, 55.2428550],
          [-2.3449910, 55.2428740],
          [-2.3449330, 55.2428930],
          [-2.3448780, 55.2429120],
          [-2.3448250, 55.2429300],
          [-2.3447680, 55.2429500],
          [-2.3447130, 55.2429650],
          [-2.3446530, 55.2429840],
          [-2.3445940, 55.2430000],
          [-2.3445380, 55.2430150],
          [-2.3444810, 55.2430300],
          [-2.3444280, 55.2430490],
          [-2.3443790, 55.2430640],
          [-2.3443250, 55.2430800],
          [-2.3442720, 55.2430990],
          [-2.3442190, 55.2431180],
          [-2.3441710, 55.2431330],
          [-2.3441230, 55.2431520],
          [-2.3440720, 55.2431710],
          [-2.3440210, 55.2431900],
          [-2.3439680, 55.2432090],
          [-2.3439160, 55.2432280],
          [-2.3438650, 55.2432470],
          [-2.3438150, 55.2432660],
          [-2.3437630, 55.2432860],
          [-2.3437100, 55.2433050],
          [-2.3436580, 55.2433240],
          [-2.3436100, 55.2433430],
          [-2.3435590, 55.2433660],
          [-2.3435120, 55.2433850],
          [-2.3434660, 55.2434080],
          [-2.3434200, 55.2434300],
          [-2.3433740, 55.2434540],
          [-2.3433320, 55.2434770],
          [-2.3432890, 55.2434960],
          [-2.3432480, 55.2435180],
          [-2.3432040, 55.2435410],
          [-2.3431650, 55.2435600],
          [-2.3431260, 55.2435830],
          [-2.3430840, 55.2436060],
          [-2.3430430, 55.2436250],
          [-2.3430010, 55.2436520],
          [-2.3429570, 55.2436740],
          [-2.3429100, 55.2436970],
          [-2.3428610, 55.2437240],
          [-2.3428140, 55.2437510],
          [-2.3427660, 55.2437740],
          [-2.3427180, 55.2438010],
          [-2.3426710, 55.2438270],
          [-2.3426240, 55.2438540],
          [-2.3425740, 55.2438760],
          [-2.3425230, 55.2439030],
          [-2.3424730, 55.2439260],
          [-2.3424240, 55.2439530],
          [-2.3423770, 55.2439760],
          [-2.3423300, 55.2440030],
          [-2.3422820, 55.2440220],
          [-2.3422330, 55.2440450],
          [-2.3421840, 55.2440680],
          [-2.3421370, 55.2440940],
          [-2.3420890, 55.2441170],
          [-2.3420420, 55.2441360],
          [-2.3419950, 55.2441550],
          [-2.3419450, 55.2441740],
          [-2.3418990, 55.2441930],
          [-2.3418510, 55.2442130],
          [-2.3418010, 55.2442360],
          [-2.3417540, 55.2442590],
          [-2.3417060, 55.2442820],
          [-2.3416530, 55.2443080],
          [-2.3415990, 55.2443350],
          [-2.3415470, 55.2443610],
          [-2.3414930, 55.2443840],
          [-2.3414390, 55.2444150],
          [-2.3413840, 55.2444450],
          [-2.3413290, 55.2444760],
          [-2.3412750, 55.2445020],
          [-2.3412150, 55.2445290],
          [-2.3411530, 55.2445480],
          [-2.3410910, 55.2445710],
          [-2.3410280, 55.2445900],
          [-2.3409550, 55.2446130],
          [-2.3408750, 55.2446430],
          [-2.3407950, 55.2446780],
          [-2.3407140, 55.2447160],
          [-2.3406400, 55.2447540],
          [-2.3405690, 55.2448000],
          [-2.3405050, 55.2448560],
          [-2.3404430, 55.2449180],
          [-2.3403830, 55.2449790],
          [-2.3403240, 55.2450360],
          [-2.3402700, 55.2450970],
          [-2.3402190, 55.2451530],
          [-2.3401660, 55.2452120],
          [-2.3401110, 55.2452690],
          [-2.3400620, 55.2453220],
          [-2.3400140, 55.2453720],
          [-2.3399710, 55.2454180],
          [-2.3399230, 55.2454670],
          [-2.3398760, 55.2455200],
          [-2.3398290, 55.2455740],
          [-2.3397800, 55.2456270],
          [-2.3397360, 55.2456800],
          [-2.3396940, 55.2457350],
          [-2.3396520, 55.2457840],
          [-2.3396110, 55.2458340],
          [-2.3395670, 55.2458830],
          [-2.3395280, 55.2459320],
          [-2.3394870, 55.2459820],
          [-2.3394470, 55.2460320],
          [-2.3394120, 55.2460770],
          [-2.3393780, 55.2461230],
          [-2.3393440, 55.2461670],
          [-2.3393090, 55.2462110],
          [-2.3392720, 55.2462610],
          [-2.3392340, 55.2463030],
          [-2.3391990, 55.2463450],
          [-2.3391630, 55.2463930],
          [-2.3391220, 55.2464400],
          [-2.3390790, 55.2464860],
          [-2.3390360, 55.2465350],
          [-2.3389920, 55.2465810],
          [-2.3389520, 55.2466310],
          [-2.3389110, 55.2466730],
          [-2.3388710, 55.2467180],
          [-2.3388340, 55.2467620],
          [-2.3387890, 55.2468100],
          [-2.3387440, 55.2468600],
          [-2.3386960, 55.2469050],
          [-2.3386480, 55.2469510],
          [-2.3385980, 55.2469970],
          [-2.3385480, 55.2470430],
          [-2.3384970, 55.2470920],
          [-2.3384400, 55.2471420],
          [-2.3383870, 55.2471910],
          [-2.3383290, 55.2472410],
          [-2.3382720, 55.2472910],
          [-2.3382170, 55.2473390],
          [-2.3381630, 55.2473900],
          [-2.3381100, 55.2474390],
          [-2.3380490, 55.2474970],
          [-2.3379880, 55.2475500],
          [-2.3379300, 55.2476050],
          [-2.3378640, 55.2476690],
          [-2.3378040, 55.2477260],
          [-2.3377510, 55.2477830],
          [-2.3376940, 55.2478460],
          [-2.3376360, 55.2479050],
          [-2.3375820, 55.2479610],
          [-2.3375270, 55.2480180],
          [-2.3374710, 55.2480800],
          [-2.3374170, 55.2481440],
          [-2.3373650, 55.2482040],
          [-2.3373130, 55.2482710],
          [-2.3372630, 55.2483320],
          [-2.3372140, 55.2483890],
          [-2.3371660, 55.2484460],
          [-2.3371180, 55.2485000],
          [-2.3370710, 55.2485570],
          [-2.3370280, 55.2486100],
          [-2.3369860, 55.2486630],
          [-2.3369410, 55.2487170],
          [-2.3368950, 55.2487730],
          [-2.3368490, 55.2488280],
          [-2.3368070, 55.2488820],
          [-2.3367640, 55.2489350],
          [-2.3367180, 55.2489880],
          [-2.3366740, 55.2490410],
          [-2.3366310, 55.2490950],
          [-2.3365900, 55.2491410],
          [-2.3365540, 55.2491910],
          [-2.3365200, 55.2492360],
          [-2.3364840, 55.2492780],
          [-2.3364460, 55.2493240],
          [-2.3364050, 55.2493660],
          [-2.3363700, 55.2494120],
          [-2.3363390, 55.2494570],
          [-2.3363070, 55.2494960],
          [-2.3362740, 55.2495410],
          [-2.3362400, 55.2495860],
          [-2.3362040, 55.2496330],
          [-2.3361710, 55.2496820],
          [-2.3361400, 55.2497270],
          [-2.3361100, 55.2497730],
          [-2.3360790, 55.2498200],
          [-2.3360440, 55.2498660],
          [-2.3360130, 55.2499150],
          [-2.3359840, 55.2499650],
          [-2.3359520, 55.2500150],
          [-2.3359160, 55.2500630],
          [-2.3358820, 55.2501100],
          [-2.3358470, 55.2501590],
          [-2.3358170, 55.2502070],
          [-2.3357840, 55.2502550],
          [-2.3357510, 55.2503040],
          [-2.3357190, 55.2503540],
          [-2.3356860, 55.2504110],
          [-2.3356490, 55.2504640],
          [-2.3356120, 55.2505220],
          [-2.3355750, 55.2505790],
          [-2.3355340, 55.2506400],
          [-2.3354960, 55.2506970],
          [-2.3354640, 55.2507540],
          [-2.3354380, 55.2508110],
          [-2.3354070, 55.2508690],
          [-2.3353710, 55.2509220],
          [-2.3353310, 55.2509790],
          [-2.3352930, 55.2510290],
          [-2.3352560, 55.2510820],
          [-2.3352180, 55.2511350],
          [-2.3351810, 55.2511880],
          [-2.3351470, 55.2512430],
          [-2.3351110, 55.2512960],
          [-2.3350740, 55.2513530],
          [-2.3350370, 55.2514070],
          [-2.3350030, 55.2514600],
          [-2.3349650, 55.2515160],
          [-2.3349260, 55.2515710],
          [-2.3348890, 55.2516240],
          [-2.3348520, 55.2516820],
          [-2.3348160, 55.2517380],
          [-2.3347770, 55.2517920],
          [-2.3347400, 55.2518480],
          [-2.3347010, 55.2519030],
          [-2.3346630, 55.2519590],
          [-2.3346240, 55.2520170],
          [-2.3345860, 55.2520740],
          [-2.3345440, 55.2521260],
          [-2.3344920, 55.2521890],
          [-2.3344410, 55.2522500],
          [-2.3343930, 55.2523180],
          [-2.3343510, 55.2523820],
          [-2.3343130, 55.2524440],
          [-2.3342760, 55.2525090],
          [-2.3342360, 55.2525660],
          [-2.3341950, 55.2526240],
          [-2.3341590, 55.2526770],
          [-2.3341260, 55.2527300],
          [-2.3340910, 55.2527800],
          [-2.3340550, 55.2528300],
          [-2.3340230, 55.2528800],
          [-2.3339910, 55.2529280],
          [-2.3339580, 55.2529770],
          [-2.3339250, 55.2530240],
          [-2.3338940, 55.2530740],
          [-2.3338590, 55.2531200],
          [-2.3338220, 55.2531690],
          [-2.3337890, 55.2532190],
          [-2.3337520, 55.2532690],
          [-2.3337160, 55.2533220],
          [-2.3336790, 55.2533750],
          [-2.3336430, 55.2534280],
          [-2.3336050, 55.2534810],
          [-2.3335650, 55.2535360],
          [-2.3335290, 55.2535850],
          [-2.3334970, 55.2536380],
          [-2.3334660, 55.2536880],
          [-2.3334310, 55.2537410],
          [-2.3333920, 55.2537950],
          [-2.3333540, 55.2538450],
          [-2.3333170, 55.2538940],
          [-2.3332820, 55.2539440],
          [-2.3332480, 55.2539920],
          [-2.3332140, 55.2540430],
          [-2.3331830, 55.2540950],
          [-2.3331490, 55.2541500],
          [-2.3331120, 55.2541990],
          [-2.3330740, 55.2542560],
          [-2.3330290, 55.2543100],
          [-2.3329860, 55.2543630],
          [-2.3329440, 55.2544200],
          [-2.3329060, 55.2544760],
          [-2.3328710, 55.2545370],
          [-2.3328320, 55.2545960],
          [-2.3327920, 55.2546570],
          [-2.3327520, 55.2547220],
          [-2.3327140, 55.2547790],
          [-2.3326770, 55.2548360],
          [-2.3326400, 55.2548930],
          [-2.3326040, 55.2549460],
          [-2.3325660, 55.2550040],
          [-2.3325260, 55.2550570],
          [-2.3324850, 55.2551170],
          [-2.3324410, 55.2551750],
          [-2.3323970, 55.2552410],
          [-2.3323540, 55.2553020],
          [-2.3323100, 55.2553610],
          [-2.3322610, 55.2554200],
          [-2.3322090, 55.2554770],
          [-2.3321520, 55.2555350],
          [-2.3320870, 55.2555880],
          [-2.3320200, 55.2556370],
          [-2.3319490, 55.2556900],
          [-2.3318730, 55.2557400],
          [-2.3318010, 55.2557940],
          [-2.3317260, 55.2558470],
          [-2.3316530, 55.2559080],
          [-2.3315790, 55.2559650],
          [-2.3315040, 55.2560230],
          [-2.3314310, 55.2560790],
          [-2.3313540, 55.2561360],
          [-2.3312760, 55.2561950],
          [-2.3311980, 55.2562540],
          [-2.3311130, 55.2563160],
          [-2.3310280, 55.2563770],
          [-2.3309430, 55.2564420],
          [-2.3308590, 55.2565030],
          [-2.3307780, 55.2565710],
          [-2.3306900, 55.2566400],
          [-2.3305940, 55.2567090],
          [-2.3304970, 55.2567720],
          [-2.3303870, 55.2568310],
          [-2.3302710, 55.2568760],
          [-2.3301360, 55.2569120],
          [-2.3299920, 55.2569350],
          [-2.3298300, 55.2569310],
          [-2.3296540, 55.2569240],
          [-2.3294590, 55.2569090],
          [-2.3292450, 55.2568900],
          [-2.3290280, 55.2568670],
          [-2.3288210, 55.2568520],
          [-2.3286150, 55.2568360],
          [-2.3284720, 55.2568310],
          [-2.3281970, 55.2568140],
          [-2.3279840, 55.2567980],
          [-2.3277690, 55.2567830],
          [-2.3275640, 55.2567710],
          [-2.3273660, 55.2567640],
          [-2.3271580, 55.2567670],
          [-2.3269480, 55.2567790],
          [-2.3267340, 55.2567900],
          [-2.3265190, 55.2567940],
          [-2.3263020, 55.2567940],
          [-2.3260900, 55.2567790],
          [-2.3258850, 55.2567680],
          [-2.3256840, 55.2567600],
          [-2.3254780, 55.2567600],
          [-2.3252690, 55.2567630],
          [-2.3250590, 55.2567820],
          [-2.3248410, 55.2568010],
          [-2.3246070, 55.2568200],
          [-2.3243770, 55.2568280],
          [-2.3241530, 55.2568250],
          [-2.3239300, 55.2568090],
          [-2.3236770, 55.2567830],
          [-2.3234640, 55.2567560],
          [-2.3232580, 55.2567310],
          [-2.3230550, 55.2567070],
          [-2.3228610, 55.2566950],
          [-2.3226650, 55.2566910],
          [-2.3224730, 55.2566950],
          [-2.3222760, 55.2566950],
          [-2.3220860, 55.2566840],
          [-2.3218900, 55.2566650],
          [-2.3216960, 55.2566420],
          [-2.3215190, 55.2566210],
          [-2.3213240, 55.2566040],
          [-2.3211450, 55.2565960],
          [-2.3209570, 55.2565990],
          [-2.3207710, 55.2566140],
          [-2.3205980, 55.2566350],
          [-2.3204280, 55.2566550],
          [-2.3202700, 55.2566700],
          [-2.3201070, 55.2566860],
          [-2.3199570, 55.2566840],
          [-2.3197670, 55.2566730],
          [-2.3195900, 55.2566330],
          [-2.3194130, 55.2565930],
          [-2.3192280, 55.2565620],
          [-2.3190330, 55.2565500],
          [-2.3188660, 55.2565570],
          [-2.3187190, 55.2565760],
          [-2.3185990, 55.2566070],
          [-2.3184960, 55.2566450],
          [-2.3184260, 55.2567050],
          [-2.3183970, 55.2567700],
          [-2.3184050, 55.2568350],
          [-2.3184250, 55.2568960],
          [-2.3184410, 55.2569610],
          [-2.3184450, 55.2570370],
          [-2.3184470, 55.2571170],
          [-2.3184560, 55.2572040],
          [-2.3184780, 55.2572980],
          [-2.3184980, 55.2574140],
          [-2.3185260, 55.2575270],
          [-2.3185550, 55.2576400],
          [-2.3185960, 55.2577610],
          [-2.3186230, 55.2578830],
          [-2.3186500, 55.2580050],
          [-2.3186760, 55.2581350],
          [-2.3187040, 55.2582630],
          [-2.3187350, 55.2583990],
          [-2.3187720, 55.2585340],
          [-2.3188270, 55.2586720],
          [-2.3188990, 55.2588060],
          [-2.3189770, 55.2589350],
          [-2.3190420, 55.2590570],
          [-2.3190970, 55.2591650],
          [-2.3191430, 55.2592570],
          [-2.3191830, 55.2593370],
          [-2.3192230, 55.2594130],
          [-2.3192660, 55.2594740],
          [-2.3193270, 55.2595170],
          [-2.3194060, 55.2595360],
          [-2.3194820, 55.2595410],
          [-2.3195460, 55.2595290],
          [-2.3196170, 55.2595100],
          [-2.3196910, 55.2594950],
          [-2.3197660, 55.2594800],
          [-2.3198400, 55.2594680],
          [-2.3199120, 55.2594570],
          [-2.3199800, 55.2594420],
          [-2.3200440, 55.2594230],
          [-2.3201020, 55.2594080],
          [-2.3201600, 55.2593890],
          [-2.3202180, 55.2593690],
          [-2.3202740, 55.2593500],
          [-2.3203310, 55.2593230],
          [-2.3203910, 55.2593010],
          [-2.3204550, 55.2592780],
          [-2.3205160, 55.2592550],
          [-2.3205770, 55.2592320],
          [-2.3206410, 55.2592170],
          [-2.3206990, 55.2592050],
          [-2.3207630, 55.2591970],
          [-2.3208300, 55.2591930],
          [-2.3209000, 55.2591970],
          [-2.3209730, 55.2592010],
          [-2.3210390, 55.2592050],
          [-2.3210980, 55.2592090],
          [-2.3211580, 55.2592090],
          [-2.3212130, 55.2592120],
          [-2.3212740, 55.2592090],
          [-2.3213330, 55.2592120],
          [-2.3213900, 55.2592200],
          [-2.3214510, 55.2592280],
          [-2.3215130, 55.2592310],
          [-2.3215730, 55.2592350],
          [-2.3216270, 55.2592350],
          [-2.3216920, 55.2592240],
          [-2.3217440, 55.2592280],
          [-2.3217990, 55.2592350],
          [-2.3218490, 55.2592390],
          [-2.3218990, 55.2592430],
          [-2.3219480, 55.2592470],
          [-2.3220050, 55.2592350],
          [-2.3220540, 55.2592350],
          [-2.3221080, 55.2592350],
          [-2.3221580, 55.2592350],
          [-2.3222110, 55.2592320],
          [-2.3222630, 55.2592320],
          [-2.3223210, 55.2592280],
          [-2.3223820, 55.2592280],
          [-2.3224490, 55.2592320],
          [-2.3225100, 55.2592390],
          [-2.3225740, 55.2592430],
          [-2.3226390, 55.2592430],
          [-2.3227010, 55.2592390],
          [-2.3227660, 55.2592430],
          [-2.3228530, 55.2592240],
          [-2.3229270, 55.2592240],
          [-2.3229940, 55.2592390],
          [-2.3230520, 55.2592430],
          [-2.3231240, 55.2592540],
          [-2.3231860, 55.2592540],
          [-2.3232620, 55.2592430],
          [-2.3233270, 55.2592500],
          [-2.3233890, 55.2592540],
          [-2.3234610, 55.2592580],
          [-2.3235260, 55.2592550],
          [-2.3235910, 55.2592620],
          [-2.3236690, 55.2592810],
          [-2.3237360, 55.2592890],
          [-2.3238040, 55.2592960],
          [-2.3238770, 55.2593110],
          [-2.3239610, 55.2593150],
          [-2.3240390, 55.2593080],
          [-2.3241220, 55.2593040],
          [-2.3241960, 55.2593000],
          [-2.3242580, 55.2593000],
          [-2.3243230, 55.2593040],
          [-2.3243830, 55.2593000],
          [-2.3244450, 55.2593040],
          [-2.3245140, 55.2593120],
          [-2.3245740, 55.2593150],
          [-2.3246420, 55.2593190],
          [-2.3247150, 55.2593270],
          [-2.3247930, 55.2593420],
          [-2.3248640, 55.2593540],
          [-2.3249370, 55.2593540],
          [-2.3250110, 55.2593570],
          [-2.3250790, 55.2593650],
          [-2.3251440, 55.2593690],
          [-2.3252040, 55.2593760],
          [-2.3252620, 55.2593800],
          [-2.3253180, 55.2593840],
          [-2.3253740, 55.2593840],
          [-2.3254370, 55.2593840],
          [-2.3255020, 55.2593920],
          [-2.3255710, 55.2593920],
          [-2.3256410, 55.2593880],
          [-2.3257070, 55.2593840],
          [-2.3257690, 55.2593840],
          [-2.3258360, 55.2593880],
          [-2.3259030, 55.2593880],
          [-2.3259730, 55.2593920],
          [-2.3260380, 55.2593920],
          [-2.3261050, 55.2593960],
          [-2.3261720, 55.2593990],
          [-2.3262410, 55.2594030],
          [-2.3263040, 55.2594070],
          [-2.3263640, 55.2594150],
          [-2.3264220, 55.2594180],
          [-2.3264820, 55.2594180],
          [-2.3265420, 55.2594260],
          [-2.3266020, 55.2594300],
          [-2.3266590, 55.2594300],
          [-2.3267190, 55.2594300],
          [-2.3267810, 55.2594370],
          [-2.3268440, 55.2594450],
          [-2.3269040, 55.2594520],
          [-2.3269680, 55.2594570],
          [-2.3270260, 55.2594600],
          [-2.3270840, 55.2594640],
          [-2.3271430, 55.2594720],
          [-2.3272060, 55.2594720],
          [-2.3272710, 55.2594760],
          [-2.3273310, 55.2594790],
          [-2.3273910, 55.2594830],
          [-2.3274520, 55.2594830],
          [-2.3275140, 55.2594870],
          [-2.3275830, 55.2594910],
          [-2.3276540, 55.2594950],
          [-2.3277250, 55.2594950],
          [-2.3277960, 55.2595020],
          [-2.3278660, 55.2595060],
          [-2.3279360, 55.2595100],
          [-2.3280020, 55.2595100],
          [-2.3280680, 55.2595100],
          [-2.3281340, 55.2595180],
          [-2.3282000, 55.2595210],
          [-2.3282690, 55.2595290],
          [-2.3283370, 55.2595370],
          [-2.3284040, 55.2595410],
          [-2.3284720, 55.2595480],
          [-2.3285420, 55.2595590],
          [-2.3286050, 55.2595630],
          [-2.3286800, 55.2595710],
          [-2.3287430, 55.2595780],
          [-2.3288110, 55.2595900],
          [-2.3288780, 55.2595940],
          [-2.3289490, 55.2596010],
          [-2.3290220, 55.2596090],
          [-2.3290930, 55.2596170],
          [-2.3291660, 55.2596210],
          [-2.3292380, 55.2596280],
          [-2.3293100, 55.2596280],
          [-2.3293800, 55.2596320],
          [-2.3294520, 55.2596320],
          [-2.3295210, 55.2596320],
          [-2.3295870, 55.2596360],
          [-2.3296530, 55.2596400],
          [-2.3297180, 55.2596440],
          [-2.3297860, 55.2596440],
          [-2.3298530, 55.2596470],
          [-2.3299240, 55.2596510],
          [-2.3299920, 55.2596550],
          [-2.3300590, 55.2596550],
          [-2.3301310, 55.2596510],
          [-2.3301980, 55.2596550],
          [-2.3302670, 55.2596550],
          [-2.3303350, 55.2596590],
          [-2.3304040, 55.2596630],
          [-2.3304720, 55.2596660],
          [-2.3305370, 55.2596660],
          [-2.3306030, 55.2596740],
          [-2.3306630, 55.2596810],
          [-2.3307300, 55.2596850],
          [-2.3308000, 55.2596890],
          [-2.3308700, 55.2596930],
          [-2.3309390, 55.2596930],
          [-2.3310070, 55.2596930],
          [-2.3310740, 55.2596970],
          [-2.3311370, 55.2597010],
          [-2.3312000, 55.2597040],
          [-2.3312640, 55.2597050],
          [-2.3313300, 55.2597050],
          [-2.3313980, 55.2597010],
          [-2.3314650, 55.2597010],
          [-2.3315290, 55.2597050],
          [-2.3315970, 55.2597080],
          [-2.3316670, 55.2597120],
          [-2.3317350, 55.2597120],
          [-2.3318040, 55.2597160],
          [-2.3318730, 55.2597200],
          [-2.3319440, 55.2597200],
          [-2.3320190, 55.2597240],
          [-2.3320920, 55.2597270],
          [-2.3321620, 55.2597310],
          [-2.3322330, 55.2597350],
          [-2.3323040, 55.2597390],
          [-2.3323720, 55.2597430],
          [-2.3324390, 55.2597460],
          [-2.3325020, 55.2597540],
          [-2.3325740, 55.2597580],
          [-2.3326390, 55.2597580],
          [-2.3327090, 55.2597620],
          [-2.3327730, 55.2597620],
          [-2.3328460, 55.2597690],
          [-2.3329190, 55.2597690],
          [-2.3329910, 55.2597770],
          [-2.3330610, 55.2597810],
          [-2.3331260, 55.2597810],
          [-2.3331910, 55.2597850],
          [-2.3332520, 55.2597880],
          [-2.3333220, 55.2597960],
          [-2.3333800, 55.2598000],
          [-2.3334420, 55.2598070],
          [-2.3335170, 55.2598150],
          [-2.3335990, 55.2598150],
          [-2.3336670, 55.2598230],
          [-2.3337400, 55.2598270],
          [-2.3338120, 55.2598300],
          [-2.3338810, 55.2598300],
          [-2.3339500, 55.2598340],
          [-2.3340190, 55.2598380],
          [-2.3340850, 55.2598380],
          [-2.3341490, 55.2598420],
          [-2.3342150, 55.2598460],
          [-2.3342860, 55.2598490],
          [-2.3343620, 55.2598570],
          [-2.3344420, 55.2598650],
          [-2.3345230, 55.2598720],
          [-2.3345940, 55.2598760],
          [-2.3346660, 55.2598800],
          [-2.3347380, 55.2598800],
          [-2.3348140, 55.2598840],
          [-2.3348920, 55.2598910],
          [-2.3349760, 55.2598920],
          [-2.3350550, 55.2598920],
          [-2.3351370, 55.2598880],
          [-2.3352190, 55.2598910],
          [-2.3352990, 55.2598920],
          [-2.3353780, 55.2598990],
          [-2.3354570, 55.2599070],
          [-2.3355470, 55.2599110],
          [-2.3356330, 55.2599140],
          [-2.3357240, 55.2599140],
          [-2.3358060, 55.2599220],
          [-2.3358940, 55.2599260],
          [-2.3359790, 55.2599300],
          [-2.3360690, 55.2599330],
          [-2.3361540, 55.2599450],
          [-2.3362390, 55.2599560],
          [-2.3363280, 55.2599640],
          [-2.3364170, 55.2599720],
          [-2.3365020, 55.2599790],
          [-2.3365910, 55.2599870],
          [-2.3366790, 55.2599980],
          [-2.3367740, 55.2600060],
          [-2.3368640, 55.2600140],
          [-2.3369510, 55.2600210],
          [-2.3370410, 55.2600290],
          [-2.3371330, 55.2600360],
          [-2.3372240, 55.2600480],
          [-2.3373170, 55.2600590],
          [-2.3374100, 55.2600670],
          [-2.3374990, 55.2600780],
          [-2.3375940, 55.2600900],
          [-2.3376840, 55.2601010],
          [-2.3377780, 55.2601120],
          [-2.3378750, 55.2601280],
          [-2.3379760, 55.2601390],
          [-2.3380810, 55.2601550],
          [-2.3381840, 55.2601700],
          [-2.3382970, 55.2601890],
          [-2.3384180, 55.2602040],
          [-2.3385450, 55.2602270],
          [-2.3386630, 55.2602490],
          [-2.3387900, 55.2602690],
          [-2.3389040, 55.2602910],
          [-2.3390290, 55.2603180],
          [-2.3391550, 55.2603450],
          [-2.3392810, 55.2603720],
          [-2.3394120, 55.2603990],
          [-2.3395420, 55.2604220],
          [-2.3396650, 55.2604480],
          [-2.3397890, 55.2604790],
          [-2.3399050, 55.2605090],
          [-2.3400280, 55.2605320],
          [-2.3401470, 55.2605550],
          [-2.3402680, 55.2605780],
          [-2.3403850, 55.2605970],
          [-2.3405000, 55.2606200],
          [-2.3406140, 55.2606420],
          [-2.3407360, 55.2606580],
          [-2.3408500, 55.2606730],
          [-2.3409630, 55.2606880],
          [-2.3410720, 55.2607040],
          [-2.3411820, 55.2607190],
          [-2.3412950, 55.2607340],
          [-2.3414080, 55.2607500],
          [-2.3415170, 55.2607650],
          [-2.3416240, 55.2607880],
          [-2.3417300, 55.2608100],
          [-2.3418420, 55.2608300],
          [-2.3419540, 55.2608560],
          [-2.3420680, 55.2608830],
          [-2.3421800, 55.2609090],
          [-2.3422970, 55.2609360],
          [-2.3424070, 55.2609630],
          [-2.3425190, 55.2609940],
          [-2.3426270, 55.2610200],
          [-2.3427380, 55.2610510],
          [-2.3428460, 55.2610770],
          [-2.3429560, 55.2611080],
          [-2.3430630, 55.2611380],
          [-2.3431770, 55.2611690],
          [-2.3432920, 55.2611960],
          [-2.3434050, 55.2612260],
          [-2.3435220, 55.2612530],
          [-2.3436440, 55.2612800],
          [-2.3437620, 55.2613090],
          [-2.3438880, 55.2613410],
          [-2.3440120, 55.2613710],
          [-2.3441450, 55.2614020],
          [-2.3442770, 55.2614360],
          [-2.3444020, 55.2614780],
          [-2.3445240, 55.2615270],
          [-2.3446470, 55.2615850],
          [-2.3447970, 55.2616420],
          [-2.3449320, 55.2617030],
          [-2.3450600, 55.2617600],
          [-2.3451970, 55.2618210],
          [-2.3453430, 55.2618860],
          [-2.3454990, 55.2619510],
          [-2.3456280, 55.2620280],
          [-2.3457350, 55.2621140],
          [-2.3458170, 55.2622100],
          [-2.3459090, 55.2622900],
          [-2.3460130, 55.2623550],
          [-2.3461110, 55.2624190],
          [-2.3462170, 55.2624770],
          [-2.3463500, 55.2625190],
          [-2.3464920, 55.2625200],
          [-2.3466350, 55.2624970],
          [-2.3467670, 55.2624590],
          [-2.3468880, 55.2624140],
          [-2.3470040, 55.2623600],
          [-2.3471130, 55.2623120],
          [-2.3472300, 55.2622650],
          [-2.3473480, 55.2622310],
          [-2.3474660, 55.2622040],
          [-2.3475950, 55.2621810],
          [-2.3477250, 55.2621730],
          [-2.3478520, 55.2621690],
          [-2.3479860, 55.2621690],
          [-2.3481140, 55.2621730],
          [-2.3482430, 55.2621760],
          [-2.3483680, 55.2621770],
          [-2.3485000, 55.2621800],
          [-2.3486240, 55.2621880],
          [-2.3487520, 55.2621920],
          [-2.3488740, 55.2621960],
          [-2.3489950, 55.2621990],
          [-2.3491150, 55.2622030],
          [-2.3492440, 55.2622030],
          [-2.3493630, 55.2622030],
          [-2.3494860, 55.2622070],
          [-2.3496060, 55.2622110],
          [-2.3497260, 55.2622110],
          [-2.3498440, 55.2622150],
          [-2.3499660, 55.2622180],
          [-2.3500890, 55.2622180],
          [-2.3502130, 55.2622180],
          [-2.3503370, 55.2622180],
          [-2.3504560, 55.2622220],
          [-2.3505710, 55.2622260],
          [-2.3506880, 55.2622300],
          [-2.3508010, 55.2622340],
          [-2.3509200, 55.2622370],
          [-2.3510410, 55.2622410],
          [-2.3511640, 55.2622450],
          [-2.3512830, 55.2622520],
          [-2.3514080, 55.2622720],
          [-2.3515240, 55.2622940],
          [-2.3516370, 55.2623290],
          [-2.3517490, 55.2623670],
          [-2.3518620, 55.2624050],
          [-2.3519750, 55.2624460],
          [-2.3520900, 55.2624930],
          [-2.3521950, 55.2625380],
          [-2.3522930, 55.2625880],
          [-2.3523910, 55.2626410],
          [-2.3524910, 55.2626870],
          [-2.3525970, 55.2627330],
          [-2.3527120, 55.2627790],
          [-2.3528220, 55.2628240],
          [-2.3529370, 55.2628700],
          [-2.3530540, 55.2629080],
          [-2.3531780, 55.2629500],
          [-2.3533000, 55.2629880],
          [-2.3534180, 55.2630300],
          [-2.3535330, 55.2630720],
          [-2.3536450, 55.2631140],
          [-2.3537540, 55.2631600],
          [-2.3538540, 55.2632110],
          [-2.3539610, 55.2632660],
          [-2.3540600, 55.2633240],
          [-2.3541490, 55.2633770],
          [-2.3542390, 55.2634350],
          [-2.3543310, 55.2634840],
          [-2.3544340, 55.2635380],
          [-2.3545370, 55.2635910],
          [-2.3546480, 55.2636330],
          [-2.3547670, 55.2636710],
          [-2.3548840, 55.2637100],
          [-2.3549920, 55.2637510],
          [-2.3551040, 55.2637900],
          [-2.3552090, 55.2638240],
          [-2.3553230, 55.2638470],
          [-2.3554400, 55.2638700],
          [-2.3555540, 55.2638820],
          [-2.3556610, 55.2638930],
          [-2.3557610, 55.2638930],
          [-2.3558500, 55.2638930],
          [-2.3559370, 55.2638890],
          [-2.3560210, 55.2638860],
          [-2.3561030, 55.2638820],
          [-2.3561850, 55.2638780],
          [-2.3562640, 55.2638780],
          [-2.3563370, 55.2638740],
          [-2.3564120, 55.2638700],
          [-2.3564870, 55.2638630],
          [-2.3565610, 55.2638630],
          [-2.3566360, 55.2638590],
          [-2.3567090, 55.2638550],
          [-2.3567870, 55.2638550],
          [-2.3568690, 55.2638590],
          [-2.3569420, 55.2638740],
          [-2.3570150, 55.2638930],
          [-2.3570870, 55.2639190],
          [-2.3571560, 55.2639500],
          [-2.3572160, 55.2639840],
          [-2.3572750, 55.2640150],
          [-2.3573360, 55.2640450],
          [-2.3574010, 55.2640760],
          [-2.3574660, 55.2641030],
          [-2.3575300, 55.2641280],
          [-2.3576010, 55.2641480],
          [-2.3576700, 55.2641710],
          [-2.3577410, 55.2641940],
          [-2.3578090, 55.2642170],
          [-2.3578760, 55.2642360],
          [-2.3579380, 55.2642590],
          [-2.3579980, 55.2642780],
          [-2.3580560, 55.2642930],
          [-2.3581080, 55.2643120],
          [-2.3581590, 55.2643320],
          [-2.3582080, 55.2643430],
          [-2.3582540, 55.2643620],
          [-2.3583010, 55.2643810],
          [-2.3583530, 55.2643960],
          [-2.3584020, 55.2644080],
          [-2.3584500, 55.2644230],
          [-2.3585050, 55.2644380],
          [-2.3585630, 55.2644500],
          [-2.3586170, 55.2644610],
          [-2.3586680, 55.2644770],
          [-2.3587190, 55.2644920],
          [-2.3587680, 55.2645070],
          [-2.3588180, 55.2645260],
          [-2.3588780, 55.2645410],
          [-2.3589430, 55.2645570],
          [-2.3590110, 55.2645680],
          [-2.3590770, 55.2645870],
          [-2.3591440, 55.2646060],
          [-2.3592220, 55.2646220],
          [-2.3592940, 55.2646370],
          [-2.3593660, 55.2646520],
          [-2.3594390, 55.2646670],
          [-2.3595070, 55.2646860],
          [-2.3595720, 55.2647010],
          [-2.3596360, 55.2647210],
          [-2.3597000, 55.2647400],
          [-2.3597670, 55.2647550],
          [-2.3598310, 55.2647820],
          [-2.3598940, 55.2648050],
          [-2.3599570, 55.2648200],
          [-2.3600210, 55.2648390],
          [-2.3600870, 55.2648580],
          [-2.3601510, 55.2648800],
          [-2.3602170, 55.2649000],
          [-2.3602830, 55.2649190],
          [-2.3603470, 55.2649340],
          [-2.3604060, 55.2649500],
          [-2.3604650, 55.2649680],
          [-2.3605260, 55.2649910],
          [-2.3605860, 55.2650180],
          [-2.3606490, 55.2650410],
          [-2.3607150, 55.2650640],
          [-2.3607860, 55.2650870],
          [-2.3608590, 55.2651130],
          [-2.3609340, 55.2651400],
          [-2.3610180, 55.2651630],
          [-2.3610970, 55.2651900],
          [-2.3611660, 55.2652160],
          [-2.3612360, 55.2652390],
          [-2.3613030, 55.2652580],
          [-2.3613640, 55.2652810],
          [-2.3614230, 55.2653010],
          [-2.3614790, 55.2653190],
          [-2.3615420, 55.2653390],
          [-2.3616050, 55.2653610],
          [-2.3616690, 55.2653840],
          [-2.3617360, 55.2654030],
          [-2.3618020, 55.2654260],
          [-2.3618650, 55.2654450],
          [-2.3619480, 55.2654570],
          [-2.3620340, 55.2654650],
          [-2.3621140, 55.2654800],
          [-2.3621920, 55.2654910],
          [-2.3622630, 55.2655100],
          [-2.3623290, 55.2655290],
          [-2.3623920, 55.2655480],
          [-2.3624540, 55.2655670],
          [-2.3625130, 55.2655870],
          [-2.3625660, 55.2656010],
          [-2.3626190, 55.2656240],
          [-2.3626640, 55.2656510],
          [-2.3627080, 55.2656740],
          [-2.3627510, 55.2657050],
          [-2.3627870, 55.2657390],
          [-2.3628160, 55.2657730],
          [-2.3628380, 55.2658110],
          [-2.3628560, 55.2658500],
          [-2.3628710, 55.2658880],
          [-2.3628850, 55.2659290],
          [-2.3628950, 55.2659790],
          [-2.3629050, 55.2660240],
          [-2.3629170, 55.2660780],
          [-2.3629320, 55.2661320],
          [-2.3629500, 55.2661890],
          [-2.3629710, 55.2662460],
          [-2.3629950, 55.2663030],
          [-2.3630160, 55.2663700],
          [-2.3630400, 55.2664510],
          [-2.3630660, 55.2665360],
          [-2.3630930, 55.2666270],
          [-2.3631180, 55.2667190],
          [-2.3631480, 55.2668030],
          [-2.3631750, 55.2668770],
          [-2.3631980, 55.2669590],
          [-2.3632230, 55.2670360],
          [-2.3632450, 55.2671120],
          [-2.3632690, 55.2671830],
          [-2.3632960, 55.2672530],
          [-2.3633190, 55.2673260],
          [-2.3633440, 55.2673930],
          [-2.3633660, 55.2674580],
          [-2.3633910, 55.2675160],
          [-2.3634060, 55.2675800],
          [-2.3634150, 55.2676420],
          [-2.3634260, 55.2677070],
          [-2.3634410, 55.2677750],
          [-2.3634560, 55.2678460],
          [-2.3634750, 55.2679200],
          [-2.3634960, 55.2679960],
          [-2.3635160, 55.2680730],
          [-2.3635330, 55.2681520],
          [-2.3635500, 55.2682290],
          [-2.3635680, 55.2683050],
          [-2.3635820, 55.2683740],
          [-2.3636000, 55.2684420],
          [-2.3636160, 55.2685080],
          [-2.3636310, 55.2685680],
          [-2.3636460, 55.2686330],
          [-2.3636630, 55.2686980],
          [-2.3636800, 55.2687630],
          [-2.3636980, 55.2688290],
          [-2.3637210, 55.2688930],
          [-2.3637450, 55.2689540],
          [-2.3637650, 55.2690150],
          [-2.3637830, 55.2690800],
          [-2.3638000, 55.2691410],
          [-2.3638160, 55.2692050],
          [-2.3638330, 55.2692710],
          [-2.3638520, 55.2693400],
          [-2.3638660, 55.2694010],
          [-2.3638800, 55.2694540],
          [-2.3638980, 55.2695080],
          [-2.3639110, 55.2695560],
          [-2.3639250, 55.2696030],
          [-2.3639380, 55.2696450],
          [-2.3639500, 55.2696830],
          [-2.3639580, 55.2697250],
          [-2.3639700, 55.2697670],
          [-2.3639820, 55.2698090],
          [-2.3639920, 55.2698510],
          [-2.3640030, 55.2698930],
          [-2.3640130, 55.2699310],
          [-2.3640230, 55.2699680],
          [-2.3640330, 55.2700040],
          [-2.3640430, 55.2700380],
          [-2.3640500, 55.2700720],
          [-2.3640610, 55.2701070],
          [-2.3640710, 55.2701410],
          [-2.3640820, 55.2701750],
          [-2.3640910, 55.2702090],
          [-2.3640980, 55.2702430],
          [-2.3641050, 55.2702780],
          [-2.3641100, 55.2703160],
          [-2.3641180, 55.2703510],
          [-2.3641300, 55.2703890],
          [-2.3641410, 55.2704270],
          [-2.3641530, 55.2704650],
          [-2.3641670, 55.2705110],
          [-2.3641860, 55.2705570],
          [-2.3642060, 55.2706060],
          [-2.3642210, 55.2706590],
          [-2.3642330, 55.2707210],
          [-2.3642460, 55.2707780],
          [-2.3642610, 55.2708310],
          [-2.3642780, 55.2708880],
          [-2.3642980, 55.2709380],
          [-2.3643150, 55.2709880],
          [-2.3643280, 55.2710370],
          [-2.3643400, 55.2710870],
          [-2.3643550, 55.2711320],
          [-2.3643730, 55.2711860],
          [-2.3643910, 55.2712400],
          [-2.3644100, 55.2713010],
          [-2.3644310, 55.2713620],
          [-2.3644480, 55.2714230],
          [-2.3644680, 55.2714870],
          [-2.3644930, 55.2715600],
          [-2.3645180, 55.2716320],
          [-2.3645460, 55.2717080],
          [-2.3645760, 55.2717850],
          [-2.3646010, 55.2718600],
          [-2.3646260, 55.2719370],
          [-2.3646500, 55.2720140],
          [-2.3646750, 55.2720860],
          [-2.3647030, 55.2721620],
          [-2.3647310, 55.2722390],
          [-2.3647600, 55.2723120],
          [-2.3647900, 55.2723840],
          [-2.3648140, 55.2724520],
          [-2.3648350, 55.2725250],
          [-2.3648580, 55.2725960],
          [-2.3648860, 55.2726620],
          [-2.3649160, 55.2727350],
          [-2.3649430, 55.2728030],
          [-2.3649710, 55.2728740],
          [-2.3650000, 55.2729560],
          [-2.3650280, 55.2730360],
          [-2.3650560, 55.2731230],
          [-2.3650820, 55.2732060],
          [-2.3651060, 55.2732980],
          [-2.3651310, 55.2733850],
          [-2.3651530, 55.2734890],
          [-2.3651760, 55.2735880],
          [-2.3652050, 55.2736920],
          [-2.3652280, 55.2737960],
          [-2.3652560, 55.2739060],
          [-2.3652830, 55.2740150],
          [-2.3653180, 55.2741150],
          [-2.3653550, 55.2742070],
          [-2.3653930, 55.2742910],
          [-2.3654280, 55.2743940],
          [-2.3654480, 55.2744850],
          [-2.3654780, 55.2745770],
          [-2.3655050, 55.2746680],
          [-2.3655310, 55.2747560],
          [-2.3655630, 55.2748430],
          [-2.3655950, 55.2749240],
          [-2.3655930, 55.2750080],
          [-2.3655550, 55.2750830],
          [-2.3654730, 55.2751420],
          [-2.3653540, 55.2751840],
          [-2.3652180, 55.2752180],
          [-2.3650690, 55.2752490],
          [-2.3649220, 55.2752800],
          [-2.3647710, 55.2753140],
          [-2.3646210, 55.2753440],
          [-2.3644760, 55.2753750],
          [-2.3643300, 55.2754090],
          [-2.3641760, 55.2754400],
          [-2.3640240, 55.2754780],
          [-2.3638700, 55.2755120],
          [-2.3637100, 55.2755430],
          [-2.3635480, 55.2755690],
          [-2.3633860, 55.2756000],
          [-2.3632200, 55.2756340],
          [-2.3630540, 55.2756650],
          [-2.3628890, 55.2756950],
          [-2.3627160, 55.2757290],
          [-2.3625420, 55.2757600],
          [-2.3623660, 55.2757870],
          [-2.3621990, 55.2758170],
          [-2.3620220, 55.2758440],
          [-2.3618430, 55.2758750],
          [-2.3616670, 55.2759010],
          [-2.3614960, 55.2759170],
          [-2.3613180, 55.2759360],
          [-2.3611280, 55.2759510],
          [-2.3609290, 55.2759740],
          [-2.3607250, 55.2759930],
          [-2.3605210, 55.2760120],
          [-2.3603110, 55.2760310],
          [-2.3601050, 55.2760540],
          [-2.3598880, 55.2760730],
          [-2.3596750, 55.2760880],
          [-2.3594650, 55.2761000],
          [-2.3592540, 55.2761110],
          [-2.3590550, 55.2761340],
          [-2.3588490, 55.2761600],
          [-2.3586420, 55.2761910],
          [-2.3584420, 55.2762180],
          [-2.3582500, 55.2762480],
          [-2.3580610, 55.2762720],
          [-2.3578740, 55.2762980],
          [-2.3576950, 55.2763280],
          [-2.3574950, 55.2763550],
          [-2.3573240, 55.2763930],
          [-2.3571500, 55.2764390],
          [-2.3569690, 55.2764810],
          [-2.3567990, 55.2765190],
          [-2.3566280, 55.2765760],
          [-2.3564600, 55.2766260],
          [-2.3562920, 55.2766790],
          [-2.3561240, 55.2767330],
          [-2.3559560, 55.2767900],
          [-2.3557800, 55.2768400],
          [-2.3556020, 55.2768820],
          [-2.3554300, 55.2769200],
          [-2.3552570, 55.2769690],
          [-2.3550900, 55.2770110],
          [-2.3549250, 55.2770570],
          [-2.3547700, 55.2770910],
          [-2.3546250, 55.2771280],
          [-2.3544780, 55.2771640],
          [-2.3543340, 55.2771980],
          [-2.3541960, 55.2772330],
          [-2.3540590, 55.2772630],
          [-2.3539220, 55.2772940],
          [-2.3537840, 55.2773240],
          [-2.3536420, 55.2773550],
          [-2.3535030, 55.2773850],
          [-2.3533610, 55.2774150],
          [-2.3532160, 55.2774460],
          [-2.3530710, 55.2774770],
          [-2.3529330, 55.2775110],
          [-2.3527970, 55.2775490],
          [-2.3526710, 55.2775870],
          [-2.3525480, 55.2776330],
          [-2.3524390, 55.2776850],
          [-2.3523290, 55.2777390],
          [-2.3522190, 55.2777990],
          [-2.3521040, 55.2778580],
          [-2.3519910, 55.2779290],
          [-2.3518700, 55.2779980],
          [-2.3517500, 55.2780670],
          [-2.3516240, 55.2781430],
          [-2.3514930, 55.2782160],
          [-2.3513600, 55.2782930],
          [-2.3512360, 55.2783680],
          [-2.3511080, 55.2784340],
          [-2.3509870, 55.2785020],
          [-2.3508690, 55.2785650],
          [-2.3507370, 55.2786440],
          [-2.3506150, 55.2787150],
          [-2.3504830, 55.2787890],
          [-2.3503450, 55.2788650],
          [-2.3502280, 55.2789330],
          [-2.3501050, 55.2790020],
          [-2.3499780, 55.2790660],
          [-2.3498550, 55.2791360],
          [-2.3497330, 55.2792080],
          [-2.3496510, 55.2792520],
          [-2.3494830, 55.2793400],
          [-2.3493560, 55.2793900],
          [-2.3492080, 55.2794300],
          [-2.3490590, 55.2794540],
          [-2.3489090, 55.2794780],
          [-2.3487590, 55.2795020],
          [-2.3486000, 55.2795400],
          [-2.3484510, 55.2795720],
          [-2.3483070, 55.2795980],
          [-2.3481540, 55.2796100],
          [-2.3480260, 55.2796320],
          [-2.3479080, 55.2796550],
          [-2.3477870, 55.2796820],
          [-2.3476620, 55.2797050],
          [-2.3475370, 55.2797200],
          [-2.3474040, 55.2797320],
          [-2.3472740, 55.2797390],
          [-2.3471430, 55.2797430],
          [-2.3470090, 55.2797390],
          [-2.3468710, 55.2797320],
          [-2.3467300, 55.2797240],
          [-2.3465890, 55.2797170],
          [-2.3464460, 55.2797130],
          [-2.3463010, 55.2797050],
          [-2.3461570, 55.2796970],
          [-2.3460130, 55.2796900],
          [-2.3458680, 55.2796820],
          [-2.3457230, 55.2796780],
          [-2.3455780, 55.2796710],
          [-2.3454330, 55.2796670],
          [-2.3452910, 55.2796630],
          [-2.3451480, 55.2796630],
          [-2.3450060, 55.2796630],
          [-2.3448650, 55.2796670],
          [-2.3447230, 55.2796670],
          [-2.3445790, 55.2796710],
          [-2.3444390, 55.2796780],
          [-2.3442850, 55.2796820],
          [-2.3441360, 55.2796900],
          [-2.3439840, 55.2796970],
          [-2.3438300, 55.2797010],
          [-2.3436730, 55.2797090],
          [-2.3435170, 55.2797160],
          [-2.3433610, 55.2797200],
          [-2.3432000, 55.2797240],
          [-2.3430410, 55.2797280],
          [-2.3428820, 55.2797320],
          [-2.3427190, 55.2797360],
          [-2.3425570, 55.2797430],
          [-2.3423870, 55.2797470],
          [-2.3422030, 55.2797550],
          [-2.3420240, 55.2797660],
          [-2.3418430, 55.2797700],
          [-2.3416530, 55.2797770],
          [-2.3414610, 55.2797810],
          [-2.3412570, 55.2797810],
          [-2.3410550, 55.2797850],
          [-2.3408520, 55.2797850],
          [-2.3406480, 55.2797850],
          [-2.3404340, 55.2797740],
          [-2.3402040, 55.2797550],
          [-2.3399830, 55.2797320],
          [-2.3397660, 55.2797090],
          [-2.3395470, 55.2796900],
          [-2.3393180, 55.2796710],
          [-2.3390940, 55.2796560],
          [-2.3388610, 55.2796400],
          [-2.3386350, 55.2796250],
          [-2.3384060, 55.2796140],
          [-2.3381710, 55.2795910],
          [-2.3379360, 55.2795680],
          [-2.3377050, 55.2795450],
          [-2.3374720, 55.2795220],
          [-2.3372390, 55.2794990],
          [-2.3370050, 55.2794800],
          [-2.3367680, 55.2794610],
          [-2.3365330, 55.2794500],
          [-2.3362980, 55.2794380],
          [-2.3360690, 55.2794300],
          [-2.3358570, 55.2794270],
          [-2.3356570, 55.2794380],
          [-2.3354680, 55.2794600],
          [-2.3352810, 55.2794980],
          [-2.3351040, 55.2795370],
          [-2.3349390, 55.2795820],
          [-2.3347950, 55.2796250],
          [-2.3346750, 55.2796660],
          [-2.3345760, 55.2797150],
          [-2.3344960, 55.2797620],
          [-2.3344450, 55.2798150],
          [-2.3344320, 55.2798760],
          [-2.3344550, 55.2799300],
          [-2.3344950, 55.2799790],
          [-2.3345490, 55.2800320],
          [-2.3346110, 55.2800900],
          [-2.3346750, 55.2801510],
          [-2.3347340, 55.2802040],
          [-2.3348000, 55.2802610],
          [-2.3348690, 55.2803190],
          [-2.3349340, 55.2803750],
          [-2.3349990, 55.2804360],
          [-2.3350660, 55.2804980],
          [-2.3351340, 55.2805590],
          [-2.3352040, 55.2806190],
          [-2.3352780, 55.2806810],
          [-2.3353450, 55.2807450],
          [-2.3354110, 55.2808070],
          [-2.3354770, 55.2808670],
          [-2.3355420, 55.2809290],
          [-2.3356040, 55.2809890],
          [-2.3356710, 55.2810510],
          [-2.3357380, 55.2811120],
          [-2.3358050, 55.2811720],
          [-2.3358790, 55.2812340],
          [-2.3359500, 55.2812980],
          [-2.3360210, 55.2813640],
          [-2.3360900, 55.2814280],
          [-2.3361590, 55.2814930],
          [-2.3362290, 55.2815580],
          [-2.3362950, 55.2816230],
          [-2.3363580, 55.2816870],
          [-2.3364180, 55.2817530],
          [-2.3364740, 55.2818210],
          [-2.3365230, 55.2818940],
          [-2.3365710, 55.2819740],
          [-2.3366030, 55.2820530],
          [-2.3366280, 55.2821340],
          [-2.3366440, 55.2822150],
          [-2.3366600, 55.2822980],
          [-2.3366810, 55.2823890],
          [-2.3367030, 55.2824810],
          [-2.3367240, 55.2825680],
          [-2.3367460, 55.2826550],
          [-2.3367700, 55.2827400],
          [-2.3367910, 55.2828270],
          [-2.3368150, 55.2829110],
          [-2.3368340, 55.2829930],
          [-2.3368530, 55.2830800],
          [-2.3368730, 55.2831640],
          [-2.3368900, 55.2832440],
          [-2.3369100, 55.2833280],
          [-2.3369280, 55.2834120],
          [-2.3369450, 55.2834960],
          [-2.3369650, 55.2835830],
          [-2.3369930, 55.2836670],
          [-2.3370260, 55.2837570],
          [-2.3370580, 55.2838470],
          [-2.3370880, 55.2839350],
          [-2.3371150, 55.2840260],
          [-2.3371400, 55.2841170],
          [-2.3371660, 55.2842080],
          [-2.3371960, 55.2843050],
          [-2.3372240, 55.2843940],
          [-2.3372540, 55.2844940],
          [-2.3372790, 55.2845910],
          [-2.3373010, 55.2846970],
          [-2.3373170, 55.2847920],
          [-2.3373260, 55.2849050],
          [-2.3373280, 55.2850160],
          [-2.3373230, 55.2851200],
          [-2.3373140, 55.2852440],
          [-2.3372960, 55.2853760],
          [-2.3372640, 55.2855180],
          [-2.3372300, 55.2856510],
          [-2.3372060, 55.2857960],
          [-2.3371990, 55.2859160],
          [-2.3372080, 55.2860480],
          [-2.3372220, 55.2861640],
          [-2.3372390, 55.2862760],
          [-2.3372580, 55.2863700],
          [-2.3372790, 55.2864760],
          [-2.3372940, 55.2865660],
          [-2.3373050, 55.2866660],
          [-2.3373110, 55.2867540],
          [-2.3373170, 55.2868580],
          [-2.3373200, 55.2869430],
          [-2.3373220, 55.2870440],
          [-2.3373280, 55.2871280],
          [-2.3373400, 55.2872270],
          [-2.3373480, 55.2873190],
          [-2.3373550, 55.2874140],
          [-2.3373650, 55.2874950],
          [-2.3373850, 55.2875910],
          [-2.3374070, 55.2876910],
          [-2.3374450, 55.2877850],
          [-2.3374980, 55.2878800],
          [-2.3375640, 55.2879760],
          [-2.3376290, 55.2880730],
          [-2.3377130, 55.2881840],
          [-2.3377920, 55.2882880],
          [-2.3378650, 55.2883860],
          [-2.3379470, 55.2885000],
          [-2.3380220, 55.2886040],
          [-2.3381010, 55.2887200],
          [-2.3381770, 55.2888250],
          [-2.3382650, 55.2889480],
          [-2.3383440, 55.2890620],
          [-2.3384240, 55.2891760],
          [-2.3384960, 55.2892840],
          [-2.3385680, 55.2893890],
          [-2.3386330, 55.2894910],
          [-2.3387000, 55.2895890],
          [-2.3387660, 55.2896860],
          [-2.3388360, 55.2897820],
          [-2.3389010, 55.2898700],
          [-2.3389700, 55.2899640],
          [-2.3390340, 55.2900610],
          [-2.3390970, 55.2901530],
          [-2.3391640, 55.2902510],
          [-2.3392280, 55.2903410],
          [-2.3392890, 55.2904320],
          [-2.3393520, 55.2905190],
          [-2.3394120, 55.2905980],
          [-2.3394710, 55.2906770],
          [-2.3395340, 55.2907580],
          [-2.3395990, 55.2908430],
          [-2.3396670, 55.2909340],
          [-2.3397360, 55.2910240],
          [-2.3398080, 55.2911160],
          [-2.3398770, 55.2912080],
          [-2.3399420, 55.2912960],
          [-2.3400040, 55.2913820],
          [-2.3400700, 55.2914730],
          [-2.3401350, 55.2915620],
          [-2.3402030, 55.2916560],
          [-2.3402700, 55.2917460],
          [-2.3403360, 55.2918350],
          [-2.3404040, 55.2919300],
          [-2.3404700, 55.2920230],
          [-2.3405440, 55.2921200],
          [-2.3406230, 55.2922170],
          [-2.3407010, 55.2923200],
          [-2.3407710, 55.2924200],
          [-2.3408370, 55.2925170],
          [-2.3408890, 55.2926210],
          [-2.3409210, 55.2927180],
          [-2.3409360, 55.2928140],
          [-2.3409250, 55.2928990],
          [-2.3409130, 55.2929840],
          [-2.3408900, 55.2930660],
          [-2.3408570, 55.2931650],
          [-2.3408290, 55.2932480],
          [-2.3407990, 55.2933340],
          [-2.3407670, 55.2934170],
          [-2.3407370, 55.2935040],
          [-2.3407110, 55.2935880],
          [-2.3406860, 55.2936780],
          [-2.3406630, 55.2937740],
          [-2.3406340, 55.2938730],
          [-2.3406050, 55.2939660],
          [-2.3405760, 55.2940730],
          [-2.3405490, 55.2941740],
          [-2.3405250, 55.2942790],
          [-2.3405040, 55.2943770],
          [-2.3404870, 55.2944810],
          [-2.3404670, 55.2945790],
          [-2.3404450, 55.2946750],
          [-2.3404160, 55.2947740],
          [-2.3403880, 55.2948750],
          [-2.3403590, 55.2949610],
          [-2.3403420, 55.2950650],
          [-2.3403530, 55.2951460],
          [-2.3403830, 55.2952190],
          [-2.3404150, 55.2952770],
          [-2.3404500, 55.2953330],
          [-2.3404790, 55.2953790],
          [-2.3405070, 55.2954130],
          [-2.3405360, 55.2954360],
          [-2.3405610, 55.2954480],
          [-2.3405770, 55.2954610],
          [-2.3405770, 55.2954620],
          [-2.3405770, 55.2954630],
          [-2.3405770, 55.2954650],
          [-2.3405770, 55.2954660],
          [-2.3405770, 55.2954670],
          [-2.3405780, 55.2954680],
          [-2.3405780, 55.2954690],
          [-2.3405780, 55.2954710],
          [-2.3405780, 55.2954720],
          [-2.3405780, 55.2954730],
          [-2.3405780, 55.2954740],
          [-2.3405790, 55.2954760],
          [-2.3405790, 55.2954770],
          [-2.3405790, 55.2954780],
          [-2.3405790, 55.2954790],
          [-2.3405790, 55.2954800],
          [-2.3405790, 55.2954820],
          [-2.3405800, 55.2954830],
          [-2.3405800, 55.2954840],
          [-2.3405800, 55.2954850],
          [-2.3405800, 55.2954870],
          [-2.3405800, 55.2954880],
          [-2.3405800, 55.2954890],
          [-2.3405810, 55.2954900],
          [-2.3405810, 55.2954920],
          [-2.3405810, 55.2954930],
          [-2.3405810, 55.2954940],
          [-2.3405810, 55.2954950],
          [-2.3405810, 55.2954960],
          [-2.3405810, 55.2954980],
          [-2.3405820, 55.2954990],
          [-2.3405820, 55.2955000],
          [-2.3405820, 55.2955010],
          [-2.3405820, 55.2955030],
          [-2.3405820, 55.2955040],
          [-2.3405820, 55.2955050],
          [-2.3405830, 55.2955060],
          [-2.3405830, 55.2955070],
          [-2.3405830, 55.2955090],
          [-2.3405830, 55.2955100],
          [-2.3405830, 55.2955110],
          [-2.3405830, 55.2955120],
          [-2.3405840, 55.2955140],
          [-2.3405840, 55.2955150],
          [-2.3405840, 55.2955160],
          [-2.3405840, 55.2955170],
          [-2.3405840, 55.2955190],
          [-2.3405840, 55.2955200],
          [-2.3405850, 55.2955210],
          [-2.3405850, 55.2955220],
          [-2.3405850, 55.2955230],
          [-2.3405940, 55.2955460],
          [-2.3406000, 55.2955660],
          [-2.3406020, 55.2955920],
          [-2.3405970, 55.2956270],
          [-2.3405930, 55.2956610],
          [-2.3406020, 55.2956990],
          [-2.3406180, 55.2957350],
          [-2.3406500, 55.2957760],
          [-2.3406900, 55.2958040],
          [-2.3407540, 55.2958290],
          [-2.3408250, 55.2958440],
          [-2.3409020, 55.2958490],
          [-2.3409760, 55.2958490],
          [-2.3410510, 55.2958450],
          [-2.3411240, 55.2958410],
          [-2.3412070, 55.2958380],
          [-2.3412860, 55.2958410],
          [-2.3413780, 55.2958450],
          [-2.3414640, 55.2958520],
          [-2.3415550, 55.2958560],
          [-2.3416460, 55.2958560],
          [-2.3417390, 55.2958560],
          [-2.3418370, 55.2958530],
          [-2.3419290, 55.2958490],
          [-2.3420280, 55.2958450],
          [-2.3421220, 55.2958410],
          [-2.3422140, 55.2958380],
          [-2.3423010, 55.2958340],
          [-2.3423970, 55.2958300],
          [-2.3424860, 55.2958300],
          [-2.3425620, 55.2958300],
          [-2.3426470, 55.2958270],
          [-2.3427240, 55.2958230],
          [-2.3428160, 55.2958220],
          [-2.3428920, 55.2958180],
          [-2.3429660, 55.2958180],
          [-2.3430340, 55.2958150],
          [-2.3431020, 55.2958150],
          [-2.3431660, 55.2958150],
          [-2.3432270, 55.2958110],
          [-2.3432960, 55.2958110],
          [-2.3433560, 55.2958070],
          [-2.3434190, 55.2958070],
          [-2.3434760, 55.2958030],
          [-2.3435380, 55.2957990],
          [-2.3435910, 55.2957990],
          [-2.3436480, 55.2957990],
          [-2.3436970, 55.2957960],
          [-2.3437510, 55.2957950],
          [-2.3438010, 55.2957920],
          [-2.3438500, 55.2957920],
          [-2.3438970, 55.2957920],
          [-2.3439480, 55.2957880],
          [-2.3439930, 55.2957840],
          [-2.3440430, 55.2957800],
          [-2.3440870, 55.2957770],
          [-2.3441390, 55.2957730],
          [-2.3441850, 55.2957690],
          [-2.3442370, 55.2957650],
          [-2.3442880, 55.2957580],
          [-2.3443410, 55.2957500],
          [-2.3443850, 55.2957400],
          [-2.3444420, 55.2957270],
          [-2.3444790, 55.2957180],
          [-2.3445430, 55.2957000],
          [-2.3445970, 55.2956890],
          [-2.3446510, 55.2956770],
          [-2.3447030, 55.2956630],
          [-2.3447560, 55.2956510],
          [-2.3448060, 55.2956400],
          [-2.3448580, 55.2956240],
          [-2.3449080, 55.2956130],
          [-2.3449620, 55.2955970],
          [-2.3450090, 55.2955830],
          [-2.3450640, 55.2955670],
          [-2.3451150, 55.2955560],
          [-2.3451680, 55.2955400],
          [-2.3452150, 55.2955300],
          [-2.3452660, 55.2955130],
          [-2.3453130, 55.2955020],
          [-2.3453640, 55.2954870],
          [-2.3454110, 55.2954750],
          [-2.3454600, 55.2954640],
          [-2.3455080, 55.2954530],
          [-2.3455630, 55.2954370],
          [-2.3456130, 55.2954220],
          [-2.3456640, 55.2954070],
          [-2.3457110, 55.2953920],
          [-2.3457590, 55.2953760],
          [-2.3458060, 55.2953610],
          [-2.3458610, 55.2953450],
          [-2.3459080, 55.2953340],
          [-2.3459570, 55.2953230],
          [-2.3460010, 55.2953150],
          [-2.3460540, 55.2953000],
          [-2.3461110, 55.2952850],
          [-2.3461690, 55.2952690],
          [-2.3462230, 55.2952540],
          [-2.3462800, 55.2952390],
          [-2.3463340, 55.2952240],
          [-2.3463940, 55.2952120],
          [-2.3464500, 55.2951970],
          [-2.3465020, 55.2951780],
          [-2.3465550, 55.2951640],
          [-2.3466140, 55.2951470],
          [-2.3466670, 55.2951360],
          [-2.3467240, 55.2951210],
          [-2.3467700, 55.2951060],
          [-2.3468210, 55.2950900],
          [-2.3468710, 55.2950750],
          [-2.3469160, 55.2950670],
          [-2.3469630, 55.2950520],
          [-2.3470090, 55.2950370],
          [-2.3470520, 55.2950220],
          [-2.3471000, 55.2950060],
          [-2.3471450, 55.2949920],
          [-2.3471980, 55.2949760],
          [-2.3472510, 55.2949640],
          [-2.3473040, 55.2949490],
          [-2.3473580, 55.2949340],
          [-2.3474170, 55.2949180],
          [-2.3474720, 55.2949000],
          [-2.3475270, 55.2948840],
          [-2.3475800, 55.2948690],
          [-2.3476360, 55.2948530],
          [-2.3476890, 55.2948350],
          [-2.3477410, 55.2948150],
          [-2.3477860, 55.2947970],
          [-2.3478380, 55.2947780],
          [-2.3478920, 55.2947620],
          [-2.3479450, 55.2947510],
          [-2.3479940, 55.2947360],
          [-2.3480440, 55.2947240],
          [-2.3480920, 55.2947100],
          [-2.3481450, 55.2947010],
          [-2.3481930, 55.2946900],
          [-2.3482390, 55.2946780],
          [-2.3482850, 55.2946640],
          [-2.3483410, 55.2946470],
          [-2.3483870, 55.2946330],
          [-2.3484420, 55.2946210],
          [-2.3484880, 55.2946130],
          [-2.3485370, 55.2946020],
          [-2.3485770, 55.2945950],
          [-2.3486310, 55.2945860],
          [-2.3486770, 55.2945790],
          [-2.3487300, 55.2945710],
          [-2.3487810, 55.2945600],
          [-2.3488340, 55.2945520],
          [-2.3488880, 55.2945480],
          [-2.3489400, 55.2945480],
          [-2.3489860, 55.2945480],
          [-2.3490380, 55.2945440],
          [-2.3490930, 55.2945440],
          [-2.3491490, 55.2945400],
          [-2.3492010, 55.2945370],
          [-2.3492590, 55.2945330],
          [-2.3493160, 55.2945290],
          [-2.3493760, 55.2945250],
          [-2.3494350, 55.2945220],
          [-2.3495020, 55.2945180],
          [-2.3495560, 55.2945110],
          [-2.3496220, 55.2945060],
          [-2.3496760, 55.2945030],
          [-2.3497510, 55.2944980],
          [-2.3498140, 55.2944950],
          [-2.3498840, 55.2944870],
          [-2.3499590, 55.2944830],
          [-2.3500350, 55.2944790],
          [-2.3501130, 55.2944760],
          [-2.3502010, 55.2944680],
          [-2.3502850, 55.2944640],
          [-2.3503710, 55.2944570],
          [-2.3504520, 55.2944570],
          [-2.3505480, 55.2944450],
          [-2.3506450, 55.2944380],
          [-2.3507390, 55.2944300],
          [-2.3508320, 55.2944190],
          [-2.3509270, 55.2944110],
          [-2.3510140, 55.2944070],
          [-2.3510980, 55.2944070],
          [-2.3511820, 55.2944070],
          [-2.3512540, 55.2944070],
          [-2.3513410, 55.2944070],
          [-2.3514350, 55.2944140],
          [-2.3515350, 55.2944110],
          [-2.3516370, 55.2944140],
          [-2.3517280, 55.2944110],
          [-2.3518300, 55.2944110],
          [-2.3519280, 55.2944030],
          [-2.3520340, 55.2943920],
          [-2.3521340, 55.2943810],
          [-2.3522490, 55.2943650],
          [-2.3523490, 55.2943380],
          [-2.3524700, 55.2943080],
          [-2.3525780, 55.2942870],
          [-2.3526940, 55.2942590],
          [-2.3527840, 55.2942380],
          [-2.3529100, 55.2942100],
          [-2.3530220, 55.2941870],
          [-2.3531320, 55.2941750],
          [-2.3532270, 55.2941670],
          [-2.3533260, 55.2941670],
          [-2.3534150, 55.2941670],
          [-2.3535250, 55.2941700],
          [-2.3536230, 55.2941810],
          [-2.3537240, 55.2941960],
          [-2.3538310, 55.2942180],
          [-2.3539450, 55.2942370],
          [-2.3540600, 55.2942610],
          [-2.3541670, 55.2942840],
          [-2.3542650, 55.2943020],
          [-2.3543680, 55.2943250],
          [-2.3544680, 55.2943470],
          [-2.3545810, 55.2943680],
          [-2.3546820, 55.2943940],
          [-2.3547690, 55.2944100],
          [-2.3548880, 55.2944410],
          [-2.3549790, 55.2944660],
          [-2.3550770, 55.2944940],
          [-2.3551620, 55.2945180],
          [-2.3552540, 55.2945440],
          [-2.3553760, 55.2945740],
          [-2.3554790, 55.2946000],
          [-2.3555840, 55.2946340],
          [-2.3556830, 55.2946650],
          [-2.3557750, 55.2946910],
          [-2.3558670, 55.2947220],
          [-2.3559570, 55.2947490],
          [-2.3560300, 55.2947770],
          [-2.3561080, 55.2948100],
          [-2.3561850, 55.2948360],
          [-2.3562750, 55.2948710],
          [-2.3563650, 55.2949110],
          [-2.3564680, 55.2949460],
          [-2.3565750, 55.2949800],
          [-2.3566880, 55.2950180],
          [-2.3568160, 55.2950580],
          [-2.3569370, 55.2950910],
          [-2.3570650, 55.2951190],
          [-2.3571950, 55.2951500],
          [-2.3573170, 55.2951710],
          [-2.3574550, 55.2952030],
          [-2.3575740, 55.2952210],
          [-2.3577030, 55.2952440],
          [-2.3578320, 55.2952640],
          [-2.3579610, 55.2952830],
          [-2.3580930, 55.2952990],
          [-2.3582200, 55.2953070],
          [-2.3583600, 55.2953180],
          [-2.3584920, 55.2953300],
          [-2.3586200, 55.2953370],
          [-2.3587520, 55.2953410],
          [-2.3588630, 55.2953410],
          [-2.3590030, 55.2953450],
          [-2.3591220, 55.2953520],
          [-2.3592440, 55.2953570],
          [-2.3593600, 55.2953670],
          [-2.3594800, 55.2953750],
          [-2.3595920, 55.2953790],
          [-2.3597080, 55.2953830],
          [-2.3598270, 55.2953830],
          [-2.3599450, 55.2953830],
          [-2.3600560, 55.2953830],
          [-2.3601650, 55.2953910],
          [-2.3602650, 55.2953950],
          [-2.3603650, 55.2953980],
          [-2.3604630, 55.2954060],
          [-2.3605640, 55.2954060],
          [-2.3606660, 55.2954130],
          [-2.3607590, 55.2954210],
          [-2.3608640, 55.2954220],
          [-2.3609690, 55.2954180],
          [-2.3610830, 55.2954180],
          [-2.3611930, 55.2954180],
          [-2.3613030, 55.2954180],
          [-2.3614090, 55.2954180],
          [-2.3615060, 55.2954180],
          [-2.3615880, 55.2954180],
          [-2.3616990, 55.2954210],
          [-2.3618010, 55.2954250],
          [-2.3618900, 55.2954290],
          [-2.3619840, 55.2954290],
          [-2.3620780, 55.2954330],
          [-2.3621770, 55.2954370],
          [-2.3622650, 55.2954370],
          [-2.3623640, 55.2954410],
          [-2.3624590, 55.2954410],
          [-2.3625540, 55.2954440],
          [-2.3626490, 55.2954480],
          [-2.3627460, 55.2954480],
          [-2.3628430, 55.2954520],
          [-2.3629370, 55.2954520],
          [-2.3630420, 55.2954560],
          [-2.3631470, 55.2954600],
          [-2.3632490, 55.2954640],
          [-2.3633500, 55.2954640],
          [-2.3634520, 55.2954670],
          [-2.3635540, 55.2954750],
          [-2.3636570, 55.2954750],
          [-2.3637590, 55.2954790],
          [-2.3638640, 55.2954790],
          [-2.3639670, 55.2954830],
          [-2.3640650, 55.2954860],
          [-2.3641790, 55.2954900],
          [-2.3642890, 55.2954940],
          [-2.3644020, 55.2954980],
          [-2.3645150, 55.2955020],
          [-2.3646270, 55.2955050],
          [-2.3647390, 55.2955090],
          [-2.3648580, 55.2955130],
          [-2.3649760, 55.2955210],
          [-2.3650930, 55.2955280],
          [-2.3652130, 55.2955360],
          [-2.3653340, 55.2955400],
          [-2.3654510, 55.2955440],
          [-2.3655690, 55.2955470],
          [-2.3656840, 55.2955550],
          [-2.3657980, 55.2955550],
          [-2.3659090, 55.2955590],
          [-2.3660200, 55.2955630],
          [-2.3661270, 55.2955670],
          [-2.3662330, 55.2955700],
          [-2.3663420, 55.2955740],
          [-2.3664560, 55.2955780],
          [-2.3665610, 55.2955780],
          [-2.3666800, 55.2955740],
          [-2.3667920, 55.2955700],
          [-2.3669060, 55.2955700],
          [-2.3670230, 55.2955670],
          [-2.3671440, 55.2955670],
          [-2.3672640, 55.2955630],
          [-2.3673920, 55.2955590],
          [-2.3675060, 55.2955590],
          [-2.3676400, 55.2955550],
          [-2.3677630, 55.2955550],
          [-2.3678860, 55.2955550],
          [-2.3680070, 55.2955510],
          [-2.3681280, 55.2955510],
          [-2.3682500, 55.2955510],
          [-2.3683720, 55.2955480],
          [-2.3684920, 55.2955400],
          [-2.3686120, 55.2955400],
          [-2.3687350, 55.2955250],
          [-2.3688570, 55.2955130],
          [-2.3689710, 55.2955020],
          [-2.3690880, 55.2954900],
          [-2.3691980, 55.2954750],
          [-2.3693090, 55.2954670],
          [-2.3694170, 55.2954520],
          [-2.3695220, 55.2954370],
          [-2.3696190, 55.2954220],
          [-2.3697190, 55.2953990],
          [-2.3698210, 55.2953770],
          [-2.3699300, 55.2953530],
          [-2.3700370, 55.2953230],
          [-2.3701570, 55.2952890],
          [-2.3702770, 55.2952580],
          [-2.3703940, 55.2952270],
          [-2.3705020, 55.2952010],
          [-2.3705980, 55.2951780],
          [-2.3706900, 55.2951550],
          [-2.3707770, 55.2951360],
          [-2.3708630, 55.2951200],
          [-2.3709450, 55.2950980],
          [-2.3710300, 55.2950750],
          [-2.3711130, 55.2950590],
          [-2.3711990, 55.2950400],
          [-2.3712840, 55.2950210],
          [-2.3713710, 55.2950020],
          [-2.3714600, 55.2949830],
          [-2.3715520, 55.2949600],
          [-2.3716450, 55.2949420],
          [-2.3717450, 55.2949220],
          [-2.3718440, 55.2948990],
          [-2.3719420, 55.2948800],
          [-2.3720420, 55.2948570],
          [-2.3721430, 55.2948350],
          [-2.3722440, 55.2948120],
          [-2.3723440, 55.2947890],
          [-2.3724440, 55.2947660],
          [-2.3725470, 55.2947430],
          [-2.3726480, 55.2947200],
          [-2.3727480, 55.2946930],
          [-2.3728550, 55.2946660],
          [-2.3729590, 55.2946360],
          [-2.3730640, 55.2946060],
          [-2.3731620, 55.2945800],
          [-2.3732640, 55.2945520],
          [-2.3733600, 55.2945290],
          [-2.3734570, 55.2945060],
          [-2.3735520, 55.2944830],
          [-2.3736460, 55.2944680],
          [-2.3737400, 55.2944490],
          [-2.3738370, 55.2944300],
          [-2.3739320, 55.2944150],
          [-2.3740260, 55.2943960],
          [-2.3741200, 55.2943800],
          [-2.3742150, 55.2943690],
          [-2.3743130, 55.2943540],
          [-2.3744130, 55.2943390],
          [-2.3745140, 55.2943230],
          [-2.3746160, 55.2943080],
          [-2.3747160, 55.2942930],
          [-2.3748200, 55.2942770],
          [-2.3749230, 55.2942620],
          [-2.3750270, 55.2942470],
          [-2.3751300, 55.2942280],
          [-2.3752320, 55.2942090],
          [-2.3753300, 55.2941900],
          [-2.3754280, 55.2941670],
          [-2.3755290, 55.2941440],
          [-2.3756240, 55.2941180],
          [-2.3757220, 55.2940870],
          [-2.3758210, 55.2940560],
          [-2.3759170, 55.2940260],
          [-2.3760090, 55.2939960],
          [-2.3761040, 55.2939690],
          [-2.3762010, 55.2939380],
          [-2.3762940, 55.2939130],
          [-2.3763960, 55.2938850],
          [-2.3764890, 55.2938580],
          [-2.3765800, 55.2938320],
          [-2.3766720, 55.2938050],
          [-2.3767640, 55.2937780],
          [-2.3768600, 55.2937590],
          [-2.3769550, 55.2937400],
          [-2.3770510, 55.2937210],
          [-2.3771530, 55.2937010],
          [-2.3772540, 55.2936820],
          [-2.3773620, 55.2936670],
          [-2.3774690, 55.2936520],
          [-2.3775700, 55.2936360],
          [-2.3776720, 55.2936180],
          [-2.3777790, 55.2936020],
          [-2.3778880, 55.2935910],
          [-2.3780000, 55.2935760],
          [-2.3781100, 55.2935600],
          [-2.3782160, 55.2935450],
          [-2.3783220, 55.2935260],
          [-2.3784250, 55.2935070],
          [-2.3785320, 55.2934880],
          [-2.3786350, 55.2934650],
          [-2.3787360, 55.2934430],
          [-2.3788390, 55.2934230],
          [-2.3789370, 55.2934040],
          [-2.3790350, 55.2933810],
          [-2.3791290, 55.2933540],
          [-2.3792220, 55.2933310],
          [-2.3793120, 55.2933090],
          [-2.3794040, 55.2932820],
          [-2.3794970, 55.2932550],
          [-2.3795920, 55.2932250],
          [-2.3796820, 55.2931950],
          [-2.3797760, 55.2931640],
          [-2.3798690, 55.2931290],
          [-2.3799620, 55.2930990],
          [-2.3800570, 55.2930720],
          [-2.3801480, 55.2930420],
          [-2.3802440, 55.2930110],
          [-2.3803350, 55.2929810],
          [-2.3804290, 55.2929420],
          [-2.3805220, 55.2929080],
          [-2.3806200, 55.2928700],
          [-2.3807220, 55.2928360],
          [-2.3808270, 55.2927940],
          [-2.3809350, 55.2927560],
          [-2.3810470, 55.2927140],
          [-2.3811570, 55.2926720],
          [-2.3812650, 55.2926340],
          [-2.3813690, 55.2925950],
          [-2.3814720, 55.2925570],
          [-2.3815550, 55.2925260],
          [-2.3816700, 55.2924850],
          [-2.3817640, 55.2924500],
          [-2.3818590, 55.2924120],
          [-2.3819550, 55.2923780],
          [-2.3820520, 55.2923440],
          [-2.3821430, 55.2923100],
          [-2.3822320, 55.2922750],
          [-2.3823210, 55.2922330],
          [-2.3823970, 55.2921840],
          [-2.3824670, 55.2921300],
          [-2.3825280, 55.2920670],
          [-2.3825800, 55.2919940],
          [-2.3826310, 55.2919240],
          [-2.3826840, 55.2918560],
          [-2.3827460, 55.2917870],
          [-2.3828140, 55.2917220],
          [-2.3829010, 55.2916570],
          [-2.3830050, 55.2916080],
          [-2.3831170, 55.2915690],
          [-2.3832310, 55.2915390],
          [-2.3833410, 55.2915160],
          [-2.3834590, 55.2914970],
          [-2.3835750, 55.2914810],
          [-2.3836850, 55.2914700],
          [-2.3837980, 55.2914580],
          [-2.3839140, 55.2914580],
          [-2.3840250, 55.2914510],
          [-2.3841330, 55.2914390],
          [-2.3842390, 55.2914320],
          [-2.3843390, 55.2914200],
          [-2.3844370, 55.2914090],
          [-2.3845360, 55.2914050],
          [-2.3846340, 55.2913930],
          [-2.3847270, 55.2913860],
          [-2.3848250, 55.2913740],
          [-2.3849210, 55.2913670],
          [-2.3850200, 55.2913550],
          [-2.3851190, 55.2913400],
          [-2.3852160, 55.2913320],
          [-2.3853100, 55.2913210],
          [-2.3854000, 55.2913100],
          [-2.3854920, 55.2912940],
          [-2.3855820, 55.2912830],
          [-2.3856740, 55.2912750],
          [-2.3857610, 55.2912640],
          [-2.3858500, 55.2912560],
          [-2.3859380, 55.2912490],
          [-2.3860270, 55.2912370],
          [-2.3861170, 55.2912260],
          [-2.3862120, 55.2912220],
          [-2.3863050, 55.2912140],
          [-2.3863970, 55.2912070],
          [-2.3864870, 55.2911950],
          [-2.3865790, 55.2911870],
          [-2.3866670, 55.2911800],
          [-2.3867550, 55.2911680],
          [-2.3868440, 55.2911570],
          [-2.3869320, 55.2911450],
          [-2.3870210, 55.2911340],
          [-2.3871120, 55.2911260],
          [-2.3871990, 55.2911150],
          [-2.3872840, 55.2911070],
          [-2.3873660, 55.2911000],
          [-2.3874510, 55.2910920],
          [-2.3875340, 55.2910880],
          [-2.3876140, 55.2910810],
          [-2.3876940, 55.2910730],
          [-2.3877740, 55.2910650],
          [-2.3878500, 55.2910580],
          [-2.3879270, 55.2910500],
          [-2.3880020, 55.2910420],
          [-2.3880740, 55.2910350],
          [-2.3881460, 55.2910230],
          [-2.3882150, 55.2910200],
          [-2.3882820, 55.2910120],
          [-2.3883510, 55.2910040],
          [-2.3884200, 55.2909970],
          [-2.3884810, 55.2909850],
          [-2.3885420, 55.2909780],
          [-2.3886010, 55.2909740],
          [-2.3886560, 55.2909700],
          [-2.3887150, 55.2909620],
          [-2.3887740, 55.2909550],
          [-2.3888310, 55.2909430],
          [-2.3888880, 55.2909360],
          [-2.3889450, 55.2909280],
          [-2.3890030, 55.2909170],
          [-2.3890640, 55.2909090],
          [-2.3891240, 55.2909010],
          [-2.3891860, 55.2908940],
          [-2.3892420, 55.2908900],
          [-2.3893030, 55.2908860],
          [-2.3893610, 55.2908780],
          [-2.3894170, 55.2908780],
          [-2.3894760, 55.2908740],
          [-2.3895340, 55.2908710],
          [-2.3895930, 55.2908670],
          [-2.3896500, 55.2908630],
          [-2.3897060, 55.2908590],
          [-2.3897640, 55.2908550],
          [-2.3898260, 55.2908480],
          [-2.3898840, 55.2908440],
          [-2.3899440, 55.2908360],
          [-2.3900010, 55.2908290],
          [-2.3900580, 55.2908210],
          [-2.3901140, 55.2908140],
          [-2.3901740, 55.2908020],
          [-2.3902350, 55.2907910],
          [-2.3902980, 55.2907830],
          [-2.3903560, 55.2907790],
          [-2.3904190, 55.2907790],
          [-2.3904840, 55.2907750],
          [-2.3905500, 55.2907640],
          [-2.3906190, 55.2907530],
          [-2.3906890, 55.2907450],
          [-2.3907600, 55.2907450],
          [-2.3908360, 55.2907410],
          [-2.3909110, 55.2907370],
          [-2.3909840, 55.2907370],
          [-2.3910550, 55.2907370],
          [-2.3911300, 55.2907370],
          [-2.3912060, 55.2907370],
          [-2.3912740, 55.2907410],
          [-2.3913420, 55.2907450],
          [-2.3914090, 55.2907490],
          [-2.3914730, 55.2907490],
          [-2.3915380, 55.2907560],
          [-2.3916020, 55.2907640],
          [-2.3916630, 55.2907710],
          [-2.3917240, 55.2907820],
          [-2.3917830, 55.2907900],
          [-2.3918450, 55.2907980],
          [-2.3919060, 55.2908060],
          [-2.3919660, 55.2908130],
          [-2.3920230, 55.2908210],
          [-2.3920830, 55.2908280],
          [-2.3921440, 55.2908360],
          [-2.3922030, 55.2908400],
          [-2.3922660, 55.2908510],
          [-2.3923290, 55.2908590],
          [-2.3923930, 55.2908630],
          [-2.3924610, 55.2908670],
          [-2.3925300, 55.2908710],
          [-2.3926010, 55.2908740],
          [-2.3926730, 55.2908820],
          [-2.3927500, 55.2908930],
          [-2.3928290, 55.2909050],
          [-2.3929060, 55.2909200],
          [-2.3929870, 55.2909310],
          [-2.3930680, 55.2909390],
          [-2.3931480, 55.2909510],
          [-2.3932290, 55.2909580],
          [-2.3933120, 55.2909660],
          [-2.3933940, 55.2909740],
          [-2.3934770, 55.2909850],
          [-2.3935600, 55.2909930],
          [-2.3936440, 55.2910040],
          [-2.3937340, 55.2910120],
          [-2.3938230, 55.2910270],
          [-2.3939100, 55.2910380],
          [-2.3940020, 55.2910500],
          [-2.3940950, 55.2910610],
          [-2.3941900, 55.2910730],
          [-2.3942840, 55.2910840],
          [-2.3943760, 55.2910960],
          [-2.3944650, 55.2911030],
          [-2.3945540, 55.2911140],
          [-2.3946470, 55.2911220],
          [-2.3947390, 55.2911300],
          [-2.3948290, 55.2911380],
          [-2.3949170, 55.2911450],
          [-2.3950010, 55.2911570],
          [-2.3950870, 55.2911680],
          [-2.3951710, 55.2911800],
          [-2.3952530, 55.2911870],
          [-2.3953370, 55.2911950],
          [-2.3954220, 55.2912020],
          [-2.3955050, 55.2912140],
          [-2.3955870, 55.2912220],
          [-2.3956510, 55.2912300],
          [-2.3957540, 55.2912410],
          [-2.3958370, 55.2912480],
          [-2.3959050, 55.2912570],
          [-2.3960040, 55.2912750],
          [-2.3960860, 55.2912860],
          [-2.3961700, 55.2912980],
          [-2.3962570, 55.2913130],
          [-2.3963450, 55.2913280],
          [-2.3964420, 55.2913400],
          [-2.3965390, 55.2913510],
          [-2.3966350, 55.2913660],
          [-2.3967370, 55.2913780],
          [-2.3968450, 55.2913890],
          [-2.3969580, 55.2914040],
          [-2.3970730, 55.2914160],
          [-2.3971860, 55.2914270],
          [-2.3973020, 55.2914430],
          [-2.3974190, 55.2914580],
          [-2.3975290, 55.2914730],
          [-2.3976370, 55.2914880],
          [-2.3977370, 55.2915040],
          [-2.3978280, 55.2915190],
          [-2.3979140, 55.2915340],
          [-2.3979940, 55.2915420],
          [-2.3980670, 55.2915530],
          [-2.3981390, 55.2915650],
          [-2.3982020, 55.2915740],
          [-2.3982650, 55.2915840],
          [-2.3983240, 55.2915910],
          [-2.3983820, 55.2916030],
          [-2.3984380, 55.2916110],
          [-2.3984930, 55.2916220],
          [-2.3985470, 55.2916330],
          [-2.3985990, 55.2916450],
          [-2.3986540, 55.2916530],
          [-2.3987090, 55.2916600],
          [-2.3987620, 55.2916720],
          [-2.3988140, 55.2916830],
          [-2.3988650, 55.2916910],
          [-2.3989170, 55.2916980],
          [-2.3989670, 55.2917100],
          [-2.3990160, 55.2917170],
          [-2.3990640, 55.2917250],
          [-2.3991110, 55.2917330],
          [-2.3991570, 55.2917400],
          [-2.3992030, 55.2917480],
          [-2.3992490, 55.2917560],
          [-2.3992950, 55.2917630],
          [-2.3993400, 55.2917740],
          [-2.3993890, 55.2917820],
          [-2.3994390, 55.2917900],
          [-2.3994890, 55.2917970],
          [-2.3995400, 55.2918010],
          [-2.3995930, 55.2918130],
          [-2.3996460, 55.2918200],
          [-2.3996970, 55.2918280],
          [-2.3997490, 55.2918360],
          [-2.3998020, 55.2918470],
          [-2.3998520, 55.2918550],
          [-2.3999010, 55.2918620],
          [-2.3999500, 55.2918700],
          [-2.4000010, 55.2918780],
          [-2.4000520, 55.2918850],
          [-2.4001010, 55.2918930],
          [-2.4001500, 55.2919000],
          [-2.4002040, 55.2919080],
          [-2.4002510, 55.2919150],
          [-2.4003020, 55.2919230],
          [-2.4003550, 55.2919350],
          [-2.4004090, 55.2919500],
          [-2.4004610, 55.2919610],
          [-2.4005170, 55.2919770],
          [-2.4005740, 55.2919880],
          [-2.4006410, 55.2920000],
          [-2.4007040, 55.2920150],
          [-2.4007700, 55.2920220],
          [-2.4008340, 55.2920380],
          [-2.4009010, 55.2920490],
          [-2.4009710, 55.2920600],
          [-2.4010470, 55.2920720],
          [-2.4011250, 55.2920840],
          [-2.4012060, 55.2920950],
          [-2.4012870, 55.2921060],
          [-2.4013730, 55.2921100],
          [-2.4014640, 55.2921140],
          [-2.4015600, 55.2921140],
          [-2.4016640, 55.2921030],
          [-2.4017690, 55.2920840],
          [-2.4018800, 55.2920500],
          [-2.4019840, 55.2920050],
          [-2.4020800, 55.2919470],
          [-2.4021550, 55.2918790],
          [-2.4022150, 55.2918040],
          [-2.4022540, 55.2917350],
          [-2.4022780, 55.2916730],
          [-2.4022880, 55.2916130],
          [-2.4022960, 55.2915610],
          [-2.4023030, 55.2915090],
          [-2.4023100, 55.2914630],
          [-2.4023210, 55.2914210],
          [-2.4023380, 55.2913750],
          [-2.4023590, 55.2913300],
          [-2.4023850, 55.2912880],
          [-2.4024150, 55.2912490],
          [-2.4024450, 55.2912110],
          [-2.4024740, 55.2911740],
          [-2.4025040, 55.2911390],
          [-2.4025340, 55.2911080],
          [-2.4025670, 55.2910780],
          [-2.4026010, 55.2910470],
          [-2.4026350, 55.2910200],
          [-2.4026690, 55.2909930],
          [-2.4027050, 55.2909660],
          [-2.4027400, 55.2909400],
          [-2.4027710, 55.2909100],
          [-2.4028010, 55.2908860],
          [-2.4028290, 55.2908600],
          [-2.4028560, 55.2908330],
          [-2.4028810, 55.2908070],
          [-2.4029080, 55.2907790],
          [-2.4029340, 55.2907540],
          [-2.4029590, 55.2907260],
          [-2.4029850, 55.2906990],
          [-2.4030090, 55.2906730],
          [-2.4030320, 55.2906500],
          [-2.4030580, 55.2906230],
          [-2.4030830, 55.2905970],
          [-2.4031060, 55.2905730],
          [-2.4031280, 55.2905510],
          [-2.4031450, 55.2905240],
          [-2.4031610, 55.2905010],
          [-2.4031790, 55.2904820],
          [-2.4031990, 55.2904550],
          [-2.4032180, 55.2904360],
          [-2.4032360, 55.2904130],
          [-2.4032600, 55.2903900],
          [-2.4032840, 55.2903640],
          [-2.4033080, 55.2903410],
          [-2.4033360, 55.2903190],
          [-2.4033640, 55.2902910],
          [-2.4033920, 55.2902690],
          [-2.4034250, 55.2902420],
          [-2.4034610, 55.2902190],
          [-2.4034970, 55.2901920],
          [-2.4035390, 55.2901730],
          [-2.4035790, 55.2901540],
          [-2.4036210, 55.2901310],
          [-2.4036620, 55.2901120],
          [-2.4037060, 55.2900960],
          [-2.4037490, 55.2900780],
          [-2.4037960, 55.2900660],
          [-2.4038440, 55.2900580],
          [-2.4038940, 55.2900470],
          [-2.4039440, 55.2900430],
          [-2.4039940, 55.2900390],
          [-2.4040440, 55.2900350],
          [-2.4040910, 55.2900320],
          [-2.4041350, 55.2900310],
          [-2.4041830, 55.2900310],
          [-2.4042260, 55.2900310],
          [-2.4042670, 55.2900350],
          [-2.4043040, 55.2900420],
          [-2.4043490, 55.2900500],
          [-2.4043910, 55.2900540],
          [-2.4044340, 55.2900620],
          [-2.4044750, 55.2900690],
          [-2.4045190, 55.2900770],
          [-2.4045580, 55.2900850],
          [-2.4045940, 55.2900880],
          [-2.4046360, 55.2900960],
          [-2.4046770, 55.2901040],
          [-2.4047170, 55.2901110],
          [-2.4047590, 55.2901190],
          [-2.4047980, 55.2901230],
          [-2.4048400, 55.2901270],
          [-2.4048860, 55.2901270],
          [-2.4049300, 55.2901310],
          [-2.4049720, 55.2901310],
          [-2.4050180, 55.2901310],
          [-2.4050630, 55.2901340],
          [-2.4051070, 55.2901310],
          [-2.4051450, 55.2901310],
          [-2.4051940, 55.2901270],
          [-2.4052390, 55.2901230],
          [-2.4052840, 55.2901120],
          [-2.4053310, 55.2901080],
          [-2.4053810, 55.2901000],
          [-2.4054280, 55.2900890],
          [-2.4054740, 55.2900810],
          [-2.4055200, 55.2900660],
          [-2.4055690, 55.2900550],
          [-2.4056170, 55.2900430],
          [-2.4056630, 55.2900290],
          [-2.4057110, 55.2900090],
          [-2.4057560, 55.2899860],
          [-2.4058000, 55.2899670],
          [-2.4058460, 55.2899410],
          [-2.4058990, 55.2899170],
          [-2.4059490, 55.2898920],
          [-2.4060010, 55.2898640],
          [-2.4060470, 55.2898380],
          [-2.4060890, 55.2898080],
          [-2.4061360, 55.2897770],
          [-2.4061790, 55.2897460],
          [-2.4062240, 55.2897170],
          [-2.4062730, 55.2896880],
          [-2.4063180, 55.2896660],
          [-2.4063660, 55.2896360],
          [-2.4064150, 55.2896090],
          [-2.4064660, 55.2895780],
          [-2.4065140, 55.2895520],
          [-2.4065680, 55.2895210],
          [-2.4066190, 55.2894940],
          [-2.4066700, 55.2894650],
          [-2.4067230, 55.2894300],
          [-2.4067770, 55.2894030],
          [-2.4068340, 55.2893730],
          [-2.4068920, 55.2893450],
          [-2.4069510, 55.2893190],
          [-2.4070100, 55.2892890],
          [-2.4070770, 55.2892620],
          [-2.4071470, 55.2892310],
          [-2.4072190, 55.2892000],
          [-2.4072910, 55.2891720],
          [-2.4073760, 55.2891360],
          [-2.4074610, 55.2891030],
          [-2.4075550, 55.2890650],
          [-2.4076490, 55.2890270],
          [-2.4077500, 55.2889900],
          [-2.4078400, 55.2889540],
          [-2.4079370, 55.2889150],
          [-2.4080340, 55.2888800],
          [-2.4081320, 55.2888450],
          [-2.4082250, 55.2888090],
          [-2.4083200, 55.2887730],
          [-2.4084030, 55.2887400],
          [-2.4084840, 55.2887130],
          [-2.4085570, 55.2886910],
          [-2.4086290, 55.2886700],
          [-2.4086920, 55.2886510],
          [-2.4087570, 55.2886320],
          [-2.4088260, 55.2886130],
          [-2.4088950, 55.2885950],
          [-2.4089710, 55.2885820],
          [-2.4090440, 55.2885710],
          [-2.4091190, 55.2885600],
          [-2.4091990, 55.2885520],
          [-2.4092850, 55.2885360],
          [-2.4093730, 55.2885180],
          [-2.4094590, 55.2885030],
          [-2.4095550, 55.2884870],
          [-2.4096410, 55.2884720],
          [-2.4097420, 55.2884530],
          [-2.4098330, 55.2884380],
          [-2.4099300, 55.2884220],
          [-2.4100180, 55.2884040],
          [-2.4101070, 55.2883880],
          [-2.4101900, 55.2883690],
          [-2.4102760, 55.2883570],
          [-2.4103610, 55.2883420],
          [-2.4104440, 55.2883300],
          [-2.4105230, 55.2883160],
          [-2.4106120, 55.2883040],
          [-2.4107010, 55.2882890],
          [-2.4107980, 55.2882700],
          [-2.4108970, 55.2882510],
          [-2.4110030, 55.2882240],
          [-2.4111010, 55.2881910],
          [-2.4112050, 55.2881520],
          [-2.4113000, 55.2881100],
          [-2.4113870, 55.2880670],
          [-2.4114630, 55.2880270],
          [-2.4115410, 55.2879840],
          [-2.4116080, 55.2879480],
          [-2.4116800, 55.2879150],
          [-2.4117390, 55.2878830],
          [-2.4118040, 55.2878420],
          [-2.4118660, 55.2878060],
          [-2.4119330, 55.2877620],
          [-2.4119920, 55.2877240],
          [-2.4120610, 55.2876800],
          [-2.4121230, 55.2876330],
          [-2.4121890, 55.2875750],
          [-2.4122490, 55.2875290],
          [-2.4123180, 55.2874810],
          [-2.4123830, 55.2874340],
          [-2.4124570, 55.2873850],
          [-2.4125240, 55.2873450],
          [-2.4125960, 55.2873010],
          [-2.4126590, 55.2872570],
          [-2.4127330, 55.2872170],
          [-2.4128100, 55.2871810],
          [-2.4128940, 55.2871410],
          [-2.4129770, 55.2871060],
          [-2.4130580, 55.2870760],
          [-2.4131360, 55.2870470],
          [-2.4132210, 55.2870150],
          [-2.4133010, 55.2869860],
          [-2.4133880, 55.2869570],
          [-2.4134700, 55.2869320],
          [-2.4135520, 55.2869020],
          [-2.4136260, 55.2868780],
          [-2.4137200, 55.2868430],
          [-2.4138020, 55.2868170],
          [-2.4138890, 55.2867900],
          [-2.4139740, 55.2867710],
          [-2.4140570, 55.2867480],
          [-2.4141490, 55.2867280],
          [-2.4142320, 55.2867100],
          [-2.4143210, 55.2866900],
          [-2.4144080, 55.2866670],
          [-2.4144950, 55.2866410],
          [-2.4145870, 55.2866180],
          [-2.4146810, 55.2865980],
          [-2.4147760, 55.2865720],
          [-2.4148720, 55.2865450],
          [-2.4149690, 55.2865220],
          [-2.4150660, 55.2864950],
          [-2.4151590, 55.2864730],
          [-2.4152560, 55.2864460],
          [-2.4153530, 55.2864190],
          [-2.4154450, 55.2863890],
          [-2.4155410, 55.2863620],
          [-2.4156430, 55.2863320],
          [-2.4157500, 55.2863050],
          [-2.4158580, 55.2862750],
          [-2.4159760, 55.2862440],
          [-2.4161010, 55.2862170],
          [-2.4162290, 55.2861870],
          [-2.4163660, 55.2861600],
          [-2.4165100, 55.2861290],
          [-2.4166590, 55.2860990],
          [-2.4168080, 55.2860720],
          [-2.4169500, 55.2860460],
          [-2.4170890, 55.2860230],
          [-2.4172280, 55.2860030],
          [-2.4173580, 55.2859880],
          [-2.4174860, 55.2859730],
          [-2.4176070, 55.2859580],
          [-2.4177200, 55.2859460],
          [-2.4178290, 55.2859380],
          [-2.4179330, 55.2859270],
          [-2.4180380, 55.2859230],
          [-2.4181390, 55.2859190],
          [-2.4182440, 55.2859150],
          [-2.4183490, 55.2859120],
          [-2.4184500, 55.2859080],
          [-2.4185520, 55.2859000],
          [-2.4186550, 55.2858960],
          [-2.4187620, 55.2858890],
          [-2.4188640, 55.2858810],
          [-2.4189610, 55.2858770],
          [-2.4190600, 55.2858730],
          [-2.4191570, 55.2858660],
          [-2.4192530, 55.2858580],
          [-2.4193510, 55.2858540],
          [-2.4194460, 55.2858540],
          [-2.4195440, 55.2858540],
          [-2.4196390, 55.2858510],
          [-2.4197320, 55.2858510],
          [-2.4198250, 55.2858470],
          [-2.4199180, 55.2858470],
          [-2.4200100, 55.2858470],
          [-2.4201040, 55.2858470],
          [-2.4201940, 55.2858500],
          [-2.4202830, 55.2858540],
          [-2.4203730, 55.2858540],
          [-2.4204600, 55.2858580],
          [-2.4205490, 55.2858580],
          [-2.4206430, 55.2858580],
          [-2.4207360, 55.2858580],
          [-2.4208260, 55.2858620],
          [-2.4209180, 55.2858620],
          [-2.4210080, 55.2858660],
          [-2.4210940, 55.2858660],
          [-2.4211810, 55.2858660],
          [-2.4212650, 55.2858660],
          [-2.4213470, 55.2858660],
          [-2.4214310, 55.2858700],
          [-2.4215130, 55.2858700],
          [-2.4215940, 55.2858700],
          [-2.4216750, 55.2858730],
          [-2.4217570, 55.2858730],
          [-2.4218370, 55.2858730],
          [-2.4219210, 55.2858730],
          [-2.4220020, 55.2858730],
          [-2.4220830, 55.2858730],
          [-2.4221620, 55.2858700],
          [-2.4222400, 55.2858700],
          [-2.4223160, 55.2858660],
          [-2.4223920, 55.2858660],
          [-2.4224700, 55.2858660],
          [-2.4225490, 55.2858660],
          [-2.4226250, 55.2858660],
          [-2.4227000, 55.2858660],
          [-2.4227770, 55.2858660],
          [-2.4228550, 55.2858620],
          [-2.4229310, 55.2858580],
          [-2.4230060, 55.2858580],
          [-2.4230800, 55.2858580],
          [-2.4231510, 55.2858580],
          [-2.4232210, 55.2858540],
          [-2.4232940, 55.2858540],
          [-2.4233660, 55.2858470],
          [-2.4234360, 55.2858430],
          [-2.4235070, 55.2858390],
          [-2.4235770, 55.2858320],
          [-2.4236450, 55.2858240],
          [-2.4237140, 55.2858200],
          [-2.4237810, 55.2858090],
          [-2.4238440, 55.2858010],
          [-2.4239080, 55.2857900],
          [-2.4239760, 55.2857820],
          [-2.4240460, 55.2857670],
          [-2.4241170, 55.2857550],
          [-2.4241950, 55.2857440],
          [-2.4242740, 55.2857290],
          [-2.4243510, 55.2857100],
          [-2.4244270, 55.2856910],
          [-2.4245050, 55.2856750],
          [-2.4245780, 55.2856570],
          [-2.4246560, 55.2856330],
          [-2.4247310, 55.2856100],
          [-2.4248050, 55.2855840],
          [-2.4248790, 55.2855650],
          [-2.4249540, 55.2855420],
          [-2.4250300, 55.2855150],
          [-2.4251050, 55.2854880],
          [-2.4251810, 55.2854650],
          [-2.4252590, 55.2854380],
          [-2.4253340, 55.2854120],
          [-2.4254120, 55.2853860],
          [-2.4254970, 55.2853590],
          [-2.4255840, 55.2853280],
          [-2.4256670, 55.2853020],
          [-2.4257420, 55.2852750],
          [-2.4258160, 55.2852410],
          [-2.4258870, 55.2852060],
          [-2.4259510, 55.2851720],
          [-2.4260130, 55.2851390],
          [-2.4260830, 55.2851030],
          [-2.4261490, 55.2850690],
          [-2.4262160, 55.2850380],
          [-2.4262860, 55.2850000],
          [-2.4263590, 55.2849620],
          [-2.4264310, 55.2849280],
          [-2.4265010, 55.2848910],
          [-2.4265790, 55.2848550],
          [-2.4266540, 55.2848130],
          [-2.4267260, 55.2847770],
          [-2.4268080, 55.2847370],
          [-2.4268870, 55.2846950],
          [-2.4269660, 55.2846570],
          [-2.4270480, 55.2846190],
          [-2.4271290, 55.2845890],
          [-2.4272120, 55.2845580],
          [-2.4272940, 55.2845270],
          [-2.4273760, 55.2845040],
          [-2.4274640, 55.2844810],
          [-2.4275490, 55.2844590],
          [-2.4276400, 55.2844390],
          [-2.4277310, 55.2844210],
          [-2.4278230, 55.2844090],
          [-2.4279100, 55.2843970],
          [-2.4279980, 55.2843890],
          [-2.4280810, 55.2843820],
          [-2.4281630, 55.2843740],
          [-2.4282420, 55.2843700],
          [-2.4283180, 55.2843700],
          [-2.4283900, 55.2843700],
          [-2.4284610, 55.2843700],
          [-2.4285260, 55.2843700],
          [-2.4285910, 55.2843700],
          [-2.4286510, 55.2843740],
          [-2.4287080, 55.2843780],
          [-2.4287640, 55.2843820],
          [-2.4288190, 55.2843860],
          [-2.4288740, 55.2843930],
          [-2.4289280, 55.2843930],
          [-2.4289830, 55.2843970],
          [-2.4290350, 55.2844050],
          [-2.4290860, 55.2844090],
          [-2.4291380, 55.2844160],
          [-2.4291890, 55.2844200],
          [-2.4292410, 55.2844240],
          [-2.4292930, 55.2844280],
          [-2.4293460, 55.2844310],
          [-2.4293990, 55.2844350],
          [-2.4294520, 55.2844390],
          [-2.4295060, 55.2844430],
          [-2.4295580, 55.2844500],
          [-2.4296090, 55.2844580],
          [-2.4296560, 55.2844690],
          [-2.4297040, 55.2844770],
          [-2.4297540, 55.2844770],
          [-2.4298030, 55.2844810],
          [-2.4298490, 55.2844850],
          [-2.4298960, 55.2844890],
          [-2.4299430, 55.2844960],
          [-2.4299870, 55.2845000],
          [-2.4300320, 55.2845000],
          [-2.4300780, 55.2845040],
          [-2.4301250, 55.2845080],
          [-2.4301680, 55.2845120],
          [-2.4302110, 55.2845190],
          [-2.4302560, 55.2845230],
          [-2.4302990, 55.2845270],
          [-2.4303450, 55.2845340],
          [-2.4303880, 55.2845380],
          [-2.4304330, 55.2845380],
          [-2.4304760, 55.2845420],
          [-2.4305200, 55.2845500],
          [-2.4305650, 55.2845540],
          [-2.4306090, 55.2845570],
          [-2.4306540, 55.2845610],
          [-2.4307000, 55.2845650],
          [-2.4307470, 55.2845720],
          [-2.4307920, 55.2845800],
          [-2.4308390, 55.2845840],
          [-2.4308860, 55.2845880],
          [-2.4309310, 55.2845920],
          [-2.4309770, 55.2845950],
          [-2.4310250, 55.2845990],
          [-2.4310710, 55.2846030],
          [-2.4311250, 55.2846070],
          [-2.4311760, 55.2846110],
          [-2.4312260, 55.2846110],
          [-2.4312780, 55.2846140],
          [-2.4313290, 55.2846180],
          [-2.4313810, 55.2846220],
          [-2.4314360, 55.2846260],
          [-2.4314910, 55.2846300],
          [-2.4315490, 55.2846300],
          [-2.4316060, 55.2846300],
          [-2.4316640, 55.2846260],
          [-2.4317240, 55.2846260],
          [-2.4317840, 55.2846220],
          [-2.4318450, 55.2846190],
          [-2.4319160, 55.2846180],
          [-2.4319850, 55.2846110],
          [-2.4320590, 55.2846070],
          [-2.4321390, 55.2846030],
          [-2.4322190, 55.2845990],
          [-2.4322960, 55.2845920],
          [-2.4323780, 55.2845880],
          [-2.4324540, 55.2845800],
          [-2.4325270, 55.2845800],
          [-2.4325960, 55.2845760],
          [-2.4326580, 55.2845730],
          [-2.4327290, 55.2845690],
          [-2.4327930, 55.2845650],
          [-2.4328530, 55.2845580],
          [-2.4329180, 55.2845540],
          [-2.4329810, 55.2845500],
          [-2.4330390, 55.2845460],
          [-2.4331030, 55.2845420],
          [-2.4331660, 55.2845380],
          [-2.4332280, 55.2845310],
          [-2.4332960, 55.2845270],
          [-2.4333640, 55.2845270],
          [-2.4334300, 55.2845230],
          [-2.4334960, 55.2845230],
          [-2.4335640, 55.2845190],
          [-2.4336280, 55.2845150],
          [-2.4336920, 55.2845120],
          [-2.4337540, 55.2845080],
          [-2.4338100, 55.2845080],
          [-2.4338680, 55.2845040],
          [-2.4339260, 55.2845080],
          [-2.4339800, 55.2845080],
          [-2.4340410, 55.2845080],
          [-2.4341040, 55.2845080],
          [-2.4341660, 55.2845080],
          [-2.4342330, 55.2845040],
          [-2.4342970, 55.2845040],
          [-2.4343660, 55.2845000],
          [-2.4344340, 55.2845000],
          [-2.4345040, 55.2844960],
          [-2.4345730, 55.2844930],
          [-2.4346430, 55.2844890],
          [-2.4347060, 55.2844850],
          [-2.4347650, 55.2844810],
          [-2.4348230, 55.2844770],
          [-2.4348740, 55.2844730],
          [-2.4349230, 55.2844700],
          [-2.4349730, 55.2844620],
          [-2.4350180, 55.2844580],
          [-2.4350620, 55.2844580],
          [-2.4351030, 55.2844550],
          [-2.4351510, 55.2844470],
          [-2.4351980, 55.2844470],
          [-2.4352460, 55.2844390],
          [-2.4352960, 55.2844350],
          [-2.4353500, 55.2844320],
          [-2.4354030, 55.2844240],
          [-2.4354570, 55.2844170],
          [-2.4355120, 55.2844120],
          [-2.4355630, 55.2844090],
          [-2.4356170, 55.2844090],
          [-2.4356750, 55.2844010],
          [-2.4357330, 55.2843940],
          [-2.4357890, 55.2843860],
          [-2.4358490, 55.2843820],
          [-2.4359070, 55.2843780],
          [-2.4359600, 55.2843670],
          [-2.4360170, 55.2843590],
          [-2.4360730, 55.2843550],
          [-2.4361300, 55.2843480],
          [-2.4361870, 55.2843440],
          [-2.4362420, 55.2843360],
          [-2.4362970, 55.2843290],
          [-2.4363520, 55.2843210],
          [-2.4364080, 55.2843170],
          [-2.4364630, 55.2843100],
          [-2.4365200, 55.2843020],
          [-2.4365770, 55.2842910],
          [-2.4366320, 55.2842830],
          [-2.4366930, 55.2842750],
          [-2.4367520, 55.2842680],
          [-2.4368110, 55.2842600],
          [-2.4368700, 55.2842490],
          [-2.4369250, 55.2842410],
          [-2.4369780, 55.2842330],
          [-2.4370300, 55.2842290],
          [-2.4370820, 55.2842220],
          [-2.4371300, 55.2842140],
          [-2.4371820, 55.2842070],
          [-2.4372340, 55.2841990],
          [-2.4372850, 55.2841910],
          [-2.4373350, 55.2841840],
          [-2.4373880, 55.2841760],
          [-2.4374360, 55.2841690],
          [-2.4374900, 55.2841610],
          [-2.4375420, 55.2841530],
          [-2.4375940, 55.2841460],
          [-2.4376470, 55.2841340],
          [-2.4377010, 55.2841230],
          [-2.4377560, 55.2841150],
          [-2.4378100, 55.2841080],
          [-2.4378570, 55.2841040],
          [-2.4379150, 55.2840960],
          [-2.4379670, 55.2840890],
          [-2.4380190, 55.2840810],
          [-2.4380720, 55.2840700],
          [-2.4381260, 55.2840580],
          [-2.4381790, 55.2840470],
          [-2.4382350, 55.2840320],
          [-2.4382870, 55.2840160],
          [-2.4383360, 55.2840020],
          [-2.4383880, 55.2839860],
          [-2.4384360, 55.2839670],
          [-2.4384840, 55.2839490],
          [-2.4385330, 55.2839320],
          [-2.4385820, 55.2839130],
          [-2.4386270, 55.2838950],
          [-2.4386760, 55.2838790],
          [-2.4387200, 55.2838560],
          [-2.4387640, 55.2838380],
          [-2.4388100, 55.2838140],
          [-2.4388540, 55.2837950],
          [-2.4388950, 55.2837770],
          [-2.4389390, 55.2837540],
          [-2.4389840, 55.2837300],
          [-2.4390240, 55.2837090],
          [-2.4390690, 55.2836850],
          [-2.4391130, 55.2836620],
          [-2.4391540, 55.2836400],
          [-2.4391960, 55.2836160],
          [-2.4392370, 55.2835930],
          [-2.4392760, 55.2835750],
          [-2.4393220, 55.2835510],
          [-2.4393630, 55.2835320],
          [-2.4394030, 55.2835100],
          [-2.4394470, 55.2834900],
          [-2.4394880, 55.2834640],
          [-2.4395320, 55.2834450],
          [-2.4395790, 55.2834220],
          [-2.4396200, 55.2833990],
          [-2.4396570, 55.2833770],
          [-2.4397000, 55.2833530],
          [-2.4397410, 55.2833340],
          [-2.4397790, 55.2833130],
          [-2.4398240, 55.2832910],
          [-2.4398640, 55.2832720],
          [-2.4399000, 55.2832510],
          [-2.4399370, 55.2832310],
          [-2.4399750, 55.2832120],
          [-2.4400140, 55.2831920],
          [-2.4400500, 55.2831710],
          [-2.4400900, 55.2831500],
          [-2.4401280, 55.2831320],
          [-2.4401670, 55.2831130],
          [-2.4402060, 55.2830950],
          [-2.4402500, 55.2830750],
          [-2.4402940, 55.2830480],
          [-2.4403370, 55.2830260],
          [-2.4403830, 55.2829980],
          [-2.4404300, 55.2829710],
          [-2.4404700, 55.2829460],
          [-2.4405100, 55.2829150],
          [-2.4405440, 55.2828840],
          [-2.4405780, 55.2828530],
          [-2.4406040, 55.2828180],
          [-2.4406310, 55.2827840],
          [-2.4406530, 55.2827500],
          [-2.4406710, 55.2827120],
          [-2.4406880, 55.2826730],
          [-2.4407050, 55.2826350],
          [-2.4407250, 55.2825980],
          [-2.4407430, 55.2825550],
          [-2.4407590, 55.2825170],
          [-2.4407780, 55.2824760],
          [-2.4407960, 55.2824330],
          [-2.4408160, 55.2823920],
          [-2.4408400, 55.2823490],
          [-2.4408630, 55.2823070],
          [-2.4408890, 55.2822620],
          [-2.4409180, 55.2822200],
          [-2.4409460, 55.2821780],
          [-2.4409760, 55.2821360],
          [-2.4410090, 55.2820900],
          [-2.4410500, 55.2820520],
          [-2.4410960, 55.2820100],
          [-2.4411480, 55.2819710],
          [-2.4412040, 55.2819370],
          [-2.4412630, 55.2818990],
          [-2.4413240, 55.2818680],
          [-2.4413890, 55.2818340],
          [-2.4414540, 55.2818070],
          [-2.4415210, 55.2817810],
          [-2.4415870, 55.2817580],
          [-2.4416600, 55.2817390],
          [-2.4417330, 55.2817190],
          [-2.4418070, 55.2817010],
          [-2.4418810, 55.2816810],
          [-2.4419570, 55.2816660],
          [-2.4420360, 55.2816510],
          [-2.4421120, 55.2816350],
          [-2.4421900, 55.2816240],
          [-2.4422670, 55.2816120],
          [-2.4423440, 55.2816010],
          [-2.4424210, 55.2815900],
          [-2.4424940, 55.2815780],
          [-2.4425610, 55.2815670],
          [-2.4426260, 55.2815590],
          [-2.4426870, 55.2815510],
          [-2.4427430, 55.2815410],
          [-2.4428010, 55.2815290],
          [-2.4428580, 55.2815210],
          [-2.4429110, 55.2815100],
          [-2.4429650, 55.2814940],
          [-2.4430120, 55.2814790],
          [-2.4430590, 55.2814600],
          [-2.4431060, 55.2814450],
          [-2.4431510, 55.2814260],
          [-2.4431950, 55.2814060],
          [-2.4432340, 55.2813840],
          [-2.4432700, 55.2813620],
          [-2.4433050, 55.2813380],
          [-2.4433360, 55.2813150],
          [-2.4433640, 55.2812890],
          [-2.4433930, 55.2812620],
          [-2.4434210, 55.2812350],
          [-2.4434510, 55.2812090],
          [-2.4434830, 55.2811780],
          [-2.4435150, 55.2811470],
          [-2.4435380, 55.2811170],
          [-2.4435610, 55.2810900],
          [-2.4435880, 55.2810630],
          [-2.4436160, 55.2810370],
          [-2.4436460, 55.2810070],
          [-2.4436730, 55.2809830],
          [-2.4437010, 55.2809570],
          [-2.4437320, 55.2809300],
          [-2.4437610, 55.2809030],
          [-2.4437870, 55.2808770],
          [-2.4438100, 55.2808540],
          [-2.4438320, 55.2808280],
          [-2.4438550, 55.2807970],
          [-2.4438810, 55.2807670],
          [-2.4439080, 55.2807350],
          [-2.4439330, 55.2807010],
          [-2.4439600, 55.2806710],
          [-2.4439830, 55.2806440],
          [-2.4440080, 55.2806170],
          [-2.4440370, 55.2805830],
          [-2.4440660, 55.2805490],
          [-2.4440960, 55.2805180],
          [-2.4441230, 55.2804880],
          [-2.4441510, 55.2804570],
          [-2.4441810, 55.2804230],
          [-2.4442110, 55.2803880],
          [-2.4442450, 55.2803580],
          [-2.4442750, 55.2803230],
          [-2.4443040, 55.2802860],
          [-2.4443360, 55.2802550],
          [-2.4443680, 55.2802240],
          [-2.4444050, 55.2801900],
          [-2.4444430, 55.2801590],
          [-2.4444880, 55.2801320],
          [-2.4445320, 55.2801020],
          [-2.4445770, 55.2800760],
          [-2.4446280, 55.2800450],
          [-2.4446770, 55.2800190],
          [-2.4447290, 55.2799950],
          [-2.4447860, 55.2799760],
          [-2.4448490, 55.2799650],
          [-2.4449110, 55.2799530],
          [-2.4449710, 55.2799450],
          [-2.4450280, 55.2799380],
          [-2.4450840, 55.2799340],
          [-2.4451390, 55.2799340],
          [-2.4451910, 55.2799340],
          [-2.4452440, 55.2799380],
          [-2.4453000, 55.2799410],
          [-2.4453590, 55.2799380],
          [-2.4454240, 55.2799340],
          [-2.4454830, 55.2799380],
          [-2.4455390, 55.2799410],
          [-2.4456000, 55.2799420],
          [-2.4456660, 55.2799380],
          [-2.4457260, 55.2799410],
          [-2.4457840, 55.2799420],
          [-2.4458440, 55.2799420],
          [-2.4459070, 55.2799420],
          [-2.4459720, 55.2799420],
          [-2.4460340, 55.2799450],
          [-2.4460930, 55.2799450],
          [-2.4461540, 55.2799490],
          [-2.4462160, 55.2799490],
          [-2.4462780, 55.2799490],
          [-2.4463400, 55.2799460],
          [-2.4464050, 55.2799420],
          [-2.4464660, 55.2799420],
          [-2.4465250, 55.2799420],
          [-2.4465880, 55.2799420],
          [-2.4466440, 55.2799420],
          [-2.4467060, 55.2799340],
          [-2.4467710, 55.2799300],
          [-2.4468360, 55.2799260],
          [-2.4468990, 55.2799190],
          [-2.4469630, 55.2799070],
          [-2.4470230, 55.2798960],
          [-2.4470790, 55.2798880],
          [-2.4471380, 55.2798770],
          [-2.4471990, 55.2798690],
          [-2.4472590, 55.2798620],
          [-2.4473220, 55.2798500],
          [-2.4473880, 55.2798420],
          [-2.4474530, 55.2798350],
          [-2.4475260, 55.2798270],
          [-2.4475950, 55.2798160],
          [-2.4476660, 55.2798080],
          [-2.4477340, 55.2797930],
          [-2.4478020, 55.2797820],
          [-2.4478730, 55.2797670],
          [-2.4479450, 55.2797550],
          [-2.4480160, 55.2797400],
          [-2.4480910, 55.2797280],
          [-2.4481640, 55.2797170],
          [-2.4482330, 55.2796980],
          [-2.4483040, 55.2796830],
          [-2.4483760, 55.2796630],
          [-2.4484460, 55.2796400],
          [-2.4485170, 55.2796180],
          [-2.4485910, 55.2795980],
          [-2.4486620, 55.2795720],
          [-2.4487370, 55.2795490],
          [-2.4488120, 55.2795230],
          [-2.4488900, 55.2794920],
          [-2.4489650, 55.2794550],
          [-2.4490440, 55.2794190],
          [-2.4491160, 55.2793810],
          [-2.4491810, 55.2793480],
          [-2.4492500, 55.2793090],
          [-2.4493170, 55.2792710],
          [-2.4493820, 55.2792290],
          [-2.4494450, 55.2791910],
          [-2.4495110, 55.2791560],
          [-2.4495780, 55.2791260],
          [-2.4496450, 55.2790950],
          [-2.4497150, 55.2790690],
          [-2.4497890, 55.2790420],
          [-2.4498590, 55.2790190],
          [-2.4499280, 55.2789960],
          [-2.4499960, 55.2789810],
          [-2.4500670, 55.2789650],
          [-2.4501420, 55.2789460],
          [-2.4502110, 55.2789320],
          [-2.4502880, 55.2789120],
          [-2.4503580, 55.2788970],
          [-2.4504290, 55.2788820],
          [-2.4504980, 55.2788620],
          [-2.4505650, 55.2788470],
          [-2.4506390, 55.2788320],
          [-2.4507110, 55.2788200],
          [-2.4507790, 55.2788090],
          [-2.4508460, 55.2788010],
          [-2.4509110, 55.2787930],
          [-2.4509750, 55.2787860],
          [-2.4510370, 55.2787820],
          [-2.4510970, 55.2787750],
          [-2.4511580, 55.2787710],
          [-2.4512170, 55.2787670],
          [-2.4512740, 55.2787630],
          [-2.4513320, 55.2787590],
          [-2.4513880, 55.2787550],
          [-2.4514440, 55.2787550],
          [-2.4515000, 55.2787550],
          [-2.4515560, 55.2787550],
          [-2.4516110, 55.2787510],
          [-2.4516650, 55.2787510],
          [-2.4517170, 55.2787480],
          [-2.4517720, 55.2787480],
          [-2.4518240, 55.2787480],
          [-2.4518750, 55.2787480],
          [-2.4519280, 55.2787480],
          [-2.4519800, 55.2787480],
          [-2.4520330, 55.2787480],
          [-2.4520860, 55.2787480],
          [-2.4521370, 55.2787480],
          [-2.4521890, 55.2787440],
          [-2.4522400, 55.2787440],
          [-2.4522920, 55.2787400],
          [-2.4523450, 55.2787320],
          [-2.4524000, 55.2787320],
          [-2.4524590, 55.2787320],
          [-2.4525190, 55.2787320],
          [-2.4525770, 55.2787320],
          [-2.4526360, 55.2787360],
          [-2.4526930, 55.2787320],
          [-2.4527540, 55.2787320],
          [-2.4528140, 55.2787290],
          [-2.4528760, 55.2787280],
          [-2.4529370, 55.2787250],
          [-2.4530040, 55.2787210],
          [-2.4530720, 55.2787210],
          [-2.4531390, 55.2787170],
          [-2.4532090, 55.2787130],
          [-2.4532820, 55.2787100],
          [-2.4533580, 55.2787060],
          [-2.4534340, 55.2786980],
          [-2.4535140, 55.2786900],
          [-2.4535970, 55.2786870],
          [-2.4536780, 55.2786790],
          [-2.4537610, 55.2786680],
          [-2.4538470, 55.2786600],
          [-2.4539390, 55.2786520],
          [-2.4540290, 55.2786450],
          [-2.4541240, 55.2786410],
          [-2.4542180, 55.2786330],
          [-2.4543170, 55.2786330],
          [-2.4544170, 55.2786290],
          [-2.4545220, 55.2786260],
          [-2.4546310, 55.2786260],
          [-2.4547460, 55.2786260],
          [-2.4548590, 55.2786260],
          [-2.4549730, 55.2786260],
          [-2.4550920, 55.2786260],
          [-2.4552100, 55.2786260],
          [-2.4553300, 55.2786220],
          [-2.4554570, 55.2786220],
          [-2.4555830, 55.2786140],
          [-2.4557130, 55.2786100],
          [-2.4558480, 55.2785950],
          [-2.4559890, 55.2785840],
          [-2.4561380, 55.2785610],
          [-2.4562900, 55.2785420],
          [-2.4564530, 55.2785190],
          [-2.4566250, 55.2784920],
          [-2.4567970, 55.2784660],
          [-2.4569750, 55.2784370],
          [-2.4571740, 55.2783900],
          [-2.4573660, 55.2783590],
          [-2.4575760, 55.2783180],
          [-2.4578020, 55.2782750],
          [-2.4580100, 55.2782410],
          [-2.4582370, 55.2782030],
          [-2.4584640, 55.2781640],
          [-2.4586940, 55.2781190],
          [-2.4589370, 55.2780770],
          [-2.4591760, 55.2780310],
          [-2.4594030, 55.2779850],
          [-2.4596230, 55.2779320],
          [-2.4598400, 55.2778750],
          [-2.4600560, 55.2778250],
          [-2.4602690, 55.2777720],
          [-2.4604780, 55.2777180],
          [-2.4606760, 55.2776610],
          [-2.4608840, 55.2776000],
          [-2.4611070, 55.2775430],
          [-2.4612990, 55.2774970],
          [-2.4614790, 55.2774400],
          [-2.4616480, 55.2773790],
          [-2.4618120, 55.2773250],
          [-2.4619670, 55.2772760],
          [-2.4621280, 55.2772190],
          [-2.4622870, 55.2771570],
          [-2.4624440, 55.2771040],
          [-2.4626000, 55.2770470],
          [-2.4627530, 55.2769930],
          [-2.4629090, 55.2769440],
          [-2.4630470, 55.2768920],
          [-2.4632040, 55.2768330],
          [-2.4633510, 55.2767760],
          [-2.4635000, 55.2767260],
          [-2.4636580, 55.2766770],
          [-2.4638160, 55.2766310],
          [-2.4639800, 55.2765740],
          [-2.4641320, 55.2765240],
          [-2.4643010, 55.2764630],
          [-2.4644610, 55.2764020],
          [-2.4646360, 55.2763410],
          [-2.4648030, 55.2762800],
          [-2.4649740, 55.2762190],
          [-2.4651490, 55.2761580],
          [-2.4653210, 55.2761010],
          [-2.4655000, 55.2760400],
          [-2.4656850, 55.2759830],
          [-2.4658620, 55.2759300],
          [-2.4660410, 55.2758720],
          [-2.4662220, 55.2758140],
          [-2.4664010, 55.2757610],
          [-2.4665800, 55.2757160],
          [-2.4667610, 55.2756700],
          [-2.4669410, 55.2756280],
          [-2.4671190, 55.2755900],
          [-2.4672950, 55.2755550],
          [-2.4674680, 55.2755240],
          [-2.4676410, 55.2754940],
          [-2.4678070, 55.2754650],
          [-2.4679880, 55.2754370],
          [-2.4681560, 55.2754100],
          [-2.4683230, 55.2753870],
          [-2.4684860, 55.2753600],
          [-2.4686450, 55.2753410],
          [-2.4688130, 55.2753070],
          [-2.4689700, 55.2752840],
          [-2.4691220, 55.2752540],
          [-2.4692650, 55.2752270],
          [-2.4694020, 55.2752000],
          [-2.4695350, 55.2751780],
          [-2.4696780, 55.2751540],
          [-2.4698200, 55.2751280],
          [-2.4699600, 55.2751010],
          [-2.4701010, 55.2750740],
          [-2.4702330, 55.2750490],
          [-2.4703760, 55.2750250],
          [-2.4705120, 55.2750020],
          [-2.4706460, 55.2749790],
          [-2.4707800, 55.2749560],
          [-2.4709100, 55.2749370],
          [-2.4710380, 55.2749140],
          [-2.4711630, 55.2748910],
          [-2.4712820, 55.2748720],
          [-2.4714050, 55.2748530],
          [-2.4715200, 55.2748310],
          [-2.4716420, 55.2748110],
          [-2.4717640, 55.2747920],
          [-2.4718840, 55.2747730],
          [-2.4719990, 55.2747540],
          [-2.4721180, 55.2747390],
          [-2.4722380, 55.2747230],
          [-2.4723570, 55.2747040],
          [-2.4724790, 55.2746850],
          [-2.4726030, 55.2746660],
          [-2.4727250, 55.2746510],
          [-2.4728530, 55.2746320],
          [-2.4729810, 55.2746170],
          [-2.4731150, 55.2745970],
          [-2.4732460, 55.2745820],
          [-2.4733810, 55.2745670],
          [-2.4735160, 55.2745520],
          [-2.4736520, 55.2745360],
          [-2.4737870, 55.2745250],
          [-2.4739190, 55.2745130],
          [-2.4740490, 55.2745020],
          [-2.4741800, 55.2744870],
          [-2.4743050, 55.2744790],
          [-2.4744350, 55.2744710],
          [-2.4745610, 55.2744600],
          [-2.4746940, 55.2744480],
          [-2.4748270, 55.2744370],
          [-2.4749680, 55.2744260],
          [-2.4751070, 55.2744140],
          [-2.4752450, 55.2744070],
          [-2.4753910, 55.2743950],
          [-2.4755400, 55.2743870],
          [-2.4756830, 55.2743800],
          [-2.4758400, 55.2743720],
          [-2.4759910, 55.2743650],
          [-2.4761400, 55.2743540],
          [-2.4762970, 55.2743420],
          [-2.4764510, 55.2743340],
          [-2.4766050, 55.2743230],
          [-2.4767540, 55.2743150],
          [-2.4769060, 55.2743070],
          [-2.4770560, 55.2742960],
          [-2.4772020, 55.2742850],
          [-2.4773500, 55.2742770],
          [-2.4774920, 55.2742660],
          [-2.4776360, 55.2742580],
          [-2.4777760, 55.2742500],
          [-2.4779180, 55.2742460],
          [-2.4780550, 55.2742420],
          [-2.4781950, 55.2742350],
          [-2.4783290, 55.2742350],
          [-2.4784670, 55.2742350],
          [-2.4786050, 55.2742350],
          [-2.4787430, 55.2742350],
          [-2.4788750, 55.2742390],
          [-2.4790140, 55.2742460],
          [-2.4791480, 55.2742570],
          [-2.4792850, 55.2742730],
          [-2.4794200, 55.2742880],
          [-2.4795590, 55.2743110],
          [-2.4796980, 55.2743300],
          [-2.4798350, 55.2743490],
          [-2.4799850, 55.2743680],
          [-2.4801410, 55.2743800],
          [-2.4802960, 55.2743950],
          [-2.4804650, 55.2744020],
          [-2.4806210, 55.2743990],
          [-2.4807740, 55.2743950],
          [-2.4809160, 55.2743840],
          [-2.4810220, 55.2743690],
          [-2.4811160, 55.2743420],
          [-2.4812070, 55.2743080],
          [-2.4812960, 55.2742740],
          [-2.4813820, 55.2742310],
          [-2.4814670, 55.2741900],
          [-2.4815510, 55.2741450],
          [-2.4816370, 55.2740980],
          [-2.4817270, 55.2740490],
          [-2.4818170, 55.2739990],
          [-2.4819020, 55.2739500],
          [-2.4819900, 55.2738960],
          [-2.4820800, 55.2738470],
          [-2.4821720, 55.2737930],
          [-2.4822630, 55.2737400],
          [-2.4823560, 55.2736870],
          [-2.4824510, 55.2736330],
          [-2.4825470, 55.2735790],
          [-2.4826360, 55.2735270],
          [-2.4827260, 55.2734720],
          [-2.4828190, 55.2734190],
          [-2.4829150, 55.2733620],
          [-2.4830130, 55.2733040],
          [-2.4831100, 55.2732470],
          [-2.4832100, 55.2731910],
          [-2.4833120, 55.2731310],
          [-2.4834220, 55.2730650],
          [-2.4835300, 55.2729960],
          [-2.4836430, 55.2729280],
          [-2.4837600, 55.2728470],
          [-2.4838800, 55.2727710],
          [-2.4840000, 55.2727020],
          [-2.4841210, 55.2726300],
          [-2.4842450, 55.2725580],
          [-2.4843690, 55.2724810],
          [-2.4844920, 55.2724050],
          [-2.4846050, 55.2723250],
          [-2.4847130, 55.2722410],
          [-2.4848250, 55.2721530],
          [-2.4849280, 55.2720680],
          [-2.4850460, 55.2719710],
          [-2.4851590, 55.2718790],
          [-2.4852850, 55.2717740],
          [-2.4854120, 55.2716770],
          [-2.4855370, 55.2715760],
          [-2.4856640, 55.2714760],
          [-2.4857990, 55.2713690],
          [-2.4859350, 55.2712720],
          [-2.4860700, 55.2711810],
          [-2.4862270, 55.2710830],
          [-2.4863720, 55.2709960],
          [-2.4865320, 55.2709080],
          [-2.4866800, 55.2708280],
          [-2.4868460, 55.2707420],
          [-2.4870340, 55.2706550],
          [-2.4872210, 55.2705680],
          [-2.4873980, 55.2704990],
          [-2.4876180, 55.2704290],
          [-2.4878460, 55.2703650],
          [-2.4880780, 55.2703040],
          [-2.4883060, 55.2702490],
          [-2.4885410, 55.2701910],
          [-2.4887770, 55.2701370],
          [-2.4889860, 55.2701010],
          [-2.4891930, 55.2700750],
          [-2.4894150, 55.2700580],
          [-2.4896240, 55.2700510],
          [-2.4898630, 55.2700460],
          [-2.4900940, 55.2700430],
          [-2.4903440, 55.2700310],
          [-2.4905470, 55.2700130],
          [-2.4907520, 55.2699710],
          [-2.4909180, 55.2699210],
          [-2.4910710, 55.2698560],
          [-2.4911960, 55.2697940],
          [-2.4913160, 55.2697310],
          [-2.4914430, 55.2696710],
          [-2.4915660, 55.2696240],
          [-2.4916890, 55.2695800],
          [-2.4917920, 55.2695370],
          [-2.4918780, 55.2695040],
          [-2.4919870, 55.2694620],
          [-2.4920920, 55.2694270],
          [-2.4921950, 55.2693920],
          [-2.4923020, 55.2693540],
          [-2.4924050, 55.2693160],
          [-2.4925160, 55.2692740],
          [-2.4926380, 55.2692310],
          [-2.4927620, 55.2691900],
          [-2.4928890, 55.2691470],
          [-2.4930050, 55.2691070],
          [-2.4931270, 55.2690650],
          [-2.4932410, 55.2690270],
          [-2.4933610, 55.2689920],
          [-2.4934740, 55.2689580],
          [-2.4935850, 55.2689220],
          [-2.4936830, 55.2688930],
          [-2.4937810, 55.2688640],
          [-2.4938820, 55.2688320],
          [-2.4939780, 55.2688070],
          [-2.4940620, 55.2687820],
          [-2.4941440, 55.2687550],
          [-2.4942210, 55.2687330],
          [-2.4943030, 55.2687160],
          [-2.4943800, 55.2687000],
          [-2.4944540, 55.2686850],
          [-2.4945290, 55.2686730],
          [-2.4945930, 55.2686610],
          [-2.4946700, 55.2686490],
          [-2.4947710, 55.2686360],
          [-2.4948560, 55.2686310],
          [-2.4949420, 55.2686200],
          [-2.4950240, 55.2686090],
          [-2.4951160, 55.2686010],
          [-2.4952000, 55.2685900],
          [-2.4952940, 55.2685780],
          [-2.4953810, 55.2685630],
          [-2.4954660, 55.2685520],
          [-2.4955520, 55.2685370],
          [-2.4956280, 55.2685220],
          [-2.4957120, 55.2685100],
          [-2.4957930, 55.2684950],
          [-2.4958730, 55.2684790],
          [-2.4959520, 55.2684640],
          [-2.4960340, 55.2684520],
          [-2.4961080, 55.2684380],
          [-2.4961820, 55.2684270],
          [-2.4962690, 55.2684110],
          [-2.4963380, 55.2683960],
          [-2.4964180, 55.2683800],
          [-2.4964870, 55.2683690],
          [-2.4965590, 55.2683530],
          [-2.4966280, 55.2683380],
          [-2.4966980, 55.2683190],
          [-2.4967530, 55.2683060],
          [-2.4968250, 55.2682850],
          [-2.4968930, 55.2682700],
          [-2.4969580, 55.2682540],
          [-2.4970180, 55.2682330],
          [-2.4970860, 55.2682120],
          [-2.4971460, 55.2681900],
          [-2.4972100, 55.2681700],
          [-2.4972670, 55.2681490],
          [-2.4973290, 55.2681250],
          [-2.4973870, 55.2681060],
          [-2.4974510, 55.2680830],
          [-2.4975110, 55.2680610],
          [-2.4975780, 55.2680440],
          [-2.4976490, 55.2680300],
          [-2.4977250, 55.2680100],
          [-2.4977940, 55.2679920],
          [-2.4978670, 55.2679760],
          [-2.4979380, 55.2679570],
          [-2.4980040, 55.2679420],
          [-2.4980760, 55.2679290],
          [-2.4981360, 55.2679220],
          [-2.4982010, 55.2679180],
          [-2.4982590, 55.2679070],
          [-2.4983300, 55.2678950],
          [-2.4983930, 55.2678800],
          [-2.4984590, 55.2678690],
          [-2.4985250, 55.2678570],
          [-2.4985910, 55.2678490],
          [-2.4986510, 55.2678420],
          [-2.4987150, 55.2678300],
          [-2.4987790, 55.2678190],
          [-2.4988420, 55.2678070],
          [-2.4989040, 55.2677990],
          [-2.4989640, 55.2677920],
          [-2.4990240, 55.2677840],
          [-2.4990860, 55.2677770],
          [-2.4991490, 55.2677650],
          [-2.4992090, 55.2677610],
          [-2.4992690, 55.2677540],
          [-2.4993310, 55.2677500],
          [-2.4993920, 55.2677390],
          [-2.4994530, 55.2677310],
          [-2.4995150, 55.2677270],
          [-2.4995770, 55.2677160],
          [-2.4996410, 55.2677120],
          [-2.4997060, 55.2677000],
          [-2.4997690, 55.2676960],
          [-2.4998340, 55.2676890],
          [-2.4998990, 55.2676850],
          [-2.4999640, 55.2676740],
          [-2.5000290, 55.2676700],
          [-2.5000890, 55.2676580],
          [-2.5001490, 55.2676510],
          [-2.5002070, 55.2676430],
          [-2.5002690, 55.2676320],
          [-2.5003310, 55.2676240],
          [-2.5003930, 55.2676090],
          [-2.5004560, 55.2675940],
          [-2.5005190, 55.2675790],
          [-2.5005790, 55.2675630],
          [-2.5006340, 55.2675440],
          [-2.5006870, 55.2675250],
          [-2.5007390, 55.2675060],
          [-2.5007880, 55.2674910],
          [-2.5008320, 55.2674790],
          [-2.5008760, 55.2674680],
          [-2.5009190, 55.2674490],
          [-2.5009650, 55.2674300],
          [-2.5010080, 55.2674110],
          [-2.5010560, 55.2673920],
          [-2.5011020, 55.2673730],
          [-2.5011470, 55.2673610],
          [-2.5011940, 55.2673460],
          [-2.5012410, 55.2673380],
          [-2.5012870, 55.2673270],
          [-2.5013360, 55.2673150],
          [-2.5013830, 55.2673000],
          [-2.5014310, 55.2672810],
          [-2.5014790, 55.2672660],
          [-2.5015310, 55.2672540],
          [-2.5015810, 55.2672460],
          [-2.5016330, 55.2672390],
          [-2.5016870, 55.2672310],
          [-2.5017440, 55.2672230],
          [-2.5018070, 55.2672080],
          [-2.5018730, 55.2672010],
          [-2.5019340, 55.2671930],
          [-2.5019990, 55.2671820],
          [-2.5020660, 55.2671700],
          [-2.5021310, 55.2671590],
          [-2.5021940, 55.2671510],
          [-2.5022590, 55.2671400],
          [-2.5023280, 55.2671280],
          [-2.5023970, 55.2671170],
          [-2.5024650, 55.2671050],
          [-2.5025370, 55.2670940],
          [-2.5026040, 55.2670790],
          [-2.5026690, 55.2670630],
          [-2.5027280, 55.2670520],
          [-2.5027860, 55.2670330],
          [-2.5028460, 55.2670140],
          [-2.5029040, 55.2669950],
          [-2.5029640, 55.2669720],
          [-2.5030210, 55.2669490],
          [-2.5030760, 55.2669270],
          [-2.5031330, 55.2668990],
          [-2.5031880, 55.2668730],
          [-2.5032460, 55.2668430],
          [-2.5033090, 55.2668080],
          [-2.5033740, 55.2667700],
          [-2.5034410, 55.2667320],
          [-2.5035110, 55.2666940],
          [-2.5035840, 55.2666550],
          [-2.5036540, 55.2666170],
          [-2.5037250, 55.2665750],
          [-2.5038020, 55.2665330],
          [-2.5038790, 55.2664910],
          [-2.5039590, 55.2664500],
          [-2.5040440, 55.2664040],
          [-2.5041300, 55.2663580],
          [-2.5042240, 55.2663120],
          [-2.5043170, 55.2662590],
          [-2.5044030, 55.2662150],
          [-2.5045000, 55.2661670],
          [-2.5045930, 55.2661180],
          [-2.5046870, 55.2660720],
          [-2.5047830, 55.2660270],
          [-2.5048830, 55.2659800],
          [-2.5049800, 55.2659380],
          [-2.5050820, 55.2658930],
          [-2.5051910, 55.2658510],
          [-2.5052980, 55.2658110],
          [-2.5054130, 55.2657630],
          [-2.5055240, 55.2657210],
          [-2.5056370, 55.2656790],
          [-2.5057500, 55.2656370],
          [-2.5058630, 55.2655990],
          [-2.5059790, 55.2655610],
          [-2.5060980, 55.2655190],
          [-2.5062180, 55.2654810],
          [-2.5063450, 55.2654390],
          [-2.5064700, 55.2654000],
          [-2.5065940, 55.2653620],
          [-2.5067160, 55.2653210],
          [-2.5068330, 55.2652860],
          [-2.5069820, 55.2652440],
          [-2.5071240, 55.2652020],
          [-2.5072820, 55.2651600],
          [-2.5074230, 55.2651180],
          [-2.5075670, 55.2650840],
          [-2.5077230, 55.2650490],
          [-2.5078890, 55.2650230],
          [-2.5080520, 55.2650000],
          [-2.5082110, 55.2649690],
          [-2.5083630, 55.2649500],
          [-2.5085130, 55.2649270],
          [-2.5086610, 55.2649040],
          [-2.5088050, 55.2648810],
          [-2.5089460, 55.2648550],
          [-2.5090900, 55.2648240],
          [-2.5092220, 55.2647940],
          [-2.5093450, 55.2647540],
          [-2.5094770, 55.2647100],
          [-2.5096100, 55.2646640],
          [-2.5097430, 55.2646140],
          [-2.5098850, 55.2645650],
          [-2.5100240, 55.2645080],
          [-2.5101480, 55.2644580],
          [-2.5102970, 55.2644090],
          [-2.5104530, 55.2643550],
          [-2.5105990, 55.2643060],
          [-2.5107450, 55.2642640],
          [-2.5108980, 55.2642260],
          [-2.5110660, 55.2641840],
          [-2.5112410, 55.2641460],
          [-2.5114170, 55.2640920],
          [-2.5115700, 55.2640430],
          [-2.5117090, 55.2639830],
          [-2.5118610, 55.2639170],
          [-2.5120190, 55.2638610],
          [-2.5122130, 55.2638020],
          [-2.5124090, 55.2637450],
          [-2.5126070, 55.2636990],
          [-2.5128070, 55.2636530],
          [-2.5130190, 55.2636050],
          [-2.5132510, 55.2635540],
          [-2.5134770, 55.2635050],
          [-2.5137020, 55.2634480],
          [-2.5139100, 55.2633940],
          [-2.5141180, 55.2633410],
          [-2.5143480, 55.2632870],
          [-2.5145620, 55.2632400],
          [-2.5147870, 55.2631920],
          [-2.5150070, 55.2631420],
          [-2.5152300, 55.2630970],
          [-2.5154770, 55.2630470],
          [-2.5157370, 55.2630130],
          [-2.5159850, 55.2629750],
          [-2.5162110, 55.2629360],
          [-2.5164240, 55.2628910],
          [-2.5166250, 55.2628450],
          [-2.5168450, 55.2628100],
          [-2.5170590, 55.2627650],
          [-2.5172620, 55.2627190],
          [-2.5174410, 55.2626820],
          [-2.5176230, 55.2626350],
          [-2.5178240, 55.2625930],
          [-2.5180230, 55.2625550],
          [-2.5182200, 55.2625210],
          [-2.5184190, 55.2624830],
          [-2.5186450, 55.2624520],
          [-2.5188890, 55.2624220],
          [-2.5191210, 55.2623920],
          [-2.5193450, 55.2623560],
          [-2.5195680, 55.2623100],
          [-2.5198120, 55.2622730],
          [-2.5200490, 55.2622190],
          [-2.5202620, 55.2621650],
          [-2.5204640, 55.2621160],
          [-2.5206620, 55.2620700],
          [-2.5208750, 55.2620250],
          [-2.5210890, 55.2619940],
          [-2.5213020, 55.2619640],
          [-2.5215170, 55.2619180],
          [-2.5217570, 55.2618760],
          [-2.5219920, 55.2618410],
          [-2.5222190, 55.2617990],
          [-2.5224450, 55.2617570],
          [-2.5226790, 55.2617270],
          [-2.5229330, 55.2616960],
          [-2.5231890, 55.2616730],
          [-2.5234450, 55.2616500],
          [-2.5236920, 55.2616320],
          [-2.5239460, 55.2616120],
          [-2.5241670, 55.2615780],
          [-2.5243620, 55.2615440],
          [-2.5245610, 55.2614910],
          [-2.5247730, 55.2614270],
          [-2.5249790, 55.2613500],
          [-2.5251610, 55.2612740],
          [-2.5253360, 55.2611860],
          [-2.5255000, 55.2610910],
          [-2.5256520, 55.2609920],
          [-2.5257950, 55.2608880],
          [-2.5259380, 55.2608050],
          [-2.5260990, 55.2607350],
          [-2.5262550, 55.2606740],
          [-2.5264260, 55.2606400],
          [-2.5266250, 55.2606170],
          [-2.5268430, 55.2605940],
          [-2.5270520, 55.2605820],
          [-2.5272740, 55.2605710],
          [-2.5275020, 55.2605710],
          [-2.5277320, 55.2605710],
          [-2.5279480, 55.2605630],
          [-2.5281700, 55.2605440],
          [-2.5283920, 55.2605330],
          [-2.5286160, 55.2605100],
          [-2.5288240, 55.2604910],
          [-2.5290590, 55.2604600],
          [-2.5292800, 55.2604270],
          [-2.5294990, 55.2603950],
          [-2.5297170, 55.2603690],
          [-2.5299490, 55.2603420],
          [-2.5301710, 55.2603120],
          [-2.5303860, 55.2602770],
          [-2.5306090, 55.2602540],
          [-2.5308340, 55.2602310],
          [-2.5310540, 55.2602050],
          [-2.5312630, 55.2601820],
          [-2.5314840, 55.2601590],
          [-2.5316700, 55.2601440],
          [-2.5318470, 55.2601170],
          [-2.5320130, 55.2600900],
          [-2.5321550, 55.2600520],
          [-2.5322930, 55.2600110],
          [-2.5324440, 55.2599610],
          [-2.5325570, 55.2599000],
          [-2.5326400, 55.2598360],
          [-2.5327200, 55.2597490],
          [-2.5327760, 55.2596490],
          [-2.5328340, 55.2595460],
          [-2.5328800, 55.2594350],
          [-2.5329260, 55.2593130],
          [-2.5329490, 55.2591930],
          [-2.5329700, 55.2590690],
          [-2.5329700, 55.2589470],
          [-2.5329570, 55.2588370],
          [-2.5329580, 55.2587180],
          [-2.5329650, 55.2585980],
          [-2.5329930, 55.2584790],
          [-2.5330310, 55.2583480],
          [-2.5330730, 55.2582210],
          [-2.5331210, 55.2580920],
          [-2.5331730, 55.2579710],
          [-2.5332230, 55.2578410],
          [-2.5332840, 55.2577270],
          [-2.5333770, 55.2576310],
          [-2.5334770, 55.2575540],
          [-2.5336290, 55.2574890],
          [-2.5337830, 55.2574390],
          [-2.5339410, 55.2573860],
          [-2.5341220, 55.2573470],
          [-2.5342800, 55.2573140],
          [-2.5344470, 55.2572750],
          [-2.5346020, 55.2572370],
          [-2.5347490, 55.2571910],
          [-2.5348910, 55.2571460],
          [-2.5350440, 55.2571000],
          [-2.5351880, 55.2570500],
          [-2.5353450, 55.2569890],
          [-2.5354970, 55.2569400],
          [-2.5356480, 55.2568860],
          [-2.5358100, 55.2568330],
          [-2.5359670, 55.2567760],
          [-2.5361310, 55.2567150],
          [-2.5362950, 55.2566540],
          [-2.5364630, 55.2565930],
          [-2.5366430, 55.2565240],
          [-2.5368080, 55.2564590],
          [-2.5369930, 55.2563980],
          [-2.5371740, 55.2563290],
          [-2.5373500, 55.2562620],
          [-2.5375380, 55.2561960],
          [-2.5377200, 55.2561310],
          [-2.5379130, 55.2560660],
          [-2.5381100, 55.2559970],
          [-2.5383060, 55.2559290],
          [-2.5385150, 55.2558600],
          [-2.5387170, 55.2557950],
          [-2.5389210, 55.2557380],
          [-2.5391150, 55.2556890],
          [-2.5393090, 55.2556420],
          [-2.5394740, 55.2556080],
          [-2.5396330, 55.2555810],
          [-2.5397990, 55.2555660],
          [-2.5399540, 55.2555620],
          [-2.5401280, 55.2555580],
          [-2.5403110, 55.2555730],
          [-2.5404890, 55.2556000],
          [-2.5406720, 55.2556370],
          [-2.5408310, 55.2556820],
          [-2.5410020, 55.2557430],
          [-2.5411510, 55.2558090],
          [-2.5412970, 55.2558870],
          [-2.5414450, 55.2559620],
          [-2.5415830, 55.2560340],
          [-2.5417210, 55.2561030],
          [-2.5418650, 55.2561670],
          [-2.5420270, 55.2562210],
          [-2.5421900, 55.2562710],
          [-2.5423750, 55.2563050],
          [-2.5425630, 55.2563170],
          [-2.5427730, 55.2563320],
          [-2.5429660, 55.2563510],
          [-2.5431660, 55.2563670],
          [-2.5433710, 55.2563860],
          [-2.5435900, 55.2563970],
          [-2.5438290, 55.2564050],
          [-2.5440550, 55.2564270],
          [-2.5442970, 55.2564430],
          [-2.5445490, 55.2564580],
          [-2.5447950, 55.2564800],
          [-2.5450420, 55.2564960],
          [-2.5452730, 55.2565110],
          [-2.5455190, 55.2565230],
          [-2.5457290, 55.2565340],
          [-2.5459490, 55.2565460],
          [-2.5461610, 55.2565570],
          [-2.5463950, 55.2565610],
          [-2.5466130, 55.2565610],
          [-2.5468280, 55.2565580],
          [-2.5470250, 55.2565540],
          [-2.5472240, 55.2565390],
          [-2.5474120, 55.2565310],
          [-2.5475940, 55.2565230],
          [-2.5477700, 55.2565120],
          [-2.5479370, 55.2565080],
          [-2.5481150, 55.2564970],
          [-2.5482720, 55.2564930],
          [-2.5484300, 55.2564770],
          [-2.5485620, 55.2564740],
          [-2.5486840, 55.2564740],
          [-2.5487960, 55.2564740],
          [-2.5489030, 55.2564740],
          [-2.5490050, 55.2564740],
          [-2.5491010, 55.2564740],
          [-2.5491990, 55.2564700],
          [-2.5492930, 55.2564660],
          [-2.5493800, 55.2564660],
          [-2.5494650, 55.2564660],
          [-2.5495490, 55.2564660],
          [-2.5496370, 55.2564620],
          [-2.5497220, 55.2564620],
          [-2.5498060, 55.2564580],
          [-2.5498920, 55.2564550],
          [-2.5499850, 55.2564470],
          [-2.5500830, 55.2564470],
          [-2.5501900, 55.2564390],
          [-2.5503030, 55.2564350],
          [-2.5504250, 55.2564320],
          [-2.5505630, 55.2564200],
          [-2.5506990, 55.2564050],
          [-2.5508330, 55.2563780],
          [-2.5509500, 55.2563410],
          [-2.5510470, 55.2562840],
          [-2.5511320, 55.2562190],
          [-2.5512150, 55.2561470],
          [-2.5512990, 55.2560740],
          [-2.5513830, 55.2560020],
          [-2.5514710, 55.2559290],
          [-2.5515550, 55.2558530],
          [-2.5516460, 55.2557810],
          [-2.5517410, 55.2557120],
          [-2.5518400, 55.2556400],
          [-2.5519450, 55.2555520],
          [-2.5520480, 55.2554680],
          [-2.5521570, 55.2553800],
          [-2.5522580, 55.2552960],
          [-2.5523550, 55.2552200],
          [-2.5524570, 55.2551400],
          [-2.5525510, 55.2550570],
          [-2.5526490, 55.2549800],
          [-2.5527490, 55.2549010],
          [-2.5528490, 55.2548080],
          [-2.5529500, 55.2547350],
          [-2.5530510, 55.2546590],
          [-2.5531660, 55.2545860],
          [-2.5532820, 55.2545170],
          [-2.5533970, 55.2544520],
          [-2.5535280, 55.2543870],
          [-2.5536420, 55.2543270],
          [-2.5537700, 55.2542710],
          [-2.5539160, 55.2542160],
          [-2.5540410, 55.2541660],
          [-2.5541830, 55.2541170],
          [-2.5543260, 55.2540560],
          [-2.5544710, 55.2539910],
          [-2.5546020, 55.2539110],
          [-2.5547100, 55.2538310],
          [-2.5547840, 55.2537320],
          [-2.5548480, 55.2536400],
          [-2.5548920, 55.2535580],
          [-2.5549350, 55.2534720],
          [-2.5549810, 55.2533910],
          [-2.5550300, 55.2533160],
          [-2.5550880, 55.2532520],
          [-2.5551550, 55.2531910],
          [-2.5552240, 55.2531330],
          [-2.5552930, 55.2530800],
          [-2.5553570, 55.2530260],
          [-2.5554190, 55.2529770],
          [-2.5554850, 55.2529270],
          [-2.5555560, 55.2528810],
          [-2.5556290, 55.2528310],
          [-2.5557070, 55.2527820],
          [-2.5557870, 55.2527290],
          [-2.5558730, 55.2526670],
          [-2.5559640, 55.2526060],
          [-2.5560670, 55.2525450],
          [-2.5561750, 55.2524770],
          [-2.5562970, 55.2524010],
          [-2.5564330, 55.2523200],
          [-2.5565700, 55.2522360],
          [-2.5566940, 55.2521450],
          [-2.5568340, 55.2520460],
          [-2.5569770, 55.2519540],
          [-2.5571200, 55.2518590],
          [-2.5572550, 55.2517680],
          [-2.5573940, 55.2516640],
          [-2.5575350, 55.2515730],
          [-2.5576730, 55.2514880],
          [-2.5578070, 55.2513970],
          [-2.5579270, 55.2513220],
          [-2.5580470, 55.2512450],
          [-2.5581580, 55.2511740],
          [-2.5582720, 55.2511030],
          [-2.5583810, 55.2510350],
          [-2.5584910, 55.2509700],
          [-2.5585990, 55.2509090],
          [-2.5587040, 55.2508440],
          [-2.5588060, 55.2507820],
          [-2.5589000, 55.2507270],
          [-2.5589960, 55.2506680],
          [-2.5590790, 55.2506120],
          [-2.5591590, 55.2505610],
          [-2.5592320, 55.2505120],
          [-2.5593050, 55.2504660],
          [-2.5593770, 55.2504240],
          [-2.5594470, 55.2503740],
          [-2.5595200, 55.2503290],
          [-2.5596010, 55.2502790],
          [-2.5596870, 55.2502300],
          [-2.5597770, 55.2501760],
          [-2.5598660, 55.2501200],
          [-2.5599590, 55.2500580],
          [-2.5600600, 55.2500010],
          [-2.5601610, 55.2499400],
          [-2.5602620, 55.2498790],
          [-2.5603650, 55.2498140],
          [-2.5604760, 55.2497490],
          [-2.5605900, 55.2496810],
          [-2.5606990, 55.2496080],
          [-2.5608080, 55.2495310],
          [-2.5609170, 55.2494550],
          [-2.5610240, 55.2493830],
          [-2.5611210, 55.2493070],
          [-2.5612060, 55.2492340],
          [-2.5612940, 55.2491620],
          [-2.5613850, 55.2490890],
          [-2.5614770, 55.2490240],
          [-2.5615620, 55.2489590],
          [-2.5616560, 55.2488900],
          [-2.5617440, 55.2488260],
          [-2.5618350, 55.2487600],
          [-2.5619260, 55.2486920],
          [-2.5620160, 55.2486270],
          [-2.5621020, 55.2485590],
          [-2.5621940, 55.2484940],
          [-2.5622900, 55.2484330],
          [-2.5623900, 55.2483750],
          [-2.5624850, 55.2483150],
          [-2.5625820, 55.2482580],
          [-2.5626800, 55.2481970],
          [-2.5627810, 55.2481400],
          [-2.5628870, 55.2480820],
          [-2.5629930, 55.2480250],
          [-2.5631010, 55.2479640],
          [-2.5632130, 55.2479030],
          [-2.5633260, 55.2478420],
          [-2.5634470, 55.2477810],
          [-2.5635740, 55.2477160],
          [-2.5636980, 55.2476510],
          [-2.5638190, 55.2475830],
          [-2.5639400, 55.2475140],
          [-2.5640570, 55.2474410],
          [-2.5641700, 55.2473730],
          [-2.5642940, 55.2473080],
          [-2.5644100, 55.2472470],
          [-2.5645250, 55.2471860],
          [-2.5646350, 55.2471290],
          [-2.5647290, 55.2470560],
          [-2.5648360, 55.2469880],
          [-2.5649490, 55.2469300],
          [-2.5650540, 55.2468680],
          [-2.5651580, 55.2467970],
          [-2.5652650, 55.2467250],
          [-2.5653510, 55.2466600],
          [-2.5654470, 55.2465940],
          [-2.5655300, 55.2465340],
          [-2.5656120, 55.2464700],
          [-2.5656950, 55.2464050],
          [-2.5657760, 55.2463430],
          [-2.5658570, 55.2462860],
          [-2.5659370, 55.2462260],
          [-2.5660170, 55.2461670],
          [-2.5661000, 55.2461060],
          [-2.5661770, 55.2460460],
          [-2.5662470, 55.2459870],
          [-2.5663200, 55.2459270],
          [-2.5663940, 55.2458700],
          [-2.5664660, 55.2458090],
          [-2.5665350, 55.2457510],
          [-2.5666040, 55.2456830],
          [-2.5666670, 55.2456240],
          [-2.5667310, 55.2455620],
          [-2.5667920, 55.2455000],
          [-2.5668520, 55.2454430],
          [-2.5669060, 55.2453830],
          [-2.5669660, 55.2453240],
          [-2.5670200, 55.2452670],
          [-2.5670760, 55.2452100],
          [-2.5671290, 55.2451530],
          [-2.5671850, 55.2450950],
          [-2.5672410, 55.2450380],
          [-2.5673000, 55.2449820],
          [-2.5673580, 55.2449210],
          [-2.5674170, 55.2448630],
          [-2.5674760, 55.2447980],
          [-2.5675320, 55.2447370],
          [-2.5675910, 55.2446770],
          [-2.5676540, 55.2446110],
          [-2.5677140, 55.2445500],
          [-2.5677740, 55.2444850],
          [-2.5678370, 55.2444200],
          [-2.5679020, 55.2443600],
          [-2.5679660, 55.2442940],
          [-2.5680260, 55.2442330],
          [-2.5680910, 55.2441720],
          [-2.5681490, 55.2441110],
          [-2.5682030, 55.2440500],
          [-2.5682560, 55.2439920],
          [-2.5683110, 55.2439350],
          [-2.5683680, 55.2438780],
          [-2.5684230, 55.2438220],
          [-2.5684830, 55.2437670],
          [-2.5685380, 55.2437140],
          [-2.5685930, 55.2436570],
          [-2.5686490, 55.2436050],
          [-2.5687090, 55.2435470],
          [-2.5687670, 55.2434930],
          [-2.5688290, 55.2434390],
          [-2.5688910, 55.2433900],
          [-2.5689490, 55.2433360],
          [-2.5690090, 55.2432830],
          [-2.5690690, 55.2432300],
          [-2.5691320, 55.2431730],
          [-2.5692020, 55.2431190],
          [-2.5692740, 55.2430660],
          [-2.5693470, 55.2430080],
          [-2.5694210, 55.2429520],
          [-2.5695040, 55.2428940],
          [-2.5695860, 55.2428330],
          [-2.5696680, 55.2427730],
          [-2.5697590, 55.2427110],
          [-2.5698520, 55.2426460],
          [-2.5699460, 55.2425810],
          [-2.5700410, 55.2425160],
          [-2.5701340, 55.2424520],
          [-2.5702270, 55.2423830],
          [-2.5703250, 55.2423180],
          [-2.5704220, 55.2422530],
          [-2.5705200, 55.2421850],
          [-2.5706180, 55.2421160],
          [-2.5707170, 55.2420520],
          [-2.5708220, 55.2419830],
          [-2.5709270, 55.2419140],
          [-2.5710370, 55.2418460],
          [-2.5711530, 55.2417690],
          [-2.5712750, 55.2416930],
          [-2.5713950, 55.2416130],
          [-2.5715270, 55.2415400],
          [-2.5716510, 55.2414560],
          [-2.5717730, 55.2413730],
          [-2.5719010, 55.2412890],
          [-2.5720310, 55.2412080],
          [-2.5721510, 55.2411300],
          [-2.5722750, 55.2410520],
          [-2.5723920, 55.2409750],
          [-2.5725090, 55.2409040],
          [-2.5726300, 55.2408310],
          [-2.5727500, 55.2407580],
          [-2.5728660, 55.2406860],
          [-2.5729800, 55.2406090],
          [-2.5730870, 55.2405290],
          [-2.5731950, 55.2404530],
          [-2.5732880, 55.2403790],
          [-2.5733640, 55.2402970],
          [-2.5734310, 55.2402010],
          [-2.5734910, 55.2401130],
          [-2.5735290, 55.2400220],
          [-2.5735460, 55.2399230],
          [-2.5735750, 55.2398390],
          [-2.5736010, 55.2397620],
          [-2.5736240, 55.2396880],
          [-2.5736480, 55.2396170],
          [-2.5736760, 55.2395530],
          [-2.5737080, 55.2394840],
          [-2.5737480, 55.2394190],
          [-2.5737950, 55.2393550],
          [-2.5738490, 55.2392930],
          [-2.5739060, 55.2392360],
          [-2.5739700, 55.2391750],
          [-2.5740470, 55.2391220],
          [-2.5741270, 55.2390720],
          [-2.5742150, 55.2390260],
          [-2.5743090, 55.2389840],
          [-2.5744020, 55.2389420],
          [-2.5745020, 55.2389000],
          [-2.5746070, 55.2388580],
          [-2.5747180, 55.2388200],
          [-2.5748370, 55.2387810],
          [-2.5749600, 55.2387440],
          [-2.5750940, 55.2387010],
          [-2.5752310, 55.2386560],
          [-2.5753780, 55.2386100],
          [-2.5755280, 55.2385640],
          [-2.5756820, 55.2385070],
          [-2.5758430, 55.2384460],
          [-2.5760040, 55.2383810],
          [-2.5761470, 55.2383140],
          [-2.5762770, 55.2382480],
          [-2.5764030, 55.2381800],
          [-2.5765360, 55.2381150],
          [-2.5766610, 55.2380460],
          [-2.5767910, 55.2379730],
          [-2.5769070, 55.2379050],
          [-2.5770090, 55.2378360],
          [-2.5770990, 55.2377720],
          [-2.5772020, 55.2377100],
          [-2.5773260, 55.2376720],
          [-2.5774570, 55.2376490],
          [-2.5776010, 55.2376410],
          [-2.5777300, 55.2376440],
          [-2.5778500, 55.2376560],
          [-2.5779630, 55.2376630],
          [-2.5780600, 55.2376710],
          [-2.5781570, 55.2376900],
          [-2.5782490, 55.2377050],
          [-2.5783340, 55.2377240],
          [-2.5784140, 55.2377390],
          [-2.5784890, 55.2377550],
          [-2.5785600, 55.2377660],
          [-2.5786320, 55.2377770],
          [-2.5786960, 55.2377920],
          [-2.5787590, 55.2378000],
          [-2.5788260, 55.2378080],
          [-2.5788940, 55.2378190],
          [-2.5789570, 55.2378350],
          [-2.5790230, 55.2378460],
          [-2.5790760, 55.2378650],
          [-2.5791350, 55.2378770],
          [-2.5791970, 55.2378880],
          [-2.5792630, 55.2379000],
          [-2.5793310, 55.2379110],
          [-2.5794010, 55.2379220],
          [-2.5794650, 55.2379410],
          [-2.5795350, 55.2379570],
          [-2.5796000, 55.2379760],
          [-2.5796620, 55.2379910],
          [-2.5797230, 55.2380100],
          [-2.5797860, 55.2380330],
          [-2.5798390, 55.2380560],
          [-2.5798910, 55.2380790],
          [-2.5799470, 55.2381020],
          [-2.5800070, 55.2381320],
          [-2.5800640, 55.2381590],
          [-2.5801190, 55.2381890],
          [-2.5801770, 55.2382200],
          [-2.5802310, 55.2382500],
          [-2.5802890, 55.2382850],
          [-2.5803410, 55.2383140],
          [-2.5803950, 55.2383490],
          [-2.5804480, 55.2383800],
          [-2.5805030, 55.2384180],
          [-2.5805580, 55.2384560],
          [-2.5805950, 55.2384850],
          [-2.5806560, 55.2385290],
          [-2.5807060, 55.2385630],
          [-2.5807560, 55.2385970],
          [-2.5808060, 55.2386320],
          [-2.5808600, 55.2386660],
          [-2.5809200, 55.2387040],
          [-2.5809840, 55.2387380],
          [-2.5810480, 55.2387690],
          [-2.5811200, 55.2388000],
          [-2.5811940, 55.2388260],
          [-2.5812750, 55.2388490],
          [-2.5813620, 55.2388640],
          [-2.5814630, 55.2388800],
          [-2.5815710, 55.2388910],
          [-2.5816910, 55.2388920],
          [-2.5818170, 55.2388920],
          [-2.5819470, 55.2388880],
          [-2.5820810, 55.2388840],
          [-2.5822140, 55.2388800],
          [-2.5823450, 55.2388840],
          [-2.5824660, 55.2388990],
          [-2.5825800, 55.2389180],
          [-2.5826750, 55.2389370],
          [-2.5827570, 55.2389560],
          [-2.5828290, 55.2389790],
          [-2.5828910, 55.2389980],
          [-2.5829480, 55.2390210],
          [-2.5830000, 55.2390440],
          [-2.5830490, 55.2390630],
          [-2.5830970, 55.2390860],
          [-2.5831440, 55.2391090],
          [-2.5831860, 55.2391280],
          [-2.5832240, 55.2391470],
          [-2.5832640, 55.2391660],
          [-2.5833090, 55.2391810],
          [-2.5833540, 55.2391960],
          [-2.5833960, 55.2392190],
          [-2.5834320, 55.2392460],
          [-2.5834630, 55.2392730],
          [-2.5834920, 55.2392920],
          [-2.5835260, 55.2393140],
          [-2.5835580, 55.2393340],
          [-2.5835910, 55.2393530],
          [-2.5836230, 55.2393760],
          [-2.5836510, 55.2393950],
          [-2.5836780, 55.2394210],
          [-2.5837000, 55.2394410],
          [-2.5837180, 55.2394630],
          [-2.5837360, 55.2394860],
          [-2.5837550, 55.2395130],
          [-2.5837730, 55.2395320],
          [-2.5837910, 55.2395550],
          [-2.5838100, 55.2395780],
          [-2.5838280, 55.2396010],
          [-2.5838410, 55.2396200],
          [-2.5838560, 55.2396430],
          [-2.5838700, 55.2396660],
          [-2.5838810, 55.2396890],
          [-2.5838910, 55.2397110],
          [-2.5839000, 55.2397340],
          [-2.5839060, 55.2397570],
          [-2.5839160, 55.2397760],
          [-2.5839280, 55.2397990],
          [-2.5839400, 55.2398180],
          [-2.5839480, 55.2398370],
          [-2.5839580, 55.2398560],
          [-2.5839700, 55.2398830],
          [-2.5839780, 55.2399020],
          [-2.5839870, 55.2399250],
          [-2.5840030, 55.2399440],
          [-2.5840130, 55.2399670],
          [-2.5840230, 55.2399900],
          [-2.5840350, 55.2400090],
          [-2.5840500, 55.2400280],
          [-2.5840610, 55.2400510],
          [-2.5840700, 55.2400700],
          [-2.5840810, 55.2400930],
          [-2.5840900, 55.2401160],
          [-2.5840980, 55.2401310],
          [-2.5841020, 55.2401460],
          [-2.5841100, 55.2401690],
          [-2.5841200, 55.2401920],
          [-2.5841330, 55.2402110],
          [-2.5841500, 55.2402380],
          [-2.5841650, 55.2402600],
          [-2.5841730, 55.2402800],
          [-2.5841820, 55.2402990],
          [-2.5841910, 55.2403210],
          [-2.5842050, 55.2403480],
          [-2.5842200, 55.2403710],
          [-2.5842360, 55.2403980],
          [-2.5842500, 55.2404250],
          [-2.5842630, 55.2404470],
          [-2.5842760, 55.2404740],
          [-2.5842880, 55.2405010],
          [-2.5843000, 55.2405240],
          [-2.5843080, 55.2405510],
          [-2.5843200, 55.2405770],
          [-2.5843320, 55.2406040],
          [-2.5843450, 55.2406310],
          [-2.5843580, 55.2406610],
          [-2.5843710, 55.2406880],
          [-2.5843840, 55.2407140],
          [-2.5843960, 55.2407410],
          [-2.5844130, 55.2407680],
          [-2.5844300, 55.2407980],
          [-2.5844500, 55.2408250],
          [-2.5844760, 55.2408550],
          [-2.5845080, 55.2408860],
          [-2.5845380, 55.2409210],
          [-2.5845710, 55.2409510],
          [-2.5846120, 55.2409850],
          [-2.5846640, 55.2410240],
          [-2.5847200, 55.2410610],
          [-2.5847810, 55.2411030],
          [-2.5848420, 55.2411490],
          [-2.5849040, 55.2412030],
          [-2.5849690, 55.2412600],
          [-2.5850360, 55.2413200],
          [-2.5850950, 55.2413920],
          [-2.5851470, 55.2414720],
          [-2.5851910, 55.2415570],
          [-2.5852390, 55.2416440],
          [-2.5853010, 55.2417360],
          [-2.5853970, 55.2418230],
          [-2.5855150, 55.2419030],
          [-2.5856560, 55.2419730],
          [-2.5858020, 55.2420330],
          [-2.5859550, 55.2420840],
          [-2.5861100, 55.2421330],
          [-2.5862820, 55.2421940],
          [-2.5864470, 55.2422630],
          [-2.5865880, 55.2423310],
          [-2.5867230, 55.2424190],
          [-2.5868480, 55.2425030],
          [-2.5869690, 55.2425750],
          [-2.5870810, 55.2426590],
          [-2.5872030, 55.2427360],
          [-2.5873220, 55.2428120],
          [-2.5874280, 55.2428920],
          [-2.5875480, 55.2429720],
          [-2.5876770, 55.2430520],
          [-2.5878150, 55.2431360],
          [-2.5879500, 55.2432240],
          [-2.5880940, 55.2433150],
          [-2.5882350, 55.2434060],
          [-2.5883830, 55.2435020],
          [-2.5885320, 55.2436030],
          [-2.5886810, 55.2437070],
          [-2.5888380, 55.2438070],
          [-2.5889910, 55.2439100],
          [-2.5891470, 55.2440160],
          [-2.5893030, 55.2441240],
          [-2.5894570, 55.2442300],
          [-2.5896160, 55.2443450],
          [-2.5897700, 55.2444470],
          [-2.5899260, 55.2445550],
          [-2.5900870, 55.2446310],
          [-2.5902210, 55.2447150],
          [-2.5903560, 55.2448110],
          [-2.5905070, 55.2449060],
          [-2.5906510, 55.2449830],
          [-2.5908130, 55.2450660],
          [-2.5909940, 55.2451350],
          [-2.5912070, 55.2451850],
          [-2.5914210, 55.2452200],
          [-2.5916370, 55.2452580],
          [-2.5918330, 55.2452960],
          [-2.5920240, 55.2453450],
          [-2.5922010, 55.2454030],
          [-2.5923660, 55.2454710],
          [-2.5925200, 55.2455470],
          [-2.5926800, 55.2456190],
          [-2.5928510, 55.2456770],
          [-2.5930100, 55.2457250],
          [-2.5931620, 55.2457760],
          [-2.5933080, 55.2458260],
          [-2.5934500, 55.2458720],
          [-2.5935900, 55.2459030],
          [-2.5937300, 55.2459070],
          [-2.5938680, 55.2458920],
          [-2.5939880, 55.2458540],
          [-2.5940850, 55.2457930],
          [-2.5941600, 55.2457210],
          [-2.5942260, 55.2456410],
          [-2.5942890, 55.2455610],
          [-2.5943560, 55.2454810],
          [-2.5944180, 55.2453980],
          [-2.5944770, 55.2453130],
          [-2.5945290, 55.2452180],
          [-2.5945780, 55.2451280],
          [-2.5946450, 55.2450160],
          [-2.5947140, 55.2449170],
          [-2.5947990, 55.2448090],
          [-2.5948800, 55.2447080],
          [-2.5949780, 55.2446060],
          [-2.5950740, 55.2445190],
          [-2.5951600, 55.2444280],
          [-2.5952250, 55.2443300],
          [-2.5952790, 55.2442190],
          [-2.5953320, 55.2441200],
          [-2.5953740, 55.2440170],
          [-2.5954140, 55.2439210],
          [-2.5954640, 55.2438380],
          [-2.5955330, 55.2437720],
          [-2.5956120, 55.2437260],
          [-2.5957000, 55.2437060],
          [-2.5957780, 55.2437060],
          [-2.5958640, 55.2437170],
          [-2.5959420, 55.2437400],
          [-2.5960180, 55.2437660],
          [-2.5960950, 55.2437970],
          [-2.5961760, 55.2438230],
          [-2.5962620, 55.2438500],
          [-2.5963440, 55.2438810],
          [-2.5964220, 55.2439100],
          [-2.5965010, 55.2439420],
          [-2.5965770, 55.2439720],
          [-2.5966470, 55.2440030],
          [-2.5967120, 55.2440300],
          [-2.5967770, 55.2440490],
          [-2.5968360, 55.2440450],
          [-2.5968920, 55.2440340],
          [-2.5969440, 55.2440230],
          [-2.5969880, 55.2440070],
          [-2.5970330, 55.2439960],
          [-2.5970710, 55.2439840],
          [-2.5970960, 55.2439730],
          [-2.5971100, 55.2439580],
          [-2.5970970, 55.2439460],
          [-2.5970670, 55.2439270],
          [-2.5970280, 55.2439100],
          [-2.5969760, 55.2438820],
          [-2.5969220, 55.2438520],
          [-2.5968670, 55.2438170],
          [-2.5968060, 55.2437790],
          [-2.5967280, 55.2437330],
          [-2.5966610, 55.2436910],
          [-2.5966030, 55.2436490],
          [-2.5965410, 55.2436120],
          [-2.5964720, 55.2435800],
          [-2.5964100, 55.2435420],
          [-2.5963460, 55.2434930],
          [-2.5962810, 55.2434390],
          [-2.5962160, 55.2433860],
          [-2.5961540, 55.2433290],
          [-2.5960960, 55.2432720],
          [-2.5960340, 55.2432160],
          [-2.5959710, 55.2431610],
          [-2.5959110, 55.2431080],
          [-2.5958560, 55.2430550],
          [-2.5958040, 55.2429970],
          [-2.5957610, 55.2429360],
          [-2.5957160, 55.2428750],
          [-2.5956660, 55.2428140],
          [-2.5956090, 55.2427620],
          [-2.5955540, 55.2426960],
          [-2.5955090, 55.2426420],
          [-2.5954670, 55.2425780],
          [-2.5954310, 55.2425170],
          [-2.5953860, 55.2424480],
          [-2.5953340, 55.2423830],
          [-2.5952820, 55.2423140],
          [-2.5952340, 55.2422530],
          [-2.5951870, 55.2421810],
          [-2.5951490, 55.2421130],
          [-2.5951070, 55.2420440],
          [-2.5950690, 55.2419750],
          [-2.5950310, 55.2419140],
          [-2.5949950, 55.2418560],
          [-2.5949640, 55.2417920],
          [-2.5949440, 55.2417200],
          [-2.5949270, 55.2416470],
          [-2.5949040, 55.2415740],
          [-2.5948820, 55.2415060],
          [-2.5948670, 55.2414380],
          [-2.5948570, 55.2413760],
          [-2.5948440, 55.2413120],
          [-2.5948220, 55.2412540],
          [-2.5948040, 55.2411930],
          [-2.5947970, 55.2411240],
          [-2.5947950, 55.2410710],
          [-2.5948030, 55.2410290],
          [-2.5948300, 55.2410020],
          [-2.5948660, 55.2409940],
          [-2.5949020, 55.2409970],
          [-2.5949350, 55.2410080],
          [-2.5949570, 55.2410270],
          [-2.5949830, 55.2410500],
          [-2.5950180, 55.2410850],
          [-2.5950600, 55.2411300],
          [-2.5951060, 55.2411760],
          [-2.5951480, 55.2412220],
          [-2.5951910, 55.2412680],
          [-2.5952410, 55.2413090],
          [-2.5952920, 55.2413550],
          [-2.5953440, 55.2414010],
          [-2.5954010, 55.2414430],
          [-2.5954590, 55.2414930],
          [-2.5955210, 55.2415350],
          [-2.5955860, 55.2415760],
          [-2.5956520, 55.2416180],
          [-2.5957210, 55.2416570],
          [-2.5957850, 55.2416910],
          [-2.5958540, 55.2417220],
          [-2.5959210, 55.2417560],
          [-2.5959820, 55.2417860],
          [-2.5960440, 55.2418170],
          [-2.5961020, 55.2418420],
          [-2.5961710, 55.2418780],
          [-2.5962260, 55.2419090],
          [-2.5962690, 55.2419350],
          [-2.5963150, 55.2419540],
          [-2.5963690, 55.2419770],
          [-2.5964240, 55.2420030],
          [-2.5964770, 55.2420270],
          [-2.5965210, 55.2420540],
          [-2.5965730, 55.2420800],
          [-2.5966280, 55.2421030],
          [-2.5966790, 55.2421340],
          [-2.5967330, 55.2421640],
          [-2.5967680, 55.2421810],
          [-2.5968310, 55.2422210],
          [-2.5968760, 55.2422410],
          [-2.5969210, 55.2422630],
          [-2.5969630, 55.2422860],
          [-2.5970080, 55.2423130],
          [-2.5970490, 55.2423390],
          [-2.5970880, 55.2423630],
          [-2.5971330, 55.2423820],
          [-2.5971720, 55.2424010],
          [-2.5972140, 55.2424120],
          [-2.5972540, 55.2424310],
          [-2.5972940, 55.2424540],
          [-2.5973390, 55.2424770],
          [-2.5973970, 55.2424920],
          [-2.5974510, 55.2425110],
          [-2.5975050, 55.2425230],
          [-2.5975620, 55.2425380],
          [-2.5976230, 55.2425530],
          [-2.5976840, 55.2425690],
          [-2.5977440, 55.2425880],
          [-2.5978070, 55.2426030],
          [-2.5978680, 55.2426180],
          [-2.5979240, 55.2426340],
          [-2.5979750, 55.2426490],
          [-2.5980410, 55.2426680],
          [-2.5981030, 55.2426870],
          [-2.5981700, 55.2427100],
          [-2.5982280, 55.2427250],
          [-2.5982870, 55.2427480],
          [-2.5983430, 55.2427750],
          [-2.5983930, 55.2428010],
          [-2.5984360, 55.2428320],
          [-2.5984830, 55.2428590],
          [-2.5985230, 55.2428920],
          [-2.5985690, 55.2429230],
          [-2.5986170, 55.2429500],
          [-2.5986630, 55.2429800],
          [-2.5987060, 55.2430110],
          [-2.5987480, 55.2430380],
          [-2.5987860, 55.2430800],
          [-2.5988330, 55.2431250],
          [-2.5988790, 55.2431750],
          [-2.5989290, 55.2432240],
          [-2.5989840, 55.2432810],
          [-2.5990420, 55.2433390],
          [-2.5991030, 55.2433950],
          [-2.5991760, 55.2434500],
          [-2.5992600, 55.2434950],
          [-2.5993580, 55.2435370],
          [-2.5994620, 55.2435740],
          [-2.5995780, 55.2436140],
          [-2.5997030, 55.2436440],
          [-2.5998300, 55.2436670],
          [-2.5999500, 55.2436860],
          [-2.6000680, 55.2437010],
          [-2.6001850, 55.2437170],
          [-2.6003040, 55.2437320],
          [-2.6004160, 55.2437470],
          [-2.6005230, 55.2437670],
          [-2.6006290, 55.2437860],
          [-2.6007380, 55.2438160],
          [-2.6008420, 55.2438460],
          [-2.6009400, 55.2438840],
          [-2.6010290, 55.2439290],
          [-2.6011120, 55.2439760],
          [-2.6011860, 55.2440200],
          [-2.6012570, 55.2440670],
          [-2.6013270, 55.2441170],
          [-2.6013960, 55.2441620],
          [-2.6014650, 55.2442010],
          [-2.6015260, 55.2442430],
          [-2.6015850, 55.2442870],
          [-2.6016570, 55.2443310],
          [-2.6017250, 55.2443720],
          [-2.6018000, 55.2444110],
          [-2.6018820, 55.2444490],
          [-2.6019670, 55.2444800],
          [-2.6020480, 55.2445100],
          [-2.6021290, 55.2445360],
          [-2.6022110, 55.2445600],
          [-2.6022900, 55.2445790],
          [-2.6023700, 55.2445980],
          [-2.6024480, 55.2446210],
          [-2.6025280, 55.2446360],
          [-2.6026070, 55.2446550],
          [-2.6026810, 55.2446780],
          [-2.6027600, 55.2446970],
          [-2.6028310, 55.2447050],
          [-2.6028960, 55.2447160],
          [-2.6029580, 55.2447280],
          [-2.6030130, 55.2447360],
          [-2.6030760, 55.2447470],
          [-2.6031370, 55.2447550],
          [-2.6032000, 55.2447590],
          [-2.6032550, 55.2447730],
          [-2.6033110, 55.2447890],
          [-2.6033650, 55.2448000],
          [-2.6034220, 55.2448120],
          [-2.6034800, 55.2448270],
          [-2.6035420, 55.2448500],
          [-2.6036030, 55.2448690],
          [-2.6036640, 55.2448990],
          [-2.6037210, 55.2449300],
          [-2.6037750, 55.2449590],
          [-2.6038290, 55.2449940],
          [-2.6038820, 55.2450250],
          [-2.6039250, 55.2450590],
          [-2.6039710, 55.2450850],
          [-2.6040010, 55.2451130],
          [-2.6040330, 55.2451400],
          [-2.6040850, 55.2451590],
          [-2.6041290, 55.2451820],
          [-2.6041690, 55.2452010],
          [-2.6042070, 55.2452230],
          [-2.6042300, 55.2452470],
          [-2.6042560, 55.2452690],
          [-2.6042740, 55.2452920],
          [-2.6042910, 55.2453110],
          [-2.6043210, 55.2453340],
          [-2.6043560, 55.2453530],
          [-2.6043890, 55.2453760],
          [-2.6044220, 55.2453990],
          [-2.6044550, 55.2454220],
          [-2.6044860, 55.2454450],
          [-2.6045160, 55.2454670],
          [-2.6045510, 55.2454830],
          [-2.6045910, 55.2455020],
          [-2.6046280, 55.2455250],
          [-2.6046690, 55.2455480],
          [-2.6047090, 55.2455670],
          [-2.6047560, 55.2455860],
          [-2.6048040, 55.2456010],
          [-2.6048560, 55.2456170],
          [-2.6049020, 55.2456390],
          [-2.6049430, 55.2456580],
          [-2.6049880, 55.2456770],
          [-2.6050360, 55.2456970],
          [-2.6050840, 55.2457200],
          [-2.6051340, 55.2457390],
          [-2.6051780, 55.2457610],
          [-2.6052220, 55.2457840],
          [-2.6052740, 55.2458110],
          [-2.6053280, 55.2458420],
          [-2.6053860, 55.2458680],
          [-2.6054520, 55.2458990],
          [-2.6055220, 55.2459290],
          [-2.6055910, 55.2459600],
          [-2.6056600, 55.2459940],
          [-2.6057470, 55.2460250],
          [-2.6058420, 55.2460590],
          [-2.6059350, 55.2460890],
          [-2.6060250, 55.2461160],
          [-2.6061200, 55.2461470],
          [-2.6062250, 55.2461730],
          [-2.6063330, 55.2462000],
          [-2.6064390, 55.2462310],
          [-2.6065450, 55.2462610],
          [-2.6066570, 55.2462840],
          [-2.6067690, 55.2463000],
          [-2.6068860, 55.2463110],
          [-2.6070000, 55.2463150],
          [-2.6071140, 55.2463220],
          [-2.6072350, 55.2463110],
          [-2.6073420, 55.2462960],
          [-2.6074470, 55.2462740],
          [-2.6075500, 55.2462500],
          [-2.6076500, 55.2462240],
          [-2.6077560, 55.2461970],
          [-2.6078640, 55.2461700],
          [-2.6079790, 55.2461360],
          [-2.6081040, 55.2461060],
          [-2.6082280, 55.2460790],
          [-2.6083530, 55.2460560],
          [-2.6084840, 55.2460480],
          [-2.6086300, 55.2460480],
          [-2.6087600, 55.2460630],
          [-2.6088790, 55.2460890],
          [-2.6090050, 55.2461160],
          [-2.6091390, 55.2461430],
          [-2.6092610, 55.2461700],
          [-2.6093750, 55.2461890],
          [-2.6094830, 55.2462080],
          [-2.6095820, 55.2462270],
          [-2.6096680, 55.2462450],
          [-2.6097440, 55.2462610],
          [-2.6098120, 55.2462770],
          [-2.6098730, 55.2462880],
          [-2.6099290, 55.2463000],
          [-2.6099810, 55.2463070],
          [-2.6100290, 55.2463150],
          [-2.6100720, 55.2463260],
          [-2.6101110, 55.2463380],
          [-2.6101500, 55.2463420],
          [-2.6101900, 55.2463490],
          [-2.6102300, 55.2463600],
          [-2.6102730, 55.2463720],
          [-2.6103090, 55.2463830],
          [-2.6103460, 55.2463950],
          [-2.6103880, 55.2464060],
          [-2.6104290, 55.2464140],
          [-2.6104660, 55.2464220],
          [-2.6105030, 55.2464330],
          [-2.6105390, 55.2464450],
          [-2.6105740, 55.2464560],
          [-2.6106090, 55.2464670],
          [-2.6106460, 55.2464790],
          [-2.6106860, 55.2464940],
          [-2.6107240, 55.2465020],
          [-2.6107620, 55.2465130],
          [-2.6108020, 55.2465170],
          [-2.6108410, 55.2465250],
          [-2.6108820, 55.2465290],
          [-2.6109240, 55.2465250],
          [-2.6109660, 55.2465250],
          [-2.6110040, 55.2465170],
          [-2.6110410, 55.2465100],
          [-2.6110790, 55.2464980],
          [-2.6111130, 55.2464870],
          [-2.6111450, 55.2464760],
          [-2.6111800, 55.2464640],
          [-2.6112010, 55.2464530],
          [-2.6112240, 55.2464410],
          [-2.6112510, 55.2464220],
          [-2.6112750, 55.2464070],
          [-2.6112960, 55.2463880],
          [-2.6113200, 55.2463690],
          [-2.6113430, 55.2463530],
          [-2.6113610, 55.2463350],
          [-2.6113800, 55.2463150],
          [-2.6113960, 55.2462960],
          [-2.6114110, 55.2462770],
          [-2.6114340, 55.2462550],
          [-2.6114600, 55.2462310],
          [-2.6114860, 55.2462120],
          [-2.6115160, 55.2461930],
          [-2.6115430, 55.2461740],
          [-2.6115650, 55.2461560],
          [-2.6115890, 55.2461330],
          [-2.6116110, 55.2461130],
          [-2.6116300, 55.2460940],
          [-2.6116480, 55.2460710],
          [-2.6116730, 55.2460520],
          [-2.6116950, 55.2460290],
          [-2.6117150, 55.2460060],
          [-2.6117400, 55.2459830],
          [-2.6117630, 55.2459570],
          [-2.6117850, 55.2459270],
          [-2.6118100, 55.2458970],
          [-2.6118390, 55.2458620],
          [-2.6118690, 55.2458280],
          [-2.6118990, 55.2457860],
          [-2.6119280, 55.2457440],
          [-2.6119600, 55.2456990],
          [-2.6119980, 55.2456520],
          [-2.6120390, 55.2456020],
          [-2.6120860, 55.2455490],
          [-2.6121380, 55.2454890],
          [-2.6122000, 55.2454280],
          [-2.6122780, 55.2453660],
          [-2.6123580, 55.2453150],
          [-2.6124500, 55.2452640],
          [-2.6125460, 55.2452170],
          [-2.6126390, 55.2451790],
          [-2.6127250, 55.2451490],
          [-2.6128150, 55.2451140],
          [-2.6129110, 55.2450880],
          [-2.6130180, 55.2450720],
          [-2.6131240, 55.2450600],
          [-2.6132310, 55.2450560],
          [-2.6133260, 55.2450450],
          [-2.6134200, 55.2450330],
          [-2.6135110, 55.2450220],
          [-2.6135940, 55.2450070],
          [-2.6136790, 55.2449880],
          [-2.6137670, 55.2449650],
          [-2.6138490, 55.2449420],
          [-2.6139320, 55.2449190],
          [-2.6140040, 55.2448930],
          [-2.6140790, 55.2448660],
          [-2.6141390, 55.2448480],
          [-2.6142010, 55.2448280],
          [-2.6142790, 55.2448090],
          [-2.6143760, 55.2447860],
          [-2.6144940, 55.2447700],
          [-2.6146070, 55.2447590],
          [-2.6147510, 55.2447550],
          [-2.6148880, 55.2447550],
          [-2.6150390, 55.2447590],
          [-2.6151940, 55.2447700],
          [-2.6153770, 55.2447890],
          [-2.6155470, 55.2448080],
          [-2.6156970, 55.2448270],
          [-2.6158360, 55.2448460],
          [-2.6159600, 55.2448570],
          [-2.6160780, 55.2448730],
          [-2.6161850, 55.2448910],
          [-2.6162850, 55.2449110],
          [-2.6163780, 55.2449260],
          [-2.6164700, 55.2449380],
          [-2.6165610, 55.2449530],
          [-2.6166460, 55.2449680],
          [-2.6167300, 55.2449870],
          [-2.6168110, 55.2450030],
          [-2.6168810, 55.2450170],
          [-2.6169610, 55.2450330],
          [-2.6170310, 55.2450480],
          [-2.6171020, 55.2450670],
          [-2.6171700, 55.2450830],
          [-2.6172360, 55.2451010],
          [-2.6173040, 55.2451170],
          [-2.6173700, 55.2451360],
          [-2.6174400, 55.2451470],
          [-2.6175100, 55.2451630],
          [-2.6175930, 55.2451910],
          [-2.6176750, 55.2452200],
          [-2.6177570, 55.2452490],
          [-2.6178260, 55.2452720],
          [-2.6178900, 55.2452770],
          [-2.6179770, 55.2452660],
          [-2.6180510, 55.2452810],
          [-2.6181230, 55.2452890],
          [-2.6181900, 55.2452960],
          [-2.6182500, 55.2453110],
          [-2.6183040, 55.2453270],
          [-2.6183610, 55.2453380],
          [-2.6184160, 55.2453490],
          [-2.6184730, 55.2453570],
          [-2.6185250, 55.2453720],
          [-2.6185770, 55.2453800],
          [-2.6186310, 55.2453920],
          [-2.6186840, 55.2454030],
          [-2.6187370, 55.2454110],
          [-2.6187890, 55.2454180],
          [-2.6188410, 55.2454300],
          [-2.6188900, 55.2454400],
          [-2.6189490, 55.2454530],
          [-2.6190040, 55.2454640],
          [-2.6190600, 55.2454750],
          [-2.6191150, 55.2454870],
          [-2.6191710, 55.2454990],
          [-2.6192280, 55.2455100],
          [-2.6192860, 55.2455210],
          [-2.6193440, 55.2455330],
          [-2.6193960, 55.2455430],
          [-2.6194580, 55.2455560],
          [-2.6195130, 55.2455670],
          [-2.6195680, 55.2455780],
          [-2.6196280, 55.2455900],
          [-2.6196830, 55.2456010],
          [-2.6197350, 55.2456090],
          [-2.6197930, 55.2456210],
          [-2.6198460, 55.2456280],
          [-2.6198980, 55.2456360],
          [-2.6199510, 55.2456400],
          [-2.6200030, 55.2456470],
          [-2.6200490, 55.2456510],
          [-2.6201000, 55.2456550],
          [-2.6201490, 55.2456550],
          [-2.6202010, 55.2456550],
          [-2.6202460, 55.2456520],
          [-2.6202980, 55.2456480],
          [-2.6203470, 55.2456440],
          [-2.6203960, 55.2456400],
          [-2.6204380, 55.2456330],
          [-2.6204840, 55.2456210],
          [-2.6205280, 55.2456130],
          [-2.6205710, 55.2456060],
          [-2.6206100, 55.2455980],
          [-2.6206550, 55.2455860],
          [-2.6206930, 55.2455790],
          [-2.6207310, 55.2455710],
          [-2.6207690, 55.2455640],
          [-2.6208090, 55.2455520],
          [-2.6208450, 55.2455450],
          [-2.6208840, 55.2455330],
          [-2.6209230, 55.2455220],
          [-2.6209640, 55.2455100],
          [-2.6210050, 55.2454990],
          [-2.6210440, 55.2454880],
          [-2.6210820, 55.2454760],
          [-2.6211200, 55.2454650],
          [-2.6211590, 55.2454530],
          [-2.6211990, 55.2454420],
          [-2.6212360, 55.2454310],
          [-2.6212740, 55.2454190],
          [-2.6213150, 55.2454110],
          [-2.6213570, 55.2453960],
          [-2.6213990, 55.2453850],
          [-2.6214460, 55.2453690],
          [-2.6214940, 55.2453580],
          [-2.6215400, 55.2453430],
          [-2.6215840, 55.2453280],
          [-2.6216360, 55.2453130],
          [-2.6216860, 55.2452970],
          [-2.6217370, 55.2452860],
          [-2.6217930, 55.2452700],
          [-2.6218480, 55.2452550],
          [-2.6219000, 55.2452440],
          [-2.6219610, 55.2452280],
          [-2.6220190, 55.2452090],
          [-2.6220750, 55.2451950],
          [-2.6221370, 55.2451790],
          [-2.6222010, 55.2451630],
          [-2.6222570, 55.2451490],
          [-2.6223220, 55.2451330],
          [-2.6223760, 55.2451220],
          [-2.6224360, 55.2451070],
          [-2.6224930, 55.2450950],
          [-2.6225490, 55.2450830],
          [-2.6226000, 55.2450730],
          [-2.6226580, 55.2450610],
          [-2.6227140, 55.2450490],
          [-2.6227670, 55.2450420],
          [-2.6228270, 55.2450340],
          [-2.6228830, 55.2450230],
          [-2.6229400, 55.2450150],
          [-2.6230040, 55.2450070],
          [-2.6230700, 55.2450030],
          [-2.6231390, 55.2449960],
          [-2.6232190, 55.2449910],
          [-2.6232970, 55.2449840],
          [-2.6233730, 55.2449800],
          [-2.6234560, 55.2449730],
          [-2.6235400, 55.2449650],
          [-2.6236240, 55.2449570],
          [-2.6237030, 55.2449460],
          [-2.6237840, 55.2449380],
          [-2.6238600, 55.2449270],
          [-2.6239320, 55.2449150],
          [-2.6240050, 55.2449040],
          [-2.6240770, 55.2448880],
          [-2.6241450, 55.2448770],
          [-2.6242110, 55.2448660],
          [-2.6242730, 55.2448590],
          [-2.6243370, 55.2448470],
          [-2.6243920, 55.2448350],
          [-2.6244460, 55.2448240],
          [-2.6245000, 55.2448100],
          [-2.6245540, 55.2447970],
          [-2.6246110, 55.2447890],
          [-2.6246670, 55.2447820],
          [-2.6247270, 55.2447700],
          [-2.6247860, 55.2447660],
          [-2.6248470, 55.2447630],
          [-2.6249060, 55.2447550],
          [-2.6249630, 55.2447440],
          [-2.6250200, 55.2447320],
          [-2.6250770, 55.2447210],
          [-2.6251310, 55.2447130],
          [-2.6251870, 55.2447020],
          [-2.6252390, 55.2446940],
          [-2.6252890, 55.2446830],
          [-2.6253370, 55.2446710],
          [-2.6253820, 55.2446600],
          [-2.6254260, 55.2446440],
          [-2.6254640, 55.2446290],
          [-2.6255020, 55.2446140],
          [-2.6255410, 55.2445990],
          [-2.6255780, 55.2445800],
          [-2.6256140, 55.2445640],
          [-2.6256510, 55.2445450],
          [-2.6256860, 55.2445300],
          [-2.6257190, 55.2445070],
          [-2.6257510, 55.2444880],
          [-2.6257860, 55.2444650],
          [-2.6258230, 55.2444430],
          [-2.6258570, 55.2444230],
          [-2.6258910, 55.2444000],
          [-2.6259260, 55.2443740],
          [-2.6259600, 55.2443510],
          [-2.6259960, 55.2443240],
          [-2.6260310, 55.2442970],
          [-2.6260640, 55.2442710],
          [-2.6261030, 55.2442480],
          [-2.6261400, 55.2442250],
          [-2.6261740, 55.2442020],
          [-2.6262100, 55.2441790],
          [-2.6262410, 55.2441560],
          [-2.6262790, 55.2441370],
          [-2.6263170, 55.2441140],
          [-2.6263540, 55.2440910],
          [-2.6263940, 55.2440720],
          [-2.6264330, 55.2440530],
          [-2.6264710, 55.2440340],
          [-2.6265080, 55.2440190],
          [-2.6265380, 55.2439960],
          [-2.6265690, 55.2439770],
          [-2.6266060, 55.2439620],
          [-2.6266420, 55.2439390],
          [-2.6266790, 55.2439240],
          [-2.6267160, 55.2439010],
          [-2.6267490, 55.2438820],
          [-2.6267860, 55.2438660],
          [-2.6268260, 55.2438470],
          [-2.6268660, 55.2438280],
          [-2.6269090, 55.2438090],
          [-2.6269560, 55.2437860],
          [-2.6270030, 55.2437630],
          [-2.6270540, 55.2437410],
          [-2.6271060, 55.2437250],
          [-2.6271580, 55.2436990],
          [-2.6272070, 55.2436760],
          [-2.6272580, 55.2436530],
          [-2.6273030, 55.2436340],
          [-2.6273400, 55.2436180],
          [-2.6273710, 55.2436030],
          [-2.6274030, 55.2435880],
          [-2.6274380, 55.2435690],
          [-2.6274790, 55.2435530],
          [-2.6275210, 55.2435380],
          [-2.6275610, 55.2435190],
          [-2.6276020, 55.2435000],
          [-2.6276430, 55.2434700],
          [-2.6276800, 55.2434350],
          [-2.6277180, 55.2434060],
          [-2.6277590, 55.2433710],
          [-2.6277900, 55.2433440],
          [-2.6278150, 55.2433150],
          [-2.6278440, 55.2432790],
          [-2.6278680, 55.2432520],
          [-2.6278880, 55.2432260],
          [-2.6279100, 55.2431990],
          [-2.6279280, 55.2431720],
          [-2.6279460, 55.2431460],
          [-2.6279630, 55.2431220],
          [-2.6279830, 55.2431030],
          [-2.6280050, 55.2430810],
          [-2.6280230, 55.2430610],
          [-2.6280380, 55.2430390],
          [-2.6280520, 55.2430160],
          [-2.6280670, 55.2429960],
          [-2.6280780, 55.2429740],
          [-2.6280910, 55.2429510],
          [-2.6281050, 55.2429280],
          [-2.6281180, 55.2429050],
          [-2.6281330, 55.2428820],
          [-2.6281480, 55.2428600],
          [-2.6281630, 55.2428330],
          [-2.6281830, 55.2428060],
          [-2.6282050, 55.2427750],
          [-2.6282220, 55.2427450],
          [-2.6282400, 55.2427110],
          [-2.6282580, 55.2426800],
          [-2.6282800, 55.2426420],
          [-2.6283010, 55.2426080],
          [-2.6283230, 55.2425730],
          [-2.6283450, 55.2425390],
          [-2.6283650, 55.2425030],
          [-2.6283850, 55.2424660],
          [-2.6284070, 55.2424280],
          [-2.6284280, 55.2423940],
          [-2.6284510, 55.2423600],
          [-2.6284730, 55.2423250],
          [-2.6284900, 55.2422870],
          [-2.6285060, 55.2422530],
          [-2.6285220, 55.2422180],
          [-2.6285400, 55.2421840],
          [-2.6285560, 55.2421460],
          [-2.6285730, 55.2421120],
          [-2.6285880, 55.2420810],
          [-2.6286010, 55.2420510],
          [-2.6286160, 55.2420200],
          [-2.6286300, 55.2419860],
          [-2.6286430, 55.2419560],
          [-2.6286580, 55.2419250],
          [-2.6286750, 55.2418980],
          [-2.6286880, 55.2418710],
          [-2.6287030, 55.2418410],
          [-2.6287200, 55.2418140],
          [-2.6287320, 55.2417840],
          [-2.6287410, 55.2417570],
          [-2.6287520, 55.2417260],
          [-2.6287610, 55.2416960],
          [-2.6287720, 55.2416650],
          [-2.6287810, 55.2416310],
          [-2.6287900, 55.2415970],
          [-2.6287970, 55.2415620],
          [-2.6288000, 55.2415280],
          [-2.6288060, 55.2414910],
          [-2.6288130, 55.2414560],
          [-2.6288170, 55.2414170],
          [-2.6288200, 55.2413830],
          [-2.6288230, 55.2413490],
          [-2.6288270, 55.2413110],
          [-2.6288270, 55.2412760],
          [-2.6288270, 55.2412380],
          [-2.6288250, 55.2412040],
          [-2.6288230, 55.2411660],
          [-2.6288200, 55.2411310],
          [-2.6288170, 55.2410970],
          [-2.6288130, 55.2410600],
          [-2.6288100, 55.2410240],
          [-2.6288080, 55.2409900],
          [-2.6288070, 55.2409530],
          [-2.6288050, 55.2409180],
          [-2.6288030, 55.2408830],
          [-2.6288030, 55.2408490],
          [-2.6288060, 55.2408150],
          [-2.6288080, 55.2407760],
          [-2.6288100, 55.2407420],
          [-2.6288120, 55.2407080],
          [-2.6288180, 55.2406740],
          [-2.6288230, 55.2406360],
          [-2.6288250, 55.2405980],
          [-2.6288280, 55.2405630],
          [-2.6288330, 55.2405250],
          [-2.6288400, 55.2404900],
          [-2.6288460, 55.2404530],
          [-2.6288530, 55.2404140],
          [-2.6288630, 55.2403760],
          [-2.6288720, 55.2403380],
          [-2.6288780, 55.2402960],
          [-2.6288870, 55.2402580],
          [-2.6288970, 55.2402160],
          [-2.6289080, 55.2401750],
          [-2.6289210, 55.2401290],
          [-2.6289350, 55.2400820],
          [-2.6289470, 55.2400370],
          [-2.6289580, 55.2399910],
          [-2.6289700, 55.2399420],
          [-2.6289810, 55.2398960],
          [-2.6289930, 55.2398470],
          [-2.6290030, 55.2398010],
          [-2.6290150, 55.2397510],
          [-2.6290280, 55.2397050],
          [-2.6290420, 55.2396550],
          [-2.6290530, 55.2396100],
          [-2.6290650, 55.2395640],
          [-2.6290710, 55.2395230],
          [-2.6290770, 55.2394830],
          [-2.6290830, 55.2394430],
          [-2.6290900, 55.2394050],
          [-2.6290970, 55.2393620],
          [-2.6291030, 55.2393240],
          [-2.6291070, 55.2392810],
          [-2.6291080, 55.2392440],
          [-2.6291080, 55.2392050],
          [-2.6291080, 55.2391680],
          [-2.6291080, 55.2391290],
          [-2.6291120, 55.2390910],
          [-2.6291150, 55.2390600],
          [-2.6291140, 55.2390270],
          [-2.6291100, 55.2389960],
          [-2.6291050, 55.2389650],
          [-2.6291020, 55.2389340],
          [-2.6291020, 55.2389070],
          [-2.6290990, 55.2388850],
          [-2.6290950, 55.2388580],
          [-2.6290890, 55.2388350],
          [-2.6290840, 55.2388090],
          [-2.6290770, 55.2387850],
          [-2.6290720, 55.2387590],
          [-2.6290720, 55.2387360],
          [-2.6290700, 55.2387130],
          [-2.6290700, 55.2386900],
          [-2.6290680, 55.2386640],
          [-2.6290640, 55.2386370],
          [-2.6290570, 55.2386140],
          [-2.6290520, 55.2385870],
          [-2.6290480, 55.2385640],
          [-2.6290470, 55.2385410],
          [-2.6290420, 55.2385180],
          [-2.6290340, 55.2384950],
          [-2.6290240, 55.2384760],
          [-2.6290140, 55.2384500],
          [-2.6290030, 55.2384260],
          [-2.6289950, 55.2384040],
          [-2.6289870, 55.2383810],
          [-2.6289800, 55.2383620],
          [-2.6289720, 55.2383430],
          [-2.6289620, 55.2383240],
          [-2.6289500, 55.2383010],
          [-2.6289390, 55.2382810],
          [-2.6289270, 55.2382630],
          [-2.6289170, 55.2382430],
          [-2.6289070, 55.2382240],
          [-2.6288910, 55.2382060],
          [-2.6288750, 55.2381830],
          [-2.6288550, 55.2381630],
          [-2.6288350, 55.2381410],
          [-2.6288110, 55.2381220],
          [-2.6287920, 55.2380990],
          [-2.6287710, 55.2380760],
          [-2.6287450, 55.2380490],
          [-2.6287200, 55.2380260],
          [-2.6286960, 55.2380000],
          [-2.6286760, 55.2379690],
          [-2.6286550, 55.2379420],
          [-2.6286350, 55.2379120],
          [-2.6286170, 55.2378780],
          [-2.6286000, 55.2378430],
          [-2.6285840, 55.2378090],
          [-2.6285670, 55.2377710],
          [-2.6285500, 55.2377360],
          [-2.6285350, 55.2376980],
          [-2.6285200, 55.2376640],
          [-2.6285050, 55.2376260],
          [-2.6284920, 55.2375920],
          [-2.6284800, 55.2375530],
          [-2.6284690, 55.2375190],
          [-2.6284550, 55.2374810],
          [-2.6284440, 55.2374430],
          [-2.6284300, 55.2374050],
          [-2.6284160, 55.2373630],
          [-2.6284020, 55.2373250],
          [-2.6283850, 55.2372860],
          [-2.6283690, 55.2372490],
          [-2.6283540, 55.2372100],
          [-2.6283400, 55.2371720],
          [-2.6283240, 55.2371370],
          [-2.6283090, 55.2371000],
          [-2.6282940, 55.2370650],
          [-2.6282770, 55.2370310],
          [-2.6282570, 55.2370000],
          [-2.6282410, 55.2369660],
          [-2.6282290, 55.2369360],
          [-2.6282140, 55.2369050],
          [-2.6281970, 55.2368780],
          [-2.6281770, 55.2368510],
          [-2.6281540, 55.2368250],
          [-2.6281350, 55.2368020],
          [-2.6281170, 55.2367750],
          [-2.6280990, 55.2367520],
          [-2.6280840, 55.2367250],
          [-2.6280650, 55.2367020],
          [-2.6280430, 55.2366840],
          [-2.6280110, 55.2366600],
          [-2.6279760, 55.2366420],
          [-2.6279330, 55.2366260],
          [-2.6278860, 55.2366220],
          [-2.6278430, 55.2366220],
          [-2.6278030, 55.2366290],
          [-2.6277640, 55.2366410],
          [-2.6277270, 55.2366630],
          [-2.6276930, 55.2366820],
          [-2.6276530, 55.2367050],
          [-2.6276110, 55.2367250],
          [-2.6275740, 55.2367510],
          [-2.6275420, 55.2367780],
          [-2.6275070, 55.2368010],
          [-2.6274690, 55.2368240],
          [-2.6274280, 55.2368470],
          [-2.6273890, 55.2368690],
          [-2.6273490, 55.2368920],
          [-2.6273090, 55.2369190],
          [-2.6272690, 55.2369420],
          [-2.6272270, 55.2369650],
          [-2.6271880, 55.2369910],
          [-2.6271470, 55.2370140],
          [-2.6271060, 55.2370370],
          [-2.6270660, 55.2370600],
          [-2.6270290, 55.2370870],
          [-2.6269880, 55.2371100],
          [-2.6269440, 55.2371330],
          [-2.6268990, 55.2371520],
          [-2.6268530, 55.2371740],
          [-2.6268030, 55.2371940],
          [-2.6267450, 55.2372130],
          [-2.6266780, 55.2372280],
          [-2.6266060, 55.2372470],
          [-2.6265340, 55.2372650],
          [-2.6264540, 55.2372880],
          [-2.6263700, 55.2373120],
          [-2.6262840, 55.2373340],
          [-2.6261930, 55.2373530],
          [-2.6260900, 55.2373760],
          [-2.6259810, 55.2373990],
          [-2.6258650, 55.2374220],
          [-2.6257440, 55.2374450],
          [-2.6256210, 55.2374650],
          [-2.6254990, 55.2374870],
          [-2.6253750, 55.2375060],
          [-2.6252490, 55.2375290],
          [-2.6251200, 55.2375480],
          [-2.6249990, 55.2375700],
          [-2.6248640, 55.2375940],
          [-2.6247340, 55.2376170],
          [-2.6246000, 55.2376360],
          [-2.6244620, 55.2376590],
          [-2.6243250, 55.2376850],
          [-2.6241840, 55.2377120],
          [-2.6240470, 55.2377390],
          [-2.6239070, 55.2377700],
          [-2.6237690, 55.2378000],
          [-2.6236270, 55.2378270],
          [-2.6234800, 55.2378460],
          [-2.6233350, 55.2378580],
          [-2.6231930, 55.2378620],
          [-2.6230510, 55.2378580],
          [-2.6229070, 55.2378500],
          [-2.6227590, 55.2378430],
          [-2.6226120, 55.2378310],
          [-2.6224690, 55.2378240],
          [-2.6223290, 55.2378200],
          [-2.6221880, 55.2378120],
          [-2.6220490, 55.2378080],
          [-2.6219140, 55.2378010],
          [-2.6217770, 55.2377970],
          [-2.6216470, 55.2377860],
          [-2.6215170, 55.2377820],
          [-2.6213930, 55.2377700],
          [-2.6212680, 55.2377660],
          [-2.6211470, 55.2377590],
          [-2.6210290, 55.2377510],
          [-2.6209070, 55.2377400],
          [-2.6207920, 55.2377320],
          [-2.6206760, 55.2377210],
          [-2.6205630, 55.2377130],
          [-2.6204470, 55.2377020],
          [-2.6203370, 55.2376940],
          [-2.6202250, 55.2376790],
          [-2.6201150, 55.2376630],
          [-2.6200050, 55.2376520],
          [-2.6198990, 55.2376330],
          [-2.6197980, 55.2376110],
          [-2.6197000, 55.2375910],
          [-2.6196140, 55.2375650],
          [-2.6195320, 55.2375420],
          [-2.6194600, 55.2375230],
          [-2.6193860, 55.2375030],
          [-2.6193240, 55.2374840],
          [-2.6192650, 55.2374660],
          [-2.6192050, 55.2374500],
          [-2.6191470, 55.2374350],
          [-2.6190930, 55.2374230],
          [-2.6190260, 55.2374120],
          [-2.6189530, 55.2374000],
          [-2.6188740, 55.2373960],
          [-2.6187880, 55.2373890],
          [-2.6186890, 55.2373890],
          [-2.6185840, 55.2373920],
          [-2.6184730, 55.2373960],
          [-2.6183560, 55.2373930],
          [-2.6182430, 55.2373850],
          [-2.6181360, 55.2373740],
          [-2.6180310, 55.2373580],
          [-2.6179340, 55.2373440],
          [-2.6178340, 55.2373320],
          [-2.6177360, 55.2373130],
          [-2.6176380, 55.2372940],
          [-2.6175360, 55.2372820],
          [-2.6174250, 55.2372670],
          [-2.6173200, 55.2372550],
          [-2.6172090, 55.2372400],
          [-2.6170960, 55.2372320],
          [-2.6169800, 55.2372250],
          [-2.6168620, 55.2372250],
          [-2.6167300, 55.2372250],
          [-2.6165920, 55.2372280],
          [-2.6164520, 55.2372280],
          [-2.6163040, 55.2372280],
          [-2.6161490, 55.2372280],
          [-2.6159940, 55.2372320],
          [-2.6158310, 55.2372320],
          [-2.6156700, 55.2372360],
          [-2.6155050, 55.2372360],
          [-2.6153450, 55.2372400],
          [-2.6151890, 55.2372400],
          [-2.6150300, 55.2372440],
          [-2.6148710, 55.2372440],
          [-2.6147120, 55.2372470],
          [-2.6145510, 55.2372470],
          [-2.6143850, 55.2372470],
          [-2.6142210, 55.2372470],
          [-2.6140560, 55.2372510],
          [-2.6138910, 55.2372510],
          [-2.6137190, 55.2372550],
          [-2.6135470, 55.2372510],
          [-2.6133760, 55.2372470],
          [-2.6132100, 55.2372370],
          [-2.6130410, 55.2372210],
          [-2.6128950, 55.2372020],
          [-2.6127390, 55.2371640],
          [-2.6125670, 55.2371150],
          [-2.6123890, 55.2370540],
          [-2.6121960, 55.2369940],
          [-2.6120210, 55.2369360],
          [-2.6118410, 55.2368930],
          [-2.6116740, 55.2368440],
          [-2.6114940, 55.2367980],
          [-2.6113090, 55.2367490],
          [-2.6111250, 55.2366990],
          [-2.6109490, 55.2366490],
          [-2.6107660, 55.2365880],
          [-2.6105780, 55.2365270],
          [-2.6103870, 55.2364670],
          [-2.6102080, 55.2363940],
          [-2.6100310, 55.2363180],
          [-2.6098520, 55.2362420],
          [-2.6096660, 55.2361690],
          [-2.6094890, 55.2361040],
          [-2.6093260, 55.2360390],
          [-2.6091780, 55.2359700],
          [-2.6090470, 55.2358950],
          [-2.6089040, 55.2358150],
          [-2.6087820, 55.2357230],
          [-2.6087060, 55.2356390],
          [-2.6086350, 55.2355630],
          [-2.6085310, 55.2354980],
          [-2.6083820, 55.2354670],
          [-2.6082110, 55.2354550],
          [-2.6080390, 55.2354550],
          [-2.6078590, 55.2354850],
          [-2.6076890, 55.2355490],
          [-2.6075520, 55.2356210],
          [-2.6074500, 55.2357010],
          [-2.6073740, 55.2358000],
          [-2.6073060, 55.2359000],
          [-2.6072410, 55.2359990],
          [-2.6071510, 55.2360830],
          [-2.6070410, 55.2361710],
          [-2.6068820, 55.2362060],
          [-2.6066990, 55.2362170],
          [-2.6064990, 55.2362210],
          [-2.6062820, 55.2362180],
          [-2.6060600, 55.2362060],
          [-2.6058480, 55.2362020],
          [-2.6056430, 55.2362060],
          [-2.6054420, 55.2362100],
          [-2.6052330, 55.2362060],
          [-2.6050370, 55.2361990],
          [-2.6048470, 55.2361950],
          [-2.6046570, 55.2361830],
          [-2.6044690, 55.2361680],
          [-2.6042830, 55.2361450],
          [-2.6041090, 55.2361110],
          [-2.6039590, 55.2360660],
          [-2.6038230, 55.2360080],
          [-2.6036960, 55.2359510],
          [-2.6035720, 55.2359020],
          [-2.6034430, 55.2358670],
          [-2.6033250, 55.2358400],
          [-2.6032140, 55.2358250],
          [-2.6031030, 55.2358100],
          [-2.6029880, 55.2357910],
          [-2.6028650, 55.2357750],
          [-2.6027460, 55.2357490],
          [-2.6026300, 55.2357220],
          [-2.6025170, 55.2356880],
          [-2.6023990, 55.2356570],
          [-2.6022810, 55.2356150],
          [-2.6021690, 55.2355740],
          [-2.6020640, 55.2355240],
          [-2.6019650, 55.2354710],
          [-2.6018800, 55.2354140],
          [-2.6018050, 55.2353530],
          [-2.6017310, 55.2352870],
          [-2.6016630, 55.2352230],
          [-2.6015910, 55.2351540],
          [-2.6015180, 55.2350890],
          [-2.6014410, 55.2350170],
          [-2.6013760, 55.2349410],
          [-2.6013190, 55.2348660],
          [-2.6012780, 55.2347850],
          [-2.6012590, 55.2346930],
          [-2.6012630, 55.2346130],
          [-2.6012930, 55.2345330],
          [-2.6013410, 55.2344560],
          [-2.6014020, 55.2343800],
          [-2.6014690, 55.2343040],
          [-2.6015410, 55.2342240],
          [-2.6016320, 55.2341440],
          [-2.6017320, 55.2340630],
          [-2.6018270, 55.2339640],
          [-2.6019030, 55.2338610],
          [-2.6019380, 55.2337510],
          [-2.6019560, 55.2336480],
          [-2.6019370, 55.2335490],
          [-2.6018790, 55.2334490],
          [-2.6017860, 55.2333730],
          [-2.6016750, 55.2333080],
          [-2.6015640, 55.2332540],
          [-2.6014520, 55.2332120],
          [-2.6013470, 55.2331780],
          [-2.6012500, 55.2331480],
          [-2.6011600, 55.2331200],
          [-2.6010810, 55.2330940],
          [-2.6010140, 55.2330710],
          [-2.6009550, 55.2330520],
          [-2.6008960, 55.2330410],
          [-2.6008460, 55.2330220],
          [-2.6008020, 55.2330060],
          [-2.6007660, 55.2329830],
          [-2.6007270, 55.2329640],
          [-2.6006910, 55.2329450],
          [-2.6006520, 55.2329260],
          [-2.6006220, 55.2329070],
          [-2.6005900, 55.2328840],
          [-2.6005490, 55.2328680],
          [-2.6005030, 55.2328530],
          [-2.6004640, 55.2328420],
          [-2.6004280, 55.2328270],
          [-2.6003910, 55.2328150],
          [-2.6003460, 55.2328030],
          [-2.6002920, 55.2327850],
          [-2.6002310, 55.2327630],
          [-2.6001680, 55.2327390],
          [-2.6001030, 55.2327160],
          [-2.6000360, 55.2326940],
          [-2.5999710, 55.2326660],
          [-2.5999060, 55.2326400],
          [-2.5998430, 55.2326130],
          [-2.5997860, 55.2325860],
          [-2.5997210, 55.2325520],
          [-2.5996610, 55.2325250],
          [-2.5996090, 55.2324960],
          [-2.5995640, 55.2324600],
          [-2.5995220, 55.2324230],
          [-2.5994710, 55.2323880],
          [-2.5994220, 55.2323460],
          [-2.5993860, 55.2323010],
          [-2.5993600, 55.2322590],
          [-2.5993400, 55.2322170],
          [-2.5993250, 55.2321710],
          [-2.5993150, 55.2321330],
          [-2.5993070, 55.2320910],
          [-2.5993000, 55.2320450],
          [-2.5993090, 55.2320010],
          [-2.5993130, 55.2319500],
          [-2.5993150, 55.2319000],
          [-2.5993200, 55.2318520],
          [-2.5993280, 55.2317970],
          [-2.5993380, 55.2317430],
          [-2.5993480, 55.2316870],
          [-2.5993630, 55.2316330],
          [-2.5993730, 55.2315760],
          [-2.5993800, 55.2315150],
          [-2.5993850, 55.2314570],
          [-2.5993920, 55.2313970],
          [-2.5993980, 55.2313400],
          [-2.5994080, 55.2312830],
          [-2.5994130, 55.2312190],
          [-2.5994200, 55.2311570],
          [-2.5994270, 55.2310910],
          [-2.5994300, 55.2310280],
          [-2.5994330, 55.2309690],
          [-2.5994370, 55.2309160],
          [-2.5994400, 55.2308660],
          [-2.5994450, 55.2308170],
          [-2.5994480, 55.2307650],
          [-2.5994480, 55.2307060],
          [-2.5994480, 55.2306450],
          [-2.5994500, 55.2305840],
          [-2.5994500, 55.2305150],
          [-2.5994500, 55.2304530],
          [-2.5994500, 55.2303830],
          [-2.5994500, 55.2303170],
          [-2.5994480, 55.2302480],
          [-2.5994440, 55.2301840],
          [-2.5994300, 55.2301150],
          [-2.5994090, 55.2300460],
          [-2.5993760, 55.2299820],
          [-2.5993340, 55.2299170],
          [-2.5992820, 55.2298480],
          [-2.5992370, 55.2297720],
          [-2.5991920, 55.2296950],
          [-2.5991460, 55.2296120],
          [-2.5990940, 55.2295240],
          [-2.5990440, 55.2294360],
          [-2.5989990, 55.2293420],
          [-2.5989600, 55.2292340],
          [-2.5989290, 55.2291310],
          [-2.5988940, 55.2290280],
          [-2.5988490, 55.2289290],
          [-2.5987980, 55.2288340],
          [-2.5987170, 55.2287450],
          [-2.5986230, 55.2286420],
          [-2.5985160, 55.2285510],
          [-2.5984020, 55.2284790],
          [-2.5982730, 55.2283980],
          [-2.5981570, 55.2283220],
          [-2.5980260, 55.2282470],
          [-2.5978880, 55.2281700],
          [-2.5977540, 55.2280900],
          [-2.5976240, 55.2280020],
          [-2.5975060, 55.2279060],
          [-2.5974130, 55.2278120],
          [-2.5973290, 55.2277020],
          [-2.5972510, 55.2275900],
          [-2.5971480, 55.2274800],
          [-2.5969960, 55.2273770],
          [-2.5968300, 55.2272810],
          [-2.5966690, 55.2271860],
          [-2.5965040, 55.2270830],
          [-2.5963800, 55.2269810],
          [-2.5962340, 55.2268650],
          [-2.5960910, 55.2267570],
          [-2.5959540, 55.2266490],
          [-2.5958110, 55.2265290],
          [-2.5956590, 55.2264260],
          [-2.5954920, 55.2263200],
          [-2.5953150, 55.2262160],
          [-2.5951690, 55.2261030],
          [-2.5950570, 55.2259840],
          [-2.5949520, 55.2258720],
          [-2.5948650, 55.2257460],
          [-2.5947940, 55.2256220],
          [-2.5947160, 55.2255130],
          [-2.5946410, 55.2254080],
          [-2.5945830, 55.2253130],
          [-2.5945390, 55.2252250],
          [-2.5944990, 55.2251300],
          [-2.5944390, 55.2250450],
          [-2.5943840, 55.2249580],
          [-2.5943400, 55.2248650],
          [-2.5942760, 55.2247740],
          [-2.5942170, 55.2246930],
          [-2.5941700, 55.2246250],
          [-2.5941300, 55.2245560],
          [-2.5940980, 55.2244930],
          [-2.5940570, 55.2244270],
          [-2.5940240, 55.2243600],
          [-2.5939900, 55.2242930],
          [-2.5939520, 55.2242250],
          [-2.5939180, 55.2241620],
          [-2.5938800, 55.2240950],
          [-2.5938440, 55.2240270],
          [-2.5938120, 55.2239580],
          [-2.5937790, 55.2238890],
          [-2.5937420, 55.2238320],
          [-2.5937030, 55.2237680],
          [-2.5936610, 55.2237020],
          [-2.5936220, 55.2236360],
          [-2.5935740, 55.2235650],
          [-2.5935310, 55.2234960],
          [-2.5934890, 55.2234270],
          [-2.5934530, 55.2233580],
          [-2.5934140, 55.2232900],
          [-2.5933790, 55.2232220],
          [-2.5933420, 55.2231530],
          [-2.5933030, 55.2230890],
          [-2.5932540, 55.2230230],
          [-2.5932070, 55.2229590],
          [-2.5931670, 55.2228860],
          [-2.5931250, 55.2228120],
          [-2.5930790, 55.2227340],
          [-2.5930390, 55.2226530],
          [-2.5929940, 55.2225700],
          [-2.5929570, 55.2224820],
          [-2.5929060, 55.2223980],
          [-2.5928480, 55.2223110],
          [-2.5927940, 55.2222160],
          [-2.5927530, 55.2221280],
          [-2.5927040, 55.2220280],
          [-2.5926560, 55.2219290],
          [-2.5926030, 55.2218280],
          [-2.5925500, 55.2217300],
          [-2.5924910, 55.2216320],
          [-2.5924310, 55.2215240],
          [-2.5923740, 55.2214180],
          [-2.5923150, 55.2213130],
          [-2.5922550, 55.2212030],
          [-2.5921930, 55.2210950],
          [-2.5921270, 55.2209820],
          [-2.5920630, 55.2208660],
          [-2.5919940, 55.2207380],
          [-2.5919230, 55.2206120],
          [-2.5918530, 55.2204840],
          [-2.5917730, 55.2203540],
          [-2.5916990, 55.2202200],
          [-2.5916300, 55.2200870],
          [-2.5915760, 55.2199570],
          [-2.5915160, 55.2198460],
          [-2.5914560, 55.2197510],
          [-2.5913950, 55.2196650],
          [-2.5913450, 55.2195750],
          [-2.5913020, 55.2194990],
          [-2.5912560, 55.2194200],
          [-2.5912050, 55.2193380],
          [-2.5911580, 55.2192630],
          [-2.5911060, 55.2191830],
          [-2.5910530, 55.2191010],
          [-2.5910040, 55.2190190],
          [-2.5909550, 55.2189410],
          [-2.5909090, 55.2188650],
          [-2.5908650, 55.2187960],
          [-2.5908260, 55.2187360],
          [-2.5907950, 55.2186800],
          [-2.5907640, 55.2186250],
          [-2.5907290, 55.2185710],
          [-2.5906990, 55.2185220],
          [-2.5906820, 55.2184780],
          [-2.5906640, 55.2184340],
          [-2.5906420, 55.2183960],
          [-2.5906210, 55.2183560],
          [-2.5906000, 55.2183160],
          [-2.5905770, 55.2182740],
          [-2.5905570, 55.2182350],
          [-2.5905350, 55.2181940],
          [-2.5905110, 55.2181530],
          [-2.5904880, 55.2181140],
          [-2.5904590, 55.2180710],
          [-2.5904320, 55.2180330],
          [-2.5904050, 55.2179920],
          [-2.5903790, 55.2179490],
          [-2.5903540, 55.2179080],
          [-2.5903290, 55.2178660],
          [-2.5903030, 55.2178240],
          [-2.5902770, 55.2177780],
          [-2.5902530, 55.2177340],
          [-2.5902240, 55.2176860],
          [-2.5901980, 55.2176410],
          [-2.5901690, 55.2175920],
          [-2.5901410, 55.2175420],
          [-2.5901140, 55.2174920],
          [-2.5900920, 55.2174430],
          [-2.5900900, 55.2174000],
          [-2.5901110, 55.2173600],
          [-2.5901560, 55.2173280],
          [-2.5902100, 55.2173010],
          [-2.5902680, 55.2172820],
          [-2.5903240, 55.2172700],
          [-2.5903820, 55.2172590],
          [-2.5904400, 55.2172470],
          [-2.5904970, 55.2172400],
          [-2.5905520, 55.2172320],
          [-2.5906020, 55.2172280],
          [-2.5906520, 55.2172200],
          [-2.5907020, 55.2172090],
          [-2.5907530, 55.2171980],
          [-2.5908030, 55.2171900],
          [-2.5908520, 55.2171820],
          [-2.5909000, 55.2171710],
          [-2.5909480, 55.2171630],
          [-2.5909940, 55.2171520],
          [-2.5910410, 55.2171410],
          [-2.5910920, 55.2171290],
          [-2.5911410, 55.2171250],
          [-2.5911870, 55.2171140],
          [-2.5912340, 55.2171060],
          [-2.5912790, 55.2170980],
          [-2.5913210, 55.2170910],
          [-2.5913660, 55.2170830],
          [-2.5914130, 55.2170720],
          [-2.5914570, 55.2170640],
          [-2.5915030, 55.2170560],
          [-2.5915470, 55.2170490],
          [-2.5915930, 55.2170410],
          [-2.5916370, 55.2170340],
          [-2.5916810, 55.2170220],
          [-2.5917260, 55.2170140],
          [-2.5917680, 55.2170070],
          [-2.5918110, 55.2170030],
          [-2.5918590, 55.2169920],
          [-2.5919070, 55.2169800],
          [-2.5919510, 55.2169760],
          [-2.5919910, 55.2169690],
          [-2.5920330, 55.2169570],
          [-2.5920750, 55.2169500],
          [-2.5921190, 55.2169420],
          [-2.5921630, 55.2169340],
          [-2.5922040, 55.2169270],
          [-2.5922430, 55.2169190],
          [-2.5922820, 55.2169110],
          [-2.5923190, 55.2169000],
          [-2.5923590, 55.2168960],
          [-2.5923970, 55.2168890],
          [-2.5924320, 55.2168810],
          [-2.5924680, 55.2168730],
          [-2.5925040, 55.2168660],
          [-2.5925430, 55.2168620],
          [-2.5925800, 55.2168540],
          [-2.5926160, 55.2168470],
          [-2.5926530, 55.2168350],
          [-2.5926880, 55.2168310],
          [-2.5927210, 55.2168200],
          [-2.5927540, 55.2168120],
          [-2.5927880, 55.2168080],
          [-2.5928220, 55.2168010],
          [-2.5928570, 55.2167970],
          [-2.5928960, 55.2167930],
          [-2.5929370, 55.2167860],
          [-2.5929710, 55.2167780],
          [-2.5930040, 55.2167740],
          [-2.5930390, 55.2167660],
          [-2.5930730, 55.2167590],
          [-2.5931100, 55.2167510],
          [-2.5931440, 55.2167440],
          [-2.5931810, 55.2167400],
          [-2.5932180, 55.2167320],
          [-2.5932520, 55.2167250],
          [-2.5932880, 55.2167170],
          [-2.5933220, 55.2167090],
          [-2.5933570, 55.2167020],
          [-2.5933910, 55.2166980],
          [-2.5934230, 55.2166940],
          [-2.5934560, 55.2166900],
          [-2.5934890, 55.2166830],
          [-2.5935260, 55.2166750],
          [-2.5935610, 55.2166710],
          [-2.5935960, 55.2166640],
          [-2.5936310, 55.2166600],
          [-2.5936680, 55.2166480],
          [-2.5937030, 55.2166440],
          [-2.5937380, 55.2166370],
          [-2.5937730, 55.2166330],
          [-2.5938090, 55.2166250],
          [-2.5938440, 55.2166180],
          [-2.5938790, 55.2166100],
          [-2.5939140, 55.2166030],
          [-2.5939490, 55.2165950],
          [-2.5939840, 55.2165870],
          [-2.5940190, 55.2165800],
          [-2.5940530, 55.2165720],
          [-2.5940870, 55.2165680],
          [-2.5941230, 55.2165640],
          [-2.5941580, 55.2165570],
          [-2.5941910, 55.2165530],
          [-2.5942240, 55.2165490],
          [-2.5942560, 55.2165450],
          [-2.5942900, 55.2165410],
          [-2.5943200, 55.2165340],
          [-2.5943460, 55.2165300],
          [-2.5943710, 55.2165260],
          [-2.5943990, 55.2165190],
          [-2.5944300, 55.2165150],
          [-2.5944580, 55.2165070],
          [-2.5944880, 55.2165000],
          [-2.5945200, 55.2164920],
          [-2.5945480, 55.2164880],
          [-2.5945760, 55.2164800],
          [-2.5946040, 55.2164760],
          [-2.5946330, 55.2164690],
          [-2.5946610, 55.2164610],
          [-2.5946910, 55.2164540],
          [-2.5947230, 55.2164460],
          [-2.5947560, 55.2164380],
          [-2.5947860, 55.2164310],
          [-2.5948110, 55.2164270],
          [-2.5948360, 55.2164160],
          [-2.5948600, 55.2164040],
          [-2.5948850, 55.2163960],
          [-2.5949110, 55.2163850],
          [-2.5949390, 55.2163740],
          [-2.5949660, 55.2163620],
          [-2.5949910, 55.2163470],
          [-2.5950110, 55.2163350],
          [-2.5950300, 55.2163240],
          [-2.5950510, 55.2163130],
          [-2.5950710, 55.2162980],
          [-2.5950880, 55.2162820],
          [-2.5951050, 55.2162670],
          [-2.5951230, 55.2162520],
          [-2.5951410, 55.2162360],
          [-2.5951600, 55.2162180],
          [-2.5951780, 55.2162020],
          [-2.5951980, 55.2161790],
          [-2.5952180, 55.2161600],
          [-2.5952370, 55.2161410],
          [-2.5952560, 55.2161180],
          [-2.5952760, 55.2160990],
          [-2.5953000, 55.2160800],
          [-2.5953220, 55.2160580],
          [-2.5953490, 55.2160310],
          [-2.5953800, 55.2160080],
          [-2.5954130, 55.2159840],
          [-2.5954490, 55.2159700],
          [-2.5954940, 55.2159540],
          [-2.5955420, 55.2159390],
          [-2.5955970, 55.2159200],
          [-2.5956530, 55.2159050],
          [-2.5957110, 55.2158900],
          [-2.5957710, 55.2158710],
          [-2.5958380, 55.2158550],
          [-2.5959000, 55.2158400],
          [-2.5959650, 55.2158240],
          [-2.5960290, 55.2158090],
          [-2.5960950, 55.2157900],
          [-2.5961610, 55.2157750],
          [-2.5962250, 55.2157560],
          [-2.5962890, 55.2157400],
          [-2.5963510, 55.2157220],
          [-2.5964150, 55.2157020],
          [-2.5964770, 55.2156870],
          [-2.5965360, 55.2156680],
          [-2.5965980, 55.2156490],
          [-2.5966520, 55.2156300],
          [-2.5967020, 55.2156070],
          [-2.5967480, 55.2155850],
          [-2.5967940, 55.2155580],
          [-2.5968290, 55.2155320],
          [-2.5968560, 55.2155010],
          [-2.5968730, 55.2154740],
          [-2.5968800, 55.2154510],
          [-2.5968900, 55.2154280],
          [-2.5968920, 55.2153970],
          [-2.5968880, 55.2153630],
          [-2.5968820, 55.2153260],
          [-2.5968750, 55.2152910],
          [-2.5968700, 55.2152490],
          [-2.5968650, 55.2152110],
          [-2.5968620, 55.2151730],
          [-2.5968550, 55.2151340],
          [-2.5968480, 55.2150960],
          [-2.5968400, 55.2150580],
          [-2.5968330, 55.2150240],
          [-2.5968250, 55.2149900],
          [-2.5968150, 55.2149550],
          [-2.5968100, 55.2149210],
          [-2.5968080, 55.2148900],
          [-2.5968080, 55.2148600],
          [-2.5968070, 55.2148290],
          [-2.5968020, 55.2147990],
          [-2.5967970, 55.2147650],
          [-2.5967900, 55.2147340],
          [-2.5967850, 55.2147000],
          [-2.5967800, 55.2146660],
          [-2.5967820, 55.2146310],
          [-2.5967850, 55.2146040],
          [-2.5967880, 55.2145700],
          [-2.5967880, 55.2145390],
          [-2.5967950, 55.2145090],
          [-2.5968000, 55.2144780],
          [-2.5968050, 55.2144470],
          [-2.5968080, 55.2144170],
          [-2.5968130, 55.2143870],
          [-2.5968200, 55.2143520],
          [-2.5968260, 55.2143180],
          [-2.5968300, 55.2142840],
          [-2.5968350, 55.2142500],
          [-2.5968380, 55.2142180],
          [-2.5968420, 55.2141850],
          [-2.5968480, 55.2141540],
          [-2.5968550, 55.2141160],
          [-2.5968600, 55.2140810],
          [-2.5968650, 55.2140470],
          [-2.5968700, 55.2140130],
          [-2.5968760, 55.2139820],
          [-2.5968850, 55.2139520],
          [-2.5968950, 55.2139210],
          [-2.5969000, 55.2138870],
          [-2.5969050, 55.2138560],
          [-2.5969120, 55.2138260],
          [-2.5969170, 55.2137910],
          [-2.5969200, 55.2137580],
          [-2.5969230, 55.2137270],
          [-2.5969310, 55.2136960],
          [-2.5969380, 55.2136690],
          [-2.5969450, 55.2136430],
          [-2.5969520, 55.2136160],
          [-2.5969570, 55.2135860],
          [-2.5969630, 55.2135590],
          [-2.5969630, 55.2135280],
          [-2.5969670, 55.2134980],
          [-2.5969760, 55.2134680],
          [-2.5969850, 55.2134370],
          [-2.5969930, 55.2134030],
          [-2.5969970, 55.2133760],
          [-2.5970000, 55.2133410],
          [-2.5970070, 55.2133100],
          [-2.5970160, 55.2132690],
          [-2.5970230, 55.2132320],
          [-2.5970330, 55.2131960],
          [-2.5970430, 55.2131580],
          [-2.5970510, 55.2131240],
          [-2.5970600, 55.2130860],
          [-2.5970670, 55.2130510],
          [-2.5970730, 55.2130180],
          [-2.5970810, 55.2129790],
          [-2.5970880, 55.2129410],
          [-2.5970980, 55.2129030],
          [-2.5971120, 55.2128680],
          [-2.5971270, 55.2128340],
          [-2.5971450, 55.2127990],
          [-2.5971700, 55.2127690],
          [-2.5971980, 55.2127380],
          [-2.5972330, 55.2127150],
          [-2.5972690, 55.2126960],
          [-2.5973100, 55.2126810],
          [-2.5973520, 55.2126730],
          [-2.5973980, 55.2126690],
          [-2.5974370, 55.2126730],
          [-2.5974780, 55.2126730],
          [-2.5975160, 55.2126840],
          [-2.5975510, 55.2126920],
          [-2.5975830, 55.2127070],
          [-2.5976130, 55.2127220],
          [-2.5976430, 55.2127420],
          [-2.5976710, 55.2127570],
          [-2.5977010, 55.2127800],
          [-2.5977310, 55.2127950],
          [-2.5977610, 55.2128060],
          [-2.5977900, 55.2128220],
          [-2.5978210, 55.2128330],
          [-2.5978530, 55.2128450],
          [-2.5978840, 55.2128600],
          [-2.5979130, 55.2128710],
          [-2.5979360, 55.2128790],
          [-2.5979600, 55.2128870],
          [-2.5979850, 55.2128940],
          [-2.5980140, 55.2129050],
          [-2.5980440, 55.2129170],
          [-2.5980670, 55.2129250],
          [-2.5980910, 55.2129360],
          [-2.5981130, 55.2129440],
          [-2.5981380, 55.2129510],
          [-2.5981660, 55.2129630],
          [-2.5981910, 55.2129780],
          [-2.5982290, 55.2129860],
          [-2.5982730, 55.2130010],
          [-2.5983080, 55.2130130],
          [-2.5983450, 55.2130200],
          [-2.5983860, 55.2130320],
          [-2.5984240, 55.2130430],
          [-2.5984590, 55.2130510],
          [-2.5984940, 55.2130550],
          [-2.5985270, 55.2130580],
          [-2.5985640, 55.2130580],
          [-2.5986010, 55.2130580],
          [-2.5986360, 55.2130580],
          [-2.5986690, 55.2130550],
          [-2.5987060, 55.2130510],
          [-2.5987460, 55.2130430],
          [-2.5987840, 55.2130390],
          [-2.5988190, 55.2130430],
          [-2.5988550, 55.2130360],
          [-2.5988900, 55.2130320],
          [-2.5989240, 55.2130240],
          [-2.5989590, 55.2130170],
          [-2.5989940, 55.2130090],
          [-2.5990300, 55.2130050],
          [-2.5990640, 55.2129980],
          [-2.5990980, 55.2129940],
          [-2.5991270, 55.2129900],
          [-2.5991560, 55.2129860],
          [-2.5991850, 55.2129820],
          [-2.5992130, 55.2129710],
          [-2.5992440, 55.2129630],
          [-2.5992750, 55.2129630],
          [-2.5993070, 55.2129560],
          [-2.5993410, 55.2129520],
          [-2.5993750, 55.2129400],
          [-2.5994090, 55.2129330],
          [-2.5994470, 55.2129220],
          [-2.5994850, 55.2129140],
          [-2.5995160, 55.2129060],
          [-2.5995510, 55.2128950],
          [-2.5995900, 55.2128870],
          [-2.5996260, 55.2128830],
          [-2.5996630, 55.2128760],
          [-2.5997060, 55.2128680],
          [-2.5997440, 55.2128640],
          [-2.5997810, 55.2128560],
          [-2.5998180, 55.2128490],
          [-2.5998570, 55.2128450],
          [-2.5998910, 55.2128380],
          [-2.5999310, 55.2128300],
          [-2.5999700, 55.2128220],
          [-2.6000060, 55.2128180],
          [-2.6000410, 55.2128110],
          [-2.6000740, 55.2127990],
          [-2.6001140, 55.2127920],
          [-2.6001540, 55.2127800],
          [-2.6001960, 55.2127650],
          [-2.6002410, 55.2127530],
          [-2.6002840, 55.2127420],
          [-2.6003260, 55.2127340],
          [-2.6003690, 55.2127270],
          [-2.6004140, 55.2127190],
          [-2.6004590, 55.2127110],
          [-2.6005000, 55.2127040],
          [-2.6005360, 55.2126960],
          [-2.6005770, 55.2126890],
          [-2.6006230, 55.2126810],
          [-2.6006600, 55.2126730],
          [-2.6006990, 55.2126660],
          [-2.6007360, 55.2126620],
          [-2.6007770, 55.2126540],
          [-2.6008170, 55.2126470],
          [-2.6008610, 55.2126390],
          [-2.6009030, 55.2126310],
          [-2.6009530, 55.2126200],
          [-2.6009970, 55.2126200],
          [-2.6010420, 55.2126120],
          [-2.6010860, 55.2126080],
          [-2.6011270, 55.2126010],
          [-2.6011690, 55.2125970],
          [-2.6012070, 55.2125860],
          [-2.6012490, 55.2125780],
          [-2.6012870, 55.2125700],
          [-2.6013240, 55.2125590],
          [-2.6013620, 55.2125510],
          [-2.6013940, 55.2125440],
          [-2.6014240, 55.2125400],
          [-2.6014570, 55.2125360],
          [-2.6014930, 55.2125320],
          [-2.6015280, 55.2125280],
          [-2.6015630, 55.2125240],
          [-2.6015990, 55.2125210],
          [-2.6016320, 55.2125130],
          [-2.6016650, 55.2125060],
          [-2.6017030, 55.2125020],
          [-2.6017360, 55.2124900],
          [-2.6017660, 55.2124830],
          [-2.6017930, 55.2124750],
          [-2.6018190, 55.2124640],
          [-2.6018530, 55.2124560],
          [-2.6018830, 55.2124480],
          [-2.6019160, 55.2124370],
          [-2.6019540, 55.2124290],
          [-2.6019950, 55.2124210],
          [-2.6020310, 55.2124100],
          [-2.6020680, 55.2123990],
          [-2.6021090, 55.2123910],
          [-2.6021520, 55.2123830],
          [-2.6021910, 55.2123720],
          [-2.6022300, 55.2123610],
          [-2.6022630, 55.2123490],
          [-2.6022940, 55.2123340],
          [-2.6023260, 55.2123220],
          [-2.6023550, 55.2123070],
          [-2.6023850, 55.2122990],
          [-2.6024130, 55.2122880],
          [-2.6024410, 55.2122770],
          [-2.6024650, 55.2122580],
          [-2.6024900, 55.2122390],
          [-2.6025160, 55.2122200],
          [-2.6025370, 55.2122010],
          [-2.6025550, 55.2121850],
          [-2.6025700, 55.2121660],
          [-2.6025910, 55.2121510],
          [-2.6026150, 55.2121320],
          [-2.6026310, 55.2121200],
          [-2.6026530, 55.2121050],
          [-2.6026760, 55.2120900],
          [-2.6026950, 55.2120710],
          [-2.6027110, 55.2120550],
          [-2.6027250, 55.2120400],
          [-2.6027450, 55.2120210],
          [-2.6027710, 55.2120100],
          [-2.6027970, 55.2119910],
          [-2.6028130, 55.2119720],
          [-2.6028260, 55.2119530],
          [-2.6028380, 55.2119330],
          [-2.6028480, 55.2119140],
          [-2.6028580, 55.2118950],
          [-2.6028630, 55.2118730],
          [-2.6028630, 55.2118530],
          [-2.6028700, 55.2118340],
          [-2.6028770, 55.2118150],
          [-2.6028780, 55.2117960],
          [-2.6028780, 55.2117770],
          [-2.6028820, 55.2117580],
          [-2.6028780, 55.2117390],
          [-2.6028750, 55.2117240],
          [-2.6028730, 55.2117080],
          [-2.6028750, 55.2116970],
          [-2.6028770, 55.2116780],
          [-2.6028820, 55.2116630],
          [-2.6028800, 55.2116440],
          [-2.6028730, 55.2116240],
          [-2.6028700, 55.2116050],
          [-2.6028720, 55.2115830],
          [-2.6028750, 55.2115630],
          [-2.6028720, 55.2115440],
          [-2.6028700, 55.2115220],
          [-2.6028720, 55.2114990],
          [-2.6028730, 55.2114760],
          [-2.6028730, 55.2114530],
          [-2.6028730, 55.2114340],
          [-2.6028680, 55.2114140],
          [-2.6028620, 55.2113920],
          [-2.6028500, 55.2113690],
          [-2.6028370, 55.2113430],
          [-2.6028240, 55.2113200],
          [-2.6028120, 55.2112930],
          [-2.6028050, 55.2112700],
          [-2.6027980, 55.2112470],
          [-2.6027920, 55.2112200],
          [-2.6027850, 55.2111970],
          [-2.6027770, 55.2111710],
          [-2.6027720, 55.2111480],
          [-2.6027650, 55.2111250],
          [-2.6027570, 55.2111020],
          [-2.6027520, 55.2110790],
          [-2.6027490, 55.2110570],
          [-2.6027470, 55.2110330],
          [-2.6027430, 55.2110100],
          [-2.6027450, 55.2109880],
          [-2.6027480, 55.2109650],
          [-2.6027520, 55.2109420],
          [-2.6027570, 55.2109190],
          [-2.6027650, 55.2108920],
          [-2.6027780, 55.2108730],
          [-2.6027870, 55.2108500],
          [-2.6027930, 55.2108270],
          [-2.6028000, 55.2108010],
          [-2.6028100, 55.2107780],
          [-2.6028210, 55.2107510],
          [-2.6028310, 55.2107280],
          [-2.6028430, 55.2107050],
          [-2.6028580, 55.2106830],
          [-2.6028780, 55.2106630],
          [-2.6029000, 55.2106440],
          [-2.6029230, 55.2106250],
          [-2.6029460, 55.2106020],
          [-2.6029690, 55.2105830],
          [-2.6029940, 55.2105600],
          [-2.6030200, 55.2105410],
          [-2.6030480, 55.2105180],
          [-2.6030720, 55.2104960],
          [-2.6031000, 55.2104730],
          [-2.6031270, 55.2104530],
          [-2.6031540, 55.2104310],
          [-2.6031870, 55.2104150],
          [-2.6032180, 55.2103920],
          [-2.6032450, 55.2103730],
          [-2.6032710, 55.2103510],
          [-2.6032940, 55.2103270],
          [-2.6033210, 55.2103010],
          [-2.6033480, 55.2102740],
          [-2.6033780, 55.2102510],
          [-2.6034030, 55.2102320],
          [-2.6034390, 55.2102050],
          [-2.6034730, 55.2101790],
          [-2.6035080, 55.2101600],
          [-2.6035390, 55.2101390],
          [-2.6035760, 55.2101140],
          [-2.6036080, 55.2100950],
          [-2.6036420, 55.2100720],
          [-2.6036760, 55.2100520],
          [-2.6037210, 55.2100300],
          [-2.6037590, 55.2100110],
          [-2.6037990, 55.2099920],
          [-2.6038430, 55.2099730],
          [-2.6038930, 55.2099570],
          [-2.6039360, 55.2099440],
          [-2.6039890, 55.2099270],
          [-2.6040380, 55.2099150],
          [-2.6040810, 55.2099020],
          [-2.6041430, 55.2098890],
          [-2.6041990, 55.2098780],
          [-2.6042590, 55.2098660],
          [-2.6043210, 55.2098540],
          [-2.6043850, 55.2098430],
          [-2.6044440, 55.2098330],
          [-2.6045200, 55.2098200],
          [-2.6045880, 55.2098090],
          [-2.6046490, 55.2097990],
          [-2.6047340, 55.2097820],
          [-2.6048080, 55.2097700],
          [-2.6048740, 55.2097570],
          [-2.6049620, 55.2097440],
          [-2.6050330, 55.2097300],
          [-2.6051260, 55.2097130],
          [-2.6052100, 55.2096980],
          [-2.6052990, 55.2096790],
          [-2.6053770, 55.2096660],
          [-2.6054790, 55.2096480],
          [-2.6055590, 55.2096350],
          [-2.6056650, 55.2096150],
          [-2.6057540, 55.2096040],
          [-2.6058700, 55.2095910],
          [-2.6059600, 55.2095780],
          [-2.6060800, 55.2095610],
          [-2.6061720, 55.2095470],
          [-2.6062920, 55.2095300],
          [-2.6063830, 55.2095100],
          [-2.6064960, 55.2094810],
          [-2.6065800, 55.2094550],
          [-2.6066880, 55.2094200],
          [-2.6067670, 55.2093940],
          [-2.6068680, 55.2093560],
          [-2.6069390, 55.2093220],
          [-2.6070220, 55.2092710],
          [-2.6070790, 55.2092260],
          [-2.6071540, 55.2091680],
          [-2.6072170, 55.2091220],
          [-2.6073050, 55.2090620],
          [-2.6073790, 55.2090150],
          [-2.6074850, 55.2089550],
          [-2.6075730, 55.2089160],
          [-2.6077040, 55.2088630],
          [-2.6078080, 55.2088260],
          [-2.6079550, 55.2087830],
          [-2.6080770, 55.2087490],
          [-2.6082350, 55.2087070],
          [-2.6083600, 55.2086710],
          [-2.6085350, 55.2086250],
          [-2.6087120, 55.2085790],
          [-2.6088820, 55.2085400],
          [-2.6090760, 55.2085010],
          [-2.6092210, 55.2084650],
          [-2.6093850, 55.2084200],
          [-2.6095430, 55.2083680],
          [-2.6096830, 55.2083010],
          [-2.6097870, 55.2082250],
          [-2.6098720, 55.2081420],
          [-2.6099230, 55.2080590],
          [-2.6099420, 55.2079740],
          [-2.6099250, 55.2078800],
          [-2.6098700, 55.2077740],
          [-2.6098120, 55.2076900],
          [-2.6097340, 55.2075950],
          [-2.6096200, 55.2074940],
          [-2.6095200, 55.2074110],
          [-2.6094030, 55.2072970],
          [-2.6092880, 55.2071940],
          [-2.6091530, 55.2070970],
          [-2.6090110, 55.2070010],
          [-2.6088710, 55.2068950],
          [-2.6087570, 55.2067790],
          [-2.6086550, 55.2066530],
          [-2.6085530, 55.2065230],
          [-2.6084360, 55.2063930],
          [-2.6083270, 55.2062860],
          [-2.6081870, 55.2061630],
          [-2.6080620, 55.2060490],
          [-2.6079480, 55.2059310],
          [-2.6078740, 55.2058230],
          [-2.6078200, 55.2056930],
          [-2.6077680, 55.2055710],
          [-2.6077160, 55.2054370],
          [-2.6076480, 55.2053170],
          [-2.6075690, 55.2052000],
          [-2.6074920, 55.2050920],
          [-2.6074000, 55.2049720],
          [-2.6073100, 55.2048630],
          [-2.6072060, 55.2047530],
          [-2.6070880, 55.2046570],
          [-2.6069550, 55.2045580],
          [-2.6068310, 55.2044720],
          [-2.6067070, 55.2043850],
          [-2.6065960, 55.2043030],
          [-2.6064920, 55.2042210],
          [-2.6063930, 55.2041490],
          [-2.6062900, 55.2040820],
          [-2.6061730, 55.2040160],
          [-2.6060870, 55.2039690],
          [-2.6059810, 55.2039080],
          [-2.6059080, 55.2038630],
          [-2.6058310, 55.2038120],
          [-2.6057840, 55.2037760],
          [-2.6057400, 55.2037470],
          [-2.6057370, 55.2037390],
          [-2.6057480, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057580, 55.2037430],
          [-2.6057250, 55.2037140],
          [-2.6057110, 55.2036970],
          [-2.6056870, 55.2036710],
          [-2.6056600, 55.2036520],
          [-2.6056290, 55.2036360],
          [-2.6055940, 55.2036170],
          [-2.6055540, 55.2035950],
          [-2.6055080, 55.2035720],
          [-2.6054480, 55.2035460],
          [-2.6053580, 55.2035080],
          [-2.6052920, 55.2034750],
          [-2.6052070, 55.2034350],
          [-2.6051460, 55.2034060],
          [-2.6050600, 55.2033620],
          [-2.6050000, 55.2033360],
          [-2.6049290, 55.2033050],
          [-2.6049050, 55.2032950],
          [-2.6048940, 55.2032850],
          [-2.6048900, 55.2032820],
          [-2.6048720, 55.2032630],
          [-2.6048470, 55.2032510],
          [-2.6048070, 55.2032320],
          [-2.6047780, 55.2032140],
          [-2.6047330, 55.2031900],
          [-2.6046890, 55.2031670],
          [-2.6046510, 55.2031490],
          [-2.6045850, 55.2031170],
          [-2.6045280, 55.2030870],
          [-2.6044790, 55.2030580],
          [-2.6044010, 55.2030090],
          [-2.6043460, 55.2029750],
          [-2.6042600, 55.2029250],
          [-2.6042030, 55.2028870],
          [-2.6041220, 55.2028390],
          [-2.6040730, 55.2028090],
          [-2.6039950, 55.2027630],
          [-2.6039460, 55.2027330],
          [-2.6038780, 55.2026910],
          [-2.6038320, 55.2026640],
          [-2.6037570, 55.2026190],
          [-2.6037060, 55.2025870],
          [-2.6036370, 55.2025420],
          [-2.6035870, 55.2025030],
          [-2.6035040, 55.2024520],
          [-2.6034460, 55.2024170],
          [-2.6033600, 55.2023670],
          [-2.6033020, 55.2023340],
          [-2.6032080, 55.2022820],
          [-2.6031480, 55.2022520],
          [-2.6030590, 55.2022030],
          [-2.6029970, 55.2021720],
          [-2.6029030, 55.2021230],
          [-2.6028350, 55.2020950],
          [-2.6027310, 55.2020540],
          [-2.6026600, 55.2020260],
          [-2.6025560, 55.2019890],
          [-2.6024840, 55.2019610],
          [-2.6023790, 55.2019210],
          [-2.6023050, 55.2018930],
          [-2.6021950, 55.2018510],
          [-2.6021210, 55.2018270],
          [-2.6020160, 55.2017950],
          [-2.6019410, 55.2017700],
          [-2.6018340, 55.2017330],
          [-2.6017620, 55.2017090],
          [-2.6016550, 55.2016730],
          [-2.6015830, 55.2016510],
          [-2.6014740, 55.2016190],
          [-2.6014000, 55.2015970],
          [-2.6012880, 55.2015690],
          [-2.6012150, 55.2015540],
          [-2.6011000, 55.2015310],
          [-2.6010260, 55.2015190],
          [-2.6009050, 55.2015000],
          [-2.6008270, 55.2014880],
          [-2.6007060, 55.2014730],
          [-2.6006280, 55.2014610],
          [-2.6005050, 55.2014470],
          [-2.6004280, 55.2014410],
          [-2.6003030, 55.2014350],
          [-2.6002220, 55.2014290],
          [-2.6000990, 55.2014240],
          [-2.6000200, 55.2014210],
          [-2.5999000, 55.2014160],
          [-2.5998240, 55.2014130],
          [-2.5997120, 55.2014080],
          [-2.5996400, 55.2014020],
          [-2.5995240, 55.2013930],
          [-2.5994230, 55.2013890],
          [-2.5993170, 55.2013780],
          [-2.5992310, 55.2013660],
          [-2.5990990, 55.2013440],
          [-2.5990150, 55.2013290],
          [-2.5988880, 55.2013060],
          [-2.5988020, 55.2012910],
          [-2.5986640, 55.2012600],
          [-2.5985630, 55.2012390],
          [-2.5984090, 55.2011990],
          [-2.5983110, 55.2011730],
          [-2.5981480, 55.2011310],
          [-2.5980450, 55.2011010],
          [-2.5978820, 55.2010580],
          [-2.5977790, 55.2010310],
          [-2.5976130, 55.2009930],
          [-2.5975070, 55.2009660],
          [-2.5973490, 55.2009280],
          [-2.5972490, 55.2009040],
          [-2.5970970, 55.2008710],
          [-2.5970020, 55.2008530],
          [-2.5968560, 55.2008250],
          [-2.5967620, 55.2007980],
          [-2.5966260, 55.2007530],
          [-2.5965440, 55.2007130],
          [-2.5964310, 55.2006470],
          [-2.5963640, 55.2005950],
          [-2.5962690, 55.2005090],
          [-2.5962150, 55.2004490],
          [-2.5961350, 55.2003490],
          [-2.5960920, 55.2002820],
          [-2.5960230, 55.2001780],
          [-2.5959680, 55.2001080],
          [-2.5958930, 55.2000140],
          [-2.5958120, 55.1999210],
          [-2.5957170, 55.1998280],
          [-2.5955940, 55.1997320],
          [-2.5954630, 55.1996350],
          [-2.5953450, 55.1995500],
          [-2.5952290, 55.1994660],
          [-2.5950950, 55.1993880],
          [-2.5949330, 55.1993050],
          [-2.5947720, 55.1992380],
          [-2.5945820, 55.1991760],
          [-2.5943950, 55.1991180],
          [-2.5941920, 55.1990580],
          [-2.5939890, 55.1990000],
          [-2.5937970, 55.1989440],
          [-2.5936090, 55.1988890],
          [-2.5934170, 55.1988340],
          [-2.5932230, 55.1987760],
          [-2.5930470, 55.1987260],
          [-2.5928650, 55.1986750],
          [-2.5926900, 55.1986320],
          [-2.5924970, 55.1985970],
          [-2.5923060, 55.1985840],
          [-2.5921100, 55.1985820],
          [-2.5919060, 55.1985900],
          [-2.5917220, 55.1986100],
          [-2.5915290, 55.1986300],
          [-2.5913530, 55.1986580],
          [-2.5912040, 55.1986790],
          [-2.5909890, 55.1987080],
          [-2.5908620, 55.1987200],
          [-2.5907280, 55.1987340],
          [-2.5905500, 55.1987450],
          [-2.5904240, 55.1987520],
          [-2.5902420, 55.1987640],
          [-2.5901240, 55.1987730],
          [-2.5899500, 55.1987870],
          [-2.5898410, 55.1987960],
          [-2.5896740, 55.1988140],
          [-2.5895770, 55.1988320],
          [-2.5894240, 55.1988600],
          [-2.5893290, 55.1988810],
          [-2.5891850, 55.1989130],
          [-2.5890960, 55.1989340],
          [-2.5889620, 55.1989660],
          [-2.5888800, 55.1989870],
          [-2.5887520, 55.1990190],
          [-2.5886660, 55.1990410],
          [-2.5885490, 55.1990690],
          [-2.5884740, 55.1990850],
          [-2.5883830, 55.1991120],
          [-2.5883480, 55.1991210],
          [-2.5883090, 55.1991250],
          [-2.5883050, 55.1991260],
          [-2.5883010, 55.1991270],
          [-2.5882980, 55.1991280],
          [-2.5882940, 55.1991290],
          [-2.5882910, 55.1991310],
          [-2.5882870, 55.1991320],
          [-2.5882830, 55.1991330],
          [-2.5882800, 55.1991340],
          [-2.5882760, 55.1991350],
          [-2.5882720, 55.1991370],
          [-2.5882690, 55.1991380],
          [-2.5882650, 55.1991390],
          [-2.5882610, 55.1991400],
          [-2.5882580, 55.1991410],
          [-2.5882540, 55.1991430],
          [-2.5882510, 55.1991440],
          [-2.5882470, 55.1991450],
          [-2.5882430, 55.1991460],
          [-2.5882400, 55.1991470],
          [-2.5882360, 55.1991490],
          [-2.5882320, 55.1991500],
          [-2.5882290, 55.1991510],
          [-2.5882250, 55.1991520],
          [-2.5882210, 55.1991530],
          [-2.5882180, 55.1991540],
          [-2.5882140, 55.1991560],
          [-2.5881830, 55.1991630],
          [-2.5881180, 55.1991840],
          [-2.5880440, 55.1992070],
          [-2.5879630, 55.1992410],
          [-2.5878770, 55.1992790],
          [-2.5878060, 55.1993110],
          [-2.5876910, 55.1993630],
          [-2.5876140, 55.1993930],
          [-2.5874930, 55.1994470],
          [-2.5874080, 55.1994800],
          [-2.5872830, 55.1995340],
          [-2.5872020, 55.1995640],
          [-2.5870760, 55.1996180],
          [-2.5869840, 55.1996510],
          [-2.5868410, 55.1997020],
          [-2.5867450, 55.1997320],
          [-2.5866010, 55.1997860],
          [-2.5865170, 55.1998250],
          [-2.5863800, 55.1998850],
          [-2.5862850, 55.1999210],
          [-2.5861500, 55.1999790],
          [-2.5860630, 55.2000230],
          [-2.5859170, 55.2000830],
          [-2.5858190, 55.2001200],
          [-2.5856830, 55.2001760],
          [-2.5856020, 55.2002140],
          [-2.5854790, 55.2002750],
          [-2.5853930, 55.2003230],
          [-2.5852910, 55.2003740],
          [-2.5851690, 55.2004150],
          [-2.5850480, 55.2004580],
          [-2.5849370, 55.2005020],
          [-2.5848200, 55.2005480],
          [-2.5847050, 55.2006050],
          [-2.5845750, 55.2006640],
          [-2.5844620, 55.2007230],
          [-2.5843390, 55.2007900],
          [-2.5842230, 55.2008540],
          [-2.5841070, 55.2009240],
          [-2.5840030, 55.2009790],
          [-2.5839030, 55.2010170],
          [-2.5838060, 55.2010370],
          [-2.5837050, 55.2010380],
          [-2.5836370, 55.2010200],
          [-2.5835960, 55.2009820],
          [-2.5835640, 55.2009260],
          [-2.5835380, 55.2008630],
          [-2.5835130, 55.2007890],
          [-2.5834940, 55.2007220],
          [-2.5834770, 55.2006520],
          [-2.5834570, 55.2005820],
          [-2.5834410, 55.2005040],
          [-2.5834250, 55.2004300],
          [-2.5834120, 55.2003510],
          [-2.5834020, 55.2002760],
          [-2.5833920, 55.2001980],
          [-2.5833820, 55.2001260],
          [-2.5833740, 55.2000570],
          [-2.5833660, 55.1999900],
          [-2.5833550, 55.1999120],
          [-2.5833440, 55.1998390],
          [-2.5833300, 55.1997520],
          [-2.5833170, 55.1996750],
          [-2.5833050, 55.1995890],
          [-2.5832930, 55.1995070],
          [-2.5832790, 55.1994200],
          [-2.5832550, 55.1993340],
          [-2.5832220, 55.1992440],
          [-2.5831930, 55.1991580],
          [-2.5831590, 55.1990800],
          [-2.5831320, 55.1990060],
          [-2.5831030, 55.1989250],
          [-2.5830790, 55.1988500],
          [-2.5830510, 55.1987750],
          [-2.5830260, 55.1987040],
          [-2.5829990, 55.1986230],
          [-2.5829680, 55.1985440],
          [-2.5829340, 55.1984580],
          [-2.5829070, 55.1983770],
          [-2.5828890, 55.1982940],
          [-2.5828760, 55.1982280],
          [-2.5828720, 55.1981540],
          [-2.5828720, 55.1980820],
          [-2.5828700, 55.1980040],
          [-2.5828720, 55.1979390],
          [-2.5828780, 55.1978640],
          [-2.5828830, 55.1977970],
          [-2.5828950, 55.1977250],
          [-2.5829030, 55.1976590],
          [-2.5829080, 55.1975970],
          [-2.5829180, 55.1975330],
          [-2.5829310, 55.1974660],
          [-2.5829430, 55.1974040],
          [-2.5829560, 55.1973420],
          [-2.5829770, 55.1972760],
          [-2.5830050, 55.1972050],
          [-2.5830340, 55.1971410],
          [-2.5830700, 55.1970700],
          [-2.5830990, 55.1970110],
          [-2.5831330, 55.1969370],
          [-2.5831680, 55.1968700],
          [-2.5831980, 55.1968000],
          [-2.5832280, 55.1967290],
          [-2.5832560, 55.1966580],
          [-2.5832850, 55.1965870],
          [-2.5833140, 55.1965090],
          [-2.5833430, 55.1964350],
          [-2.5833720, 55.1963530],
          [-2.5834020, 55.1962710],
          [-2.5834320, 55.1961870],
          [-2.5834630, 55.1961040],
          [-2.5834920, 55.1960220],
          [-2.5835230, 55.1959440],
          [-2.5835650, 55.1958650],
          [-2.5836230, 55.1957920],
          [-2.5837020, 55.1957160],
          [-2.5837830, 55.1956530],
          [-2.5838750, 55.1955910],
          [-2.5839690, 55.1955350],
          [-2.5840720, 55.1954830],
          [-2.5841720, 55.1954390],
          [-2.5842810, 55.1954020],
          [-2.5843860, 55.1953680],
          [-2.5845010, 55.1953400],
          [-2.5846060, 55.1953180],
          [-2.5847170, 55.1953090],
          [-2.5848190, 55.1953050],
          [-2.5849220, 55.1953080],
          [-2.5850200, 55.1953160],
          [-2.5851180, 55.1953200],
          [-2.5852080, 55.1953310],
          [-2.5852990, 55.1953430],
          [-2.5853790, 55.1953540],
          [-2.5854620, 55.1953620],
          [-2.5855410, 55.1953730],
          [-2.5856190, 55.1953840],
          [-2.5856970, 55.1953950],
          [-2.5857800, 55.1954070],
          [-2.5858590, 55.1954180],
          [-2.5859390, 55.1954300],
          [-2.5860150, 55.1954450],
          [-2.5860960, 55.1954610],
          [-2.5861740, 55.1954750],
          [-2.5862570, 55.1954910],
          [-2.5863350, 55.1955100],
          [-2.5864170, 55.1955290],
          [-2.5864970, 55.1955440],
          [-2.5865860, 55.1955630],
          [-2.5866710, 55.1955820],
          [-2.5867650, 55.1956050],
          [-2.5868530, 55.1956310],
          [-2.5869440, 55.1956660],
          [-2.5870270, 55.1957060],
          [-2.5871110, 55.1957560],
          [-2.5871960, 55.1958090],
          [-2.5872980, 55.1958660],
          [-2.5874150, 55.1959220],
          [-2.5875640, 55.1959810],
          [-2.5877280, 55.1960320],
          [-2.5879140, 55.1960650],
          [-2.5880910, 55.1960680],
          [-2.5882980, 55.1960500],
          [-2.5884530, 55.1960300],
          [-2.5885970, 55.1960150],
          [-2.5887170, 55.1959970],
          [-2.5888350, 55.1959810],
          [-2.5889370, 55.1959660],
          [-2.5890340, 55.1959470],
          [-2.5891250, 55.1959310],
          [-2.5892150, 55.1959120],
          [-2.5892910, 55.1958940],
          [-2.5893780, 55.1958780],
          [-2.5894510, 55.1958630],
          [-2.5895320, 55.1958510],
          [-2.5896100, 55.1958390],
          [-2.5896880, 55.1958320],
          [-2.5897670, 55.1958310],
          [-2.5898570, 55.1958390],
          [-2.5899370, 55.1958530],
          [-2.5900170, 55.1958720],
          [-2.5900900, 55.1958910],
          [-2.5901700, 55.1959140],
          [-2.5902460, 55.1959330],
          [-2.5903210, 55.1959520],
          [-2.5903970, 55.1959710],
          [-2.5904800, 55.1959980],
          [-2.5905560, 55.1960200],
          [-2.5906430, 55.1960480],
          [-2.5907260, 55.1960700],
          [-2.5908140, 55.1960960],
          [-2.5909000, 55.1961230],
          [-2.5909950, 55.1961500],
          [-2.5910880, 55.1961760],
          [-2.5911890, 55.1962030],
          [-2.5912910, 55.1962300],
          [-2.5914000, 55.1962600],
          [-2.5915080, 55.1962930],
          [-2.5916250, 55.1963250],
          [-2.5917370, 55.1963580],
          [-2.5918610, 55.1963940],
          [-2.5919810, 55.1964270],
          [-2.5921040, 55.1964620],
          [-2.5922170, 55.1964930],
          [-2.5923270, 55.1965190],
          [-2.5924360, 55.1965460],
          [-2.5925490, 55.1965740],
          [-2.5926470, 55.1966000],
          [-2.5927460, 55.1966270],
          [-2.5928350, 55.1966530],
          [-2.5929260, 55.1966840],
          [-2.5930110, 55.1967070],
          [-2.5930990, 55.1967340],
          [-2.5931820, 55.1967560],
          [-2.5932680, 55.1967840],
          [-2.5933460, 55.1968060],
          [-2.5934270, 55.1968290],
          [-2.5935060, 55.1968480],
          [-2.5935890, 55.1968750],
          [-2.5936660, 55.1969010],
          [-2.5937480, 55.1969250],
          [-2.5938260, 55.1969460],
          [-2.5939110, 55.1969700],
          [-2.5939930, 55.1969960],
          [-2.5940770, 55.1970240],
          [-2.5941580, 55.1970500],
          [-2.5942450, 55.1970770],
          [-2.5943300, 55.1971030],
          [-2.5944220, 55.1971340],
          [-2.5945040, 55.1971620],
          [-2.5946000, 55.1971910],
          [-2.5946870, 55.1972180],
          [-2.5947740, 55.1972460],
          [-2.5948490, 55.1972670],
          [-2.5949290, 55.1972910],
          [-2.5950010, 55.1973120],
          [-2.5950770, 55.1973400],
          [-2.5951490, 55.1973630],
          [-2.5952210, 55.1973820],
          [-2.5952920, 55.1974050],
          [-2.5953620, 55.1974240],
          [-2.5954290, 55.1974440],
          [-2.5954930, 55.1974670],
          [-2.5955540, 55.1974860],
          [-2.5956130, 55.1975010],
          [-2.5956790, 55.1975240],
          [-2.5957410, 55.1975430],
          [-2.5958030, 55.1975610],
          [-2.5958670, 55.1975850],
          [-2.5959300, 55.1976040],
          [-2.5959900, 55.1976260],
          [-2.5960560, 55.1976420],
          [-2.5961150, 55.1976610],
          [-2.5961710, 55.1976760],
          [-2.5962300, 55.1976930],
          [-2.5962830, 55.1977080],
          [-2.5963310, 55.1977230],
          [-2.5963780, 55.1977380],
          [-2.5964260, 55.1977540],
          [-2.5964720, 55.1977690],
          [-2.5965180, 55.1977800],
          [-2.5965610, 55.1977950],
          [-2.5966080, 55.1978030],
          [-2.5966490, 55.1978150],
          [-2.5966920, 55.1978260],
          [-2.5967390, 55.1978410],
          [-2.5967860, 55.1978530],
          [-2.5968310, 55.1978680],
          [-2.5968730, 55.1978830],
          [-2.5969120, 55.1978980],
          [-2.5969540, 55.1979140],
          [-2.5969960, 55.1979290],
          [-2.5970410, 55.1979410],
          [-2.5970880, 55.1979560],
          [-2.5971340, 55.1979710],
          [-2.5971830, 55.1979860],
          [-2.5972300, 55.1980010],
          [-2.5972790, 55.1980170],
          [-2.5973290, 55.1980360],
          [-2.5973790, 55.1980510],
          [-2.5974290, 55.1980660],
          [-2.5974810, 55.1980820],
          [-2.5975340, 55.1981010],
          [-2.5975870, 55.1981160],
          [-2.5976440, 55.1981310],
          [-2.5977010, 55.1981470],
          [-2.5977560, 55.1981660],
          [-2.5978130, 55.1981810],
          [-2.5978690, 55.1982000],
          [-2.5979230, 55.1982150],
          [-2.5979810, 55.1982340],
          [-2.5980390, 55.1982530],
          [-2.5980990, 55.1982760],
          [-2.5981580, 55.1982990],
          [-2.5982190, 55.1983180],
          [-2.5982790, 55.1983340],
          [-2.5983390, 55.1983560],
          [-2.5984040, 55.1983720],
          [-2.5984680, 55.1983940],
          [-2.5985310, 55.1984130],
          [-2.5985960, 55.1984330],
          [-2.5986590, 55.1984550],
          [-2.5987260, 55.1984750],
          [-2.5987900, 55.1984900],
          [-2.5988570, 55.1985130],
          [-2.5989210, 55.1985320],
          [-2.5989850, 55.1985510],
          [-2.5990480, 55.1985700],
          [-2.5991110, 55.1985930],
          [-2.5991720, 55.1986120],
          [-2.5992360, 55.1986350],
          [-2.5993010, 55.1986540],
          [-2.5993650, 55.1986730],
          [-2.5994310, 55.1986960],
          [-2.5994970, 55.1987150],
          [-2.5995660, 55.1987380],
          [-2.5996370, 55.1987610],
          [-2.5997070, 55.1987840],
          [-2.5997750, 55.1988060],
          [-2.5998440, 55.1988290],
          [-2.5999110, 55.1988480],
          [-2.5999780, 55.1988710],
          [-2.6000470, 55.1988900],
          [-2.6001170, 55.1989130],
          [-2.6001900, 55.1989360],
          [-2.6002630, 55.1989590],
          [-2.6003370, 55.1989820],
          [-2.6004140, 55.1990050],
          [-2.6004910, 55.1990310],
          [-2.6005720, 55.1990540],
          [-2.6006540, 55.1990770],
          [-2.6007380, 55.1991040],
          [-2.6008260, 55.1991310],
          [-2.6009140, 55.1991570],
          [-2.6009970, 55.1991830],
          [-2.6010850, 55.1992110],
          [-2.6011740, 55.1992370],
          [-2.6012640, 55.1992640],
          [-2.6013560, 55.1992910],
          [-2.6014490, 55.1993210],
          [-2.6015440, 55.1993480],
          [-2.6016400, 55.1993780],
          [-2.6017380, 55.1994090],
          [-2.6018380, 55.1994390],
          [-2.6019400, 55.1994740],
          [-2.6020400, 55.1995080],
          [-2.6021420, 55.1995460],
          [-2.6022420, 55.1995840],
          [-2.6023400, 55.1996230],
          [-2.6024320, 55.1996600],
          [-2.6025280, 55.1997020],
          [-2.6026190, 55.1997410],
          [-2.6027050, 55.1997830],
          [-2.6027910, 55.1998250],
          [-2.6028740, 55.1998630],
          [-2.6029530, 55.1999050],
          [-2.6030320, 55.1999470],
          [-2.6031090, 55.1999850],
          [-2.6031860, 55.2000270],
          [-2.6032620, 55.2000650],
          [-2.6033420, 55.2001070],
          [-2.6034200, 55.2001490],
          [-2.6034990, 55.2001870],
          [-2.6035800, 55.2002290],
          [-2.6036620, 55.2002710],
          [-2.6037460, 55.2003170],
          [-2.6038250, 55.2003620],
          [-2.6039100, 55.2004080],
          [-2.6039960, 55.2004540],
          [-2.6040820, 55.2005040],
          [-2.6041660, 55.2005530],
          [-2.6042500, 55.2006020],
          [-2.6043320, 55.2006560],
          [-2.6044100, 55.2007060],
          [-2.6044870, 55.2007620],
          [-2.6045650, 55.2008200],
          [-2.6046420, 55.2008770],
          [-2.6047170, 55.2009310],
          [-2.6047920, 55.2009880],
          [-2.6048750, 55.2010410],
          [-2.6049640, 55.2011030],
          [-2.6050550, 55.2011560],
          [-2.6051520, 55.2012050],
          [-2.6052500, 55.2012480],
          [-2.6053530, 55.2012820],
          [-2.6054600, 55.2013090],
          [-2.6055670, 55.2013240],
          [-2.6056700, 55.2013360],
          [-2.6057740, 55.2013430],
          [-2.6058740, 55.2013470],
          [-2.6059720, 55.2013550],
          [-2.6060660, 55.2013620],
          [-2.6061620, 55.2013740],
          [-2.6062630, 55.2013890],
          [-2.6063640, 55.2014040],
          [-2.6064690, 55.2014200],
          [-2.6065750, 55.2014390],
          [-2.6066810, 55.2014580],
          [-2.6067880, 55.2014770],
          [-2.6068960, 55.2014960],
          [-2.6070000, 55.2015180],
          [-2.6071090, 55.2015420],
          [-2.6072100, 55.2015640],
          [-2.6073170, 55.2015910],
          [-2.6074170, 55.2016170],
          [-2.6075190, 55.2016480],
          [-2.6076120, 55.2016740],
          [-2.6077090, 55.2017050],
          [-2.6077960, 55.2017360],
          [-2.6078810, 55.2017630],
          [-2.6079640, 55.2017970],
          [-2.6080490, 55.2018350],
          [-2.6081320, 55.2018840],
          [-2.6082120, 55.2019340],
          [-2.6082600, 55.2019700],
          [-2.6083560, 55.2020470],
          [-2.6084370, 55.2021170],
          [-2.6085130, 55.2021830],
          [-2.6085840, 55.2022490],
          [-2.6086600, 55.2023230],
          [-2.6087340, 55.2023960],
          [-2.6088150, 55.2024600],
          [-2.6088980, 55.2025250],
          [-2.6089830, 55.2025820],
          [-2.6090690, 55.2026430],
          [-2.6091620, 55.2026970],
          [-2.6092700, 55.2027390],
          [-2.6093930, 55.2027700],
          [-2.6095240, 55.2027850],
          [-2.6096620, 55.2027820],
          [-2.6097880, 55.2027440],
          [-2.6099090, 55.2026960],
          [-2.6100330, 55.2026370],
          [-2.6101530, 55.2025690],
          [-2.6102790, 55.2024930],
          [-2.6104120, 55.2024120],
          [-2.6105450, 55.2023330],
          [-2.6106690, 55.2022570],
          [-2.6108050, 55.2021760],
          [-2.6109130, 55.2020970],
          [-2.6109960, 55.2020100],
          [-2.6110440, 55.2018980],
          [-2.6110450, 55.2017870],
          [-2.6110340, 55.2016730],
          [-2.6110270, 55.2015700],
          [-2.6110480, 55.2014740],
          [-2.6111110, 55.2013790],
          [-2.6112080, 55.2012890],
          [-2.6113020, 55.2011980],
          [-2.6114010, 55.2011050],
          [-2.6114820, 55.2010130],
          [-2.6115160, 55.2009180],
          [-2.6115510, 55.2008200],
          [-2.6115780, 55.2007220],
          [-2.6116080, 55.2006470],
          [-2.6116280, 55.2005850],
          [-2.6116180, 55.2005320],
          [-2.6115870, 55.2004820],
          [-2.6115180, 55.2004360],
          [-2.6114190, 55.2004100],
          [-2.6112980, 55.2003940],
          [-2.6111640, 55.2003860],
          [-2.6110200, 55.2003750],
          [-2.6108560, 55.2003600],
          [-2.6106980, 55.2003410],
          [-2.6105320, 55.2003100],
          [-2.6103860, 55.2002740],
          [-2.6102290, 55.2002320],
          [-2.6100620, 55.2001810],
          [-2.6099110, 55.2001320],
          [-2.6097600, 55.2000740],
          [-2.6096340, 55.2000200],
          [-2.6094990, 55.1999600],
          [-2.6093650, 55.1998990],
          [-2.6092430, 55.1998340],
          [-2.6091170, 55.1997690],
          [-2.6090010, 55.1997010],
          [-2.6088830, 55.1996320],
          [-2.6087700, 55.1995690],
          [-2.6086460, 55.1995060],
          [-2.6085200, 55.1994490],
          [-2.6083890, 55.1993950],
          [-2.6082520, 55.1993490],
          [-2.6081160, 55.1993110],
          [-2.6079800, 55.1992730],
          [-2.6078360, 55.1992380],
          [-2.6076940, 55.1992040],
          [-2.6075570, 55.1991700],
          [-2.6074140, 55.1991390],
          [-2.6072700, 55.1991080],
          [-2.6071380, 55.1990780],
          [-2.6069940, 55.1990470],
          [-2.6068420, 55.1990250],
          [-2.6066850, 55.1990050],
          [-2.6065280, 55.1989910],
          [-2.6063570, 55.1989780],
          [-2.6062000, 55.1989750],
          [-2.6060420, 55.1989710],
          [-2.6058840, 55.1989710],
          [-2.6057270, 55.1989710],
          [-2.6055720, 55.1989780],
          [-2.6054210, 55.1989860],
          [-2.6052720, 55.1990010],
          [-2.6051270, 55.1990200],
          [-2.6049830, 55.1990390],
          [-2.6048410, 55.1990580],
          [-2.6046970, 55.1990770],
          [-2.6045500, 55.1990930],
          [-2.6044030, 55.1991040],
          [-2.6042650, 55.1991080],
          [-2.6041230, 55.1991080],
          [-2.6039790, 55.1990970],
          [-2.6038410, 55.1990740],
          [-2.6037040, 55.1990440],
          [-2.6035770, 55.1990070],
          [-2.6034600, 55.1989580],
          [-2.6033520, 55.1988990],
          [-2.6032660, 55.1988420],
          [-2.6031910, 55.1987730],
          [-2.6031190, 55.1986890],
          [-2.6030580, 55.1986100],
          [-2.6029990, 55.1985300],
          [-2.6029460, 55.1984420],
          [-2.6028900, 55.1983600],
          [-2.6028340, 55.1982780],
          [-2.6027720, 55.1982050],
          [-2.6027030, 55.1981370],
          [-2.6026310, 55.1980710],
          [-2.6025440, 55.1980140],
          [-2.6024460, 55.1979610],
          [-2.6023400, 55.1979110],
          [-2.6022250, 55.1978690],
          [-2.6021000, 55.1978350],
          [-2.6019760, 55.1978050],
          [-2.6018590, 55.1977780],
          [-2.6017360, 55.1977510],
          [-2.6016180, 55.1977240],
          [-2.6015050, 55.1976930],
          [-2.6013890, 55.1976700],
          [-2.6012740, 55.1976400],
          [-2.6011520, 55.1976130],
          [-2.6010360, 55.1975830],
          [-2.6009210, 55.1975520],
          [-2.6008100, 55.1975290],
          [-2.6007110, 55.1975040],
          [-2.6006100, 55.1974800],
          [-2.6005180, 55.1974570],
          [-2.6004300, 55.1974340],
          [-2.6003400, 55.1974110],
          [-2.6002450, 55.1973810],
          [-2.6001450, 55.1973500],
          [-2.6000440, 55.1973200],
          [-2.5999450, 55.1972890],
          [-2.5998390, 55.1972510],
          [-2.5997270, 55.1972100],
          [-2.5996010, 55.1971590],
          [-2.5994810, 55.1971110],
          [-2.5993640, 55.1970640],
          [-2.5992530, 55.1970150],
          [-2.5991430, 55.1969690],
          [-2.5990300, 55.1969230],
          [-2.5989160, 55.1968700],
          [-2.5988020, 55.1968200],
          [-2.5986890, 55.1967670],
          [-2.5985770, 55.1967180],
          [-2.5984650, 55.1966670],
          [-2.5983520, 55.1966220],
          [-2.5982370, 55.1965760],
          [-2.5981230, 55.1965260],
          [-2.5980070, 55.1964800],
          [-2.5978880, 55.1964350],
          [-2.5977630, 55.1963850],
          [-2.5976500, 55.1963380],
          [-2.5975280, 55.1962900],
          [-2.5974140, 55.1962450],
          [-2.5972910, 55.1961940],
          [-2.5971750, 55.1961490],
          [-2.5970550, 55.1961030],
          [-2.5969340, 55.1960610],
          [-2.5968080, 55.1960150],
          [-2.5966850, 55.1959770],
          [-2.5965910, 55.1959470],
          [-2.5964430, 55.1959120],
          [-2.5963170, 55.1958810],
          [-2.5961880, 55.1958540],
          [-2.5960560, 55.1958320],
          [-2.5959190, 55.1958120],
          [-2.5957820, 55.1957930],
          [-2.5956440, 55.1957740],
          [-2.5955030, 55.1957560],
          [-2.5953570, 55.1957400],
          [-2.5952120, 55.1957210],
          [-2.5950690, 55.1957060],
          [-2.5949190, 55.1956940],
          [-2.5947770, 55.1956790],
          [-2.5946280, 55.1956680],
          [-2.5944700, 55.1956600],
          [-2.5943150, 55.1956520],
          [-2.5941590, 55.1956450],
          [-2.5940050, 55.1956370],
          [-2.5938410, 55.1956290],
          [-2.5936880, 55.1956180],
          [-2.5935290, 55.1955990],
          [-2.5933840, 55.1955760],
          [-2.5932550, 55.1955460],
          [-2.5931330, 55.1955040],
          [-2.5930280, 55.1954620],
          [-2.5929260, 55.1954170],
          [-2.5928150, 55.1953700],
          [-2.5927030, 55.1953250],
          [-2.5926010, 55.1952870],
          [-2.5924840, 55.1952450],
          [-2.5923690, 55.1952060],
          [-2.5922590, 55.1951690],
          [-2.5921420, 55.1951270],
          [-2.5920230, 55.1950850],
          [-2.5919040, 55.1950500],
          [-2.5917810, 55.1950190],
          [-2.5916550, 55.1949960],
          [-2.5915250, 55.1949770],
          [-2.5913940, 55.1949540],
          [-2.5912620, 55.1949390],
          [-2.5911300, 55.1949240],
          [-2.5909980, 55.1949120],
          [-2.5908670, 55.1949010],
          [-2.5907370, 55.1948930],
          [-2.5906050, 55.1948850],
          [-2.5904710, 55.1948740],
          [-2.5903440, 55.1948590],
          [-2.5902250, 55.1948320],
          [-2.5901140, 55.1947910],
          [-2.5900180, 55.1947530],
          [-2.5899510, 55.1946930],
          [-2.5899060, 55.1946240],
          [-2.5898890, 55.1945510],
          [-2.5898870, 55.1944820],
          [-2.5898930, 55.1944090],
          [-2.5898880, 55.1943340],
          [-2.5898540, 55.1942650],
          [-2.5897870, 55.1941920],
          [-2.5896960, 55.1941270],
          [-2.5895850, 55.1940740],
          [-2.5894600, 55.1940280],
          [-2.5893340, 55.1939900],
          [-2.5892070, 55.1939560],
          [-2.5890660, 55.1939210],
          [-2.5889340, 55.1938910],
          [-2.5887930, 55.1938590],
          [-2.5886600, 55.1938290],
          [-2.5885280, 55.1937990],
          [-2.5883950, 55.1937640],
          [-2.5882630, 55.1937340],
          [-2.5881360, 55.1937070],
          [-2.5880050, 55.1936800],
          [-2.5878730, 55.1936570],
          [-2.5877360, 55.1936380],
          [-2.5875990, 55.1936270],
          [-2.5874580, 55.1936150],
          [-2.5873190, 55.1936070],
          [-2.5871780, 55.1936040],
          [-2.5870410, 55.1936040],
          [-2.5868970, 55.1936000],
          [-2.5867640, 55.1935960],
          [-2.5866200, 55.1935960],
          [-2.5864840, 55.1935960],
          [-2.5863490, 55.1935920],
          [-2.5862110, 55.1935920],
          [-2.5860740, 55.1935920],
          [-2.5859350, 55.1935920],
          [-2.5857960, 55.1935920],
          [-2.5856640, 55.1935920],
          [-2.5855300, 55.1935880],
          [-2.5854010, 55.1935880],
          [-2.5852780, 55.1935810],
          [-2.5851580, 55.1935770],
          [-2.5850300, 55.1935800],
          [-2.5849100, 55.1935810],
          [-2.5847830, 55.1935810],
          [-2.5846550, 55.1935810],
          [-2.5845310, 55.1935770],
          [-2.5844000, 55.1935730],
          [-2.5842680, 55.1935660],
          [-2.5841290, 55.1935510],
          [-2.5839930, 55.1935360],
          [-2.5838550, 55.1935100],
          [-2.5837360, 55.1934690],
          [-2.5836170, 55.1934270],
          [-2.5835220, 55.1933770],
          [-2.5834520, 55.1933170],
          [-2.5834060, 55.1932460],
          [-2.5833810, 55.1931690],
          [-2.5833660, 55.1930940],
          [-2.5833470, 55.1930140],
          [-2.5833250, 55.1929490],
          [-2.5833030, 55.1928740],
          [-2.5832800, 55.1928010],
          [-2.5832560, 55.1927280],
          [-2.5832290, 55.1926660],
          [-2.5831980, 55.1925990],
          [-2.5831640, 55.1925320],
          [-2.5831210, 55.1924700],
          [-2.5830780, 55.1924130],
          [-2.5830290, 55.1923510],
          [-2.5829830, 55.1922950],
          [-2.5829330, 55.1922340],
          [-2.5828800, 55.1921770],
          [-2.5828250, 55.1921170],
          [-2.5827760, 55.1920660],
          [-2.5827210, 55.1920080],
          [-2.5826680, 55.1919600],
          [-2.5826040, 55.1919130],
          [-2.5825360, 55.1918660],
          [-2.5824680, 55.1918280],
          [-2.5823970, 55.1917940],
          [-2.5823190, 55.1917620],
          [-2.5822390, 55.1917430],
          [-2.5821510, 55.1917270],
          [-2.5820670, 55.1917270],
          [-2.5819850, 55.1917340],
          [-2.5819130, 55.1917450],
          [-2.5818450, 55.1917570],
          [-2.5817820, 55.1917720],
          [-2.5817280, 55.1917900],
          [-2.5816720, 55.1918060],
          [-2.5816170, 55.1918290],
          [-2.5815560, 55.1918550],
          [-2.5814870, 55.1918860],
          [-2.5814210, 55.1919220],
          [-2.5813520, 55.1919660],
          [-2.5812940, 55.1920130],
          [-2.5812360, 55.1920670],
          [-2.5811850, 55.1921230],
          [-2.5811300, 55.1921860],
          [-2.5810790, 55.1922450],
          [-2.5810250, 55.1923040],
          [-2.5809670, 55.1923640],
          [-2.5809080, 55.1924220],
          [-2.5808500, 55.1924800],
          [-2.5807790, 55.1925410],
          [-2.5807070, 55.1925960],
          [-2.5806290, 55.1926550],
          [-2.5805550, 55.1927070],
          [-2.5804800, 55.1927650],
          [-2.5804060, 55.1928210],
          [-2.5803210, 55.1928730],
          [-2.5802440, 55.1929230],
          [-2.5801670, 55.1929790],
          [-2.5801020, 55.1930380],
          [-2.5800340, 55.1930980],
          [-2.5799780, 55.1931600],
          [-2.5799230, 55.1932300],
          [-2.5798750, 55.1932930],
          [-2.5798320, 55.1933600],
          [-2.5797980, 55.1934300],
          [-2.5797610, 55.1935060],
          [-2.5797240, 55.1935760],
          [-2.5796890, 55.1936510],
          [-2.5796580, 55.1937250],
          [-2.5796260, 55.1938060],
          [-2.5795960, 55.1938750],
          [-2.5795630, 55.1939560],
          [-2.5795330, 55.1940320],
          [-2.5794970, 55.1941130],
          [-2.5794660, 55.1941820],
          [-2.5794340, 55.1942590],
          [-2.5794040, 55.1943270],
          [-2.5793770, 55.1944060],
          [-2.5793580, 55.1944690],
          [-2.5793400, 55.1945400],
          [-2.5793230, 55.1946090],
          [-2.5793100, 55.1946850],
          [-2.5792990, 55.1947470],
          [-2.5792900, 55.1948130],
          [-2.5792880, 55.1948770],
          [-2.5792900, 55.1949480],
          [-2.5792930, 55.1950100],
          [-2.5793000, 55.1950770],
          [-2.5793060, 55.1951390],
          [-2.5793180, 55.1951990],
          [-2.5793270, 55.1952610],
          [-2.5793380, 55.1953210],
          [-2.5793510, 55.1953810],
          [-2.5793660, 55.1954360],
          [-2.5793790, 55.1954950],
          [-2.5793880, 55.1955550],
          [-2.5793950, 55.1956130],
          [-2.5793980, 55.1956790],
          [-2.5794000, 55.1957420],
          [-2.5794000, 55.1958050],
          [-2.5793910, 55.1958660],
          [-2.5793720, 55.1959360],
          [-2.5793500, 55.1960020],
          [-2.5793200, 55.1960680],
          [-2.5792820, 55.1961310],
          [-2.5792390, 55.1961980],
          [-2.5791880, 55.1962610],
          [-2.5791340, 55.1963260],
          [-2.5790730, 55.1963860],
          [-2.5790080, 55.1964570],
          [-2.5789460, 55.1965160],
          [-2.5788720, 55.1965830],
          [-2.5787950, 55.1966500],
          [-2.5787120, 55.1967240],
          [-2.5786300, 55.1967910],
          [-2.5785430, 55.1968580],
          [-2.5784630, 55.1969180],
          [-2.5783770, 55.1969800],
          [-2.5782950, 55.1970380],
          [-2.5782040, 55.1970950],
          [-2.5781150, 55.1971470],
          [-2.5780230, 55.1972050],
          [-2.5779370, 55.1972540],
          [-2.5778470, 55.1973080],
          [-2.5777620, 55.1973560],
          [-2.5776700, 55.1974150],
          [-2.5775820, 55.1974630],
          [-2.5774820, 55.1975150],
          [-2.5773930, 55.1975670],
          [-2.5773010, 55.1976170],
          [-2.5772080, 55.1976620],
          [-2.5771080, 55.1977100],
          [-2.5770160, 55.1977550],
          [-2.5769200, 55.1977900],
          [-2.5768310, 55.1978300],
          [-2.5767360, 55.1978710],
          [-2.5766500, 55.1979070],
          [-2.5765550, 55.1979470],
          [-2.5764660, 55.1979800],
          [-2.5763660, 55.1980150],
          [-2.5762690, 55.1980520],
          [-2.5761660, 55.1980910],
          [-2.5760620, 55.1981260],
          [-2.5759600, 55.1981570],
          [-2.5758690, 55.1981970],
          [-2.5757650, 55.1982330],
          [-2.5756760, 55.1982620],
          [-2.5755730, 55.1982900],
          [-2.5754630, 55.1983170],
          [-2.5753450, 55.1983440],
          [-2.5752360, 55.1983660],
          [-2.5751260, 55.1983900],
          [-2.5750180, 55.1984160],
          [-2.5749000, 55.1984400],
          [-2.5747920, 55.1984580],
          [-2.5746810, 55.1984810],
          [-2.5745750, 55.1985040],
          [-2.5744640, 55.1985270],
          [-2.5743620, 55.1985500],
          [-2.5742610, 55.1985730],
          [-2.5741640, 55.1985950],
          [-2.5740570, 55.1986150],
          [-2.5739520, 55.1986300],
          [-2.5738430, 55.1986460],
          [-2.5737380, 55.1986600],
          [-2.5736230, 55.1986760],
          [-2.5735170, 55.1986950],
          [-2.5734070, 55.1987100],
          [-2.5733050, 55.1987290],
          [-2.5732030, 55.1987520],
          [-2.5731140, 55.1987820],
          [-2.5730180, 55.1988130],
          [-2.5729360, 55.1988490],
          [-2.5728510, 55.1988920],
          [-2.5727780, 55.1989360],
          [-2.5727060, 55.1989890],
          [-2.5726360, 55.1990390],
          [-2.5725700, 55.1990810],
          [-2.5724980, 55.1991270],
          [-2.5724260, 55.1991800],
          [-2.5723510, 55.1992260],
          [-2.5722710, 55.1992710],
          [-2.5721890, 55.1993210],
          [-2.5721040, 55.1993710],
          [-2.5720180, 55.1994160],
          [-2.5719330, 55.1994660],
          [-2.5718480, 55.1995120],
          [-2.5717600, 55.1995580],
          [-2.5716720, 55.1995990],
          [-2.5715880, 55.1996490],
          [-2.5715030, 55.1997030],
          [-2.5714150, 55.1997430],
          [-2.5713180, 55.1997900],
          [-2.5712230, 55.1998400],
          [-2.5711280, 55.1998860],
          [-2.5710390, 55.1999310],
          [-2.5709480, 55.1999810],
          [-2.5708550, 55.2000230],
          [-2.5707620, 55.2000650],
          [-2.5706660, 55.2001150],
          [-2.5705700, 55.2001560],
          [-2.5704770, 55.2001960],
          [-2.5703680, 55.2002410],
          [-2.5702640, 55.2002820],
          [-2.5701550, 55.2003240],
          [-2.5700450, 55.2003630],
          [-2.5699370, 55.2004040],
          [-2.5698270, 55.2004460],
          [-2.5697130, 55.2004880],
          [-2.5696000, 55.2005300],
          [-2.5694900, 55.2005680],
          [-2.5693800, 55.2006070],
          [-2.5692750, 55.2006450],
          [-2.5691690, 55.2006870],
          [-2.5690530, 55.2007250],
          [-2.5689320, 55.2007630],
          [-2.5688120, 55.2008090],
          [-2.5687010, 55.2008570],
          [-2.5685880, 55.2009120],
          [-2.5684800, 55.2009610],
          [-2.5683700, 55.2010190],
          [-2.5682670, 55.2010720],
          [-2.5681580, 55.2011290],
          [-2.5680520, 55.2011860],
          [-2.5679430, 55.2012360],
          [-2.5678330, 55.2012860],
          [-2.5677280, 55.2013350],
          [-2.5676320, 55.2013840],
          [-2.5675430, 55.2014340],
          [-2.5674480, 55.2014910],
          [-2.5673530, 55.2015490],
          [-2.5672560, 55.2016020],
          [-2.5671600, 55.2016670],
          [-2.5670650, 55.2017240],
          [-2.5669790, 55.2018000],
          [-2.5669060, 55.2018770],
          [-2.5668360, 55.2019560],
          [-2.5667690, 55.2020350],
          [-2.5667090, 55.2021130],
          [-2.5666510, 55.2021930],
          [-2.5665890, 55.2022770],
          [-2.5665250, 55.2023630],
          [-2.5664450, 55.2024440],
          [-2.5663600, 55.2025250],
          [-2.5662670, 55.2026010],
          [-2.5661600, 55.2026650],
          [-2.5660410, 55.2027190],
          [-2.5659070, 55.2027700],
          [-2.5657700, 55.2028080],
          [-2.5656270, 55.2028460],
          [-2.5654860, 55.2028730],
          [-2.5653440, 55.2029070],
          [-2.5652070, 55.2029300],
          [-2.5650690, 55.2029600],
          [-2.5649260, 55.2029870],
          [-2.5647880, 55.2030170],
          [-2.5646430, 55.2030410],
          [-2.5645020, 55.2030670],
          [-2.5643660, 55.2030900],
          [-2.5642340, 55.2031170],
          [-2.5641050, 55.2031400],
          [-2.5639830, 55.2031660],
          [-2.5638700, 55.2032010],
          [-2.5637450, 55.2032310],
          [-2.5636140, 55.2032620],
          [-2.5634820, 55.2032890],
          [-2.5633590, 55.2033190],
          [-2.5632290, 55.2033490],
          [-2.5631000, 55.2033800],
          [-2.5629770, 55.2034140],
          [-2.5628600, 55.2034490],
          [-2.5627420, 55.2034790],
          [-2.5626330, 55.2035050],
          [-2.5625300, 55.2035430],
          [-2.5624370, 55.2035890],
          [-2.5623580, 55.2036390],
          [-2.5622890, 55.2036960],
          [-2.5622270, 55.2037600],
          [-2.5621730, 55.2038220],
          [-2.5621350, 55.2038800],
          [-2.5621070, 55.2039440],
          [-2.5620940, 55.2040040],
          [-2.5620850, 55.2040700],
          [-2.5620720, 55.2041350],
          [-2.5620630, 55.2041920],
          [-2.5620600, 55.2042460],
          [-2.5620620, 55.2042990],
          [-2.5620550, 55.2043490],
          [-2.5620460, 55.2043930],
          [-2.5620340, 55.2044440],
          [-2.5620270, 55.2044860],
          [-2.5620140, 55.2045240],
          [-2.5619940, 55.2045700],
          [-2.5619710, 55.2046110],
          [-2.5619390, 55.2046580],
          [-2.5619060, 55.2046950],
          [-2.5618760, 55.2047300],
          [-2.5618540, 55.2047720],
          [-2.5618270, 55.2048100],
          [-2.5618010, 55.2048480],
          [-2.5617670, 55.2048830],
          [-2.5617340, 55.2049130],
          [-2.5617010, 55.2049470],
          [-2.5616670, 55.2049780],
          [-2.5616310, 55.2050050],
          [-2.5615960, 55.2050320],
          [-2.5615660, 55.2050580],
          [-2.5615470, 55.2050810],
          [-2.5615530, 55.2051190],
          [-2.5615580, 55.2051570],
          [-2.5615550, 55.2051920],
          [-2.5615260, 55.2052220],
          [-2.5614740, 55.2052490],
          [-2.5614150, 55.2052720],
          [-2.5613430, 55.2052950],
          [-2.5612670, 55.2053100],
          [-2.5611930, 55.2053260],
          [-2.5611220, 55.2053370],
          [-2.5610530, 55.2053490],
          [-2.5609800, 55.2053640],
          [-2.5609020, 55.2053750],
          [-2.5608090, 55.2053910],
          [-2.5607110, 55.2053980],
          [-2.5606070, 55.2054020],
          [-2.5604950, 55.2054020],
          [-2.5603780, 55.2054060],
          [-2.5602560, 55.2054100],
          [-2.5601400, 55.2054170],
          [-2.5600380, 55.2054210],
          [-2.5599480, 55.2054290],
          [-2.5598720, 55.2054360],
          [-2.5598080, 55.2054440],
          [-2.5597590, 55.2054590],
          [-2.5597540, 55.2054860],
          [-2.5597980, 55.2055090],
          [-2.5598750, 55.2055200],
          [-2.5599590, 55.2055280],
          [-2.5600210, 55.2055430],
          [-2.5600590, 55.2055620],
          [-2.5600700, 55.2055850],
          [-2.5600390, 55.2056010],
          [-2.5599760, 55.2056150],
          [-2.5598830, 55.2056350],
          [-2.5597800, 55.2056610],
          [-2.5596750, 55.2056990],
          [-2.5595800, 55.2057410],
          [-2.5594880, 55.2057860],
          [-2.5593830, 55.2058250],
          [-2.5592700, 55.2058520],
          [-2.5591460, 55.2058640],
          [-2.5590220, 55.2058530],
          [-2.5589020, 55.2058300],
          [-2.5587820, 55.2058000],
          [-2.5586730, 55.2057650],
          [-2.5585820, 55.2057350],
          [-2.5585070, 55.2057020],
          [-2.5584310, 55.2056700],
          [-2.5583580, 55.2056430],
          [-2.5582910, 55.2056200],
          [-2.5582280, 55.2056010],
          [-2.5581640, 55.2055820],
          [-2.5581060, 55.2055670],
          [-2.5580460, 55.2055550],
          [-2.5579930, 55.2055360],
          [-2.5579470, 55.2055180],
          [-2.5579060, 55.2055020],
          [-2.5578480, 55.2054870],
          [-2.5577820, 55.2054640],
          [-2.5576980, 55.2054410],
          [-2.5576030, 55.2054180],
          [-2.5575030, 55.2053870],
          [-2.5573980, 55.2053570],
          [-2.5572900, 55.2053230],
          [-2.5571820, 55.2052850],
          [-2.5570770, 55.2052540],
          [-2.5569730, 55.2052240],
          [-2.5568720, 55.2051930],
          [-2.5567650, 55.2051590],
          [-2.5566580, 55.2051240],
          [-2.5565530, 55.2050940],
          [-2.5564490, 55.2050600],
          [-2.5563470, 55.2050290],
          [-2.5562420, 55.2049950],
          [-2.5561330, 55.2049600],
          [-2.5560280, 55.2049220],
          [-2.5559250, 55.2048880],
          [-2.5558300, 55.2048460],
          [-2.5557410, 55.2048120],
          [-2.5556650, 55.2047780],
          [-2.5555850, 55.2047510],
          [-2.5555000, 55.2047280],
          [-2.5554120, 55.2047010],
          [-2.5553170, 55.2046630],
          [-2.5552220, 55.2046170],
          [-2.5551290, 55.2045750],
          [-2.5550380, 55.2045260],
          [-2.5549440, 55.2044760],
          [-2.5548470, 55.2044340],
          [-2.5547490, 55.2043920],
          [-2.5546380, 55.2043540],
          [-2.5545120, 55.2043160],
          [-2.5543870, 55.2042810],
          [-2.5542620, 55.2042430],
          [-2.5541400, 55.2042060],
          [-2.5540140, 55.2041710],
          [-2.5538870, 55.2041330],
          [-2.5537710, 55.2040950],
          [-2.5536490, 55.2040570],
          [-2.5535150, 55.2040140],
          [-2.5533810, 55.2039840],
          [-2.5532380, 55.2039570],
          [-2.5530820, 55.2039300],
          [-2.5529160, 55.2038960],
          [-2.5527580, 55.2038620],
          [-2.5525860, 55.2038200],
          [-2.5524190, 55.2037670],
          [-2.5522470, 55.2037170],
          [-2.5520920, 55.2036600],
          [-2.5519590, 55.2036110],
          [-2.5518310, 55.2035610],
          [-2.5517130, 55.2035070],
          [-2.5516150, 55.2034540],
          [-2.5515290, 55.2033930],
          [-2.5514710, 55.2033280],
          [-2.5514520, 55.2032630],
          [-2.5514700, 55.2031990],
          [-2.5515040, 55.2031310],
          [-2.5515340, 55.2030580],
          [-2.5515430, 55.2029780],
          [-2.5515110, 55.2029010],
          [-2.5514480, 55.2028320],
          [-2.5513640, 55.2027820],
          [-2.5512710, 55.2027550],
          [-2.5511830, 55.2027360],
          [-2.5510900, 55.2027210],
          [-2.5509950, 55.2027020],
          [-2.5509000, 55.2026790],
          [-2.5508080, 55.2026600],
          [-2.5507180, 55.2026330],
          [-2.5506260, 55.2026100],
          [-2.5505330, 55.2025800],
          [-2.5504400, 55.2025380],
          [-2.5503610, 55.2024870],
          [-2.5502820, 55.2024250],
          [-2.5502080, 55.2023560],
          [-2.5501310, 55.2022870],
          [-2.5500570, 55.2022150],
          [-2.5499750, 55.2021420],
          [-2.5498870, 55.2020700],
          [-2.5498000, 55.2020050],
          [-2.5496960, 55.2019400],
          [-2.5495890, 55.2018750],
          [-2.5494830, 55.2018180],
          [-2.5493810, 55.2017600],
          [-2.5492800, 55.2017110],
          [-2.5491810, 55.2016610],
          [-2.5490880, 55.2016150],
          [-2.5489910, 55.2015770],
          [-2.5488940, 55.2015350],
          [-2.5487950, 55.2014970],
          [-2.5486950, 55.2014590],
          [-2.5485930, 55.2014290],
          [-2.5484850, 55.2014010],
          [-2.5483600, 55.2013820],
          [-2.5482200, 55.2013630],
          [-2.5480770, 55.2013440],
          [-2.5479290, 55.2013280],
          [-2.5477940, 55.2013020],
          [-2.5476640, 55.2012640],
          [-2.5475560, 55.2011960],
          [-2.5474470, 55.2011080],
          [-2.5473160, 55.2010180],
          [-2.5471810, 55.2009340],
          [-2.5470510, 55.2008480],
          [-2.5469300, 55.2007620],
          [-2.5468230, 55.2006740],
          [-2.5467360, 55.2005890],
          [-2.5466600, 55.2005030],
          [-2.5465710, 55.2004180],
          [-2.5465090, 55.2003450],
          [-2.5464430, 55.2002810],
          [-2.5463770, 55.2002230],
          [-2.5462950, 55.2001810],
          [-2.5461960, 55.2001390],
          [-2.5460990, 55.2000930],
          [-2.5460020, 55.2000440],
          [-2.5459340, 55.1999790],
          [-2.5458770, 55.1999040],
          [-2.5458210, 55.1998230],
          [-2.5457740, 55.1997390],
          [-2.5457320, 55.1996530],
          [-2.5456870, 55.1995700],
          [-2.5456430, 55.1994810],
          [-2.5455930, 55.1993960],
          [-2.5455380, 55.1993050],
          [-2.5454850, 55.1992170],
          [-2.5454340, 55.1991290],
          [-2.5453860, 55.1990420],
          [-2.5453410, 55.1989520],
          [-2.5452930, 55.1988580],
          [-2.5452440, 55.1987740],
          [-2.5451970, 55.1986960],
          [-2.5451490, 55.1986210],
          [-2.5451070, 55.1985530],
          [-2.5450710, 55.1984870],
          [-2.5450450, 55.1984260],
          [-2.5450220, 55.1983700],
          [-2.5449830, 55.1983200],
          [-2.5449370, 55.1982730],
          [-2.5449060, 55.1982200],
          [-2.5448870, 55.1981670],
          [-2.5449100, 55.1981210],
          [-2.5449770, 55.1980860],
          [-2.5450610, 55.1980710],
          [-2.5451550, 55.1980710],
          [-2.5452380, 55.1980850],
          [-2.5453070, 55.1981040],
          [-2.5453580, 55.1981430],
          [-2.5453990, 55.1981840],
          [-2.5454370, 55.1982220],
          [-2.5454790, 55.1982640],
          [-2.5455180, 55.1983030],
          [-2.5455560, 55.1983370],
          [-2.5455940, 55.1983710],
          [-2.5456340, 55.1984060],
          [-2.5456790, 55.1984440],
          [-2.5457240, 55.1984780],
          [-2.5457590, 55.1985160],
          [-2.5457980, 55.1985540],
          [-2.5458410, 55.1985960],
          [-2.5458810, 55.1986420],
          [-2.5459260, 55.1986880],
          [-2.5459720, 55.1987260],
          [-2.5460160, 55.1987680],
          [-2.5460630, 55.1988100],
          [-2.5461140, 55.1988480],
          [-2.5461620, 55.1988860],
          [-2.5462030, 55.1989360],
          [-2.5462390, 55.1989850],
          [-2.5462830, 55.1990350],
          [-2.5463280, 55.1990760],
          [-2.5463780, 55.1991190],
          [-2.5464210, 55.1991610],
          [-2.5464600, 55.1992020],
          [-2.5465010, 55.1992450],
          [-2.5465480, 55.1992830],
          [-2.5466010, 55.1993210],
          [-2.5466640, 55.1993520],
          [-2.5467300, 55.1993520],
          [-2.5467840, 55.1993300],
          [-2.5468180, 55.1992960],
          [-2.5468250, 55.1992540],
          [-2.5467970, 55.1992040],
          [-2.5467520, 55.1991510],
          [-2.5466990, 55.1990940],
          [-2.5466330, 55.1990330],
          [-2.5465620, 55.1989730],
          [-2.5464900, 55.1989110],
          [-2.5464140, 55.1988460],
          [-2.5463410, 55.1987810],
          [-2.5462700, 55.1987130],
          [-2.5462000, 55.1986480],
          [-2.5461290, 55.1985750],
          [-2.5460580, 55.1985030],
          [-2.5459850, 55.1984230],
          [-2.5459100, 55.1983460],
          [-2.5458340, 55.1982710],
          [-2.5457560, 55.1981900],
          [-2.5456860, 55.1981060],
          [-2.5456030, 55.1980220],
          [-2.5455200, 55.1979310],
          [-2.5454410, 55.1978390],
          [-2.5453550, 55.1977510],
          [-2.5452710, 55.1976640],
          [-2.5452000, 55.1975840],
          [-2.5451200, 55.1975070],
          [-2.5450490, 55.1974270],
          [-2.5449780, 55.1973550],
          [-2.5449080, 55.1972710],
          [-2.5448330, 55.1971910],
          [-2.5447840, 55.1971180],
          [-2.5447210, 55.1970380],
          [-2.5446560, 55.1969580],
          [-2.5445940, 55.1968780],
          [-2.5445280, 55.1967980],
          [-2.5444640, 55.1967210],
          [-2.5444050, 55.1966460],
          [-2.5443460, 55.1965690],
          [-2.5442910, 55.1964960],
          [-2.5442390, 55.1964200],
          [-2.5441920, 55.1963470],
          [-2.5441470, 55.1962670],
          [-2.5440960, 55.1961910],
          [-2.5440440, 55.1961150],
          [-2.5439920, 55.1960380],
          [-2.5439410, 55.1959660],
          [-2.5438940, 55.1958900],
          [-2.5438460, 55.1958210],
          [-2.5438010, 55.1957480],
          [-2.5437570, 55.1956800],
          [-2.5437140, 55.1956110],
          [-2.5436710, 55.1955470],
          [-2.5436320, 55.1954820],
          [-2.5435920, 55.1954160],
          [-2.5435560, 55.1953550],
          [-2.5435210, 55.1952950],
          [-2.5434880, 55.1952390],
          [-2.5434560, 55.1951800],
          [-2.5434270, 55.1951230],
          [-2.5433970, 55.1950690],
          [-2.5433700, 55.1950190],
          [-2.5433460, 55.1949740],
          [-2.5433190, 55.1949240],
          [-2.5432940, 55.1948790],
          [-2.5432690, 55.1948290],
          [-2.5432460, 55.1947790],
          [-2.5432210, 55.1947340],
          [-2.5431940, 55.1946840],
          [-2.5431660, 55.1946360],
          [-2.5431390, 55.1945890],
          [-2.5431170, 55.1945430],
          [-2.5430970, 55.1944970],
          [-2.5430740, 55.1944550],
          [-2.5430520, 55.1944090],
          [-2.5430320, 55.1943670],
          [-2.5430140, 55.1943210],
          [-2.5429920, 55.1942760],
          [-2.5429740, 55.1942340],
          [-2.5429550, 55.1941880],
          [-2.5429380, 55.1941460],
          [-2.5429220, 55.1941040],
          [-2.5429020, 55.1940620],
          [-2.5428840, 55.1940200],
          [-2.5428670, 55.1939820],
          [-2.5428490, 55.1939440],
          [-2.5428320, 55.1939020],
          [-2.5428140, 55.1938640],
          [-2.5427950, 55.1938220],
          [-2.5427830, 55.1937830],
          [-2.5427720, 55.1937450],
          [-2.5427570, 55.1937030],
          [-2.5427440, 55.1936650],
          [-2.5427300, 55.1936310],
          [-2.5427150, 55.1935930],
          [-2.5427000, 55.1935580],
          [-2.5426870, 55.1935240],
          [-2.5426770, 55.1934860],
          [-2.5426670, 55.1934510],
          [-2.5426550, 55.1934130],
          [-2.5426470, 55.1933790],
          [-2.5426320, 55.1933390],
          [-2.5426220, 55.1933030],
          [-2.5426080, 55.1932650],
          [-2.5426000, 55.1932300],
          [-2.5425900, 55.1931930],
          [-2.5425800, 55.1931580],
          [-2.5425720, 55.1931240],
          [-2.5425620, 55.1930890],
          [-2.5425540, 55.1930480],
          [-2.5425470, 55.1930090],
          [-2.5425400, 55.1929710],
          [-2.5425320, 55.1929330],
          [-2.5425200, 55.1928940],
          [-2.5425090, 55.1928530],
          [-2.5424990, 55.1928150],
          [-2.5424870, 55.1927770],
          [-2.5424750, 55.1927420],
          [-2.5424670, 55.1927050],
          [-2.5424590, 55.1926630],
          [-2.5424520, 55.1926240],
          [-2.5424420, 55.1925860],
          [-2.5424320, 55.1925480],
          [-2.5424200, 55.1925060],
          [-2.5424130, 55.1924680],
          [-2.5424070, 55.1924260],
          [-2.5424000, 55.1923870],
          [-2.5423970, 55.1923500],
          [-2.5423920, 55.1923080],
          [-2.5423820, 55.1922690],
          [-2.5423740, 55.1922310],
          [-2.5423640, 55.1921900],
          [-2.5423550, 55.1921480],
          [-2.5423490, 55.1921030],
          [-2.5423430, 55.1920590],
          [-2.5423370, 55.1920140],
          [-2.5423280, 55.1919710],
          [-2.5423190, 55.1919260],
          [-2.5423130, 55.1918800],
          [-2.5423080, 55.1918350],
          [-2.5423020, 55.1917920],
          [-2.5423020, 55.1917470],
          [-2.5422970, 55.1917010],
          [-2.5422930, 55.1916590],
          [-2.5422870, 55.1916140],
          [-2.5422800, 55.1915670],
          [-2.5422800, 55.1915250],
          [-2.5422780, 55.1914830],
          [-2.5422720, 55.1914410],
          [-2.5422630, 55.1913990],
          [-2.5422550, 55.1913540],
          [-2.5422480, 55.1913090],
          [-2.5422420, 55.1912620],
          [-2.5422370, 55.1912130],
          [-2.5422350, 55.1911670],
          [-2.5422300, 55.1911170],
          [-2.5422310, 55.1910680],
          [-2.5422330, 55.1910220],
          [-2.5422300, 55.1909760],
          [-2.5422270, 55.1909310],
          [-2.5422230, 55.1908870],
          [-2.5422180, 55.1908390],
          [-2.5422170, 55.1907890],
          [-2.5422170, 55.1907430],
          [-2.5422170, 55.1906940],
          [-2.5422170, 55.1906440],
          [-2.5422170, 55.1905910],
          [-2.5422170, 55.1905370],
          [-2.5422220, 55.1904840],
          [-2.5422260, 55.1904300],
          [-2.5422300, 55.1903730],
          [-2.5422330, 55.1903160],
          [-2.5422370, 55.1902630],
          [-2.5422400, 55.1902020],
          [-2.5422430, 55.1901410],
          [-2.5422470, 55.1900800],
          [-2.5422500, 55.1900160],
          [-2.5422530, 55.1899520],
          [-2.5422570, 55.1898810],
          [-2.5422600, 55.1898130],
          [-2.5422670, 55.1897370],
          [-2.5422730, 55.1896640],
          [-2.5422800, 55.1895840],
          [-2.5422850, 55.1895040],
          [-2.5422930, 55.1894210],
          [-2.5423030, 55.1893370],
          [-2.5423160, 55.1892450],
          [-2.5423290, 55.1891590],
          [-2.5423430, 55.1890620],
          [-2.5423630, 55.1889630],
          [-2.5423830, 55.1888710],
          [-2.5423980, 55.1887720],
          [-2.5424060, 55.1886740],
          [-2.5424230, 55.1885740],
          [-2.5424510, 55.1884710],
          [-2.5424710, 55.1883710],
          [-2.5424990, 55.1882740],
          [-2.5425310, 55.1881740],
          [-2.5425610, 55.1880700],
          [-2.5425960, 55.1879670],
          [-2.5426290, 55.1878610],
          [-2.5426790, 55.1877570],
          [-2.5427010, 55.1876460],
          [-2.5427410, 55.1875330],
          [-2.5427610, 55.1874180],
          [-2.5427860, 55.1873000],
          [-2.5428060, 55.1871820],
          [-2.5428420, 55.1870570],
          [-2.5428630, 55.1869330],
          [-2.5428650, 55.1868120],
          [-2.5428780, 55.1866900],
          [-2.5428920, 55.1865600],
          [-2.5428850, 55.1864350],
          [-2.5428690, 55.1863120],
          [-2.5428470, 55.1861960],
          [-2.5428270, 55.1860840],
          [-2.5428120, 55.1859720],
          [-2.5427920, 55.1858660],
          [-2.5427520, 55.1857590],
          [-2.5427120, 55.1856550],
          [-2.5426840, 55.1855530],
          [-2.5426490, 55.1854450],
          [-2.5426090, 55.1853460],
          [-2.5425670, 55.1852510],
          [-2.5425280, 55.1851630],
          [-2.5424870, 55.1850720],
          [-2.5424470, 55.1849800],
          [-2.5424040, 55.1848890],
          [-2.5423490, 55.1848010],
          [-2.5423070, 55.1847090],
          [-2.5422500, 55.1846260],
          [-2.5421950, 55.1845450],
          [-2.5421410, 55.1844650],
          [-2.5420880, 55.1843810],
          [-2.5420290, 55.1843010],
          [-2.5419700, 55.1842260],
          [-2.5419110, 55.1841480],
          [-2.5418520, 55.1840770],
          [-2.5417890, 55.1840030],
          [-2.5417300, 55.1839320],
          [-2.5416690, 55.1838630],
          [-2.5416060, 55.1837930],
          [-2.5415400, 55.1837250],
          [-2.5414740, 55.1836560],
          [-2.5414060, 55.1835910],
          [-2.5413420, 55.1835310],
          [-2.5412750, 55.1834690],
          [-2.5412070, 55.1834080],
          [-2.5411410, 55.1833430],
          [-2.5410750, 55.1832820],
          [-2.5410090, 55.1832210],
          [-2.5409440, 55.1831640],
          [-2.5408750, 55.1831040],
          [-2.5408110, 55.1830430],
          [-2.5407430, 55.1829810],
          [-2.5406770, 55.1829200],
          [-2.5406100, 55.1828590],
          [-2.5405430, 55.1827980],
          [-2.5404760, 55.1827330],
          [-2.5404110, 55.1826720],
          [-2.5403490, 55.1826140],
          [-2.5402880, 55.1825530],
          [-2.5402260, 55.1824920],
          [-2.5401640, 55.1824310],
          [-2.5401060, 55.1823740],
          [-2.5400500, 55.1823170],
          [-2.5399910, 55.1822560],
          [-2.5399330, 55.1822040],
          [-2.5398750, 55.1821420],
          [-2.5398180, 55.1820850],
          [-2.5397620, 55.1820270],
          [-2.5397100, 55.1819700],
          [-2.5396560, 55.1819130],
          [-2.5396030, 55.1818590],
          [-2.5395490, 55.1818020],
          [-2.5394990, 55.1817460],
          [-2.5394460, 55.1816870],
          [-2.5393940, 55.1816300],
          [-2.5393420, 55.1815730],
          [-2.5392910, 55.1815200],
          [-2.5392370, 55.1814660],
          [-2.5391860, 55.1814130],
          [-2.5391370, 55.1813630],
          [-2.5390930, 55.1813100],
          [-2.5390460, 55.1812560],
          [-2.5390020, 55.1812070],
          [-2.5389610, 55.1811530],
          [-2.5389170, 55.1811040],
          [-2.5388760, 55.1810540],
          [-2.5388320, 55.1810040],
          [-2.5387940, 55.1809580],
          [-2.5387510, 55.1809090],
          [-2.5387110, 55.1808600],
          [-2.5386690, 55.1808140],
          [-2.5386280, 55.1807640],
          [-2.5385880, 55.1807110],
          [-2.5385430, 55.1806580],
          [-2.5384990, 55.1806080],
          [-2.5384570, 55.1805620],
          [-2.5384150, 55.1805130],
          [-2.5383700, 55.1804630],
          [-2.5383310, 55.1804170],
          [-2.5382870, 55.1803730],
          [-2.5382470, 55.1803250],
          [-2.5382120, 55.1802760],
          [-2.5381740, 55.1802350],
          [-2.5381380, 55.1801880],
          [-2.5381030, 55.1801430],
          [-2.5380660, 55.1800960],
          [-2.5380280, 55.1800510],
          [-2.5379890, 55.1800050],
          [-2.5379510, 55.1799590],
          [-2.5379170, 55.1799140],
          [-2.5378860, 55.1798650],
          [-2.5378540, 55.1798140],
          [-2.5378260, 55.1797650],
          [-2.5377970, 55.1797190],
          [-2.5377650, 55.1796700],
          [-2.5377290, 55.1796240],
          [-2.5376950, 55.1795780],
          [-2.5376650, 55.1795280],
          [-2.5376390, 55.1794790],
          [-2.5376100, 55.1794290],
          [-2.5375830, 55.1793840],
          [-2.5375490, 55.1793340],
          [-2.5375150, 55.1792860],
          [-2.5374810, 55.1792340],
          [-2.5374490, 55.1791850],
          [-2.5374170, 55.1791350],
          [-2.5373860, 55.1790860],
          [-2.5373550, 55.1790320],
          [-2.5373240, 55.1789830],
          [-2.5372920, 55.1789290],
          [-2.5372640, 55.1788760],
          [-2.5372340, 55.1788220],
          [-2.5372030, 55.1787730],
          [-2.5371690, 55.1787230],
          [-2.5371380, 55.1786740],
          [-2.5371060, 55.1786240],
          [-2.5370740, 55.1785710],
          [-2.5370440, 55.1785210],
          [-2.5370140, 55.1784720],
          [-2.5369850, 55.1784220],
          [-2.5369560, 55.1783720],
          [-2.5369280, 55.1783230],
          [-2.5368990, 55.1782730],
          [-2.5368700, 55.1782230],
          [-2.5368430, 55.1781750],
          [-2.5368150, 55.1781240],
          [-2.5367890, 55.1780710],
          [-2.5367640, 55.1780210],
          [-2.5367400, 55.1779680],
          [-2.5367160, 55.1779190],
          [-2.5366890, 55.1778690],
          [-2.5366600, 55.1778150],
          [-2.5366340, 55.1777620],
          [-2.5366060, 55.1777140],
          [-2.5365750, 55.1776590],
          [-2.5365490, 55.1776050],
          [-2.5365220, 55.1775530],
          [-2.5364940, 55.1774990],
          [-2.5364640, 55.1774420],
          [-2.5364370, 55.1773840],
          [-2.5364110, 55.1773270],
          [-2.5363820, 55.1772730],
          [-2.5363540, 55.1772170],
          [-2.5363270, 55.1771590],
          [-2.5363020, 55.1771060],
          [-2.5362770, 55.1770490],
          [-2.5362540, 55.1769960],
          [-2.5362290, 55.1769380],
          [-2.5362040, 55.1768810],
          [-2.5361760, 55.1768240],
          [-2.5361470, 55.1767660],
          [-2.5361200, 55.1767090],
          [-2.5360940, 55.1766560],
          [-2.5360700, 55.1765980],
          [-2.5360460, 55.1765420],
          [-2.5360200, 55.1764840],
          [-2.5359920, 55.1764230],
          [-2.5359630, 55.1763680],
          [-2.5359300, 55.1763090],
          [-2.5359040, 55.1762470],
          [-2.5358770, 55.1761940],
          [-2.5358520, 55.1761370],
          [-2.5358270, 55.1760800],
          [-2.5358000, 55.1760220],
          [-2.5357760, 55.1759660],
          [-2.5357520, 55.1759080],
          [-2.5357270, 55.1758510],
          [-2.5357040, 55.1757940],
          [-2.5356800, 55.1757380],
          [-2.5356520, 55.1756790],
          [-2.5356300, 55.1756260],
          [-2.5356090, 55.1755690],
          [-2.5355840, 55.1755110],
          [-2.5355590, 55.1754540],
          [-2.5355310, 55.1754020],
          [-2.5355070, 55.1753430],
          [-2.5354800, 55.1752820],
          [-2.5354550, 55.1752290],
          [-2.5354310, 55.1751690],
          [-2.5354070, 55.1751110],
          [-2.5353810, 55.1750540],
          [-2.5353520, 55.1749960],
          [-2.5353260, 55.1749400],
          [-2.5352990, 55.1748820],
          [-2.5352720, 55.1748250],
          [-2.5352440, 55.1747670],
          [-2.5352140, 55.1747070],
          [-2.5351820, 55.1746490],
          [-2.5351510, 55.1745890],
          [-2.5351210, 55.1745270],
          [-2.5350890, 55.1744670],
          [-2.5350590, 55.1744050],
          [-2.5350260, 55.1743450],
          [-2.5349920, 55.1742790],
          [-2.5349590, 55.1742210],
          [-2.5349240, 55.1741540],
          [-2.5348890, 55.1740920],
          [-2.5348520, 55.1740270],
          [-2.5348160, 55.1739640],
          [-2.5347760, 55.1738980],
          [-2.5347320, 55.1738290],
          [-2.5346890, 55.1737570],
          [-2.5346470, 55.1736880],
          [-2.5346040, 55.1736190],
          [-2.5345590, 55.1735470],
          [-2.5345140, 55.1734740],
          [-2.5344670, 55.1733980],
          [-2.5344190, 55.1733260],
          [-2.5343710, 55.1732530],
          [-2.5343220, 55.1731770],
          [-2.5342730, 55.1731010],
          [-2.5342190, 55.1730240],
          [-2.5341660, 55.1729480],
          [-2.5341080, 55.1728650],
          [-2.5340510, 55.1727810],
          [-2.5339910, 55.1727000],
          [-2.5339300, 55.1726170],
          [-2.5338670, 55.1725320],
          [-2.5338060, 55.1724440],
          [-2.5337410, 55.1723570],
          [-2.5336730, 55.1722690],
          [-2.5336030, 55.1721810],
          [-2.5335340, 55.1720900],
          [-2.5334630, 55.1719980],
          [-2.5333900, 55.1719090],
          [-2.5332980, 55.1718110],
          [-2.5332160, 55.1717200],
          [-2.5331480, 55.1716290],
          [-2.5330570, 55.1715480],
          [-2.5329830, 55.1714530],
          [-2.5328980, 55.1713650],
          [-2.5328140, 55.1712700],
          [-2.5327330, 55.1711750],
          [-2.5326690, 55.1710750],
          [-2.5325950, 55.1709850],
          [-2.5324870, 55.1709260],
          [-2.5323760, 55.1708950],
          [-2.5322560, 55.1708950],
          [-2.5321390, 55.1709210],
          [-2.5320170, 55.1709400],
          [-2.5318950, 55.1709590],
          [-2.5317720, 55.1709740],
          [-2.5316440, 55.1709820],
          [-2.5315200, 55.1709860],
          [-2.5314030, 55.1709900],
          [-2.5312850, 55.1709940],
          [-2.5311750, 55.1709980],
          [-2.5310710, 55.1710050],
          [-2.5309720, 55.1710160],
          [-2.5308780, 55.1710280],
          [-2.5307880, 55.1710350],
          [-2.5306990, 55.1710430],
          [-2.5306160, 55.1710540],
          [-2.5305360, 55.1710700],
          [-2.5304680, 55.1710920],
          [-2.5303980, 55.1711120],
          [-2.5303310, 55.1711300],
          [-2.5302620, 55.1711500],
          [-2.5301960, 55.1711650],
          [-2.5301310, 55.1711800],
          [-2.5300680, 55.1711920],
          [-2.5300040, 55.1712070],
          [-2.5299410, 55.1712190],
          [-2.5298810, 55.1712300],
          [-2.5298270, 55.1712410],
          [-2.5297730, 55.1712530],
          [-2.5297140, 55.1712640],
          [-2.5296600, 55.1712750],
          [-2.5296060, 55.1712900],
          [-2.5295560, 55.1713100],
          [-2.5295180, 55.1713330],
          [-2.5294990, 55.1713590],
          [-2.5294860, 55.1713860],
          [-2.5294800, 55.1714090],
          [-2.5294700, 55.1714320],
          [-2.5294600, 55.1714580],
          [-2.5294540, 55.1714810],
          [-2.5294550, 55.1715080],
          [-2.5294580, 55.1715350],
          [-2.5294680, 55.1715650],
          [-2.5294780, 55.1715960],
          [-2.5294900, 55.1716230],
          [-2.5295030, 55.1716490],
          [-2.5295160, 55.1716800],
          [-2.5295280, 55.1717060],
          [-2.5295380, 55.1717370],
          [-2.5295500, 55.1717600],
          [-2.5295600, 55.1717870],
          [-2.5295770, 55.1718100],
          [-2.5295900, 55.1718330],
          [-2.5296050, 55.1718560],
          [-2.5296180, 55.1718780],
          [-2.5296350, 55.1719050],
          [-2.5296560, 55.1719320],
          [-2.5296760, 55.1719580],
          [-2.5296940, 55.1719810],
          [-2.5297100, 55.1720040],
          [-2.5297250, 55.1720270],
          [-2.5297460, 55.1720500],
          [-2.5297630, 55.1720730],
          [-2.5297800, 55.1720960],
          [-2.5297880, 55.1721190],
          [-2.5297980, 55.1721420],
          [-2.5298130, 55.1721680],
          [-2.5298260, 55.1721910],
          [-2.5298430, 55.1722140],
          [-2.5298600, 55.1722370],
          [-2.5298760, 55.1722600],
          [-2.5298870, 55.1722830],
          [-2.5299020, 55.1723100],
          [-2.5299160, 55.1723280],
          [-2.5299350, 55.1723520],
          [-2.5299530, 55.1723780],
          [-2.5299700, 55.1724010],
          [-2.5299810, 55.1724270],
          [-2.5299910, 55.1724540],
          [-2.5300080, 55.1724810],
          [-2.5300250, 55.1725040],
          [-2.5300500, 55.1725270],
          [-2.5300760, 55.1725500],
          [-2.5301010, 55.1725730],
          [-2.5301200, 55.1725960],
          [-2.5301430, 55.1726180],
          [-2.5301710, 55.1726410],
          [-2.5302020, 55.1726640],
          [-2.5302370, 55.1726830],
          [-2.5302830, 55.1727100],
          [-2.5303370, 55.1727330],
          [-2.5303880, 55.1727600],
          [-2.5304340, 55.1727820],
          [-2.5304800, 55.1728010],
          [-2.5305270, 55.1728210],
          [-2.5305730, 55.1728400],
          [-2.5306210, 55.1728620],
          [-2.5306700, 55.1728920],
          [-2.5307260, 55.1729240],
          [-2.5307720, 55.1729500],
          [-2.5308180, 55.1729770],
          [-2.5308710, 55.1730070],
          [-2.5309190, 55.1730380],
          [-2.5309680, 55.1730720],
          [-2.5310120, 55.1731030],
          [-2.5310520, 55.1731440],
          [-2.5310760, 55.1731900],
          [-2.5310950, 55.1732430],
          [-2.5311050, 55.1732900],
          [-2.5310990, 55.1733390],
          [-2.5310790, 55.1733890],
          [-2.5310440, 55.1734450],
          [-2.5309960, 55.1735060],
          [-2.5309290, 55.1735600],
          [-2.5308550, 55.1736120],
          [-2.5307680, 55.1736560],
          [-2.5306730, 55.1736940],
          [-2.5304860, 55.1737560],
          [-2.5302970, 55.1738190],
          [-2.5301080, 55.1738820],
          [-2.5299320, 55.1739330],
          [-2.5297920, 55.1739700],
          [-2.5296730, 55.1740070],
          [-2.5294480, 55.1740600],
          [-2.5293090, 55.1741060],
          [-2.5291660, 55.1741480],
          [-2.5289950, 55.1741750],
          [-2.5288260, 55.1742160],
          [-2.5286520, 55.1742630],
          [-2.5284780, 55.1743050],
          [-2.5283130, 55.1743450],
          [-2.5281450, 55.1743850],
          [-2.5279750, 55.1744230],
          [-2.5278080, 55.1744650],
          [-2.5276450, 55.1745060],
          [-2.5274840, 55.1745490],
          [-2.5273310, 55.1745950],
          [-2.5271860, 55.1746470],
          [-2.5270330, 55.1747010],
          [-2.5268810, 55.1747550],
          [-2.5267270, 55.1748120],
          [-2.5265700, 55.1748690],
          [-2.5264120, 55.1749220],
          [-2.5262550, 55.1749800],
          [-2.5260950, 55.1750410],
          [-2.5259400, 55.1751050],
          [-2.5257850, 55.1751740],
          [-2.5256370, 55.1752420],
          [-2.5254890, 55.1753100],
          [-2.5253390, 55.1753870],
          [-2.5251950, 55.1754560],
          [-2.5250550, 55.1755330],
          [-2.5249180, 55.1756010],
          [-2.5247900, 55.1756700],
          [-2.5246650, 55.1757310],
          [-2.5245450, 55.1757960],
          [-2.5244240, 55.1758530],
          [-2.5243050, 55.1759140],
          [-2.5241870, 55.1759710],
          [-2.5240710, 55.1760240],
          [-2.5239530, 55.1760820],
          [-2.5238380, 55.1761390],
          [-2.5237270, 55.1761880],
          [-2.5236160, 55.1762340],
          [-2.5235180, 55.1762650],
          [-2.5234420, 55.1762920],
          [-2.5234040, 55.1763190],
          [-2.5234250, 55.1763380],
          [-2.5234880, 55.1763460],
          [-2.5235680, 55.1763500],
          [-2.5236490, 55.1763610],
          [-2.5237410, 55.1763880],
          [-2.5238360, 55.1764260],
          [-2.5239360, 55.1764670],
          [-2.5240400, 55.1765150],
          [-2.5241540, 55.1765700],
          [-2.5242670, 55.1766270],
          [-2.5243920, 55.1766880],
          [-2.5245350, 55.1767460],
          [-2.5246860, 55.1767990],
          [-2.5248490, 55.1768520],
          [-2.5250160, 55.1769020],
          [-2.5251790, 55.1769480],
          [-2.5253290, 55.1769790],
          [-2.5254630, 55.1769980],
          [-2.5255650, 55.1770130],
          [-2.5256300, 55.1770280],
          [-2.5256630, 55.1770470],
          [-2.5256750, 55.1770670],
          [-2.5256600, 55.1770820],
          [-2.5256120, 55.1770860],
          [-2.5255530, 55.1770900],
          [-2.5254810, 55.1770860],
          [-2.5254090, 55.1770780],
          [-2.5253400, 55.1770780],
          [-2.5252740, 55.1770780],
          [-2.5252110, 55.1770820],
          [-2.5251560, 55.1770820],
          [-2.5250900, 55.1770860],
          [-2.5250240, 55.1770860],
          [-2.5249680, 55.1770780],
          [-2.5249160, 55.1770670],
          [-2.5248600, 55.1770560],
          [-2.5248000, 55.1770440],
          [-2.5247290, 55.1770290],
          [-2.5246490, 55.1770250],
          [-2.5245570, 55.1770140],
          [-2.5244660, 55.1770060],
          [-2.5243860, 55.1769980],
          [-2.5243060, 55.1769870],
          [-2.5242280, 55.1769720],
          [-2.5241440, 55.1769570],
          [-2.5240600, 55.1769380],
          [-2.5239730, 55.1769220],
          [-2.5238800, 55.1769110],
          [-2.5237820, 55.1769030],
          [-2.5236880, 55.1768990],
          [-2.5235770, 55.1769060],
          [-2.5234710, 55.1769140],
          [-2.5233470, 55.1769180],
          [-2.5232280, 55.1769180],
          [-2.5230990, 55.1769290],
          [-2.5229760, 55.1769440],
          [-2.5228500, 55.1769600],
          [-2.5227240, 55.1769790],
          [-2.5225940, 55.1769940],
          [-2.5224680, 55.1770120],
          [-2.5223230, 55.1770250],
          [-2.5221940, 55.1770390],
          [-2.5220440, 55.1770480],
          [-2.5219080, 55.1770550],
          [-2.5217740, 55.1770630],
          [-2.5216470, 55.1770740],
          [-2.5215090, 55.1770860],
          [-2.5213810, 55.1771040],
          [-2.5212440, 55.1771270],
          [-2.5211080, 55.1771540],
          [-2.5209700, 55.1771840],
          [-2.5208290, 55.1772150],
          [-2.5206790, 55.1772530],
          [-2.5205360, 55.1772870],
          [-2.5203750, 55.1773290],
          [-2.5202250, 55.1773660],
          [-2.5200700, 55.1774010],
          [-2.5199260, 55.1774310],
          [-2.5197650, 55.1774550],
          [-2.5196180, 55.1774740],
          [-2.5194730, 55.1774900],
          [-2.5193330, 55.1775120],
          [-2.5191910, 55.1775320],
          [-2.5190450, 55.1775580],
          [-2.5189050, 55.1775890],
          [-2.5187740, 55.1776180],
          [-2.5186330, 55.1776460],
          [-2.5185080, 55.1776710],
          [-2.5183610, 55.1776920],
          [-2.5182320, 55.1777110],
          [-2.5180950, 55.1777270],
          [-2.5179690, 55.1777380],
          [-2.5178340, 55.1777490],
          [-2.5177040, 55.1777610],
          [-2.5175730, 55.1777690],
          [-2.5174560, 55.1777800],
          [-2.5173330, 55.1777880],
          [-2.5172230, 55.1777910],
          [-2.5171120, 55.1777990],
          [-2.5170110, 55.1778070],
          [-2.5169050, 55.1778140],
          [-2.5168050, 55.1778220],
          [-2.5167060, 55.1778330],
          [-2.5166190, 55.1778480],
          [-2.5165320, 55.1778710],
          [-2.5164460, 55.1779010],
          [-2.5163620, 55.1779280],
          [-2.5162750, 55.1779620],
          [-2.5161850, 55.1779930],
          [-2.5160980, 55.1780260],
          [-2.5160020, 55.1780540],
          [-2.5159080, 55.1780770],
          [-2.5158050, 55.1781000],
          [-2.5157100, 55.1781180],
          [-2.5156030, 55.1781350],
          [-2.5155040, 55.1781490],
          [-2.5153960, 55.1781580],
          [-2.5152940, 55.1781610],
          [-2.5151900, 55.1781650],
          [-2.5151030, 55.1781620],
          [-2.5150080, 55.1781580],
          [-2.5149370, 55.1781510],
          [-2.5148430, 55.1781500],
          [-2.5147640, 55.1781540],
          [-2.5146730, 55.1781580],
          [-2.5145940, 55.1781580],
          [-2.5145060, 55.1781500],
          [-2.5144310, 55.1781470],
          [-2.5143500, 55.1781390],
          [-2.5142800, 55.1781320],
          [-2.5141980, 55.1781240],
          [-2.5141250, 55.1781160],
          [-2.5140500, 55.1781090],
          [-2.5139710, 55.1781010],
          [-2.5138910, 55.1780930],
          [-2.5138180, 55.1780820],
          [-2.5137420, 55.1780740],
          [-2.5136700, 55.1780630],
          [-2.5136030, 55.1780520],
          [-2.5135240, 55.1780430],
          [-2.5134510, 55.1780280],
          [-2.5133820, 55.1780170],
          [-2.5133060, 55.1780050],
          [-2.5132390, 55.1779900],
          [-2.5131760, 55.1779720],
          [-2.5131020, 55.1779560],
          [-2.5130310, 55.1779410],
          [-2.5129630, 55.1779260],
          [-2.5128930, 55.1779060],
          [-2.5128230, 55.1778950],
          [-2.5127510, 55.1778910],
          [-2.5126750, 55.1779020],
          [-2.5126070, 55.1779360],
          [-2.5125550, 55.1779840],
          [-2.5125240, 55.1780350],
          [-2.5125120, 55.1780940],
          [-2.5124920, 55.1781570],
          [-2.5124560, 55.1782140],
          [-2.5124160, 55.1782670],
          [-2.5123690, 55.1783140],
          [-2.5123090, 55.1783550],
          [-2.5122490, 55.1783870],
          [-2.5121700, 55.1784200],
          [-2.5120970, 55.1784460],
          [-2.5120210, 55.1784780],
          [-2.5119500, 55.1785040],
          [-2.5118740, 55.1785420],
          [-2.5118010, 55.1785780],
          [-2.5117160, 55.1786170],
          [-2.5116210, 55.1786480],
          [-2.5115080, 55.1786760],
          [-2.5113930, 55.1786980],
          [-2.5112730, 55.1787140],
          [-2.5111700, 55.1787280],
          [-2.5110400, 55.1787490],
          [-2.5109190, 55.1787560],
          [-2.5107740, 55.1787570],
          [-2.5106490, 55.1787420],
          [-2.5105150, 55.1787340],
          [-2.5103990, 55.1787340],
          [-2.5102680, 55.1787340],
          [-2.5101470, 55.1787450],
          [-2.5100170, 55.1787560],
          [-2.5098920, 55.1787600],
          [-2.5097410, 55.1787640],
          [-2.5096170, 55.1787610],
          [-2.5094700, 55.1787530],
          [-2.5093350, 55.1787350],
          [-2.5091920, 55.1787120],
          [-2.5090740, 55.1786830],
          [-2.5089520, 55.1786540],
          [-2.5088590, 55.1786270],
          [-2.5087540, 55.1785930],
          [-2.5086790, 55.1785730],
          [-2.5086020, 55.1785620],
          [-2.5085420, 55.1785690],
          [-2.5084940, 55.1786070],
          [-2.5084640, 55.1786560],
          [-2.5084380, 55.1787160],
          [-2.5083910, 55.1787830],
          [-2.5083280, 55.1788490],
          [-2.5082550, 55.1789270],
          [-2.5081980, 55.1789950],
          [-2.5081440, 55.1790700],
          [-2.5080990, 55.1791390],
          [-2.5080500, 55.1792160],
          [-2.5080050, 55.1792750],
          [-2.5079550, 55.1793510],
          [-2.5079020, 55.1794210],
          [-2.5078280, 55.1794940],
          [-2.5077480, 55.1795510],
          [-2.5076500, 55.1796040],
          [-2.5075480, 55.1796380],
          [-2.5074290, 55.1796520],
          [-2.5073050, 55.1796460],
          [-2.5071850, 55.1796530],
          [-2.5070840, 55.1796660],
          [-2.5069760, 55.1797010],
          [-2.5068840, 55.1797430],
          [-2.5067830, 55.1797840],
          [-2.5066950, 55.1798170],
          [-2.5065750, 55.1798300],
          [-2.5064500, 55.1798400],
          [-2.5063190, 55.1798400],
          [-2.5061960, 55.1798360],
          [-2.5060880, 55.1798290],
          [-2.5059890, 55.1798220],
          [-2.5058900, 55.1798230],
          [-2.5057920, 55.1798250],
          [-2.5057070, 55.1798510],
          [-2.5056300, 55.1798930],
          [-2.5055450, 55.1799460],
          [-2.5054450, 55.1799880],
          [-2.5053310, 55.1800150],
          [-2.5052100, 55.1800380],
          [-2.5050990, 55.1800460],
          [-2.5049880, 55.1800420],
          [-2.5048720, 55.1800380],
          [-2.5047630, 55.1800310],
          [-2.5046560, 55.1800270],
          [-2.5045460, 55.1800230],
          [-2.5044360, 55.1800190],
          [-2.5043240, 55.1800120],
          [-2.5042110, 55.1800040],
          [-2.5040910, 55.1799970],
          [-2.5039770, 55.1799890],
          [-2.5038590, 55.1799850],
          [-2.5037460, 55.1799810],
          [-2.5036330, 55.1799810],
          [-2.5035220, 55.1799770],
          [-2.5034160, 55.1799740],
          [-2.5033080, 55.1799700],
          [-2.5032010, 55.1799660],
          [-2.5030950, 55.1799620],
          [-2.5029910, 55.1799580],
          [-2.5028850, 55.1799510],
          [-2.5027750, 55.1799470],
          [-2.5026700, 55.1799430],
          [-2.5025620, 55.1799390],
          [-2.5024590, 55.1799360],
          [-2.5023530, 55.1799320],
          [-2.5022480, 55.1799280],
          [-2.5021460, 55.1799240],
          [-2.5020440, 55.1799200],
          [-2.5019490, 55.1799170],
          [-2.5018490, 55.1799090],
          [-2.5017530, 55.1799050],
          [-2.5016530, 55.1799010],
          [-2.5015480, 55.1799050],
          [-2.5014400, 55.1799050],
          [-2.5013310, 55.1799050],
          [-2.5012180, 55.1799010],
          [-2.5011100, 55.1799010],
          [-2.5010000, 55.1798940],
          [-2.5008880, 55.1798930],
          [-2.5007790, 55.1798900],
          [-2.5006680, 55.1798820],
          [-2.5005530, 55.1798780],
          [-2.5004400, 55.1798780],
          [-2.5003270, 55.1798740],
          [-2.5002190, 55.1798710],
          [-2.5001040, 55.1798670],
          [-2.4999920, 55.1798630],
          [-2.4998810, 55.1798520],
          [-2.4997640, 55.1798480],
          [-2.4996480, 55.1798400],
          [-2.4995310, 55.1798360],
          [-2.4994150, 55.1798250],
          [-2.4993020, 55.1798210],
          [-2.4991880, 55.1798100],
          [-2.4990800, 55.1798020],
          [-2.4989700, 55.1797940],
          [-2.4988600, 55.1797830],
          [-2.4987420, 55.1797720],
          [-2.4986190, 55.1797640],
          [-2.4984910, 55.1797600],
          [-2.4983660, 55.1797560],
          [-2.4982470, 55.1797490],
          [-2.4981260, 55.1797410],
          [-2.4980140, 55.1797330],
          [-2.4979080, 55.1797220],
          [-2.4978110, 55.1797030],
          [-2.4977310, 55.1796770],
          [-2.4976580, 55.1796420],
          [-2.4975930, 55.1796000],
          [-2.4975240, 55.1795630],
          [-2.4974560, 55.1795240],
          [-2.4973990, 55.1794900],
          [-2.4973480, 55.1794550],
          [-2.4972970, 55.1794250],
          [-2.4972450, 55.1793910],
          [-2.4971910, 55.1793560],
          [-2.4971380, 55.1793150],
          [-2.4970810, 55.1792800],
          [-2.4970210, 55.1792460],
          [-2.4969560, 55.1792070],
          [-2.4968920, 55.1791730],
          [-2.4968380, 55.1791430],
          [-2.4967820, 55.1791120],
          [-2.4967230, 55.1790890],
          [-2.4966590, 55.1790700],
          [-2.4965910, 55.1790540],
          [-2.4965060, 55.1790320],
          [-2.4964270, 55.1790130],
          [-2.4963410, 55.1790010],
          [-2.4962460, 55.1789930],
          [-2.4961450, 55.1789930],
          [-2.4960390, 55.1790010],
          [-2.4959360, 55.1790120],
          [-2.4958350, 55.1790310],
          [-2.4957250, 55.1790540],
          [-2.4956050, 55.1790770],
          [-2.4954810, 55.1790850],
          [-2.4953480, 55.1790740],
          [-2.4952150, 55.1790620],
          [-2.4950870, 55.1790540],
          [-2.4949670, 55.1790660],
          [-2.4948350, 55.1790810],
          [-2.4947050, 55.1790920],
          [-2.4945790, 55.1791000],
          [-2.4944550, 55.1791040],
          [-2.4943410, 55.1791080],
          [-2.4942340, 55.1791040],
          [-2.4941380, 55.1791080],
          [-2.4940410, 55.1791110],
          [-2.4939460, 55.1791230],
          [-2.4938530, 55.1791270],
          [-2.4937580, 55.1791270],
          [-2.4936640, 55.1791380],
          [-2.4935630, 55.1791530],
          [-2.4934670, 55.1791720],
          [-2.4933680, 55.1791840],
          [-2.4932650, 55.1791880],
          [-2.4931630, 55.1791880],
          [-2.4930660, 55.1791880],
          [-2.4929710, 55.1791920],
          [-2.4928720, 55.1791920],
          [-2.4927750, 55.1791880],
          [-2.4926740, 55.1791840],
          [-2.4925700, 55.1791760],
          [-2.4924730, 55.1791650],
          [-2.4923790, 55.1791570],
          [-2.4922890, 55.1791500],
          [-2.4922030, 55.1791420],
          [-2.4921080, 55.1791380],
          [-2.4920200, 55.1791340],
          [-2.4919410, 55.1791310],
          [-2.4918550, 55.1791230],
          [-2.4917660, 55.1791120],
          [-2.4916680, 55.1791040],
          [-2.4915630, 55.1790930],
          [-2.4914510, 55.1790740],
          [-2.4913390, 55.1790400],
          [-2.4912210, 55.1790020],
          [-2.4911030, 55.1789670],
          [-2.4909800, 55.1789330],
          [-2.4908580, 55.1789020],
          [-2.4907430, 55.1788650],
          [-2.4906340, 55.1788110],
          [-2.4905230, 55.1787570],
          [-2.4904130, 55.1787000],
          [-2.4903030, 55.1786320],
          [-2.4902040, 55.1785590],
          [-2.4901270, 55.1784840],
          [-2.4900720, 55.1784070],
          [-2.4900510, 55.1783370],
          [-2.4900500, 55.1782700],
          [-2.4900420, 55.1782010],
          [-2.4900060, 55.1781280],
          [-2.4899690, 55.1780520],
          [-2.4899490, 55.1779760],
          [-2.4899400, 55.1779000],
          [-2.4899190, 55.1778310],
          [-2.4898660, 55.1777550],
          [-2.4898160, 55.1776860],
          [-2.4897970, 55.1776220],
          [-2.4897830, 55.1775610],
          [-2.4897500, 55.1774910],
          [-2.4897090, 55.1774300],
          [-2.4896860, 55.1773700],
          [-2.4896800, 55.1773090],
          [-2.4896930, 55.1772360],
          [-2.4897140, 55.1771830],
          [-2.4897460, 55.1771260],
          [-2.4897740, 55.1770730],
          [-2.4897880, 55.1770190],
          [-2.4897880, 55.1769540],
          [-2.4898010, 55.1768860],
          [-2.4898150, 55.1768160],
          [-2.4898210, 55.1767490],
          [-2.4898230, 55.1766790],
          [-2.4898230, 55.1766140],
          [-2.4898170, 55.1765540],
          [-2.4897960, 55.1764850],
          [-2.4897640, 55.1764240],
          [-2.4896970, 55.1763850],
          [-2.4896080, 55.1763540],
          [-2.4895190, 55.1763200],
          [-2.4894350, 55.1762670],
          [-2.4893670, 55.1762140],
          [-2.4893010, 55.1761680],
          [-2.4892290, 55.1761110],
          [-2.4891630, 55.1760610],
          [-2.4890910, 55.1760040],
          [-2.4890130, 55.1759510],
          [-2.4889410, 55.1759000],
          [-2.4888900, 55.1758620],
          [-2.4888590, 55.1758280],
          [-2.4888550, 55.1757970],
          [-2.4889140, 55.1757860],
          [-2.4889840, 55.1757740],
          [-2.4890630, 55.1757700],
          [-2.4891570, 55.1757740],
          [-2.4892500, 55.1757770],
          [-2.4893440, 55.1757890],
          [-2.4894410, 55.1758000],
          [-2.4895290, 55.1758110],
          [-2.4896140, 55.1758230],
          [-2.4896990, 55.1758420],
          [-2.4897940, 55.1758530],
          [-2.4898890, 55.1758650],
          [-2.4899820, 55.1758690],
          [-2.4900850, 55.1758690],
          [-2.4901900, 55.1758650],
          [-2.4902930, 55.1758610],
          [-2.4904070, 55.1758540],
          [-2.4905300, 55.1758500],
          [-2.4906620, 55.1758390],
          [-2.4907840, 55.1758160],
          [-2.4908900, 55.1757790],
          [-2.4909810, 55.1757370],
          [-2.4910500, 55.1756980],
          [-2.4910220, 55.1756230],
          [-2.4909840, 55.1755770],
          [-2.4909370, 55.1755390],
          [-2.4908750, 55.1755070],
          [-2.4908080, 55.1754850],
          [-2.4907340, 55.1754570],
          [-2.4906700, 55.1754380],
          [-2.4905990, 55.1754160],
          [-2.4905250, 55.1753930],
          [-2.4904470, 55.1753700],
          [-2.4903650, 55.1753470],
          [-2.4902850, 55.1753280],
          [-2.4902080, 55.1753090],
          [-2.4901380, 55.1752860],
          [-2.4900700, 55.1752670],
          [-2.4900050, 55.1752440],
          [-2.4899330, 55.1752250],
          [-2.4898580, 55.1752090],
          [-2.4897750, 55.1751870],
          [-2.4896930, 55.1751600],
          [-2.4896010, 55.1751370],
          [-2.4895120, 55.1751100],
          [-2.4894190, 55.1750800],
          [-2.4893320, 55.1750570],
          [-2.4892400, 55.1750340],
          [-2.4891560, 55.1750070],
          [-2.4890790, 55.1749880],
          [-2.4890010, 55.1749580],
          [-2.4889250, 55.1749270],
          [-2.4888470, 55.1748970],
          [-2.4887470, 55.1748670],
          [-2.4886390, 55.1748180],
          [-2.4885640, 55.1747760],
          [-2.4885170, 55.1747290],
          [-2.4885180, 55.1746680],
          [-2.4885380, 55.1746190],
          [-2.4885600, 55.1745500],
          [-2.4885910, 55.1744860],
          [-2.4886080, 55.1744320],
          [-2.4886380, 55.1743860],
          [-2.4886960, 55.1743590],
          [-2.4887540, 55.1743320],
          [-2.4888190, 55.1743170],
          [-2.4888820, 55.1743050],
          [-2.4889420, 55.1742900],
          [-2.4890010, 55.1742750],
          [-2.4890640, 55.1742670],
          [-2.4891220, 55.1742590],
          [-2.4891770, 55.1742520],
          [-2.4892320, 55.1742440],
          [-2.4892880, 55.1742400],
          [-2.4893470, 55.1742330],
          [-2.4894030, 55.1742290],
          [-2.4894610, 55.1742250],
          [-2.4895160, 55.1742250],
          [-2.4895730, 55.1742210],
          [-2.4896330, 55.1742210],
          [-2.4896900, 55.1742210],
          [-2.4897520, 55.1742210],
          [-2.4898140, 55.1742210],
          [-2.4898730, 55.1742210],
          [-2.4899330, 55.1742210],
          [-2.4899940, 55.1742170],
          [-2.4900520, 55.1742210],
          [-2.4901120, 55.1742210],
          [-2.4901730, 55.1742170],
          [-2.4902320, 55.1742130],
          [-2.4902930, 55.1742100],
          [-2.4903570, 55.1742100],
          [-2.4904200, 55.1742100],
          [-2.4904830, 55.1742060],
          [-2.4905490, 55.1742060],
          [-2.4906150, 55.1742090],
          [-2.4906830, 55.1742130],
          [-2.4907430, 55.1742130],
          [-2.4908100, 55.1742130],
          [-2.4908730, 55.1742100],
          [-2.4909420, 55.1742100],
          [-2.4910080, 55.1742100],
          [-2.4910680, 55.1741980],
          [-2.4911310, 55.1741870],
          [-2.4911950, 55.1741790],
          [-2.4912570, 55.1741680],
          [-2.4913190, 55.1741570],
          [-2.4913880, 55.1741450],
          [-2.4914540, 55.1741340],
          [-2.4915230, 55.1741220],
          [-2.4915920, 55.1741110],
          [-2.4916600, 55.1741000],
          [-2.4917370, 55.1740920],
          [-2.4918070, 55.1740800],
          [-2.4918730, 55.1740690],
          [-2.4919430, 55.1740570],
          [-2.4920080, 55.1740460],
          [-2.4920720, 55.1740380],
          [-2.4921400, 55.1740270],
          [-2.4922070, 55.1740160],
          [-2.4922790, 55.1740040],
          [-2.4923550, 55.1739960],
          [-2.4924270, 55.1739890],
          [-2.4925040, 55.1739810],
          [-2.4925780, 55.1739730],
          [-2.4926550, 55.1739660],
          [-2.4927290, 55.1739620],
          [-2.4928040, 55.1739580],
          [-2.4928760, 55.1739500],
          [-2.4929460, 55.1739430],
          [-2.4930180, 55.1739350],
          [-2.4930920, 55.1739350],
          [-2.4931730, 55.1739280],
          [-2.4932630, 55.1739240],
          [-2.4933640, 55.1739120],
          [-2.4934770, 55.1738970],
          [-2.4935910, 55.1738820],
          [-2.4937060, 55.1738670],
          [-2.4938140, 55.1738440],
          [-2.4939150, 55.1738260],
          [-2.4940210, 55.1738030],
          [-2.4941320, 55.1737870],
          [-2.4942420, 55.1737720],
          [-2.4943580, 55.1737530],
          [-2.4944750, 55.1737340],
          [-2.4945870, 55.1737140],
          [-2.4946950, 55.1736950],
          [-2.4948010, 55.1736800],
          [-2.4949100, 55.1736570],
          [-2.4950190, 55.1736310],
          [-2.4951270, 55.1736050],
          [-2.4952350, 55.1735740],
          [-2.4953430, 55.1735280],
          [-2.4954530, 55.1735010],
          [-2.4955610, 55.1734780],
          [-2.4956770, 55.1734660],
          [-2.4957940, 55.1734510],
          [-2.4959030, 55.1734360],
          [-2.4960250, 55.1734210],
          [-2.4961340, 55.1734060],
          [-2.4962440, 55.1733930],
          [-2.4963460, 55.1733860],
          [-2.4964500, 55.1733780],
          [-2.4965490, 55.1733670],
          [-2.4966450, 55.1733330],
          [-2.4967390, 55.1732920],
          [-2.4968340, 55.1732430],
          [-2.4969410, 55.1731960],
          [-2.4970450, 55.1731460],
          [-2.4971440, 55.1730990],
          [-2.4972600, 55.1730510],
          [-2.4973730, 55.1730060],
          [-2.4974920, 55.1729770],
          [-2.4976250, 55.1729450],
          [-2.4977540, 55.1729210],
          [-2.4978820, 55.1729020],
          [-2.4980160, 55.1728720],
          [-2.4981520, 55.1728490],
          [-2.4982970, 55.1728190],
          [-2.4984460, 55.1727990],
          [-2.4986000, 55.1727760],
          [-2.4987580, 55.1727500],
          [-2.4989080, 55.1727230],
          [-2.4990400, 55.1726900],
          [-2.4991840, 55.1726620],
          [-2.4993320, 55.1726280],
          [-2.4994540, 55.1726160],
          [-2.4995700, 55.1726110],
          [-2.4996740, 55.1726150],
          [-2.4997740, 55.1726190],
          [-2.4998590, 55.1726300],
          [-2.4999350, 55.1726480],
          [-2.5000090, 55.1726680],
          [-2.5000790, 55.1726950],
          [-2.5001400, 55.1727100],
          [-2.5002130, 55.1727210],
          [-2.5002890, 55.1727250],
          [-2.5003690, 55.1727220],
          [-2.5004530, 55.1727150],
          [-2.5005330, 55.1727110],
          [-2.5006210, 55.1727070],
          [-2.5007140, 55.1727100],
          [-2.5008020, 55.1727180],
          [-2.5008760, 55.1727220],
          [-2.5009560, 55.1727250],
          [-2.5010280, 55.1727260],
          [-2.5011000, 55.1727220],
          [-2.5011720, 55.1727180],
          [-2.5012490, 55.1727040],
          [-2.5013450, 55.1726840],
          [-2.5014390, 55.1726620],
          [-2.5015400, 55.1726430],
          [-2.5016520, 55.1726240],
          [-2.5017770, 55.1726040],
          [-2.5018980, 55.1725960],
          [-2.5020230, 55.1725700],
          [-2.5021470, 55.1725360],
          [-2.5022610, 55.1725020],
          [-2.5023650, 55.1724720],
          [-2.5024770, 55.1724370],
          [-2.5025960, 55.1723960],
          [-2.5027380, 55.1723500],
          [-2.5028700, 55.1723080],
          [-2.5029950, 55.1722650],
          [-2.5031120, 55.1722280],
          [-2.5032390, 55.1721930],
          [-2.5033860, 55.1721540],
          [-2.5035260, 55.1721320],
          [-2.5036950, 55.1721120],
          [-2.5038390, 55.1720860],
          [-2.5039740, 55.1720550],
          [-2.5041170, 55.1720100],
          [-2.5042690, 55.1719750],
          [-2.5044240, 55.1719450],
          [-2.5045770, 55.1719180],
          [-2.5047750, 55.1719280],
          [-2.5049750, 55.1719320],
          [-2.5051490, 55.1719430],
          [-2.5052840, 55.1719650],
          [-2.5054170, 55.1719960],
          [-2.5055420, 55.1720300],
          [-2.5056580, 55.1720610],
          [-2.5057720, 55.1720910],
          [-2.5058800, 55.1721180],
          [-2.5059700, 55.1721520],
          [-2.5060450, 55.1721830],
          [-2.5060980, 55.1722130],
          [-2.5061650, 55.1722520],
          [-2.5062360, 55.1722850],
          [-2.5062990, 55.1723200],
          [-2.5063490, 55.1723360],
          [-2.5064050, 55.1723550],
          [-2.5064720, 55.1723670],
          [-2.5065490, 55.1723780],
          [-2.5066160, 55.1723890],
          [-2.5066790, 55.1724010],
          [-2.5067410, 55.1724160],
          [-2.5068150, 55.1724200],
          [-2.5068870, 55.1724240],
          [-2.5069600, 55.1724240],
          [-2.5070330, 55.1724280],
          [-2.5071130, 55.1724320],
          [-2.5072040, 55.1724250],
          [-2.5073130, 55.1724170],
          [-2.5074300, 55.1724060],
          [-2.5075590, 55.1723940],
          [-2.5076950, 55.1723790],
          [-2.5078160, 55.1723530],
          [-2.5079170, 55.1723270],
          [-2.5080210, 55.1722960],
          [-2.5080940, 55.1722690],
          [-2.5081600, 55.1722430],
          [-2.5082310, 55.1722200],
          [-2.5083040, 55.1722000],
          [-2.5083900, 55.1721810],
          [-2.5084840, 55.1721660],
          [-2.5085800, 55.1721470],
          [-2.5086500, 55.1721240],
          [-2.5087140, 55.1721010],
          [-2.5087570, 55.1720780],
          [-2.5087950, 55.1720550],
          [-2.5088270, 55.1720320],
          [-2.5088600, 55.1720210],
          [-2.5088920, 55.1720060],
          [-2.5089190, 55.1719970],
          [-2.5089310, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089320, 55.1719930],
          [-2.5089730, 55.1720160],
          [-2.5089520, 55.1720240],
          [-2.5089330, 55.1720270],
          [-2.5089020, 55.1720160],
          [-2.5088820, 55.1720010],
          [-2.5088700, 55.1719790],
          [-2.5088680, 55.1719520],
          [-2.5088670, 55.1719180],
          [-2.5088680, 55.1718870],
          [-2.5088670, 55.1718460],
          [-2.5088720, 55.1717990],
          [-2.5088830, 55.1717650],
          [-2.5088960, 55.1717230],
          [-2.5088970, 55.1716700],
          [-2.5089060, 55.1716130],
          [-2.5089310, 55.1715550],
          [-2.5090090, 55.1714680],
          [-2.5090900, 55.1714070],
          [-2.5091820, 55.1713270],
          [-2.5092650, 55.1712470],
          [-2.5093650, 55.1712040],
          [-2.5094770, 55.1711550],
          [-2.5095900, 55.1711240],
          [-2.5097010, 55.1710900],
          [-2.5097960, 55.1710570],
          [-2.5098890, 55.1710320],
          [-2.5099730, 55.1710170],
          [-2.5100540, 55.1710020],
          [-2.5101250, 55.1709830],
          [-2.5101780, 55.1709670],
          [-2.5102090, 55.1709520],
          [-2.5102300, 55.1709290],
          [-2.5102280, 55.1709140],
          [-2.5102280, 55.1709020],
          [-2.5102280, 55.1709020],
          [-2.5102280, 55.1709020],
          [-2.5102280, 55.1709020],
          [-2.5102280, 55.1709020],
          [-2.5102280, 55.1709020],
          [-2.5102280, 55.1709020],
          [-2.5102280, 55.1709020],
          [-2.5102280, 55.1709020],
          [-2.5102610, 55.1708840],
          [-2.5102880, 55.1708790],
          [-2.5103210, 55.1708720],
          [-2.5103590, 55.1708680],
          [-2.5104060, 55.1708570],
          [-2.5104640, 55.1708410],
          [-2.5105140, 55.1708220],
          [-2.5105730, 55.1707920],
          [-2.5106320, 55.1707620],
          [-2.5107020, 55.1707200],
          [-2.5107760, 55.1706820],
          [-2.5108720, 55.1706320],
          [-2.5109560, 55.1705830],
          [-2.5110290, 55.1705480],
          [-2.5110720, 55.1705250],
          [-2.5111050, 55.1705120],
          [-2.5111090, 55.1705100],
          [-2.5111140, 55.1705090],
          [-2.5111180, 55.1705070],
          [-2.5111220, 55.1705060],
          [-2.5111260, 55.1705040],
          [-2.5111300, 55.1705030],
          [-2.5111350, 55.1705010],
          [-2.5111390, 55.1705000],
          [-2.5111430, 55.1704980],
          [-2.5111470, 55.1704960],
          [-2.5111510, 55.1704950],
          [-2.5111560, 55.1704930],
          [-2.5111600, 55.1704920],
          [-2.5111640, 55.1704900],
          [-2.5111680, 55.1704890],
          [-2.5111720, 55.1704870],
          [-2.5111770, 55.1704860],
          [-2.5111810, 55.1704840],
          [-2.5111850, 55.1704830],
          [-2.5111890, 55.1704810],
          [-2.5111930, 55.1704790],
          [-2.5111980, 55.1704780],
          [-2.5112020, 55.1704760],
          [-2.5112060, 55.1704750],
          [-2.5112100, 55.1704730],
          [-2.5112140, 55.1704720],
          [-2.5112180, 55.1704700],
          [-2.5112230, 55.1704690],
          [-2.5112270, 55.1704670],
          [-2.5112310, 55.1704660],
          [-2.5112350, 55.1704640],
          [-2.5112810, 55.1704520],
          [-2.5113370, 55.1704410],
          [-2.5114100, 55.1704290],
          [-2.5114980, 55.1704100],
          [-2.5115980, 55.1703880],
          [-2.5117090, 55.1703650],
          [-2.5118220, 55.1703460],
          [-2.5119420, 55.1703190],
          [-2.5120590, 55.1703040],
          [-2.5121770, 55.1702880],
          [-2.5122970, 55.1702770],
          [-2.5124140, 55.1702730],
          [-2.5125310, 55.1702650],
          [-2.5126440, 55.1702580],
          [-2.5127470, 55.1702500],
          [-2.5128410, 55.1702390],
          [-2.5129390, 55.1702270],
          [-2.5130460, 55.1702120],
          [-2.5131550, 55.1701860],
          [-2.5132430, 55.1701630],
          [-2.5133350, 55.1701400],
          [-2.5133990, 55.1701180],
          [-2.5134680, 55.1701020],
          [-2.5135370, 55.1700900],
          [-2.5136040, 55.1700750],
          [-2.5136720, 55.1700600],
          [-2.5137400, 55.1700410],
          [-2.5138100, 55.1700100],
          [-2.5138770, 55.1699840],
          [-2.5139500, 55.1699530],
          [-2.5140180, 55.1699230],
          [-2.5140790, 55.1698840],
          [-2.5141350, 55.1698540],
          [-2.5141780, 55.1698280],
          [-2.5142210, 55.1697900],
          [-2.5142760, 55.1697620],
          [-2.5143160, 55.1697240],
          [-2.5143520, 55.1696900],
          [-2.5143840, 55.1696560],
          [-2.5144100, 55.1696250],
          [-2.5144430, 55.1695900],
          [-2.5144810, 55.1695450],
          [-2.5145190, 55.1695100],
          [-2.5145680, 55.1694830],
          [-2.5146140, 55.1694610],
          [-2.5146730, 55.1694450],
          [-2.5147370, 55.1694220],
          [-2.5147990, 55.1694080],
          [-2.5148630, 55.1693840],
          [-2.5149130, 55.1693650],
          [-2.5149610, 55.1693500],
          [-2.5150090, 55.1693350],
          [-2.5150590, 55.1693230],
          [-2.5151100, 55.1693160],
          [-2.5151730, 55.1693080],
          [-2.5152400, 55.1693000],
          [-2.5153120, 55.1692850],
          [-2.5153810, 55.1692810],
          [-2.5154490, 55.1692810],
          [-2.5155150, 55.1692770],
          [-2.5155710, 55.1692810],
          [-2.5156310, 55.1692890],
          [-2.5156880, 55.1692890],
          [-2.5157520, 55.1692850],
          [-2.5158110, 55.1692850],
          [-2.5158640, 55.1692920],
          [-2.5159210, 55.1692890],
          [-2.5159840, 55.1692740],
          [-2.5160510, 55.1692580],
          [-2.5161240, 55.1692510],
          [-2.5162060, 55.1692430],
          [-2.5162900, 55.1692320],
          [-2.5163690, 55.1692310],
          [-2.5164440, 55.1692350],
          [-2.5165180, 55.1692390],
          [-2.5165930, 55.1692460],
          [-2.5166670, 55.1692500],
          [-2.5167490, 55.1692580],
          [-2.5168230, 55.1692660],
          [-2.5169070, 55.1692660],
          [-2.5169790, 55.1692730],
          [-2.5170540, 55.1692810],
          [-2.5171270, 55.1692960],
          [-2.5172120, 55.1693000],
          [-2.5172860, 55.1693070],
          [-2.5173600, 55.1693190],
          [-2.5174290, 55.1693300],
          [-2.5175060, 55.1693380],
          [-2.5175770, 55.1693530],
          [-2.5176460, 55.1693650],
          [-2.5177270, 55.1693650],
          [-2.5178030, 55.1693760],
          [-2.5178780, 55.1693760],
          [-2.5179440, 55.1693800],
          [-2.5180050, 55.1693840],
          [-2.5180680, 55.1693910],
          [-2.5181340, 55.1693990],
          [-2.5182040, 55.1694070],
          [-2.5182700, 55.1694140],
          [-2.5183370, 55.1694300],
          [-2.5184080, 55.1694340],
          [-2.5184740, 55.1694450],
          [-2.5185370, 55.1694560],
          [-2.5185910, 55.1694680],
          [-2.5186390, 55.1694750],
          [-2.5186870, 55.1694830],
          [-2.5187420, 55.1694900],
          [-2.5188100, 55.1695020],
          [-2.5188810, 55.1695170],
          [-2.5189530, 55.1695290],
          [-2.5190140, 55.1695400],
          [-2.5190650, 55.1695480],
          [-2.5191210, 55.1695550],
          [-2.5191740, 55.1695670],
          [-2.5192360, 55.1695780],
          [-2.5193030, 55.1695860],
          [-2.5193670, 55.1695970],
          [-2.5194400, 55.1696230],
          [-2.5195200, 55.1696470],
          [-2.5195900, 55.1696700],
          [-2.5196600, 55.1696890],
          [-2.5197360, 55.1697040],
          [-2.5198100, 55.1697270],
          [-2.5198870, 55.1697460],
          [-2.5199650, 55.1697610],
          [-2.5200480, 55.1697730],
          [-2.5201270, 55.1697810],
          [-2.5202090, 55.1697880],
          [-2.5202960, 55.1697880],
          [-2.5203920, 55.1697920],
          [-2.5204850, 55.1697960],
          [-2.5205820, 55.1698000],
          [-2.5206720, 55.1698000],
          [-2.5207600, 55.1698000],
          [-2.5208410, 55.1698000],
          [-2.5209200, 55.1698070],
          [-2.5210050, 55.1698150],
          [-2.5210890, 55.1698260],
          [-2.5211690, 55.1698380],
          [-2.5212450, 55.1698520],
          [-2.5213240, 55.1698680],
          [-2.5213990, 55.1698760],
          [-2.5214660, 55.1698840],
          [-2.5215200, 55.1698950],
          [-2.5215800, 55.1699030],
          [-2.5216420, 55.1699140],
          [-2.5217140, 55.1699290],
          [-2.5217790, 55.1699440],
          [-2.5218440, 55.1699560],
          [-2.5219110, 55.1699640],
          [-2.5219670, 55.1699750],
          [-2.5220220, 55.1699830],
          [-2.5220790, 55.1699940],
          [-2.5221440, 55.1700090],
          [-2.5222170, 55.1700250],
          [-2.5222830, 55.1700430],
          [-2.5223410, 55.1700630],
          [-2.5223960, 55.1700710],
          [-2.5224490, 55.1700860],
          [-2.5225000, 55.1701010],
          [-2.5225520, 55.1701120],
          [-2.5226020, 55.1701280],
          [-2.5226560, 55.1701430],
          [-2.5227130, 55.1701620],
          [-2.5227770, 55.1701730],
          [-2.5228410, 55.1701850],
          [-2.5229090, 55.1702000],
          [-2.5229820, 55.1702150],
          [-2.5230590, 55.1702340],
          [-2.5231390, 55.1702540],
          [-2.5232190, 55.1702720],
          [-2.5233070, 55.1702950],
          [-2.5233950, 55.1703180],
          [-2.5234930, 55.1703370],
          [-2.5236000, 55.1703530],
          [-2.5237110, 55.1703680],
          [-2.5238260, 55.1703830],
          [-2.5239290, 55.1703830],
          [-2.5240180, 55.1703830],
          [-2.5240910, 55.1703680],
          [-2.5241800, 55.1703420],
          [-2.5242820, 55.1703150],
          [-2.5243800, 55.1702770],
          [-2.5244920, 55.1702350],
          [-2.5246220, 55.1701720],
          [-2.5247450, 55.1701090],
          [-2.5248430, 55.1700520],
          [-2.5249260, 55.1699990],
          [-2.5249840, 55.1699610],
          [-2.5250290, 55.1699340],
          [-2.5250480, 55.1699070],
          [-2.5250370, 55.1698880],
          [-2.5250090, 55.1698690],
          [-2.5249560, 55.1698500],
          [-2.5249030, 55.1698310],
          [-2.5248870, 55.1698010],
          [-2.5249080, 55.1697630],
          [-2.5249720, 55.1697360],
          [-2.5250390, 55.1697090],
          [-2.5251070, 55.1696900],
          [-2.5251800, 55.1696820],
          [-2.5252550, 55.1696740],
          [-2.5253300, 55.1696700],
          [-2.5254020, 55.1696590],
          [-2.5254700, 55.1696480],
          [-2.5255380, 55.1696320],
          [-2.5256040, 55.1696170],
          [-2.5256560, 55.1696020],
          [-2.5257040, 55.1695900],
          [-2.5257390, 55.1695750],
          [-2.5257270, 55.1695640],
          [-2.5256940, 55.1695520],
          [-2.5256560, 55.1695520],
          [-2.5256200, 55.1695560],
          [-2.5255990, 55.1695520],
          [-2.5255820, 55.1695370],
          [-2.5255910, 55.1695220],
          [-2.5256340, 55.1695030],
          [-2.5256700, 55.1694870],
          [-2.5256810, 55.1694650],
          [-2.5256830, 55.1694450],
          [-2.5256640, 55.1694260],
          [-2.5256270, 55.1694150],
          [-2.5255880, 55.1694000],
          [-2.5255630, 55.1693850],
          [-2.5255490, 55.1693540],
          [-2.5255500, 55.1693160],
          [-2.5255550, 55.1692820],
          [-2.5255470, 55.1692080],
          [-2.5255370, 55.1691530],
          [-2.5255460, 55.1691030],
          [-2.5255740, 55.1690570],
          [-2.5256140, 55.1690260],
          [-2.5256410, 55.1689840],
          [-2.5257020, 55.1689570],
          [-2.5257800, 55.1689340],
          [-2.5258560, 55.1689010],
          [-2.5259350, 55.1688700],
          [-2.5260090, 55.1688470],
          [-2.5260790, 55.1688200],
          [-2.5261470, 55.1687970],
          [-2.5262120, 55.1687850],
          [-2.5262810, 55.1687700],
          [-2.5263370, 55.1687590],
          [-2.5263940, 55.1687510],
          [-2.5264520, 55.1687510],
          [-2.5265060, 55.1687470],
          [-2.5265610, 55.1687430],
          [-2.5266090, 55.1687320],
          [-2.5266620, 55.1687240],
          [-2.5267250, 55.1687200],
          [-2.5267870, 55.1687110],
          [-2.5268540, 55.1687060],
          [-2.5269420, 55.1686940],
          [-2.5270010, 55.1686820],
          [-2.5270820, 55.1686600],
          [-2.5271420, 55.1686480],
          [-2.5272450, 55.1686220],
          [-2.5273590, 55.1685870],
          [-2.5274570, 55.1685570],
          [-2.5275420, 55.1685260],
          [-2.5276180, 55.1684920],
          [-2.5277190, 55.1684610],
          [-2.5278310, 55.1684310],
          [-2.5278960, 55.1683890],
          [-2.5279560, 55.1683540],
          [-2.5279800, 55.1682750],
          [-2.5279800, 55.1681800],
          [-2.5279740, 55.1681030],
          [-2.5279800, 55.1680380],
          [-2.5279880, 55.1679890],
          [-2.5280060, 55.1679350],
          [-2.5280670, 55.1678860],
          [-2.5281650, 55.1678400],
          [-2.5282370, 55.1678050],
          [-2.5282980, 55.1677640],
          [-2.5283710, 55.1677250],
          [-2.5284460, 55.1676980],
          [-2.5285280, 55.1676190],
          [-2.5286190, 55.1675950],
          [-2.5286940, 55.1675690],
          [-2.5287590, 55.1675340],
          [-2.5288220, 55.1674930],
          [-2.5288820, 55.1674510],
          [-2.5289420, 55.1674090],
          [-2.5290050, 55.1673630],
          [-2.5290910, 55.1673060],
          [-2.5291560, 55.1672670],
          [-2.5292200, 55.1672290],
          [-2.5292860, 55.1671950],
          [-2.5293580, 55.1671600],
          [-2.5294290, 55.1671220],
          [-2.5295110, 55.1670920],
          [-2.5295900, 55.1670580],
          [-2.5296500, 55.1670200],
          [-2.5297000, 55.1669780],
          [-2.5297600, 55.1669360],
          [-2.5298120, 55.1668830],
          [-2.5298580, 55.1668400],
          [-2.5298940, 55.1667950],
          [-2.5299310, 55.1667530],
          [-2.5299710, 55.1667110],
          [-2.5300230, 55.1666650],
          [-2.5300690, 55.1666190],
          [-2.5301040, 55.1665810],
          [-2.5301440, 55.1665350],
          [-2.5302030, 55.1664850],
          [-2.5302550, 55.1664440],
          [-2.5303140, 55.1664130],
          [-2.5303680, 55.1663820],
          [-2.5304230, 55.1663590],
          [-2.5304820, 55.1663330],
          [-2.5305360, 55.1662950],
          [-2.5305930, 55.1662640],
          [-2.5306710, 55.1662180],
          [-2.5307390, 55.1661730],
          [-2.5307820, 55.1661270],
          [-2.5308100, 55.1660780],
          [-2.5308280, 55.1660240],
          [-2.5308350, 55.1659600],
          [-2.5308380, 55.1659020],
          [-2.5308370, 55.1658450],
          [-2.5308320, 55.1657880],
          [-2.5308370, 55.1657300],
          [-2.5308400, 55.1656730],
          [-2.5308480, 55.1656120],
          [-2.5308660, 55.1655590],
          [-2.5308780, 55.1655090],
          [-2.5308850, 55.1654520],
          [-2.5308670, 55.1653980],
          [-2.5308250, 55.1653600],
          [-2.5307680, 55.1653290],
          [-2.5307020, 55.1653070],
          [-2.5306320, 55.1652880],
          [-2.5305510, 55.1652760],
          [-2.5304650, 55.1652530],
          [-2.5303760, 55.1652380],
          [-2.5302860, 55.1652260],
          [-2.5301900, 55.1652110],
          [-2.5300980, 55.1651990],
          [-2.5300010, 55.1651920],
          [-2.5299090, 55.1651800],
          [-2.5298170, 55.1651690],
          [-2.5297210, 55.1651760],
          [-2.5296380, 55.1652030],
          [-2.5295640, 55.1652410],
          [-2.5294920, 55.1652830],
          [-2.5294160, 55.1653250],
          [-2.5293340, 55.1653630],
          [-2.5292400, 55.1653900],
          [-2.5291420, 55.1653900],
          [-2.5290400, 55.1653790],
          [-2.5289400, 55.1653600],
          [-2.5288360, 55.1653410],
          [-2.5287340, 55.1653250],
          [-2.5286290, 55.1653180],
          [-2.5285240, 55.1653100],
          [-2.5284230, 55.1653100],
          [-2.5283200, 55.1653060],
          [-2.5282250, 55.1653020],
          [-2.5281390, 55.1653020],
          [-2.5280630, 55.1653020],
          [-2.5279880, 55.1653020],
          [-2.5279200, 55.1653020],
          [-2.5278640, 55.1652980],
          [-2.5278140, 55.1652980],
          [-2.5277590, 55.1652910],
          [-2.5277070, 55.1652910],
          [-2.5276580, 55.1652940],
          [-2.5276110, 55.1652950],
          [-2.5275620, 55.1652950],
          [-2.5275110, 55.1652950],
          [-2.5274630, 55.1652950],
          [-2.5274090, 55.1652910],
          [-2.5273570, 55.1652870],
          [-2.5273040, 55.1652830],
          [-2.5272480, 55.1652830],
          [-2.5271880, 55.1652830],
          [-2.5271260, 55.1652830],
          [-2.5270610, 55.1652830],
          [-2.5269910, 55.1652830],
          [-2.5269230, 55.1652830],
          [-2.5268540, 55.1652830],
          [-2.5267800, 55.1652830],
          [-2.5267070, 55.1652830],
          [-2.5266330, 55.1652830],
          [-2.5265640, 55.1652790],
          [-2.5264900, 55.1652790],
          [-2.5264240, 55.1652760],
          [-2.5263560, 55.1652760],
          [-2.5262930, 55.1652720],
          [-2.5262310, 55.1652680],
          [-2.5261680, 55.1652570],
          [-2.5261080, 55.1652490],
          [-2.5260520, 55.1652420],
          [-2.5260000, 55.1652300],
          [-2.5259510, 55.1652190],
          [-2.5259060, 55.1652040],
          [-2.5258610, 55.1651880],
          [-2.5258170, 55.1651730],
          [-2.5257760, 55.1651610],
          [-2.5257320, 55.1651540],
          [-2.5256870, 55.1651420],
          [-2.5256440, 55.1651310],
          [-2.5256020, 55.1651230],
          [-2.5255590, 55.1651120],
          [-2.5255110, 55.1651040],
          [-2.5254640, 55.1650960],
          [-2.5254180, 55.1650890],
          [-2.5253720, 55.1650810],
          [-2.5253260, 55.1650770],
          [-2.5252820, 55.1650730],
          [-2.5252390, 55.1650700],
          [-2.5251960, 55.1650660],
          [-2.5251520, 55.1650620],
          [-2.5251060, 55.1650580],
          [-2.5250580, 55.1650470],
          [-2.5250100, 55.1650390],
          [-2.5249640, 55.1650320],
          [-2.5249190, 55.1650200],
          [-2.5248770, 55.1650090],
          [-2.5248390, 55.1649970],
          [-2.5248010, 55.1649820],
          [-2.5247610, 55.1649670],
          [-2.5247220, 55.1649440],
          [-2.5246850, 55.1649220],
          [-2.5246520, 55.1648940],
          [-2.5246290, 55.1648640],
          [-2.5246060, 55.1648340],
          [-2.5245800, 55.1647950],
          [-2.5245510, 55.1647650],
          [-2.5245140, 55.1647420],
          [-2.5244720, 55.1647260],
          [-2.5244270, 55.1647150],
          [-2.5243810, 55.1647040],
          [-2.5243370, 55.1646960],
          [-2.5242960, 55.1646920],
          [-2.5242480, 55.1646840],
          [-2.5242020, 55.1646770],
          [-2.5241530, 55.1646730],
          [-2.5241030, 55.1646690],
          [-2.5240570, 55.1646650],
          [-2.5240130, 55.1646650],
          [-2.5239670, 55.1646620],
          [-2.5239220, 55.1646610],
          [-2.5238760, 55.1646610],
          [-2.5238270, 55.1646580],
          [-2.5237790, 55.1646540],
          [-2.5237330, 55.1646500],
          [-2.5236810, 55.1646500],
          [-2.5236290, 55.1646500],
          [-2.5235810, 55.1646540],
          [-2.5235310, 55.1646500],
          [-2.5234820, 55.1646500],
          [-2.5234390, 55.1646420],
          [-2.5233940, 55.1646390],
          [-2.5233470, 55.1646350],
          [-2.5233010, 55.1646310],
          [-2.5232550, 55.1646270],
          [-2.5232090, 55.1646230],
          [-2.5231720, 55.1646230],
          [-2.5231280, 55.1646230],
          [-2.5230820, 55.1646190],
          [-2.5230440, 55.1646120],
          [-2.5230070, 55.1646080],
          [-2.5229670, 55.1646080],
          [-2.5229240, 55.1645970],
          [-2.5228880, 55.1645850],
          [-2.5228440, 55.1645590],
          [-2.5227960, 55.1645320],
          [-2.5227490, 55.1645050],
          [-2.5227120, 55.1644790],
          [-2.5226830, 55.1644530],
          [-2.5226640, 55.1644370],
          [-2.5226370, 55.1644140],
          [-2.5226100, 55.1643950],
          [-2.5225940, 55.1643760],
          [-2.5225800, 55.1643570],
          [-2.5225680, 55.1643340],
          [-2.5225600, 55.1643180],
          [-2.5225500, 55.1642990],
          [-2.5225440, 55.1642800],
          [-2.5225320, 55.1642570],
          [-2.5225280, 55.1642420],
          [-2.5225250, 55.1642190],
          [-2.5225150, 55.1641930],
          [-2.5225000, 55.1641700],
          [-2.5224840, 55.1641470],
          [-2.5224900, 55.1641280],
          [-2.5224930, 55.1641010],
          [-2.5224970, 55.1640860],
          [-2.5224970, 55.1640630],
          [-2.5225130, 55.1640480],
          [-2.5225170, 55.1640250],
          [-2.5225250, 55.1640060],
          [-2.5225270, 55.1639870],
          [-2.5225230, 55.1639520],
          [-2.5225280, 55.1639220],
          [-2.5225380, 55.1638950],
          [-2.5225480, 55.1638610],
          [-2.5225630, 55.1638230],
          [-2.5225780, 55.1637820],
          [-2.5225960, 55.1637540],
          [-2.5226100, 55.1637270],
          [-2.5226300, 55.1637040],
          [-2.5226460, 55.1636740],
          [-2.5226760, 55.1636400],
          [-2.5226960, 55.1636130],
          [-2.5226920, 55.1635860],
          [-2.5226690, 55.1635560],
          [-2.5226290, 55.1635290],
          [-2.5225790, 55.1634950],
          [-2.5225200, 55.1634750],
          [-2.5224450, 55.1634640],
          [-2.5223700, 55.1634560],
          [-2.5222900, 55.1634480],
          [-2.5222210, 55.1634520],
          [-2.5221350, 55.1634520],
          [-2.5220600, 55.1634590],
          [-2.5219840, 55.1634750],
          [-2.5219340, 55.1635010],
          [-2.5218940, 55.1635350],
          [-2.5218610, 55.1635740],
          [-2.5218320, 55.1636160],
          [-2.5218070, 55.1636570],
          [-2.5217870, 55.1636990],
          [-2.5217670, 55.1637410],
          [-2.5217450, 55.1637800],
          [-2.5217090, 55.1638180],
          [-2.5216680, 55.1638550],
          [-2.5216190, 55.1638940],
          [-2.5215590, 55.1639360],
          [-2.5215060, 55.1639780],
          [-2.5214510, 55.1640240],
          [-2.5213940, 55.1640690],
          [-2.5213340, 55.1641080],
          [-2.5212740, 55.1641460],
          [-2.5212100, 55.1641800],
          [-2.5211430, 55.1642150],
          [-2.5210710, 55.1642410],
          [-2.5209790, 55.1642670],
          [-2.5208830, 55.1642990],
          [-2.5207930, 55.1643250],
          [-2.5206850, 55.1643440],
          [-2.5205820, 55.1643670],
          [-2.5204670, 55.1643830],
          [-2.5203540, 55.1643980],
          [-2.5202440, 55.1644130],
          [-2.5201270, 55.1644170],
          [-2.5200140, 55.1644170],
          [-2.5198870, 55.1644060],
          [-2.5197600, 55.1643950],
          [-2.5196370, 55.1643830],
          [-2.5195140, 55.1643760],
          [-2.5193830, 55.1643680],
          [-2.5192600, 55.1643560],
          [-2.5191420, 55.1643490],
          [-2.5190200, 55.1643410],
          [-2.5189080, 55.1643330],
          [-2.5187970, 55.1643220],
          [-2.5186840, 55.1643140],
          [-2.5185720, 55.1643070],
          [-2.5184680, 55.1643070],
          [-2.5183720, 55.1642990],
          [-2.5182770, 55.1642950],
          [-2.5181840, 55.1642920],
          [-2.5180940, 55.1642910],
          [-2.5180010, 55.1642880],
          [-2.5179150, 55.1642840],
          [-2.5178260, 55.1642840],
          [-2.5177350, 55.1642800],
          [-2.5176450, 55.1642760],
          [-2.5175550, 55.1642650],
          [-2.5174620, 55.1642500],
          [-2.5173740, 55.1642310],
          [-2.5172920, 55.1642230],
          [-2.5172110, 55.1642010],
          [-2.5171350, 55.1641810],
          [-2.5170700, 55.1641620],
          [-2.5170020, 55.1641510],
          [-2.5169360, 55.1641320],
          [-2.5168730, 55.1641160],
          [-2.5168060, 55.1640970],
          [-2.5167390, 55.1640780],
          [-2.5166710, 55.1640520],
          [-2.5166100, 55.1640210],
          [-2.5165530, 55.1639940],
          [-2.5164980, 55.1639610],
          [-2.5164380, 55.1639180],
          [-2.5163920, 55.1638840],
          [-2.5163510, 55.1638500],
          [-2.5163090, 55.1638190],
          [-2.5162640, 55.1637810],
          [-2.5162190, 55.1637430],
          [-2.5161750, 55.1637090],
          [-2.5161230, 55.1636710],
          [-2.5160760, 55.1636400],
          [-2.5160340, 55.1636130],
          [-2.5159880, 55.1635860],
          [-2.5159440, 55.1635630],
          [-2.5159070, 55.1635400],
          [-2.5158630, 55.1635140],
          [-2.5158230, 55.1634830],
          [-2.5157830, 55.1634490],
          [-2.5157290, 55.1634040],
          [-2.5156870, 55.1633660],
          [-2.5156560, 55.1633310],
          [-2.5156270, 55.1632930],
          [-2.5156050, 55.1632580],
          [-2.5155860, 55.1632250],
          [-2.5155700, 55.1631860],
          [-2.5155670, 55.1631550],
          [-2.5155680, 55.1631250],
          [-2.5155780, 55.1630980],
          [-2.5155960, 55.1630680],
          [-2.5156080, 55.1630330],
          [-2.5156120, 55.1629990],
          [-2.5156020, 55.1629610],
          [-2.5155750, 55.1629190],
          [-2.5155340, 55.1628770],
          [-2.5154890, 55.1628420],
          [-2.5154410, 55.1628080],
          [-2.5153890, 55.1627740],
          [-2.5153290, 55.1627510],
          [-2.5152660, 55.1627280],
          [-2.5152030, 55.1627090],
          [-2.5151390, 55.1626820],
          [-2.5150790, 55.1626590],
          [-2.5150240, 55.1626360],
          [-2.5149680, 55.1626170],
          [-2.5149080, 55.1625940],
          [-2.5148450, 55.1625720],
          [-2.5147800, 55.1625480],
          [-2.5147050, 55.1625260],
          [-2.5146180, 55.1625030],
          [-2.5145130, 55.1624760],
          [-2.5144020, 55.1624640],
          [-2.5142760, 55.1624600],
          [-2.5141590, 55.1624600],
          [-2.5140500, 55.1624600],
          [-2.5139590, 55.1624640],
          [-2.5138890, 55.1624640],
          [-2.5138230, 55.1624680],
          [-2.5137580, 55.1624640],
          [-2.5136890, 55.1624570],
          [-2.5136280, 55.1624530],
          [-2.5135720, 55.1624420],
          [-2.5135110, 55.1624300],
          [-2.5134520, 55.1624190],
          [-2.5133930, 55.1624070],
          [-2.5133310, 55.1623920],
          [-2.5132690, 55.1623800],
          [-2.5132110, 55.1623690],
          [-2.5131590, 55.1623550],
          [-2.5131030, 55.1623420],
          [-2.5130490, 55.1623310],
          [-2.5129880, 55.1623190],
          [-2.5129290, 55.1623120],
          [-2.5128670, 55.1622930],
          [-2.5128090, 55.1622810],
          [-2.5127530, 55.1622740],
          [-2.5126900, 55.1622590],
          [-2.5126270, 55.1622470],
          [-2.5125660, 55.1622320],
          [-2.5125100, 55.1622240],
          [-2.5124560, 55.1622160],
          [-2.5124000, 55.1622090],
          [-2.5123390, 55.1621970],
          [-2.5122760, 55.1621860],
          [-2.5122130, 55.1621740],
          [-2.5121500, 55.1621630],
          [-2.5120930, 55.1621520],
          [-2.5120330, 55.1621360],
          [-2.5119730, 55.1621250],
          [-2.5119050, 55.1621130],
          [-2.5118330, 55.1620980],
          [-2.5117570, 55.1620790],
          [-2.5116770, 55.1620600],
          [-2.5115920, 55.1620450],
          [-2.5115130, 55.1620260],
          [-2.5114410, 55.1620070],
          [-2.5113610, 55.1619880],
          [-2.5112800, 55.1619690],
          [-2.5112010, 55.1619500],
          [-2.5111090, 55.1619300],
          [-2.5110190, 55.1619110],
          [-2.5109210, 55.1618920],
          [-2.5108230, 55.1618730],
          [-2.5107170, 55.1618500],
          [-2.5106070, 55.1618270],
          [-2.5104980, 55.1618050],
          [-2.5103930, 55.1617850],
          [-2.5102860, 55.1617590],
          [-2.5101750, 55.1617410],
          [-2.5100530, 55.1617210],
          [-2.5099300, 55.1617060],
          [-2.5098070, 55.1616860],
          [-2.5096830, 55.1616670],
          [-2.5095510, 55.1616480],
          [-2.5094220, 55.1616250],
          [-2.5092880, 55.1616020],
          [-2.5091550, 55.1615830],
          [-2.5090140, 55.1615570],
          [-2.5088740, 55.1615340],
          [-2.5087270, 55.1615220],
          [-2.5085810, 55.1615260],
          [-2.5084280, 55.1615410],
          [-2.5082840, 55.1615600],
          [-2.5081230, 55.1615830],
          [-2.5079630, 55.1616130],
          [-2.5078080, 55.1616430],
          [-2.5076510, 55.1616780],
          [-2.5074950, 55.1617190],
          [-2.5073440, 55.1617690],
          [-2.5072010, 55.1618190],
          [-2.5070660, 55.1618700],
          [-2.5069270, 55.1619260],
          [-2.5067970, 55.1619790],
          [-2.5066770, 55.1620260],
          [-2.5065410, 55.1620780],
          [-2.5064160, 55.1621270],
          [-2.5062840, 55.1621810],
          [-2.5061560, 55.1622310],
          [-2.5060310, 55.1622910],
          [-2.5059040, 55.1623490],
          [-2.5057830, 55.1624010],
          [-2.5056690, 55.1624560],
          [-2.5055720, 55.1625050],
          [-2.5054890, 55.1625580],
          [-2.5054110, 55.1626080],
          [-2.5053330, 55.1626650],
          [-2.5052710, 55.1627220],
          [-2.5052180, 55.1627750],
          [-2.5051720, 55.1628330],
          [-2.5051320, 55.1628870],
          [-2.5050950, 55.1629390],
          [-2.5050610, 55.1629970],
          [-2.5050240, 55.1630510],
          [-2.5049960, 55.1631040],
          [-2.5049620, 55.1631580],
          [-2.5049430, 55.1632080],
          [-2.5049200, 55.1632570],
          [-2.5048890, 55.1632990],
          [-2.5048420, 55.1633370],
          [-2.5047810, 55.1633560],
          [-2.5047150, 55.1633680],
          [-2.5046410, 55.1633610],
          [-2.5045780, 55.1633490],
          [-2.5045160, 55.1633310],
          [-2.5044510, 55.1633080],
          [-2.5043840, 55.1632880],
          [-2.5043210, 55.1632660],
          [-2.5042480, 55.1632350],
          [-2.5041800, 55.1632050],
          [-2.5041030, 55.1631710],
          [-2.5040330, 55.1631410],
          [-2.5039550, 55.1631020],
          [-2.5038610, 55.1630610],
          [-2.5037860, 55.1630180],
          [-2.5037150, 55.1629720],
          [-2.5036270, 55.1629230],
          [-2.5035370, 55.1628620],
          [-2.5034530, 55.1628010],
          [-2.5033800, 55.1627370],
          [-2.5033080, 55.1626750],
          [-2.5032360, 55.1626110],
          [-2.5031640, 55.1625460],
          [-2.5030880, 55.1624850],
          [-2.5030290, 55.1624260],
          [-2.5029710, 55.1623720],
          [-2.5028980, 55.1623090],
          [-2.5028250, 55.1622480],
          [-2.5027530, 55.1621910],
          [-2.5026810, 55.1621350],
          [-2.5026050, 55.1620820],
          [-2.5025240, 55.1620320],
          [-2.5024510, 55.1619850],
          [-2.5023660, 55.1619310],
          [-2.5023000, 55.1618890],
          [-2.5022270, 55.1618390],
          [-2.5021690, 55.1618020],
          [-2.5021020, 55.1617590],
          [-2.5020550, 55.1617260],
          [-2.5020090, 55.1616860],
          [-2.5019720, 55.1616530],
          [-2.5019340, 55.1616220],
          [-2.5018950, 55.1615880],
          [-2.5018610, 55.1615590],
          [-2.5018300, 55.1615220],
          [-2.5018040, 55.1614920],
          [-2.5017870, 55.1614600],
          [-2.5017670, 55.1614270],
          [-2.5017390, 55.1613970],
          [-2.5017180, 55.1613680],
          [-2.5016930, 55.1613350],
          [-2.5016690, 55.1613090],
          [-2.5016470, 55.1612840],
          [-2.5016200, 55.1612510],
          [-2.5015950, 55.1612250],
          [-2.5015720, 55.1612030],
          [-2.5015440, 55.1611750],
          [-2.5015170, 55.1611530],
          [-2.5014910, 55.1611300],
          [-2.5014630, 55.1611050],
          [-2.5014300, 55.1610760],
          [-2.5014010, 55.1610490],
          [-2.5013740, 55.1610310],
          [-2.5013400, 55.1610030],
          [-2.5013100, 55.1609810],
          [-2.5012780, 55.1609580],
          [-2.5012470, 55.1609360],
          [-2.5012110, 55.1609120],
          [-2.5011780, 55.1608890],
          [-2.5011420, 55.1608660],
          [-2.5011070, 55.1608440],
          [-2.5010700, 55.1608200],
          [-2.5010340, 55.1607970],
          [-2.5010000, 55.1607800],
          [-2.5009590, 55.1607550],
          [-2.5009190, 55.1607360],
          [-2.5008820, 55.1607160],
          [-2.5008350, 55.1606940],
          [-2.5007960, 55.1606750],
          [-2.5007610, 55.1606540],
          [-2.5007140, 55.1606330],
          [-2.5006720, 55.1606140],
          [-2.5006370, 55.1605970],
          [-2.5005930, 55.1605800],
          [-2.5005520, 55.1605650],
          [-2.5005110, 55.1605460],
          [-2.5004730, 55.1605320],
          [-2.5004290, 55.1605150],
          [-2.5003890, 55.1604960],
          [-2.5003510, 55.1604810],
          [-2.5003160, 55.1604660],
          [-2.5002750, 55.1604460],
          [-2.5002330, 55.1604310],
          [-2.5001890, 55.1604200],
          [-2.5001470, 55.1604090],
          [-2.5001020, 55.1603930],
          [-2.5000630, 55.1603780],
          [-2.5000200, 55.1603630],
          [-2.4999790, 55.1603480],
          [-2.4999370, 55.1603320],
          [-2.4998960, 55.1603210],
          [-2.4998540, 55.1603090],
          [-2.4998170, 55.1603020],
          [-2.4997790, 55.1602900],
          [-2.4997370, 55.1602780],
          [-2.4996970, 55.1602750],
          [-2.4996610, 55.1602740],
          [-2.4996150, 55.1602670],
          [-2.4995720, 55.1602630],
          [-2.4995410, 55.1602560],
          [-2.4995080, 55.1602480],
          [-2.4994620, 55.1602480],
          [-2.4994160, 55.1602400],
          [-2.4993640, 55.1602330],
          [-2.4993180, 55.1602260],
          [-2.4992570, 55.1602140],
          [-2.4992060, 55.1602130],
          [-2.4991550, 55.1602100],
          [-2.4991020, 55.1602060],
          [-2.4990470, 55.1602060],
          [-2.4989950, 55.1602020],
          [-2.4989460, 55.1602020],
          [-2.4988990, 55.1601980],
          [-2.4988520, 55.1601980],
          [-2.4988080, 55.1601950],
          [-2.4987570, 55.1601940],
          [-2.4987080, 55.1601870],
          [-2.4986580, 55.1601870],
          [-2.4986070, 55.1601870],
          [-2.4985560, 55.1601910],
          [-2.4985060, 55.1601940],
          [-2.4984570, 55.1601940],
          [-2.4984090, 55.1601910],
          [-2.4983520, 55.1601870],
          [-2.4983040, 55.1601760],
          [-2.4982520, 55.1601720],
          [-2.4982030, 55.1601680],
          [-2.4981400, 55.1601600],
          [-2.4980890, 55.1601560],
          [-2.4980440, 55.1601530],
          [-2.4979910, 55.1601450],
          [-2.4979400, 55.1601410],
          [-2.4978930, 55.1601340],
          [-2.4978510, 55.1601300],
          [-2.4978040, 55.1601180],
          [-2.4977560, 55.1601140],
          [-2.4977080, 55.1601070],
          [-2.4976690, 55.1601000],
          [-2.4976280, 55.1600950],
          [-2.4975840, 55.1600910],
          [-2.4975440, 55.1600910],
          [-2.4975040, 55.1600840],
          [-2.4974620, 55.1600760],
          [-2.4974230, 55.1600690],
          [-2.4973830, 55.1600570],
          [-2.4973420, 55.1600500],
          [-2.4973030, 55.1600460],
          [-2.4972660, 55.1600340],
          [-2.4972270, 55.1600270],
          [-2.4971910, 55.1600150],
          [-2.4971540, 55.1600040],
          [-2.4971190, 55.1600070],
          [-2.4970860, 55.1599960],
          [-2.4970580, 55.1599850],
          [-2.4970310, 55.1599730],
          [-2.4970050, 55.1599540],
          [-2.4969810, 55.1599360],
          [-2.4969540, 55.1599200],
          [-2.4969240, 55.1599010],
          [-2.4968990, 55.1598860],
          [-2.4968690, 55.1598710],
          [-2.4968450, 55.1598550],
          [-2.4968220, 55.1598400],
          [-2.4967990, 55.1598210],
          [-2.4967770, 55.1598090],
          [-2.4967460, 55.1597910],
          [-2.4967120, 55.1597710],
          [-2.4966710, 55.1597450],
          [-2.4966400, 55.1597260],
          [-2.4966060, 55.1597030],
          [-2.4965740, 55.1596760],
          [-2.4965440, 55.1596530],
          [-2.4965190, 55.1596300],
          [-2.4964950, 55.1596070],
          [-2.4964710, 55.1595850],
          [-2.4964470, 55.1595620],
          [-2.4964240, 55.1595390],
          [-2.4964040, 55.1595200],
          [-2.4963820, 55.1595000],
          [-2.4963600, 55.1594780],
          [-2.4963390, 55.1594590],
          [-2.4963160, 55.1594390],
          [-2.4962910, 55.1594210],
          [-2.4962660, 55.1594020],
          [-2.4962390, 55.1593820],
          [-2.4962090, 55.1593600],
          [-2.4961810, 55.1593370],
          [-2.4961550, 55.1593140],
          [-2.4961290, 55.1592940],
          [-2.4961040, 55.1592720],
          [-2.4960770, 55.1592500],
          [-2.4960470, 55.1592330],
          [-2.4960190, 55.1592150],
          [-2.4959920, 55.1591950],
          [-2.4959670, 55.1591720],
          [-2.4959410, 55.1591570],
          [-2.4959150, 55.1591340],
          [-2.4958910, 55.1591160],
          [-2.4958630, 55.1590960],
          [-2.4958390, 55.1590810],
          [-2.4958110, 55.1590620],
          [-2.4957870, 55.1590470],
          [-2.4957610, 55.1590240],
          [-2.4957320, 55.1590050],
          [-2.4957060, 55.1589860],
          [-2.4956790, 55.1589630],
          [-2.4956520, 55.1589400],
          [-2.4956290, 55.1589130],
          [-2.4955990, 55.1588900],
          [-2.4955690, 55.1588640],
          [-2.4955390, 55.1588410],
          [-2.4955080, 55.1588140],
          [-2.4954740, 55.1587880],
          [-2.4954440, 55.1587610],
          [-2.4954050, 55.1587270],
          [-2.4953650, 55.1586930],
          [-2.4953280, 55.1586620],
          [-2.4952930, 55.1586310],
          [-2.4952510, 55.1586000],
          [-2.4952110, 55.1585740],
          [-2.4951690, 55.1585430],
          [-2.4951320, 55.1585130],
          [-2.4950930, 55.1584820],
          [-2.4950540, 55.1584550],
          [-2.4950090, 55.1584250],
          [-2.4949640, 55.1583910],
          [-2.4949180, 55.1583600],
          [-2.4948740, 55.1583300],
          [-2.4948340, 55.1582990],
          [-2.4947860, 55.1582690],
          [-2.4947410, 55.1582380],
          [-2.4946960, 55.1582000],
          [-2.4946540, 55.1581620],
          [-2.4946020, 55.1581210],
          [-2.4945470, 55.1580710],
          [-2.4944860, 55.1580140],
          [-2.4944230, 55.1579600],
          [-2.4943630, 55.1579070],
          [-2.4943100, 55.1578540],
          [-2.4942630, 55.1577960],
          [-2.4942140, 55.1577350],
          [-2.4941710, 55.1576710],
          [-2.4941370, 55.1576110],
          [-2.4941070, 55.1575450],
          [-2.4940920, 55.1574800],
          [-2.4940900, 55.1574150],
          [-2.4941080, 55.1573620],
          [-2.4941340, 55.1573110],
          [-2.4941690, 55.1572730],
          [-2.4942060, 55.1572420],
          [-2.4942510, 55.1572160],
          [-2.4943000, 55.1571860],
          [-2.4943480, 55.1571620],
          [-2.4943960, 55.1571430],
          [-2.4944430, 55.1571240],
          [-2.4944910, 55.1571050],
          [-2.4945410, 55.1570900],
          [-2.4945890, 55.1570780],
          [-2.4946420, 55.1570670],
          [-2.4946920, 55.1570520],
          [-2.4947430, 55.1570360],
          [-2.4947930, 55.1570180],
          [-2.4948470, 55.1570020],
          [-2.4949090, 55.1569830],
          [-2.4949660, 55.1569640],
          [-2.4950190, 55.1569370],
          [-2.4950670, 55.1569140],
          [-2.4951140, 55.1568880],
          [-2.4951650, 55.1568650],
          [-2.4952210, 55.1568420],
          [-2.4952790, 55.1568190],
          [-2.4953370, 55.1567920],
          [-2.4953970, 55.1567540],
          [-2.4954480, 55.1567200],
          [-2.4954950, 55.1566860],
          [-2.4955440, 55.1566480],
          [-2.4955940, 55.1566130],
          [-2.4956440, 55.1565790],
          [-2.4956930, 55.1565410],
          [-2.4957380, 55.1565070],
          [-2.4957890, 55.1564760],
          [-2.4958390, 55.1564410],
          [-2.4958920, 55.1564110],
          [-2.4959410, 55.1563730],
          [-2.4959910, 55.1563380],
          [-2.4960360, 55.1562930],
          [-2.4960810, 55.1562510],
          [-2.4961240, 55.1562130],
          [-2.4961660, 55.1561710],
          [-2.4962080, 55.1561320],
          [-2.4962500, 55.1560920],
          [-2.4962990, 55.1560560],
          [-2.4963450, 55.1560180],
          [-2.4963930, 55.1559760],
          [-2.4964400, 55.1559380],
          [-2.4964890, 55.1558960],
          [-2.4965390, 55.1558500],
          [-2.4965870, 55.1558050],
          [-2.4966390, 55.1557630],
          [-2.4966870, 55.1557250],
          [-2.4967360, 55.1556860],
          [-2.4967840, 55.1556450],
          [-2.4968290, 55.1556060],
          [-2.4968750, 55.1555680],
          [-2.4969210, 55.1555300],
          [-2.4969670, 55.1554920],
          [-2.4970140, 55.1554570],
          [-2.4970560, 55.1554190],
          [-2.4970990, 55.1553770],
          [-2.4971470, 55.1553430],
          [-2.4971940, 55.1553050],
          [-2.4972380, 55.1552670],
          [-2.4972820, 55.1552330],
          [-2.4973290, 55.1552010],
          [-2.4973740, 55.1551680],
          [-2.4974140, 55.1551290],
          [-2.4974640, 55.1550880],
          [-2.4975110, 55.1550540],
          [-2.4975580, 55.1550110],
          [-2.4976040, 55.1549730],
          [-2.4976570, 55.1549310],
          [-2.4977110, 55.1548890],
          [-2.4977640, 55.1548510],
          [-2.4978120, 55.1548090],
          [-2.4978640, 55.1547670],
          [-2.4979100, 55.1547290],
          [-2.4979590, 55.1546910],
          [-2.4980070, 55.1546450],
          [-2.4980570, 55.1546070],
          [-2.4981060, 55.1545650],
          [-2.4981570, 55.1545230],
          [-2.4982120, 55.1544810],
          [-2.4982690, 55.1544430],
          [-2.4983260, 55.1543970],
          [-2.4983820, 55.1543560],
          [-2.4984390, 55.1543130],
          [-2.4984980, 55.1542710],
          [-2.4985550, 55.1542290],
          [-2.4986060, 55.1541870],
          [-2.4986540, 55.1541490],
          [-2.4987050, 55.1541110],
          [-2.4987560, 55.1540760],
          [-2.4987990, 55.1540420],
          [-2.4988440, 55.1540040],
          [-2.4988910, 55.1539650],
          [-2.4989350, 55.1539320],
          [-2.4989840, 55.1538970],
          [-2.4990370, 55.1538590],
          [-2.4990860, 55.1538210],
          [-2.4991330, 55.1537830],
          [-2.4991780, 55.1537440],
          [-2.4992190, 55.1537070],
          [-2.4992620, 55.1536690],
          [-2.4993040, 55.1536270],
          [-2.4993470, 55.1535850],
          [-2.4993860, 55.1535420],
          [-2.4994190, 55.1535040],
          [-2.4994430, 55.1534590],
          [-2.4994660, 55.1534170],
          [-2.4994780, 55.1533740],
          [-2.4994780, 55.1533330],
          [-2.4994690, 55.1532910],
          [-2.4994520, 55.1532490],
          [-2.4994350, 55.1532140],
          [-2.4994150, 55.1531800],
          [-2.4993950, 55.1531460],
          [-2.4993800, 55.1531190],
          [-2.4993580, 55.1530890],
          [-2.4993290, 55.1530500],
          [-2.4993050, 55.1530080],
          [-2.4992760, 55.1529700],
          [-2.4992360, 55.1529320],
          [-2.4992050, 55.1528990],
          [-2.4991700, 55.1528560],
          [-2.4991410, 55.1528170],
          [-2.4991150, 55.1527760],
          [-2.4990920, 55.1527370],
          [-2.4990670, 55.1526950],
          [-2.4990440, 55.1526540],
          [-2.4990260, 55.1526120],
          [-2.4990110, 55.1525700],
          [-2.4989970, 55.1525240],
          [-2.4989900, 55.1524760],
          [-2.4989840, 55.1524290],
          [-2.4989800, 55.1523840],
          [-2.4989870, 55.1523370],
          [-2.4989970, 55.1522880],
          [-2.4990180, 55.1522420],
          [-2.4990470, 55.1521930],
          [-2.4990690, 55.1521400],
          [-2.4990960, 55.1520830],
          [-2.4991360, 55.1520320],
          [-2.4991820, 55.1519870],
          [-2.4992240, 55.1519410],
          [-2.4992710, 55.1518950],
          [-2.4993170, 55.1518560],
          [-2.4993660, 55.1518180],
          [-2.4994010, 55.1517840],
          [-2.4994280, 55.1517460],
          [-2.4994620, 55.1517160],
          [-2.4995030, 55.1516840],
          [-2.4995380, 55.1516540],
          [-2.4995730, 55.1516230],
          [-2.4996060, 55.1515930],
          [-2.4996410, 55.1515620],
          [-2.4996730, 55.1515240],
          [-2.4997060, 55.1514930],
          [-2.4997530, 55.1514550],
          [-2.4998040, 55.1514250],
          [-2.4998510, 55.1513920],
          [-2.4998910, 55.1513570],
          [-2.4999240, 55.1513110],
          [-2.4999610, 55.1512730],
          [-2.4999970, 55.1512310],
          [-2.5000260, 55.1511810],
          [-2.5000610, 55.1511360],
          [-2.5001010, 55.1510970],
          [-2.5001390, 55.1510590],
          [-2.5001840, 55.1510210],
          [-2.5002310, 55.1509670],
          [-2.5002830, 55.1509130],
          [-2.5003280, 55.1508620],
          [-2.5003710, 55.1508110],
          [-2.5004080, 55.1507650],
          [-2.5004460, 55.1507180],
          [-2.5004920, 55.1506700],
          [-2.5005360, 55.1506240],
          [-2.5005780, 55.1505780],
          [-2.5006270, 55.1505370],
          [-2.5006810, 55.1504910],
          [-2.5007330, 55.1504420],
          [-2.5007840, 55.1503950],
          [-2.5008270, 55.1503470],
          [-2.5008810, 55.1503070],
          [-2.5009290, 55.1502700],
          [-2.5009740, 55.1502350],
          [-2.5010070, 55.1501940],
          [-2.5010390, 55.1501510],
          [-2.5010670, 55.1501170],
          [-2.5011080, 55.1500820],
          [-2.5011520, 55.1500480],
          [-2.5011980, 55.1500140],
          [-2.5012390, 55.1499790],
          [-2.5012820, 55.1499490],
          [-2.5013290, 55.1499180],
          [-2.5013710, 55.1498800],
          [-2.5014170, 55.1498460],
          [-2.5014640, 55.1498080],
          [-2.5015040, 55.1497660],
          [-2.5015370, 55.1497290],
          [-2.5015610, 55.1496900],
          [-2.5015940, 55.1496510],
          [-2.5016260, 55.1496060],
          [-2.5016720, 55.1495570],
          [-2.5017240, 55.1495030],
          [-2.5017790, 55.1494600],
          [-2.5018390, 55.1494150],
          [-2.5018940, 55.1493730],
          [-2.5019510, 55.1493310],
          [-2.5020120, 55.1492930],
          [-2.5020840, 55.1492620],
          [-2.5021560, 55.1492280],
          [-2.5022260, 55.1491970],
          [-2.5022970, 55.1491670],
          [-2.5023690, 55.1491360],
          [-2.5024400, 55.1491090],
          [-2.5025150, 55.1490790],
          [-2.5025860, 55.1490560],
          [-2.5026600, 55.1490330],
          [-2.5027300, 55.1490100],
          [-2.5028040, 55.1489840],
          [-2.5028750, 55.1489570],
          [-2.5029430, 55.1489270],
          [-2.5030140, 55.1488990],
          [-2.5030840, 55.1488700],
          [-2.5031560, 55.1488390],
          [-2.5032360, 55.1488080],
          [-2.5033150, 55.1487780],
          [-2.5033940, 55.1487470],
          [-2.5034780, 55.1487130],
          [-2.5035730, 55.1486820],
          [-2.5036720, 55.1486440],
          [-2.5037720, 55.1486060],
          [-2.5038720, 55.1485650],
          [-2.5039800, 55.1485260],
          [-2.5040840, 55.1484840],
          [-2.5041940, 55.1484420],
          [-2.5043070, 55.1483970],
          [-2.5044200, 55.1483510],
          [-2.5045310, 55.1483050],
          [-2.5046400, 55.1482590],
          [-2.5047440, 55.1482100],
          [-2.5048420, 55.1481570],
          [-2.5049290, 55.1481060],
          [-2.5050010, 55.1480620],
          [-2.5050650, 55.1480120],
          [-2.5051240, 55.1479690],
          [-2.5051700, 55.1479280],
          [-2.5052110, 55.1478850],
          [-2.5052440, 55.1478470],
          [-2.5052710, 55.1478020],
          [-2.5052980, 55.1477600],
          [-2.5053230, 55.1477170],
          [-2.5053440, 55.1476760],
          [-2.5053640, 55.1476340],
          [-2.5053810, 55.1475910],
          [-2.5053980, 55.1475460],
          [-2.5054130, 55.1475080],
          [-2.5054280, 55.1474650],
          [-2.5054430, 55.1474200],
          [-2.5054610, 55.1473780],
          [-2.5054780, 55.1473360],
          [-2.5054930, 55.1472940],
          [-2.5055080, 55.1472530],
          [-2.5055220, 55.1472100],
          [-2.5055380, 55.1471720],
          [-2.5055530, 55.1471340],
          [-2.5055660, 55.1470920],
          [-2.5055800, 55.1470540],
          [-2.5055930, 55.1470120],
          [-2.5056060, 55.1469730],
          [-2.5056190, 55.1469360],
          [-2.5056330, 55.1468970],
          [-2.5056480, 55.1468550],
          [-2.5056630, 55.1468170],
          [-2.5056780, 55.1467760],
          [-2.5056930, 55.1467330],
          [-2.5057080, 55.1466910],
          [-2.5057230, 55.1466460],
          [-2.5057380, 55.1465960],
          [-2.5057550, 55.1465430],
          [-2.5057680, 55.1464890],
          [-2.5057800, 55.1464330],
          [-2.5057950, 55.1463750],
          [-2.5058080, 55.1463190],
          [-2.5058220, 55.1462680],
          [-2.5058330, 55.1462160],
          [-2.5058460, 55.1461690],
          [-2.5058580, 55.1461190],
          [-2.5058700, 55.1460730],
          [-2.5058770, 55.1460280],
          [-2.5058850, 55.1459820],
          [-2.5058930, 55.1459360],
          [-2.5059030, 55.1458900],
          [-2.5059150, 55.1458440],
          [-2.5059250, 55.1457990],
          [-2.5059330, 55.1457490],
          [-2.5059410, 55.1456990],
          [-2.5059510, 55.1456510],
          [-2.5059610, 55.1456010],
          [-2.5059720, 55.1455470],
          [-2.5059810, 55.1454940],
          [-2.5059930, 55.1454400],
          [-2.5060050, 55.1453870],
          [-2.5060150, 55.1453340],
          [-2.5060250, 55.1452840],
          [-2.5060350, 55.1452300],
          [-2.5060430, 55.1451810],
          [-2.5060500, 55.1451310],
          [-2.5060580, 55.1450810],
          [-2.5060650, 55.1450350],
          [-2.5060730, 55.1449900],
          [-2.5060830, 55.1449440],
          [-2.5060910, 55.1449020],
          [-2.5061000, 55.1448610],
          [-2.5061080, 55.1448150],
          [-2.5061160, 55.1447720],
          [-2.5061250, 55.1447310],
          [-2.5061320, 55.1446850],
          [-2.5061400, 55.1446430],
          [-2.5061480, 55.1445970],
          [-2.5061570, 55.1445550],
          [-2.5061630, 55.1445130],
          [-2.5061700, 55.1444710],
          [-2.5061780, 55.1444290],
          [-2.5061850, 55.1443910],
          [-2.5061900, 55.1443540],
          [-2.5061950, 55.1443150],
          [-2.5061980, 55.1442810],
          [-2.5062020, 55.1442430],
          [-2.5062000, 55.1442040],
          [-2.5061980, 55.1441660],
          [-2.5061970, 55.1441320],
          [-2.5061900, 55.1440980],
          [-2.5061820, 55.1440590],
          [-2.5061720, 55.1440290],
          [-2.5061650, 55.1439940],
          [-2.5061540, 55.1439630],
          [-2.5061390, 55.1439330],
          [-2.5061220, 55.1439060],
          [-2.5061050, 55.1438790],
          [-2.5060890, 55.1438530],
          [-2.5060720, 55.1438300],
          [-2.5060520, 55.1438070],
          [-2.5060320, 55.1437840],
          [-2.5060070, 55.1437650],
          [-2.5059770, 55.1437420],
          [-2.5059490, 55.1437230],
          [-2.5059190, 55.1437040],
          [-2.5058970, 55.1436820],
          [-2.5058710, 55.1436580],
          [-2.5058460, 55.1436390],
          [-2.5058240, 55.1436160],
          [-2.5058020, 55.1435970],
          [-2.5057810, 55.1435750],
          [-2.5057590, 55.1435510],
          [-2.5057340, 55.1435320],
          [-2.5057110, 55.1435100],
          [-2.5056850, 55.1434900],
          [-2.5056610, 55.1434670],
          [-2.5056340, 55.1434480],
          [-2.5056090, 55.1434250],
          [-2.5055820, 55.1434030],
          [-2.5055560, 55.1433800],
          [-2.5055310, 55.1433570],
          [-2.5055090, 55.1433300],
          [-2.5054920, 55.1433070],
          [-2.5054740, 55.1432810],
          [-2.5054550, 55.1432540],
          [-2.5054360, 55.1432270],
          [-2.5054190, 55.1432000],
          [-2.5054040, 55.1431740],
          [-2.5053920, 55.1431470],
          [-2.5053830, 55.1431200],
          [-2.5053750, 55.1430940],
          [-2.5053680, 55.1430630],
          [-2.5053600, 55.1430290],
          [-2.5053550, 55.1429950],
          [-2.5053550, 55.1429640],
          [-2.5053530, 55.1429300],
          [-2.5053500, 55.1428950],
          [-2.5053550, 55.1428610],
          [-2.5053700, 55.1428300],
          [-2.5053800, 55.1428000],
          [-2.5053850, 55.1427670],
          [-2.5053960, 55.1427350],
          [-2.5054130, 55.1427010],
          [-2.5054330, 55.1426670],
          [-2.5054530, 55.1426330],
          [-2.5054730, 55.1426020],
          [-2.5054890, 55.1425760],
          [-2.5055050, 55.1425440],
          [-2.5055250, 55.1425140],
          [-2.5055440, 55.1424800],
          [-2.5055660, 55.1424490],
          [-2.5055880, 55.1424190],
          [-2.5056080, 55.1423840],
          [-2.5056260, 55.1423540],
          [-2.5056490, 55.1423200],
          [-2.5056730, 55.1422810],
          [-2.5056980, 55.1422470],
          [-2.5057260, 55.1422090],
          [-2.5057530, 55.1421670],
          [-2.5057830, 55.1421250],
          [-2.5058100, 55.1420840],
          [-2.5058390, 55.1420450],
          [-2.5058710, 55.1420030],
          [-2.5059030, 55.1419570],
          [-2.5059340, 55.1419150],
          [-2.5059640, 55.1418690],
          [-2.5059900, 55.1418270],
          [-2.5060160, 55.1417790],
          [-2.5060410, 55.1417360],
          [-2.5060660, 55.1416900],
          [-2.5060900, 55.1416440],
          [-2.5061150, 55.1416000],
          [-2.5061510, 55.1415570],
          [-2.5061920, 55.1415190],
          [-2.5062280, 55.1414730],
          [-2.5062620, 55.1414310],
          [-2.5062980, 55.1413890],
          [-2.5063290, 55.1413470],
          [-2.5063560, 55.1413020],
          [-2.5063830, 55.1412590],
          [-2.5064130, 55.1412130],
          [-2.5064480, 55.1411640],
          [-2.5064870, 55.1411150],
          [-2.5065290, 55.1410690],
          [-2.5065740, 55.1410230],
          [-2.5066190, 55.1409660],
          [-2.5066620, 55.1409050],
          [-2.5067110, 55.1408470],
          [-2.5067570, 55.1407950],
          [-2.5068060, 55.1407330],
          [-2.5068540, 55.1406760],
          [-2.5069110, 55.1406150],
          [-2.5069620, 55.1405610],
          [-2.5070060, 55.1405120],
          [-2.5070470, 55.1404660],
          [-2.5070940, 55.1404210],
          [-2.5071390, 55.1403700],
          [-2.5071760, 55.1403180],
          [-2.5072140, 55.1402670],
          [-2.5072530, 55.1402180],
          [-2.5073070, 55.1401600],
          [-2.5073480, 55.1401110],
          [-2.5073880, 55.1400570],
          [-2.5074300, 55.1400080],
          [-2.5074710, 55.1399580],
          [-2.5075380, 55.1398800],
          [-2.5075990, 55.1398030],
          [-2.5076500, 55.1397380],
          [-2.5077000, 55.1396730],
          [-2.5077680, 55.1395930],
          [-2.5078270, 55.1395270],
          [-2.5078900, 55.1394540],
          [-2.5079690, 55.1393640],
          [-2.5080440, 55.1392840],
          [-2.5081160, 55.1392030],
          [-2.5081900, 55.1391230],
          [-2.5082690, 55.1390470],
          [-2.5083600, 55.1389720],
          [-2.5084610, 55.1388940],
          [-2.5085740, 55.1388250],
          [-2.5086940, 55.1387570],
          [-2.5088180, 55.1386920],
          [-2.5089480, 55.1386230],
          [-2.5091010, 55.1385440],
          [-2.5092590, 55.1384670],
          [-2.5094300, 55.1383800],
          [-2.5095910, 55.1382960],
          [-2.5097410, 55.1382160],
          [-2.5098800, 55.1381390],
          [-2.5100110, 55.1380740],
          [-2.5101340, 55.1380060],
          [-2.5102330, 55.1379410],
          [-2.5103250, 55.1378800],
          [-2.5104040, 55.1378190],
          [-2.5104700, 55.1377610],
          [-2.5105260, 55.1377080],
          [-2.5105720, 55.1376550],
          [-2.5106110, 55.1376080],
          [-2.5106430, 55.1375630],
          [-2.5106690, 55.1375180],
          [-2.5106940, 55.1374750],
          [-2.5107180, 55.1374290],
          [-2.5107350, 55.1373830],
          [-2.5107480, 55.1373380],
          [-2.5107580, 55.1372960],
          [-2.5107670, 55.1372500],
          [-2.5107670, 55.1372080],
          [-2.5107620, 55.1371710],
          [-2.5107520, 55.1371360],
          [-2.5107370, 55.1371010],
          [-2.5107250, 55.1370740],
          [-2.5107110, 55.1370480],
          [-2.5106940, 55.1370210],
          [-2.5106720, 55.1369980],
          [-2.5106520, 55.1369750],
          [-2.5106320, 55.1369520],
          [-2.5106110, 55.1369290],
          [-2.5105900, 55.1369020],
          [-2.5105660, 55.1368800],
          [-2.5105410, 55.1368570],
          [-2.5105150, 55.1368340],
          [-2.5104920, 55.1368110],
          [-2.5104700, 55.1367880],
          [-2.5104470, 55.1367650],
          [-2.5104240, 55.1367420],
          [-2.5104040, 55.1367160],
          [-2.5103820, 55.1366930],
          [-2.5103640, 55.1366700],
          [-2.5103470, 55.1366470],
          [-2.5103300, 55.1366200],
          [-2.5103170, 55.1365970],
          [-2.5103050, 55.1365740],
          [-2.5102970, 55.1365550],
          [-2.5102900, 55.1365330],
          [-2.5102840, 55.1365060],
          [-2.5102780, 55.1364830],
          [-2.5102720, 55.1364600],
          [-2.5102690, 55.1364370],
          [-2.5102670, 55.1364140],
          [-2.5102670, 55.1363910],
          [-2.5102700, 55.1363690],
          [-2.5102750, 55.1363460],
          [-2.5102810, 55.1363230],
          [-2.5102900, 55.1362960],
          [-2.5103000, 55.1362770],
          [-2.5103110, 55.1362540],
          [-2.5103250, 55.1362310],
          [-2.5103400, 55.1362080],
          [-2.5103600, 55.1361850],
          [-2.5103810, 55.1361660],
          [-2.5104030, 55.1361430],
          [-2.5104240, 55.1361210],
          [-2.5104460, 55.1361010],
          [-2.5104710, 55.1360820],
          [-2.5104980, 55.1360590],
          [-2.5105280, 55.1360400],
          [-2.5105590, 55.1360220],
          [-2.5105910, 55.1360020],
          [-2.5106240, 55.1359800],
          [-2.5106570, 55.1359600],
          [-2.5106910, 55.1359370],
          [-2.5107240, 55.1359140],
          [-2.5107590, 55.1358920],
          [-2.5107960, 55.1358690],
          [-2.5108330, 55.1358460],
          [-2.5108740, 55.1358190],
          [-2.5109170, 55.1357930],
          [-2.5109610, 55.1357620],
          [-2.5110050, 55.1357240],
          [-2.5110540, 55.1356860],
          [-2.5111040, 55.1356360],
          [-2.5111550, 55.1355870],
          [-2.5112060, 55.1355330],
          [-2.5112540, 55.1354770],
          [-2.5113030, 55.1354190],
          [-2.5113480, 55.1353590],
          [-2.5113930, 55.1353040],
          [-2.5114360, 55.1352470],
          [-2.5114800, 55.1351950],
          [-2.5115230, 55.1351400],
          [-2.5115640, 55.1350870],
          [-2.5116050, 55.1350340],
          [-2.5116450, 55.1349810],
          [-2.5116890, 55.1349270],
          [-2.5117290, 55.1348730],
          [-2.5117690, 55.1348200],
          [-2.5118060, 55.1347700],
          [-2.5118430, 55.1347170],
          [-2.5118780, 55.1346690],
          [-2.5119160, 55.1346140],
          [-2.5119530, 55.1345640],
          [-2.5119850, 55.1345150],
          [-2.5120180, 55.1344690],
          [-2.5120470, 55.1344240],
          [-2.5120720, 55.1343780],
          [-2.5120980, 55.1343350],
          [-2.5121230, 55.1342970],
          [-2.5121440, 55.1342560],
          [-2.5121650, 55.1342170],
          [-2.5121810, 55.1341750],
          [-2.5121910, 55.1341340],
          [-2.5122030, 55.1340910],
          [-2.5122150, 55.1340490],
          [-2.5122280, 55.1340030],
          [-2.5122430, 55.1339610],
          [-2.5122560, 55.1339160],
          [-2.5122680, 55.1338740],
          [-2.5122800, 55.1338290],
          [-2.5122900, 55.1337820],
          [-2.5123000, 55.1337400],
          [-2.5123100, 55.1336950],
          [-2.5123200, 55.1336520],
          [-2.5123310, 55.1336070],
          [-2.5123450, 55.1335650],
          [-2.5123600, 55.1335230],
          [-2.5123760, 55.1334850],
          [-2.5123880, 55.1334470],
          [-2.5123960, 55.1334080],
          [-2.5124080, 55.1333670],
          [-2.5124250, 55.1333250],
          [-2.5124460, 55.1332860],
          [-2.5124700, 55.1332480],
          [-2.5124930, 55.1332060],
          [-2.5125180, 55.1331640],
          [-2.5125440, 55.1331260],
          [-2.5125740, 55.1330840],
          [-2.5126060, 55.1330460],
          [-2.5126380, 55.1330040],
          [-2.5126720, 55.1329660],
          [-2.5127060, 55.1329280],
          [-2.5127390, 55.1328860],
          [-2.5127760, 55.1328480],
          [-2.5128130, 55.1328090],
          [-2.5128520, 55.1327760],
          [-2.5128910, 55.1327370],
          [-2.5129310, 55.1326990],
          [-2.5129680, 55.1326610],
          [-2.5130100, 55.1326230],
          [-2.5130520, 55.1325850],
          [-2.5130940, 55.1325540],
          [-2.5131340, 55.1325190],
          [-2.5131780, 55.1324890],
          [-2.5132220, 55.1324550],
          [-2.5132670, 55.1324240],
          [-2.5133130, 55.1323940],
          [-2.5133600, 55.1323600],
          [-2.5134080, 55.1323290],
          [-2.5134540, 55.1322980],
          [-2.5135040, 55.1322680],
          [-2.5135540, 55.1322370],
          [-2.5136070, 55.1322070],
          [-2.5136650, 55.1321760],
          [-2.5137190, 55.1321460],
          [-2.5137710, 55.1321110],
          [-2.5138180, 55.1320740],
          [-2.5138690, 55.1320350],
          [-2.5139190, 55.1319940],
          [-2.5139670, 55.1319470],
          [-2.5140160, 55.1319020],
          [-2.5140630, 55.1318560],
          [-2.5141040, 55.1318070],
          [-2.5141430, 55.1317570],
          [-2.5141740, 55.1317070],
          [-2.5142030, 55.1316540],
          [-2.5142260, 55.1316050],
          [-2.5142460, 55.1315510],
          [-2.5142580, 55.1315020],
          [-2.5142650, 55.1314480],
          [-2.5142670, 55.1314020],
          [-2.5142650, 55.1313520],
          [-2.5142600, 55.1313040],
          [-2.5142500, 55.1312570],
          [-2.5142430, 55.1312070],
          [-2.5142370, 55.1311650],
          [-2.5142300, 55.1311240],
          [-2.5142200, 55.1310890],
          [-2.5142090, 55.1310550],
          [-2.5141950, 55.1310240],
          [-2.5141840, 55.1309940],
          [-2.5141730, 55.1309700],
          [-2.5141630, 55.1309440],
          [-2.5141520, 55.1309170],
          [-2.5141420, 55.1308940],
          [-2.5141320, 55.1308710],
          [-2.5141250, 55.1308480],
          [-2.5141190, 55.1308260],
          [-2.5141100, 55.1307990],
          [-2.5141040, 55.1307760],
          [-2.5140950, 55.1307530],
          [-2.5140870, 55.1307300],
          [-2.5140790, 55.1307040],
          [-2.5140700, 55.1306810],
          [-2.5140600, 55.1306540],
          [-2.5140520, 55.1306270],
          [-2.5140420, 55.1306010],
          [-2.5140340, 55.1305740],
          [-2.5140230, 55.1305470],
          [-2.5140150, 55.1305250],
          [-2.5140030, 55.1304970],
          [-2.5139930, 55.1304750],
          [-2.5139820, 55.1304480],
          [-2.5139650, 55.1304250],
          [-2.5139540, 55.1303980],
          [-2.5139420, 55.1303720],
          [-2.5139340, 55.1303450],
          [-2.5139250, 55.1303180],
          [-2.5139140, 55.1302880],
          [-2.5139040, 55.1302610],
          [-2.5138950, 55.1302340],
          [-2.5138850, 55.1302040],
          [-2.5138770, 55.1301770],
          [-2.5138680, 55.1301470],
          [-2.5138570, 55.1301160],
          [-2.5138440, 55.1300860],
          [-2.5138270, 55.1300550],
          [-2.5138120, 55.1300210],
          [-2.5137960, 55.1299830],
          [-2.5137740, 55.1299450],
          [-2.5137510, 55.1299030],
          [-2.5137260, 55.1298590],
          [-2.5136970, 55.1298080],
          [-2.5136670, 55.1297540],
          [-2.5136370, 55.1297050],
          [-2.5136030, 55.1296510],
          [-2.5135620, 55.1295980],
          [-2.5135200, 55.1295450],
          [-2.5134780, 55.1294910],
          [-2.5134340, 55.1294340],
          [-2.5133880, 55.1293780],
          [-2.5133360, 55.1293200],
          [-2.5132830, 55.1292580],
          [-2.5132250, 55.1291940],
          [-2.5131600, 55.1291290],
          [-2.5130980, 55.1290700],
          [-2.5130310, 55.1290030],
          [-2.5129710, 55.1289420],
          [-2.5129140, 55.1288820],
          [-2.5128550, 55.1288200],
          [-2.5128010, 55.1287620],
          [-2.5127470, 55.1287090],
          [-2.5126960, 55.1286510],
          [-2.5126410, 55.1285980],
          [-2.5125870, 55.1285450],
          [-2.5125330, 55.1284960],
          [-2.5124760, 55.1284460],
          [-2.5124240, 55.1283960],
          [-2.5123790, 55.1283500],
          [-2.5123360, 55.1283040],
          [-2.5122950, 55.1282520],
          [-2.5122550, 55.1282060],
          [-2.5122160, 55.1281590],
          [-2.5121810, 55.1281100],
          [-2.5121470, 55.1280640],
          [-2.5121180, 55.1280150],
          [-2.5120900, 55.1279690],
          [-2.5120670, 55.1279230],
          [-2.5120470, 55.1278810],
          [-2.5120290, 55.1278360],
          [-2.5120140, 55.1277940],
          [-2.5120040, 55.1277550],
          [-2.5119940, 55.1277170],
          [-2.5119870, 55.1276830],
          [-2.5119830, 55.1276400],
          [-2.5119770, 55.1276100],
          [-2.5119720, 55.1275750],
          [-2.5119680, 55.1275410],
          [-2.5119670, 55.1275110],
          [-2.5119670, 55.1274800],
          [-2.5119630, 55.1274500],
          [-2.5119620, 55.1274200],
          [-2.5119600, 55.1273920],
          [-2.5119580, 55.1273620],
          [-2.5119580, 55.1273280],
          [-2.5119580, 55.1272970],
          [-2.5119580, 55.1272670],
          [-2.5119570, 55.1272330],
          [-2.5119580, 55.1272020],
          [-2.5119620, 55.1271680],
          [-2.5119650, 55.1271410],
          [-2.5119670, 55.1271070],
          [-2.5119680, 55.1270760],
          [-2.5119700, 55.1270450],
          [-2.5119720, 55.1270110],
          [-2.5119750, 55.1269810],
          [-2.5119770, 55.1269540],
          [-2.5119780, 55.1269270],
          [-2.5119770, 55.1268980],
          [-2.5119770, 55.1268700],
          [-2.5119780, 55.1268430],
          [-2.5119820, 55.1268130],
          [-2.5119830, 55.1267860],
          [-2.5119880, 55.1267590],
          [-2.5119900, 55.1267330],
          [-2.5119980, 55.1267060],
          [-2.5120030, 55.1266790],
          [-2.5120130, 55.1266520],
          [-2.5120200, 55.1266260],
          [-2.5120260, 55.1265950],
          [-2.5120280, 55.1265680],
          [-2.5120300, 55.1265380],
          [-2.5120310, 55.1265110],
          [-2.5120320, 55.1264810],
          [-2.5120320, 55.1264500],
          [-2.5120350, 55.1264200],
          [-2.5120400, 55.1263850],
          [-2.5120450, 55.1263510],
          [-2.5120520, 55.1263160],
          [-2.5120580, 55.1262830],
          [-2.5120630, 55.1262450],
          [-2.5120670, 55.1262100],
          [-2.5120680, 55.1261680],
          [-2.5120720, 55.1261300],
          [-2.5120720, 55.1260880],
          [-2.5120730, 55.1260460],
          [-2.5120750, 55.1259970],
          [-2.5120780, 55.1259510],
          [-2.5120850, 55.1258970],
          [-2.5120880, 55.1258480],
          [-2.5120910, 55.1257970],
          [-2.5120970, 55.1257370],
          [-2.5120980, 55.1256760],
          [-2.5121020, 55.1256160],
          [-2.5121030, 55.1255540],
          [-2.5121070, 55.1254900],
          [-2.5121070, 55.1254210],
          [-2.5121080, 55.1253480],
          [-2.5121080, 55.1252790],
          [-2.5121070, 55.1252050],
          [-2.5121050, 55.1251310],
          [-2.5121000, 55.1250550],
          [-2.5120950, 55.1249760],
          [-2.5120900, 55.1248950],
          [-2.5120850, 55.1248070],
          [-2.5120770, 55.1247230],
          [-2.5120620, 55.1246350],
          [-2.5120420, 55.1245480],
          [-2.5120170, 55.1244560],
          [-2.5119820, 55.1243680],
          [-2.5119420, 55.1242840],
          [-2.5118940, 55.1242040],
          [-2.5118510, 55.1241320],
          [-2.5118240, 55.1240670],
          [-2.5118200, 55.1240060],
          [-2.5118430, 55.1239520],
          [-2.5119060, 55.1239100],
          [-2.5119890, 55.1238830],
          [-2.5120850, 55.1238670],
          [-2.5121870, 55.1238560],
          [-2.5122920, 55.1238480],
          [-2.5124030, 55.1238370],
          [-2.5125150, 55.1238180],
          [-2.5126310, 55.1237950],
          [-2.5127490, 55.1237610],
          [-2.5128670, 55.1237190],
          [-2.5129810, 55.1236700],
          [-2.5130930, 55.1236240],
          [-2.5132030, 55.1235700],
          [-2.5133090, 55.1235210],
          [-2.5134120, 55.1234720],
          [-2.5135180, 55.1234220],
          [-2.5136280, 55.1233680],
          [-2.5137370, 55.1233190],
          [-2.5138430, 55.1232690],
          [-2.5139500, 55.1232170],
          [-2.5140640, 55.1231630],
          [-2.5141830, 55.1231090],
          [-2.5143060, 55.1230640],
          [-2.5144460, 55.1230210],
          [-2.5145960, 55.1229900],
          [-2.5147550, 55.1229670],
          [-2.5149090, 55.1229590],
          [-2.5150750, 55.1229520],
          [-2.5152480, 55.1229440],
          [-2.5154280, 55.1229360],
          [-2.5156090, 55.1229180],
          [-2.5157960, 55.1228870],
          [-2.5159810, 55.1228500],
          [-2.5161660, 55.1228070],
          [-2.5163430, 55.1227620],
          [-2.5165190, 55.1227160],
          [-2.5166960, 55.1226700],
          [-2.5168750, 55.1226210],
          [-2.5170540, 55.1225600],
          [-2.5172280, 55.1224990],
          [-2.5173980, 55.1224340],
          [-2.5175660, 55.1223690],
          [-2.5177360, 55.1223040],
          [-2.5179080, 55.1222430],
          [-2.5180830, 55.1221910],
          [-2.5182590, 55.1221480],
          [-2.5184320, 55.1221090],
          [-2.5185970, 55.1220710],
          [-2.5187580, 55.1220370],
          [-2.5189100, 55.1219950],
          [-2.5190560, 55.1219540],
          [-2.5191900, 55.1219130],
          [-2.5193190, 55.1218700],
          [-2.5194410, 55.1218280],
          [-2.5195580, 55.1217890],
          [-2.5196640, 55.1217520],
          [-2.5197630, 55.1217130],
          [-2.5198530, 55.1216750],
          [-2.5199390, 55.1216440],
          [-2.5200130, 55.1216130],
          [-2.5200790, 55.1215830],
          [-2.5201380, 55.1215560],
          [-2.5201920, 55.1215330],
          [-2.5202370, 55.1215100],
          [-2.5202840, 55.1214880],
          [-2.5203300, 55.1214680],
          [-2.5203750, 55.1214490],
          [-2.5204170, 55.1214300],
          [-2.5204600, 55.1214070],
          [-2.5205010, 55.1213880],
          [-2.5205420, 55.1213690],
          [-2.5205850, 55.1213500],
          [-2.5206270, 55.1213310],
          [-2.5206670, 55.1213160],
          [-2.5207030, 55.1212970],
          [-2.5207390, 55.1212810],
          [-2.5207780, 55.1212620],
          [-2.5208150, 55.1212430],
          [-2.5208560, 55.1212240],
          [-2.5208890, 55.1212050],
          [-2.5209260, 55.1211900],
          [-2.5209570, 55.1211710],
          [-2.5209920, 55.1211520],
          [-2.5210260, 55.1211360],
          [-2.5210580, 55.1211170],
          [-2.5210880, 55.1211020],
          [-2.5211140, 55.1210860],
          [-2.5211390, 55.1210710],
          [-2.5211660, 55.1210520],
          [-2.5211910, 55.1210330],
          [-2.5212170, 55.1210150],
          [-2.5212420, 55.1209950],
          [-2.5212660, 55.1209760],
          [-2.5212900, 55.1209530],
          [-2.5213110, 55.1209310],
          [-2.5213310, 55.1209070],
          [-2.5213520, 55.1208880],
          [-2.5213740, 55.1208690],
          [-2.5213940, 55.1208510],
          [-2.5214130, 55.1208310],
          [-2.5214330, 55.1208120],
          [-2.5214510, 55.1207930],
          [-2.5214690, 55.1207740],
          [-2.5214860, 55.1207520],
          [-2.5215030, 55.1207320],
          [-2.5215180, 55.1207090],
          [-2.5215360, 55.1206900],
          [-2.5215540, 55.1206720],
          [-2.5215720, 55.1206560],
          [-2.5215890, 55.1206330],
          [-2.5216050, 55.1206130],
          [-2.5216160, 55.1205910],
          [-2.5216320, 55.1205690],
          [-2.5216510, 55.1205420],
          [-2.5216690, 55.1205150],
          [-2.5216850, 55.1204880],
          [-2.5217020, 55.1204620],
          [-2.5217210, 55.1204310],
          [-2.5217380, 55.1204040],
          [-2.5217510, 55.1203740],
          [-2.5217630, 55.1203430],
          [-2.5217790, 55.1203130],
          [-2.5217930, 55.1202790],
          [-2.5218100, 55.1202480],
          [-2.5218290, 55.1202150],
          [-2.5218480, 55.1201790],
          [-2.5218660, 55.1201450],
          [-2.5218860, 55.1201110],
          [-2.5219090, 55.1200770],
          [-2.5219340, 55.1200430],
          [-2.5219610, 55.1200070],
          [-2.5219890, 55.1199740],
          [-2.5220190, 55.1199390],
          [-2.5220520, 55.1199040],
          [-2.5220850, 55.1198670],
          [-2.5221240, 55.1198280],
          [-2.5221680, 55.1197860],
          [-2.5222100, 55.1197450],
          [-2.5222570, 55.1196990],
          [-2.5223070, 55.1196510],
          [-2.5223700, 55.1196000],
          [-2.5224380, 55.1195490],
          [-2.5225200, 55.1194930],
          [-2.5226110, 55.1194420],
          [-2.5227240, 55.1193870],
          [-2.5228490, 55.1193380],
          [-2.5229920, 55.1192870],
          [-2.5231390, 55.1192420],
          [-2.5232900, 55.1192040],
          [-2.5234520, 55.1191620],
          [-2.5236160, 55.1191210],
          [-2.5237920, 55.1190740],
          [-2.5239680, 55.1190210],
          [-2.5241380, 55.1189650],
          [-2.5243020, 55.1189020],
          [-2.5244710, 55.1188380],
          [-2.5246250, 55.1187710],
          [-2.5247690, 55.1186950],
          [-2.5249070, 55.1186190],
          [-2.5250280, 55.1185410],
          [-2.5251310, 55.1184660],
          [-2.5252180, 55.1183870],
          [-2.5252860, 55.1183030],
          [-2.5253310, 55.1182140],
          [-2.5253480, 55.1181240],
          [-2.5253290, 55.1180310],
          [-2.5252780, 55.1179380],
          [-2.5252190, 55.1178490],
          [-2.5251590, 55.1177580],
          [-2.5250970, 55.1176660],
          [-2.5250370, 55.1175700],
          [-2.5249820, 55.1174750],
          [-2.5249250, 55.1173770],
          [-2.5248710, 55.1172800],
          [-2.5248210, 55.1171780],
          [-2.5247740, 55.1170780],
          [-2.5247290, 55.1169790],
          [-2.5246780, 55.1168840],
          [-2.5246130, 55.1167840],
          [-2.5245360, 55.1166960],
          [-2.5244530, 55.1166130],
          [-2.5243760, 55.1165370],
          [-2.5243150, 55.1164570],
          [-2.5242890, 55.1163920],
          [-2.5243040, 55.1163170],
          [-2.5243600, 55.1162560],
          [-2.5244270, 55.1162130],
          [-2.5244960, 55.1161790],
          [-2.5245730, 55.1161540],
          [-2.5246490, 55.1161390],
          [-2.5247320, 55.1161170],
          [-2.5248110, 55.1160870],
          [-2.5249020, 55.1160480],
          [-2.5249990, 55.1160080],
          [-2.5251050, 55.1159620],
          [-2.5252180, 55.1159050],
          [-2.5253360, 55.1158470],
          [-2.5254470, 55.1157900],
          [-2.5255730, 55.1157300],
          [-2.5256960, 55.1156740],
          [-2.5258290, 55.1156200],
          [-2.5259610, 55.1155690],
          [-2.5261040, 55.1155190],
          [-2.5262430, 55.1154780],
          [-2.5263930, 55.1154390],
          [-2.5265430, 55.1154080],
          [-2.5266970, 55.1153850],
          [-2.5268520, 55.1153720],
          [-2.5269980, 55.1153680],
          [-2.5271370, 55.1153610],
          [-2.5272770, 55.1153600],
          [-2.5274080, 55.1153570],
          [-2.5275420, 55.1153490],
          [-2.5276690, 55.1153450],
          [-2.5278010, 55.1153450],
          [-2.5279270, 55.1153410],
          [-2.5280580, 55.1153450],
          [-2.5281860, 55.1153490],
          [-2.5283160, 55.1153600],
          [-2.5284320, 55.1153710],
          [-2.5285630, 55.1153830],
          [-2.5286830, 55.1153900],
          [-2.5288110, 55.1154020],
          [-2.5289280, 55.1154090],
          [-2.5290580, 55.1154140],
          [-2.5291730, 55.1154170],
          [-2.5292950, 55.1154210],
          [-2.5294100, 55.1154250],
          [-2.5295250, 55.1154290],
          [-2.5296230, 55.1154290],
          [-2.5297290, 55.1154250],
          [-2.5298200, 55.1154150],
          [-2.5299140, 55.1154060],
          [-2.5300070, 55.1153950],
          [-2.5301010, 55.1153800],
          [-2.5301900, 55.1153620],
          [-2.5302840, 55.1153460],
          [-2.5303730, 55.1153310],
          [-2.5304680, 55.1153110],
          [-2.5305570, 55.1152930],
          [-2.5306520, 55.1152700],
          [-2.5307430, 55.1152510],
          [-2.5308350, 55.1152280],
          [-2.5309240, 55.1152060],
          [-2.5310170, 55.1151820],
          [-2.5311050, 55.1151630],
          [-2.5311960, 55.1151400],
          [-2.5312850, 55.1151210],
          [-2.5313730, 55.1150980],
          [-2.5314560, 55.1150790],
          [-2.5315430, 55.1150560],
          [-2.5316240, 55.1150370],
          [-2.5317090, 55.1150180],
          [-2.5317870, 55.1149960],
          [-2.5318650, 55.1149800],
          [-2.5319390, 55.1149650],
          [-2.5320130, 55.1149420],
          [-2.5320810, 55.1149230],
          [-2.5321550, 55.1149000],
          [-2.5322230, 55.1148780],
          [-2.5322970, 55.1148540],
          [-2.5323690, 55.1148320],
          [-2.5324490, 55.1148050],
          [-2.5325250, 55.1147790],
          [-2.5326090, 55.1147510],
          [-2.5326840, 55.1147250],
          [-2.5327630, 55.1146980],
          [-2.5328370, 55.1146720],
          [-2.5329160, 55.1146440],
          [-2.5329890, 55.1146150],
          [-2.5330660, 55.1145800],
          [-2.5331360, 55.1145540],
          [-2.5332090, 55.1145190],
          [-2.5332810, 55.1144890],
          [-2.5333570, 55.1144610],
          [-2.5334280, 55.1144350],
          [-2.5335020, 55.1144150],
          [-2.5335680, 55.1143970],
          [-2.5336330, 55.1143810],
          [-2.5336960, 55.1143620],
          [-2.5337520, 55.1143500],
          [-2.5338100, 55.1143350],
          [-2.5338660, 55.1143230],
          [-2.5339170, 55.1143120],
          [-2.5339750, 55.1143000],
          [-2.5340270, 55.1142890],
          [-2.5340730, 55.1142810],
          [-2.5341180, 55.1142730],
          [-2.5341610, 55.1142660],
          [-2.5342080, 55.1142580],
          [-2.5342530, 55.1142510],
          [-2.5342970, 55.1142390],
          [-2.5343410, 55.1142310],
          [-2.5343850, 55.1142240],
          [-2.5344260, 55.1142160],
          [-2.5344700, 55.1142090],
          [-2.5345130, 55.1142010],
          [-2.5345560, 55.1141970],
          [-2.5345980, 55.1141930],
          [-2.5346470, 55.1141860],
          [-2.5346920, 55.1141780],
          [-2.5347360, 55.1141700],
          [-2.5347800, 55.1141600],
          [-2.5348270, 55.1141480],
          [-2.5348730, 55.1141400],
          [-2.5349180, 55.1141320],
          [-2.5349630, 55.1141250],
          [-2.5350080, 55.1141130],
          [-2.5350490, 55.1141060],
          [-2.5350940, 55.1140950],
          [-2.5351410, 55.1140830],
          [-2.5351910, 55.1140710],
          [-2.5352410, 55.1140600],
          [-2.5352910, 55.1140450],
          [-2.5353440, 55.1140330],
          [-2.5353960, 55.1140220],
          [-2.5354430, 55.1140110],
          [-2.5354900, 55.1139950],
          [-2.5355310, 55.1139840],
          [-2.5355730, 55.1139760],
          [-2.5356120, 55.1139690],
          [-2.5356480, 55.1139640],
          [-2.5356850, 55.1139570],
          [-2.5357210, 55.1139490],
          [-2.5357570, 55.1139420],
          [-2.5357950, 55.1139340],
          [-2.5358330, 55.1139190],
          [-2.5358720, 55.1139070],
          [-2.5359120, 55.1138960],
          [-2.5359500, 55.1138850],
          [-2.5359940, 55.1138730],
          [-2.5360310, 55.1138620],
          [-2.5360720, 55.1138460],
          [-2.5361100, 55.1138320],
          [-2.5361520, 55.1138160],
          [-2.5361920, 55.1138010],
          [-2.5362300, 55.1137850],
          [-2.5362650, 55.1137670],
          [-2.5363030, 55.1137480],
          [-2.5363420, 55.1137280],
          [-2.5363790, 55.1137090],
          [-2.5364160, 55.1136910],
          [-2.5364530, 55.1136680],
          [-2.5364920, 55.1136450],
          [-2.5365260, 55.1136230],
          [-2.5365670, 55.1136020],
          [-2.5366060, 55.1135760],
          [-2.5366440, 55.1135570],
          [-2.5366820, 55.1135370],
          [-2.5367190, 55.1135150],
          [-2.5367570, 55.1134920],
          [-2.5367940, 55.1134730],
          [-2.5368330, 55.1134490],
          [-2.5368730, 55.1134310],
          [-2.5369170, 55.1134120],
          [-2.5369600, 55.1133890],
          [-2.5370010, 55.1133710],
          [-2.5370490, 55.1133500],
          [-2.5370920, 55.1133280],
          [-2.5371330, 55.1133130],
          [-2.5371790, 55.1132890],
          [-2.5372230, 55.1132700],
          [-2.5372660, 55.1132550],
          [-2.5373090, 55.1132360],
          [-2.5373530, 55.1132210],
          [-2.5373980, 55.1132010],
          [-2.5374390, 55.1131830],
          [-2.5374820, 55.1131640],
          [-2.5375270, 55.1131440],
          [-2.5375710, 55.1131250],
          [-2.5376140, 55.1131100],
          [-2.5376590, 55.1130950],
          [-2.5377040, 55.1130760],
          [-2.5377480, 55.1130600],
          [-2.5377910, 55.1130410],
          [-2.5378340, 55.1130220],
          [-2.5378790, 55.1130070],
          [-2.5379210, 55.1129880],
          [-2.5379630, 55.1129690],
          [-2.5380070, 55.1129540],
          [-2.5380490, 55.1129340],
          [-2.5380920, 55.1129150],
          [-2.5381340, 55.1129000],
          [-2.5381760, 55.1128850],
          [-2.5382140, 55.1128680],
          [-2.5382610, 55.1128500],
          [-2.5383040, 55.1128350],
          [-2.5383460, 55.1128240],
          [-2.5383860, 55.1128090],
          [-2.5384240, 55.1127970],
          [-2.5384600, 55.1127860],
          [-2.5385010, 55.1127740],
          [-2.5385390, 55.1127660],
          [-2.5385770, 55.1127590],
          [-2.5386190, 55.1127470],
          [-2.5386590, 55.1127400],
          [-2.5386990, 55.1127280],
          [-2.5387390, 55.1127170],
          [-2.5387790, 55.1127050],
          [-2.5388160, 55.1126980],
          [-2.5388540, 55.1126860],
          [-2.5388910, 55.1126750],
          [-2.5389260, 55.1126630],
          [-2.5389590, 55.1126520],
          [-2.5389930, 55.1126440],
          [-2.5390290, 55.1126330],
          [-2.5390630, 55.1126250],
          [-2.5390960, 55.1126180],
          [-2.5391330, 55.1126060],
          [-2.5391660, 55.1125990],
          [-2.5392040, 55.1125830],
          [-2.5392390, 55.1125760],
          [-2.5392740, 55.1125680],
          [-2.5393160, 55.1125570],
          [-2.5393590, 55.1125450],
          [-2.5394050, 55.1125370],
          [-2.5394540, 55.1125300],
          [-2.5395030, 55.1125180],
          [-2.5395520, 55.1125070],
          [-2.5395990, 55.1124990],
          [-2.5396440, 55.1124880],
          [-2.5396850, 55.1124780],
          [-2.5397380, 55.1124690],
          [-2.5397860, 55.1124610],
          [-2.5398320, 55.1124540],
          [-2.5398780, 55.1124420],
          [-2.5399240, 55.1124310],
          [-2.5399700, 55.1124200],
          [-2.5400170, 55.1124080],
          [-2.5400660, 55.1123930],
          [-2.5401140, 55.1123770],
          [-2.5401630, 55.1123660],
          [-2.5402160, 55.1123550],
          [-2.5402690, 55.1123390],
          [-2.5403220, 55.1123240],
          [-2.5403790, 55.1123090],
          [-2.5404360, 55.1122940],
          [-2.5404930, 55.1122790],
          [-2.5405510, 55.1122590],
          [-2.5406070, 55.1122410],
          [-2.5406690, 55.1122290],
          [-2.5407300, 55.1122130],
          [-2.5407870, 55.1122020],
          [-2.5408440, 55.1121870],
          [-2.5409030, 55.1121790],
          [-2.5409600, 55.1121640],
          [-2.5410160, 55.1121560],
          [-2.5410720, 55.1121490],
          [-2.5411250, 55.1121410],
          [-2.5411780, 55.1121370],
          [-2.5412300, 55.1121290],
          [-2.5412790, 55.1121220],
          [-2.5413270, 55.1121140],
          [-2.5413760, 55.1121060],
          [-2.5414250, 55.1120990],
          [-2.5414780, 55.1120950],
          [-2.5415270, 55.1120910],
          [-2.5415790, 55.1120840],
          [-2.5416290, 55.1120800],
          [-2.5416840, 55.1120760],
          [-2.5417370, 55.1120680],
          [-2.5417920, 55.1120610],
          [-2.5418460, 55.1120570],
          [-2.5419020, 55.1120460],
          [-2.5419580, 55.1120420],
          [-2.5420120, 55.1120340],
          [-2.5420710, 55.1120300],
          [-2.5421260, 55.1120230],
          [-2.5421810, 55.1120150],
          [-2.5422330, 55.1120070],
          [-2.5422850, 55.1120030],
          [-2.5423410, 55.1119960],
          [-2.5423990, 55.1119880],
          [-2.5424550, 55.1119810],
          [-2.5425110, 55.1119770],
          [-2.5425650, 55.1119660],
          [-2.5426220, 55.1119580],
          [-2.5426790, 55.1119500],
          [-2.5427370, 55.1119390],
          [-2.5427940, 55.1119310],
          [-2.5428520, 55.1119200],
          [-2.5429110, 55.1119080],
          [-2.5429670, 55.1118970],
          [-2.5430260, 55.1118850],
          [-2.5430860, 55.1118700],
          [-2.5431470, 55.1118550],
          [-2.5432070, 55.1118400],
          [-2.5432640, 55.1118240],
          [-2.5433150, 55.1118050],
          [-2.5433690, 55.1117900],
          [-2.5434220, 55.1117750],
          [-2.5434770, 55.1117560],
          [-2.5435300, 55.1117400],
          [-2.5435860, 55.1117250],
          [-2.5436420, 55.1117100],
          [-2.5437010, 55.1116950],
          [-2.5437580, 55.1116760],
          [-2.5438120, 55.1116600],
          [-2.5438720, 55.1116410],
          [-2.5439340, 55.1116220],
          [-2.5439970, 55.1116030],
          [-2.5440600, 55.1115840],
          [-2.5441260, 55.1115690],
          [-2.5441920, 55.1115540],
          [-2.5442600, 55.1115380],
          [-2.5443300, 55.1115230],
          [-2.5444000, 55.1115120],
          [-2.5444700, 55.1115000],
          [-2.5445440, 55.1114890],
          [-2.5446150, 55.1114770],
          [-2.5446850, 55.1114690],
          [-2.5447540, 55.1114620],
          [-2.5448270, 55.1114500],
          [-2.5448970, 55.1114430],
          [-2.5449690, 55.1114350],
          [-2.5450420, 55.1114270],
          [-2.5451150, 55.1114200],
          [-2.5451880, 55.1114120],
          [-2.5452630, 55.1114010],
          [-2.5453380, 55.1113930],
          [-2.5454160, 55.1113820],
          [-2.5454950, 55.1113740],
          [-2.5455730, 55.1113630],
          [-2.5456530, 55.1113550],
          [-2.5457350, 55.1113440],
          [-2.5458170, 55.1113320],
          [-2.5458970, 55.1113210],
          [-2.5459820, 55.1113090],
          [-2.5460680, 55.1112940],
          [-2.5461620, 55.1112830],
          [-2.5462600, 55.1112670],
          [-2.5463620, 55.1112560],
          [-2.5464640, 55.1112410],
          [-2.5465730, 55.1112220],
          [-2.5466780, 55.1112030],
          [-2.5467860, 55.1111870],
          [-2.5468910, 55.1111680],
          [-2.5469970, 55.1111490],
          [-2.5471050, 55.1111300],
          [-2.5472080, 55.1111150],
          [-2.5473130, 55.1110960],
          [-2.5474150, 55.1110800],
          [-2.5475170, 55.1110650],
          [-2.5476180, 55.1110500],
          [-2.5477190, 55.1110350],
          [-2.5478180, 55.1110200],
          [-2.5479230, 55.1110080],
          [-2.5480220, 55.1109930],
          [-2.5481250, 55.1109810],
          [-2.5482250, 55.1109730],
          [-2.5483190, 55.1109580],
          [-2.5484080, 55.1109510],
          [-2.5484910, 55.1109400],
          [-2.5485780, 55.1109320],
          [-2.5486620, 55.1109200],
          [-2.5487440, 55.1109120],
          [-2.5488220, 55.1109050],
          [-2.5488970, 55.1108970],
          [-2.5489740, 55.1108900],
          [-2.5490500, 55.1108780],
          [-2.5491260, 55.1108710],
          [-2.5492020, 55.1108630],
          [-2.5492760, 55.1108550],
          [-2.5493460, 55.1108480],
          [-2.5494180, 55.1108400],
          [-2.5494890, 55.1108290],
          [-2.5495580, 55.1108210],
          [-2.5496270, 55.1108130],
          [-2.5496930, 55.1108060],
          [-2.5497510, 55.1107980],
          [-2.5498020, 55.1107900],
          [-2.5498530, 55.1107870],
          [-2.5498990, 55.1107790],
          [-2.5499390, 55.1107750],
          [-2.5499760, 55.1107710],
          [-2.5500120, 55.1107680],
          [-2.5500490, 55.1107640],
          [-2.5500850, 55.1107600],
          [-2.5501210, 55.1107560],
          [-2.5501550, 55.1107490],
          [-2.5501930, 55.1107450],
          [-2.5502310, 55.1107410],
          [-2.5502730, 55.1107370],
          [-2.5503140, 55.1107260],
          [-2.5503570, 55.1107180],
          [-2.5504010, 55.1107100],
          [-2.5504460, 55.1107030],
          [-2.5504890, 55.1106950],
          [-2.5505330, 55.1106840],
          [-2.5505740, 55.1106760],
          [-2.5506160, 55.1106650],
          [-2.5506570, 55.1106570],
          [-2.5507010, 55.1106450],
          [-2.5507440, 55.1106340],
          [-2.5507860, 55.1106190],
          [-2.5508310, 55.1106040],
          [-2.5508770, 55.1105850],
          [-2.5509290, 55.1105690],
          [-2.5509840, 55.1105500],
          [-2.5510350, 55.1105350],
          [-2.5510860, 55.1105160],
          [-2.5511360, 55.1104930],
          [-2.5511860, 55.1104700],
          [-2.5512320, 55.1104510],
          [-2.5512770, 55.1104280],
          [-2.5513230, 55.1104050],
          [-2.5513670, 55.1103830],
          [-2.5514140, 55.1103600],
          [-2.5514590, 55.1103370],
          [-2.5515040, 55.1103140],
          [-2.5515480, 55.1102870],
          [-2.5515890, 55.1102640],
          [-2.5516310, 55.1102410],
          [-2.5516740, 55.1102180],
          [-2.5517150, 55.1101960],
          [-2.5517590, 55.1101730],
          [-2.5518030, 55.1101460],
          [-2.5518470, 55.1101230],
          [-2.5518890, 55.1100970],
          [-2.5519280, 55.1100700],
          [-2.5519740, 55.1100430],
          [-2.5520220, 55.1100200],
          [-2.5520690, 55.1099940],
          [-2.5521120, 55.1099670],
          [-2.5521610, 55.1099440],
          [-2.5522110, 55.1099210],
          [-2.5522610, 55.1098980],
          [-2.5522850, 55.1098730],
          [-2.5523010, 55.1098340],
          [-2.5523350, 55.1097840],
          [-2.5523900, 55.1097570],
          [-2.5524450, 55.1097380],
          [-2.5524870, 55.1097110],
          [-2.5525360, 55.1096770],
          [-2.5525730, 55.1096500],
          [-2.5526210, 55.1096230],
          [-2.5526680, 55.1096000],
          [-2.5527210, 55.1095780],
          [-2.5527690, 55.1095550],
          [-2.5528160, 55.1095320],
          [-2.5528610, 55.1095130],
          [-2.5529060, 55.1094940],
          [-2.5529560, 55.1094750],
          [-2.5530060, 55.1094590],
          [-2.5530560, 55.1094400],
          [-2.5531080, 55.1094210],
          [-2.5531570, 55.1094020],
          [-2.5532030, 55.1093870],
          [-2.5532540, 55.1093680],
          [-2.5532990, 55.1093490],
          [-2.5533480, 55.1093300],
          [-2.5533990, 55.1093100],
          [-2.5534490, 55.1092950],
          [-2.5534990, 55.1092720],
          [-2.5535490, 55.1092530],
          [-2.5536000, 55.1092300],
          [-2.5536480, 55.1092120],
          [-2.5536990, 55.1091880],
          [-2.5537480, 55.1091650],
          [-2.5537960, 55.1091390],
          [-2.5538440, 55.1091120],
          [-2.5538900, 55.1090820],
          [-2.5539310, 55.1090520],
          [-2.5539760, 55.1090250],
          [-2.5540160, 55.1089980],
          [-2.5540540, 55.1089670],
          [-2.5540930, 55.1089330],
          [-2.5541310, 55.1088990],
          [-2.5541720, 55.1088650],
          [-2.5542160, 55.1088260],
          [-2.5542620, 55.1087920],
          [-2.5543110, 55.1087580],
          [-2.5543610, 55.1087230],
          [-2.5544130, 55.1086890],
          [-2.5544630, 55.1086510],
          [-2.5545120, 55.1086130],
          [-2.5545660, 55.1085780],
          [-2.5546200, 55.1085410],
          [-2.5546780, 55.1085060],
          [-2.5547340, 55.1084670],
          [-2.5547890, 55.1084290],
          [-2.5548510, 55.1083870],
          [-2.5549140, 55.1083460],
          [-2.5549810, 55.1083070],
          [-2.5550530, 55.1082660],
          [-2.5551360, 55.1082160],
          [-2.5552220, 55.1081700],
          [-2.5553180, 55.1081210],
          [-2.5554250, 55.1080680],
          [-2.5555390, 55.1080100],
          [-2.5556630, 55.1079570],
          [-2.5557960, 55.1079040],
          [-2.5559360, 55.1078500],
          [-2.5560870, 55.1077960],
          [-2.5562440, 55.1077470],
          [-2.5564090, 55.1076970],
          [-2.5565780, 55.1076550],
          [-2.5567500, 55.1076170],
          [-2.5569200, 55.1075790],
          [-2.5570810, 55.1075470],
          [-2.5572670, 55.1075210],
          [-2.5574470, 55.1074990],
          [-2.5576390, 55.1074790],
          [-2.5578350, 55.1074640],
          [-2.5580390, 55.1074530],
          [-2.5582570, 55.1074370],
          [-2.5584710, 55.1074220],
          [-2.5586770, 55.1074070],
          [-2.5588860, 55.1073880],
          [-2.5591030, 55.1073540],
          [-2.5593230, 55.1073190],
          [-2.5595410, 55.1072740],
          [-2.5597630, 55.1072240],
          [-2.5599800, 55.1071710],
          [-2.5601890, 55.1071210],
          [-2.5604000, 55.1070720],
          [-2.5606140, 55.1070220],
          [-2.5608280, 55.1069690],
          [-2.5610380, 55.1069120],
          [-2.5612430, 55.1068510],
          [-2.5614460, 55.1067780],
          [-2.5616420, 55.1066980],
          [-2.5618280, 55.1066150],
          [-2.5620080, 55.1065150],
          [-2.5621780, 55.1064250],
          [-2.5623420, 55.1063330],
          [-2.5625110, 55.1062440],
          [-2.5626780, 55.1061530],
          [-2.5628440, 55.1060650],
          [-2.5630030, 55.1059770],
          [-2.5631570, 55.1058910],
          [-2.5633110, 55.1057980],
          [-2.5634610, 55.1057110],
          [-2.5636100, 55.1056200],
          [-2.5637610, 55.1055270],
          [-2.5639090, 55.1054400],
          [-2.5640590, 55.1053480],
          [-2.5642080, 55.1052560],
          [-2.5643520, 55.1051660],
          [-2.5644950, 55.1050820],
          [-2.5646430, 55.1050080],
          [-2.5647780, 55.1049400],
          [-2.5648960, 55.1048670],
          [-2.5649920, 55.1047870],
          [-2.5650620, 55.1047000],
          [-2.5650980, 55.1046040],
          [-2.5651060, 55.1045050],
          [-2.5651070, 55.1044060],
          [-2.5651050, 55.1043170],
          [-2.5651030, 55.1042230],
          [-2.5650970, 55.1041310],
          [-2.5650830, 55.1040430],
          [-2.5650670, 55.1039590],
          [-2.5650460, 55.1038720],
          [-2.5650200, 55.1037880],
          [-2.5649920, 55.1036960],
          [-2.5649620, 55.1036120],
          [-2.5649310, 55.1035220],
          [-2.5648920, 55.1034290],
          [-2.5648510, 55.1033380],
          [-2.5648060, 55.1032460],
          [-2.5647570, 55.1031510],
          [-2.5647060, 55.1030620],
          [-2.5646480, 55.1029720],
          [-2.5645880, 55.1028840],
          [-2.5645210, 55.1028030],
          [-2.5644500, 55.1027240],
          [-2.5643660, 55.1026510],
          [-2.5642700, 55.1025860],
          [-2.5641620, 55.1025230],
          [-2.5640350, 55.1024670],
          [-2.5639030, 55.1024210],
          [-2.5637620, 55.1023870],
          [-2.5636140, 55.1023560],
          [-2.5634650, 55.1023340],
          [-2.5633120, 55.1023180],
          [-2.5631680, 55.1023030],
          [-2.5630220, 55.1022870],
          [-2.5628770, 55.1022800],
          [-2.5627380, 55.1022690],
          [-2.5625990, 55.1022570],
          [-2.5624600, 55.1022450],
          [-2.5623240, 55.1022380],
          [-2.5621880, 55.1022340],
          [-2.5620560, 55.1022300],
          [-2.5619250, 55.1022260],
          [-2.5617880, 55.1022220],
          [-2.5616470, 55.1022190],
          [-2.5614990, 55.1022150],
          [-2.5613490, 55.1022110],
          [-2.5611930, 55.1022070],
          [-2.5610400, 55.1022030],
          [-2.5608790, 55.1022000],
          [-2.5607140, 55.1021880],
          [-2.5605470, 55.1021730],
          [-2.5603770, 55.1021500],
          [-2.5602200, 55.1021160],
          [-2.5600760, 55.1020740],
          [-2.5599470, 55.1020290],
          [-2.5598200, 55.1019720],
          [-2.5596930, 55.1019180],
          [-2.5595670, 55.1018610],
          [-2.5594390, 55.1018000],
          [-2.5593070, 55.1017430],
          [-2.5591730, 55.1016890],
          [-2.5590250, 55.1016360],
          [-2.5588710, 55.1015970],
          [-2.5587070, 55.1015590],
          [-2.5585430, 55.1015320],
          [-2.5583730, 55.1015130],
          [-2.5582050, 55.1014900],
          [-2.5580360, 55.1014710],
          [-2.5578660, 55.1014490],
          [-2.5576990, 55.1014270],
          [-2.5575230, 55.1014070],
          [-2.5573540, 55.1013840],
          [-2.5571860, 55.1013610],
          [-2.5570250, 55.1013380],
          [-2.5568680, 55.1013190],
          [-2.5567130, 55.1012960],
          [-2.5565620, 55.1012770],
          [-2.5564130, 55.1012540],
          [-2.5562670, 55.1012350],
          [-2.5561160, 55.1012120],
          [-2.5559680, 55.1011890],
          [-2.5558220, 55.1011670],
          [-2.5556690, 55.1011430],
          [-2.5555230, 55.1011170],
          [-2.5553960, 55.1010900],
          [-2.5552380, 55.1010560],
          [-2.5550960, 55.1010140],
          [-2.5549600, 55.1009760],
          [-2.5548240, 55.1009380],
          [-2.5546910, 55.1008930],
          [-2.5545510, 55.1008500],
          [-2.5544170, 55.1008090],
          [-2.5542770, 55.1007700],
          [-2.5541410, 55.1007320],
          [-2.5540080, 55.1006940],
          [-2.5538790, 55.1006600],
          [-2.5537540, 55.1006250],
          [-2.5536330, 55.1005910],
          [-2.5535160, 55.1005570],
          [-2.5533960, 55.1005260],
          [-2.5532810, 55.1004950],
          [-2.5531640, 55.1004610],
          [-2.5530450, 55.1004300],
          [-2.5529240, 55.1003960],
          [-2.5527990, 55.1003620],
          [-2.5526700, 55.1003270],
          [-2.5525390, 55.1002890],
          [-2.5524040, 55.1002510],
          [-2.5522710, 55.1002090],
          [-2.5521400, 55.1001710],
          [-2.5520190, 55.1001330],
          [-2.5519050, 55.1000940],
          [-2.5518000, 55.1000560],
          [-2.5516980, 55.1000220],
          [-2.5516020, 55.0999840],
          [-2.5515000, 55.0999530],
          [-2.5513950, 55.0999190],
          [-2.5512910, 55.0998810],
          [-2.5511900, 55.0998460],
          [-2.5510870, 55.0998120],
          [-2.5509840, 55.0997820],
          [-2.5508790, 55.0997470],
          [-2.5507810, 55.0997170],
          [-2.5506830, 55.0996820],
          [-2.5505880, 55.0996560],
          [-2.5504970, 55.0996260],
          [-2.5504020, 55.0995990],
          [-2.5503080, 55.0995720],
          [-2.5502170, 55.0995450],
          [-2.5501260, 55.0995220],
          [-2.5500390, 55.0994960],
          [-2.5499510, 55.0994720],
          [-2.5498630, 55.0994500],
          [-2.5497760, 55.0994270],
          [-2.5496880, 55.0994040],
          [-2.5495980, 55.0993770],
          [-2.5495060, 55.0993500],
          [-2.5494150, 55.0993240],
          [-2.5493210, 55.0992930],
          [-2.5492250, 55.0992630],
          [-2.5491300, 55.0992320],
          [-2.5490340, 55.0992020],
          [-2.5489350, 55.0991750],
          [-2.5488420, 55.0991440],
          [-2.5487500, 55.0991140],
          [-2.5486620, 55.0990870],
          [-2.5485730, 55.0990570],
          [-2.5484860, 55.0990260],
          [-2.5484060, 55.0989960],
          [-2.5483280, 55.0989620],
          [-2.5482560, 55.0989240],
          [-2.5481790, 55.0988850],
          [-2.5481010, 55.0988400],
          [-2.5480160, 55.0987820],
          [-2.5479350, 55.0987290],
          [-2.5478580, 55.0986720],
          [-2.5477890, 55.0986150],
          [-2.5477260, 55.0985610],
          [-2.5476690, 55.0985120],
          [-2.5476150, 55.0984660],
          [-2.5475610, 55.0984160],
          [-2.5475110, 55.0983740],
          [-2.5474620, 55.0983250],
          [-2.5474130, 55.0982790],
          [-2.5473590, 55.0982290],
          [-2.5473090, 55.0981830],
          [-2.5472590, 55.0981380],
          [-2.5472090, 55.0980920],
          [-2.5471610, 55.0980460],
          [-2.5471140, 55.0980040],
          [-2.5470660, 55.0979590],
          [-2.5470190, 55.0979120],
          [-2.5469700, 55.0978700],
          [-2.5469260, 55.0978220],
          [-2.5468820, 55.0977710],
          [-2.5468360, 55.0977260],
          [-2.5467890, 55.0976760],
          [-2.5467440, 55.0976300],
          [-2.5466990, 55.0975770],
          [-2.5466560, 55.0975240],
          [-2.5466120, 55.0974750],
          [-2.5465690, 55.0974200],
          [-2.5465260, 55.0973670],
          [-2.5464840, 55.0973140],
          [-2.5464400, 55.0972600],
          [-2.5463980, 55.0972070],
          [-2.5463570, 55.0971500],
          [-2.5463160, 55.0970930],
          [-2.5462740, 55.0970390],
          [-2.5462370, 55.0969820],
          [-2.5462030, 55.0969260],
          [-2.5461690, 55.0968710],
          [-2.5461360, 55.0968140],
          [-2.5461020, 55.0967570],
          [-2.5460690, 55.0966960],
          [-2.5460410, 55.0966310],
          [-2.5460090, 55.0965660],
          [-2.5459770, 55.0965010],
          [-2.5459490, 55.0964330],
          [-2.5459190, 55.0963680],
          [-2.5458950, 55.0962990],
          [-2.5458720, 55.0962270],
          [-2.5458490, 55.0961580],
          [-2.5458240, 55.0960860],
          [-2.5457990, 55.0960100],
          [-2.5457730, 55.0959360],
          [-2.5457420, 55.0958540],
          [-2.5457110, 55.0957690],
          [-2.5456740, 55.0956810],
          [-2.5456340, 55.0955940],
          [-2.5455910, 55.0955080],
          [-2.5455440, 55.0954110],
          [-2.5454940, 55.0953160],
          [-2.5454410, 55.0952170],
          [-2.5453860, 55.0951210],
          [-2.5453310, 55.0950180],
          [-2.5452760, 55.0949150],
          [-2.5452190, 55.0948090],
          [-2.5451640, 55.0947050],
          [-2.5451090, 55.0946030],
          [-2.5450510, 55.0945000],
          [-2.5449840, 55.0943910],
          [-2.5449060, 55.0942780],
          [-2.5448200, 55.0941600],
          [-2.5447270, 55.0940380],
          [-2.5446330, 55.0939120],
          [-2.5445430, 55.0937830],
          [-2.5444550, 55.0936530],
          [-2.5443710, 55.0935270],
          [-2.5442930, 55.0934070],
          [-2.5442130, 55.0932900],
          [-2.5441340, 55.0931670],
          [-2.5440570, 55.0930530],
          [-2.5439850, 55.0929440],
          [-2.5439130, 55.0928290],
          [-2.5438390, 55.0927180],
          [-2.5437630, 55.0926080],
          [-2.5436870, 55.0924980],
          [-2.5436120, 55.0923790],
          [-2.5435410, 55.0922640],
          [-2.5434800, 55.0921540],
          [-2.5434180, 55.0920350],
          [-2.5433590, 55.0919210],
          [-2.5433040, 55.0918030],
          [-2.5432510, 55.0916800],
          [-2.5432020, 55.0915620],
          [-2.5431560, 55.0914370],
          [-2.5431170, 55.0913070],
          [-2.5430810, 55.0911810],
          [-2.5430460, 55.0910480],
          [-2.5430120, 55.0909140],
          [-2.5429850, 55.0907840],
          [-2.5429620, 55.0906660],
          [-2.5429550, 55.0905550],
          [-2.5429570, 55.0904520],
          [-2.5429640, 55.0903630],
          [-2.5429780, 55.0902760],
          [-2.5429900, 55.0901930],
          [-2.5430000, 55.0901160],
          [-2.5430050, 55.0900390],
          [-2.5430100, 55.0899600],
          [-2.5430120, 55.0898790],
          [-2.5430170, 55.0898030],
          [-2.5430180, 55.0897270],
          [-2.5430120, 55.0896520],
          [-2.5429900, 55.0895750],
          [-2.5429640, 55.0894990],
          [-2.5429290, 55.0894190],
          [-2.5428840, 55.0893300],
          [-2.5428240, 55.0892460],
          [-2.5427540, 55.0891670],
          [-2.5426720, 55.0890870],
          [-2.5425790, 55.0890110],
          [-2.5424690, 55.0889370],
          [-2.5423390, 55.0888840],
          [-2.5422030, 55.0888460],
          [-2.5420640, 55.0888260],
          [-2.5419290, 55.0888110],
          [-2.5417970, 55.0888030],
          [-2.5416740, 55.0887990],
          [-2.5415600, 55.0887990],
          [-2.5414470, 55.0887990],
          [-2.5413440, 55.0887950],
          [-2.5412400, 55.0887910],
          [-2.5411460, 55.0887870],
          [-2.5410530, 55.0887910],
          [-2.5409650, 55.0887910],
          [-2.5408750, 55.0887910],
          [-2.5407880, 55.0887870],
          [-2.5407060, 55.0887910],
          [-2.5406270, 55.0887910],
          [-2.5405520, 55.0887950],
          [-2.5404700, 55.0887950],
          [-2.5403960, 55.0887950],
          [-2.5403200, 55.0887980],
          [-2.5402450, 55.0888020],
          [-2.5401710, 55.0888060],
          [-2.5400980, 55.0888100],
          [-2.5400260, 55.0888100],
          [-2.5399540, 55.0888140],
          [-2.5398800, 55.0888170],
          [-2.5398030, 55.0888210],
          [-2.5397300, 55.0888250],
          [-2.5396580, 55.0888290],
          [-2.5395860, 55.0888330],
          [-2.5395170, 55.0888370],
          [-2.5394450, 55.0888370],
          [-2.5393750, 55.0888400],
          [-2.5393060, 55.0888440],
          [-2.5392330, 55.0888480],
          [-2.5391640, 55.0888520],
          [-2.5390850, 55.0888560],
          [-2.5390110, 55.0888630],
          [-2.5389360, 55.0888630],
          [-2.5388650, 55.0888630],
          [-2.5387950, 55.0888670],
          [-2.5387210, 55.0888670],
          [-2.5386510, 55.0888710],
          [-2.5385840, 55.0888750],
          [-2.5385110, 55.0888750],
          [-2.5384410, 55.0888750],
          [-2.5383710, 55.0888710],
          [-2.5383000, 55.0888670],
          [-2.5382320, 55.0888630],
          [-2.5381670, 55.0888630],
          [-2.5380940, 55.0888600],
          [-2.5380230, 55.0888560],
          [-2.5379590, 55.0888490],
          [-2.5378810, 55.0888440],
          [-2.5378130, 55.0888370],
          [-2.5377430, 55.0888330],
          [-2.5376710, 55.0888250],
          [-2.5376010, 55.0888220],
          [-2.5375370, 55.0888150],
          [-2.5374620, 55.0888060],
          [-2.5373920, 55.0887990],
          [-2.5373230, 55.0887950],
          [-2.5372450, 55.0887910],
          [-2.5371690, 55.0887840],
          [-2.5371000, 55.0887800],
          [-2.5370170, 55.0887720],
          [-2.5369420, 55.0887720],
          [-2.5368740, 55.0887680],
          [-2.5367960, 55.0887720],
          [-2.5367250, 55.0887720],
          [-2.5366530, 55.0887750],
          [-2.5365740, 55.0887760],
          [-2.5365020, 55.0887790],
          [-2.5364350, 55.0887830],
          [-2.5363570, 55.0887830],
          [-2.5362820, 55.0887870],
          [-2.5362140, 55.0887910],
          [-2.5361390, 55.0887950],
          [-2.5360650, 55.0887980],
          [-2.5359950, 55.0887990],
          [-2.5359180, 55.0887990],
          [-2.5358460, 55.0888020],
          [-2.5357790, 55.0888060],
          [-2.5357000, 55.0888060],
          [-2.5356290, 55.0888020],
          [-2.5355670, 55.0887990],
          [-2.5354870, 55.0887910],
          [-2.5354150, 55.0887840],
          [-2.5353480, 55.0887770],
          [-2.5352670, 55.0887720],
          [-2.5351930, 55.0887650],
          [-2.5351290, 55.0887580],
          [-2.5350460, 55.0887490],
          [-2.5349720, 55.0887410],
          [-2.5349050, 55.0887340],
          [-2.5348320, 55.0887300],
          [-2.5347590, 55.0887230],
          [-2.5346990, 55.0887160],
          [-2.5346280, 55.0887110],
          [-2.5345590, 55.0887110],
          [-2.5345000, 55.0887110],
          [-2.5344320, 55.0887000],
          [-2.5343710, 55.0886920],
          [-2.5343070, 55.0886840],
          [-2.5342430, 55.0886770],
          [-2.5341860, 55.0886690],
          [-2.5341230, 55.0886610],
          [-2.5340590, 55.0886540],
          [-2.5339930, 55.0886420],
          [-2.5339280, 55.0886310],
          [-2.5338610, 55.0886160],
          [-2.5337910, 55.0886010],
          [-2.5337170, 55.0885890],
          [-2.5336430, 55.0885780],
          [-2.5335770, 55.0885630],
          [-2.5335080, 55.0885480],
          [-2.5334380, 55.0885290],
          [-2.5333670, 55.0885170],
          [-2.5332960, 55.0885050],
          [-2.5332270, 55.0884930],
          [-2.5331580, 55.0884820],
          [-2.5330910, 55.0884710],
          [-2.5330230, 55.0884590],
          [-2.5329610, 55.0884520],
          [-2.5328860, 55.0884430],
          [-2.5328080, 55.0884330],
          [-2.5327350, 55.0884210],
          [-2.5326590, 55.0884100],
          [-2.5325830, 55.0883980],
          [-2.5325050, 55.0883870],
          [-2.5324250, 55.0883750],
          [-2.5323440, 55.0883600],
          [-2.5322640, 55.0883450],
          [-2.5321830, 55.0883300],
          [-2.5321000, 55.0883140],
          [-2.5320170, 55.0882990],
          [-2.5319270, 55.0882840],
          [-2.5318340, 55.0882650],
          [-2.5317370, 55.0882490],
          [-2.5316370, 55.0882300],
          [-2.5315320, 55.0882150],
          [-2.5314270, 55.0882020],
          [-2.5313220, 55.0881880],
          [-2.5312110, 55.0881810],
          [-2.5311050, 55.0881730],
          [-2.5310070, 55.0881690],
          [-2.5309130, 55.0881690],
          [-2.5308280, 55.0881730],
          [-2.5307470, 55.0881800],
          [-2.5306680, 55.0881810],
          [-2.5305940, 55.0881820],
          [-2.5305210, 55.0881840],
          [-2.5304460, 55.0881810],
          [-2.5303740, 55.0881770],
          [-2.5303010, 55.0881730],
          [-2.5302250, 55.0881620],
          [-2.5301460, 55.0881540],
          [-2.5300680, 55.0881430],
          [-2.5299910, 55.0881310],
          [-2.5299120, 55.0881200],
          [-2.5298310, 55.0881050],
          [-2.5297470, 55.0880850],
          [-2.5296600, 55.0880660],
          [-2.5295730, 55.0880470],
          [-2.5294870, 55.0880280],
          [-2.5294000, 55.0880020],
          [-2.5293100, 55.0879790],
          [-2.5292200, 55.0879560],
          [-2.5291300, 55.0879330],
          [-2.5290370, 55.0879140],
          [-2.5289380, 55.0879020],
          [-2.5288420, 55.0879060],
          [-2.5287530, 55.0879170],
          [-2.5286780, 55.0879400],
          [-2.5286150, 55.0879590],
          [-2.5285580, 55.0879780],
          [-2.5285080, 55.0880010],
          [-2.5284630, 55.0880200],
          [-2.5284230, 55.0880390],
          [-2.5283860, 55.0880620],
          [-2.5283510, 55.0880810],
          [-2.5283140, 55.0881000],
          [-2.5282770, 55.0881190],
          [-2.5282410, 55.0881380],
          [-2.5282070, 55.0881570],
          [-2.5281710, 55.0881730],
          [-2.5281380, 55.0881880],
          [-2.5281060, 55.0882070],
          [-2.5280760, 55.0882260],
          [-2.5280380, 55.0882410],
          [-2.5280020, 55.0882600],
          [-2.5279640, 55.0882790],
          [-2.5279270, 55.0882950],
          [-2.5278910, 55.0883130],
          [-2.5278590, 55.0883330],
          [-2.5278240, 55.0883480],
          [-2.5277890, 55.0883630],
          [-2.5277560, 55.0883820],
          [-2.5277240, 55.0884010],
          [-2.5276920, 55.0884170],
          [-2.5276620, 55.0884390],
          [-2.5276290, 55.0884550],
          [-2.5275950, 55.0884700],
          [-2.5275560, 55.0884850],
          [-2.5275110, 55.0885010],
          [-2.5274670, 55.0885120],
          [-2.5274180, 55.0885200],
          [-2.5273660, 55.0885310],
          [-2.5273060, 55.0885430],
          [-2.5272430, 55.0885540],
          [-2.5271730, 55.0885690],
          [-2.5270970, 55.0885770],
          [-2.5270160, 55.0885880],
          [-2.5269330, 55.0886000],
          [-2.5268490, 55.0886110],
          [-2.5267560, 55.0886230],
          [-2.5266640, 55.0886340],
          [-2.5265690, 55.0886460],
          [-2.5264700, 55.0886610],
          [-2.5263720, 55.0886720],
          [-2.5262700, 55.0886880],
          [-2.5261640, 55.0886950],
          [-2.5260570, 55.0887110],
          [-2.5259470, 55.0887220],
          [-2.5258340, 55.0887370],
          [-2.5257170, 55.0887490],
          [-2.5255970, 55.0887640],
          [-2.5254740, 55.0887750],
          [-2.5253500, 55.0887870],
          [-2.5252210, 55.0888020],
          [-2.5250960, 55.0888170],
          [-2.5249680, 55.0888290],
          [-2.5248420, 55.0888440],
          [-2.5247090, 55.0888560],
          [-2.5245790, 55.0888670],
          [-2.5244500, 55.0888780],
          [-2.5243270, 55.0888900],
          [-2.5242040, 55.0889010],
          [-2.5240820, 55.0889090],
          [-2.5239600, 55.0889170],
          [-2.5238440, 55.0889280],
          [-2.5237300, 55.0889360],
          [-2.5236190, 55.0889430],
          [-2.5235050, 55.0889550],
          [-2.5233980, 55.0889620],
          [-2.5232920, 55.0889740],
          [-2.5231850, 55.0889850],
          [-2.5230770, 55.0889970],
          [-2.5229690, 55.0890080],
          [-2.5228590, 55.0890190],
          [-2.5227510, 55.0890270],
          [-2.5226430, 55.0890350],
          [-2.5225350, 55.0890460],
          [-2.5224320, 55.0890610],
          [-2.5223300, 55.0890730],
          [-2.5222300, 55.0890840],
          [-2.5221300, 55.0890920],
          [-2.5220340, 55.0891070],
          [-2.5219420, 55.0891150],
          [-2.5218530, 55.0891260],
          [-2.5217600, 55.0891340],
          [-2.5216650, 55.0891420],
          [-2.5215730, 55.0891570],
          [-2.5214780, 55.0891680],
          [-2.5213800, 55.0891840],
          [-2.5212820, 55.0891950],
          [-2.5211840, 55.0892100],
          [-2.5210830, 55.0892260],
          [-2.5209820, 55.0892410],
          [-2.5208830, 55.0892520],
          [-2.5207820, 55.0892640],
          [-2.5206850, 55.0892790],
          [-2.5205870, 55.0892940],
          [-2.5204900, 55.0893090],
          [-2.5203920, 55.0893250],
          [-2.5202930, 55.0893360],
          [-2.5201950, 55.0893480],
          [-2.5200980, 55.0893550],
          [-2.5199940, 55.0893590],
          [-2.5198890, 55.0893630],
          [-2.5197820, 55.0893710],
          [-2.5196760, 55.0893740],
          [-2.5195670, 55.0893750],
          [-2.5194600, 55.0893750],
          [-2.5193570, 55.0893710],
          [-2.5192500, 55.0893710],
          [-2.5191480, 55.0893670],
          [-2.5190500, 55.0893670],
          [-2.5189560, 55.0893630],
          [-2.5188580, 55.0893590],
          [-2.5187610, 55.0893560],
          [-2.5186680, 55.0893520],
          [-2.5185740, 55.0893520],
          [-2.5184780, 55.0893520],
          [-2.5183820, 55.0893550],
          [-2.5182870, 55.0893630],
          [-2.5181890, 55.0893670],
          [-2.5180900, 55.0893740],
          [-2.5179950, 55.0893820],
          [-2.5178980, 55.0893970],
          [-2.5178020, 55.0894120],
          [-2.5177090, 55.0894240],
          [-2.5176120, 55.0894390],
          [-2.5175170, 55.0894510],
          [-2.5174200, 55.0894620],
          [-2.5173250, 55.0894700],
          [-2.5172300, 55.0894850],
          [-2.5171320, 55.0894960],
          [-2.5170350, 55.0895040],
          [-2.5169320, 55.0895120],
          [-2.5168290, 55.0895230],
          [-2.5167250, 55.0895270],
          [-2.5166200, 55.0895270],
          [-2.5165150, 55.0895270],
          [-2.5164090, 55.0895270],
          [-2.5162990, 55.0895230],
          [-2.5161890, 55.0895200],
          [-2.5160830, 55.0895160],
          [-2.5159700, 55.0895120],
          [-2.5158610, 55.0895040],
          [-2.5157520, 55.0895010],
          [-2.5156420, 55.0894930],
          [-2.5155300, 55.0894850],
          [-2.5154170, 55.0894780],
          [-2.5153040, 55.0894700],
          [-2.5151880, 55.0894660],
          [-2.5150740, 55.0894590],
          [-2.5149590, 55.0894510],
          [-2.5148450, 55.0894430],
          [-2.5147370, 55.0894430],
          [-2.5146300, 55.0894390],
          [-2.5145240, 55.0894390],
          [-2.5144130, 55.0894430],
          [-2.5143050, 55.0894540],
          [-2.5141970, 55.0894700],
          [-2.5140850, 55.0894850],
          [-2.5139700, 55.0895080],
          [-2.5138590, 55.0895300],
          [-2.5137500, 55.0895500],
          [-2.5136520, 55.0895760],
          [-2.5135600, 55.0896030],
          [-2.5134780, 55.0896330],
          [-2.5134030, 55.0896600],
          [-2.5133380, 55.0896870],
          [-2.5132830, 55.0897170],
          [-2.5132330, 55.0897440],
          [-2.5131910, 55.0897670],
          [-2.5131510, 55.0897900],
          [-2.5131140, 55.0898130],
          [-2.5130810, 55.0898320],
          [-2.5130460, 55.0898510],
          [-2.5130090, 55.0898740],
          [-2.5129740, 55.0899000],
          [-2.5129390, 55.0899230],
          [-2.5129030, 55.0899500],
          [-2.5128620, 55.0899760],
          [-2.5128240, 55.0899990],
          [-2.5127820, 55.0900260],
          [-2.5127440, 55.0900530],
          [-2.5127060, 55.0900790],
          [-2.5126670, 55.0901060],
          [-2.5126310, 55.0901330],
          [-2.5125920, 55.0901560],
          [-2.5125560, 55.0901820],
          [-2.5125190, 55.0902090],
          [-2.5124840, 55.0902360],
          [-2.5124510, 55.0902630],
          [-2.5124180, 55.0902890],
          [-2.5123840, 55.0903160],
          [-2.5123520, 55.0903430],
          [-2.5123210, 55.0903700],
          [-2.5122940, 55.0903960],
          [-2.5122640, 55.0904230],
          [-2.5122270, 55.0904500],
          [-2.5121910, 55.0904760],
          [-2.5121540, 55.0904990],
          [-2.5121170, 55.0905260],
          [-2.5120790, 55.0905530],
          [-2.5120400, 55.0905750],
          [-2.5119960, 55.0905990],
          [-2.5119480, 55.0906180],
          [-2.5118980, 55.0906440],
          [-2.5118460, 55.0906640],
          [-2.5117940, 55.0906860],
          [-2.5117450, 55.0907090],
          [-2.5116930, 55.0907280],
          [-2.5116410, 55.0907510],
          [-2.5115900, 55.0907740],
          [-2.5115400, 55.0907930],
          [-2.5114910, 55.0908160],
          [-2.5114410, 55.0908390],
          [-2.5113910, 55.0908580],
          [-2.5113390, 55.0908810],
          [-2.5112910, 55.0909000],
          [-2.5112410, 55.0909190],
          [-2.5111890, 55.0909420],
          [-2.5111390, 55.0909610],
          [-2.5110880, 55.0909840],
          [-2.5110380, 55.0910030],
          [-2.5109860, 55.0910260],
          [-2.5109330, 55.0910450],
          [-2.5108790, 55.0910640],
          [-2.5108230, 55.0910830],
          [-2.5107660, 55.0911020],
          [-2.5107080, 55.0911210],
          [-2.5106470, 55.0911370],
          [-2.5105880, 55.0911520],
          [-2.5105280, 55.0911710],
          [-2.5104660, 55.0911860],
          [-2.5104060, 55.0911980],
          [-2.5103440, 55.0912130],
          [-2.5102840, 55.0912280],
          [-2.5102230, 55.0912400],
          [-2.5101610, 55.0912550],
          [-2.5100980, 55.0912660],
          [-2.5100360, 55.0912820],
          [-2.5099760, 55.0912930],
          [-2.5099160, 55.0913050],
          [-2.5098580, 55.0913200],
          [-2.5098000, 55.0913350],
          [-2.5097400, 55.0913500],
          [-2.5096780, 55.0913660],
          [-2.5096190, 55.0913840],
          [-2.5095580, 55.0914000],
          [-2.5094980, 55.0914190],
          [-2.5094360, 55.0914380],
          [-2.5093760, 55.0914530],
          [-2.5093110, 55.0914720],
          [-2.5092510, 55.0914910],
          [-2.5091910, 55.0915140],
          [-2.5091340, 55.0915330],
          [-2.5090760, 55.0915550],
          [-2.5090140, 55.0915750],
          [-2.5089530, 55.0915940],
          [-2.5088910, 55.0916130],
          [-2.5088290, 55.0916330],
          [-2.5087670, 55.0916510],
          [-2.5087050, 55.0916740],
          [-2.5086430, 55.0916940],
          [-2.5085820, 55.0917120],
          [-2.5085200, 55.0917310],
          [-2.5084580, 55.0917510],
          [-2.5083960, 55.0917700],
          [-2.5083370, 55.0917890],
          [-2.5082770, 55.0918080],
          [-2.5082210, 55.0918270],
          [-2.5081650, 55.0918420],
          [-2.5081050, 55.0918610],
          [-2.5080420, 55.0918800],
          [-2.5079790, 55.0918990],
          [-2.5079160, 55.0919220],
          [-2.5078560, 55.0919420],
          [-2.5077920, 55.0919570],
          [-2.5077250, 55.0919760],
          [-2.5076550, 55.0919870],
          [-2.5075850, 55.0919990],
          [-2.5075120, 55.0920060],
          [-2.5074390, 55.0920070],
          [-2.5073610, 55.0920070],
          [-2.5072810, 55.0920030],
          [-2.5071990, 55.0919920],
          [-2.5071180, 55.0919770],
          [-2.5070310, 55.0919580],
          [-2.5069460, 55.0919430],
          [-2.5068520, 55.0919230],
          [-2.5067590, 55.0919000],
          [-2.5066680, 55.0918740],
          [-2.5065760, 55.0918510],
          [-2.5064820, 55.0918280],
          [-2.5063850, 55.0918010],
          [-2.5062900, 55.0917740],
          [-2.5061940, 55.0917520],
          [-2.5060990, 55.0917290],
          [-2.5060000, 55.0917060],
          [-2.5059040, 55.0916830],
          [-2.5058040, 55.0916640],
          [-2.5057030, 55.0916410],
          [-2.5056040, 55.0916260],
          [-2.5055030, 55.0916030],
          [-2.5054040, 55.0915840],
          [-2.5053000, 55.0915680],
          [-2.5051970, 55.0915490],
          [-2.5050920, 55.0915340],
          [-2.5049810, 55.0915190],
          [-2.5048720, 55.0915030],
          [-2.5047670, 55.0914920],
          [-2.5046590, 55.0914770],
          [-2.5045530, 55.0914730],
          [-2.5044460, 55.0914690],
          [-2.5043360, 55.0914730],
          [-2.5042220, 55.0914760],
          [-2.5041170, 55.0914870],
          [-2.5040070, 55.0915030],
          [-2.5039110, 55.0915220],
          [-2.5038200, 55.0915450],
          [-2.5037400, 55.0915630],
          [-2.5036580, 55.0915790],
          [-2.5035790, 55.0915980],
          [-2.5035010, 55.0916130],
          [-2.5034240, 55.0916290],
          [-2.5033480, 55.0916440],
          [-2.5032700, 55.0916590],
          [-2.5031930, 55.0916750],
          [-2.5031200, 55.0916900],
          [-2.5030510, 55.0917080],
          [-2.5029810, 55.0917280],
          [-2.5029180, 55.0917470],
          [-2.5028580, 55.0917700],
          [-2.5027980, 55.0917930],
          [-2.5027420, 55.0918190],
          [-2.5026860, 55.0918420],
          [-2.5026330, 55.0918650],
          [-2.5025830, 55.0918920],
          [-2.5025380, 55.0919180],
          [-2.5024940, 55.0919450],
          [-2.5024510, 55.0919720],
          [-2.5024110, 55.0919980],
          [-2.5023720, 55.0920290],
          [-2.5023370, 55.0920510],
          [-2.5023000, 55.0920780],
          [-2.5022640, 55.0921050],
          [-2.5022310, 55.0921320],
          [-2.5021970, 55.0921590],
          [-2.5021660, 55.0921850],
          [-2.5021340, 55.0922080],
          [-2.5021040, 55.0922350],
          [-2.5020740, 55.0922580],
          [-2.5020460, 55.0922810],
          [-2.5020190, 55.0923040],
          [-2.5019920, 55.0923270],
          [-2.5019660, 55.0923500],
          [-2.5019370, 55.0923760],
          [-2.5019100, 55.0923990],
          [-2.5018840, 55.0924220],
          [-2.5018600, 55.0924450],
          [-2.5018400, 55.0924670],
          [-2.5018170, 55.0924950],
          [-2.5017940, 55.0925210],
          [-2.5017710, 55.0925440],
          [-2.5017470, 55.0925700],
          [-2.5017240, 55.0925940],
          [-2.5016960, 55.0926200],
          [-2.5016690, 55.0926430],
          [-2.5016440, 55.0926730],
          [-2.5016240, 55.0927040],
          [-2.5016040, 55.0927300],
          [-2.5015860, 55.0927600],
          [-2.5015680, 55.0927910],
          [-2.5015490, 55.0928220],
          [-2.5015340, 55.0928490],
          [-2.5015190, 55.0928760],
          [-2.5015040, 55.0929060],
          [-2.5014850, 55.0929330],
          [-2.5014770, 55.0929590],
          [-2.5014620, 55.0929900],
          [-2.5014450, 55.0930210],
          [-2.5014250, 55.0930480],
          [-2.5014080, 55.0930760],
          [-2.5013890, 55.0931090],
          [-2.5013720, 55.0931350],
          [-2.5013570, 55.0931650],
          [-2.5013420, 55.0931950],
          [-2.5013280, 55.0932230],
          [-2.5013140, 55.0932490],
          [-2.5013000, 55.0932800],
          [-2.5012870, 55.0933070],
          [-2.5012750, 55.0933370],
          [-2.5012640, 55.0933640],
          [-2.5012540, 55.0933940],
          [-2.5012450, 55.0934170],
          [-2.5012370, 55.0934480],
          [-2.5012250, 55.0934740],
          [-2.5012140, 55.0935050],
          [-2.5011990, 55.0935320],
          [-2.5011840, 55.0935620],
          [-2.5011700, 55.0935930],
          [-2.5011600, 55.0936230],
          [-2.5011500, 55.0936540],
          [-2.5011390, 55.0936840],
          [-2.5011270, 55.0937150],
          [-2.5011170, 55.0937490],
          [-2.5011040, 55.0937790],
          [-2.5010900, 55.0938140],
          [-2.5010760, 55.0938520],
          [-2.5010640, 55.0938860],
          [-2.5010500, 55.0939240],
          [-2.5010370, 55.0939670],
          [-2.5010210, 55.0940120],
          [-2.5010040, 55.0940580],
          [-2.5009860, 55.0941030],
          [-2.5009610, 55.0941570],
          [-2.5009230, 55.0942070],
          [-2.5008650, 55.0942450],
          [-2.5007780, 55.0942680],
          [-2.5006780, 55.0942760],
          [-2.5005800, 55.0942660],
          [-2.5004800, 55.0942470],
          [-2.5003820, 55.0942130],
          [-2.5002830, 55.0941780],
          [-2.5001890, 55.0941360],
          [-2.5001000, 55.0940950],
          [-2.5000120, 55.0940570],
          [-2.4999240, 55.0940120],
          [-2.4998460, 55.0939630],
          [-2.4997650, 55.0939120],
          [-2.4996910, 55.0938620],
          [-2.4996200, 55.0938130],
          [-2.4995520, 55.0937640],
          [-2.4994800, 55.0937130],
          [-2.4994110, 55.0936600],
          [-2.4993450, 55.0936070],
          [-2.4992770, 55.0935460],
          [-2.4992000, 55.0934790],
          [-2.4991210, 55.0934100],
          [-2.4990350, 55.0933360],
          [-2.4989620, 55.0932690],
          [-2.4988890, 55.0932030],
          [-2.4988240, 55.0931380],
          [-2.4987650, 55.0930760],
          [-2.4987140, 55.0930200],
          [-2.4986690, 55.0929650],
          [-2.4986280, 55.0929200],
          [-2.4985900, 55.0928700],
          [-2.4985480, 55.0928290],
          [-2.4985070, 55.0927860],
          [-2.4984670, 55.0927490],
          [-2.4984260, 55.0927060],
          [-2.4983850, 55.0926720],
          [-2.4983420, 55.0926370],
          [-2.4982970, 55.0926030],
          [-2.4982490, 55.0925730],
          [-2.4981980, 55.0925410],
          [-2.4981430, 55.0925150],
          [-2.4980880, 55.0924880],
          [-2.4980310, 55.0924650],
          [-2.4979740, 55.0924460],
          [-2.4979120, 55.0924270],
          [-2.4978490, 55.0924110],
          [-2.4977860, 55.0923920],
          [-2.4977210, 55.0923770],
          [-2.4976530, 55.0923620],
          [-2.4975860, 55.0923500],
          [-2.4975160, 55.0923430],
          [-2.4974430, 55.0923310],
          [-2.4973690, 55.0923240],
          [-2.4972960, 55.0923120],
          [-2.4972220, 55.0923040],
          [-2.4971450, 55.0922970],
          [-2.4970690, 55.0922890],
          [-2.4969880, 55.0922850],
          [-2.4969080, 55.0922780],
          [-2.4968220, 55.0922700],
          [-2.4967360, 55.0922660],
          [-2.4966420, 55.0922590],
          [-2.4965510, 55.0922550],
          [-2.4964520, 55.0922470],
          [-2.4963550, 55.0922400],
          [-2.4962590, 55.0922320],
          [-2.4961600, 55.0922280],
          [-2.4960550, 55.0922240],
          [-2.4959530, 55.0922170],
          [-2.4958480, 55.0922170],
          [-2.4957480, 55.0922170],
          [-2.4956380, 55.0922130],
          [-2.4955290, 55.0922050],
          [-2.4954130, 55.0922010],
          [-2.4953000, 55.0921970],
          [-2.4951800, 55.0921940],
          [-2.4950620, 55.0921900],
          [-2.4949380, 55.0921860],
          [-2.4948100, 55.0921900],
          [-2.4946740, 55.0921860],
          [-2.4945370, 55.0921820],
          [-2.4943900, 55.0921750],
          [-2.4942540, 55.0921670],
          [-2.4941130, 55.0921560],
          [-2.4939760, 55.0921520],
          [-2.4938310, 55.0921480],
          [-2.4936870, 55.0921440],
          [-2.4935390, 55.0921400],
          [-2.4933900, 55.0921360],
          [-2.4932320, 55.0921290],
          [-2.4930720, 55.0921210],
          [-2.4929100, 55.0921140],
          [-2.4927510, 55.0921100],
          [-2.4925930, 55.0920980],
          [-2.4924460, 55.0920840],
          [-2.4922960, 55.0920680],
          [-2.4921540, 55.0920460],
          [-2.4920130, 55.0920150],
          [-2.4918770, 55.0919740],
          [-2.4917430, 55.0919280],
          [-2.4916100, 55.0918710],
          [-2.4914850, 55.0918110],
          [-2.4913590, 55.0917500],
          [-2.4912430, 55.0916890],
          [-2.4911320, 55.0916270],
          [-2.4910210, 55.0915660],
          [-2.4909150, 55.0915080],
          [-2.4908100, 55.0914590],
          [-2.4907040, 55.0914130],
          [-2.4905950, 55.0913710],
          [-2.4904840, 55.0913320],
          [-2.4903730, 55.0912950],
          [-2.4902560, 55.0912570],
          [-2.4901350, 55.0912260],
          [-2.4900060, 55.0911980],
          [-2.4898790, 55.0911760],
          [-2.4897510, 55.0911560],
          [-2.4896280, 55.0911450],
          [-2.4895110, 55.0911330],
          [-2.4894040, 55.0911260],
          [-2.4892970, 55.0911260],
          [-2.4891930, 55.0911260],
          [-2.4890830, 55.0911260],
          [-2.4889720, 55.0911290],
          [-2.4888660, 55.0911290],
          [-2.4887660, 55.0911290],
          [-2.4886570, 55.0911260],
          [-2.4885570, 55.0911260],
          [-2.4884580, 55.0911290],
          [-2.4883570, 55.0911290],
          [-2.4882550, 55.0911290],
          [-2.4881600, 55.0911290],
          [-2.4880670, 55.0911290],
          [-2.4879680, 55.0911290],
          [-2.4878800, 55.0911330],
          [-2.4878010, 55.0911370],
          [-2.4877260, 55.0911370],
          [-2.4876510, 55.0911370],
          [-2.4875780, 55.0911370],
          [-2.4874960, 55.0911330],
          [-2.4874170, 55.0911370],
          [-2.4873360, 55.0911370],
          [-2.4872520, 55.0911410],
          [-2.4871630, 55.0911370],
          [-2.4870720, 55.0911410],
          [-2.4869830, 55.0911440],
          [-2.4868970, 55.0911480],
          [-2.4868100, 55.0911560],
          [-2.4867180, 55.0911630],
          [-2.4866210, 55.0911740],
          [-2.4865210, 55.0911820],
          [-2.4864240, 55.0911900],
          [-2.4863220, 55.0912050],
          [-2.4862200, 55.0912160],
          [-2.4861090, 55.0912280],
          [-2.4860160, 55.0912390],
          [-2.4859210, 55.0912550],
          [-2.4858160, 55.0912620],
          [-2.4857200, 55.0912700],
          [-2.4856300, 55.0912850],
          [-2.4855410, 55.0913010],
          [-2.4854540, 55.0913190],
          [-2.4853590, 55.0913350],
          [-2.4852720, 55.0913540],
          [-2.4851890, 55.0913690],
          [-2.4851080, 55.0913880],
          [-2.4850260, 55.0914110],
          [-2.4849600, 55.0914370],
          [-2.4848900, 55.0914570],
          [-2.4848090, 55.0914720],
          [-2.4847350, 55.0914910],
          [-2.4846470, 55.0915180],
          [-2.4845720, 55.0915440],
          [-2.4844970, 55.0915710],
          [-2.4844210, 55.0915970],
          [-2.4843310, 55.0916240],
          [-2.4842500, 55.0916500],
          [-2.4841720, 55.0916780],
          [-2.4841020, 55.0917000],
          [-2.4840210, 55.0917200],
          [-2.4839450, 55.0917310],
          [-2.4838650, 55.0917360],
          [-2.4837950, 55.0917360],
          [-2.4837200, 55.0917320],
          [-2.4836250, 55.0917210],
          [-2.4834750, 55.0916990],
          [-2.4833060, 55.0916750],
          [-2.4831610, 55.0916670],
          [-2.4830250, 55.0916520],
          [-2.4828950, 55.0916410],
          [-2.4827550, 55.0916260],
          [-2.4826350, 55.0916180],
          [-2.4825060, 55.0916060],
          [-2.4823930, 55.0915880],
          [-2.4822720, 55.0915760],
          [-2.4821560, 55.0915690],
          [-2.4820290, 55.0915570],
          [-2.4819050, 55.0915530],
          [-2.4817750, 55.0915450],
          [-2.4816670, 55.0915380],
          [-2.4815600, 55.0915300],
          [-2.4814730, 55.0915260],
          [-2.4813890, 55.0915220],
          [-2.4813120, 55.0915190],
          [-2.4812350, 55.0915180],
          [-2.4811630, 55.0915150],
          [-2.4810920, 55.0915110],
          [-2.4810200, 55.0915110],
          [-2.4809510, 55.0915140],
          [-2.4808850, 55.0915150],
          [-2.4808150, 55.0915150],
          [-2.4807480, 55.0915150],
          [-2.4806810, 55.0915150],
          [-2.4806140, 55.0915150],
          [-2.4805500, 55.0915110],
          [-2.4804900, 55.0915140],
          [-2.4804250, 55.0915150],
          [-2.4803660, 55.0915110],
          [-2.4803080, 55.0915110],
          [-2.4802450, 55.0915110],
          [-2.4801840, 55.0915110],
          [-2.4801270, 55.0915110],
          [-2.4800680, 55.0915110],
          [-2.4800120, 55.0915070],
          [-2.4799580, 55.0915070],
          [-2.4799000, 55.0915070],
          [-2.4798420, 55.0915030],
          [-2.4797860, 55.0915030],
          [-2.4797280, 55.0915030],
          [-2.4796750, 55.0915030],
          [-2.4796180, 55.0915030],
          [-2.4795630, 55.0915030],
          [-2.4795090, 55.0914990],
          [-2.4794540, 55.0914990],
          [-2.4793970, 55.0914960],
          [-2.4793400, 55.0914960],
          [-2.4792860, 55.0914960],
          [-2.4792280, 55.0914920],
          [-2.4791750, 55.0914920],
          [-2.4791230, 55.0914920],
          [-2.4790700, 55.0914920],
          [-2.4790240, 55.0914880],
          [-2.4789830, 55.0914840],
          [-2.4789430, 55.0914810],
          [-2.4788990, 55.0914770],
          [-2.4788590, 55.0914690],
          [-2.4788200, 55.0914650],
          [-2.4787730, 55.0914650],
          [-2.4787240, 55.0914650],
          [-2.4786780, 55.0914690],
          [-2.4786310, 55.0914690],
          [-2.4785840, 55.0914690],
          [-2.4785350, 55.0914690],
          [-2.4784910, 55.0914690],
          [-2.4784450, 55.0914690],
          [-2.4783990, 55.0914690],
          [-2.4783500, 55.0914690],
          [-2.4783030, 55.0914650],
          [-2.4782590, 55.0914650],
          [-2.4782150, 55.0914610],
          [-2.4781650, 55.0914650],
          [-2.4781200, 55.0914650],
          [-2.4780740, 55.0914650],
          [-2.4780290, 55.0914650],
          [-2.4779810, 55.0914650],
          [-2.4779340, 55.0914650],
          [-2.4778860, 55.0914690],
          [-2.4778390, 55.0914690],
          [-2.4777900, 55.0914690],
          [-2.4777400, 55.0914690],
          [-2.4776870, 55.0914690],
          [-2.4776370, 55.0914720],
          [-2.4775780, 55.0914760],
          [-2.4775220, 55.0914760],
          [-2.4774630, 55.0914800],
          [-2.4774060, 55.0914800],
          [-2.4773490, 55.0914840],
          [-2.4772920, 55.0914840],
          [-2.4772300, 55.0914880],
          [-2.4771710, 55.0914880],
          [-2.4771070, 55.0914880],
          [-2.4770380, 55.0914920],
          [-2.4769660, 55.0914920],
          [-2.4768950, 55.0914950],
          [-2.4768150, 55.0914960],
          [-2.4767380, 55.0915030],
          [-2.4766620, 55.0915030],
          [-2.4765840, 55.0915030],
          [-2.4765100, 55.0915070],
          [-2.4764350, 55.0915110],
          [-2.4763540, 55.0915140],
          [-2.4762740, 55.0915180],
          [-2.4761960, 55.0915180],
          [-2.4761080, 55.0915220],
          [-2.4760250, 55.0915260],
          [-2.4759350, 55.0915300],
          [-2.4758510, 55.0915330],
          [-2.4757550, 55.0915370],
          [-2.4756670, 55.0915410],
          [-2.4755710, 55.0915450],
          [-2.4754840, 55.0915450],
          [-2.4753930, 55.0915450],
          [-2.4753070, 55.0915490],
          [-2.4752150, 55.0915490],
          [-2.4751280, 55.0915520],
          [-2.4750340, 55.0915530],
          [-2.4749460, 55.0915560],
          [-2.4748530, 55.0915570],
          [-2.4747630, 55.0915600],
          [-2.4746740, 55.0915640],
          [-2.4745940, 55.0915680],
          [-2.4745090, 55.0915720],
          [-2.4744370, 55.0915720],
          [-2.4743570, 55.0915720],
          [-2.4742870, 55.0915720],
          [-2.4742250, 55.0915750],
          [-2.4741600, 55.0915790],
          [-2.4740990, 55.0915830],
          [-2.4740390, 55.0915800],
          [-2.4739710, 55.0915790],
          [-2.4739040, 55.0915830],
          [-2.4738390, 55.0915830],
          [-2.4737720, 55.0915870],
          [-2.4737050, 55.0915870],
          [-2.4736410, 55.0915910],
          [-2.4735770, 55.0915910],
          [-2.4735150, 55.0915910],
          [-2.4734550, 55.0915940],
          [-2.4733900, 55.0915950],
          [-2.4733320, 55.0915980],
          [-2.4732740, 55.0915990],
          [-2.4732140, 55.0916020],
          [-2.4731550, 55.0916060],
          [-2.4730990, 55.0916060],
          [-2.4730420, 55.0916100],
          [-2.4729890, 55.0916100],
          [-2.4729420, 55.0916130],
          [-2.4728910, 55.0916140],
          [-2.4728400, 55.0916140],
          [-2.4727900, 55.0916140],
          [-2.4727380, 55.0916140],
          [-2.4726830, 55.0916100],
          [-2.4726270, 55.0916100],
          [-2.4725760, 55.0916100],
          [-2.4725200, 55.0916100],
          [-2.4724660, 55.0916100],
          [-2.4724130, 55.0916130],
          [-2.4723560, 55.0916170],
          [-2.4722970, 55.0916180],
          [-2.4722380, 55.0916180],
          [-2.4721750, 55.0916180],
          [-2.4721160, 55.0916210],
          [-2.4720550, 55.0916250],
          [-2.4719890, 55.0916290],
          [-2.4719200, 55.0916330],
          [-2.4718530, 55.0916360],
          [-2.4717810, 55.0916440],
          [-2.4717130, 55.0916520],
          [-2.4716610, 55.0916550],
          [-2.4715760, 55.0916630],
          [-2.4715060, 55.0916710],
          [-2.4714350, 55.0916750],
          [-2.4713600, 55.0916860],
          [-2.4712840, 55.0916940],
          [-2.4712060, 55.0917010],
          [-2.4711280, 55.0917090],
          [-2.4710510, 55.0917170],
          [-2.4709730, 55.0917280],
          [-2.4708950, 55.0917390],
          [-2.4708180, 55.0917470],
          [-2.4707410, 55.0917590],
          [-2.4706690, 55.0917700],
          [-2.4706000, 55.0917810],
          [-2.4705300, 55.0917890],
          [-2.4704600, 55.0918000],
          [-2.4703900, 55.0918120],
          [-2.4703170, 55.0918270],
          [-2.4702470, 55.0918380],
          [-2.4701800, 55.0918580],
          [-2.4701130, 55.0918730],
          [-2.4700480, 55.0918920],
          [-2.4699870, 55.0919110],
          [-2.4699290, 55.0919300],
          [-2.4698750, 55.0919490],
          [-2.4698230, 55.0919670],
          [-2.4697660, 55.0919830],
          [-2.4697060, 55.0920020],
          [-2.4696480, 55.0920180],
          [-2.4695880, 55.0920370],
          [-2.4695270, 55.0920560],
          [-2.4694670, 55.0920710],
          [-2.4693990, 55.0920940],
          [-2.4693320, 55.0921090],
          [-2.4692610, 55.0921290],
          [-2.4691910, 55.0921480],
          [-2.4691220, 55.0921660],
          [-2.4690480, 55.0921860],
          [-2.4689720, 55.0922010],
          [-2.4688960, 55.0922200],
          [-2.4688230, 55.0922390],
          [-2.4687510, 55.0922580],
          [-2.4686750, 55.0922730],
          [-2.4685940, 55.0922930],
          [-2.4685130, 55.0923120],
          [-2.4684320, 55.0923300],
          [-2.4683520, 55.0923500],
          [-2.4682730, 55.0923730],
          [-2.4682000, 55.0923920],
          [-2.4681290, 55.0924070],
          [-2.4680610, 55.0924290],
          [-2.4679930, 55.0924490],
          [-2.4679330, 55.0924680],
          [-2.4678740, 55.0924870],
          [-2.4678150, 55.0925060],
          [-2.4677540, 55.0925250],
          [-2.4676930, 55.0925480],
          [-2.4676330, 55.0925710],
          [-2.4675710, 55.0925900],
          [-2.4675110, 55.0926130],
          [-2.4674530, 55.0926320],
          [-2.4673940, 55.0926550],
          [-2.4673360, 55.0926770],
          [-2.4672760, 55.0927000],
          [-2.4672190, 55.0927240],
          [-2.4671620, 55.0927430],
          [-2.4671090, 55.0927580],
          [-2.4670520, 55.0927770],
          [-2.4669960, 55.0927920],
          [-2.4669390, 55.0928110],
          [-2.4668830, 55.0928380],
          [-2.4668260, 55.0928570],
          [-2.4667680, 55.0928760],
          [-2.4667110, 55.0928990],
          [-2.4666560, 55.0929180],
          [-2.4666010, 55.0929410],
          [-2.4665460, 55.0929560],
          [-2.4664930, 55.0929750],
          [-2.4664380, 55.0929980],
          [-2.4663830, 55.0930130],
          [-2.4663280, 55.0930250],
          [-2.4662700, 55.0930400],
          [-2.4662080, 55.0930550],
          [-2.4661480, 55.0930710],
          [-2.4660840, 55.0930820],
          [-2.4660230, 55.0930940],
          [-2.4659560, 55.0931050],
          [-2.4658860, 55.0931200],
          [-2.4658120, 55.0931320],
          [-2.4657350, 55.0931430],
          [-2.4656520, 55.0931550],
          [-2.4655690, 55.0931660],
          [-2.4654790, 55.0931780],
          [-2.4653880, 55.0931930],
          [-2.4652930, 55.0932040],
          [-2.4651950, 55.0932160],
          [-2.4650970, 55.0932230],
          [-2.4649970, 55.0932310],
          [-2.4649010, 55.0932350],
          [-2.4648020, 55.0932460],
          [-2.4647060, 55.0932540],
          [-2.4646080, 55.0932610],
          [-2.4645070, 55.0932650],
          [-2.4644050, 55.0932730],
          [-2.4643030, 55.0932810],
          [-2.4641980, 55.0932850],
          [-2.4640870, 55.0932880],
          [-2.4639790, 55.0932920],
          [-2.4638680, 55.0932960],
          [-2.4637600, 55.0933000],
          [-2.4636510, 55.0933000],
          [-2.4635400, 55.0932960],
          [-2.4634310, 55.0932890],
          [-2.4633200, 55.0932810],
          [-2.4632100, 55.0932700],
          [-2.4631010, 55.0932540],
          [-2.4630000, 55.0932430],
          [-2.4628890, 55.0932280],
          [-2.4627800, 55.0932130],
          [-2.4626680, 55.0931970],
          [-2.4625600, 55.0931890],
          [-2.4624460, 55.0931780],
          [-2.4623270, 55.0931700],
          [-2.4622010, 55.0931630],
          [-2.4620740, 55.0931590],
          [-2.4619410, 55.0931620],
          [-2.4618050, 55.0931700],
          [-2.4616750, 55.0931780],
          [-2.4615450, 55.0931890],
          [-2.4614190, 55.0932000],
          [-2.4612990, 55.0932160],
          [-2.4611850, 55.0932350],
          [-2.4610770, 55.0932500],
          [-2.4609750, 55.0932730],
          [-2.4608830, 55.0932880],
          [-2.4607990, 55.0933070],
          [-2.4607230, 55.0933230],
          [-2.4606460, 55.0933380],
          [-2.4605710, 55.0933530],
          [-2.4604960, 55.0933650],
          [-2.4604210, 55.0933800],
          [-2.4603480, 55.0933950],
          [-2.4602770, 55.0934100],
          [-2.4602050, 55.0934290],
          [-2.4601370, 55.0934440],
          [-2.4600660, 55.0934600],
          [-2.4599960, 55.0934750],
          [-2.4599270, 55.0934900],
          [-2.4598630, 55.0935060],
          [-2.4598010, 55.0935170],
          [-2.4597380, 55.0935320],
          [-2.4596740, 55.0935440],
          [-2.4596120, 55.0935550],
          [-2.4595450, 55.0935700],
          [-2.4594780, 55.0935820],
          [-2.4594080, 55.0935930],
          [-2.4593370, 55.0936050],
          [-2.4592660, 55.0936160],
          [-2.4591920, 55.0936240],
          [-2.4591180, 55.0936320],
          [-2.4590410, 55.0936430],
          [-2.4589610, 55.0936540],
          [-2.4588820, 55.0936620],
          [-2.4588010, 55.0936740],
          [-2.4587220, 55.0936810],
          [-2.4586410, 55.0936890],
          [-2.4585600, 55.0936960],
          [-2.4584810, 55.0937000],
          [-2.4583970, 55.0937120],
          [-2.4583100, 55.0937190],
          [-2.4582210, 55.0937270],
          [-2.4581310, 55.0937350],
          [-2.4580440, 55.0937420],
          [-2.4579580, 55.0937500],
          [-2.4578680, 55.0937540],
          [-2.4577790, 55.0937580],
          [-2.4576920, 55.0937650],
          [-2.4576040, 55.0937690],
          [-2.4575170, 55.0937730],
          [-2.4574300, 55.0937770],
          [-2.4573460, 55.0937800],
          [-2.4572630, 55.0937880],
          [-2.4571780, 55.0937920],
          [-2.4570960, 55.0937960],
          [-2.4570170, 55.0937990],
          [-2.4569310, 55.0938030],
          [-2.4568470, 55.0938070],
          [-2.4567630, 55.0938110],
          [-2.4566770, 55.0938150],
          [-2.4565930, 55.0938150],
          [-2.4565090, 55.0938190],
          [-2.4564240, 55.0938220],
          [-2.4563410, 55.0938260],
          [-2.4562570, 55.0938300],
          [-2.4561740, 55.0938300],
          [-2.4560910, 55.0938300],
          [-2.4560120, 55.0938300],
          [-2.4559310, 55.0938340],
          [-2.4558510, 55.0938380],
          [-2.4557730, 55.0938420],
          [-2.4556960, 55.0938420],
          [-2.4556170, 55.0938420],
          [-2.4555380, 55.0938420],
          [-2.4554600, 55.0938420],
          [-2.4553830, 55.0938450],
          [-2.4553100, 55.0938450],
          [-2.4552290, 55.0938420],
          [-2.4551550, 55.0938420],
          [-2.4550770, 55.0938450],
          [-2.4549990, 55.0938490],
          [-2.4549180, 55.0938450],
          [-2.4548360, 55.0938420],
          [-2.4547530, 55.0938450],
          [-2.4546700, 55.0938450],
          [-2.4545890, 55.0938420],
          [-2.4545110, 55.0938420],
          [-2.4544360, 55.0938380],
          [-2.4543670, 55.0938340],
          [-2.4543000, 55.0938340],
          [-2.4542350, 55.0938300],
          [-2.4541710, 55.0938230],
          [-2.4541090, 55.0938190],
          [-2.4540460, 55.0938110],
          [-2.4539810, 55.0938030],
          [-2.4539130, 55.0938000],
          [-2.4538450, 55.0937880],
          [-2.4537760, 55.0937810],
          [-2.4537100, 55.0937690],
          [-2.4536430, 55.0937580],
          [-2.4535780, 55.0937470],
          [-2.4535100, 55.0937350],
          [-2.4534410, 55.0937230],
          [-2.4533770, 55.0937160],
          [-2.4533130, 55.0937080],
          [-2.4532490, 55.0936970],
          [-2.4531850, 55.0936890],
          [-2.4531210, 55.0936780],
          [-2.4530580, 55.0936660],
          [-2.4529930, 55.0936590],
          [-2.4529280, 55.0936470],
          [-2.4528630, 55.0936360],
          [-2.4527980, 55.0936280],
          [-2.4527330, 55.0936170],
          [-2.4526680, 55.0936090],
          [-2.4526030, 55.0935980],
          [-2.4525390, 55.0935860],
          [-2.4524750, 55.0935780],
          [-2.4524110, 55.0935670],
          [-2.4523490, 55.0935560],
          [-2.4522850, 55.0935480],
          [-2.4522220, 55.0935400],
          [-2.4521580, 55.0935290],
          [-2.4520930, 55.0935210],
          [-2.4520300, 55.0935140],
          [-2.4519660, 55.0935020],
          [-2.4519030, 55.0934950],
          [-2.4518380, 55.0934870],
          [-2.4517730, 55.0934760],
          [-2.4517100, 55.0934680],
          [-2.4516460, 55.0934600],
          [-2.4515870, 55.0934490],
          [-2.4515240, 55.0934410],
          [-2.4514590, 55.0934330],
          [-2.4513960, 55.0934260],
          [-2.4513360, 55.0934180],
          [-2.4512750, 55.0934140],
          [-2.4512140, 55.0934070],
          [-2.4511540, 55.0934030],
          [-2.4510940, 55.0933950],
          [-2.4510310, 55.0933880],
          [-2.4509690, 55.0933800],
          [-2.4509040, 55.0933760],
          [-2.4508430, 55.0933720],
          [-2.4507830, 55.0933720],
          [-2.4507210, 55.0933720],
          [-2.4506590, 55.0933720],
          [-2.4505960, 55.0933690],
          [-2.4505330, 55.0933690],
          [-2.4504680, 55.0933690],
          [-2.4504020, 55.0933720],
          [-2.4503350, 55.0933760],
          [-2.4502660, 55.0933800],
          [-2.4501980, 55.0933800],
          [-2.4501260, 55.0933870],
          [-2.4500530, 55.0933950],
          [-2.4499780, 55.0934030],
          [-2.4499050, 55.0934100],
          [-2.4498310, 55.0934180],
          [-2.4497540, 55.0934290],
          [-2.4496720, 55.0934410],
          [-2.4495910, 55.0934520],
          [-2.4495060, 55.0934640],
          [-2.4494250, 55.0934750],
          [-2.4493430, 55.0934870],
          [-2.4492600, 55.0935020],
          [-2.4491760, 55.0935170],
          [-2.4490900, 55.0935320],
          [-2.4490040, 55.0935470],
          [-2.4489170, 55.0935630],
          [-2.4488240, 55.0935820],
          [-2.4487300, 55.0936010],
          [-2.4486380, 55.0936160],
          [-2.4485470, 55.0936390],
          [-2.4484570, 55.0936660],
          [-2.4483650, 55.0936880],
          [-2.4482720, 55.0937150],
          [-2.4481750, 55.0937420],
          [-2.4480780, 55.0937720],
          [-2.4479780, 55.0938020],
          [-2.4478730, 55.0938300],
          [-2.4477730, 55.0938560],
          [-2.4476680, 55.0938870],
          [-2.4475630, 55.0939170],
          [-2.4474550, 55.0939480],
          [-2.4473530, 55.0939780],
          [-2.4472550, 55.0940050],
          [-2.4471650, 55.0940280],
          [-2.4470820, 55.0940510],
          [-2.4470010, 55.0940740],
          [-2.4469260, 55.0940960],
          [-2.4468520, 55.0941190],
          [-2.4467840, 55.0941500],
          [-2.4467160, 55.0941770],
          [-2.4466550, 55.0942070],
          [-2.4466010, 55.0942410],
          [-2.4465580, 55.0942830],
          [-2.4465160, 55.0943250],
          [-2.4464770, 55.0943670],
          [-2.4464340, 55.0944090],
          [-2.4463910, 55.0944470],
          [-2.4463490, 55.0944890],
          [-2.4463090, 55.0945280],
          [-2.4462720, 55.0945700],
          [-2.4462370, 55.0946040],
          [-2.4462040, 55.0946420],
          [-2.4461710, 55.0946760],
          [-2.4461410, 55.0947110],
          [-2.4461110, 55.0947450],
          [-2.4460760, 55.0947760],
          [-2.4460440, 55.0948060],
          [-2.4460120, 55.0948370],
          [-2.4459850, 55.0948710],
          [-2.4459540, 55.0948980],
          [-2.4459240, 55.0949250],
          [-2.4458920, 55.0949510],
          [-2.4458580, 55.0949770],
          [-2.4458220, 55.0950050],
          [-2.4457850, 55.0950270],
          [-2.4457480, 55.0950540],
          [-2.4457090, 55.0950770],
          [-2.4456660, 55.0950960],
          [-2.4456230, 55.0951150],
          [-2.4455760, 55.0951340],
          [-2.4455330, 55.0951500],
          [-2.4454900, 55.0951690],
          [-2.4454470, 55.0951880],
          [-2.4454060, 55.0952070],
          [-2.4453600, 55.0952260],
          [-2.4453140, 55.0952410],
          [-2.4452640, 55.0952560],
          [-2.4452060, 55.0952720],
          [-2.4451490, 55.0952870],
          [-2.4450890, 55.0952990],
          [-2.4450310, 55.0953140],
          [-2.4449780, 55.0953280],
          [-2.4449220, 55.0953440],
          [-2.4448620, 55.0953600],
          [-2.4448040, 55.0953750],
          [-2.4447430, 55.0953900],
          [-2.4446760, 55.0954050],
          [-2.4446090, 55.0954200],
          [-2.4445430, 55.0954350],
          [-2.4444760, 55.0954470],
          [-2.4444090, 55.0954590],
          [-2.4443350, 55.0954740],
          [-2.4442600, 55.0954850],
          [-2.4441850, 55.0955010],
          [-2.4441160, 55.0955160],
          [-2.4440470, 55.0955310],
          [-2.4439830, 55.0955500],
          [-2.4439210, 55.0955730],
          [-2.4438660, 55.0955960],
          [-2.4438140, 55.0956190],
          [-2.4437640, 55.0956490],
          [-2.4437140, 55.0956680],
          [-2.4436620, 55.0956980],
          [-2.4436070, 55.0957320],
          [-2.4435580, 55.0957710],
          [-2.4435080, 55.0958090],
          [-2.4434610, 55.0958470],
          [-2.4434150, 55.0958900],
          [-2.4433690, 55.0959280],
          [-2.4433210, 55.0959720],
          [-2.4432620, 55.0960110],
          [-2.4432090, 55.0960570],
          [-2.4431640, 55.0960980],
          [-2.4431150, 55.0961400],
          [-2.4430630, 55.0961870],
          [-2.4430130, 55.0962290],
          [-2.4429610, 55.0962710],
          [-2.4429030, 55.0963130],
          [-2.4428410, 55.0963590],
          [-2.4427760, 55.0963970],
          [-2.4427150, 55.0964350],
          [-2.4426530, 55.0964730],
          [-2.4426020, 55.0965100],
          [-2.4425470, 55.0965460],
          [-2.4424900, 55.0965760],
          [-2.4424330, 55.0966110],
          [-2.4423740, 55.0966410],
          [-2.4423230, 55.0966750],
          [-2.4422730, 55.0967060],
          [-2.4422270, 55.0967360],
          [-2.4421810, 55.0967630],
          [-2.4421390, 55.0967900],
          [-2.4420960, 55.0968130],
          [-2.4420460, 55.0968360],
          [-2.4419910, 55.0968550],
          [-2.4419410, 55.0968740],
          [-2.4418860, 55.0968890],
          [-2.4418330, 55.0969040],
          [-2.4417780, 55.0969120],
          [-2.4417210, 55.0969200],
          [-2.4416640, 55.0969240],
          [-2.4416110, 55.0969310],
          [-2.4415590, 55.0969350],
          [-2.4415050, 55.0969390],
          [-2.4414520, 55.0969390],
          [-2.4414010, 55.0969350],
          [-2.4413460, 55.0969320],
          [-2.4412930, 55.0969280],
          [-2.4412430, 55.0969240],
          [-2.4411930, 55.0969240],
          [-2.4411430, 55.0969200],
          [-2.4410910, 55.0969160],
          [-2.4410390, 55.0969120],
          [-2.4409870, 55.0969120],
          [-2.4409340, 55.0969090],
          [-2.4408780, 55.0969090],
          [-2.4408240, 55.0969050],
          [-2.4407730, 55.0969050],
          [-2.4407130, 55.0969010],
          [-2.4406580, 55.0968970],
          [-2.4406050, 55.0968930],
          [-2.4405510, 55.0968930],
          [-2.4404980, 55.0968930],
          [-2.4404430, 55.0968900],
          [-2.4403920, 55.0968900],
          [-2.4403310, 55.0968860],
          [-2.4402750, 55.0968820],
          [-2.4402180, 55.0968820],
          [-2.4401600, 55.0968820],
          [-2.4401020, 55.0968820],
          [-2.4400430, 55.0968820],
          [-2.4399860, 55.0968820],
          [-2.4399290, 55.0968820],
          [-2.4398690, 55.0968780],
          [-2.4398130, 55.0968780],
          [-2.4397570, 55.0968740],
          [-2.4396990, 55.0968740],
          [-2.4396410, 55.0968740],
          [-2.4395840, 55.0968740],
          [-2.4395260, 55.0968780],
          [-2.4394680, 55.0968780],
          [-2.4394060, 55.0968820],
          [-2.4393430, 55.0968820],
          [-2.4392780, 55.0968820],
          [-2.4392130, 55.0968820],
          [-2.4391460, 55.0968780],
          [-2.4390810, 55.0968780],
          [-2.4390140, 55.0968780],
          [-2.4389450, 55.0968780],
          [-2.4388750, 55.0968820],
          [-2.4388030, 55.0968820],
          [-2.4387320, 55.0968820],
          [-2.4386600, 55.0968860],
          [-2.4385880, 55.0968820],
          [-2.4385150, 55.0968860],
          [-2.4384420, 55.0968860],
          [-2.4383700, 55.0968860],
          [-2.4382960, 55.0968890],
          [-2.4382230, 55.0968930],
          [-2.4381530, 55.0968930],
          [-2.4380830, 55.0968930],
          [-2.4380160, 55.0968930],
          [-2.4379470, 55.0968930],
          [-2.4378780, 55.0968930],
          [-2.4378080, 55.0968970],
          [-2.4377370, 55.0968930],
          [-2.4376650, 55.0968930],
          [-2.4375910, 55.0968900],
          [-2.4375200, 55.0968930],
          [-2.4374460, 55.0968930],
          [-2.4373720, 55.0968930],
          [-2.4372960, 55.0968930],
          [-2.4372230, 55.0968930],
          [-2.4371500, 55.0968930],
          [-2.4370750, 55.0968930],
          [-2.4369970, 55.0968900],
          [-2.4369180, 55.0968860],
          [-2.4368400, 55.0968820],
          [-2.4367600, 55.0968780],
          [-2.4366790, 55.0968710],
          [-2.4365950, 55.0968630],
          [-2.4365120, 55.0968550],
          [-2.4364230, 55.0968480],
          [-2.4363330, 55.0968360],
          [-2.4362470, 55.0968210],
          [-2.4361620, 55.0968020],
          [-2.4360800, 55.0967790],
          [-2.4359980, 55.0967530],
          [-2.4359170, 55.0967260],
          [-2.4358370, 55.0966960],
          [-2.4357610, 55.0966620],
          [-2.4356860, 55.0966280],
          [-2.4356090, 55.0965930],
          [-2.4355390, 55.0965580],
          [-2.4354730, 55.0965200],
          [-2.4354060, 55.0964860],
          [-2.4353410, 55.0964520],
          [-2.4352810, 55.0964170],
          [-2.4352230, 55.0963830],
          [-2.4351650, 55.0963520],
          [-2.4351100, 55.0963180],
          [-2.4350550, 55.0962870],
          [-2.4350000, 55.0962530],
          [-2.4349460, 55.0962190],
          [-2.4348960, 55.0961850],
          [-2.4348430, 55.0961500],
          [-2.4347940, 55.0961120],
          [-2.4347480, 55.0960740],
          [-2.4347010, 55.0960320],
          [-2.4346570, 55.0959860],
          [-2.4346160, 55.0959440],
          [-2.4345760, 55.0958950],
          [-2.4345420, 55.0958500],
          [-2.4345070, 55.0957990],
          [-2.4344760, 55.0957540],
          [-2.4344470, 55.0957040],
          [-2.4344190, 55.0956580],
          [-2.4343940, 55.0956130],
          [-2.4343690, 55.0955700],
          [-2.4343420, 55.0955170],
          [-2.4343200, 55.0954680],
          [-2.4342960, 55.0954220],
          [-2.4342710, 55.0953730],
          [-2.4342470, 55.0953230],
          [-2.4342260, 55.0952730],
          [-2.4342040, 55.0952230],
          [-2.4341820, 55.0951740],
          [-2.4341610, 55.0951240],
          [-2.4341370, 55.0950750],
          [-2.4341120, 55.0950260],
          [-2.4340840, 55.0949760],
          [-2.4340570, 55.0949260],
          [-2.4340330, 55.0948770],
          [-2.4340070, 55.0948310],
          [-2.4339820, 55.0947810],
          [-2.4339570, 55.0947280],
          [-2.4339320, 55.0946780],
          [-2.4339060, 55.0946250],
          [-2.4338790, 55.0945710],
          [-2.4338510, 55.0945220],
          [-2.4338220, 55.0944680],
          [-2.4337940, 55.0944160],
          [-2.4337670, 55.0943620],
          [-2.4337440, 55.0943040],
          [-2.4337160, 55.0942510],
          [-2.4336870, 55.0941970],
          [-2.4336570, 55.0941460],
          [-2.4336270, 55.0940870],
          [-2.4335990, 55.0940300],
          [-2.4335670, 55.0939760],
          [-2.4335330, 55.0939190],
          [-2.4334960, 55.0938580],
          [-2.4334560, 55.0938010],
          [-2.4334140, 55.0937410],
          [-2.4333660, 55.0936790],
          [-2.4333190, 55.0936140],
          [-2.4332710, 55.0935490],
          [-2.4332190, 55.0934800],
          [-2.4331630, 55.0934080],
          [-2.4331080, 55.0933410],
          [-2.4330440, 55.0932670],
          [-2.4329810, 55.0931950],
          [-2.4329150, 55.0931220],
          [-2.4328470, 55.0930500],
          [-2.4327730, 55.0929770],
          [-2.4326950, 55.0929050],
          [-2.4326100, 55.0928360],
          [-2.4325180, 55.0927600],
          [-2.4324230, 55.0926910],
          [-2.4323320, 55.0926220],
          [-2.4322440, 55.0925580],
          [-2.4321570, 55.0924960],
          [-2.4320710, 55.0924390],
          [-2.4319860, 55.0923830],
          [-2.4318970, 55.0923240],
          [-2.4318100, 55.0922640],
          [-2.4317200, 55.0922060],
          [-2.4316310, 55.0921490],
          [-2.4315410, 55.0920880],
          [-2.4314530, 55.0920340],
          [-2.4313680, 55.0919780],
          [-2.4312810, 55.0919200],
          [-2.4312060, 55.0918630],
          [-2.4311500, 55.0918020],
          [-2.4311190, 55.0917370],
          [-2.4311300, 55.0916720],
          [-2.4311840, 55.0916120],
          [-2.4312620, 55.0915570],
          [-2.4313450, 55.0915080],
          [-2.4314310, 55.0914620],
          [-2.4315230, 55.0914120],
          [-2.4316100, 55.0913670],
          [-2.4316830, 55.0913290],
          [-2.4317490, 55.0912980],
          [-2.4318110, 55.0912710],
          [-2.4318720, 55.0912520],
          [-2.4319320, 55.0912290],
          [-2.4319930, 55.0912100],
          [-2.4320550, 55.0911950],
          [-2.4321190, 55.0911790],
          [-2.4321820, 55.0911640],
          [-2.4322450, 55.0911530],
          [-2.4323090, 55.0911410],
          [-2.4323730, 55.0911330],
          [-2.4324400, 55.0911260],
          [-2.4325120, 55.0911180],
          [-2.4325870, 55.0911070],
          [-2.4326650, 55.0910950],
          [-2.4327420, 55.0910840],
          [-2.4328150, 55.0910720],
          [-2.4328900, 55.0910650],
          [-2.4329670, 55.0910570],
          [-2.4330430, 55.0910460],
          [-2.4331200, 55.0910420],
          [-2.4331970, 55.0910340],
          [-2.4332750, 55.0910300],
          [-2.4333520, 55.0910230],
          [-2.4334240, 55.0910190],
          [-2.4334860, 55.0910150],
          [-2.4335240, 55.0910110],
          [-2.4335360, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335370, 55.0910070],
          [-2.4335590, 55.0910070],
          [-2.4335930, 55.0910110],
          [-2.4336360, 55.0910110],
          [-2.4336890, 55.0910110],
          [-2.4337530, 55.0910070],
          [-2.4338300, 55.0910030],
          [-2.4339110, 55.0910070],
          [-2.4340020, 55.0910110],
          [-2.4340950, 55.0910150],
          [-2.4341930, 55.0910190],
          [-2.4342920, 55.0910220],
          [-2.4343930, 55.0910230],
          [-2.4344960, 55.0910230],
          [-2.4345980, 55.0910230],
          [-2.4346980, 55.0910230],
          [-2.4348060, 55.0910190],
          [-2.4349110, 55.0910190],
          [-2.4350200, 55.0910190],
          [-2.4351250, 55.0910190],
          [-2.4352360, 55.0910150],
          [-2.4353430, 55.0910040],
          [-2.4354480, 55.0909880],
          [-2.4355510, 55.0909620],
          [-2.4356450, 55.0909280],
          [-2.4357270, 55.0908820],
          [-2.4357950, 55.0908270],
          [-2.4358620, 55.0907680],
          [-2.4359250, 55.0907030],
          [-2.4359890, 55.0906380],
          [-2.4360520, 55.0905660],
          [-2.4361150, 55.0904940],
          [-2.4361800, 55.0904220],
          [-2.4362490, 55.0903450],
          [-2.4363190, 55.0902720],
          [-2.4363880, 55.0901970],
          [-2.4364570, 55.0901240],
          [-2.4365240, 55.0900550],
          [-2.4365880, 55.0899870],
          [-2.4366510, 55.0899170],
          [-2.4367120, 55.0898470],
          [-2.4367670, 55.0897780],
          [-2.4368170, 55.0897120],
          [-2.4368590, 55.0896390],
          [-2.4368960, 55.0895670],
          [-2.4369260, 55.0894980],
          [-2.4369530, 55.0894290],
          [-2.4369780, 55.0893610],
          [-2.4370000, 55.0892920],
          [-2.4370180, 55.0892270],
          [-2.4370380, 55.0891620],
          [-2.4370580, 55.0891010],
          [-2.4370800, 55.0890440],
          [-2.4370990, 55.0889940],
          [-2.4371160, 55.0889440],
          [-2.4371300, 55.0889020],
          [-2.4371430, 55.0888600],
          [-2.4371600, 55.0888220],
          [-2.4371780, 55.0887840],
          [-2.4371950, 55.0887520],
          [-2.4372150, 55.0887150],
          [-2.4372310, 55.0886780],
          [-2.4372460, 55.0886390],
          [-2.4372590, 55.0886010],
          [-2.4372750, 55.0885660],
          [-2.4372860, 55.0885250],
          [-2.4372960, 55.0884840],
          [-2.4373100, 55.0884410],
          [-2.4373250, 55.0883990],
          [-2.4373380, 55.0883580],
          [-2.4373500, 55.0883110],
          [-2.4373650, 55.0882660],
          [-2.4373850, 55.0882200],
          [-2.4374050, 55.0881700],
          [-2.4374240, 55.0881210],
          [-2.4374440, 55.0880670],
          [-2.4374630, 55.0880140],
          [-2.4374780, 55.0879600],
          [-2.4374940, 55.0879070],
          [-2.4375110, 55.0878550],
          [-2.4375310, 55.0877960],
          [-2.4375530, 55.0877430],
          [-2.4375760, 55.0876860],
          [-2.4376010, 55.0876290],
          [-2.4376240, 55.0875710],
          [-2.4376460, 55.0875180],
          [-2.4376640, 55.0874610],
          [-2.4376860, 55.0874000],
          [-2.4377080, 55.0873420],
          [-2.4377280, 55.0872830],
          [-2.4377490, 55.0872260],
          [-2.4377700, 55.0871590],
          [-2.4377860, 55.0870950],
          [-2.4378000, 55.0870300],
          [-2.4378130, 55.0869660],
          [-2.4378200, 55.0868960],
          [-2.4378180, 55.0868310],
          [-2.4378070, 55.0867630],
          [-2.4377870, 55.0866980],
          [-2.4377630, 55.0866340],
          [-2.4377320, 55.0865640],
          [-2.4376970, 55.0865000],
          [-2.4376560, 55.0864380],
          [-2.4376090, 55.0863770],
          [-2.4375630, 55.0863130],
          [-2.4375160, 55.0862480],
          [-2.4374680, 55.0861870],
          [-2.4374220, 55.0861220],
          [-2.4373810, 55.0860570],
          [-2.4373420, 55.0859900],
          [-2.4373040, 55.0859230],
          [-2.4372690, 55.0858550],
          [-2.4372360, 55.0857860],
          [-2.4372040, 55.0857180],
          [-2.4371720, 55.0856490],
          [-2.4371420, 55.0855800],
          [-2.4371120, 55.0855090],
          [-2.4370820, 55.0854390],
          [-2.4370510, 55.0853670],
          [-2.4370180, 55.0852990],
          [-2.4369840, 55.0852250],
          [-2.4369510, 55.0851530],
          [-2.4369190, 55.0850820],
          [-2.4368850, 55.0850090],
          [-2.4368510, 55.0849360],
          [-2.4368160, 55.0848600],
          [-2.4367820, 55.0847830],
          [-2.4367490, 55.0847070],
          [-2.4367170, 55.0846310],
          [-2.4366850, 55.0845560],
          [-2.4366490, 55.0844740],
          [-2.4366160, 55.0843940],
          [-2.4365780, 55.0843070],
          [-2.4365360, 55.0842230],
          [-2.4364940, 55.0841430],
          [-2.4364560, 55.0840620],
          [-2.4364190, 55.0839820],
          [-2.4363790, 55.0839060],
          [-2.4363380, 55.0838260],
          [-2.4362980, 55.0837470],
          [-2.4362530, 55.0836660],
          [-2.4362040, 55.0835890],
          [-2.4361660, 55.0835050],
          [-2.4361260, 55.0834210],
          [-2.4360840, 55.0833450],
          [-2.4360390, 55.0832650],
          [-2.4359910, 55.0831850],
          [-2.4359430, 55.0831050],
          [-2.4358910, 55.0830250],
          [-2.4358400, 55.0829460],
          [-2.4357880, 55.0828680],
          [-2.4357350, 55.0827890],
          [-2.4356840, 55.0827080],
          [-2.4356330, 55.0826350],
          [-2.4355810, 55.0825630],
          [-2.4355300, 55.0824960],
          [-2.4354780, 55.0824220],
          [-2.4354280, 55.0823530],
          [-2.4353820, 55.0822830],
          [-2.4353410, 55.0822110],
          [-2.4352970, 55.0821410],
          [-2.4352510, 55.0820670],
          [-2.4352030, 55.0819950],
          [-2.4351480, 55.0819260],
          [-2.4350940, 55.0818570],
          [-2.4350440, 55.0817850],
          [-2.4349950, 55.0817160],
          [-2.4349460, 55.0816400],
          [-2.4348960, 55.0815670],
          [-2.4348440, 55.0814960],
          [-2.4347940, 55.0814230],
          [-2.4347430, 55.0813460],
          [-2.4346930, 55.0812710],
          [-2.4346440, 55.0811900],
          [-2.4346010, 55.0811060],
          [-2.4345610, 55.0810190],
          [-2.4345240, 55.0809270],
          [-2.4344840, 55.0808430],
          [-2.4344380, 55.0807550],
          [-2.4343890, 55.0806760],
          [-2.4343290, 55.0805950],
          [-2.4342580, 55.0805260],
          [-2.4341900, 55.0804620],
          [-2.4341080, 55.0803960],
          [-2.4340370, 55.0803350],
          [-2.4339650, 55.0802700],
          [-2.4339030, 55.0802050],
          [-2.4338440, 55.0801370],
          [-2.4337860, 55.0800680],
          [-2.4337320, 55.0800010],
          [-2.4336760, 55.0799270],
          [-2.4336230, 55.0798580],
          [-2.4335660, 55.0797860],
          [-2.4335110, 55.0797100],
          [-2.4334560, 55.0796340],
          [-2.4333990, 55.0795490],
          [-2.4333430, 55.0794650],
          [-2.4332790, 55.0793830],
          [-2.4332080, 55.0792900],
          [-2.4331160, 55.0791900],
          [-2.4330230, 55.0790880],
          [-2.4329300, 55.0789850],
          [-2.4328390, 55.0788900],
          [-2.4327520, 55.0787940],
          [-2.4326650, 55.0787030],
          [-2.4325700, 55.0786150],
          [-2.4324650, 55.0785350],
          [-2.4323570, 55.0784590],
          [-2.4322600, 55.0784010],
          [-2.4321530, 55.0783490],
          [-2.4320180, 55.0783010],
          [-2.4318810, 55.0782600],
          [-2.4317400, 55.0782250],
          [-2.4316060, 55.0782020],
          [-2.4314530, 55.0781750],
          [-2.4313000, 55.0781520],
          [-2.4311540, 55.0781330],
          [-2.4310090, 55.0781180],
          [-2.4308630, 55.0781060],
          [-2.4307310, 55.0780990],
          [-2.4305980, 55.0780840],
          [-2.4304680, 55.0780720],
          [-2.4303460, 55.0780600],
          [-2.4302260, 55.0780450],
          [-2.4301070, 55.0780340],
          [-2.4299920, 55.0780180],
          [-2.4298790, 55.0780070],
          [-2.4297650, 55.0779920],
          [-2.4296500, 55.0779800],
          [-2.4295400, 55.0779690],
          [-2.4294230, 55.0779540],
          [-2.4293020, 55.0779420],
          [-2.4291890, 55.0779270],
          [-2.4290740, 55.0779120],
          [-2.4289640, 55.0778930],
          [-2.4288430, 55.0778770],
          [-2.4287270, 55.0778620],
          [-2.4286100, 55.0778470],
          [-2.4284960, 55.0778360],
          [-2.4283720, 55.0778200],
          [-2.4282540, 55.0778050],
          [-2.4281310, 55.0777940],
          [-2.4280090, 55.0777820],
          [-2.4278870, 55.0777700],
          [-2.4277690, 55.0777590],
          [-2.4276460, 55.0777510],
          [-2.4275220, 55.0777470],
          [-2.4273960, 55.0777440],
          [-2.4272740, 55.0777360],
          [-2.4271510, 55.0777320],
          [-2.4270140, 55.0777280],
          [-2.4268810, 55.0777280],
          [-2.4267520, 55.0777320],
          [-2.4266220, 55.0777430],
          [-2.4264910, 55.0777550],
          [-2.4263560, 55.0777700],
          [-2.4262270, 55.0777810],
          [-2.4260930, 55.0778000],
          [-2.4259620, 55.0778190],
          [-2.4258340, 55.0778380],
          [-2.4256980, 55.0778570],
          [-2.4255590, 55.0778770],
          [-2.4254320, 55.0778950],
          [-2.4252900, 55.0779150],
          [-2.4251610, 55.0779300],
          [-2.4250350, 55.0779490],
          [-2.4249080, 55.0779640],
          [-2.4247800, 55.0779830],
          [-2.4246510, 55.0780020],
          [-2.4245130, 55.0780220],
          [-2.4243740, 55.0780410],
          [-2.4242310, 55.0780600],
          [-2.4240870, 55.0780750],
          [-2.4239380, 55.0780940],
          [-2.4237890, 55.0781130],
          [-2.4236310, 55.0781320],
          [-2.4234730, 55.0781510],
          [-2.4233180, 55.0781740],
          [-2.4231600, 55.0781930],
          [-2.4230120, 55.0782150],
          [-2.4228460, 55.0782310],
          [-2.4226950, 55.0782540],
          [-2.4225380, 55.0782730],
          [-2.4223780, 55.0782960],
          [-2.4222130, 55.0783230],
          [-2.4220490, 55.0783490],
          [-2.4218900, 55.0783760],
          [-2.4217430, 55.0784020],
          [-2.4215950, 55.0784260],
          [-2.4214560, 55.0784490],
          [-2.4213170, 55.0784760],
          [-2.4211890, 55.0784940],
          [-2.4210510, 55.0785170],
          [-2.4209180, 55.0785400],
          [-2.4207860, 55.0785630],
          [-2.4206650, 55.0785850],
          [-2.4205330, 55.0786050],
          [-2.4204030, 55.0786280],
          [-2.4202800, 55.0786430],
          [-2.4201620, 55.0786550],
          [-2.4200370, 55.0786660],
          [-2.4199170, 55.0786700],
          [-2.4197940, 55.0786670],
          [-2.4196710, 55.0786590],
          [-2.4195420, 55.0786440],
          [-2.4194160, 55.0786290],
          [-2.4192910, 55.0786170],
          [-2.4191700, 55.0786100],
          [-2.4190460, 55.0786060],
          [-2.4189360, 55.0786090],
          [-2.4188160, 55.0786130],
          [-2.4187080, 55.0786240],
          [-2.4186020, 55.0786400],
          [-2.4185040, 55.0786510],
          [-2.4184020, 55.0786660],
          [-2.4183110, 55.0786820],
          [-2.4182250, 55.0786970],
          [-2.4181370, 55.0787120],
          [-2.4180530, 55.0787230],
          [-2.4179680, 55.0787350],
          [-2.4178910, 55.0787460],
          [-2.4178180, 55.0787580],
          [-2.4177430, 55.0787660],
          [-2.4176680, 55.0787730],
          [-2.4175960, 55.0787770],
          [-2.4175220, 55.0787850],
          [-2.4174450, 55.0787920],
          [-2.4173710, 55.0788000],
          [-2.4172950, 55.0788040],
          [-2.4172200, 55.0788080],
          [-2.4171460, 55.0788120],
          [-2.4170780, 55.0788190],
          [-2.4170120, 55.0788270],
          [-2.4169480, 55.0788340],
          [-2.4168840, 55.0788420],
          [-2.4168210, 55.0788490],
          [-2.4167530, 55.0788570],
          [-2.4166900, 55.0788650],
          [-2.4166230, 55.0788690],
          [-2.4165640, 55.0788760],
          [-2.4164960, 55.0788800],
          [-2.4164330, 55.0788880],
          [-2.4163680, 55.0788950],
          [-2.4163080, 55.0789070],
          [-2.4162440, 55.0789140],
          [-2.4161800, 55.0789220],
          [-2.4161160, 55.0789300],
          [-2.4160540, 55.0789370],
          [-2.4159910, 55.0789450],
          [-2.4159280, 55.0789560],
          [-2.4158680, 55.0789640],
          [-2.4158060, 55.0789720],
          [-2.4157400, 55.0789760],
          [-2.4156730, 55.0789830],
          [-2.4156050, 55.0789910],
          [-2.4155370, 55.0789980],
          [-2.4154670, 55.0790100],
          [-2.4153990, 55.0790170],
          [-2.4153290, 55.0790250],
          [-2.4152620, 55.0790360],
          [-2.4151940, 55.0790440],
          [-2.4151230, 55.0790520],
          [-2.4150540, 55.0790630],
          [-2.4149840, 55.0790710],
          [-2.4149130, 55.0790790],
          [-2.4148440, 55.0790860],
          [-2.4147770, 55.0790940],
          [-2.4147070, 55.0791010],
          [-2.4146380, 55.0791130],
          [-2.4145700, 55.0791200],
          [-2.4145000, 55.0791280],
          [-2.4144300, 55.0791360],
          [-2.4143590, 55.0791470],
          [-2.4142900, 55.0791580],
          [-2.4142180, 55.0791700],
          [-2.4141530, 55.0791810],
          [-2.4140810, 55.0791930],
          [-2.4140130, 55.0792080],
          [-2.4139460, 55.0792190],
          [-2.4138810, 55.0792350],
          [-2.4138160, 55.0792460],
          [-2.4137460, 55.0792610],
          [-2.4136800, 55.0792800],
          [-2.4136100, 55.0793000],
          [-2.4135430, 55.0793140],
          [-2.4134680, 55.0793300],
          [-2.4133950, 55.0793490],
          [-2.4133190, 55.0793640],
          [-2.4132400, 55.0793830],
          [-2.4131600, 55.0794060],
          [-2.4130790, 55.0794250],
          [-2.4129980, 55.0794470],
          [-2.4129080, 55.0794710],
          [-2.4128220, 55.0794900],
          [-2.4127250, 55.0795130],
          [-2.4126320, 55.0795320],
          [-2.4125340, 55.0795510],
          [-2.4124380, 55.0795740],
          [-2.4123390, 55.0795970],
          [-2.4122390, 55.0796190],
          [-2.4121330, 55.0796430],
          [-2.4120290, 55.0796660],
          [-2.4119220, 55.0796850],
          [-2.4118130, 55.0797070],
          [-2.4116970, 55.0797270],
          [-2.4115790, 55.0797460],
          [-2.4114570, 55.0797610],
          [-2.4113370, 55.0797760],
          [-2.4112130, 55.0797910],
          [-2.4110860, 55.0797990],
          [-2.4109540, 55.0798070],
          [-2.4108240, 55.0798150],
          [-2.4106910, 55.0798190],
          [-2.4105640, 55.0798220],
          [-2.4104170, 55.0798190],
          [-2.4102730, 55.0798150],
          [-2.4101260, 55.0798040],
          [-2.4099810, 55.0797890],
          [-2.4098210, 55.0797690],
          [-2.4096700, 55.0797440],
          [-2.4094970, 55.0797120],
          [-2.4093380, 55.0796830],
          [-2.4091790, 55.0796440],
          [-2.4090250, 55.0796100],
          [-2.4088810, 55.0795720],
          [-2.4087360, 55.0795340],
          [-2.4086030, 55.0794920],
          [-2.4084780, 55.0794460],
          [-2.4083620, 55.0793970],
          [-2.4082530, 55.0793480],
          [-2.4081520, 55.0792990],
          [-2.4080480, 55.0792490],
          [-2.4079430, 55.0791990],
          [-2.4078350, 55.0791450],
          [-2.4077380, 55.0790950],
          [-2.4076280, 55.0790350],
          [-2.4075230, 55.0789790],
          [-2.4074110, 55.0789200],
          [-2.4073060, 55.0788670],
          [-2.4071960, 55.0788130],
          [-2.4070910, 55.0787620],
          [-2.4069720, 55.0787130],
          [-2.4068630, 55.0786730],
          [-2.4067390, 55.0786410],
          [-2.4066170, 55.0786180],
          [-2.4064880, 55.0786100],
          [-2.4063640, 55.0786100],
          [-2.4062360, 55.0786130],
          [-2.4061150, 55.0786170],
          [-2.4059870, 55.0786210],
          [-2.4058680, 55.0786280],
          [-2.4057520, 55.0786360],
          [-2.4056430, 55.0786430],
          [-2.4055230, 55.0786510],
          [-2.4054100, 55.0786620],
          [-2.4052930, 55.0786740],
          [-2.4051790, 55.0786850],
          [-2.4050610, 55.0787010],
          [-2.4049470, 55.0787190],
          [-2.4048280, 55.0787390],
          [-2.4047160, 55.0787570],
          [-2.4045900, 55.0787770],
          [-2.4044790, 55.0787940],
          [-2.4043470, 55.0788150],
          [-2.4042310, 55.0788340],
          [-2.4041120, 55.0788490],
          [-2.4039980, 55.0788650],
          [-2.4038820, 55.0788650],
          [-2.4037730, 55.0788540],
          [-2.4036640, 55.0788310],
          [-2.4035630, 55.0787970],
          [-2.4034640, 55.0787480],
          [-2.4033700, 55.0786990],
          [-2.4032780, 55.0786410],
          [-2.4031920, 55.0785910],
          [-2.4030950, 55.0785340],
          [-2.4030120, 55.0784820],
          [-2.4029290, 55.0784350],
          [-2.4028610, 55.0783970],
          [-2.4027970, 55.0783630],
          [-2.4027360, 55.0783370],
          [-2.4026730, 55.0783090],
          [-2.4026160, 55.0782860],
          [-2.4025620, 55.0782710],
          [-2.4024990, 55.0782510],
          [-2.4024370, 55.0782360],
          [-2.4023770, 55.0782210],
          [-2.4023130, 55.0782050],
          [-2.4022550, 55.0781940],
          [-2.4021980, 55.0781830],
          [-2.4021330, 55.0781710],
          [-2.4020710, 55.0781600],
          [-2.4020130, 55.0781490],
          [-2.4019480, 55.0781400],
          [-2.4018860, 55.0781290],
          [-2.4018210, 55.0781180],
          [-2.4017480, 55.0781100],
          [-2.4016750, 55.0781020],
          [-2.4016040, 55.0780950],
          [-2.4015270, 55.0780870],
          [-2.4014500, 55.0780790],
          [-2.4013730, 55.0780720],
          [-2.4012950, 55.0780640],
          [-2.4012170, 55.0780560],
          [-2.4011430, 55.0780530],
          [-2.4010580, 55.0780450],
          [-2.4009800, 55.0780370],
          [-2.4009020, 55.0780300],
          [-2.4008300, 55.0780230],
          [-2.4007500, 55.0780180],
          [-2.4006730, 55.0780180],
          [-2.4006050, 55.0780180],
          [-2.4005250, 55.0780140],
          [-2.4004540, 55.0780140],
          [-2.4003770, 55.0780140],
          [-2.4002980, 55.0780180],
          [-2.4002260, 55.0780180],
          [-2.4001540, 55.0780180],
          [-2.4000750, 55.0780180],
          [-2.3999990, 55.0780220],
          [-2.3999220, 55.0780260],
          [-2.3998450, 55.0780330],
          [-2.3997680, 55.0780330],
          [-2.3996930, 55.0780370],
          [-2.3996100, 55.0780410],
          [-2.3995310, 55.0780410],
          [-2.3994430, 55.0780450],
          [-2.3993660, 55.0780520],
          [-2.3992800, 55.0780560],
          [-2.3991980, 55.0780600],
          [-2.3991090, 55.0780600],
          [-2.3990280, 55.0780640],
          [-2.3989390, 55.0780680],
          [-2.3988590, 55.0780710],
          [-2.3987690, 55.0780750],
          [-2.3986890, 55.0780790],
          [-2.3985950, 55.0780830],
          [-2.3985060, 55.0780870],
          [-2.3984120, 55.0780910],
          [-2.3983230, 55.0780940],
          [-2.3982270, 55.0780980],
          [-2.3981330, 55.0781020],
          [-2.3980300, 55.0781060],
          [-2.3979350, 55.0781100],
          [-2.3978330, 55.0781130],
          [-2.3977380, 55.0781170],
          [-2.3976350, 55.0781210],
          [-2.3975400, 55.0781250],
          [-2.3974450, 55.0781320],
          [-2.3973510, 55.0781360],
          [-2.3972410, 55.0781400],
          [-2.3971410, 55.0781440],
          [-2.3970350, 55.0781520],
          [-2.3969360, 55.0781550],
          [-2.3968250, 55.0781630],
          [-2.3967240, 55.0781630],
          [-2.3966170, 55.0781670],
          [-2.3965130, 55.0781740],
          [-2.3964070, 55.0781780],
          [-2.3963100, 55.0781820],
          [-2.3961940, 55.0781900],
          [-2.3960870, 55.0781940],
          [-2.3959760, 55.0782010],
          [-2.3958720, 55.0782050],
          [-2.3957520, 55.0782090],
          [-2.3956450, 55.0782130],
          [-2.3955260, 55.0782160],
          [-2.3954150, 55.0782170],
          [-2.3953000, 55.0782130],
          [-2.3951950, 55.0782020],
          [-2.3950820, 55.0781790],
          [-2.3949850, 55.0781430],
          [-2.3948950, 55.0780970],
          [-2.3948210, 55.0780370],
          [-2.3947570, 55.0779660],
          [-2.3947110, 55.0778880],
          [-2.3946670, 55.0777990],
          [-2.3946320, 55.0777090],
          [-2.3945980, 55.0776170],
          [-2.3945650, 55.0775200],
          [-2.3945310, 55.0774160],
          [-2.3945000, 55.0773120],
          [-2.3944630, 55.0772030],
          [-2.3944230, 55.0770950],
          [-2.3943850, 55.0769880],
          [-2.3943480, 55.0768740],
          [-2.3943140, 55.0767690],
          [-2.3942770, 55.0766710],
          [-2.3942400, 55.0765620],
          [-2.3942020, 55.0764600],
          [-2.3941620, 55.0763630],
          [-2.3941210, 55.0762760],
          [-2.3940760, 55.0761940],
          [-2.3940160, 55.0761120],
          [-2.3939520, 55.0760460],
          [-2.3938640, 55.0759830],
          [-2.3937610, 55.0759300],
          [-2.3936340, 55.0758870],
          [-2.3935020, 55.0758610],
          [-2.3933500, 55.0758480],
          [-2.3932100, 55.0758440],
          [-2.3930540, 55.0758480],
          [-2.3929170, 55.0758480],
          [-2.3927630, 55.0758480],
          [-2.3926260, 55.0758440],
          [-2.3924730, 55.0758360],
          [-2.3923340, 55.0758290],
          [-2.3921850, 55.0758170],
          [-2.3920480, 55.0758060],
          [-2.3919030, 55.0757980],
          [-2.3917730, 55.0757870],
          [-2.3916310, 55.0757750],
          [-2.3915090, 55.0757680],
          [-2.3913770, 55.0757560],
          [-2.3912640, 55.0757460],
          [-2.3911390, 55.0757340],
          [-2.3910270, 55.0757260],
          [-2.3909100, 55.0757180],
          [-2.3908000, 55.0757070],
          [-2.3906840, 55.0757030],
          [-2.3905750, 55.0756960],
          [-2.3904590, 55.0756840],
          [-2.3903480, 55.0756730],
          [-2.3902320, 55.0756650],
          [-2.3901270, 55.0756570],
          [-2.3900130, 55.0756460],
          [-2.3899100, 55.0756380],
          [-2.3898020, 55.0756310],
          [-2.3897000, 55.0756230],
          [-2.3895960, 55.0756150],
          [-2.3895010, 55.0756040],
          [-2.3893960, 55.0755960],
          [-2.3893020, 55.0755890],
          [-2.3892010, 55.0755810],
          [-2.3891080, 55.0755770],
          [-2.3890120, 55.0755690],
          [-2.3889220, 55.0755620],
          [-2.3888220, 55.0755540],
          [-2.3887330, 55.0755500],
          [-2.3886310, 55.0755430],
          [-2.3885400, 55.0755360],
          [-2.3884400, 55.0755270],
          [-2.3883510, 55.0755200],
          [-2.3882560, 55.0755120],
          [-2.3881630, 55.0755050],
          [-2.3880630, 55.0754970],
          [-2.3879740, 55.0754900],
          [-2.3878750, 55.0754820],
          [-2.3877850, 55.0754740],
          [-2.3876850, 55.0754660],
          [-2.3875950, 55.0754590],
          [-2.3874990, 55.0754510],
          [-2.3874120, 55.0754440],
          [-2.3873140, 55.0754320],
          [-2.3872290, 55.0754250],
          [-2.3871320, 55.0754170],
          [-2.3870440, 55.0754090],
          [-2.3869470, 55.0754010],
          [-2.3868590, 55.0753940],
          [-2.3867610, 55.0753860],
          [-2.3866730, 55.0753790],
          [-2.3865670, 55.0753670],
          [-2.3864800, 55.0753640],
          [-2.3863770, 55.0753520],
          [-2.3862860, 55.0753480],
          [-2.3861890, 55.0753400],
          [-2.3861010, 55.0753330],
          [-2.3860080, 55.0753250],
          [-2.3859220, 55.0753180],
          [-2.3858330, 55.0753100],
          [-2.3857530, 55.0753060],
          [-2.3856630, 55.0753020],
          [-2.3855820, 55.0752990],
          [-2.3854900, 55.0752950],
          [-2.3854090, 55.0752940],
          [-2.3853200, 55.0752910],
          [-2.3852430, 55.0752870],
          [-2.3851520, 55.0752830],
          [-2.3850720, 55.0752720],
          [-2.3849910, 55.0752640],
          [-2.3849210, 55.0752540],
          [-2.3848350, 55.0752380],
          [-2.3847620, 55.0752230],
          [-2.3846770, 55.0752070],
          [-2.3846030, 55.0751890],
          [-2.3845270, 55.0751730],
          [-2.3844710, 55.0751590],
          [-2.3844120, 55.0751460],
          [-2.3843650, 55.0751310],
          [-2.3843210, 55.0751200],
          [-2.3842760, 55.0751080],
          [-2.3842360, 55.0750960],
          [-2.3841950, 55.0750850],
          [-2.3841540, 55.0750740],
          [-2.3841110, 55.0750620],
          [-2.3840680, 55.0750470],
          [-2.3840250, 55.0750390],
          [-2.3839880, 55.0750290],
          [-2.3839410, 55.0750160],
          [-2.3838960, 55.0750090],
          [-2.3838530, 55.0750010],
          [-2.3838090, 55.0749940],
          [-2.3837590, 55.0749860],
          [-2.3837140, 55.0749860],
          [-2.3836720, 55.0749860],
          [-2.3836320, 55.0749820],
          [-2.3835860, 55.0749780],
          [-2.3835440, 55.0749700],
          [-2.3835010, 55.0749630],
          [-2.3834640, 55.0749560],
          [-2.3834160, 55.0749440],
          [-2.3833690, 55.0749360],
          [-2.3833230, 55.0749250],
          [-2.3832800, 55.0749140],
          [-2.3832360, 55.0749020],
          [-2.3831930, 55.0748940],
          [-2.3831460, 55.0748870],
          [-2.3831050, 55.0748760],
          [-2.3830580, 55.0748670],
          [-2.3830130, 55.0748560],
          [-2.3829680, 55.0748480],
          [-2.3829260, 55.0748380],
          [-2.3828780, 55.0748250],
          [-2.3828300, 55.0748140],
          [-2.3827840, 55.0748030],
          [-2.3827420, 55.0747920],
          [-2.3826930, 55.0747800],
          [-2.3826460, 55.0747650],
          [-2.3825980, 55.0747490],
          [-2.3825520, 55.0747350],
          [-2.3824990, 55.0747190],
          [-2.3824500, 55.0747000],
          [-2.3824030, 55.0746790],
          [-2.3823490, 55.0746580],
          [-2.3822970, 55.0746350],
          [-2.3822450, 55.0746100],
          [-2.3821890, 55.0745850],
          [-2.3821360, 55.0745590],
          [-2.3820850, 55.0745310],
          [-2.3820230, 55.0744940],
          [-2.3819640, 55.0744640],
          [-2.3819040, 55.0744350],
          [-2.3818330, 55.0744030],
          [-2.3817690, 55.0743700],
          [-2.3816940, 55.0743340],
          [-2.3816280, 55.0743030],
          [-2.3815520, 55.0742580],
          [-2.3814810, 55.0742210],
          [-2.3814030, 55.0741810],
          [-2.3813350, 55.0741470],
          [-2.3812480, 55.0741050],
          [-2.3811740, 55.0740650],
          [-2.3810900, 55.0740180],
          [-2.3810110, 55.0739830],
          [-2.3809150, 55.0739340],
          [-2.3808280, 55.0738900],
          [-2.3807320, 55.0738460],
          [-2.3806450, 55.0738070],
          [-2.3805470, 55.0737620],
          [-2.3804620, 55.0737240],
          [-2.3803570, 55.0736780],
          [-2.3802650, 55.0736420],
          [-2.3801580, 55.0736090],
          [-2.3800590, 55.0735810],
          [-2.3799460, 55.0735520],
          [-2.3798500, 55.0735240],
          [-2.3797530, 55.0734870],
          [-2.3797030, 55.0734410],
          [-2.3796850, 55.0733850],
          [-2.3797080, 55.0733480],
          [-2.3797580, 55.0733110],
          [-2.3798190, 55.0732920],
          [-2.3798800, 55.0732850],
          [-2.3799560, 55.0732730],
          [-2.3800270, 55.0732620],
          [-2.3800900, 55.0732510],
          [-2.3801640, 55.0732390],
          [-2.3802340, 55.0732270],
          [-2.3802970, 55.0732160],
          [-2.3803650, 55.0732040],
          [-2.3804300, 55.0731930],
          [-2.3804900, 55.0731820],
          [-2.3805590, 55.0731700],
          [-2.3806260, 55.0731590],
          [-2.3806860, 55.0731510],
          [-2.3807520, 55.0731470],
          [-2.3808060, 55.0731360],
          [-2.3808550, 55.0731280],
          [-2.3809110, 55.0731170],
          [-2.3809620, 55.0731050],
          [-2.3810090, 55.0731010],
          [-2.3810560, 55.0730940],
          [-2.3811000, 55.0730900],
          [-2.3811460, 55.0730820],
          [-2.3811880, 55.0730790],
          [-2.3812320, 55.0730710],
          [-2.3812720, 55.0730630],
          [-2.3813120, 55.0730550],
          [-2.3813500, 55.0730480],
          [-2.3813930, 55.0730400],
          [-2.3814300, 55.0730290],
          [-2.3814720, 55.0730210],
          [-2.3815060, 55.0730140],
          [-2.3815510, 55.0730060],
          [-2.3815910, 55.0730020],
          [-2.3816310, 55.0729910],
          [-2.3816670, 55.0729870],
          [-2.3817060, 55.0729750],
          [-2.3817430, 55.0729710],
          [-2.3817810, 55.0729640],
          [-2.3818170, 55.0729570],
          [-2.3818590, 55.0729490],
          [-2.3818970, 55.0729380],
          [-2.3819360, 55.0729300],
          [-2.3819720, 55.0729190],
          [-2.3820140, 55.0729030],
          [-2.3820520, 55.0728920],
          [-2.3820870, 55.0728800],
          [-2.3821170, 55.0728730],
          [-2.3821520, 55.0728650],
          [-2.3821880, 55.0728570],
          [-2.3822240, 55.0728460],
          [-2.3822620, 55.0728340],
          [-2.3822960, 55.0728240],
          [-2.3823360, 55.0728110],
          [-2.3823740, 55.0728000],
          [-2.3824100, 55.0727890],
          [-2.3824460, 55.0727780],
          [-2.3824850, 55.0727690],
          [-2.3825240, 55.0727580],
          [-2.3825630, 55.0727470],
          [-2.3826010, 55.0727360],
          [-2.3826420, 55.0727240],
          [-2.3826810, 55.0727120],
          [-2.3827170, 55.0727050],
          [-2.3827500, 55.0726940],
          [-2.3827900, 55.0726820],
          [-2.3828290, 55.0726700],
          [-2.3828670, 55.0726590],
          [-2.3829030, 55.0726480],
          [-2.3829440, 55.0726360],
          [-2.3829810, 55.0726240],
          [-2.3830180, 55.0726130],
          [-2.3830530, 55.0726030],
          [-2.3830930, 55.0725900],
          [-2.3831240, 55.0725790],
          [-2.3831570, 55.0725670],
          [-2.3831880, 55.0725600],
          [-2.3832270, 55.0725480],
          [-2.3832620, 55.0725370],
          [-2.3832980, 55.0725250],
          [-2.3833310, 55.0725150],
          [-2.3833670, 55.0725020],
          [-2.3834040, 55.0724910],
          [-2.3834370, 55.0724800],
          [-2.3834680, 55.0724730],
          [-2.3835040, 55.0724600],
          [-2.3835360, 55.0724560],
          [-2.3835670, 55.0724450],
          [-2.3835990, 55.0724380],
          [-2.3836320, 55.0724300],
          [-2.3836680, 55.0724220],
          [-2.3837050, 55.0724110],
          [-2.3837440, 55.0723990],
          [-2.3837820, 55.0723890],
          [-2.3838230, 55.0723770],
          [-2.3838670, 55.0723650],
          [-2.3839110, 55.0723500],
          [-2.3839490, 55.0723300],
          [-2.3839950, 55.0723040],
          [-2.3840420, 55.0722890],
          [-2.3840850, 55.0722750],
          [-2.3841350, 55.0722580],
          [-2.3841810, 55.0722430],
          [-2.3842200, 55.0722300],
          [-2.3842700, 55.0722160],
          [-2.3843170, 55.0722010],
          [-2.3843640, 55.0721900],
          [-2.3844050, 55.0721760],
          [-2.3844530, 55.0721590],
          [-2.3845000, 55.0721440],
          [-2.3845420, 55.0721300],
          [-2.3845910, 55.0721170],
          [-2.3846370, 55.0721020],
          [-2.3846840, 55.0720870],
          [-2.3847260, 55.0720730],
          [-2.3847790, 55.0720560],
          [-2.3848240, 55.0720410],
          [-2.3848670, 55.0720270],
          [-2.3849180, 55.0720110],
          [-2.3849670, 55.0719990],
          [-2.3850120, 55.0719850],
          [-2.3850620, 55.0719680],
          [-2.3851070, 55.0719540],
          [-2.3851520, 55.0719390],
          [-2.3852030, 55.0719230],
          [-2.3852530, 55.0719080],
          [-2.3852980, 55.0718930],
          [-2.3853490, 55.0718730],
          [-2.3853940, 55.0718550],
          [-2.3854410, 55.0718400],
          [-2.3854950, 55.0718240],
          [-2.3855460, 55.0718090],
          [-2.3855910, 55.0717950],
          [-2.3856420, 55.0717780],
          [-2.3856890, 55.0717590],
          [-2.3857290, 55.0717450],
          [-2.3857790, 55.0717250],
          [-2.3858260, 55.0717050],
          [-2.3858690, 55.0716890],
          [-2.3859280, 55.0716670],
          [-2.3859790, 55.0716520],
          [-2.3860270, 55.0716350],
          [-2.3860870, 55.0716140],
          [-2.3861430, 55.0715950],
          [-2.3861930, 55.0715770],
          [-2.3862570, 55.0715530],
          [-2.3863140, 55.0715300],
          [-2.3863650, 55.0715060],
          [-2.3864240, 55.0714820],
          [-2.3864820, 55.0714540],
          [-2.3865340, 55.0714310],
          [-2.3866060, 55.0713930],
          [-2.3866630, 55.0713650],
          [-2.3867310, 55.0713360],
          [-2.3867910, 55.0713080],
          [-2.3868610, 55.0712750],
          [-2.3869230, 55.0712470],
          [-2.3869950, 55.0712130],
          [-2.3870600, 55.0711820],
          [-2.3871390, 55.0711420],
          [-2.3872120, 55.0711100],
          [-2.3872980, 55.0710700],
          [-2.3873780, 55.0710320],
          [-2.3874770, 55.0709890],
          [-2.3875630, 55.0709470],
          [-2.3876670, 55.0708980],
          [-2.3877660, 55.0708520],
          [-2.3878830, 55.0707990],
          [-2.3879840, 55.0707540],
          [-2.3881240, 55.0706880],
          [-2.3882490, 55.0706320],
          [-2.3883880, 55.0705650],
          [-2.3885240, 55.0705010],
          [-2.3886560, 55.0704320],
          [-2.3887830, 55.0703660],
          [-2.3889100, 55.0703100],
          [-2.3890460, 55.0702440],
          [-2.3891820, 55.0701780],
          [-2.3893290, 55.0701080],
          [-2.3894700, 55.0700430],
          [-2.3896190, 55.0699710],
          [-2.3897550, 55.0699080],
          [-2.3898810, 55.0698350],
          [-2.3900030, 55.0697700],
          [-2.3901260, 55.0697100],
          [-2.3902510, 55.0696520],
          [-2.3903750, 55.0695950],
          [-2.3904840, 55.0695420],
          [-2.3905960, 55.0694900],
          [-2.3906730, 55.0694520],
          [-2.3907600, 55.0694140],
          [-2.3908250, 55.0693850],
          [-2.3908870, 55.0693570],
          [-2.3909360, 55.0693320],
          [-2.3909900, 55.0693060],
          [-2.3910390, 55.0692870],
          [-2.3910850, 55.0692660],
          [-2.3911370, 55.0692410],
          [-2.3911820, 55.0692230],
          [-2.3912250, 55.0692020],
          [-2.3912750, 55.0691800],
          [-2.3913200, 55.0691580],
          [-2.3913590, 55.0691370],
          [-2.3914050, 55.0691160],
          [-2.3914450, 55.0690960],
          [-2.3914820, 55.0690790],
          [-2.3915280, 55.0690620],
          [-2.3915660, 55.0690430],
          [-2.3915970, 55.0690250],
          [-2.3916330, 55.0690100],
          [-2.3916740, 55.0689900],
          [-2.3917080, 55.0689750],
          [-2.3917440, 55.0689590],
          [-2.3917740, 55.0689420],
          [-2.3918160, 55.0689210],
          [-2.3918520, 55.0689060],
          [-2.3918880, 55.0688870],
          [-2.3919240, 55.0688690],
          [-2.3919630, 55.0688520],
          [-2.3919990, 55.0688370],
          [-2.3920300, 55.0688220],
          [-2.3920570, 55.0688080],
          [-2.3920900, 55.0687910],
          [-2.3921230, 55.0687760],
          [-2.3921600, 55.0687610],
          [-2.3921940, 55.0687430],
          [-2.3922330, 55.0687260],
          [-2.3922650, 55.0687110],
          [-2.3922980, 55.0686960],
          [-2.3923280, 55.0686820],
          [-2.3923700, 55.0686620],
          [-2.3924070, 55.0686430],
          [-2.3924430, 55.0686310],
          [-2.3924760, 55.0686140],
          [-2.3925150, 55.0685970],
          [-2.3925470, 55.0685810],
          [-2.3925780, 55.0685660],
          [-2.3926100, 55.0685520],
          [-2.3926440, 55.0685360],
          [-2.3926760, 55.0685210],
          [-2.3927080, 55.0685050],
          [-2.3927390, 55.0684910],
          [-2.3927730, 55.0684750],
          [-2.3928050, 55.0684590],
          [-2.3928350, 55.0684440],
          [-2.3928620, 55.0684300],
          [-2.3928960, 55.0684130],
          [-2.3929250, 55.0683980],
          [-2.3929530, 55.0683830],
          [-2.3929840, 55.0683680],
          [-2.3930160, 55.0683520],
          [-2.3930480, 55.0683370],
          [-2.3930790, 55.0683250],
          [-2.3931110, 55.0683100],
          [-2.3931420, 55.0682950],
          [-2.3931740, 55.0682800],
          [-2.3932040, 55.0682640],
          [-2.3932360, 55.0682470],
          [-2.3932670, 55.0682300],
          [-2.3932990, 55.0682150],
          [-2.3933310, 55.0681990],
          [-2.3933640, 55.0681840],
          [-2.3933990, 55.0681650],
          [-2.3934360, 55.0681500],
          [-2.3934740, 55.0681350],
          [-2.3935130, 55.0681160],
          [-2.3935510, 55.0681000],
          [-2.3935880, 55.0680810],
          [-2.3936280, 55.0680620],
          [-2.3936690, 55.0680430],
          [-2.3937110, 55.0680240],
          [-2.3937570, 55.0680090],
          [-2.3938040, 55.0679900],
          [-2.3938470, 55.0679670],
          [-2.3938940, 55.0679480],
          [-2.3939420, 55.0679250],
          [-2.3939920, 55.0679020],
          [-2.3940400, 55.0678790],
          [-2.3940840, 55.0678570],
          [-2.3941310, 55.0678370],
          [-2.3941780, 55.0678110],
          [-2.3942300, 55.0677880],
          [-2.3942840, 55.0677650],
          [-2.3943340, 55.0677420],
          [-2.3943860, 55.0677190],
          [-2.3944400, 55.0676930],
          [-2.3944940, 55.0676660],
          [-2.3945460, 55.0676390],
          [-2.3946010, 55.0676120],
          [-2.3946560, 55.0675850],
          [-2.3947130, 55.0675590],
          [-2.3947740, 55.0675280],
          [-2.3948320, 55.0674980],
          [-2.3948890, 55.0674750],
          [-2.3949470, 55.0674480],
          [-2.3950050, 55.0674220],
          [-2.3950640, 55.0673910],
          [-2.3951240, 55.0673600],
          [-2.3951820, 55.0673350],
          [-2.3952430, 55.0673070],
          [-2.3953050, 55.0672770],
          [-2.3953680, 55.0672420],
          [-2.3954290, 55.0672150],
          [-2.3954900, 55.0671890],
          [-2.3955490, 55.0671590],
          [-2.3956140, 55.0671320],
          [-2.3956760, 55.0671050],
          [-2.3957340, 55.0670750],
          [-2.3957920, 55.0670440],
          [-2.3958530, 55.0670140],
          [-2.3959190, 55.0669790],
          [-2.3959870, 55.0669440],
          [-2.3960510, 55.0669140],
          [-2.3961210, 55.0668840],
          [-2.3961950, 55.0668500],
          [-2.3962690, 55.0668150],
          [-2.3963460, 55.0667770],
          [-2.3964240, 55.0667390],
          [-2.3965010, 55.0667050],
          [-2.3965820, 55.0666660],
          [-2.3966590, 55.0666320],
          [-2.3967370, 55.0665940],
          [-2.3968130, 55.0665560],
          [-2.3968900, 55.0665180],
          [-2.3969640, 55.0664790],
          [-2.3970350, 55.0664450],
          [-2.3971100, 55.0664070],
          [-2.3971940, 55.0663690],
          [-2.3972780, 55.0663310],
          [-2.3973630, 55.0662890],
          [-2.3974500, 55.0662470],
          [-2.3975410, 55.0662050],
          [-2.3976330, 55.0661630],
          [-2.3977240, 55.0661220],
          [-2.3978180, 55.0660830],
          [-2.3979130, 55.0660480],
          [-2.3979980, 55.0660180],
          [-2.3980800, 55.0659910],
          [-2.3981520, 55.0659720],
          [-2.3982190, 55.0659490],
          [-2.3982820, 55.0659300],
          [-2.3983400, 55.0659110],
          [-2.3983970, 55.0658950],
          [-2.3984520, 55.0658840],
          [-2.3985090, 55.0658690],
          [-2.3985700, 55.0658540],
          [-2.3986320, 55.0658420],
          [-2.3986920, 55.0658300],
          [-2.3987510, 55.0658150],
          [-2.3988080, 55.0658040],
          [-2.3988640, 55.0657890],
          [-2.3989170, 55.0657730],
          [-2.3989700, 55.0657620],
          [-2.3990230, 55.0657430],
          [-2.3990770, 55.0657280],
          [-2.3991320, 55.0657120],
          [-2.3991890, 55.0656970],
          [-2.3992460, 55.0656740],
          [-2.3993010, 55.0656590],
          [-2.3993520, 55.0656360],
          [-2.3994030, 55.0656140],
          [-2.3994560, 55.0655900],
          [-2.3995100, 55.0655750],
          [-2.3995690, 55.0655600],
          [-2.3996240, 55.0655410],
          [-2.3996780, 55.0655250],
          [-2.3997310, 55.0655100],
          [-2.3997820, 55.0654990],
          [-2.3998340, 55.0654830],
          [-2.3998870, 55.0654680],
          [-2.3999390, 55.0654530],
          [-2.3999890, 55.0654340],
          [-2.4000420, 55.0654190],
          [-2.4000920, 55.0654030],
          [-2.4001440, 55.0653880],
          [-2.4001950, 55.0653730],
          [-2.4002470, 55.0653570],
          [-2.4002980, 55.0653390],
          [-2.4003510, 55.0653270],
          [-2.4004040, 55.0653120],
          [-2.4004550, 55.0652970],
          [-2.4005060, 55.0652810],
          [-2.4005550, 55.0652660],
          [-2.4006040, 55.0652470],
          [-2.4006560, 55.0652320],
          [-2.4007040, 55.0652160],
          [-2.4007520, 55.0652010],
          [-2.4007990, 55.0651860],
          [-2.4008490, 55.0651710],
          [-2.4009000, 55.0651560],
          [-2.4009510, 55.0651440],
          [-2.4010000, 55.0651290],
          [-2.4010510, 55.0651130],
          [-2.4011020, 55.0650980],
          [-2.4011540, 55.0650870],
          [-2.4012040, 55.0650750],
          [-2.4012540, 55.0650600],
          [-2.4013040, 55.0650450],
          [-2.4013550, 55.0650260],
          [-2.4014070, 55.0650110],
          [-2.4014570, 55.0649990],
          [-2.4015080, 55.0649840],
          [-2.4015640, 55.0649650],
          [-2.4016180, 55.0649450],
          [-2.4016670, 55.0649300],
          [-2.4017190, 55.0649110],
          [-2.4017700, 55.0648960],
          [-2.4018240, 55.0648770],
          [-2.4018810, 55.0648580],
          [-2.4019390, 55.0648390],
          [-2.4019960, 55.0648200],
          [-2.4020540, 55.0648040],
          [-2.4021120, 55.0647850],
          [-2.4021740, 55.0647660],
          [-2.4022370, 55.0647470],
          [-2.4023030, 55.0647320],
          [-2.4023670, 55.0647100],
          [-2.4024340, 55.0646900],
          [-2.4025020, 55.0646710],
          [-2.4025710, 55.0646480],
          [-2.4026440, 55.0646250],
          [-2.4027190, 55.0646030],
          [-2.4028000, 55.0645760],
          [-2.4028810, 55.0645530],
          [-2.4029650, 55.0645260],
          [-2.4030510, 55.0645000],
          [-2.4031430, 55.0644730],
          [-2.4032340, 55.0644460],
          [-2.4033220, 55.0644200],
          [-2.4034160, 55.0643890],
          [-2.4035070, 55.0643630],
          [-2.4035960, 55.0643360],
          [-2.4036910, 55.0643090],
          [-2.4037860, 55.0642780],
          [-2.4038840, 55.0642480],
          [-2.4039830, 55.0642170],
          [-2.4040820, 55.0641830],
          [-2.4041840, 55.0641520],
          [-2.4042880, 55.0641180],
          [-2.4043890, 55.0640860],
          [-2.4045020, 55.0640500],
          [-2.4046110, 55.0640150],
          [-2.4047230, 55.0639810],
          [-2.4048430, 55.0639430],
          [-2.4049630, 55.0639050],
          [-2.4050900, 55.0638630],
          [-2.4052150, 55.0638210],
          [-2.4053480, 55.0637830],
          [-2.4054830, 55.0637370],
          [-2.4056230, 55.0636950],
          [-2.4057670, 55.0636490],
          [-2.4059120, 55.0636030],
          [-2.4060630, 55.0635580],
          [-2.4062200, 55.0635080],
          [-2.4063800, 55.0634590],
          [-2.4065410, 55.0634090],
          [-2.4067040, 55.0633560],
          [-2.4068720, 55.0633020],
          [-2.4070410, 55.0632490],
          [-2.4072090, 55.0631960],
          [-2.4073780, 55.0631420],
          [-2.4075480, 55.0630920],
          [-2.4077160, 55.0630400],
          [-2.4078840, 55.0629860],
          [-2.4080520, 55.0629330],
          [-2.4082240, 55.0628790],
          [-2.4083890, 55.0628260],
          [-2.4085420, 55.0627790],
          [-2.4087250, 55.0627220],
          [-2.4088950, 55.0626690],
          [-2.4090670, 55.0626150],
          [-2.4092330, 55.0625610],
          [-2.4094160, 55.0625020],
          [-2.4095830, 55.0624440],
          [-2.4097390, 55.0623870],
          [-2.4098780, 55.0623300],
          [-2.4100040, 55.0622650],
          [-2.4101150, 55.0621960],
          [-2.4102130, 55.0621310],
          [-2.4103060, 55.0620600],
          [-2.4103950, 55.0619900],
          [-2.4104800, 55.0619250],
          [-2.4105610, 55.0618620],
          [-2.4106460, 55.0618000],
          [-2.4107270, 55.0617340],
          [-2.4108060, 55.0616780],
          [-2.4108830, 55.0616160],
          [-2.4109590, 55.0615620],
          [-2.4110320, 55.0615060],
          [-2.4111000, 55.0614560],
          [-2.4111670, 55.0614020],
          [-2.4112330, 55.0613540],
          [-2.4113000, 55.0613070],
          [-2.4113660, 55.0612570],
          [-2.4114280, 55.0612090],
          [-2.4114920, 55.0611620],
          [-2.4115540, 55.0611160],
          [-2.4116140, 55.0610740],
          [-2.4116720, 55.0610280],
          [-2.4117360, 55.0609860],
          [-2.4117960, 55.0609480],
          [-2.4118570, 55.0609060],
          [-2.4119160, 55.0608690],
          [-2.4119810, 55.0608330],
          [-2.4120470, 55.0607990],
          [-2.4121120, 55.0607690],
          [-2.4121800, 55.0607420],
          [-2.4122530, 55.0607120],
          [-2.4123270, 55.0606810],
          [-2.4124010, 55.0606510],
          [-2.4124760, 55.0606200],
          [-2.4125510, 55.0605930],
          [-2.4126240, 55.0605630],
          [-2.4126970, 55.0605360],
          [-2.4127690, 55.0605060],
          [-2.4128420, 55.0604800],
          [-2.4129270, 55.0604520],
          [-2.4130060, 55.0604210],
          [-2.4130800, 55.0603950],
          [-2.4131530, 55.0603680],
          [-2.4132250, 55.0603410],
          [-2.4132940, 55.0603180],
          [-2.4133630, 55.0602920],
          [-2.4134330, 55.0602690],
          [-2.4135010, 55.0602420],
          [-2.4135700, 55.0602160],
          [-2.4136390, 55.0601930],
          [-2.4137070, 55.0601660],
          [-2.4137740, 55.0601430],
          [-2.4138370, 55.0601210],
          [-2.4139050, 55.0600940],
          [-2.4139700, 55.0600710],
          [-2.4140330, 55.0600440],
          [-2.4140990, 55.0600210],
          [-2.4141620, 55.0599940],
          [-2.4142250, 55.0599710],
          [-2.4142910, 55.0599450],
          [-2.4143600, 55.0599180],
          [-2.4144270, 55.0598910],
          [-2.4144930, 55.0598650],
          [-2.4145580, 55.0598350],
          [-2.4146250, 55.0598070],
          [-2.4146940, 55.0597770],
          [-2.4147600, 55.0597470],
          [-2.4148300, 55.0597130],
          [-2.4149010, 55.0596780],
          [-2.4149780, 55.0596480],
          [-2.4150590, 55.0596100],
          [-2.4151430, 55.0595710],
          [-2.4152310, 55.0595300],
          [-2.4153230, 55.0594870],
          [-2.4154100, 55.0594420],
          [-2.4154950, 55.0593930],
          [-2.4155770, 55.0593390],
          [-2.4156480, 55.0592780],
          [-2.4157110, 55.0592170],
          [-2.4157660, 55.0591540],
          [-2.4158240, 55.0590880],
          [-2.4158900, 55.0590240],
          [-2.4159650, 55.0589620],
          [-2.4160520, 55.0589030],
          [-2.4161470, 55.0588500],
          [-2.4162460, 55.0588010],
          [-2.4163540, 55.0587520],
          [-2.4164690, 55.0587020],
          [-2.4165870, 55.0586560],
          [-2.4167130, 55.0586100],
          [-2.4168460, 55.0585680],
          [-2.4169860, 55.0585300],
          [-2.4171330, 55.0584960],
          [-2.4172890, 55.0584680],
          [-2.4174470, 55.0584420],
          [-2.4176040, 55.0584190],
          [-2.4177630, 55.0583920],
          [-2.4179240, 55.0583660],
          [-2.4180890, 55.0583420],
          [-2.4182550, 55.0583200],
          [-2.4184180, 55.0582940],
          [-2.4185930, 55.0582660],
          [-2.4187610, 55.0582360],
          [-2.4189330, 55.0581980],
          [-2.4191060, 55.0581560],
          [-2.4192940, 55.0581030],
          [-2.4194780, 55.0580420],
          [-2.4196610, 55.0579850],
          [-2.4198420, 55.0579200],
          [-2.4200300, 55.0578550],
          [-2.4202090, 55.0577950],
          [-2.4203860, 55.0577420],
          [-2.4205690, 55.0576840],
          [-2.4207480, 55.0576190],
          [-2.4209210, 55.0575500],
          [-2.4211010, 55.0574890],
          [-2.4212810, 55.0574280],
          [-2.4214630, 55.0573630],
          [-2.4216450, 55.0572980],
          [-2.4218210, 55.0572340],
          [-2.4220000, 55.0571740],
          [-2.4221930, 55.0571110],
          [-2.4223740, 55.0570480],
          [-2.4225730, 55.0569780],
          [-2.4227580, 55.0569130],
          [-2.4229530, 55.0568450],
          [-2.4231540, 55.0567830],
          [-2.4233520, 55.0567150],
          [-2.4235380, 55.0566500],
          [-2.4237190, 55.0565790],
          [-2.4238940, 55.0565070],
          [-2.4240730, 55.0564300],
          [-2.4242510, 55.0563580],
          [-2.4244330, 55.0562800],
          [-2.4246090, 55.0562130],
          [-2.4248000, 55.0561380],
          [-2.4249910, 55.0560610],
          [-2.4251790, 55.0559870],
          [-2.4253740, 55.0559110],
          [-2.4255470, 55.0558270],
          [-2.4257050, 55.0557470],
          [-2.4258580, 55.0556760],
          [-2.4260070, 55.0556100],
          [-2.4261610, 55.0555400],
          [-2.4262970, 55.0554680],
          [-2.4264340, 55.0553950],
          [-2.4265540, 55.0553320],
          [-2.4266850, 55.0552650],
          [-2.4268030, 55.0552010],
          [-2.4269240, 55.0551470],
          [-2.4270390, 55.0550840],
          [-2.4271520, 55.0550250],
          [-2.4272590, 55.0549720],
          [-2.4273700, 55.0549180],
          [-2.4274780, 55.0548640],
          [-2.4275790, 55.0548070],
          [-2.4276810, 55.0547550],
          [-2.4277810, 55.0547050],
          [-2.4278830, 55.0546510],
          [-2.4279810, 55.0546010],
          [-2.4280780, 55.0545510],
          [-2.4281650, 55.0545040],
          [-2.4282560, 55.0544580],
          [-2.4283490, 55.0544120],
          [-2.4284420, 55.0543650],
          [-2.4285250, 55.0543230],
          [-2.4286220, 55.0542770],
          [-2.4287060, 55.0542380],
          [-2.4288050, 55.0541890],
          [-2.4289040, 55.0541530],
          [-2.4290210, 55.0541130],
          [-2.4291310, 55.0540870],
          [-2.4292530, 55.0540630],
          [-2.4293640, 55.0540380],
          [-2.4294930, 55.0540100],
          [-2.4296070, 55.0539850],
          [-2.4297430, 55.0539560],
          [-2.4298570, 55.0539320],
          [-2.4299920, 55.0538960],
          [-2.4301100, 55.0538670],
          [-2.4302510, 55.0538280],
          [-2.4303800, 55.0537950],
          [-2.4305300, 55.0537590],
          [-2.4306620, 55.0537280],
          [-2.4308130, 55.0536900],
          [-2.4309730, 55.0536480],
          [-2.4311320, 55.0536090],
          [-2.4312960, 55.0535640],
          [-2.4314510, 55.0535280],
          [-2.4316280, 55.0534810],
          [-2.4318120, 55.0534370],
          [-2.4319900, 55.0533920],
          [-2.4321660, 55.0533540],
          [-2.4323520, 55.0533080],
          [-2.4325340, 55.0532670],
          [-2.4327230, 55.0532230],
          [-2.4329150, 55.0531810],
          [-2.4330980, 55.0531440],
          [-2.4332770, 55.0531030],
          [-2.4334520, 55.0530600],
          [-2.4336220, 55.0530090],
          [-2.4338010, 55.0529580],
          [-2.4339620, 55.0529130],
          [-2.4341440, 55.0528630],
          [-2.4343100, 55.0528230],
          [-2.4344810, 55.0527850],
          [-2.4346320, 55.0527510],
          [-2.4347450, 55.0527230],
          [-2.4348380, 55.0526970],
          [-2.4348870, 55.0526830],
          [-2.4349120, 55.0526810],
          [-2.4349120, 55.0526850],
          [-2.4349090, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349080, 55.0526850],
          [-2.4349530, 55.0526740],
          [-2.4349870, 55.0526660],
          [-2.4350400, 55.0526620],
          [-2.4351040, 55.0526540],
          [-2.4351800, 55.0526510],
          [-2.4352770, 55.0526360],
          [-2.4353750, 55.0526050],
          [-2.4354510, 55.0525560],
          [-2.4354880, 55.0524920],
          [-2.4355000, 55.0524230],
          [-2.4355100, 55.0523510],
          [-2.4355200, 55.0522780],
          [-2.4355280, 55.0522210],
          [-2.4355320, 55.0521750],
          [-2.4355220, 55.0521360],
          [-2.4354940, 55.0521090],
          [-2.4354590, 55.0520900],
          [-2.4354390, 55.0520750],
          [-2.4354320, 55.0520670],
          [-2.4354300, 55.0520630],
          [-2.4354280, 55.0520550],
          [-2.4354280, 55.0520550],
          [-2.4354280, 55.0520260],
          [-2.4354400, 55.0520100],
          [-2.4354680, 55.0519870],
          [-2.4355040, 55.0519610],
          [-2.4355260, 55.0519220],
          [-2.4355270, 55.0518810],
          [-2.4355220, 55.0518350],
          [-2.4355220, 55.0517890],
          [-2.4355200, 55.0517440],
          [-2.4355220, 55.0517010],
          [-2.4355230, 55.0516650],
          [-2.4355200, 55.0516250],
          [-2.4355130, 55.0515870],
          [-2.4355050, 55.0515490],
          [-2.4355050, 55.0515030],
          [-2.4355130, 55.0514570],
          [-2.4355210, 55.0514110],
          [-2.4355270, 55.0513580],
          [-2.4355320, 55.0513090],
          [-2.4355360, 55.0512520],
          [-2.4355400, 55.0511950],
          [-2.4355420, 55.0511370],
          [-2.4355400, 55.0510760],
          [-2.4355430, 55.0510210],
          [-2.4355550, 55.0509590],
          [-2.4355710, 55.0509010],
          [-2.4355860, 55.0508430],
          [-2.4355900, 55.0507940],
          [-2.4355920, 55.0507400],
          [-2.4355950, 55.0506910],
          [-2.4355980, 55.0506420],
          [-2.4355980, 55.0505880],
          [-2.4356020, 55.0505320],
          [-2.4356050, 55.0504730],
          [-2.4356020, 55.0504120],
          [-2.4356070, 55.0503510],
          [-2.4356170, 55.0502830],
          [-2.4356250, 55.0502180],
          [-2.4356220, 55.0501530],
          [-2.4356230, 55.0500880],
          [-2.4356310, 55.0500250],
          [-2.4356380, 55.0499620],
          [-2.4356470, 55.0499010],
          [-2.4356550, 55.0498410],
          [-2.4356610, 55.0497860],
          [-2.4356620, 55.0497300],
          [-2.4356650, 55.0496720],
          [-2.4356780, 55.0496200],
          [-2.4356950, 55.0495690],
          [-2.4357080, 55.0495160],
          [-2.4357210, 55.0494670],
          [-2.4357300, 55.0494140],
          [-2.4357330, 55.0493590],
          [-2.4357370, 55.0493100],
          [-2.4357380, 55.0492630],
          [-2.4357480, 55.0492110],
          [-2.4357650, 55.0491570],
          [-2.4357810, 55.0491080],
          [-2.4358000, 55.0490580],
          [-2.4358130, 55.0490120],
          [-2.4358040, 55.0489680],
          [-2.4357940, 55.0489240],
          [-2.4357850, 55.0488800],
          [-2.4357810, 55.0488340],
          [-2.4357950, 55.0487850],
          [-2.4358150, 55.0487370],
          [-2.4358050, 55.0486840],
          [-2.4358200, 55.0486500],
          [-2.4358280, 55.0486000],
          [-2.4358330, 55.0485510],
          [-2.4358360, 55.0485020],
          [-2.4358370, 55.0484470],
          [-2.4358350, 55.0484010],
          [-2.4358380, 55.0483560],
          [-2.4358400, 55.0483100],
          [-2.4358370, 55.0482640],
          [-2.4358310, 55.0482240],
          [-2.4358300, 55.0481800],
          [-2.4358330, 55.0481380],
          [-2.4358400, 55.0480930],
          [-2.4358450, 55.0480470],
          [-2.4358500, 55.0479970],
          [-2.4358550, 55.0479440],
          [-2.4358600, 55.0478970],
          [-2.4358620, 55.0478410],
          [-2.4358630, 55.0477840],
          [-2.4358680, 55.0477300],
          [-2.4358730, 55.0476770],
          [-2.4358800, 55.0476160],
          [-2.4358850, 55.0475550],
          [-2.4358910, 55.0474920],
          [-2.4359020, 55.0474250],
          [-2.4359100, 55.0473560],
          [-2.4359170, 55.0472850],
          [-2.4359230, 55.0472120],
          [-2.4359280, 55.0471390],
          [-2.4359330, 55.0470630],
          [-2.4359400, 55.0469900],
          [-2.4359420, 55.0469210],
          [-2.4359420, 55.0468570],
          [-2.4359480, 55.0467940],
          [-2.4359600, 55.0467270],
          [-2.4359670, 55.0466660],
          [-2.4359720, 55.0466010],
          [-2.4359830, 55.0465440],
          [-2.4359930, 55.0464930],
          [-2.4360030, 55.0464450],
          [-2.4360130, 55.0464070],
          [-2.4360170, 55.0463720],
          [-2.4360130, 55.0463450],
          [-2.4360130, 55.0463110],
          [-2.4360170, 55.0462800],
          [-2.4360230, 55.0462540],
          [-2.4360260, 55.0462230],
          [-2.4360300, 55.0461960],
          [-2.4360330, 55.0461700],
          [-2.4360410, 55.0461470],
          [-2.4360460, 55.0461250],
          [-2.4360520, 55.0461010],
          [-2.4360550, 55.0460820],
          [-2.4360550, 55.0460630],
          [-2.4360570, 55.0460440],
          [-2.4360600, 55.0460250],
          [-2.4360630, 55.0460060],
          [-2.4360700, 55.0459870],
          [-2.4360720, 55.0459640],
          [-2.4360700, 55.0459450],
          [-2.4360720, 55.0459260],
          [-2.4360770, 55.0459030],
          [-2.4360780, 55.0458800],
          [-2.4360830, 55.0458580],
          [-2.4360880, 55.0458390],
          [-2.4360900, 55.0458150],
          [-2.4360930, 55.0457920],
          [-2.4360980, 55.0457650],
          [-2.4361070, 55.0457390],
          [-2.4361130, 55.0457120],
          [-2.4361150, 55.0456850],
          [-2.4361120, 55.0456590],
          [-2.4361050, 55.0456240],
          [-2.4360990, 55.0455920],
          [-2.4360900, 55.0455520],
          [-2.4360830, 55.0455100],
          [-2.4360790, 55.0454660],
          [-2.4360680, 55.0454150],
          [-2.4360570, 55.0453690],
          [-2.4360480, 55.0453160],
          [-2.4360430, 55.0452590],
          [-2.4360370, 55.0452010],
          [-2.4360280, 55.0451400],
          [-2.4360170, 55.0450840],
          [-2.4360120, 55.0450260],
          [-2.4360070, 55.0449680],
          [-2.4360050, 55.0449170],
          [-2.4360020, 55.0448580],
          [-2.4359950, 55.0448040],
          [-2.4359890, 55.0447510],
          [-2.4359870, 55.0447010],
          [-2.4359800, 55.0446520],
          [-2.4359720, 55.0446060],
          [-2.4359650, 55.0445610],
          [-2.4359620, 55.0445150],
          [-2.4359620, 55.0444730],
          [-2.4359620, 55.0444300],
          [-2.4359570, 55.0443890],
          [-2.4359450, 55.0443390],
          [-2.4359350, 55.0442970],
          [-2.4359310, 55.0442580],
          [-2.4359330, 55.0442020],
          [-2.4359450, 55.0441560],
          [-2.4359610, 55.0441030],
          [-2.4359770, 55.0440520],
          [-2.4359920, 55.0439960],
          [-2.4360030, 55.0439350],
          [-2.4360130, 55.0438740],
          [-2.4360260, 55.0438090],
          [-2.4360380, 55.0437450],
          [-2.4360560, 55.0436740],
          [-2.4360760, 55.0435990],
          [-2.4361010, 55.0435230],
          [-2.4361290, 55.0434470],
          [-2.4361500, 55.0433710],
          [-2.4361650, 55.0432900],
          [-2.4361820, 55.0432140],
          [-2.4361980, 55.0431380],
          [-2.4362180, 55.0430660],
          [-2.4362310, 55.0429900],
          [-2.4362450, 55.0429170],
          [-2.4362610, 55.0428400],
          [-2.4362850, 55.0427640],
          [-2.4363170, 55.0426970],
          [-2.4363400, 55.0426260],
          [-2.4363550, 55.0425580],
          [-2.4363680, 55.0424820],
          [-2.4363830, 55.0424090],
          [-2.4364010, 55.0423320],
          [-2.4364230, 55.0422490],
          [-2.4364460, 55.0421620],
          [-2.4364750, 55.0420740],
          [-2.4365000, 55.0419900],
          [-2.4365250, 55.0419020],
          [-2.4365460, 55.0418190],
          [-2.4365680, 55.0417380],
          [-2.4365930, 55.0416580],
          [-2.4366230, 55.0415810],
          [-2.4366540, 55.0415130],
          [-2.4366810, 55.0414440],
          [-2.4367000, 55.0413750],
          [-2.4367160, 55.0413030],
          [-2.4367260, 55.0412280],
          [-2.4367340, 55.0411460],
          [-2.4367460, 55.0410560],
          [-2.4367680, 55.0409670],
          [-2.4368100, 55.0408980],
          [-2.4368610, 55.0408410],
          [-2.4369010, 55.0407970],
          [-2.4369310, 55.0407530],
          [-2.4369480, 55.0407150],
          [-2.4369520, 55.0406810],
          [-2.4369500, 55.0406470],
          [-2.4369480, 55.0406160],
          [-2.4369500, 55.0405770],
          [-2.4369530, 55.0405470],
          [-2.4369580, 55.0405170],
          [-2.4369620, 55.0404860],
          [-2.4369650, 55.0404530],
          [-2.4369670, 55.0404170],
          [-2.4369700, 55.0403830],
          [-2.4369830, 55.0403520],
          [-2.4370000, 55.0403180],
          [-2.4370200, 55.0402840],
          [-2.4370330, 55.0402460],
          [-2.4370430, 55.0402110],
          [-2.4370550, 55.0401730],
          [-2.4370660, 55.0401310],
          [-2.4370780, 55.0400940],
          [-2.4370910, 55.0400550],
          [-2.4371060, 55.0400130],
          [-2.4371180, 55.0399760],
          [-2.4371300, 55.0399330],
          [-2.4371430, 55.0398960],
          [-2.4371550, 55.0398570],
          [-2.4371630, 55.0398220],
          [-2.4371680, 55.0397880],
          [-2.4371730, 55.0397570],
          [-2.4371800, 55.0397270],
          [-2.4371910, 55.0396970],
          [-2.4372010, 55.0396670],
          [-2.4372070, 55.0396350],
          [-2.4372170, 55.0396050],
          [-2.4372260, 55.0395760],
          [-2.4372380, 55.0395440],
          [-2.4372460, 55.0395140],
          [-2.4372580, 55.0394830],
          [-2.4372710, 55.0394480],
          [-2.4372800, 55.0394150],
          [-2.4372790, 55.0393920],
          [-2.4372780, 55.0393500],
          [-2.4372770, 55.0393220],
          [-2.4372750, 55.0392920],
          [-2.4372730, 55.0392620],
          [-2.4372700, 55.0392310],
          [-2.4372600, 55.0392020],
          [-2.4372530, 55.0391730],
          [-2.4372390, 55.0391430],
          [-2.4372350, 55.0391090],
          [-2.4372270, 55.0390750],
          [-2.4372120, 55.0390360],
          [-2.4371890, 55.0390020],
          [-2.4371840, 55.0389570],
          [-2.4371720, 55.0389110],
          [-2.4371540, 55.0388610],
          [-2.4371410, 55.0388100],
          [-2.4371370, 55.0387430],
          [-2.4371270, 55.0386760],
          [-2.4371020, 55.0386060],
          [-2.4370760, 55.0385420],
          [-2.4370390, 55.0384760],
          [-2.4369970, 55.0384100],
          [-2.4369560, 55.0383430],
          [-2.4369180, 55.0382810],
          [-2.4368840, 55.0382160],
          [-2.4368580, 55.0381610],
          [-2.4368340, 55.0380990],
          [-2.4368070, 55.0380420],
          [-2.4367790, 55.0379840],
          [-2.4367480, 55.0379320],
          [-2.4367200, 55.0378810],
          [-2.4366980, 55.0378330],
          [-2.4366750, 55.0377820],
          [-2.4366560, 55.0377370],
          [-2.4366340, 55.0376900],
          [-2.4366120, 55.0376520],
          [-2.4365900, 55.0376170],
          [-2.4365740, 55.0375760],
          [-2.4365580, 55.0375390],
          [-2.4365420, 55.0375030],
          [-2.4365240, 55.0374650],
          [-2.4365090, 55.0374320],
          [-2.4364890, 55.0373960],
          [-2.4364720, 55.0373620],
          [-2.4364620, 55.0373280],
          [-2.4364530, 55.0372930],
          [-2.4364430, 55.0372590],
          [-2.4364310, 55.0372260],
          [-2.4364190, 55.0371940],
          [-2.4364070, 55.0371670],
          [-2.4363920, 55.0371400],
          [-2.4363800, 55.0371130],
          [-2.4363730, 55.0370870],
          [-2.4363610, 55.0370650],
          [-2.4363540, 55.0370380],
          [-2.4363490, 55.0370110],
          [-2.4363400, 55.0369880],
          [-2.4363300, 55.0369650],
          [-2.4363200, 55.0369420],
          [-2.4363100, 55.0369230],
          [-2.4362990, 55.0369000],
          [-2.4362930, 55.0368770],
          [-2.4362870, 55.0368540],
          [-2.4362850, 55.0368310],
          [-2.4362800, 55.0368120],
          [-2.4362700, 55.0367890],
          [-2.4362620, 55.0367670],
          [-2.4362540, 55.0367440],
          [-2.4362430, 55.0367210],
          [-2.4362380, 55.0367010],
          [-2.4362320, 55.0366790],
          [-2.4362220, 55.0366570],
          [-2.4362150, 55.0366370],
          [-2.4362070, 55.0366140],
          [-2.4361980, 55.0365910],
          [-2.4361900, 55.0365680],
          [-2.4361790, 55.0365450],
          [-2.4361690, 55.0365230],
          [-2.4361550, 55.0364990],
          [-2.4361480, 55.0364780],
          [-2.4361390, 55.0364500],
          [-2.4361280, 55.0364270],
          [-2.4361190, 55.0364010],
          [-2.4361070, 55.0363780],
          [-2.4360970, 55.0363540],
          [-2.4360890, 55.0363280],
          [-2.4360800, 55.0363050],
          [-2.4360700, 55.0362820],
          [-2.4360600, 55.0362630],
          [-2.4360520, 55.0362400],
          [-2.4360430, 55.0362170],
          [-2.4360340, 55.0361930],
          [-2.4360220, 55.0361680],
          [-2.4360120, 55.0361410],
          [-2.4360000, 55.0361180],
          [-2.4359860, 55.0360960],
          [-2.4359710, 55.0360730],
          [-2.4359550, 55.0360490],
          [-2.4359400, 55.0360260],
          [-2.4359240, 55.0360040],
          [-2.4359090, 55.0359780],
          [-2.4358970, 55.0359540],
          [-2.4358890, 55.0359280],
          [-2.4358810, 55.0359020],
          [-2.4358680, 55.0358770],
          [-2.4358570, 55.0358580],
          [-2.4358440, 55.0358360],
          [-2.4358310, 55.0358170],
          [-2.4358220, 55.0357940],
          [-2.4358090, 55.0357750],
          [-2.4358000, 55.0357560],
          [-2.4357930, 55.0357330],
          [-2.4357860, 55.0357150],
          [-2.4357790, 55.0356950],
          [-2.4357730, 55.0356750],
          [-2.4357680, 55.0356600],
          [-2.4357620, 55.0356410],
          [-2.4357540, 55.0356220],
          [-2.4357370, 55.0356070],
          [-2.4357240, 55.0355880],
          [-2.4357100, 55.0355690],
          [-2.4357000, 55.0355540],
          [-2.4356920, 55.0355360],
          [-2.4356830, 55.0355150],
          [-2.4356750, 55.0355000],
          [-2.4356650, 55.0354770],
          [-2.4356570, 55.0354580],
          [-2.4356470, 55.0354390],
          [-2.4356390, 55.0354200],
          [-2.4356300, 55.0354010],
          [-2.4356190, 55.0353850],
          [-2.4356070, 55.0353660],
          [-2.4355990, 55.0353480],
          [-2.4355900, 55.0353320],
          [-2.4355820, 55.0353130],
          [-2.4355750, 55.0352940],
          [-2.4355670, 55.0352790],
          [-2.4355590, 55.0352610],
          [-2.4355490, 55.0352410],
          [-2.4355350, 55.0352250],
          [-2.4355250, 55.0352070],
          [-2.4355120, 55.0351870],
          [-2.4355010, 55.0351690],
          [-2.4354930, 55.0351450],
          [-2.4354830, 55.0351220],
          [-2.4354690, 55.0351030],
          [-2.4354490, 55.0350800],
          [-2.4354280, 55.0350590],
          [-2.4354050, 55.0350350],
          [-2.4353760, 55.0350120],
          [-2.4353430, 55.0349890],
          [-2.4353110, 55.0349640],
          [-2.4352690, 55.0349320],
          [-2.4352290, 55.0349060],
          [-2.4351900, 55.0348720],
          [-2.4351480, 55.0348370],
          [-2.4351040, 55.0348020],
          [-2.4350600, 55.0347700],
          [-2.4350090, 55.0347340],
          [-2.4349580, 55.0346950],
          [-2.4349120, 55.0346570],
          [-2.4348630, 55.0346130],
          [-2.4348190, 55.0345720],
          [-2.4347640, 55.0345240],
          [-2.4347140, 55.0344810],
          [-2.4346550, 55.0344300],
          [-2.4345990, 55.0343890],
          [-2.4345290, 55.0343320],
          [-2.4344630, 55.0342810],
          [-2.4343840, 55.0342160],
          [-2.4343130, 55.0341570],
          [-2.4342360, 55.0340930],
          [-2.4341670, 55.0340460],
          [-2.4340890, 55.0340100],
          [-2.4340080, 55.0339970],
          [-2.4339350, 55.0340110],
          [-2.4338790, 55.0340340],
          [-2.4338370, 55.0340600],
          [-2.4337930, 55.0340940],
          [-2.4337480, 55.0341260],
          [-2.4337060, 55.0341510],
          [-2.4336620, 55.0341750],
          [-2.4336150, 55.0342020],
          [-2.4335730, 55.0342200],
          [-2.4335230, 55.0342480],
          [-2.4334790, 55.0342670],
          [-2.4334390, 55.0342850],
          [-2.4333910, 55.0343090],
          [-2.4333410, 55.0343280],
          [-2.4332930, 55.0343460],
          [-2.4332380, 55.0343620],
          [-2.4331880, 55.0343810],
          [-2.4331400, 55.0343960],
          [-2.4330870, 55.0344150],
          [-2.4330340, 55.0344310],
          [-2.4329870, 55.0344460],
          [-2.4329370, 55.0344650],
          [-2.4328880, 55.0344800],
          [-2.4328420, 55.0344950],
          [-2.4327920, 55.0345110],
          [-2.4327450, 55.0345230],
          [-2.4327040, 55.0345370],
          [-2.4326590, 55.0345570],
          [-2.4326130, 55.0345720],
          [-2.4325680, 55.0345860],
          [-2.4325160, 55.0345990],
          [-2.4324750, 55.0346140],
          [-2.4324300, 55.0346290],
          [-2.4323880, 55.0346400],
          [-2.4323390, 55.0346560],
          [-2.4322930, 55.0346680],
          [-2.4322500, 55.0346830],
          [-2.4322090, 55.0346940],
          [-2.4321670, 55.0347090],
          [-2.4321300, 55.0347210],
          [-2.4320940, 55.0347360],
          [-2.4320560, 55.0347510],
          [-2.4320150, 55.0347630],
          [-2.4319760, 55.0347740],
          [-2.4319370, 55.0347900],
          [-2.4319010, 55.0348010],
          [-2.4318610, 55.0348130],
          [-2.4318210, 55.0348240],
          [-2.4317820, 55.0348360],
          [-2.4317500, 55.0348500],
          [-2.4317110, 55.0348640],
          [-2.4316720, 55.0348780],
          [-2.4316370, 55.0348890],
          [-2.4315990, 55.0349000],
          [-2.4315580, 55.0349120],
          [-2.4315210, 55.0349230],
          [-2.4314880, 55.0349350],
          [-2.4314540, 55.0349460],
          [-2.4314210, 55.0349610],
          [-2.4313820, 55.0349730],
          [-2.4313460, 55.0349880],
          [-2.4313070, 55.0350000],
          [-2.4312720, 55.0350110],
          [-2.4312360, 55.0350220],
          [-2.4311990, 55.0350340],
          [-2.4311640, 55.0350450],
          [-2.4311260, 55.0350570],
          [-2.4310900, 55.0350680],
          [-2.4310540, 55.0350800],
          [-2.4310190, 55.0350950],
          [-2.4309860, 55.0351070],
          [-2.4309530, 55.0351180],
          [-2.4309190, 55.0351290],
          [-2.4308880, 55.0351410],
          [-2.4308560, 55.0351520],
          [-2.4308230, 55.0351630],
          [-2.4307890, 55.0351750],
          [-2.4307540, 55.0351870],
          [-2.4307210, 55.0351940],
          [-2.4306870, 55.0352060],
          [-2.4306570, 55.0352170],
          [-2.4306280, 55.0352320],
          [-2.4305940, 55.0352400],
          [-2.4305620, 55.0352510],
          [-2.4305310, 55.0352630],
          [-2.4304990, 55.0352710],
          [-2.4304660, 55.0352820],
          [-2.4304360, 55.0352930],
          [-2.4304040, 55.0353050],
          [-2.4303710, 55.0353160],
          [-2.4303410, 55.0353310],
          [-2.4303110, 55.0353430],
          [-2.4302790, 55.0353540],
          [-2.4302470, 55.0353660],
          [-2.4302150, 55.0353770],
          [-2.4301840, 55.0353890],
          [-2.4301510, 55.0353960],
          [-2.4301210, 55.0354080],
          [-2.4300890, 55.0354150],
          [-2.4300570, 55.0354270],
          [-2.4300260, 55.0354380],
          [-2.4299990, 55.0354530],
          [-2.4299770, 55.0354690],
          [-2.4299540, 55.0354880],
          [-2.4299290, 55.0355030],
          [-2.4299090, 55.0355220],
          [-2.4298890, 55.0355370],
          [-2.4298770, 55.0355450],
          [-2.4298770, 55.0355450],
          [-2.4298760, 55.0355460],
          [-2.4298750, 55.0355460],
          [-2.4298740, 55.0355460],
          [-2.4298730, 55.0355460],
          [-2.4298720, 55.0355460],
          [-2.4298710, 55.0355460],
          [-2.4298700, 55.0355460],
          [-2.4298690, 55.0355460],
          [-2.4298680, 55.0355470],
          [-2.4298670, 55.0355470],
          [-2.4298660, 55.0355470],
          [-2.4298650, 55.0355470],
          [-2.4298640, 55.0355470],
          [-2.4298630, 55.0355470],
          [-2.4298620, 55.0355470],
          [-2.4298610, 55.0355470],
          [-2.4298600, 55.0355470],
          [-2.4298590, 55.0355480],
          [-2.4298580, 55.0355480],
          [-2.4298570, 55.0355480],
          [-2.4298560, 55.0355480],
          [-2.4298550, 55.0355480],
          [-2.4298540, 55.0355480],
          [-2.4298530, 55.0355480],
          [-2.4298520, 55.0355480],
          [-2.4298510, 55.0355490],
          [-2.4298500, 55.0355490],
          [-2.4298490, 55.0355490],
          [-2.4298480, 55.0355490],
          [-2.4298470, 55.0355490],
          [-2.4298460, 55.0355490],
          [-2.4298450, 55.0355490],
          [-2.4298450, 55.0355490],
          [-2.4298440, 55.0355490],
          [-2.4298430, 55.0355500],
          [-2.4298420, 55.0355500],
          [-2.4298410, 55.0355500],
          [-2.4298400, 55.0355500],
          [-2.4298390, 55.0355500],
          [-2.4298380, 55.0355500],
          [-2.4298370, 55.0355500],
          [-2.4298360, 55.0355500],
          [-2.4298350, 55.0355510],
          [-2.4298340, 55.0355510],
          [-2.4298330, 55.0355510],
          [-2.4298320, 55.0355510],
          [-2.4298310, 55.0355510],
          [-2.4298300, 55.0355510],
          [-2.4298290, 55.0355510],
          [-2.4298280, 55.0355510],
          [-2.4298270, 55.0355510],
          [-2.4298260, 55.0355520],
          [-2.4298250, 55.0355520],
          [-2.4298240, 55.0355520],
          [-2.4298230, 55.0355520],
          [-2.4298220, 55.0355520],
          [-2.4298210, 55.0355520],
          [-2.4298200, 55.0355520],
          [-2.4298190, 55.0355520],
          [-2.4298180, 55.0355530],
          [-2.4298170, 55.0355530],
          [-2.4298160, 55.0355530],
          [-2.4298150, 55.0355530],
          [-2.4298140, 55.0355530],
          [-2.4298130, 55.0355530],
          [-2.4298130, 55.0355530],
          [-2.4298120, 55.0355530],
          [-2.4298110, 55.0355530],
          [-2.4298100, 55.0355540],
          [-2.4298090, 55.0355540],
          [-2.4298080, 55.0355540],
          [-2.4298070, 55.0355540],
          [-2.4298060, 55.0355540],
          [-2.4298050, 55.0355540],
          [-2.4298040, 55.0355540],
          [-2.4298030, 55.0355540],
          [-2.4298020, 55.0355550],
          [-2.4298010, 55.0355550],
          [-2.4298000, 55.0355550],
          [-2.4297990, 55.0355550],
          [-2.4297980, 55.0355550],
          [-2.4297970, 55.0355550],
          [-2.4297960, 55.0355550],
          [-2.4297950, 55.0355550],
          [-2.4297940, 55.0355560],
          [-2.4297930, 55.0355560],
          [-2.4297920, 55.0355560],
          [-2.4297910, 55.0355560],
          [-2.4297900, 55.0355560],
          [-2.4297890, 55.0355560],
          [-2.4297880, 55.0355560],
          [-2.4297870, 55.0355560],
          [-2.4297860, 55.0355560],
          [-2.4297850, 55.0355570],
          [-2.4297840, 55.0355570],
          [-2.4297480, 55.0355600],
          [-2.4297080, 55.0355680],
          [-2.4296680, 55.0355870],
          [-2.4296280, 55.0356060],
          [-2.4295860, 55.0356250],
          [-2.4295460, 55.0356440],
          [-2.4295060, 55.0356630],
          [-2.4294660, 55.0356820],
          [-2.4294180, 55.0357050],
          [-2.4293700, 55.0357240],
          [-2.4293150, 55.0357430],
          [-2.4292630, 55.0357660],
          [-2.4292130, 55.0357890],
          [-2.4291650, 55.0358150],
          [-2.4291200, 55.0358380],
          [-2.4290770, 55.0358600],
          [-2.4290280, 55.0358840],
          [-2.4289750, 55.0359110],
          [-2.4289200, 55.0359380],
          [-2.4288630, 55.0359640],
          [-2.4288030, 55.0359910],
          [-2.4287430, 55.0360210],
          [-2.4286810, 55.0360520],
          [-2.4286170, 55.0360820],
          [-2.4285480, 55.0361090],
          [-2.4284780, 55.0361400],
          [-2.4284060, 55.0361670],
          [-2.4283350, 55.0362010],
          [-2.4282610, 55.0362280],
          [-2.4281890, 55.0362540],
          [-2.4281180, 55.0362810],
          [-2.4280450, 55.0363120],
          [-2.4279770, 55.0363420],
          [-2.4279100, 55.0363730],
          [-2.4278450, 55.0363990],
          [-2.4277790, 55.0364260],
          [-2.4277110, 55.0364530],
          [-2.4276510, 55.0364800],
          [-2.4275960, 55.0365060],
          [-2.4275400, 55.0365290],
          [-2.4274780, 55.0365520],
          [-2.4274170, 55.0365750],
          [-2.4273530, 55.0366010],
          [-2.4272860, 55.0366240],
          [-2.4272210, 55.0366510],
          [-2.4271530, 55.0366780],
          [-2.4270910, 55.0367040],
          [-2.4270190, 55.0367310],
          [-2.4269500, 55.0367620],
          [-2.4268770, 55.0367920],
          [-2.4268000, 55.0368190],
          [-2.4267250, 55.0368490],
          [-2.4266580, 55.0368760],
          [-2.4265680, 55.0369140],
          [-2.4264820, 55.0369520],
          [-2.4263960, 55.0369870],
          [-2.4263140, 55.0370210],
          [-2.4262360, 55.0370510],
          [-2.4261520, 55.0370860],
          [-2.4260670, 55.0371200],
          [-2.4259830, 55.0371550],
          [-2.4259000, 55.0371850],
          [-2.4258180, 55.0372190],
          [-2.4257350, 55.0372500],
          [-2.4256450, 55.0372840],
          [-2.4255570, 55.0373220],
          [-2.4254710, 55.0373560],
          [-2.4253790, 55.0373950],
          [-2.4252860, 55.0374290],
          [-2.4251880, 55.0374670],
          [-2.4250940, 55.0375090],
          [-2.4250000, 55.0375470],
          [-2.4249050, 55.0375850],
          [-2.4248140, 55.0376280],
          [-2.4247270, 55.0376690],
          [-2.4246430, 55.0377080],
          [-2.4245650, 55.0377380],
          [-2.4244830, 55.0377800],
          [-2.4244030, 55.0378070],
          [-2.4243180, 55.0378410],
          [-2.4242380, 55.0378750],
          [-2.4241530, 55.0379020],
          [-2.4240690, 55.0379330],
          [-2.4239850, 55.0379600],
          [-2.4239020, 55.0379860],
          [-2.4238210, 55.0380130],
          [-2.4237460, 55.0380360],
          [-2.4236760, 55.0380550],
          [-2.4236060, 55.0380780],
          [-2.4235400, 55.0380970],
          [-2.4234680, 55.0381160],
          [-2.4233870, 55.0381310],
          [-2.4233000, 55.0381500],
          [-2.4232090, 55.0381690],
          [-2.4231210, 55.0381850],
          [-2.4230400, 55.0382000],
          [-2.4229520, 55.0382150],
          [-2.4228700, 55.0382310],
          [-2.4227930, 55.0382460],
          [-2.4227150, 55.0382610],
          [-2.4226350, 55.0382760],
          [-2.4225530, 55.0382990],
          [-2.4224740, 55.0383140],
          [-2.4223960, 55.0383260],
          [-2.4223150, 55.0383410],
          [-2.4222390, 55.0383560],
          [-2.4221550, 55.0383720],
          [-2.4220710, 55.0383910],
          [-2.4219880, 55.0384100],
          [-2.4219080, 55.0384290],
          [-2.4218280, 55.0384480],
          [-2.4217520, 55.0384710],
          [-2.4216760, 55.0384900],
          [-2.4216010, 55.0385130],
          [-2.4215250, 55.0385350],
          [-2.4214470, 55.0385550],
          [-2.4213640, 55.0385820],
          [-2.4212820, 55.0386040],
          [-2.4211970, 55.0386310],
          [-2.4211160, 55.0386540],
          [-2.4210390, 55.0386770],
          [-2.4209650, 55.0387030],
          [-2.4208920, 55.0387260],
          [-2.4208160, 55.0387490],
          [-2.4207430, 55.0387710],
          [-2.4206640, 55.0387910],
          [-2.4205750, 55.0388180],
          [-2.4204870, 55.0388410],
          [-2.4203930, 55.0388640],
          [-2.4202970, 55.0388900],
          [-2.4201970, 55.0389130],
          [-2.4200970, 55.0389360],
          [-2.4199960, 55.0389630],
          [-2.4198930, 55.0389900],
          [-2.4197960, 55.0390160],
          [-2.4197000, 55.0390470],
          [-2.4196100, 55.0390770],
          [-2.4195170, 55.0391070],
          [-2.4194270, 55.0391350],
          [-2.4193380, 55.0391610],
          [-2.4192540, 55.0391840],
          [-2.4191700, 55.0392150],
          [-2.4190870, 55.0392410],
          [-2.4189970, 55.0392680],
          [-2.4189030, 55.0392950],
          [-2.4188050, 55.0393210],
          [-2.4187070, 55.0393400],
          [-2.4186020, 55.0393600],
          [-2.4185030, 55.0393750],
          [-2.4184080, 55.0393900],
          [-2.4183230, 55.0394050],
          [-2.4182410, 55.0394210],
          [-2.4181570, 55.0394320],
          [-2.4180820, 55.0394400],
          [-2.4180100, 55.0394510],
          [-2.4179350, 55.0394550],
          [-2.4178530, 55.0394590],
          [-2.4177780, 55.0394630],
          [-2.4177060, 55.0394630],
          [-2.4176310, 55.0394560],
          [-2.4175610, 55.0394520],
          [-2.4174950, 55.0394440],
          [-2.4174300, 55.0394370],
          [-2.4173640, 55.0394290],
          [-2.4173010, 55.0394210],
          [-2.4172430, 55.0394140],
          [-2.4171860, 55.0394060],
          [-2.4171280, 55.0393980],
          [-2.4170690, 55.0393910],
          [-2.4170230, 55.0393940],
          [-2.4169730, 55.0393980],
          [-2.4169220, 55.0393980],
          [-2.4168680, 55.0393940],
          [-2.4168140, 55.0393910],
          [-2.4167600, 55.0393870],
          [-2.4167050, 55.0393870],
          [-2.4166480, 55.0393900],
          [-2.4165920, 55.0393910],
          [-2.4165340, 55.0393910],
          [-2.4164780, 55.0393940],
          [-2.4164230, 55.0393980],
          [-2.4163730, 55.0393980],
          [-2.4163250, 55.0394060],
          [-2.4162780, 55.0394170],
          [-2.4162340, 55.0394280],
          [-2.4161890, 55.0394440],
          [-2.4161480, 55.0394620],
          [-2.4161060, 55.0394820],
          [-2.4160610, 55.0395010],
          [-2.4160200, 55.0395230],
          [-2.4159790, 55.0395430],
          [-2.4159410, 55.0395690],
          [-2.4159010, 55.0395960],
          [-2.4158640, 55.0396190],
          [-2.4158290, 55.0396460],
          [-2.4157920, 55.0396730],
          [-2.4157520, 55.0397030],
          [-2.4157140, 55.0397330],
          [-2.4156740, 55.0397640],
          [-2.4156280, 55.0397940],
          [-2.4155800, 55.0398210],
          [-2.4155330, 55.0398480],
          [-2.4154850, 55.0398700],
          [-2.4154400, 55.0398900],
          [-2.4153940, 55.0399090],
          [-2.4153560, 55.0399280],
          [-2.4153170, 55.0399470],
          [-2.4152790, 55.0399660],
          [-2.4152410, 55.0399820],
          [-2.4152020, 55.0400000],
          [-2.4151640, 55.0400190],
          [-2.4151260, 55.0400460],
          [-2.4150910, 55.0400690],
          [-2.4150560, 55.0400920],
          [-2.4150210, 55.0401150],
          [-2.4149890, 55.0401380],
          [-2.4149620, 55.0401640],
          [-2.4149360, 55.0401870],
          [-2.4149090, 55.0402100],
          [-2.4148820, 55.0402330],
          [-2.4148560, 55.0402560],
          [-2.4148290, 55.0402830],
          [-2.4148010, 55.0403090],
          [-2.4147720, 55.0403360],
          [-2.4147440, 55.0403630],
          [-2.4147120, 55.0403860],
          [-2.4146840, 55.0404120],
          [-2.4146540, 55.0404350],
          [-2.4146220, 55.0404580],
          [-2.4145920, 55.0404810],
          [-2.4145610, 55.0405080],
          [-2.4145310, 55.0405310],
          [-2.4145010, 55.0405540],
          [-2.4144720, 55.0405770],
          [-2.4144430, 55.0406030],
          [-2.4144090, 55.0406300],
          [-2.4143740, 55.0406560],
          [-2.4143340, 55.0406870],
          [-2.4142930, 55.0407210],
          [-2.4142510, 55.0407520],
          [-2.4142090, 55.0407820],
          [-2.4141680, 55.0408120],
          [-2.4141240, 55.0408460],
          [-2.4140730, 55.0408780],
          [-2.4140200, 55.0409080],
          [-2.4139640, 55.0409390],
          [-2.4139080, 55.0409730],
          [-2.4138470, 55.0410080],
          [-2.4137840, 55.0410460],
          [-2.4137130, 55.0410840],
          [-2.4136370, 55.0411290],
          [-2.4135500, 55.0411750],
          [-2.4134590, 55.0412280],
          [-2.4133640, 55.0412850],
          [-2.4132660, 55.0413430],
          [-2.4131670, 55.0414000],
          [-2.4130650, 55.0414530],
          [-2.4129640, 55.0415100],
          [-2.4128670, 55.0415710],
          [-2.4127730, 55.0416360],
          [-2.4126870, 55.0417050],
          [-2.4126070, 55.0417730],
          [-2.4125320, 55.0418420],
          [-2.4124620, 55.0419050],
          [-2.4123900, 55.0419720],
          [-2.4123230, 55.0420320],
          [-2.4122550, 55.0420940],
          [-2.4121860, 55.0421510],
          [-2.4121130, 55.0422050],
          [-2.4120370, 55.0422540],
          [-2.4119550, 55.0423000],
          [-2.4118700, 55.0423420],
          [-2.4117790, 55.0423810],
          [-2.4116880, 55.0424150],
          [-2.4115980, 55.0424490],
          [-2.4115090, 55.0424760],
          [-2.4114220, 55.0425070],
          [-2.4113380, 55.0425370],
          [-2.4112610, 55.0425680],
          [-2.4111860, 55.0426020],
          [-2.4111100, 55.0426360],
          [-2.4110350, 55.0426670],
          [-2.4109570, 55.0427010],
          [-2.4108830, 55.0427350],
          [-2.4108140, 55.0427690],
          [-2.4107410, 55.0428080],
          [-2.4106680, 55.0428460],
          [-2.4105970, 55.0428870],
          [-2.4105240, 55.0429330],
          [-2.4104600, 55.0429790],
          [-2.4103970, 55.0430280],
          [-2.4103360, 55.0430820],
          [-2.4102810, 55.0431360],
          [-2.4102240, 55.0431890],
          [-2.4101660, 55.0432430],
          [-2.4101020, 55.0432920],
          [-2.4100330, 55.0433420],
          [-2.4099610, 55.0433920],
          [-2.4098850, 55.0434370],
          [-2.4098070, 55.0434790],
          [-2.4097320, 55.0435210],
          [-2.4096580, 55.0435620],
          [-2.4095800, 55.0435970],
          [-2.4095050, 55.0436390],
          [-2.4094300, 55.0436740],
          [-2.4093570, 55.0437090],
          [-2.4092860, 55.0437430],
          [-2.4092180, 55.0437810],
          [-2.4091480, 55.0438150],
          [-2.4090760, 55.0438530],
          [-2.4090060, 55.0438880],
          [-2.4089330, 55.0439220],
          [-2.4088580, 55.0439600],
          [-2.4087830, 55.0439940],
          [-2.4087120, 55.0440320],
          [-2.4086360, 55.0440710],
          [-2.4085610, 55.0441090],
          [-2.4084820, 55.0441470],
          [-2.4084030, 55.0441890],
          [-2.4083250, 55.0442310],
          [-2.4082490, 55.0442690],
          [-2.4081780, 55.0443040],
          [-2.4081010, 55.0443480],
          [-2.4080400, 55.0443820],
          [-2.4079660, 55.0444280],
          [-2.4079080, 55.0444660],
          [-2.4078430, 55.0445090],
          [-2.4077930, 55.0445510],
          [-2.4077400, 55.0445900],
          [-2.4076900, 55.0446300],
          [-2.4076320, 55.0446760],
          [-2.4075850, 55.0447140],
          [-2.4075280, 55.0447610],
          [-2.4074810, 55.0447970],
          [-2.4074180, 55.0448440],
          [-2.4073650, 55.0448790],
          [-2.4073030, 55.0449210],
          [-2.4072500, 55.0449560],
          [-2.4071830, 55.0449970],
          [-2.4071230, 55.0450320],
          [-2.4070510, 55.0450690],
          [-2.4069890, 55.0451050],
          [-2.4069180, 55.0451450],
          [-2.4068560, 55.0451800],
          [-2.4067820, 55.0452210],
          [-2.4067200, 55.0452550],
          [-2.4066370, 55.0452990],
          [-2.4065790, 55.0453260],
          [-2.4064890, 55.0453670],
          [-2.4064230, 55.0454050],
          [-2.4063450, 55.0454430],
          [-2.4062790, 55.0454780],
          [-2.4061990, 55.0455160],
          [-2.4061310, 55.0455510],
          [-2.4060520, 55.0455880],
          [-2.4059830, 55.0456150],
          [-2.4058980, 55.0456540],
          [-2.4058320, 55.0456840],
          [-2.4057560, 55.0457210],
          [-2.4056920, 55.0457520],
          [-2.4056160, 55.0457890],
          [-2.4055500, 55.0458190],
          [-2.4054750, 55.0458550],
          [-2.4054130, 55.0458820],
          [-2.4053320, 55.0459190],
          [-2.4052670, 55.0459480],
          [-2.4051880, 55.0459810],
          [-2.4051200, 55.0460090],
          [-2.4050460, 55.0460380],
          [-2.4049790, 55.0460590],
          [-2.4048980, 55.0460840],
          [-2.4048310, 55.0461050],
          [-2.4047580, 55.0461250],
          [-2.4046900, 55.0461400],
          [-2.4046140, 55.0461610],
          [-2.4045470, 55.0461800],
          [-2.4044830, 55.0461940],
          [-2.4044110, 55.0462070],
          [-2.4043500, 55.0462180],
          [-2.4042980, 55.0462290],
          [-2.4042360, 55.0462410],
          [-2.4041790, 55.0462530],
          [-2.4041280, 55.0462600],
          [-2.4040660, 55.0462680],
          [-2.4040100, 55.0462790],
          [-2.4039600, 55.0462890],
          [-2.4038970, 55.0463020],
          [-2.4038400, 55.0463100],
          [-2.4037900, 55.0463200],
          [-2.4037290, 55.0463320],
          [-2.4036700, 55.0463480],
          [-2.4036190, 55.0463580],
          [-2.4035540, 55.0463710],
          [-2.4034890, 55.0463830],
          [-2.4034340, 55.0463890],
          [-2.4033630, 55.0464010],
          [-2.4033000, 55.0464130],
          [-2.4032380, 55.0464250],
          [-2.4031780, 55.0464320],
          [-2.4031200, 55.0464440],
          [-2.4030610, 55.0464550],
          [-2.4030040, 55.0464630],
          [-2.4029460, 55.0464700],
          [-2.4028940, 55.0464780],
          [-2.4028430, 55.0464860],
          [-2.4027930, 55.0464930],
          [-2.4027440, 55.0465050],
          [-2.4026930, 55.0465180],
          [-2.4026430, 55.0465310],
          [-2.4025890, 55.0465470],
          [-2.4025330, 55.0465620],
          [-2.4024790, 55.0465770],
          [-2.4024240, 55.0465960],
          [-2.4023680, 55.0466110],
          [-2.4023110, 55.0466230],
          [-2.4022510, 55.0466380],
          [-2.4021920, 55.0466500],
          [-2.4021330, 55.0466650],
          [-2.4020750, 55.0466800],
          [-2.4020150, 55.0466950],
          [-2.4019510, 55.0467110],
          [-2.4018870, 55.0467260],
          [-2.4018220, 55.0467410],
          [-2.4017560, 55.0467560],
          [-2.4016920, 55.0467680],
          [-2.4016290, 55.0467790],
          [-2.4015660, 55.0467940],
          [-2.4015030, 55.0468060],
          [-2.4014430, 55.0468180],
          [-2.4013880, 55.0468330],
          [-2.4013320, 55.0468400],
          [-2.4012740, 55.0468520],
          [-2.4012180, 55.0468600],
          [-2.4011630, 55.0468710],
          [-2.4011080, 55.0468790],
          [-2.4010530, 55.0468940],
          [-2.4009970, 55.0469050],
          [-2.4009400, 55.0469170],
          [-2.4008830, 55.0469320],
          [-2.4008250, 55.0469400],
          [-2.4007650, 55.0469550],
          [-2.4007050, 55.0469660],
          [-2.4006480, 55.0469770],
          [-2.4005920, 55.0469890],
          [-2.4005380, 55.0470010],
          [-2.4004850, 55.0470120],
          [-2.4004290, 55.0470270],
          [-2.4003740, 55.0470430],
          [-2.4003240, 55.0470580],
          [-2.4002680, 55.0470730],
          [-2.4002120, 55.0470840],
          [-2.4001560, 55.0470960],
          [-2.4000990, 55.0471070],
          [-2.4000440, 55.0471190],
          [-2.3999890, 55.0471300],
          [-2.3999300, 55.0471460],
          [-2.3998720, 55.0471570],
          [-2.3998120, 55.0471680],
          [-2.3997530, 55.0471800],
          [-2.3996940, 55.0471910],
          [-2.3996390, 55.0472060],
          [-2.3995810, 55.0472180],
          [-2.3995280, 55.0472300],
          [-2.3994760, 55.0472440],
          [-2.3994210, 55.0472600],
          [-2.3993680, 55.0472750],
          [-2.3993140, 55.0472870],
          [-2.3992610, 55.0473020],
          [-2.3992080, 55.0473130],
          [-2.3991550, 55.0473290],
          [-2.3991060, 55.0473440],
          [-2.3990570, 55.0473630],
          [-2.3990110, 55.0473780],
          [-2.3989630, 55.0473970],
          [-2.3989170, 55.0474160],
          [-2.3988710, 55.0474320],
          [-2.3988290, 55.0474540],
          [-2.3987870, 55.0474780],
          [-2.3987480, 55.0475040],
          [-2.3987110, 55.0475340],
          [-2.3986790, 55.0475680],
          [-2.3986490, 55.0476020],
          [-2.3986230, 55.0476400],
          [-2.3985940, 55.0476830],
          [-2.3985690, 55.0477240],
          [-2.3985450, 55.0477710],
          [-2.3985220, 55.0478170],
          [-2.3984990, 55.0478610],
          [-2.3984790, 55.0479080],
          [-2.3984560, 55.0479490],
          [-2.3984360, 55.0479960],
          [-2.3984170, 55.0480380],
          [-2.3983990, 55.0480800],
          [-2.3983820, 55.0481210],
          [-2.3983620, 55.0481630],
          [-2.3983420, 55.0482050],
          [-2.3983210, 55.0482430],
          [-2.3982990, 55.0482780],
          [-2.3982750, 55.0483160],
          [-2.3982520, 55.0483580],
          [-2.3982310, 55.0483960],
          [-2.3982120, 55.0484340],
          [-2.3981920, 55.0484760],
          [-2.3981720, 55.0485150],
          [-2.3981510, 55.0485520],
          [-2.3981310, 55.0485900],
          [-2.3981120, 55.0486290],
          [-2.3980950, 55.0486710],
          [-2.3980790, 55.0487080],
          [-2.3980620, 55.0487470],
          [-2.3980440, 55.0487860],
          [-2.3980270, 55.0488270],
          [-2.3980080, 55.0488640],
          [-2.3979870, 55.0489040],
          [-2.3979670, 55.0489410],
          [-2.3979460, 55.0489790],
          [-2.3979190, 55.0490140],
          [-2.3978910, 55.0490520],
          [-2.3978610, 55.0490870],
          [-2.3978280, 55.0491210],
          [-2.3977920, 55.0491520],
          [-2.3977560, 55.0491860],
          [-2.3977210, 55.0492160],
          [-2.3976860, 55.0492470],
          [-2.3976510, 55.0492780],
          [-2.3976160, 55.0493080],
          [-2.3975820, 55.0493350],
          [-2.3975500, 55.0493620],
          [-2.3975210, 55.0493850],
          [-2.3974890, 55.0494110],
          [-2.3974560, 55.0494310],
          [-2.3974170, 55.0494500],
          [-2.3973790, 55.0494680],
          [-2.3973360, 55.0494880],
          [-2.3972940, 55.0495030],
          [-2.3972510, 55.0495220],
          [-2.3972070, 55.0495370],
          [-2.3971590, 55.0495560],
          [-2.3971150, 55.0495710],
          [-2.3970670, 55.0495870],
          [-2.3970230, 55.0496020],
          [-2.3969760, 55.0496180],
          [-2.3969280, 55.0496330],
          [-2.3968810, 55.0496480],
          [-2.3968340, 55.0496630],
          [-2.3967840, 55.0496780],
          [-2.3967340, 55.0496900],
          [-2.3966850, 55.0497010],
          [-2.3966340, 55.0497130],
          [-2.3965840, 55.0497240],
          [-2.3965340, 55.0497360],
          [-2.3964880, 55.0497470],
          [-2.3964400, 55.0497590],
          [-2.3963930, 55.0497740],
          [-2.3963450, 55.0497850],
          [-2.3962960, 55.0497930],
          [-2.3962470, 55.0498050],
          [-2.3961980, 55.0498160],
          [-2.3961490, 55.0498230],
          [-2.3960970, 55.0498350],
          [-2.3960450, 55.0498430],
          [-2.3959940, 55.0498540],
          [-2.3959440, 55.0498620],
          [-2.3958930, 55.0498730],
          [-2.3958460, 55.0498810],
          [-2.3957930, 55.0498920],
          [-2.3957450, 55.0499000],
          [-2.3956980, 55.0499110],
          [-2.3956510, 55.0499230],
          [-2.3956030, 55.0499300],
          [-2.3955530, 55.0499380],
          [-2.3955050, 55.0499460],
          [-2.3954580, 55.0499570],
          [-2.3954110, 55.0499650],
          [-2.3953630, 55.0499720],
          [-2.3953160, 55.0499800],
          [-2.3952710, 55.0499870],
          [-2.3952230, 55.0499950],
          [-2.3951780, 55.0500030],
          [-2.3951330, 55.0500110],
          [-2.3950880, 55.0500220],
          [-2.3950390, 55.0500330],
          [-2.3949940, 55.0500410],
          [-2.3949450, 55.0500450],
          [-2.3948980, 55.0500520],
          [-2.3948510, 55.0500600],
          [-2.3948070, 55.0500680],
          [-2.3947660, 55.0500720],
          [-2.3947230, 55.0500790],
          [-2.3946800, 55.0500830],
          [-2.3946390, 55.0500910],
          [-2.3945990, 55.0500980],
          [-2.3945610, 55.0501060],
          [-2.3945220, 55.0501140],
          [-2.3944830, 55.0501210],
          [-2.3944420, 55.0501250],
          [-2.3943980, 55.0501320],
          [-2.3943530, 55.0501330],
          [-2.3943100, 55.0501400],
          [-2.3942630, 55.0501440],
          [-2.3942200, 55.0501520],
          [-2.3941730, 55.0501520],
          [-2.3941260, 55.0501520],
          [-2.3940820, 55.0501590],
          [-2.3940410, 55.0501670],
          [-2.3939940, 55.0501750],
          [-2.3939490, 55.0501780],
          [-2.3939070, 55.0501860],
          [-2.3938690, 55.0501900],
          [-2.3938290, 55.0501970],
          [-2.3937890, 55.0501980],
          [-2.3937490, 55.0502010],
          [-2.3937110, 55.0502050],
          [-2.3936690, 55.0502050],
          [-2.3936300, 55.0502130],
          [-2.3935910, 55.0502170],
          [-2.3935470, 55.0502170],
          [-2.3934980, 55.0502200],
          [-2.3934490, 55.0502200],
          [-2.3934050, 55.0502200],
          [-2.3933610, 55.0502200],
          [-2.3933160, 55.0502200],
          [-2.3932680, 55.0502200],
          [-2.3932160, 55.0502200],
          [-2.3931650, 55.0502240],
          [-2.3931120, 55.0502280],
          [-2.3930610, 55.0502320],
          [-2.3930110, 55.0502390],
          [-2.3929630, 55.0502510],
          [-2.3929120, 55.0502620],
          [-2.3928590, 55.0502780],
          [-2.3928050, 55.0502920],
          [-2.3927490, 55.0503080],
          [-2.3926910, 55.0503270],
          [-2.3926350, 55.0503450],
          [-2.3925710, 55.0503650],
          [-2.3925040, 55.0503880],
          [-2.3924370, 55.0504070],
          [-2.3923660, 55.0504300],
          [-2.3922960, 55.0504520],
          [-2.3922210, 55.0504760],
          [-2.3921470, 55.0504950],
          [-2.3920730, 55.0505180],
          [-2.3920010, 55.0505400],
          [-2.3919290, 55.0505630],
          [-2.3918550, 55.0505860],
          [-2.3917770, 55.0506050],
          [-2.3916970, 55.0506250],
          [-2.3916200, 55.0506430],
          [-2.3915390, 55.0506620],
          [-2.3914580, 55.0506820],
          [-2.3913790, 55.0507010],
          [-2.3912970, 55.0507200],
          [-2.3912150, 55.0507430],
          [-2.3911320, 55.0507620],
          [-2.3910510, 55.0507810],
          [-2.3909670, 55.0508000],
          [-2.3908850, 55.0508190],
          [-2.3908050, 55.0508340],
          [-2.3907250, 55.0508530],
          [-2.3906460, 55.0508690],
          [-2.3905670, 55.0508880],
          [-2.3904880, 55.0509030],
          [-2.3904080, 55.0509150],
          [-2.3903290, 55.0509300],
          [-2.3902490, 55.0509490],
          [-2.3901710, 55.0509680],
          [-2.3900880, 55.0509830],
          [-2.3900080, 55.0510020],
          [-2.3899290, 55.0510210],
          [-2.3898500, 55.0510400],
          [-2.3897670, 55.0510590],
          [-2.3896850, 55.0510750],
          [-2.3896020, 55.0510940],
          [-2.3895160, 55.0511090],
          [-2.3894320, 55.0511280],
          [-2.3893440, 55.0511430],
          [-2.3892550, 55.0511620],
          [-2.3891670, 55.0511810],
          [-2.3890790, 55.0511960],
          [-2.3889850, 55.0512160],
          [-2.3888940, 55.0512350],
          [-2.3888020, 55.0512540],
          [-2.3887080, 55.0512730],
          [-2.3886160, 55.0512920],
          [-2.3885230, 55.0513110],
          [-2.3884300, 55.0513270],
          [-2.3883430, 55.0513490],
          [-2.3882580, 55.0513680],
          [-2.3881720, 55.0513910],
          [-2.3880840, 55.0514140],
          [-2.3879960, 55.0514370],
          [-2.3879100, 55.0514590],
          [-2.3878260, 55.0514790],
          [-2.3877420, 55.0514980],
          [-2.3876580, 55.0515170],
          [-2.3875770, 55.0515360],
          [-2.3874930, 55.0515550],
          [-2.3874090, 55.0515740],
          [-2.3873260, 55.0515930],
          [-2.3872460, 55.0516120],
          [-2.3871690, 55.0516280],
          [-2.3870930, 55.0516430],
          [-2.3870110, 55.0516550],
          [-2.3869350, 55.0516700],
          [-2.3868590, 55.0516850],
          [-2.3867800, 55.0517000],
          [-2.3866980, 55.0517120],
          [-2.3866170, 55.0517200],
          [-2.3865360, 55.0517310],
          [-2.3864520, 55.0517420],
          [-2.3863670, 55.0517540],
          [-2.3862830, 55.0517620],
          [-2.3862000, 55.0517690],
          [-2.3861180, 55.0517730],
          [-2.3860360, 55.0517770],
          [-2.3859560, 55.0517880],
          [-2.3858700, 55.0517920],
          [-2.3857850, 55.0517960],
          [-2.3857030, 55.0518030],
          [-2.3856140, 55.0518070],
          [-2.3855290, 55.0518150],
          [-2.3854450, 55.0518150],
          [-2.3853610, 55.0518150],
          [-2.3852780, 55.0518190],
          [-2.3851950, 55.0518230],
          [-2.3851160, 55.0518260],
          [-2.3850330, 55.0518300],
          [-2.3849470, 55.0518300],
          [-2.3848630, 55.0518340],
          [-2.3847760, 55.0518340],
          [-2.3846890, 55.0518380],
          [-2.3846020, 55.0518420],
          [-2.3845150, 55.0518420],
          [-2.3844270, 55.0518450],
          [-2.3843440, 55.0518490],
          [-2.3842570, 55.0518530],
          [-2.3841710, 55.0518530],
          [-2.3840860, 55.0518570],
          [-2.3839990, 55.0518610],
          [-2.3839130, 55.0518680],
          [-2.3838280, 55.0518720],
          [-2.3837380, 55.0518800],
          [-2.3836500, 55.0518910],
          [-2.3835590, 55.0518990],
          [-2.3834720, 55.0519140],
          [-2.3833810, 55.0519250],
          [-2.3832920, 55.0519440],
          [-2.3832060, 55.0519590],
          [-2.3831250, 55.0519780],
          [-2.3830420, 55.0520010],
          [-2.3829630, 55.0520310],
          [-2.3828830, 55.0520590],
          [-2.3828120, 55.0520840],
          [-2.3827380, 55.0521150],
          [-2.3826650, 55.0521430],
          [-2.3825930, 55.0521770],
          [-2.3825250, 55.0522110],
          [-2.3824520, 55.0522500],
          [-2.3823800, 55.0522870],
          [-2.3823060, 55.0523250],
          [-2.3822260, 55.0523590],
          [-2.3821410, 55.0523910],
          [-2.3820530, 55.0524210],
          [-2.3819610, 55.0524480],
          [-2.3818660, 55.0524750],
          [-2.3817740, 55.0524970],
          [-2.3816780, 55.0525170],
          [-2.3815850, 55.0525360],
          [-2.3814920, 55.0525550],
          [-2.3813980, 55.0525730],
          [-2.3813020, 55.0525930],
          [-2.3812060, 55.0526200],
          [-2.3811130, 55.0526420],
          [-2.3810120, 55.0526620],
          [-2.3809100, 55.0526840],
          [-2.3808110, 55.0527030],
          [-2.3807120, 55.0527270],
          [-2.3806150, 55.0527490],
          [-2.3805200, 55.0527680],
          [-2.3804240, 55.0527870],
          [-2.3803310, 55.0528060],
          [-2.3802380, 55.0528250],
          [-2.3801500, 55.0528440],
          [-2.3800580, 55.0528670],
          [-2.3799700, 55.0528830],
          [-2.3798760, 55.0529020],
          [-2.3797820, 55.0529210],
          [-2.3796880, 55.0529400],
          [-2.3795990, 55.0529590],
          [-2.3795100, 55.0529740],
          [-2.3794170, 55.0529900],
          [-2.3793270, 55.0530050],
          [-2.3792360, 55.0530170],
          [-2.3791490, 55.0530320],
          [-2.3790640, 55.0530470],
          [-2.3789760, 55.0530620],
          [-2.3788890, 55.0530780],
          [-2.3788030, 55.0530920],
          [-2.3787150, 55.0531080],
          [-2.3786310, 55.0531230],
          [-2.3785480, 55.0531390],
          [-2.3784630, 55.0531540],
          [-2.3783780, 55.0531690],
          [-2.3782920, 55.0531880],
          [-2.3782080, 55.0532070],
          [-2.3781260, 55.0532260],
          [-2.3780430, 55.0532490],
          [-2.3779630, 55.0532680],
          [-2.3778820, 55.0532800],
          [-2.3778020, 55.0532940],
          [-2.3777200, 55.0533140],
          [-2.3776400, 55.0533330],
          [-2.3775540, 55.0533520],
          [-2.3774730, 55.0533710],
          [-2.3773890, 55.0533900],
          [-2.3773030, 55.0534050],
          [-2.3772100, 55.0534210],
          [-2.3771200, 55.0534360],
          [-2.3770300, 55.0534510],
          [-2.3769390, 55.0534660],
          [-2.3768460, 55.0534820],
          [-2.3767540, 55.0535010],
          [-2.3766690, 55.0535190],
          [-2.3765760, 55.0535390],
          [-2.3764850, 55.0535580],
          [-2.3763970, 55.0535770],
          [-2.3763040, 55.0535920],
          [-2.3762090, 55.0536040],
          [-2.3761120, 55.0536110],
          [-2.3760110, 55.0536120],
          [-2.3759150, 55.0536120],
          [-2.3758270, 55.0536080],
          [-2.3757420, 55.0535900],
          [-2.3756690, 55.0535630],
          [-2.3756090, 55.0535330],
          [-2.3755610, 55.0535060],
          [-2.3755170, 55.0534750],
          [-2.3754840, 55.0534490],
          [-2.3754490, 55.0534250],
          [-2.3754190, 55.0534030],
          [-2.3753860, 55.0533830],
          [-2.3753560, 55.0533650],
          [-2.3753240, 55.0533490],
          [-2.3752950, 55.0533340],
          [-2.3752660, 55.0533220],
          [-2.3752330, 55.0533110],
          [-2.3751900, 55.0532960],
          [-2.3751470, 55.0532880],
          [-2.3751090, 55.0532800],
          [-2.3750760, 55.0532760],
          [-2.3750430, 55.0532720],
          [-2.3750130, 55.0532650],
          [-2.3749770, 55.0532570],
          [-2.3749390, 55.0532530],
          [-2.3749100, 55.0532500],
          [-2.3748740, 55.0532460],
          [-2.3748410, 55.0532420],
          [-2.3748080, 55.0532420],
          [-2.3747720, 55.0532380],
          [-2.3747370, 55.0532340],
          [-2.3747080, 55.0532380],
          [-2.3746730, 55.0532340],
          [-2.3746380, 55.0532310],
          [-2.3746060, 55.0532300],
          [-2.3745730, 55.0532300],
          [-2.3745440, 55.0532270],
          [-2.3745100, 55.0532190],
          [-2.3744820, 55.0532110],
          [-2.3744470, 55.0532070],
          [-2.3744220, 55.0532000],
          [-2.3743940, 55.0531890],
          [-2.3743690, 55.0531810],
          [-2.3743460, 55.0531660],
          [-2.3743170, 55.0531510],
          [-2.3742970, 55.0531350],
          [-2.3742800, 55.0531250],
          [-2.3742540, 55.0531050],
          [-2.3742320, 55.0530930],
          [-2.3742180, 55.0530770],
          [-2.3741970, 55.0530550],
          [-2.3741860, 55.0530400],
          [-2.3741710, 55.0530210],
          [-2.3741540, 55.0530020],
          [-2.3741390, 55.0529830],
          [-2.3741270, 55.0529640],
          [-2.3741150, 55.0529410],
          [-2.3741070, 55.0529260],
          [-2.3740990, 55.0529070],
          [-2.3740870, 55.0528880],
          [-2.3740750, 55.0528720],
          [-2.3740640, 55.0528540],
          [-2.3740570, 55.0528420],
          [-2.3740440, 55.0528270],
          [-2.3740330, 55.0528120],
          [-2.3740250, 55.0527920],
          [-2.3740140, 55.0527700],
          [-2.3740040, 55.0527510],
          [-2.3739920, 55.0527270],
          [-2.3739800, 55.0527120],
          [-2.3739580, 55.0526860],
          [-2.3739310, 55.0526630],
          [-2.3739020, 55.0526410],
          [-2.3738770, 55.0526200],
          [-2.3738530, 55.0526030],
          [-2.3738240, 55.0525790],
          [-2.3738000, 55.0525640],
          [-2.3737710, 55.0525480],
          [-2.3737400, 55.0525370],
          [-2.3737070, 55.0525180],
          [-2.3736620, 55.0525020],
          [-2.3736150, 55.0524770],
          [-2.3735760, 55.0524600],
          [-2.3735370, 55.0524390],
          [-2.3734880, 55.0524150],
          [-2.3734420, 55.0523920],
          [-2.3733960, 55.0523730],
          [-2.3733460, 55.0523610],
          [-2.3732810, 55.0523420],
          [-2.3732170, 55.0523300],
          [-2.3731500, 55.0523260],
          [-2.3730810, 55.0523260],
          [-2.3729950, 55.0523230],
          [-2.3729240, 55.0523220],
          [-2.3728330, 55.0523260],
          [-2.3727420, 55.0523190],
          [-2.3726550, 55.0523190],
          [-2.3725710, 55.0523110],
          [-2.3725110, 55.0523040],
          [-2.3724530, 55.0522960],
          [-2.3724100, 55.0522920],
          [-2.3723650, 55.0522730],
          [-2.3723230, 55.0522540],
          [-2.3722840, 55.0522290],
          [-2.3722550, 55.0522020],
          [-2.3722230, 55.0521720],
          [-2.3721960, 55.0521410],
          [-2.3721660, 55.0520990],
          [-2.3721500, 55.0520740],
          [-2.3721260, 55.0520270],
          [-2.3721110, 55.0519970],
          [-2.3720910, 55.0519620],
          [-2.3720730, 55.0519310],
          [-2.3720460, 55.0519000],
          [-2.3720130, 55.0518710],
          [-2.3719710, 55.0518320],
          [-2.3719380, 55.0518020],
          [-2.3719020, 55.0517780],
          [-2.3718690, 55.0517520],
          [-2.3718360, 55.0517280],
          [-2.3718090, 55.0517030],
          [-2.3717800, 55.0516830],
          [-2.3717540, 55.0516590],
          [-2.3717210, 55.0516440],
          [-2.3716890, 55.0516210],
          [-2.3716580, 55.0516020],
          [-2.3716210, 55.0515790],
          [-2.3715850, 55.0515600],
          [-2.3715470, 55.0515370],
          [-2.3715160, 55.0515220],
          [-2.3714820, 55.0514990],
          [-2.3714520, 55.0514830],
          [-2.3714240, 55.0514610],
          [-2.3713890, 55.0514420],
          [-2.3713520, 55.0514190],
          [-2.3713170, 55.0514000],
          [-2.3712780, 55.0513810],
          [-2.3712390, 55.0513580],
          [-2.3712060, 55.0513380],
          [-2.3711740, 55.0513160],
          [-2.3711420, 55.0512980],
          [-2.3711040, 55.0512700],
          [-2.3710660, 55.0512470],
          [-2.3710260, 55.0512200],
          [-2.3709780, 55.0511900],
          [-2.3709260, 55.0511670],
          [-2.3708770, 55.0511370],
          [-2.3708230, 55.0511060],
          [-2.3707620, 55.0510760],
          [-2.3707040, 55.0510490],
          [-2.3706410, 55.0510140],
          [-2.3705780, 55.0509840],
          [-2.3705150, 55.0509540],
          [-2.3704440, 55.0509200],
          [-2.3703650, 55.0508850],
          [-2.3702880, 55.0508510],
          [-2.3702130, 55.0508130],
          [-2.3701260, 55.0507700],
          [-2.3700340, 55.0507320],
          [-2.3699470, 55.0506950],
          [-2.3698680, 55.0506600],
          [-2.3697810, 55.0506290],
          [-2.3696870, 55.0505940],
          [-2.3695890, 55.0505640],
          [-2.3694960, 55.0505380],
          [-2.3694090, 55.0505150],
          [-2.3693210, 55.0504950],
          [-2.3692380, 55.0504800],
          [-2.3691500, 55.0504610],
          [-2.3690660, 55.0504460],
          [-2.3689830, 55.0504310],
          [-2.3689020, 55.0504150],
          [-2.3688210, 55.0504000],
          [-2.3687400, 55.0503890],
          [-2.3686580, 55.0503700],
          [-2.3685780, 55.0503540],
          [-2.3684980, 55.0503430],
          [-2.3684150, 55.0503240],
          [-2.3683310, 55.0503080],
          [-2.3682480, 55.0502930],
          [-2.3681660, 55.0502820],
          [-2.3680860, 55.0502700],
          [-2.3680080, 55.0502590],
          [-2.3679220, 55.0502510],
          [-2.3678350, 55.0502440],
          [-2.3677500, 55.0502400],
          [-2.3676640, 55.0502360],
          [-2.3675780, 55.0502280],
          [-2.3675030, 55.0502240],
          [-2.3674280, 55.0502210],
          [-2.3673530, 55.0502240],
          [-2.3672800, 55.0502320],
          [-2.3672060, 55.0502390],
          [-2.3671310, 55.0502510],
          [-2.3670570, 55.0502580],
          [-2.3669900, 55.0502770],
          [-2.3669250, 55.0503000],
          [-2.3668610, 55.0503190],
          [-2.3667960, 55.0503380],
          [-2.3667280, 55.0503610],
          [-2.3666600, 55.0503840],
          [-2.3665950, 55.0504070],
          [-2.3665300, 55.0504330],
          [-2.3664690, 55.0504570],
          [-2.3664060, 55.0504830],
          [-2.3663410, 55.0505020],
          [-2.3662770, 55.0505210],
          [-2.3662130, 55.0505400],
          [-2.3661550, 55.0505590],
          [-2.3660940, 55.0505750],
          [-2.3660350, 55.0505940],
          [-2.3659760, 55.0506130],
          [-2.3659160, 55.0506320],
          [-2.3658620, 55.0506550],
          [-2.3658060, 55.0506780],
          [-2.3657500, 55.0506970],
          [-2.3656990, 55.0507160],
          [-2.3656460, 55.0507350],
          [-2.3655960, 55.0507540],
          [-2.3655440, 55.0507730],
          [-2.3654890, 55.0507960],
          [-2.3654320, 55.0508110],
          [-2.3653730, 55.0508340],
          [-2.3653160, 55.0508530],
          [-2.3652560, 55.0508720],
          [-2.3651990, 55.0508950],
          [-2.3651460, 55.0509180],
          [-2.3650890, 55.0509410],
          [-2.3650360, 55.0509640],
          [-2.3649820, 55.0509870],
          [-2.3649270, 55.0510100],
          [-2.3648720, 55.0510330],
          [-2.3648160, 55.0510520],
          [-2.3647600, 55.0510740],
          [-2.3646970, 55.0510980],
          [-2.3646330, 55.0511170],
          [-2.3645680, 55.0511390],
          [-2.3645050, 55.0511700],
          [-2.3644380, 55.0512020],
          [-2.3643590, 55.0512310],
          [-2.3642820, 55.0512540],
          [-2.3642080, 55.0512770],
          [-2.3641310, 55.0513000],
          [-2.3640530, 55.0513220],
          [-2.3639830, 55.0513450],
          [-2.3639150, 55.0513680],
          [-2.3638460, 55.0513910],
          [-2.3637740, 55.0514180],
          [-2.3637060, 55.0514450],
          [-2.3636330, 55.0514710],
          [-2.3635630, 55.0514980],
          [-2.3634930, 55.0515250],
          [-2.3634180, 55.0515510],
          [-2.3633470, 55.0515810],
          [-2.3632700, 55.0516080],
          [-2.3631910, 55.0516430],
          [-2.3631190, 55.0516720],
          [-2.3630440, 55.0517040],
          [-2.3629670, 55.0517340],
          [-2.3628880, 55.0517690],
          [-2.3628090, 55.0517990],
          [-2.3627330, 55.0518260],
          [-2.3626550, 55.0518490],
          [-2.3625720, 55.0518790],
          [-2.3624940, 55.0519050],
          [-2.3624100, 55.0519330],
          [-2.3623240, 55.0519590],
          [-2.3622360, 55.0519860],
          [-2.3621440, 55.0520130],
          [-2.3620570, 55.0520400],
          [-2.3619640, 55.0520630],
          [-2.3618730, 55.0520850],
          [-2.3617820, 55.0521120],
          [-2.3616880, 55.0521390],
          [-2.3616010, 55.0521620],
          [-2.3615180, 55.0521850],
          [-2.3614420, 55.0522040],
          [-2.3613660, 55.0522230],
          [-2.3612880, 55.0522460],
          [-2.3612090, 55.0522680],
          [-2.3611240, 55.0522840],
          [-2.3610420, 55.0523030],
          [-2.3609610, 55.0523220],
          [-2.3608780, 55.0523410],
          [-2.3607890, 55.0523600],
          [-2.3606980, 55.0523830],
          [-2.3606200, 55.0524050],
          [-2.3605410, 55.0524290],
          [-2.3604530, 55.0524560],
          [-2.3603750, 55.0524780],
          [-2.3602990, 55.0525010],
          [-2.3602270, 55.0525240],
          [-2.3601500, 55.0525430],
          [-2.3600770, 55.0525660],
          [-2.3600030, 55.0525890],
          [-2.3599310, 55.0526110],
          [-2.3598580, 55.0526350],
          [-2.3597880, 55.0526580],
          [-2.3597170, 55.0526800],
          [-2.3596450, 55.0527030],
          [-2.3595670, 55.0527260],
          [-2.3594820, 55.0527530],
          [-2.3594000, 55.0527790],
          [-2.3593180, 55.0528030],
          [-2.3592290, 55.0528290],
          [-2.3591500, 55.0528520],
          [-2.3590680, 55.0528780],
          [-2.3589880, 55.0529020],
          [-2.3589010, 55.0529320],
          [-2.3588180, 55.0529570],
          [-2.3587310, 55.0529820],
          [-2.3586500, 55.0530050],
          [-2.3585670, 55.0530280],
          [-2.3584850, 55.0530500],
          [-2.3584020, 55.0530700],
          [-2.3583180, 55.0530890],
          [-2.3582410, 55.0531080],
          [-2.3581580, 55.0531230],
          [-2.3580730, 55.0531380],
          [-2.3579870, 55.0531570],
          [-2.3578970, 55.0531730],
          [-2.3578050, 55.0531880],
          [-2.3577030, 55.0532030],
          [-2.3576070, 55.0532220],
          [-2.3575030, 55.0532410],
          [-2.3574150, 55.0532680],
          [-2.3573330, 55.0532900],
          [-2.3572460, 55.0533030],
          [-2.3571620, 55.0533210],
          [-2.3570730, 55.0533370],
          [-2.3569860, 55.0533560],
          [-2.3569050, 55.0533710],
          [-2.3568190, 55.0533900],
          [-2.3567350, 55.0534130],
          [-2.3566230, 55.0534360],
          [-2.3565310, 55.0534480],
          [-2.3564440, 55.0534590],
          [-2.3563560, 55.0534700],
          [-2.3562630, 55.0534900],
          [-2.3561660, 55.0535050],
          [-2.3560610, 55.0535270],
          [-2.3559410, 55.0535470],
          [-2.3558240, 55.0535690],
          [-2.3557030, 55.0535920],
          [-2.3555720, 55.0536110],
          [-2.3554540, 55.0536230],
          [-2.3553500, 55.0536370],
          [-2.3552390, 55.0536530],
          [-2.3551280, 55.0536650],
          [-2.3550160, 55.0536840],
          [-2.3549100, 55.0536990],
          [-2.3548050, 55.0537180],
          [-2.3547070, 55.0537300],
          [-2.3546040, 55.0537520],
          [-2.3545050, 55.0537720],
          [-2.3544000, 55.0537940],
          [-2.3543020, 55.0538170],
          [-2.3541950, 55.0538360],
          [-2.3540850, 55.0538550],
          [-2.3539800, 55.0538780],
          [-2.3538630, 55.0538980],
          [-2.3537600, 55.0539130],
          [-2.3536580, 55.0539320],
          [-2.3535600, 55.0539510],
          [-2.3534620, 55.0539620],
          [-2.3533670, 55.0539780],
          [-2.3532600, 55.0539920],
          [-2.3531560, 55.0540120],
          [-2.3530570, 55.0540340],
          [-2.3529610, 55.0540500],
          [-2.3528690, 55.0540690],
          [-2.3527800, 55.0540840],
          [-2.3526870, 55.0541030],
          [-2.3525910, 55.0541150],
          [-2.3525010, 55.0541260],
          [-2.3524230, 55.0541340],
          [-2.3523570, 55.0541420],
          [-2.3522990, 55.0541460],
          [-2.3522390, 55.0541530],
          [-2.3521760, 55.0541530],
          [-2.3521110, 55.0541530],
          [-2.3520460, 55.0541530],
          [-2.3519910, 55.0541530],
          [-2.3519310, 55.0541530],
          [-2.3518780, 55.0541530],
          [-2.3518260, 55.0541530],
          [-2.3517710, 55.0541500],
          [-2.3517180, 55.0541500],
          [-2.3516690, 55.0541500],
          [-2.3516220, 55.0541530],
          [-2.3515770, 55.0541530],
          [-2.3515290, 55.0541530],
          [-2.3514790, 55.0541570],
          [-2.3514290, 55.0541570],
          [-2.3513830, 55.0541540],
          [-2.3513370, 55.0541500],
          [-2.3512910, 55.0541500],
          [-2.3512430, 55.0541500],
          [-2.3511930, 55.0541500],
          [-2.3511460, 55.0541530],
          [-2.3511020, 55.0541530],
          [-2.3510590, 55.0541570],
          [-2.3510170, 55.0541610],
          [-2.3509720, 55.0541610],
          [-2.3509280, 55.0541650],
          [-2.3508810, 55.0541610],
          [-2.3508350, 55.0541570],
          [-2.3507860, 55.0541570],
          [-2.3507360, 55.0541540],
          [-2.3506910, 55.0541530],
          [-2.3506440, 55.0541500],
          [-2.3505970, 55.0541460],
          [-2.3505500, 55.0541420],
          [-2.3504990, 55.0541380],
          [-2.3504440, 55.0541380],
          [-2.3503880, 55.0541340],
          [-2.3503320, 55.0541310],
          [-2.3502710, 55.0541270],
          [-2.3502090, 55.0541190],
          [-2.3501460, 55.0541190],
          [-2.3500810, 55.0541150],
          [-2.3500110, 55.0541150],
          [-2.3499410, 55.0541120],
          [-2.3498710, 55.0541110],
          [-2.3498000, 55.0541080],
          [-2.3497280, 55.0541000],
          [-2.3496570, 55.0541000],
          [-2.3495860, 55.0541040],
          [-2.3495150, 55.0541080],
          [-2.3494450, 55.0541150],
          [-2.3493740, 55.0541250],
          [-2.3493030, 55.0541340],
          [-2.3492330, 55.0541490],
          [-2.3491660, 55.0541610],
          [-2.3490970, 55.0541760],
          [-2.3490260, 55.0541910],
          [-2.3489600, 55.0542070],
          [-2.3488930, 55.0542250],
          [-2.3488220, 55.0542440],
          [-2.3487480, 55.0542600],
          [-2.3486780, 55.0542790],
          [-2.3486060, 55.0542980],
          [-2.3485310, 55.0543130],
          [-2.3484580, 55.0543320],
          [-2.3483830, 55.0543520],
          [-2.3483070, 55.0543740],
          [-2.3482300, 55.0543930],
          [-2.3481540, 55.0544160],
          [-2.3480810, 55.0544360],
          [-2.3480110, 55.0544540],
          [-2.3479390, 55.0544740],
          [-2.3478700, 55.0544960],
          [-2.3478010, 55.0545110],
          [-2.3477300, 55.0545310],
          [-2.3476600, 55.0545500],
          [-2.3475900, 55.0545690],
          [-2.3475190, 55.0545880],
          [-2.3474490, 55.0546030],
          [-2.3473760, 55.0546220],
          [-2.3473040, 55.0546410],
          [-2.3472330, 55.0546610],
          [-2.3471600, 55.0546800],
          [-2.3470900, 55.0546980],
          [-2.3470170, 55.0547180],
          [-2.3469430, 55.0547370],
          [-2.3468710, 55.0547560],
          [-2.3467980, 55.0547750],
          [-2.3467280, 55.0547940],
          [-2.3466570, 55.0548130],
          [-2.3465870, 55.0548360],
          [-2.3465150, 55.0548550],
          [-2.3464430, 55.0548740],
          [-2.3463690, 55.0548930],
          [-2.3462950, 55.0549120],
          [-2.3462180, 55.0549240],
          [-2.3461430, 55.0549310],
          [-2.3460630, 55.0549320],
          [-2.3459880, 55.0549280],
          [-2.3459170, 55.0549170],
          [-2.3458480, 55.0549010],
          [-2.3457830, 55.0548790],
          [-2.3457250, 55.0548560],
          [-2.3456710, 55.0548300],
          [-2.3456170, 55.0548030],
          [-2.3455670, 55.0547730],
          [-2.3455210, 55.0547380],
          [-2.3454740, 55.0547070],
          [-2.3454280, 55.0546770],
          [-2.3453780, 55.0546500],
          [-2.3453280, 55.0546200],
          [-2.3452780, 55.0545970],
          [-2.3452210, 55.0545780],
          [-2.3451580, 55.0545660],
          [-2.3450930, 55.0545620],
          [-2.3450270, 55.0545620],
          [-2.3449580, 55.0545690],
          [-2.3448860, 55.0545800],
          [-2.3448160, 55.0545960],
          [-2.3447480, 55.0546150],
          [-2.3446820, 55.0546370],
          [-2.3446080, 55.0546570],
          [-2.3445360, 55.0546790],
          [-2.3444590, 55.0546990],
          [-2.3443850, 55.0547210],
          [-2.3443080, 55.0547440],
          [-2.3442300, 55.0547630],
          [-2.3441530, 55.0547860],
          [-2.3440760, 55.0548090],
          [-2.3439970, 55.0548280],
          [-2.3439170, 55.0548470],
          [-2.3438360, 55.0548670],
          [-2.3437590, 55.0548890],
          [-2.3436780, 55.0549120],
          [-2.3435990, 55.0549350],
          [-2.3435180, 55.0549540],
          [-2.3434380, 55.0549770],
          [-2.3433580, 55.0549960],
          [-2.3432780, 55.0550150],
          [-2.3432000, 55.0550340],
          [-2.3431210, 55.0550500],
          [-2.3430420, 55.0550720],
          [-2.3429570, 55.0551060],
          [-2.3428760, 55.0551300],
          [-2.3427960, 55.0551560],
          [-2.3427160, 55.0551790],
          [-2.3426360, 55.0552020],
          [-2.3425590, 55.0552250],
          [-2.3424820, 55.0552510],
          [-2.3424080, 55.0552750],
          [-2.3423350, 55.0553010],
          [-2.3422610, 55.0553280],
          [-2.3421870, 55.0553540],
          [-2.3421100, 55.0553810],
          [-2.3420340, 55.0554040],
          [-2.3419530, 55.0554230],
          [-2.3418780, 55.0554460],
          [-2.3418050, 55.0554730],
          [-2.3417310, 55.0554960],
          [-2.3416590, 55.0555260],
          [-2.3415860, 55.0555530],
          [-2.3415170, 55.0555790],
          [-2.3414450, 55.0556100],
          [-2.3413750, 55.0556370],
          [-2.3413050, 55.0556630],
          [-2.3412350, 55.0556940],
          [-2.3411640, 55.0557240],
          [-2.3410910, 55.0557550],
          [-2.3410210, 55.0557850],
          [-2.3409500, 55.0558160],
          [-2.3408800, 55.0558500],
          [-2.3408110, 55.0558810],
          [-2.3407470, 55.0559150],
          [-2.3406810, 55.0559460],
          [-2.3406170, 55.0559800],
          [-2.3405510, 55.0560110],
          [-2.3404890, 55.0560450],
          [-2.3404300, 55.0560790],
          [-2.3403740, 55.0561140],
          [-2.3403160, 55.0561480],
          [-2.3402580, 55.0561820],
          [-2.3402030, 55.0562200],
          [-2.3401520, 55.0562580],
          [-2.3401030, 55.0563000],
          [-2.3400500, 55.0563380],
          [-2.3399940, 55.0563840],
          [-2.3399430, 55.0564260],
          [-2.3398940, 55.0564720],
          [-2.3398470, 55.0565160],
          [-2.3397960, 55.0565600],
          [-2.3397420, 55.0566050],
          [-2.3396840, 55.0566480],
          [-2.3396270, 55.0566890],
          [-2.3395660, 55.0567280],
          [-2.3395040, 55.0567700],
          [-2.3394430, 55.0568040],
          [-2.3393780, 55.0568420],
          [-2.3393090, 55.0568800],
          [-2.3392460, 55.0569140],
          [-2.3391810, 55.0569530],
          [-2.3391130, 55.0569870],
          [-2.3390310, 55.0570280],
          [-2.3389490, 55.0570630],
          [-2.3388690, 55.0570980],
          [-2.3387910, 55.0571320],
          [-2.3387150, 55.0571630],
          [-2.3386370, 55.0571930],
          [-2.3385600, 55.0572310],
          [-2.3384830, 55.0572620],
          [-2.3384110, 55.0573000],
          [-2.3383400, 55.0573380],
          [-2.3382700, 55.0573800],
          [-2.3381990, 55.0574180],
          [-2.3381310, 55.0574560],
          [-2.3380680, 55.0575010],
          [-2.3380000, 55.0575480],
          [-2.3379310, 55.0575940],
          [-2.3378730, 55.0576360],
          [-2.3377970, 55.0576960],
          [-2.3377240, 55.0577540],
          [-2.3376510, 55.0578110],
          [-2.3375750, 55.0578720],
          [-2.3374960, 55.0579280],
          [-2.3374110, 55.0579860],
          [-2.3373280, 55.0580470],
          [-2.3372440, 55.0581040],
          [-2.3371600, 55.0581620],
          [-2.3370800, 55.0582190],
          [-2.3369990, 55.0582720],
          [-2.3369180, 55.0583300],
          [-2.3368380, 55.0583830],
          [-2.3367570, 55.0584320],
          [-2.3366740, 55.0584820],
          [-2.3365860, 55.0585320],
          [-2.3364980, 55.0585740],
          [-2.3364130, 55.0586160],
          [-2.3363300, 55.0586580],
          [-2.3362480, 55.0586990],
          [-2.3361640, 55.0587380],
          [-2.3360830, 55.0587800],
          [-2.3360010, 55.0588260],
          [-2.3359200, 55.0588700],
          [-2.3358350, 55.0589170],
          [-2.3357530, 55.0589630],
          [-2.3356700, 55.0590120],
          [-2.3355860, 55.0590620],
          [-2.3355040, 55.0591110],
          [-2.3354200, 55.0591610],
          [-2.3353410, 55.0592080],
          [-2.3352510, 55.0592600],
          [-2.3351690, 55.0593100],
          [-2.3350860, 55.0593600],
          [-2.3350040, 55.0594090],
          [-2.3349250, 55.0594550],
          [-2.3348450, 55.0595050],
          [-2.3347650, 55.0595540],
          [-2.3346860, 55.0595990],
          [-2.3346030, 55.0596500],
          [-2.3345220, 55.0596990],
          [-2.3344400, 55.0597490],
          [-2.3343610, 55.0597950],
          [-2.3342830, 55.0598440],
          [-2.3342080, 55.0598900],
          [-2.3341330, 55.0599360],
          [-2.3340590, 55.0599770],
          [-2.3339850, 55.0600230],
          [-2.3339070, 55.0600690],
          [-2.3338290, 55.0601110],
          [-2.3337510, 55.0601550],
          [-2.3336650, 55.0602030],
          [-2.3335780, 55.0602480],
          [-2.3334860, 55.0602980],
          [-2.3333980, 55.0603510],
          [-2.3333060, 55.0604080],
          [-2.3332190, 55.0604650],
          [-2.3331380, 55.0605300],
          [-2.3330700, 55.0605940],
          [-2.3330080, 55.0606640],
          [-2.3329580, 55.0607280],
          [-2.3329120, 55.0607860],
          [-2.3328710, 55.0608430],
          [-2.3328330, 55.0609000],
          [-2.3327990, 55.0609510],
          [-2.3327720, 55.0610010],
          [-2.3327410, 55.0610610],
          [-2.3327080, 55.0611140],
          [-2.3326710, 55.0611750],
          [-2.3326360, 55.0612320],
          [-2.3325930, 55.0612930],
          [-2.3325460, 55.0613510],
          [-2.3324940, 55.0614120],
          [-2.3324340, 55.0614690],
          [-2.3323660, 55.0615290],
          [-2.3322830, 55.0615870],
          [-2.3321950, 55.0616350],
          [-2.3320910, 55.0616820],
          [-2.3319850, 55.0617200],
          [-2.3318670, 55.0617550],
          [-2.3317380, 55.0617860],
          [-2.3315930, 55.0618090],
          [-2.3314410, 55.0618280],
          [-2.3312810, 55.0618470],
          [-2.3311130, 55.0618660],
          [-2.3309410, 55.0618890],
          [-2.3307540, 55.0619050],
          [-2.3305710, 55.0619310],
          [-2.3303870, 55.0619500],
          [-2.3302100, 55.0619660],
          [-2.3300440, 55.0619810],
          [-2.3298800, 55.0619890],
          [-2.3297330, 55.0620000],
          [-2.3295950, 55.0620080],
          [-2.3294620, 55.0620120],
          [-2.3293540, 55.0620150],
          [-2.3292510, 55.0620270],
          [-2.3291520, 55.0620380],
          [-2.3290600, 55.0620500],
          [-2.3289710, 55.0620570],
          [-2.3288890, 55.0620650],
          [-2.3288040, 55.0620730],
          [-2.3287260, 55.0620840],
          [-2.3286460, 55.0620990],
          [-2.3285620, 55.0621110],
          [-2.3284780, 55.0621220],
          [-2.3283880, 55.0621370],
          [-2.3283030, 55.0621490],
          [-2.3282120, 55.0621640],
          [-2.3281200, 55.0621720],
          [-2.3280310, 55.0621830],
          [-2.3279460, 55.0621950],
          [-2.3278650, 55.0622060],
          [-2.3277850, 55.0622140],
          [-2.3277050, 55.0622250],
          [-2.3276280, 55.0622330],
          [-2.3275460, 55.0622400],
          [-2.3274580, 55.0622550],
          [-2.3273710, 55.0622670],
          [-2.3272880, 55.0622750],
          [-2.3272100, 55.0622860],
          [-2.3271310, 55.0622980],
          [-2.3270530, 55.0623050],
          [-2.3269720, 55.0623170],
          [-2.3268950, 55.0623280],
          [-2.3268210, 55.0623360],
          [-2.3267410, 55.0623430],
          [-2.3266620, 55.0623550],
          [-2.3265780, 55.0623660],
          [-2.3264940, 55.0623780],
          [-2.3264120, 55.0623930],
          [-2.3263270, 55.0624040],
          [-2.3262360, 55.0624120],
          [-2.3261450, 55.0624240],
          [-2.3260540, 55.0624350],
          [-2.3259590, 55.0624500],
          [-2.3258600, 55.0624620],
          [-2.3257630, 55.0624730],
          [-2.3256680, 55.0624810],
          [-2.3255730, 55.0624880],
          [-2.3254830, 55.0625000],
          [-2.3253950, 55.0625080],
          [-2.3253140, 55.0625150],
          [-2.3252310, 55.0625230],
          [-2.3251480, 55.0625270],
          [-2.3250720, 55.0625340],
          [-2.3249960, 55.0625420],
          [-2.3249230, 55.0625530],
          [-2.3248500, 55.0625610],
          [-2.3247770, 55.0625720],
          [-2.3247050, 55.0625800],
          [-2.3246310, 55.0625910],
          [-2.3245610, 55.0625990],
          [-2.3244950, 55.0626070],
          [-2.3244330, 55.0626180],
          [-2.3243730, 55.0626260],
          [-2.3243150, 55.0626330],
          [-2.3242580, 55.0626370],
          [-2.3242030, 55.0626450],
          [-2.3241500, 55.0626520],
          [-2.3240940, 55.0626600],
          [-2.3240400, 55.0626640],
          [-2.3239850, 55.0626710],
          [-2.3239330, 55.0626750],
          [-2.3238800, 55.0626830],
          [-2.3238290, 55.0626870],
          [-2.3237780, 55.0626940],
          [-2.3237280, 55.0626980],
          [-2.3236810, 55.0627060],
          [-2.3236360, 55.0627100],
          [-2.3235930, 55.0627170],
          [-2.3235470, 55.0627250],
          [-2.3235020, 55.0627250],
          [-2.3234570, 55.0627250],
          [-2.3234080, 55.0627250],
          [-2.3233580, 55.0627290],
          [-2.3233110, 55.0627330],
          [-2.3232650, 55.0627360],
          [-2.3232190, 55.0627440],
          [-2.3231760, 55.0627520],
          [-2.3231370, 55.0627630],
          [-2.3230990, 55.0627710],
          [-2.3230560, 55.0627750],
          [-2.3230140, 55.0627780],
          [-2.3229710, 55.0627860],
          [-2.3229270, 55.0627900],
          [-2.3228830, 55.0627900],
          [-2.3228410, 55.0627930],
          [-2.3227960, 55.0628010],
          [-2.3227530, 55.0628050],
          [-2.3227080, 55.0628130],
          [-2.3226620, 55.0628200],
          [-2.3226180, 55.0628280],
          [-2.3225730, 55.0628320],
          [-2.3225240, 55.0628390],
          [-2.3224770, 55.0628470],
          [-2.3224310, 55.0628550],
          [-2.3223840, 55.0628580],
          [-2.3223380, 55.0628660],
          [-2.3222930, 55.0628700],
          [-2.3222470, 55.0628780],
          [-2.3222030, 55.0628850],
          [-2.3221560, 55.0628930],
          [-2.3221090, 55.0628970],
          [-2.3220640, 55.0628970],
          [-2.3220180, 55.0629040],
          [-2.3219720, 55.0629120],
          [-2.3219280, 55.0629190],
          [-2.3218830, 55.0629230],
          [-2.3218390, 55.0629310],
          [-2.3217940, 55.0629390],
          [-2.3217490, 55.0629460],
          [-2.3217050, 55.0629540],
          [-2.3216600, 55.0629580],
          [-2.3216140, 55.0629610],
          [-2.3215690, 55.0629690],
          [-2.3215250, 55.0629770],
          [-2.3214780, 55.0629810],
          [-2.3214310, 55.0629840],
          [-2.3213860, 55.0629880],
          [-2.3213420, 55.0629960],
          [-2.3213020, 55.0630030],
          [-2.3212610, 55.0630070],
          [-2.3212140, 55.0630150],
          [-2.3211630, 55.0630150],
          [-2.3211090, 55.0630220],
          [-2.3210570, 55.0630260],
          [-2.3210050, 55.0630340],
          [-2.3209520, 55.0630410],
          [-2.3208990, 55.0630490],
          [-2.3208480, 55.0630600],
          [-2.3207890, 55.0630680],
          [-2.3207360, 55.0630720],
          [-2.3206860, 55.0630800],
          [-2.3206350, 55.0630910],
          [-2.3205810, 55.0630990],
          [-2.3205230, 55.0631060],
          [-2.3204580, 55.0631180],
          [-2.3203890, 55.0631250],
          [-2.3203170, 55.0631330],
          [-2.3202480, 55.0631440],
          [-2.3201710, 55.0631520],
          [-2.3200960, 55.0631600],
          [-2.3200250, 55.0631710],
          [-2.3199510, 55.0631790],
          [-2.3198760, 55.0631860],
          [-2.3197820, 55.0631900],
          [-2.3196900, 55.0631980],
          [-2.3195990, 55.0632020],
          [-2.3195010, 55.0632130],
          [-2.3194120, 55.0632250],
          [-2.3193070, 55.0632360],
          [-2.3192070, 55.0632510],
          [-2.3191030, 55.0632670],
          [-2.3190020, 55.0632820],
          [-2.3189040, 55.0633010],
          [-2.3187930, 55.0633160],
          [-2.3186820, 55.0633310],
          [-2.3185700, 55.0633390],
          [-2.3184590, 55.0633500],
          [-2.3183500, 55.0633650],
          [-2.3182220, 55.0633810],
          [-2.3181020, 55.0633960],
          [-2.3179670, 55.0634110],
          [-2.3178390, 55.0634230],
          [-2.3177130, 55.0634310],
          [-2.3175950, 55.0634420],
          [-2.3174770, 55.0634570],
          [-2.3173570, 55.0634690],
          [-2.3172370, 55.0634840],
          [-2.3171120, 55.0635030],
          [-2.3169830, 55.0635220],
          [-2.3168500, 55.0635370],
          [-2.3167220, 55.0635520],
          [-2.3165870, 55.0635680],
          [-2.3164480, 55.0635870],
          [-2.3163020, 55.0636060],
          [-2.3161430, 55.0636210],
          [-2.3159940, 55.0636400],
          [-2.3158430, 55.0636630],
          [-2.3157090, 55.0636780],
          [-2.3155730, 55.0636970],
          [-2.3154350, 55.0637130],
          [-2.3153010, 55.0637240],
          [-2.3151610, 55.0637320],
          [-2.3150300, 55.0637400],
          [-2.3148900, 55.0637550],
          [-2.3147400, 55.0637660],
          [-2.3145970, 55.0637810],
          [-2.3144540, 55.0638000],
          [-2.3143100, 55.0638160],
          [-2.3141640, 55.0638350],
          [-2.3140220, 55.0638500],
          [-2.3138820, 55.0638620],
          [-2.3137460, 55.0638730],
          [-2.3136090, 55.0638880],
          [-2.3134790, 55.0639000],
          [-2.3133420, 55.0639110],
          [-2.3132140, 55.0639230],
          [-2.3130910, 55.0639300],
          [-2.3129650, 55.0639460],
          [-2.3128450, 55.0639610],
          [-2.3127280, 55.0639720],
          [-2.3126140, 55.0639800],
          [-2.3125020, 55.0639910],
          [-2.3123820, 55.0640060],
          [-2.3122670, 55.0640140],
          [-2.3121590, 55.0640180],
          [-2.3120580, 55.0640260],
          [-2.3119330, 55.0640330],
          [-2.3118270, 55.0640480],
          [-2.3117240, 55.0640600],
          [-2.3116210, 55.0640680],
          [-2.3115300, 55.0640720],
          [-2.3114400, 55.0640790],
          [-2.3113550, 55.0640870],
          [-2.3112650, 55.0640940],
          [-2.3111810, 55.0641020],
          [-2.3111050, 55.0641130],
          [-2.3110270, 55.0641240],
          [-2.3109480, 55.0641360],
          [-2.3108710, 55.0641510],
          [-2.3107910, 55.0641630],
          [-2.3107100, 55.0641740],
          [-2.3106250, 55.0641860],
          [-2.3105490, 55.0642000],
          [-2.3104570, 55.0642160],
          [-2.3103730, 55.0642280],
          [-2.3102870, 55.0642360],
          [-2.3101990, 55.0642470],
          [-2.3101150, 55.0642620],
          [-2.3100330, 55.0642770],
          [-2.3099530, 55.0642890],
          [-2.3098740, 55.0643040],
          [-2.3097880, 55.0643230],
          [-2.3097050, 55.0643340],
          [-2.3096180, 55.0643420],
          [-2.3095320, 55.0643570],
          [-2.3094470, 55.0643760],
          [-2.3093640, 55.0643950],
          [-2.3092760, 55.0644110],
          [-2.3091890, 55.0644260],
          [-2.3091000, 55.0644410],
          [-2.3090120, 55.0644530],
          [-2.3089240, 55.0644680],
          [-2.3088370, 55.0644800],
          [-2.3087450, 55.0644910],
          [-2.3086470, 55.0645060],
          [-2.3085500, 55.0645180],
          [-2.3084530, 55.0645290],
          [-2.3083470, 55.0645410],
          [-2.3082380, 55.0645520],
          [-2.3081200, 55.0645670],
          [-2.3079990, 55.0645820],
          [-2.3078610, 55.0645980],
          [-2.3077160, 55.0646130],
          [-2.3075640, 55.0646360],
          [-2.3074080, 55.0646550],
          [-2.3072450, 55.0646780],
          [-2.3070780, 55.0646970],
          [-2.3069120, 55.0647160],
          [-2.3067450, 55.0647350],
          [-2.3065850, 55.0647540],
          [-2.3064060, 55.0647810],
          [-2.3062450, 55.0648000],
          [-2.3060900, 55.0648220],
          [-2.3059330, 55.0648420],
          [-2.3057860, 55.0648570],
          [-2.3056310, 55.0648760],
          [-2.3054910, 55.0648950],
          [-2.3053570, 55.0649140],
          [-2.3052270, 55.0649340],
          [-2.3050980, 55.0649530],
          [-2.3049700, 55.0649640],
          [-2.3048490, 55.0649750],
          [-2.3047280, 55.0649830],
          [-2.3046180, 55.0649940],
          [-2.3045120, 55.0649980],
          [-2.3043970, 55.0650100],
          [-2.3042860, 55.0650170],
          [-2.3041750, 55.0650250],
          [-2.3040710, 55.0650370],
          [-2.3039670, 55.0650400],
          [-2.3038630, 55.0650520],
          [-2.3037610, 55.0650630],
          [-2.3036550, 55.0650780],
          [-2.3035480, 55.0650900],
          [-2.3034400, 55.0651050],
          [-2.3033330, 55.0651200],
          [-2.3032190, 55.0651390],
          [-2.3031050, 55.0651550],
          [-2.3029890, 55.0651700],
          [-2.3028820, 55.0651810],
          [-2.3027720, 55.0651930],
          [-2.3026700, 55.0652070],
          [-2.3025510, 55.0652240],
          [-2.3024450, 55.0652350],
          [-2.3023430, 55.0652460],
          [-2.3022340, 55.0652610],
          [-2.3021300, 55.0652770],
          [-2.3020270, 55.0652850],
          [-2.3019200, 55.0652920],
          [-2.3018140, 55.0653000],
          [-2.3017120, 55.0653110],
          [-2.3016100, 55.0653260],
          [-2.3015090, 55.0653380],
          [-2.3014050, 55.0653570],
          [-2.3012980, 55.0653760],
          [-2.3011880, 55.0653910],
          [-2.3010770, 55.0654030],
          [-2.3009710, 55.0654140],
          [-2.3008690, 55.0654290],
          [-2.3007650, 55.0654450],
          [-2.3006650, 55.0654630],
          [-2.3005560, 55.0654790],
          [-2.3004440, 55.0654980],
          [-2.3003350, 55.0655250],
          [-2.3002440, 55.0655620],
          [-2.3001650, 55.0656120],
          [-2.3001010, 55.0656610],
          [-2.3000690, 55.0657120],
          [-2.3000540, 55.0657760],
          [-2.3000520, 55.0658360],
          [-2.3000660, 55.0658900],
          [-2.3000960, 55.0659430],
          [-2.3001340, 55.0659920],
          [-2.3001840, 55.0660540],
          [-2.3002320, 55.0661150],
          [-2.3002840, 55.0661720],
          [-2.3003440, 55.0662340],
          [-2.3004060, 55.0662910],
          [-2.3004720, 55.0663550],
          [-2.3005410, 55.0664200],
          [-2.3006030, 55.0664920],
          [-2.3006580, 55.0665760],
          [-2.3006830, 55.0666850],
          [-2.3006670, 55.0668050],
          [-2.3006230, 55.0669110],
          [-2.3005650, 55.0670070],
          [-2.3005030, 55.0670940],
          [-2.3004470, 55.0671820],
          [-2.3003940, 55.0672630],
          [-2.3003470, 55.0673430],
          [-2.3003110, 55.0674230],
          [-2.3002860, 55.0675030],
          [-2.3002700, 55.0675830],
          [-2.3002590, 55.0676600],
          [-2.3002540, 55.0677280],
          [-2.3002550, 55.0678080],
          [-2.3002490, 55.0678800],
          [-2.3002320, 55.0679520],
          [-2.3002020, 55.0680260],
          [-2.3001590, 55.0680980],
          [-2.3000990, 55.0681640],
          [-2.3000310, 55.0682210],
          [-2.2999570, 55.0682780],
          [-2.2998770, 55.0683320],
          [-2.2998030, 55.0683840],
          [-2.2997390, 55.0684410],
          [-2.2996830, 55.0684990],
          [-2.2996480, 55.0685600],
          [-2.2996350, 55.0686280],
          [-2.2996380, 55.0686910],
          [-2.2996500, 55.0687540],
          [-2.2996650, 55.0688160],
          [-2.2996880, 55.0688770],
          [-2.2997140, 55.0689340],
          [-2.2997360, 55.0689880],
          [-2.2997560, 55.0690370],
          [-2.2997730, 55.0690900],
          [-2.2997930, 55.0691400],
          [-2.2998150, 55.0691860],
          [-2.2998390, 55.0692310],
          [-2.2998610, 55.0692760],
          [-2.2998830, 55.0693200],
          [-2.2999000, 55.0693650],
          [-2.2999080, 55.0694040],
          [-2.2999230, 55.0694460],
          [-2.2999400, 55.0694840],
          [-2.2999530, 55.0695260],
          [-2.2999660, 55.0695640],
          [-2.2999800, 55.0696020],
          [-2.2999930, 55.0696440],
          [-2.3000100, 55.0696860],
          [-2.3000260, 55.0697280],
          [-2.3000410, 55.0697740],
          [-2.3000580, 55.0698160],
          [-2.3000700, 55.0698570],
          [-2.3000800, 55.0699000],
          [-2.3000950, 55.0699490],
          [-2.3001140, 55.0699960],
          [-2.3001390, 55.0700520],
          [-2.3001660, 55.0701050],
          [-2.3002140, 55.0701510],
          [-2.3002850, 55.0701860],
          [-2.3003780, 55.0701980],
          [-2.3004900, 55.0702060],
          [-2.3006030, 55.0702090],
          [-2.3007170, 55.0702130],
          [-2.3008400, 55.0702170],
          [-2.3009540, 55.0702210],
          [-2.3010720, 55.0702250],
          [-2.3011900, 55.0702320],
          [-2.3013050, 55.0702360],
          [-2.3014200, 55.0702400],
          [-2.3015400, 55.0702510],
          [-2.3016660, 55.0702510],
          [-2.3017910, 55.0702550],
          [-2.3019210, 55.0702630],
          [-2.3020520, 55.0702670],
          [-2.3021860, 55.0702740],
          [-2.3023160, 55.0702780],
          [-2.3024430, 55.0702820],
          [-2.3025700, 55.0702890],
          [-2.3026980, 55.0702930],
          [-2.3028260, 55.0702970],
          [-2.3029460, 55.0703010],
          [-2.3030610, 55.0703080],
          [-2.3031820, 55.0703200],
          [-2.3032870, 55.0703310],
          [-2.3033800, 55.0703580],
          [-2.3034370, 55.0703960],
          [-2.3034790, 55.0704600],
          [-2.3035130, 55.0705360],
          [-2.3035410, 55.0706160],
          [-2.3035710, 55.0706890],
          [-2.3035980, 55.0707650],
          [-2.3036240, 55.0708430],
          [-2.3036510, 55.0709210],
          [-2.3036760, 55.0710010],
          [-2.3037030, 55.0710740],
          [-2.3037300, 55.0711470],
          [-2.3037580, 55.0712190],
          [-2.3037870, 55.0713020],
          [-2.3038190, 55.0713900],
          [-2.3038510, 55.0714780],
          [-2.3038830, 55.0715620],
          [-2.3039060, 55.0716460],
          [-2.3039340, 55.0717260],
          [-2.3039610, 55.0718030],
          [-2.3039870, 55.0718740],
          [-2.3040230, 55.0719480],
          [-2.3040680, 55.0720080],
          [-2.3041260, 55.0720620],
          [-2.3041890, 55.0721120],
          [-2.3042540, 55.0721570],
          [-2.3043250, 55.0722040],
          [-2.3043950, 55.0722460],
          [-2.3044620, 55.0722880],
          [-2.3045280, 55.0723300],
          [-2.3045910, 55.0723720],
          [-2.3046520, 55.0724100],
          [-2.3047150, 55.0724480],
          [-2.3047870, 55.0724790],
          [-2.3048650, 55.0724980],
          [-2.3049520, 55.0725130],
          [-2.3050460, 55.0725250],
          [-2.3051500, 55.0725290],
          [-2.3052570, 55.0725330],
          [-2.3053690, 55.0725330],
          [-2.3054850, 55.0725250],
          [-2.3056160, 55.0725250],
          [-2.3057450, 55.0725180],
          [-2.3058840, 55.0725100],
          [-2.3060310, 55.0725060],
          [-2.3061780, 55.0725020],
          [-2.3063320, 55.0724950],
          [-2.3064890, 55.0724870],
          [-2.3066460, 55.0724790],
          [-2.3068040, 55.0724720],
          [-2.3069590, 55.0724640],
          [-2.3071140, 55.0724570],
          [-2.3072680, 55.0724530],
          [-2.3074270, 55.0724490],
          [-2.3075860, 55.0724520],
          [-2.3077420, 55.0724530],
          [-2.3078900, 55.0724490],
          [-2.3080350, 55.0724410],
          [-2.3081820, 55.0724410],
          [-2.3083270, 55.0724450],
          [-2.3084640, 55.0724600],
          [-2.3085960, 55.0724860],
          [-2.3087130, 55.0725080],
          [-2.3088410, 55.0725470],
          [-2.3089610, 55.0725820],
          [-2.3090800, 55.0726190],
          [-2.3092010, 55.0726580],
          [-2.3093260, 55.0727000],
          [-2.3094480, 55.0727490],
          [-2.3095630, 55.0728060],
          [-2.3096600, 55.0728820],
          [-2.3097450, 55.0729590],
          [-2.3098350, 55.0730380],
          [-2.3099250, 55.0731180],
          [-2.3100310, 55.0732100],
          [-2.3101330, 55.0733000],
          [-2.3102220, 55.0733930],
          [-2.3103200, 55.0734880],
          [-2.3104130, 55.0735840],
          [-2.3105140, 55.0736760],
          [-2.3106290, 55.0737760],
          [-2.3107410, 55.0738770],
          [-2.3108420, 55.0739620],
          [-2.3109340, 55.0740500],
          [-2.3110180, 55.0741330],
          [-2.3111060, 55.0742130],
          [-2.3111800, 55.0742780],
          [-2.3112520, 55.0743510],
          [-2.3113240, 55.0744160],
          [-2.3113920, 55.0744810],
          [-2.3114510, 55.0745410],
          [-2.3115110, 55.0745990],
          [-2.3115690, 55.0746560],
          [-2.3116240, 55.0747100],
          [-2.3116840, 55.0747670],
          [-2.3117420, 55.0748170],
          [-2.3117960, 55.0748630],
          [-2.3118490, 55.0749120],
          [-2.3118970, 55.0749580],
          [-2.3119440, 55.0750020],
          [-2.3119920, 55.0750440],
          [-2.3120390, 55.0750880],
          [-2.3120840, 55.0751300],
          [-2.3121290, 55.0751710],
          [-2.3121770, 55.0752130],
          [-2.3122260, 55.0752540],
          [-2.3122720, 55.0752980],
          [-2.3123170, 55.0753390],
          [-2.3123580, 55.0753810],
          [-2.3123940, 55.0754230],
          [-2.3124330, 55.0754660],
          [-2.3124730, 55.0755080],
          [-2.3125140, 55.0755490],
          [-2.3125570, 55.0755940],
          [-2.3126010, 55.0756410],
          [-2.3126410, 55.0756860],
          [-2.3126780, 55.0757360],
          [-2.3127160, 55.0757820],
          [-2.3127540, 55.0758350],
          [-2.3127920, 55.0758810],
          [-2.3128290, 55.0759300],
          [-2.3128670, 55.0759760],
          [-2.3129100, 55.0760210],
          [-2.3129570, 55.0760680],
          [-2.3130110, 55.0761140],
          [-2.3130750, 55.0761550],
          [-2.3131490, 55.0761900],
          [-2.3132310, 55.0762240],
          [-2.3133230, 55.0762510],
          [-2.3134270, 55.0762740],
          [-2.3135350, 55.0762970],
          [-2.3136470, 55.0763050],
          [-2.3137570, 55.0763090],
          [-2.3138740, 55.0763050],
          [-2.3139880, 55.0762950],
          [-2.3141050, 55.0762790],
          [-2.3142160, 55.0762600],
          [-2.3143260, 55.0762370],
          [-2.3144360, 55.0762180],
          [-2.3145480, 55.0761950],
          [-2.3146580, 55.0761760],
          [-2.3147650, 55.0761570],
          [-2.3148700, 55.0761380],
          [-2.3149760, 55.0761190],
          [-2.3150780, 55.0761030],
          [-2.3151790, 55.0760880],
          [-2.3152830, 55.0760690],
          [-2.3153860, 55.0760540],
          [-2.3154880, 55.0760420],
          [-2.3155910, 55.0760270],
          [-2.3156910, 55.0760130],
          [-2.3157970, 55.0760000],
          [-2.3159000, 55.0759850],
          [-2.3160060, 55.0759740],
          [-2.3161160, 55.0759620],
          [-2.3162270, 55.0759510],
          [-2.3163340, 55.0759430],
          [-2.3164390, 55.0759320],
          [-2.3165480, 55.0759280],
          [-2.3166580, 55.0759200],
          [-2.3167630, 55.0759200],
          [-2.3168800, 55.0759160],
          [-2.3169960, 55.0759130],
          [-2.3171090, 55.0759120],
          [-2.3172290, 55.0759160],
          [-2.3173470, 55.0759200],
          [-2.3174650, 55.0759200],
          [-2.3175800, 55.0759310],
          [-2.3176920, 55.0759350],
          [-2.3178080, 55.0759470],
          [-2.3179240, 55.0759540],
          [-2.3180400, 55.0759580],
          [-2.3181630, 55.0759660],
          [-2.3182860, 55.0759730],
          [-2.3184140, 55.0759810],
          [-2.3185380, 55.0759850],
          [-2.3186630, 55.0759920],
          [-2.3187870, 55.0760000],
          [-2.3189110, 55.0760080],
          [-2.3190330, 55.0760150],
          [-2.3191500, 55.0760190],
          [-2.3192670, 55.0760230],
          [-2.3193860, 55.0760270],
          [-2.3195040, 55.0760310],
          [-2.3196210, 55.0760310],
          [-2.3197420, 55.0760340],
          [-2.3198580, 55.0760350],
          [-2.3199760, 55.0760380],
          [-2.3200980, 55.0760420],
          [-2.3202180, 55.0760460],
          [-2.3203440, 55.0760460],
          [-2.3204700, 55.0760420],
          [-2.3205930, 55.0760420],
          [-2.3207280, 55.0760420],
          [-2.3208620, 55.0760460],
          [-2.3209990, 55.0760420],
          [-2.3211420, 55.0760420],
          [-2.3212840, 55.0760420],
          [-2.3214410, 55.0760420],
          [-2.3215990, 55.0760460],
          [-2.3217570, 55.0760460],
          [-2.3219160, 55.0760420],
          [-2.3220740, 55.0760420],
          [-2.3222440, 55.0760460],
          [-2.3224050, 55.0760460],
          [-2.3225850, 55.0760500],
          [-2.3227750, 55.0760540],
          [-2.3229680, 55.0760570],
          [-2.3231520, 55.0760720],
          [-2.3233510, 55.0760910],
          [-2.3235370, 55.0761180],
          [-2.3237320, 55.0761480],
          [-2.3239250, 55.0761820],
          [-2.3241090, 55.0762170],
          [-2.3242880, 55.0762590],
          [-2.3244720, 55.0762930],
          [-2.3246470, 55.0763310],
          [-2.3248170, 55.0763660],
          [-2.3250020, 55.0763960],
          [-2.3251860, 55.0764300],
          [-2.3253600, 55.0764720],
          [-2.3255310, 55.0765180],
          [-2.3257080, 55.0765680],
          [-2.3258980, 55.0766250],
          [-2.3260740, 55.0766840],
          [-2.3262530, 55.0767510],
          [-2.3264180, 55.0768230],
          [-2.3265760, 55.0768810],
          [-2.3267260, 55.0769380],
          [-2.3268740, 55.0770020],
          [-2.3270270, 55.0770630],
          [-2.3271650, 55.0771320],
          [-2.3273090, 55.0771970],
          [-2.3274660, 55.0772580],
          [-2.3276120, 55.0773190],
          [-2.3277420, 55.0773800],
          [-2.3278490, 55.0774480],
          [-2.3279380, 55.0775320],
          [-2.3280100, 55.0776200],
          [-2.3280480, 55.0777080],
          [-2.3280760, 55.0777950],
          [-2.3281040, 55.0778760],
          [-2.3281210, 55.0779470],
          [-2.3281380, 55.0780160],
          [-2.3281480, 55.0780950],
          [-2.3281630, 55.0781850],
          [-2.3281850, 55.0782760],
          [-2.3282110, 55.0783600],
          [-2.3282310, 55.0784480],
          [-2.3282480, 55.0785320],
          [-2.3282600, 55.0786190],
          [-2.3282450, 55.0786960],
          [-2.3282010, 55.0787720],
          [-2.3281160, 55.0788330],
          [-2.3280170, 55.0788830],
          [-2.3279140, 55.0789290],
          [-2.3278120, 55.0789710],
          [-2.3277130, 55.0790130],
          [-2.3276190, 55.0790620],
          [-2.3275200, 55.0791080],
          [-2.3274250, 55.0791470],
          [-2.3273270, 55.0791890],
          [-2.3272290, 55.0792260],
          [-2.3271330, 55.0792610],
          [-2.3270440, 55.0792990],
          [-2.3269500, 55.0793290],
          [-2.3268540, 55.0793640],
          [-2.3267550, 55.0793950],
          [-2.3266620, 55.0794250],
          [-2.3265680, 55.0794550],
          [-2.3264710, 55.0794860],
          [-2.3263760, 55.0795200],
          [-2.3262790, 55.0795470],
          [-2.3261780, 55.0795740],
          [-2.3260770, 55.0795970],
          [-2.3259760, 55.0796160],
          [-2.3258730, 55.0796350],
          [-2.3257700, 55.0796510],
          [-2.3256720, 55.0796660],
          [-2.3255750, 55.0796700],
          [-2.3254810, 55.0796590],
          [-2.3253940, 55.0796440],
          [-2.3253230, 55.0796170],
          [-2.3252600, 55.0795870],
          [-2.3252050, 55.0795560],
          [-2.3251620, 55.0795230],
          [-2.3251310, 55.0794880],
          [-2.3251180, 55.0794610],
          [-2.3251170, 55.0794410],
          [-2.3251270, 55.0794220],
          [-2.3251360, 55.0794070],
          [-2.3251450, 55.0793880],
          [-2.3251530, 55.0793730],
          [-2.3251530, 55.0793730],
          [-2.3251530, 55.0793730],
          [-2.3251530, 55.0793730],
          [-2.3251540, 55.0793730],
          [-2.3251540, 55.0793730],
          [-2.3251540, 55.0793730],
          [-2.3251540, 55.0793730],
          [-2.3251540, 55.0793730],
          [-2.3251540, 55.0793730],
          [-2.3251540, 55.0793730],
          [-2.3251540, 55.0793730],
          [-2.3251540, 55.0793730],
          [-2.3251540, 55.0793730],
          [-2.3251550, 55.0793730],
          [-2.3251550, 55.0793730],
          [-2.3251550, 55.0793730],
          [-2.3251550, 55.0793730],
          [-2.3251550, 55.0793730],
          [-2.3251550, 55.0793730],
          [-2.3251550, 55.0793730],
          [-2.3251550, 55.0793740],
          [-2.3251550, 55.0793740],
          [-2.3251550, 55.0793740],
          [-2.3251560, 55.0793740],
          [-2.3251560, 55.0793740],
          [-2.3251560, 55.0793740],
          [-2.3251560, 55.0793740],
          [-2.3251560, 55.0793740],
          [-2.3251560, 55.0793740],
          [-2.3251560, 55.0793740],
          [-2.3251560, 55.0793740],
          [-2.3251560, 55.0793740],
          [-2.3251560, 55.0793740],
          [-2.3251570, 55.0793740],
          [-2.3251570, 55.0793740],
          [-2.3251570, 55.0793740],
          [-2.3251570, 55.0793740],
          [-2.3251570, 55.0793740],
          [-2.3251570, 55.0793740],
          [-2.3251570, 55.0793740],
          [-2.3251570, 55.0793740],
          [-2.3251570, 55.0793740],
          [-2.3251570, 55.0793740],
          [-2.3251580, 55.0793740],
          [-2.3251580, 55.0793740],
          [-2.3251580, 55.0793740],
          [-2.3251580, 55.0793740],
          [-2.3251580, 55.0793740],
          [-2.3251580, 55.0793740],
          [-2.3251580, 55.0793740],
          [-2.3251580, 55.0793740],
          [-2.3251580, 55.0793740],
          [-2.3251580, 55.0793750],
          [-2.3251590, 55.0793750],
          [-2.3251590, 55.0793750],
          [-2.3251590, 55.0793750],
          [-2.3251590, 55.0793750],
          [-2.3251590, 55.0793750],
          [-2.3251590, 55.0793750],
          [-2.3251590, 55.0793750],
          [-2.3251590, 55.0793750],
          [-2.3251590, 55.0793750],
          [-2.3251590, 55.0793750],
          [-2.3251600, 55.0793750],
          [-2.3251600, 55.0793750],
          [-2.3251600, 55.0793750],
          [-2.3251600, 55.0793750],
          [-2.3251600, 55.0793750],
          [-2.3251600, 55.0793750],
          [-2.3251600, 55.0793750],
          [-2.3251600, 55.0793750],
          [-2.3251600, 55.0793750],
          [-2.3251600, 55.0793750],
          [-2.3251610, 55.0793750],
          [-2.3251610, 55.0793750],
          [-2.3251610, 55.0793750],
          [-2.3251610, 55.0793750],
          [-2.3251610, 55.0793750],
          [-2.3251610, 55.0793750],
          [-2.3251610, 55.0793750],
          [-2.3251610, 55.0793750],
          [-2.3251610, 55.0793750],
          [-2.3251610, 55.0793750],
          [-2.3251620, 55.0793750],
          [-2.3251620, 55.0793760],
          [-2.3251620, 55.0793760],
          [-2.3251620, 55.0793760],
          [-2.3251620, 55.0793760],
          [-2.3251620, 55.0793760],
          [-2.3251620, 55.0793760],
          [-2.3251620, 55.0793760],
          [-2.3251620, 55.0793760],
          [-2.3251620, 55.0793760],
          [-2.3251630, 55.0793760],
          [-2.3251630, 55.0793760],
          [-2.3251630, 55.0793760],
          [-2.3251630, 55.0793760],
          [-2.3251630, 55.0793760],
          [-2.3251630, 55.0793760],
          [-2.3251630, 55.0793760],
          [-2.3251630, 55.0793760],
          [-2.3251630, 55.0793760],
          [-2.3251630, 55.0793760],
          [-2.3251640, 55.0793760],
          [-2.3251640, 55.0793760],
          [-2.3251640, 55.0793760],
          [-2.3251640, 55.0793760],
          [-2.3251640, 55.0793760],
          [-2.3251640, 55.0793760],
          [-2.3251640, 55.0793760],
          [-2.3251640, 55.0793760],
          [-2.3251640, 55.0793760],
          [-2.3251640, 55.0793760],
          [-2.3251650, 55.0793760],
          [-2.3251650, 55.0793760],
          [-2.3251650, 55.0793760],
          [-2.3251650, 55.0793770],
          [-2.3251650, 55.0793770],
          [-2.3251650, 55.0793770],
          [-2.3251650, 55.0793770],
          [-2.3251650, 55.0793770],
          [-2.3251650, 55.0793770],
          [-2.3251650, 55.0793770],
          [-2.3251660, 55.0793770],
          [-2.3251660, 55.0793770],
          [-2.3251660, 55.0793770],
          [-2.3251660, 55.0793770],
          [-2.3251660, 55.0793770],
          [-2.3251660, 55.0793770],
          [-2.3251660, 55.0793770],
          [-2.3251660, 55.0793770],
          [-2.3251660, 55.0793770],
          [-2.3251660, 55.0793770],
          [-2.3251670, 55.0793770],
          [-2.3251670, 55.0793770],
          [-2.3251670, 55.0793770],
          [-2.3251670, 55.0793770],
          [-2.3251670, 55.0793770],
          [-2.3251670, 55.0793770],
          [-2.3251670, 55.0793770],
          [-2.3251670, 55.0793770],
          [-2.3251670, 55.0793770],
          [-2.3251670, 55.0793770],
          [-2.3251680, 55.0793770],
          [-2.3251680, 55.0793770],
          [-2.3251680, 55.0793770],
          [-2.3251680, 55.0793770],
          [-2.3251680, 55.0793770],
          [-2.3251680, 55.0793780],
          [-2.3251680, 55.0793780],
          [-2.3251680, 55.0793780],
          [-2.3251680, 55.0793780],
          [-2.3251680, 55.0793780],
          [-2.3251690, 55.0793780],
          [-2.3251690, 55.0793780],
          [-2.3251690, 55.0793780],
          [-2.3251690, 55.0793780],
          [-2.3251690, 55.0793780],
          [-2.3251690, 55.0793780],
          [-2.3251690, 55.0793780],
          [-2.3251690, 55.0793780],
          [-2.3251690, 55.0793780],
          [-2.3251690, 55.0793780],
          [-2.3251700, 55.0793780],
          [-2.3251700, 55.0793780],
          [-2.3251700, 55.0793780],
          [-2.3251700, 55.0793780],
          [-2.3251700, 55.0793780],
          [-2.3251700, 55.0793780],
          [-2.3251700, 55.0793780],
          [-2.3251700, 55.0793780],
          [-2.3251700, 55.0793780],
          [-2.3251700, 55.0793780],
          [-2.3251710, 55.0793780],
          [-2.3251710, 55.0793780],
          [-2.3251710, 55.0793780],
          [-2.3251710, 55.0793780],
          [-2.3251710, 55.0793780],
          [-2.3251710, 55.0793780],
          [-2.3251710, 55.0793780],
          [-2.3251710, 55.0793790],
          [-2.3251710, 55.0793790],
          [-2.3251710, 55.0793790],
          [-2.3251720, 55.0793790],
          [-2.3251720, 55.0793790],
          [-2.3251720, 55.0793790],
          [-2.3251720, 55.0793790],
          [-2.3251720, 55.0793790],
          [-2.3251720, 55.0793790],
          [-2.3251720, 55.0793790],
          [-2.3251720, 55.0793790],
          [-2.3251720, 55.0793790],
          [-2.3251720, 55.0793790],
          [-2.3251730, 55.0793790],
          [-2.3251730, 55.0793790],
          [-2.3251730, 55.0793790],
          [-2.3251730, 55.0793790],
          [-2.3251730, 55.0793790],
          [-2.3251730, 55.0793790],
          [-2.3251730, 55.0793790],
          [-2.3251730, 55.0793790],
          [-2.3251730, 55.0793790],
          [-2.3251730, 55.0793790],
          [-2.3251740, 55.0793790],
          [-2.3251740, 55.0793790],
          [-2.3251740, 55.0793790],
          [-2.3251740, 55.0793790],
          [-2.3251740, 55.0793790],
          [-2.3251740, 55.0793790],
          [-2.3251740, 55.0793790],
          [-2.3251740, 55.0793790],
          [-2.3251740, 55.0793790],
          [-2.3251740, 55.0793800],
          [-2.3251750, 55.0793800],
          [-2.3251750, 55.0793800],
          [-2.3251750, 55.0793800],
          [-2.3251750, 55.0793800],
          [-2.3251750, 55.0793800],
          [-2.3251750, 55.0793800],
          [-2.3251750, 55.0793800],
          [-2.3251750, 55.0793800],
          [-2.3251750, 55.0793800],
          [-2.3251750, 55.0793800],
          [-2.3251760, 55.0793800],
          [-2.3251760, 55.0793800],
          [-2.3251760, 55.0793800],
          [-2.3251760, 55.0793800],
          [-2.3251760, 55.0793800],
          [-2.3251760, 55.0793800],
          [-2.3251760, 55.0793800],
          [-2.3251760, 55.0793800],
          [-2.3251760, 55.0793800],
          [-2.3251760, 55.0793800],
          [-2.3251770, 55.0793800],
          [-2.3251770, 55.0793800],
          [-2.3251770, 55.0793800],
          [-2.3251770, 55.0793800],
          [-2.3251770, 55.0793800],
          [-2.3251770, 55.0793800],
          [-2.3251770, 55.0793800],
          [-2.3251770, 55.0793800],
          [-2.3251770, 55.0793800],
          [-2.3251770, 55.0793800],
          [-2.3251780, 55.0793800],
          [-2.3251780, 55.0793810],
          [-2.3251780, 55.0793810],
          [-2.3251780, 55.0793810],
          [-2.3251780, 55.0793810],
          [-2.3251780, 55.0793810],
          [-2.3251780, 55.0793810],
          [-2.3251780, 55.0793810],
          [-2.3251780, 55.0793810],
          [-2.3251780, 55.0793810],
          [-2.3251790, 55.0793810],
          [-2.3251790, 55.0793810],
          [-2.3251790, 55.0793810],
          [-2.3251790, 55.0793810],
          [-2.3251790, 55.0793810],
          [-2.3251790, 55.0793810],
          [-2.3251790, 55.0793810],
          [-2.3251790, 55.0793810],
          [-2.3251790, 55.0793810],
          [-2.3251790, 55.0793810],
          [-2.3251800, 55.0793810],
          [-2.3251800, 55.0793810],
          [-2.3251800, 55.0793810],
          [-2.3251800, 55.0793810],
          [-2.3251800, 55.0793810],
          [-2.3251800, 55.0793810],
          [-2.3251800, 55.0793810],
          [-2.3251800, 55.0793810],
          [-2.3251800, 55.0793810],
          [-2.3251800, 55.0793810],
          [-2.3251800, 55.0793810],
          [-2.3251810, 55.0793810],
          [-2.3251810, 55.0793810],
          [-2.3251810, 55.0793820],
          [-2.3251810, 55.0793820],
          [-2.3251810, 55.0793820],
          [-2.3251810, 55.0793820],
          [-2.3251810, 55.0793820],
          [-2.3251810, 55.0793820],
          [-2.3251810, 55.0793820],
          [-2.3251810, 55.0793820],
          [-2.3251820, 55.0793820],
          [-2.3251820, 55.0793820],
          [-2.3251820, 55.0793820],
          [-2.3251820, 55.0793820],
          [-2.3251820, 55.0793820],
          [-2.3251820, 55.0793820],
          [-2.3251820, 55.0793820],
          [-2.3251820, 55.0793820],
          [-2.3251820, 55.0793820],
          [-2.3251820, 55.0793820],
          [-2.3251830, 55.0793820],
          [-2.3251830, 55.0793820],
          [-2.3251830, 55.0793820],
          [-2.3251830, 55.0793820],
          [-2.3251830, 55.0793820],
          [-2.3251830, 55.0793820],
          [-2.3251830, 55.0793820],
          [-2.3251830, 55.0793820],
          [-2.3251830, 55.0793820],
          [-2.3251830, 55.0793820],
          [-2.3251840, 55.0793820],
          [-2.3251840, 55.0793820],
          [-2.3251840, 55.0793820],
          [-2.3251840, 55.0793820],
          [-2.3251840, 55.0793830],
          [-2.3251840, 55.0793830],
          [-2.3251840, 55.0793830],
          [-2.3251840, 55.0793830],
          [-2.3251840, 55.0793830],
          [-2.3251840, 55.0793830],
          [-2.3251850, 55.0793830],
          [-2.3251850, 55.0793830],
          [-2.3251850, 55.0793830],
          [-2.3251850, 55.0793830],
          [-2.3251850, 55.0793830],
          [-2.3251850, 55.0793830],
          [-2.3251850, 55.0793830],
          [-2.3251850, 55.0793830],
          [-2.3251850, 55.0793830],
          [-2.3251850, 55.0793830],
          [-2.3251860, 55.0793830],
          [-2.3251860, 55.0793830],
          [-2.3251860, 55.0793830],
          [-2.3251860, 55.0793830],
          [-2.3251860, 55.0793830],
          [-2.3251860, 55.0793830],
          [-2.3251860, 55.0793830],
          [-2.3251860, 55.0793830],
          [-2.3251860, 55.0793830],
          [-2.3251860, 55.0793830],
          [-2.3251870, 55.0793830],
          [-2.3251870, 55.0793830],
          [-2.3251870, 55.0793830],
          [-2.3251870, 55.0793830],
          [-2.3251870, 55.0793830],
          [-2.3251870, 55.0793830],
          [-2.3251870, 55.0793840],
          [-2.3251870, 55.0793840],
          [-2.3251870, 55.0793840],
          [-2.3251870, 55.0793840],
          [-2.3251880, 55.0793840],
          [-2.3251880, 55.0793840],
          [-2.3251880, 55.0793840],
          [-2.3251880, 55.0793840],
          [-2.3251880, 55.0793840],
          [-2.3251880, 55.0793840],
          [-2.3251880, 55.0793840],
          [-2.3251880, 55.0793840],
          [-2.3251880, 55.0793840],
          [-2.3251880, 55.0793840],
          [-2.3251890, 55.0793840],
          [-2.3251890, 55.0793840],
          [-2.3251890, 55.0793840],
          [-2.3251890, 55.0793840],
          [-2.3251890, 55.0793840],
          [-2.3251890, 55.0793840],
          [-2.3251890, 55.0793840],
          [-2.3251890, 55.0793840],
          [-2.3251890, 55.0793840],
          [-2.3251890, 55.0793840],
          [-2.3251900, 55.0793840],
          [-2.3251900, 55.0793840],
          [-2.3251900, 55.0793840],
          [-2.3251900, 55.0793840],
          [-2.3251900, 55.0793840],
          [-2.3251900, 55.0793840],
          [-2.3251900, 55.0793840],
          [-2.3251900, 55.0793840],
          [-2.3251900, 55.0793850],
          [-2.3251900, 55.0793850],
          [-2.3251910, 55.0793850],
          [-2.3251910, 55.0793850],
          [-2.3251910, 55.0793850],
          [-2.3251910, 55.0793850],
          [-2.3251910, 55.0793850],
          [-2.3251910, 55.0793850],
          [-2.3251910, 55.0793850],
          [-2.3251910, 55.0793850],
          [-2.3251910, 55.0793850],
          [-2.3251910, 55.0793850],
          [-2.3251920, 55.0793850],
          [-2.3251920, 55.0793850],
          [-2.3251920, 55.0793850],
          [-2.3251920, 55.0793850],
          [-2.3251920, 55.0793850],
          [-2.3251920, 55.0793850],
          [-2.3251920, 55.0793850],
          [-2.3251920, 55.0793850],
          [-2.3251920, 55.0793850],
          [-2.3251920, 55.0793850],
          [-2.3251930, 55.0793850],
          [-2.3251930, 55.0793850],
          [-2.3251930, 55.0793850],
          [-2.3251930, 55.0793850],
          [-2.3251930, 55.0793850],
          [-2.3251930, 55.0793850],
          [-2.3251930, 55.0793850],
          [-2.3251930, 55.0793850],
          [-2.3251930, 55.0793850],
          [-2.3251930, 55.0793850],
          [-2.3251940, 55.0793860],
          [-2.3251940, 55.0793860],
          [-2.3251940, 55.0793860],
          [-2.3251940, 55.0793860],
          [-2.3251940, 55.0793860],
          [-2.3251940, 55.0793860],
          [-2.3251940, 55.0793860],
          [-2.3251940, 55.0793860],
          [-2.3251940, 55.0793860],
          [-2.3251940, 55.0793860],
          [-2.3251950, 55.0793860],
          [-2.3251950, 55.0793860],
          [-2.3251950, 55.0793860],
          [-2.3251950, 55.0793860],
          [-2.3251950, 55.0793860],
          [-2.3251950, 55.0793860],
          [-2.3251950, 55.0793860],
          [-2.3251950, 55.0793860],
          [-2.3251950, 55.0793860],
          [-2.3251920, 55.0793950],
          [-2.3251880, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251850, 55.0793990],
          [-2.3251620, 55.0794440],
          [-2.3251620, 55.0794560],
          [-2.3251670, 55.0794670],
          [-2.3251740, 55.0794780],
          [-2.3251880, 55.0794870],
          [-2.3252070, 55.0794980],
          [-2.3252320, 55.0795130],
          [-2.3252600, 55.0795280],
          [-2.3252910, 55.0795400],
          [-2.3253210, 55.0795590],
          [-2.3253560, 55.0795740],
          [-2.3253960, 55.0795890],
          [-2.3254420, 55.0795970],
          [-2.3255010, 55.0796050],
          [-2.3255700, 55.0796050],
          [-2.3256500, 55.0796050],
          [-2.3257260, 55.0795980],
          [-2.3258000, 55.0795860],
          [-2.3258750, 55.0795710],
          [-2.3259510, 55.0795560],
          [-2.3260220, 55.0795450],
          [-2.3260960, 55.0795330],
          [-2.3261710, 55.0795180],
          [-2.3262480, 55.0795030],
          [-2.3263230, 55.0794870],
          [-2.3264000, 55.0794680],
          [-2.3264790, 55.0794460],
          [-2.3265610, 55.0794260],
          [-2.3266370, 55.0794070],
          [-2.3267150, 55.0793840],
          [-2.3267920, 55.0793650],
          [-2.3268670, 55.0793390],
          [-2.3269360, 55.0793120],
          [-2.3270060, 55.0792890],
          [-2.3270780, 55.0792620],
          [-2.3271500, 55.0792360],
          [-2.3272200, 55.0792090],
          [-2.3272920, 55.0791860],
          [-2.3273720, 55.0791670],
          [-2.3274500, 55.0791520],
          [-2.3275280, 55.0791440],
          [-2.3276070, 55.0791400],
          [-2.3276920, 55.0791430],
          [-2.3277800, 55.0791510],
          [-2.3278690, 55.0791590],
          [-2.3279580, 55.0791740],
          [-2.3280480, 55.0791930],
          [-2.3281390, 55.0792120],
          [-2.3282330, 55.0792350],
          [-2.3283260, 55.0792650],
          [-2.3284160, 55.0792990],
          [-2.3285050, 55.0793410],
          [-2.3285960, 55.0793790],
          [-2.3286910, 55.0794140],
          [-2.3287910, 55.0794520],
          [-2.3288980, 55.0794900],
          [-2.3290130, 55.0795240],
          [-2.3291360, 55.0795580],
          [-2.3292720, 55.0795900],
          [-2.3294110, 55.0796150],
          [-2.3295690, 55.0796350],
          [-2.3297300, 55.0796510],
          [-2.3299060, 55.0796660],
          [-2.3300850, 55.0796770],
          [-2.3302740, 55.0796740],
          [-2.3304490, 55.0796700],
          [-2.3306230, 55.0796780],
          [-2.3307870, 55.0796850],
          [-2.3309530, 55.0796850],
          [-2.3311070, 55.0796930],
          [-2.3312560, 55.0797000],
          [-2.3313970, 55.0797120],
          [-2.3315330, 55.0797190],
          [-2.3316630, 55.0797270],
          [-2.3317860, 55.0797420],
          [-2.3318970, 55.0797540],
          [-2.3319910, 55.0797610],
          [-2.3320850, 55.0797730],
          [-2.3321660, 55.0797840],
          [-2.3322420, 55.0797920],
          [-2.3323170, 55.0798030],
          [-2.3323880, 55.0798220],
          [-2.3324590, 55.0798410],
          [-2.3325180, 55.0798490],
          [-2.3325790, 55.0798570],
          [-2.3326420, 55.0798640],
          [-2.3327020, 55.0798720],
          [-2.3327550, 55.0798790],
          [-2.3328110, 55.0798870],
          [-2.3328610, 55.0798990],
          [-2.3329060, 55.0799100],
          [-2.3329490, 55.0799250],
          [-2.3329920, 55.0799370],
          [-2.3330340, 55.0799480],
          [-2.3330690, 55.0799600],
          [-2.3331040, 55.0799670],
          [-2.3331440, 55.0799790],
          [-2.3331810, 55.0799860],
          [-2.3332190, 55.0799980],
          [-2.3332540, 55.0800020],
          [-2.3332860, 55.0800060],
          [-2.3333130, 55.0800090],
          [-2.3333360, 55.0800170],
          [-2.3333560, 55.0800250],
          [-2.3333810, 55.0800320],
          [-2.3334140, 55.0800430],
          [-2.3334480, 55.0800550],
          [-2.3334810, 55.0800700],
          [-2.3335160, 55.0800850],
          [-2.3335540, 55.0801040],
          [-2.3335910, 55.0801270],
          [-2.3336260, 55.0801500],
          [-2.3336590, 55.0801730],
          [-2.3336920, 55.0801920],
          [-2.3337240, 55.0802170],
          [-2.3337580, 55.0802450],
          [-2.3337840, 55.0802680],
          [-2.3338100, 55.0802910],
          [-2.3338310, 55.0803070],
          [-2.3338540, 55.0803260],
          [-2.3338790, 55.0803480],
          [-2.3339010, 55.0803710],
          [-2.3339210, 55.0803940],
          [-2.3339430, 55.0804170],
          [-2.3339650, 55.0804400],
          [-2.3339880, 55.0804590],
          [-2.3340050, 55.0804860],
          [-2.3340230, 55.0805090],
          [-2.3340400, 55.0805320],
          [-2.3340610, 55.0805510],
          [-2.3340760, 55.0805740],
          [-2.3340900, 55.0805960],
          [-2.3341000, 55.0806190],
          [-2.3341160, 55.0806380],
          [-2.3341330, 55.0806610],
          [-2.3341480, 55.0806840],
          [-2.3341600, 55.0807030],
          [-2.3341760, 55.0807250],
          [-2.3341930, 55.0807530],
          [-2.3342110, 55.0807720],
          [-2.3342300, 55.0807950],
          [-2.3342480, 55.0808180],
          [-2.3342640, 55.0808440],
          [-2.3342810, 55.0808710],
          [-2.3342930, 55.0808940],
          [-2.3343080, 55.0809240],
          [-2.3343210, 55.0809510],
          [-2.3343330, 55.0809770],
          [-2.3343450, 55.0810020],
          [-2.3343600, 55.0810310],
          [-2.3343730, 55.0810570],
          [-2.3343880, 55.0810840],
          [-2.3344010, 55.0811080],
          [-2.3344150, 55.0811410],
          [-2.3344280, 55.0811720],
          [-2.3344440, 55.0812020],
          [-2.3344600, 55.0812290],
          [-2.3344790, 55.0812630],
          [-2.3344910, 55.0812940],
          [-2.3345030, 55.0813250],
          [-2.3345200, 55.0813590],
          [-2.3345330, 55.0813890],
          [-2.3345430, 55.0814190],
          [-2.3345590, 55.0814540],
          [-2.3345760, 55.0814880],
          [-2.3345900, 55.0815200],
          [-2.3346080, 55.0815570],
          [-2.3346220, 55.0815870],
          [-2.3346330, 55.0816200],
          [-2.3346460, 55.0816600],
          [-2.3346640, 55.0816930],
          [-2.3346810, 55.0817250],
          [-2.3346960, 55.0817660],
          [-2.3347110, 55.0818010],
          [-2.3347250, 55.0818310],
          [-2.3347440, 55.0818730],
          [-2.3347580, 55.0819110],
          [-2.3347710, 55.0819450],
          [-2.3347880, 55.0819850],
          [-2.3348060, 55.0820220],
          [-2.3348210, 55.0820570],
          [-2.3348390, 55.0820980],
          [-2.3348520, 55.0821390],
          [-2.3348660, 55.0821760],
          [-2.3348810, 55.0822240],
          [-2.3348960, 55.0822690],
          [-2.3349080, 55.0823080],
          [-2.3349210, 55.0823560],
          [-2.3349350, 55.0823990],
          [-2.3349460, 55.0824490],
          [-2.3349520, 55.0824920],
          [-2.3349600, 55.0825470],
          [-2.3349660, 55.0825920],
          [-2.3349730, 55.0826500],
          [-2.3349760, 55.0827000],
          [-2.3349830, 55.0827570],
          [-2.3349920, 55.0828100],
          [-2.3350010, 55.0828710],
          [-2.3350070, 55.0829230],
          [-2.3350130, 55.0829820],
          [-2.3350210, 55.0830420],
          [-2.3350330, 55.0831110],
          [-2.3350430, 55.0831710],
          [-2.3350590, 55.0832450],
          [-2.3350730, 55.0833200],
          [-2.3350920, 55.0834040],
          [-2.3351140, 55.0834810],
          [-2.3351470, 55.0835790],
          [-2.3351820, 55.0836670],
          [-2.3352140, 55.0837500],
          [-2.3352360, 55.0838460],
          [-2.3352630, 55.0839270],
          [-2.3352900, 55.0840110],
          [-2.3353160, 55.0840650],
          [-2.3353410, 55.0841160],
          [-2.3353600, 55.0841570],
          [-2.3353780, 55.0842030],
          [-2.3353910, 55.0842440],
          [-2.3354090, 55.0842990],
          [-2.3354280, 55.0843460],
          [-2.3354520, 55.0844090],
          [-2.3354780, 55.0844750],
          [-2.3355060, 55.0845360],
          [-2.3355360, 55.0846010],
          [-2.3355660, 55.0846660],
          [-2.3355960, 55.0847270],
          [-2.3356260, 55.0847920],
          [-2.3356520, 55.0848450],
          [-2.3356780, 55.0849220],
          [-2.3357040, 55.0850020],
          [-2.3357330, 55.0850760],
          [-2.3357710, 55.0851250],
          [-2.3357910, 55.0851880],
          [-2.3358310, 55.0852350],
          [-2.3358300, 55.0852010],
          [-2.3358780, 55.0852840],
          [-2.3359050, 55.0853340],
          [-2.3359330, 55.0853830],
          [-2.3359530, 55.0854330],
          [-2.3359780, 55.0854830],
          [-2.3360030, 55.0855290],
          [-2.3360250, 55.0855740],
          [-2.3360480, 55.0856200],
          [-2.3360710, 55.0856690],
          [-2.3360910, 55.0857190],
          [-2.3361160, 55.0857690],
          [-2.3361350, 55.0858180],
          [-2.3361530, 55.0858680],
          [-2.3361750, 55.0859210],
          [-2.3361990, 55.0859700],
          [-2.3362230, 55.0860170],
          [-2.3362420, 55.0860630],
          [-2.3362600, 55.0861050],
          [-2.3362800, 55.0861430],
          [-2.3362960, 55.0861810],
          [-2.3363080, 55.0862110],
          [-2.3363210, 55.0862420],
          [-2.3363350, 55.0862730],
          [-2.3363460, 55.0862990],
          [-2.3363560, 55.0863260],
          [-2.3363650, 55.0863530],
          [-2.3363750, 55.0863790],
          [-2.3363830, 55.0864060],
          [-2.3363950, 55.0864290],
          [-2.3364020, 55.0864520],
          [-2.3364100, 55.0864750],
          [-2.3364150, 55.0864980],
          [-2.3364180, 55.0865240],
          [-2.3364220, 55.0865470],
          [-2.3364220, 55.0865740],
          [-2.3364180, 55.0866050],
          [-2.3364090, 55.0866310],
          [-2.3363970, 55.0866580],
          [-2.3363870, 55.0866850],
          [-2.3363800, 55.0867120],
          [-2.3363750, 55.0867420],
          [-2.3363750, 55.0867690],
          [-2.3363820, 55.0868010],
          [-2.3363880, 55.0868340],
          [-2.3363980, 55.0868680],
          [-2.3364100, 55.0869060],
          [-2.3364260, 55.0869400],
          [-2.3364480, 55.0869780],
          [-2.3364760, 55.0870120],
          [-2.3365110, 55.0870510],
          [-2.3365460, 55.0870890],
          [-2.3365830, 55.0871310],
          [-2.3366210, 55.0871730],
          [-2.3366590, 55.0872180],
          [-2.3366990, 55.0872640],
          [-2.3367430, 55.0873100],
          [-2.3367860, 55.0873560],
          [-2.3368310, 55.0874050],
          [-2.3368720, 55.0874550],
          [-2.3369180, 55.0875080],
          [-2.3369630, 55.0875600],
          [-2.3370120, 55.0876150],
          [-2.3370660, 55.0876650],
          [-2.3371230, 55.0877140],
          [-2.3371820, 55.0877530],
          [-2.3372480, 55.0877950],
          [-2.3373140, 55.0878320],
          [-2.3373840, 55.0878630],
          [-2.3374540, 55.0878930],
          [-2.3375290, 55.0879250],
          [-2.3376040, 55.0879510],
          [-2.3376830, 55.0879760],
          [-2.3377620, 55.0880010],
          [-2.3378400, 55.0880310],
          [-2.3379180, 55.0880580],
          [-2.3379970, 55.0880850],
          [-2.3380730, 55.0881110],
          [-2.3381480, 55.0881380],
          [-2.3382230, 55.0881680],
          [-2.3382990, 55.0881950],
          [-2.3383760, 55.0882220],
          [-2.3384520, 55.0882490],
          [-2.3385300, 55.0882750],
          [-2.3386050, 55.0883060],
          [-2.3386780, 55.0883320],
          [-2.3387570, 55.0883670],
          [-2.3388320, 55.0883970],
          [-2.3389090, 55.0884280],
          [-2.3389850, 55.0884550],
          [-2.3390670, 55.0884810],
          [-2.3391500, 55.0885080],
          [-2.3392390, 55.0885350],
          [-2.3393250, 55.0885650],
          [-2.3394100, 55.0885960],
          [-2.3394970, 55.0886230],
          [-2.3395880, 55.0886420],
          [-2.3396840, 55.0886610],
          [-2.3397780, 55.0886760],
          [-2.3398780, 55.0886910],
          [-2.3399780, 55.0887030],
          [-2.3400690, 55.0887140],
          [-2.3401550, 55.0887370],
          [-2.3402290, 55.0887710],
          [-2.3402860, 55.0888130],
          [-2.3403220, 55.0888620],
          [-2.3403500, 55.0889160],
          [-2.3403760, 55.0889690],
          [-2.3404010, 55.0890230],
          [-2.3404250, 55.0890760],
          [-2.3404550, 55.0891370],
          [-2.3404910, 55.0891940],
          [-2.3405390, 55.0892550],
          [-2.3405960, 55.0893040],
          [-2.3406660, 55.0893510],
          [-2.3407390, 55.0893930],
          [-2.3408180, 55.0894270],
          [-2.3408990, 55.0894610],
          [-2.3409840, 55.0894920],
          [-2.3410650, 55.0895220],
          [-2.3411490, 55.0895500],
          [-2.3412290, 55.0895800],
          [-2.3413070, 55.0896100],
          [-2.3413830, 55.0896410],
          [-2.3414570, 55.0896720],
          [-2.3415330, 55.0897020],
          [-2.3416070, 55.0897330],
          [-2.3416790, 55.0897630],
          [-2.3417540, 55.0898010],
          [-2.3418230, 55.0898310],
          [-2.3418960, 55.0898660],
          [-2.3419670, 55.0898970],
          [-2.3420340, 55.0899310],
          [-2.3420970, 55.0899650],
          [-2.3421560, 55.0900000],
          [-2.3422070, 55.0900300],
          [-2.3422560, 55.0900650],
          [-2.3423010, 55.0900950],
          [-2.3423410, 55.0901250],
          [-2.3423780, 55.0901560],
          [-2.3424110, 55.0901900],
          [-2.3424460, 55.0902210],
          [-2.3424810, 55.0902510],
          [-2.3425180, 55.0902820],
          [-2.3425530, 55.0903120],
          [-2.3425890, 55.0903430],
          [-2.3426280, 55.0903740],
          [-2.3426670, 55.0904000],
          [-2.3427090, 55.0904270],
          [-2.3427490, 55.0904500],
          [-2.3427870, 55.0904780],
          [-2.3428290, 55.0905110],
          [-2.3428710, 55.0905380],
          [-2.3429140, 55.0905680],
          [-2.3429580, 55.0905950],
          [-2.3430020, 55.0906250],
          [-2.3430480, 55.0906550],
          [-2.3430970, 55.0906860],
          [-2.3431420, 55.0907130],
          [-2.3431870, 55.0907400],
          [-2.3432360, 55.0907660],
          [-2.3432820, 55.0907930],
          [-2.3433300, 55.0908230],
          [-2.3433800, 55.0908500],
          [-2.3434320, 55.0908770],
          [-2.3434850, 55.0909040],
          [-2.3435400, 55.0909270],
          [-2.3435960, 55.0909530],
          [-2.3436500, 55.0909800],
          [-2.3437070, 55.0910030],
          [-2.3437630, 55.0910260],
          [-2.3438210, 55.0910520],
          [-2.3438820, 55.0910750],
          [-2.3439400, 55.0911020],
          [-2.3440020, 55.0911250],
          [-2.3440620, 55.0911480],
          [-2.3441220, 55.0911710],
          [-2.3441830, 55.0911930],
          [-2.3442450, 55.0912160],
          [-2.3443100, 55.0912390],
          [-2.3443770, 55.0912620],
          [-2.3444420, 55.0912820],
          [-2.3445090, 55.0913010],
          [-2.3445750, 55.0913190],
          [-2.3446460, 55.0913430],
          [-2.3447150, 55.0913580],
          [-2.3447800, 55.0913760],
          [-2.3448500, 55.0913960],
          [-2.3449220, 55.0914110],
          [-2.3449940, 55.0914300],
          [-2.3450660, 55.0914490],
          [-2.3451350, 55.0914650],
          [-2.3452050, 55.0914820],
          [-2.3452750, 55.0914990],
          [-2.3453440, 55.0915140],
          [-2.3454140, 55.0915260],
          [-2.3454900, 55.0915410],
          [-2.3455640, 55.0915530],
          [-2.3456340, 55.0915680],
          [-2.3457080, 55.0915790],
          [-2.3457820, 55.0915940],
          [-2.3458550, 55.0916060],
          [-2.3459260, 55.0916170],
          [-2.3460020, 55.0916250],
          [-2.3460800, 55.0916330],
          [-2.3461550, 55.0916370],
          [-2.3462340, 55.0916440],
          [-2.3463140, 55.0916560],
          [-2.3463930, 55.0916630],
          [-2.3464700, 55.0916780],
          [-2.3465450, 55.0916900],
          [-2.3466200, 55.0916980],
          [-2.3466920, 55.0917090],
          [-2.3467640, 55.0917130],
          [-2.3468280, 55.0917170],
          [-2.3468900, 55.0917170],
          [-2.3469580, 55.0917240],
          [-2.3470280, 55.0917320],
          [-2.3471000, 55.0917390],
          [-2.3471770, 55.0917430],
          [-2.3472560, 55.0917510],
          [-2.3473340, 55.0917550],
          [-2.3474170, 55.0917550],
          [-2.3474980, 55.0917590],
          [-2.3475770, 55.0917590],
          [-2.3476600, 55.0917620],
          [-2.3477480, 55.0917740],
          [-2.3478380, 55.0917850],
          [-2.3479370, 55.0917970],
          [-2.3480370, 55.0918080],
          [-2.3481320, 55.0918190],
          [-2.3482360, 55.0918350],
          [-2.3483450, 55.0918610],
          [-2.3484510, 55.0918840],
          [-2.3485510, 55.0919180],
          [-2.3486430, 55.0919560],
          [-2.3487290, 55.0919940],
          [-2.3488100, 55.0920250],
          [-2.3488870, 55.0920590],
          [-2.3489680, 55.0920970],
          [-2.3490420, 55.0921350],
          [-2.3491170, 55.0921700],
          [-2.3491900, 55.0922040],
          [-2.3492650, 55.0922390],
          [-2.3493380, 55.0922730],
          [-2.3494140, 55.0923110],
          [-2.3494840, 55.0923490],
          [-2.3495520, 55.0923880],
          [-2.3496180, 55.0924290],
          [-2.3496880, 55.0924710],
          [-2.3497560, 55.0925090],
          [-2.3498200, 55.0925480],
          [-2.3498840, 55.0925820],
          [-2.3499460, 55.0926200],
          [-2.3500070, 55.0926620],
          [-2.3500690, 55.0927040],
          [-2.3501260, 55.0927380],
          [-2.3501820, 55.0927770],
          [-2.3502340, 55.0928220],
          [-2.3502820, 55.0928640],
          [-2.3503320, 55.0929210],
          [-2.3503820, 55.0929710],
          [-2.3504310, 55.0930200],
          [-2.3504790, 55.0930700],
          [-2.3505260, 55.0931200],
          [-2.3505760, 55.0931690],
          [-2.3506240, 55.0932230],
          [-2.3506740, 55.0932720],
          [-2.3507290, 55.0933220],
          [-2.3507860, 55.0933710],
          [-2.3508430, 55.0934200],
          [-2.3509070, 55.0934740],
          [-2.3509740, 55.0935280],
          [-2.3510400, 55.0935770],
          [-2.3511090, 55.0936340],
          [-2.3511760, 55.0936880],
          [-2.3512310, 55.0937440],
          [-2.3512740, 55.0938020],
          [-2.3512990, 55.0938630],
          [-2.3513020, 55.0939320],
          [-2.3512750, 55.0940040],
          [-2.3512320, 55.0940770],
          [-2.3511750, 55.0941490],
          [-2.3511100, 55.0942250],
          [-2.3510410, 55.0942980],
          [-2.3509680, 55.0943710],
          [-2.3508960, 55.0944480],
          [-2.3508230, 55.0945250],
          [-2.3507450, 55.0945990],
          [-2.3506670, 55.0946760],
          [-2.3505880, 55.0947480],
          [-2.3505080, 55.0948170],
          [-2.3504300, 55.0948890],
          [-2.3503530, 55.0949620],
          [-2.3502760, 55.0950300],
          [-2.3501950, 55.0950990],
          [-2.3501140, 55.0951670],
          [-2.3500300, 55.0952400],
          [-2.3499460, 55.0953080],
          [-2.3498570, 55.0953810],
          [-2.3497690, 55.0954540],
          [-2.3496750, 55.0955190],
          [-2.3495770, 55.0955860],
          [-2.3494730, 55.0956520],
          [-2.3493720, 55.0957170],
          [-2.3492700, 55.0957820],
          [-2.3491690, 55.0958580],
          [-2.3490720, 55.0959340],
          [-2.3489850, 55.0960260],
          [-2.3489330, 55.0961130],
          [-2.3489040, 55.0961830],
          [-2.3488900, 55.0962430],
          [-2.3488950, 55.0962910],
          [-2.3489080, 55.0963390],
          [-2.3489220, 55.0963850],
          [-2.3489170, 55.0964380],
          [-2.3488970, 55.0964880],
          [-2.3488790, 55.0965380],
          [-2.3488700, 55.0965870],
          [-2.3488760, 55.0966360],
          [-2.3488960, 55.0966860],
          [-2.3489280, 55.0967320],
          [-2.3489680, 55.0967780],
          [-2.3490170, 55.0968140],
          [-2.3490800, 55.0968470],
          [-2.3491470, 55.0968740],
          [-2.3492120, 55.0968930],
          [-2.3492720, 55.0969120],
          [-2.3493300, 55.0969310],
          [-2.3493770, 55.0969460],
          [-2.3494200, 55.0969580],
          [-2.3494620, 55.0969660],
          [-2.3495030, 55.0969730],
          [-2.3495440, 55.0969850],
          [-2.3495790, 55.0969960],
          [-2.3496160, 55.0970070],
          [-2.3496530, 55.0970190],
          [-2.3496940, 55.0970300],
          [-2.3497290, 55.0970420],
          [-2.3497640, 55.0970570],
          [-2.3497980, 55.0970720],
          [-2.3498310, 55.0970840],
          [-2.3498580, 55.0970990],
          [-2.3498880, 55.0971140],
          [-2.3499240, 55.0971300],
          [-2.3499630, 55.0971450],
          [-2.3499990, 55.0971600],
          [-2.3500360, 55.0971790],
          [-2.3500730, 55.0971940],
          [-2.3501070, 55.0972090],
          [-2.3501410, 55.0972210],
          [-2.3501740, 55.0972360],
          [-2.3502090, 55.0972510],
          [-2.3502400, 55.0972670],
          [-2.3502710, 55.0972860],
          [-2.3503030, 55.0973050],
          [-2.3503320, 55.0973200],
          [-2.3503610, 55.0973390],
          [-2.3503880, 55.0973550],
          [-2.3504140, 55.0973730],
          [-2.3504420, 55.0973920],
          [-2.3504690, 55.0974110],
          [-2.3504940, 55.0974310],
          [-2.3505180, 55.0974460],
          [-2.3505430, 55.0974690],
          [-2.3505660, 55.0974880],
          [-2.3505880, 55.0975070],
          [-2.3506080, 55.0975260],
          [-2.3506260, 55.0975450],
          [-2.3506460, 55.0975640],
          [-2.3506640, 55.0975830],
          [-2.3506840, 55.0976060],
          [-2.3507000, 55.0976250],
          [-2.3507140, 55.0976440],
          [-2.3507310, 55.0976670],
          [-2.3507480, 55.0976860],
          [-2.3507600, 55.0977090],
          [-2.3507710, 55.0977280],
          [-2.3507860, 55.0977510],
          [-2.3507980, 55.0977740],
          [-2.3508110, 55.0977930],
          [-2.3508230, 55.0978160],
          [-2.3508350, 55.0978390],
          [-2.3508450, 55.0978620],
          [-2.3508530, 55.0978850],
          [-2.3508610, 55.0979040],
          [-2.3508710, 55.0979230],
          [-2.3508800, 55.0979460],
          [-2.3508880, 55.0979690],
          [-2.3508950, 55.0979910],
          [-2.3509020, 55.0980150],
          [-2.3509080, 55.0980410],
          [-2.3509130, 55.0980630],
          [-2.3509170, 55.0980910],
          [-2.3509200, 55.0981130],
          [-2.3509230, 55.0981400],
          [-2.3509270, 55.0981590],
          [-2.3509300, 55.0981860],
          [-2.3509320, 55.0982090],
          [-2.3509330, 55.0982320],
          [-2.3509330, 55.0982550],
          [-2.3509330, 55.0982740],
          [-2.3509320, 55.0982970],
          [-2.3509280, 55.0983200],
          [-2.3509250, 55.0983430],
          [-2.3509230, 55.0983690],
          [-2.3509200, 55.0983950],
          [-2.3509150, 55.0984190],
          [-2.3509120, 55.0984450],
          [-2.3509080, 55.0984720],
          [-2.3509030, 55.0984950],
          [-2.3509020, 55.0985250],
          [-2.3508970, 55.0985560],
          [-2.3508880, 55.0985820],
          [-2.3508800, 55.0986090],
          [-2.3508730, 55.0986360],
          [-2.3508680, 55.0986630],
          [-2.3508630, 55.0986930],
          [-2.3508570, 55.0987230],
          [-2.3508490, 55.0987540],
          [-2.3508400, 55.0987810],
          [-2.3508320, 55.0988080],
          [-2.3508210, 55.0988340],
          [-2.3508100, 55.0988610],
          [-2.3507990, 55.0988880],
          [-2.3507920, 55.0989150],
          [-2.3507800, 55.0989450],
          [-2.3507670, 55.0989750],
          [-2.3507500, 55.0990060],
          [-2.3507290, 55.0990330],
          [-2.3507020, 55.0990630],
          [-2.3506690, 55.0990860],
          [-2.3506320, 55.0991060],
          [-2.3505880, 55.0991210],
          [-2.3505410, 55.0991290],
          [-2.3504910, 55.0991360],
          [-2.3504420, 55.0991360],
          [-2.3503860, 55.0991290],
          [-2.3503300, 55.0991250],
          [-2.3502710, 55.0991140],
          [-2.3502110, 55.0991020],
          [-2.3501490, 55.0990870],
          [-2.3500880, 55.0990720],
          [-2.3500260, 55.0990600],
          [-2.3499610, 55.0990410],
          [-2.3498920, 55.0990260],
          [-2.3498180, 55.0990110],
          [-2.3497430, 55.0989950],
          [-2.3496690, 55.0989840],
          [-2.3495910, 55.0989690],
          [-2.3495150, 55.0989570],
          [-2.3494400, 55.0989460],
          [-2.3493600, 55.0989340],
          [-2.3492810, 55.0989230],
          [-2.3491990, 55.0989120],
          [-2.3491190, 55.0989000],
          [-2.3490380, 55.0988890],
          [-2.3489500, 55.0988770],
          [-2.3488650, 55.0988660],
          [-2.3487810, 55.0988550],
          [-2.3486840, 55.0988430],
          [-2.3485890, 55.0988320],
          [-2.3484970, 55.0988240],
          [-2.3484020, 55.0988090],
          [-2.3483000, 55.0987970],
          [-2.3482010, 55.0987930],
          [-2.3480970, 55.0987860],
          [-2.3479970, 55.0987820],
          [-2.3478880, 55.0987780],
          [-2.3477810, 55.0987740],
          [-2.3476740, 55.0987740],
          [-2.3475640, 55.0987740],
          [-2.3474530, 55.0987740],
          [-2.3473460, 55.0987700],
          [-2.3472330, 55.0987700],
          [-2.3471250, 55.0987700],
          [-2.3470220, 55.0987700],
          [-2.3469110, 55.0987700],
          [-2.3467970, 55.0987700],
          [-2.3466860, 55.0987700],
          [-2.3465370, 55.0987630],
          [-2.3463940, 55.0987550],
          [-2.3462520, 55.0987550],
          [-2.3460870, 55.0987480],
          [-2.3459500, 55.0987510],
          [-2.3458150, 55.0987550],
          [-2.3456890, 55.0987620],
          [-2.3455560, 55.0987700],
          [-2.3454390, 55.0987740],
          [-2.3453190, 55.0987810],
          [-2.3452090, 55.0987890],
          [-2.3450920, 55.0987970],
          [-2.3449870, 55.0988070],
          [-2.3448750, 55.0988160],
          [-2.3447710, 55.0988230],
          [-2.3446650, 55.0988350],
          [-2.3445680, 55.0988420],
          [-2.3444600, 55.0988540],
          [-2.3443590, 55.0988610],
          [-2.3442520, 55.0988730],
          [-2.3441530, 55.0988800],
          [-2.3440460, 55.0988880],
          [-2.3439470, 55.0988990],
          [-2.3438380, 55.0989070],
          [-2.3437430, 55.0989150],
          [-2.3436430, 55.0989260],
          [-2.3435520, 55.0989370],
          [-2.3434540, 55.0989490],
          [-2.3433620, 55.0989560],
          [-2.3432650, 55.0989680],
          [-2.3431800, 55.0989820],
          [-2.3430810, 55.0989950],
          [-2.3429890, 55.0990090],
          [-2.3428860, 55.0990250],
          [-2.3427910, 55.0990430],
          [-2.3426780, 55.0990600],
          [-2.3425760, 55.0990780],
          [-2.3424610, 55.0990970],
          [-2.3423560, 55.0991160],
          [-2.3422360, 55.0991400],
          [-2.3421270, 55.0991650],
          [-2.3420060, 55.0991930],
          [-2.3418990, 55.0992210],
          [-2.3417840, 55.0992520],
          [-2.3416590, 55.0992870],
          [-2.3415360, 55.0993300],
          [-2.3414240, 55.0993700],
          [-2.3413030, 55.0994100],
          [-2.3411930, 55.0994460],
          [-2.3410740, 55.0994850],
          [-2.3409680, 55.0995220],
          [-2.3408520, 55.0995670],
          [-2.3407520, 55.0995990],
          [-2.3406380, 55.0996390],
          [-2.3405360, 55.0996760],
          [-2.3404270, 55.0997120],
          [-2.3403290, 55.0997440],
          [-2.3402220, 55.0997800],
          [-2.3401270, 55.0998090],
          [-2.3400260, 55.0998480],
          [-2.3399390, 55.0998780],
          [-2.3398490, 55.0999140],
          [-2.3397700, 55.0999430],
          [-2.3396880, 55.0999710],
          [-2.3396100, 55.0999980],
          [-2.3395370, 55.1000280],
          [-2.3394690, 55.1000570],
          [-2.3393920, 55.1000890],
          [-2.3393240, 55.1001170],
          [-2.3392450, 55.1001460],
          [-2.3391740, 55.1001710],
          [-2.3390960, 55.1001960],
          [-2.3390280, 55.1002200],
          [-2.3389500, 55.1002450],
          [-2.3388780, 55.1002670],
          [-2.3387980, 55.1002880],
          [-2.3387310, 55.1003030],
          [-2.3386660, 55.1003260],
          [-2.3386030, 55.1003450],
          [-2.3385440, 55.1003630],
          [-2.3384730, 55.1003800],
          [-2.3384040, 55.1003990],
          [-2.3383370, 55.1004170],
          [-2.3382650, 55.1004330],
          [-2.3381950, 55.1004480],
          [-2.3381270, 55.1004620],
          [-2.3380470, 55.1004820],
          [-2.3379700, 55.1004980],
          [-2.3378870, 55.1005090],
          [-2.3378080, 55.1005200],
          [-2.3377150, 55.1005400],
          [-2.3376300, 55.1005540],
          [-2.3375340, 55.1005700],
          [-2.3374490, 55.1005840],
          [-2.3373480, 55.1006050],
          [-2.3372590, 55.1006160],
          [-2.3371590, 55.1006350],
          [-2.3370710, 55.1006490],
          [-2.3369680, 55.1006690],
          [-2.3368680, 55.1006850],
          [-2.3367680, 55.1007000],
          [-2.3366730, 55.1007140],
          [-2.3365640, 55.1007270],
          [-2.3364660, 55.1007410],
          [-2.3363590, 55.1007540],
          [-2.3362630, 55.1007680],
          [-2.3361570, 55.1007800],
          [-2.3360590, 55.1007920],
          [-2.3359610, 55.1008030],
          [-2.3358690, 55.1008140],
          [-2.3357670, 55.1008260],
          [-2.3356770, 55.1008370],
          [-2.3355750, 55.1008450],
          [-2.3354840, 55.1008520],
          [-2.3353870, 55.1008640],
          [-2.3352890, 55.1008720],
          [-2.3351900, 55.1008760],
          [-2.3350980, 55.1008830],
          [-2.3349970, 55.1008910],
          [-2.3349040, 55.1008950],
          [-2.3347980, 55.1009020],
          [-2.3347080, 55.1009090],
          [-2.3346050, 55.1009180],
          [-2.3345140, 55.1009210],
          [-2.3344120, 55.1009250],
          [-2.3343190, 55.1009320],
          [-2.3342120, 55.1009370],
          [-2.3341180, 55.1009370],
          [-2.3340100, 55.1009410],
          [-2.3339170, 55.1009410],
          [-2.3338110, 55.1009440],
          [-2.3337120, 55.1009480],
          [-2.3336040, 55.1009480],
          [-2.3335070, 55.1009480],
          [-2.3334020, 55.1009480],
          [-2.3333080, 55.1009520],
          [-2.3332090, 55.1009520],
          [-2.3331120, 55.1009560],
          [-2.3330050, 55.1009560],
          [-2.3329090, 55.1009590],
          [-2.3328000, 55.1009600],
          [-2.3327050, 55.1009630],
          [-2.3325980, 55.1009640],
          [-2.3324970, 55.1009670],
          [-2.3323920, 55.1009710],
          [-2.3322930, 55.1009750],
          [-2.3321840, 55.1009750],
          [-2.3320760, 55.1009790],
          [-2.3319570, 55.1009790],
          [-2.3318540, 55.1009820],
          [-2.3317410, 55.1009860],
          [-2.3316420, 55.1009870],
          [-2.3315330, 55.1009900],
          [-2.3314370, 55.1009900],
          [-2.3313290, 55.1009900],
          [-2.3312390, 55.1009900],
          [-2.3311380, 55.1010010],
          [-2.3310550, 55.1010120],
          [-2.3309760, 55.1010390],
          [-2.3309350, 55.1010710],
          [-2.3309220, 55.1011150],
          [-2.3309350, 55.1011650],
          [-2.3309580, 55.1012150],
          [-2.3309850, 55.1012760],
          [-2.3310070, 55.1013250],
          [-2.3310280, 55.1013780],
          [-2.3310480, 55.1014290],
          [-2.3310730, 55.1014830],
          [-2.3310990, 55.1015340],
          [-2.3311310, 55.1015920],
          [-2.3311620, 55.1016420],
          [-2.3312040, 55.1016910],
          [-2.3312480, 55.1017310],
          [-2.3312970, 55.1017710],
          [-2.3313440, 55.1018040],
          [-2.3313990, 55.1018370],
          [-2.3314510, 55.1018630],
          [-2.3315040, 55.1018840],
          [-2.3315650, 55.1019130],
          [-2.3316240, 55.1019390],
          [-2.3316830, 55.1019610],
          [-2.3317440, 55.1019850],
          [-2.3318020, 55.1020080],
          [-2.3318590, 55.1020260],
          [-2.3319220, 55.1020470],
          [-2.3319770, 55.1020660],
          [-2.3320280, 55.1020840],
          [-2.3320840, 55.1021000],
          [-2.3321360, 55.1021190],
          [-2.3321850, 55.1021330],
          [-2.3322400, 55.1021490],
          [-2.3322940, 55.1021650],
          [-2.3323440, 55.1021790],
          [-2.3323990, 55.1021950],
          [-2.3324500, 55.1022100],
          [-2.3324990, 55.1022250],
          [-2.3325540, 55.1022410],
          [-2.3326070, 55.1022530],
          [-2.3326550, 55.1022660],
          [-2.3327140, 55.1022830],
          [-2.3327640, 55.1022940],
          [-2.3328170, 55.1023100],
          [-2.3328680, 55.1023250],
          [-2.3329210, 55.1023400],
          [-2.3329670, 55.1023550],
          [-2.3330200, 55.1023750],
          [-2.3330670, 55.1023860],
          [-2.3331120, 55.1024000],
          [-2.3331650, 55.1024130],
          [-2.3332120, 55.1024280],
          [-2.3332550, 55.1024390],
          [-2.3333060, 55.1024550],
          [-2.3333570, 55.1024660],
          [-2.3334010, 55.1024800],
          [-2.3334490, 55.1024930],
          [-2.3334920, 55.1025010],
          [-2.3335390, 55.1025120],
          [-2.3335790, 55.1025220],
          [-2.3336300, 55.1025350],
          [-2.3336750, 55.1025460],
          [-2.3337200, 55.1025580],
          [-2.3337610, 55.1025720],
          [-2.3338060, 55.1025880],
          [-2.3338460, 55.1026040],
          [-2.3338820, 55.1026190],
          [-2.3339180, 55.1026290],
          [-2.3339560, 55.1026460],
          [-2.3339920, 55.1026570],
          [-2.3340280, 55.1026720],
          [-2.3340630, 55.1026860],
          [-2.3341020, 55.1027030],
          [-2.3341390, 55.1027220],
          [-2.3341730, 55.1027370],
          [-2.3342030, 55.1027510],
          [-2.3342380, 55.1027720],
          [-2.3342700, 55.1027860],
          [-2.3343020, 55.1028020],
          [-2.3343310, 55.1028190],
          [-2.3343660, 55.1028350],
          [-2.3343990, 55.1028510],
          [-2.3344300, 55.1028700],
          [-2.3344580, 55.1028840],
          [-2.3344940, 55.1029010],
          [-2.3345270, 55.1029160],
          [-2.3345620, 55.1029310],
          [-2.3345930, 55.1029460],
          [-2.3346270, 55.1029620],
          [-2.3346620, 55.1029770],
          [-2.3346970, 55.1029960],
          [-2.3347300, 55.1030110],
          [-2.3347670, 55.1030270],
          [-2.3348040, 55.1030420],
          [-2.3348370, 55.1030570],
          [-2.3348700, 55.1030710],
          [-2.3349070, 55.1030880],
          [-2.3349400, 55.1031030],
          [-2.3349710, 55.1031180],
          [-2.3350000, 55.1031330],
          [-2.3350350, 55.1031490],
          [-2.3350690, 55.1031640],
          [-2.3351020, 55.1031790],
          [-2.3351350, 55.1031940],
          [-2.3351710, 55.1032140],
          [-2.3352020, 55.1032320],
          [-2.3352330, 55.1032480],
          [-2.3352640, 55.1032620],
          [-2.3353000, 55.1032820],
          [-2.3353330, 55.1033010],
          [-2.3353670, 55.1033170],
          [-2.3353980, 55.1033340],
          [-2.3354300, 55.1033540],
          [-2.3354620, 55.1033740],
          [-2.3354930, 55.1033960],
          [-2.3355190, 55.1034130],
          [-2.3355500, 55.1034380],
          [-2.3355810, 55.1034580],
          [-2.3356090, 55.1034800],
          [-2.3356370, 55.1034980],
          [-2.3356660, 55.1035220],
          [-2.3356930, 55.1035450],
          [-2.3357220, 55.1035650],
          [-2.3357470, 55.1035850],
          [-2.3357740, 55.1036140],
          [-2.3357980, 55.1036370],
          [-2.3358210, 55.1036600],
          [-2.3358400, 55.1036850],
          [-2.3358600, 55.1037100],
          [-2.3358780, 55.1037360],
          [-2.3358920, 55.1037620],
          [-2.3359070, 55.1037880],
          [-2.3359230, 55.1038160],
          [-2.3359360, 55.1038460],
          [-2.3359490, 55.1038730],
          [-2.3359590, 55.1039010],
          [-2.3359690, 55.1039300],
          [-2.3359780, 55.1039570],
          [-2.3359880, 55.1039840],
          [-2.3359950, 55.1040070],
          [-2.3360050, 55.1040410],
          [-2.3360130, 55.1040680],
          [-2.3360210, 55.1040980],
          [-2.3360310, 55.1041230],
          [-2.3360380, 55.1041550],
          [-2.3360450, 55.1041820],
          [-2.3360530, 55.1042090],
          [-2.3360610, 55.1042370],
          [-2.3360660, 55.1042690],
          [-2.3360700, 55.1043000],
          [-2.3360720, 55.1043270],
          [-2.3360750, 55.1043550],
          [-2.3360780, 55.1043880],
          [-2.3360800, 55.1044220],
          [-2.3360790, 55.1044470],
          [-2.3360770, 55.1044830],
          [-2.3360740, 55.1045140],
          [-2.3360700, 55.1045450],
          [-2.3360650, 55.1045820],
          [-2.3360530, 55.1046160],
          [-2.3360360, 55.1046480],
          [-2.3360160, 55.1046850],
          [-2.3359960, 55.1047230],
          [-2.3359760, 55.1047550],
          [-2.3359500, 55.1047920],
          [-2.3359280, 55.1048290],
          [-2.3359060, 55.1048630],
          [-2.3358780, 55.1049030],
          [-2.3358550, 55.1049400],
          [-2.3358310, 55.1049760],
          [-2.3357980, 55.1050170],
          [-2.3357680, 55.1050520],
          [-2.3357460, 55.1050830],
          [-2.3357150, 55.1051230],
          [-2.3356860, 55.1051580],
          [-2.3356620, 55.1052000],
          [-2.3356330, 55.1052450],
          [-2.3355990, 55.1052840],
          [-2.3355600, 55.1053300],
          [-2.3355250, 55.1053680],
          [-2.3354850, 55.1054130],
          [-2.3354530, 55.1054580],
          [-2.3354120, 55.1055090],
          [-2.3353730, 55.1055550],
          [-2.3353310, 55.1056090],
          [-2.3352980, 55.1056530],
          [-2.3352580, 55.1057070],
          [-2.3352210, 55.1057520],
          [-2.3351740, 55.1058090],
          [-2.3351340, 55.1058590],
          [-2.3350870, 55.1059130],
          [-2.3350470, 55.1059680],
          [-2.3349950, 55.1060310],
          [-2.3349520, 55.1060870],
          [-2.3348980, 55.1061500],
          [-2.3348520, 55.1062040],
          [-2.3347960, 55.1062670],
          [-2.3347470, 55.1063200],
          [-2.3346900, 55.1063850],
          [-2.3346390, 55.1064380],
          [-2.3345790, 55.1065030],
          [-2.3345270, 55.1065560],
          [-2.3344650, 55.1066170],
          [-2.3344110, 55.1066740],
          [-2.3343530, 55.1067400],
          [-2.3343060, 55.1067950],
          [-2.3342510, 55.1068590],
          [-2.3342060, 55.1069180],
          [-2.3341590, 55.1069840],
          [-2.3341220, 55.1070470],
          [-2.3340800, 55.1071180],
          [-2.3340490, 55.1071800],
          [-2.3340130, 55.1072550],
          [-2.3339850, 55.1073180],
          [-2.3339580, 55.1073930],
          [-2.3339420, 55.1074520],
          [-2.3339230, 55.1075140],
          [-2.3339090, 55.1075670],
          [-2.3338960, 55.1076290],
          [-2.3338880, 55.1076880],
          [-2.3338870, 55.1077550],
          [-2.3338920, 55.1078130],
          [-2.3339010, 55.1078780],
          [-2.3339070, 55.1079390],
          [-2.3339190, 55.1080100],
          [-2.3339310, 55.1080660],
          [-2.3339520, 55.1081320],
          [-2.3339710, 55.1081940],
          [-2.3339940, 55.1082680],
          [-2.3340150, 55.1083290],
          [-2.3340400, 55.1084020],
          [-2.3340670, 55.1084680],
          [-2.3341010, 55.1085380],
          [-2.3341350, 55.1085970],
          [-2.3341780, 55.1086580],
          [-2.3342170, 55.1087080],
          [-2.3342690, 55.1087670],
          [-2.3343150, 55.1088120],
          [-2.3343790, 55.1088650],
          [-2.3344400, 55.1089110],
          [-2.3345130, 55.1089640],
          [-2.3345770, 55.1090100],
          [-2.3346510, 55.1090600],
          [-2.3347090, 55.1090980],
          [-2.3347810, 55.1091480],
          [-2.3348380, 55.1091900],
          [-2.3348990, 55.1092380],
          [-2.3349490, 55.1092810],
          [-2.3350060, 55.1093360],
          [-2.3350470, 55.1093770],
          [-2.3350920, 55.1094250],
          [-2.3351310, 55.1094710],
          [-2.3351720, 55.1095240],
          [-2.3352090, 55.1095680],
          [-2.3352500, 55.1096210],
          [-2.3352860, 55.1096660],
          [-2.3353260, 55.1097200],
          [-2.3353560, 55.1097650],
          [-2.3353860, 55.1098140],
          [-2.3354140, 55.1098600],
          [-2.3354450, 55.1099140],
          [-2.3354700, 55.1099600],
          [-2.3354980, 55.1100090],
          [-2.3355200, 55.1100550],
          [-2.3355470, 55.1101050],
          [-2.3355700, 55.1101470],
          [-2.3355970, 55.1101930],
          [-2.3356220, 55.1102290],
          [-2.3356480, 55.1102720],
          [-2.3356750, 55.1103110],
          [-2.3357020, 55.1103460],
          [-2.3357380, 55.1103910],
          [-2.3357710, 55.1104290],
          [-2.3358000, 55.1104680],
          [-2.3358340, 55.1105050],
          [-2.3358710, 55.1105430],
          [-2.3359120, 55.1105800],
          [-2.3359620, 55.1106210],
          [-2.3360120, 55.1106560],
          [-2.3360640, 55.1106890],
          [-2.3361110, 55.1107220],
          [-2.3361650, 55.1107510],
          [-2.3362160, 55.1107810],
          [-2.3362630, 55.1108090],
          [-2.3363190, 55.1108460],
          [-2.3363740, 55.1108800],
          [-2.3364240, 55.1109080],
          [-2.3364870, 55.1109450],
          [-2.3365410, 55.1109760],
          [-2.3366080, 55.1110130],
          [-2.3366670, 55.1110480],
          [-2.3367310, 55.1110850],
          [-2.3367840, 55.1111210],
          [-2.3368490, 55.1111590],
          [-2.3369030, 55.1111900],
          [-2.3369670, 55.1112300],
          [-2.3370310, 55.1112700],
          [-2.3371030, 55.1113180],
          [-2.3371640, 55.1113540],
          [-2.3372370, 55.1114010],
          [-2.3373020, 55.1114410],
          [-2.3373830, 55.1114830],
          [-2.3374480, 55.1115230],
          [-2.3375340, 55.1115740],
          [-2.3376030, 55.1116180],
          [-2.3376890, 55.1116720],
          [-2.3377540, 55.1117160],
          [-2.3378230, 55.1117800],
          [-2.3378580, 55.1118380],
          [-2.3378740, 55.1119190],
          [-2.3378580, 55.1119960],
          [-2.3378130, 55.1120920],
          [-2.3377570, 55.1121670],
          [-2.3376900, 55.1122600],
          [-2.3375980, 55.1123550],
          [-2.3374900, 55.1124460],
          [-2.3373670, 55.1125310],
          [-2.3372280, 55.1126070],
          [-2.3370770, 55.1126790],
          [-2.3369210, 55.1127450],
          [-2.3367570, 55.1128030],
          [-2.3365830, 55.1128620],
          [-2.3364160, 55.1129120],
          [-2.3362440, 55.1129580],
          [-2.3360710, 55.1130030],
          [-2.3358890, 55.1130470],
          [-2.3357240, 55.1130920],
          [-2.3355500, 55.1131350],
          [-2.3353770, 55.1131730],
          [-2.3352080, 55.1132140],
          [-2.3350460, 55.1132590],
          [-2.3348950, 55.1133070],
          [-2.3347470, 55.1133700],
          [-2.3346220, 55.1134450],
          [-2.3345050, 55.1135200],
          [-2.3344030, 55.1136050],
          [-2.3342810, 55.1136930],
          [-2.3341740, 55.1137840],
          [-2.3340830, 55.1138820],
          [-2.3340200, 55.1139720],
          [-2.3339910, 55.1140580],
          [-2.3340030, 55.1141380],
          [-2.3340360, 55.1142140],
          [-2.3340840, 55.1142830],
          [-2.3341400, 55.1143440],
          [-2.3342200, 55.1144130],
          [-2.3342890, 55.1144590],
          [-2.3343830, 55.1145120],
          [-2.3344670, 55.1145540],
          [-2.3345460, 55.1145910],
          [-2.3346210, 55.1146280],
          [-2.3347000, 55.1146610],
          [-2.3347720, 55.1146920],
          [-2.3348370, 55.1147180],
          [-2.3349070, 55.1147450],
          [-2.3349700, 55.1147720],
          [-2.3350290, 55.1147980],
          [-2.3350890, 55.1148220],
          [-2.3351470, 55.1148450],
          [-2.3352060, 55.1148640],
          [-2.3352630, 55.1148830],
          [-2.3353260, 55.1149060],
          [-2.3353850, 55.1149250],
          [-2.3354440, 55.1149440],
          [-2.3354960, 55.1149590],
          [-2.3355460, 55.1149750],
          [-2.3355930, 55.1149890],
          [-2.3356420, 55.1150050],
          [-2.3356930, 55.1150240],
          [-2.3357450, 55.1150400],
          [-2.3357960, 55.1150590],
          [-2.3358460, 55.1150740],
          [-2.3358920, 55.1150880],
          [-2.3359420, 55.1151080],
          [-2.3359890, 55.1151240],
          [-2.3360370, 55.1151420],
          [-2.3360840, 55.1151610],
          [-2.3361310, 55.1151840],
          [-2.3361790, 55.1152030],
          [-2.3362260, 55.1152230],
          [-2.3362700, 55.1152380],
          [-2.3363170, 55.1152570],
          [-2.3363610, 55.1152760],
          [-2.3364070, 55.1152990],
          [-2.3364540, 55.1153220],
          [-2.3365000, 55.1153440],
          [-2.3365500, 55.1153630],
          [-2.3366040, 55.1153870],
          [-2.3366560, 55.1154090],
          [-2.3367090, 55.1154360],
          [-2.3367610, 55.1154550],
          [-2.3368120, 55.1154820],
          [-2.3368630, 55.1155050],
          [-2.3369160, 55.1155280],
          [-2.3369680, 55.1155540],
          [-2.3370200, 55.1155730],
          [-2.3370720, 55.1156000],
          [-2.3371220, 55.1156230],
          [-2.3371690, 55.1156500],
          [-2.3372140, 55.1156760],
          [-2.3372620, 55.1156990],
          [-2.3373140, 55.1157260],
          [-2.3373650, 55.1157520],
          [-2.3374190, 55.1157760],
          [-2.3374710, 55.1158020],
          [-2.3375240, 55.1158250],
          [-2.3375790, 55.1158520],
          [-2.3376320, 55.1158750],
          [-2.3376860, 55.1158980],
          [-2.3377380, 55.1159240],
          [-2.3377910, 55.1159510],
          [-2.3378460, 55.1159780],
          [-2.3379010, 55.1160050],
          [-2.3379480, 55.1160270],
          [-2.3380140, 55.1160540],
          [-2.3380720, 55.1160810],
          [-2.3381310, 55.1161070],
          [-2.3381900, 55.1161340],
          [-2.3382470, 55.1161640],
          [-2.3383050, 55.1161910],
          [-2.3383640, 55.1162170],
          [-2.3384290, 55.1162490],
          [-2.3384910, 55.1162790],
          [-2.3385510, 55.1163100],
          [-2.3386140, 55.1163400],
          [-2.3386740, 55.1163700],
          [-2.3387200, 55.1163920],
          [-2.3387840, 55.1164240],
          [-2.3388430, 55.1164510],
          [-2.3389040, 55.1164770],
          [-2.3389670, 55.1165040],
          [-2.3390290, 55.1165310],
          [-2.3390920, 55.1165610],
          [-2.3391570, 55.1165880],
          [-2.3392230, 55.1166150],
          [-2.3392890, 55.1166450],
          [-2.3393570, 55.1166720],
          [-2.3394260, 55.1166990],
          [-2.3394900, 55.1167250],
          [-2.3395570, 55.1167560],
          [-2.3396250, 55.1167790],
          [-2.3396940, 55.1168060],
          [-2.3397660, 55.1168320],
          [-2.3398340, 55.1168590],
          [-2.3399020, 55.1168860],
          [-2.3399720, 55.1169160],
          [-2.3400400, 55.1169430],
          [-2.3401080, 55.1169730],
          [-2.3401770, 55.1170000],
          [-2.3402470, 55.1170270],
          [-2.3403160, 55.1170540],
          [-2.3403860, 55.1170800],
          [-2.3404540, 55.1171070],
          [-2.3405230, 55.1171330],
          [-2.3405930, 55.1171680],
          [-2.3406610, 55.1171950],
          [-2.3407300, 55.1172250],
          [-2.3408010, 55.1172560],
          [-2.3408720, 55.1172830],
          [-2.3409450, 55.1173130],
          [-2.3410180, 55.1173390],
          [-2.3410920, 55.1173660],
          [-2.3411620, 55.1173930],
          [-2.3412320, 55.1174200],
          [-2.3413040, 55.1174460],
          [-2.3413740, 55.1174730],
          [-2.3414400, 55.1174990],
          [-2.3415090, 55.1175230],
          [-2.3415770, 55.1175490],
          [-2.3416470, 55.1175720],
          [-2.3417200, 55.1175950],
          [-2.3417920, 55.1176180],
          [-2.3418620, 55.1176450],
          [-2.3419320, 55.1176680],
          [-2.3420030, 55.1176900],
          [-2.3420740, 55.1177130],
          [-2.3421460, 55.1177400],
          [-2.3422160, 55.1177670],
          [-2.3422830, 55.1177900],
          [-2.3423540, 55.1178170],
          [-2.3424230, 55.1178390],
          [-2.3424920, 55.1178660],
          [-2.3425600, 55.1178890],
          [-2.3426270, 55.1179160],
          [-2.3426960, 55.1179420],
          [-2.3427620, 55.1179690],
          [-2.3428270, 55.1179960],
          [-2.3428910, 55.1180190],
          [-2.3429540, 55.1180490],
          [-2.3430170, 55.1180760],
          [-2.3430810, 55.1181060],
          [-2.3431450, 55.1181330],
          [-2.3432090, 55.1181560],
          [-2.3432720, 55.1181820],
          [-2.3433330, 55.1182090],
          [-2.3433970, 55.1182400],
          [-2.3434580, 55.1182630],
          [-2.3435160, 55.1182900],
          [-2.3435720, 55.1183160],
          [-2.3436290, 55.1183430],
          [-2.3436870, 55.1183700],
          [-2.3437450, 55.1183920],
          [-2.3438040, 55.1184160],
          [-2.3438610, 55.1184420],
          [-2.3439150, 55.1184690],
          [-2.3439710, 55.1184960],
          [-2.3440260, 55.1185260],
          [-2.3440810, 55.1185530],
          [-2.3441360, 55.1185800],
          [-2.3441930, 55.1186060],
          [-2.3442510, 55.1186330],
          [-2.3443080, 55.1186600],
          [-2.3443640, 55.1186900],
          [-2.3444180, 55.1187160],
          [-2.3444710, 55.1187430],
          [-2.3445260, 55.1187660],
          [-2.3445780, 55.1187890],
          [-2.3446290, 55.1188120],
          [-2.3446820, 55.1188350],
          [-2.3447370, 55.1188580],
          [-2.3447900, 55.1188840],
          [-2.3448430, 55.1189070],
          [-2.3448970, 55.1189300],
          [-2.3449540, 55.1189530],
          [-2.3450110, 55.1189800],
          [-2.3450640, 55.1190020],
          [-2.3451180, 55.1190300],
          [-2.3451710, 55.1190520],
          [-2.3452260, 55.1190750],
          [-2.3452810, 55.1190980],
          [-2.3453310, 55.1191170],
          [-2.3453790, 55.1191360],
          [-2.3454290, 55.1191630],
          [-2.3454780, 55.1191820],
          [-2.3455220, 55.1192050],
          [-2.3455680, 55.1192240],
          [-2.3456130, 55.1192430],
          [-2.3456560, 55.1192620],
          [-2.3456970, 55.1192810],
          [-2.3457410, 55.1193010],
          [-2.3457820, 55.1193160],
          [-2.3458230, 55.1193350],
          [-2.3458650, 55.1193500],
          [-2.3459080, 55.1193690],
          [-2.3459490, 55.1193850],
          [-2.3459890, 55.1194000],
          [-2.3460310, 55.1194190],
          [-2.3460720, 55.1194380],
          [-2.3461130, 55.1194530],
          [-2.3461540, 55.1194680],
          [-2.3461930, 55.1194840],
          [-2.3462310, 55.1194990],
          [-2.3462690, 55.1195180],
          [-2.3463080, 55.1195330],
          [-2.3463490, 55.1195490],
          [-2.3463900, 55.1195630],
          [-2.3464310, 55.1195830],
          [-2.3464740, 55.1195980],
          [-2.3465160, 55.1196130],
          [-2.3465580, 55.1196290],
          [-2.3466010, 55.1196480],
          [-2.3466430, 55.1196630],
          [-2.3466810, 55.1196780],
          [-2.3467180, 55.1196900],
          [-2.3467530, 55.1197050],
          [-2.3467910, 55.1197200],
          [-2.3468290, 55.1197350],
          [-2.3468710, 55.1197510],
          [-2.3469130, 55.1197660],
          [-2.3469540, 55.1197810],
          [-2.3469940, 55.1197960],
          [-2.3470360, 55.1198120],
          [-2.3470760, 55.1198270],
          [-2.3471160, 55.1198380],
          [-2.3471580, 55.1198500],
          [-2.3472010, 55.1198650],
          [-2.3472440, 55.1198770],
          [-2.3472880, 55.1198880],
          [-2.3473340, 55.1198990],
          [-2.3473770, 55.1199110],
          [-2.3474190, 55.1199260],
          [-2.3474610, 55.1199380],
          [-2.3475030, 55.1199450],
          [-2.3475430, 55.1199560],
          [-2.3475860, 55.1199680],
          [-2.3476290, 55.1199800],
          [-2.3476750, 55.1199950],
          [-2.3477180, 55.1200060],
          [-2.3477590, 55.1200220],
          [-2.3478000, 55.1200330],
          [-2.3478410, 55.1200480],
          [-2.3478810, 55.1200560],
          [-2.3479230, 55.1200670],
          [-2.3479630, 55.1200830],
          [-2.3479990, 55.1200940],
          [-2.3480410, 55.1201060],
          [-2.3480790, 55.1201170],
          [-2.3481200, 55.1201250],
          [-2.3481640, 55.1201400],
          [-2.3482090, 55.1201510],
          [-2.3482520, 55.1201590],
          [-2.3482980, 55.1201700],
          [-2.3483410, 55.1201820],
          [-2.3483810, 55.1201930],
          [-2.3484190, 55.1202080],
          [-2.3484560, 55.1202200],
          [-2.3484970, 55.1202390],
          [-2.3485390, 55.1202540],
          [-2.3485790, 55.1202700],
          [-2.3486160, 55.1202850],
          [-2.3486490, 55.1202960],
          [-2.3486860, 55.1203040],
          [-2.3487260, 55.1203150],
          [-2.3487680, 55.1203270],
          [-2.3488090, 55.1203460],
          [-2.3488490, 55.1203650],
          [-2.3488890, 55.1203760],
          [-2.3489230, 55.1203910],
          [-2.3489590, 55.1204070],
          [-2.3489890, 55.1204220],
          [-2.3490190, 55.1204330],
          [-2.3490590, 55.1204450],
          [-2.3491030, 55.1204600],
          [-2.3491420, 55.1204720],
          [-2.3491860, 55.1204870],
          [-2.3492330, 55.1204980],
          [-2.3492840, 55.1205100],
          [-2.3493370, 55.1205180],
          [-2.3493930, 55.1205250],
          [-2.3494480, 55.1205290],
          [-2.3494990, 55.1205330],
          [-2.3495490, 55.1205330],
          [-2.3496040, 55.1205290],
          [-2.3496550, 55.1205250],
          [-2.3497060, 55.1205220],
          [-2.3497590, 55.1205180],
          [-2.3498090, 55.1205180],
          [-2.3498630, 55.1205210],
          [-2.3499160, 55.1205220],
          [-2.3499690, 55.1205220],
          [-2.3500240, 55.1205220],
          [-2.3500770, 55.1205180],
          [-2.3501320, 55.1205180],
          [-2.3501890, 55.1205140],
          [-2.3502510, 55.1205100],
          [-2.3503090, 55.1205100],
          [-2.3503690, 55.1205100],
          [-2.3504320, 55.1205060],
          [-2.3505010, 55.1205030],
          [-2.3505670, 55.1204990],
          [-2.3506330, 55.1204950],
          [-2.3506990, 55.1204910],
          [-2.3507690, 55.1204910],
          [-2.3508440, 55.1204870],
          [-2.3509130, 55.1204800],
          [-2.3509880, 55.1204690],
          [-2.3510710, 55.1204610],
          [-2.3511640, 55.1204490],
          [-2.3512680, 55.1204340],
          [-2.3513860, 55.1204080],
          [-2.3515010, 55.1203810],
          [-2.3516180, 55.1203540],
          [-2.3517350, 55.1203240],
          [-2.3518410, 55.1202970],
          [-2.3519390, 55.1202700],
          [-2.3520260, 55.1202400],
          [-2.3521020, 55.1202130],
          [-2.3521710, 55.1201940],
          [-2.3522310, 55.1201710],
          [-2.3522970, 55.1201520],
          [-2.3523640, 55.1201330],
          [-2.3524320, 55.1201140],
          [-2.3525000, 55.1200980],
          [-2.3525740, 55.1200760],
          [-2.3526420, 55.1200640],
          [-2.3527080, 55.1200530],
          [-2.3527720, 55.1200490],
          [-2.3528380, 55.1200370],
          [-2.3529030, 55.1200330],
          [-2.3529670, 55.1200300],
          [-2.3530280, 55.1200260],
          [-2.3530910, 55.1200220],
          [-2.3531500, 55.1200180],
          [-2.3532070, 55.1200140],
          [-2.3532630, 55.1200110],
          [-2.3533210, 55.1200070],
          [-2.3533820, 55.1199990],
          [-2.3534420, 55.1199950],
          [-2.3535020, 55.1199950],
          [-2.3535650, 55.1199950],
          [-2.3536320, 55.1199910],
          [-2.3537000, 55.1199840],
          [-2.3537660, 55.1199800],
          [-2.3538270, 55.1199730],
          [-2.3538880, 55.1199720],
          [-2.3539470, 55.1199720],
          [-2.3540030, 55.1199690],
          [-2.3540590, 55.1199680],
          [-2.3541190, 55.1199680],
          [-2.3541770, 55.1199680],
          [-2.3542350, 55.1199680],
          [-2.3542920, 55.1199680],
          [-2.3543540, 55.1199680],
          [-2.3544150, 55.1199680],
          [-2.3544770, 55.1199680],
          [-2.3545370, 55.1199680],
          [-2.3545940, 55.1199680],
          [-2.3546500, 55.1199650],
          [-2.3547110, 55.1199610],
          [-2.3547740, 55.1199570],
          [-2.3548340, 55.1199610],
          [-2.3548990, 55.1199610],
          [-2.3549620, 55.1199610],
          [-2.3550240, 55.1199650],
          [-2.3550920, 55.1199720],
          [-2.3551620, 55.1199830],
          [-2.3552290, 55.1199950],
          [-2.3552990, 55.1199990],
          [-2.3553670, 55.1200060],
          [-2.3554340, 55.1200140],
          [-2.3554990, 55.1200180],
          [-2.3555700, 55.1200220],
          [-2.3556390, 55.1200290],
          [-2.3557090, 55.1200330],
          [-2.3557840, 55.1200370],
          [-2.3558530, 55.1200370],
          [-2.3559220, 55.1200410],
          [-2.3559900, 55.1200480],
          [-2.3560620, 55.1200520],
          [-2.3561340, 55.1200600],
          [-2.3562070, 55.1200670],
          [-2.3562790, 55.1200750],
          [-2.3563490, 55.1200790],
          [-2.3564200, 55.1200870],
          [-2.3564930, 55.1200980],
          [-2.3565650, 55.1201060],
          [-2.3566340, 55.1201130],
          [-2.3567020, 55.1201170],
          [-2.3567670, 55.1201250],
          [-2.3568300, 55.1201320],
          [-2.3568960, 55.1201400],
          [-2.3569550, 55.1201510],
          [-2.3570120, 55.1201660],
          [-2.3570710, 55.1201780],
          [-2.3571270, 55.1201820],
          [-2.3571840, 55.1201930],
          [-2.3572410, 55.1202010],
          [-2.3572940, 55.1202050],
          [-2.3573420, 55.1202120],
          [-2.3573920, 55.1202200],
          [-2.3574420, 55.1202200],
          [-2.3574920, 55.1202200],
          [-2.3575410, 55.1202240],
          [-2.3575920, 55.1202280],
          [-2.3576420, 55.1202280],
          [-2.3576920, 55.1202280],
          [-2.3577440, 55.1202320],
          [-2.3578000, 55.1202350],
          [-2.3578590, 55.1202350],
          [-2.3579130, 55.1202390],
          [-2.3579690, 55.1202390],
          [-2.3580260, 55.1202430],
          [-2.3580770, 55.1202430],
          [-2.3581270, 55.1202430],
          [-2.3581760, 55.1202470],
          [-2.3582270, 55.1202510],
          [-2.3582790, 55.1202580],
          [-2.3583320, 55.1202660],
          [-2.3583910, 55.1202730],
          [-2.3584470, 55.1202770],
          [-2.3585020, 55.1202850],
          [-2.3585540, 55.1202890],
          [-2.3586060, 55.1202930],
          [-2.3586540, 55.1203000],
          [-2.3587040, 55.1203080],
          [-2.3587500, 55.1203150],
          [-2.3587950, 55.1203230],
          [-2.3588430, 55.1203310],
          [-2.3588920, 55.1203310],
          [-2.3589430, 55.1203310],
          [-2.3589950, 55.1203380],
          [-2.3590460, 55.1203380],
          [-2.3590940, 55.1203420],
          [-2.3591390, 55.1203460],
          [-2.3591830, 55.1203460],
          [-2.3592250, 55.1203460],
          [-2.3592680, 55.1203500],
          [-2.3593120, 55.1203570],
          [-2.3593560, 55.1203650],
          [-2.3594020, 55.1203690],
          [-2.3594490, 55.1203760],
          [-2.3594960, 55.1203800],
          [-2.3595440, 55.1203840],
          [-2.3595920, 55.1203880],
          [-2.3596420, 55.1203880],
          [-2.3596870, 55.1203920],
          [-2.3597330, 55.1203960],
          [-2.3597780, 55.1203990],
          [-2.3598170, 55.1204030],
          [-2.3598570, 55.1204070],
          [-2.3598990, 55.1204110],
          [-2.3599410, 55.1204150],
          [-2.3599860, 55.1204180],
          [-2.3600270, 55.1204190],
          [-2.3600670, 55.1204220],
          [-2.3601110, 55.1204260],
          [-2.3601530, 55.1204260],
          [-2.3601950, 55.1204260],
          [-2.3602390, 55.1204260],
          [-2.3602830, 55.1204300],
          [-2.3603300, 55.1204300],
          [-2.3603710, 55.1204340],
          [-2.3604120, 55.1204410],
          [-2.3604540, 55.1204410],
          [-2.3604960, 55.1204450],
          [-2.3605370, 55.1204450],
          [-2.3605780, 55.1204450],
          [-2.3606210, 55.1204490],
          [-2.3606640, 55.1204570],
          [-2.3607030, 55.1204570],
          [-2.3607440, 55.1204600],
          [-2.3607830, 55.1204640],
          [-2.3608190, 55.1204680],
          [-2.3608610, 55.1204760],
          [-2.3608980, 55.1204830],
          [-2.3609410, 55.1204830],
          [-2.3609870, 55.1204870],
          [-2.3610350, 55.1204950],
          [-2.3610840, 55.1204990],
          [-2.3611290, 55.1205060],
          [-2.3611760, 55.1205060],
          [-2.3612180, 55.1205060],
          [-2.3612640, 55.1205100],
          [-2.3613120, 55.1205100],
          [-2.3613590, 55.1205100],
          [-2.3614030, 55.1205140],
          [-2.3614460, 55.1205180],
          [-2.3614910, 55.1205210],
          [-2.3615390, 55.1205250],
          [-2.3615830, 55.1205220],
          [-2.3616300, 55.1205220],
          [-2.3616740, 55.1205180],
          [-2.3617210, 55.1205140],
          [-2.3617650, 55.1205210],
          [-2.3618120, 55.1205220],
          [-2.3618590, 55.1205290],
          [-2.3619070, 55.1205290],
          [-2.3619570, 55.1205250],
          [-2.3620120, 55.1205290],
          [-2.3620670, 55.1205370],
          [-2.3621220, 55.1205370],
          [-2.3621790, 55.1205440],
          [-2.3622320, 55.1205480],
          [-2.3622830, 55.1205520],
          [-2.3623390, 55.1205560],
          [-2.3623970, 55.1205600],
          [-2.3624550, 55.1205600],
          [-2.3625120, 55.1205670],
          [-2.3625750, 55.1205710],
          [-2.3626420, 55.1205750],
          [-2.3627090, 55.1205820],
          [-2.3627720, 55.1205900],
          [-2.3628320, 55.1205980],
          [-2.3628900, 55.1206010],
          [-2.3629520, 55.1206130],
          [-2.3630180, 55.1206130],
          [-2.3630840, 55.1206210],
          [-2.3631490, 55.1206280],
          [-2.3632140, 55.1206360],
          [-2.3632790, 55.1206470],
          [-2.3633450, 55.1206510],
          [-2.3634110, 55.1206510],
          [-2.3634710, 55.1206510],
          [-2.3635290, 55.1206480],
          [-2.3635900, 55.1206470],
          [-2.3636550, 55.1206470],
          [-2.3637250, 55.1206550],
          [-2.3637960, 55.1206590],
          [-2.3638690, 55.1206660],
          [-2.3639440, 55.1206700],
          [-2.3640140, 55.1206740],
          [-2.3640850, 55.1206780],
          [-2.3641600, 55.1206850],
          [-2.3642380, 55.1206930],
          [-2.3643200, 55.1207040],
          [-2.3644100, 55.1207160],
          [-2.3645020, 55.1207270],
          [-2.3645920, 55.1207390],
          [-2.3646800, 55.1207580],
          [-2.3647670, 55.1207690],
          [-2.3648530, 55.1207770],
          [-2.3649400, 55.1207840],
          [-2.3650310, 55.1208040],
          [-2.3651160, 55.1208190],
          [-2.3652020, 55.1208300],
          [-2.3652900, 55.1208420],
          [-2.3653740, 55.1208530],
          [-2.3654590, 55.1208610],
          [-2.3655390, 55.1208690],
          [-2.3656200, 55.1208800],
          [-2.3657000, 55.1208910],
          [-2.3657830, 55.1208990],
          [-2.3658650, 55.1209100],
          [-2.3659500, 55.1209220],
          [-2.3660410, 55.1209330],
          [-2.3661330, 55.1209450],
          [-2.3662270, 55.1209560],
          [-2.3663200, 55.1209680],
          [-2.3664120, 55.1209750],
          [-2.3665020, 55.1209830],
          [-2.3666010, 55.1209980],
          [-2.3666970, 55.1210100],
          [-2.3667870, 55.1210210],
          [-2.3668760, 55.1210320],
          [-2.3669700, 55.1210520],
          [-2.3670630, 55.1210710],
          [-2.3671540, 55.1210900],
          [-2.3672430, 55.1211010],
          [-2.3673410, 55.1211130],
          [-2.3674370, 55.1211320],
          [-2.3675330, 55.1211430],
          [-2.3676250, 55.1211580],
          [-2.3677170, 55.1211740],
          [-2.3678070, 55.1211780],
          [-2.3679010, 55.1211810],
          [-2.3679870, 55.1211960],
          [-2.3680720, 55.1211970],
          [-2.3681610, 55.1212040],
          [-2.3682440, 55.1212120],
          [-2.3683310, 55.1212200],
          [-2.3684170, 55.1212230],
          [-2.3685100, 55.1212200],
          [-2.3686050, 55.1212080],
          [-2.3686970, 55.1211970],
          [-2.3687770, 55.1211680],
          [-2.3688470, 55.1211320],
          [-2.3689060, 55.1210940],
          [-2.3689560, 55.1210560],
          [-2.3690010, 55.1210180],
          [-2.3690460, 55.1209730],
          [-2.3690890, 55.1209300],
          [-2.3691310, 55.1208840],
          [-2.3691730, 55.1208390],
          [-2.3692110, 55.1207890],
          [-2.3692480, 55.1207440],
          [-2.3692840, 55.1206970],
          [-2.3693190, 55.1206440],
          [-2.3693560, 55.1205950],
          [-2.3693930, 55.1205490],
          [-2.3694280, 55.1205030],
          [-2.3694660, 55.1204570],
          [-2.3695040, 55.1204120],
          [-2.3695430, 55.1203620],
          [-2.3695810, 55.1203160],
          [-2.3696230, 55.1202670],
          [-2.3696620, 55.1202170],
          [-2.3697020, 55.1201680],
          [-2.3697450, 55.1201180],
          [-2.3697890, 55.1200690],
          [-2.3698360, 55.1200180],
          [-2.3698770, 55.1199690],
          [-2.3699210, 55.1199160],
          [-2.3699620, 55.1198670],
          [-2.3700060, 55.1198130],
          [-2.3700510, 55.1197590],
          [-2.3701010, 55.1197060],
          [-2.3701550, 55.1196500],
          [-2.3702110, 55.1195920],
          [-2.3702590, 55.1195340],
          [-2.3703100, 55.1194710],
          [-2.3703650, 55.1194020],
          [-2.3704240, 55.1193330],
          [-2.3704890, 55.1192570],
          [-2.3705550, 55.1191770],
          [-2.3706240, 55.1191000],
          [-2.3706940, 55.1190310],
          [-2.3707630, 55.1189630],
          [-2.3708320, 55.1188980],
          [-2.3709020, 55.1188410],
          [-2.3709700, 55.1187870],
          [-2.3710370, 55.1187410],
          [-2.3711000, 55.1186990],
          [-2.3711640, 55.1186570],
          [-2.3712260, 55.1186220],
          [-2.3712840, 55.1185880],
          [-2.3713400, 55.1185620],
          [-2.3714000, 55.1185350],
          [-2.3714620, 55.1185080],
          [-2.3715210, 55.1184890],
          [-2.3715770, 55.1184660],
          [-2.3716360, 55.1184430],
          [-2.3716940, 55.1184200],
          [-2.3717520, 55.1183970],
          [-2.3718100, 55.1183820],
          [-2.3718690, 55.1183660],
          [-2.3719270, 55.1183480],
          [-2.3719790, 55.1183330],
          [-2.3720320, 55.1183170],
          [-2.3720810, 55.1183050],
          [-2.3721290, 55.1182940],
          [-2.3721810, 55.1182790],
          [-2.3722360, 55.1182670],
          [-2.3722900, 55.1182560],
          [-2.3723420, 55.1182410],
          [-2.3723970, 55.1182250],
          [-2.3724490, 55.1182100],
          [-2.3724980, 55.1181990],
          [-2.3725510, 55.1181800],
          [-2.3726030, 55.1181640],
          [-2.3726510, 55.1181450],
          [-2.3727040, 55.1181340],
          [-2.3727560, 55.1181190],
          [-2.3728140, 55.1181070],
          [-2.3728790, 55.1180990],
          [-2.3729520, 55.1180920],
          [-2.3730200, 55.1180840],
          [-2.3730830, 55.1180800],
          [-2.3731470, 55.1180760],
          [-2.3732070, 55.1180760],
          [-2.3732690, 55.1180730],
          [-2.3733410, 55.1180730],
          [-2.3734120, 55.1180730],
          [-2.3734870, 55.1180730],
          [-2.3735600, 55.1180760],
          [-2.3736330, 55.1180760],
          [-2.3737070, 55.1180800],
          [-2.3737790, 55.1180800],
          [-2.3738570, 55.1180800],
          [-2.3739350, 55.1180840],
          [-2.3740220, 55.1180800],
          [-2.3741060, 55.1180800],
          [-2.3741940, 55.1180800],
          [-2.3742840, 55.1180800],
          [-2.3743740, 55.1180800],
          [-2.3744620, 55.1180800],
          [-2.3745460, 55.1180800],
          [-2.3746310, 55.1180800],
          [-2.3747160, 55.1180730],
          [-2.3748030, 55.1180610],
          [-2.3748900, 55.1180500],
          [-2.3749730, 55.1180420],
          [-2.3750570, 55.1180310],
          [-2.3751380, 55.1180190],
          [-2.3752160, 55.1180040],
          [-2.3752940, 55.1179960],
          [-2.3753770, 55.1179850],
          [-2.3754610, 55.1179770],
          [-2.3755420, 55.1179620],
          [-2.3756270, 55.1179470],
          [-2.3757100, 55.1179360],
          [-2.3758010, 55.1179280],
          [-2.3758900, 55.1179160],
          [-2.3759830, 55.1179050],
          [-2.3760720, 55.1178940],
          [-2.3761620, 55.1178820],
          [-2.3762520, 55.1178740],
          [-2.3763440, 55.1178630],
          [-2.3764330, 55.1178550],
          [-2.3765240, 55.1178400],
          [-2.3766140, 55.1178290],
          [-2.3767060, 55.1178210],
          [-2.3767970, 55.1178090],
          [-2.3768850, 55.1178020],
          [-2.3769710, 55.1177940],
          [-2.3770590, 55.1177830],
          [-2.3771420, 55.1177720],
          [-2.3772270, 55.1177600],
          [-2.3773090, 55.1177480],
          [-2.3773870, 55.1177370],
          [-2.3774630, 55.1177250],
          [-2.3775370, 55.1177180],
          [-2.3776150, 55.1177070],
          [-2.3776880, 55.1176950],
          [-2.3777620, 55.1176840],
          [-2.3778290, 55.1176690],
          [-2.3778950, 55.1176570],
          [-2.3779580, 55.1176490],
          [-2.3780230, 55.1176450],
          [-2.3780820, 55.1176380],
          [-2.3781440, 55.1176300],
          [-2.3782070, 55.1176260],
          [-2.3782640, 55.1176190],
          [-2.3783240, 55.1176070],
          [-2.3783820, 55.1176000],
          [-2.3784420, 55.1175920],
          [-2.3785000, 55.1175880],
          [-2.3785590, 55.1175810],
          [-2.3786220, 55.1175770],
          [-2.3786810, 55.1175730],
          [-2.3787400, 55.1175690],
          [-2.3788000, 55.1175650],
          [-2.3788570, 55.1175580],
          [-2.3789140, 55.1175540],
          [-2.3789670, 55.1175500],
          [-2.3790220, 55.1175460],
          [-2.3790780, 55.1175420],
          [-2.3791380, 55.1175420],
          [-2.3791980, 55.1175380],
          [-2.3792570, 55.1175350],
          [-2.3793190, 55.1175310],
          [-2.3793810, 55.1175270],
          [-2.3794430, 55.1175230],
          [-2.3795050, 55.1175230],
          [-2.3795670, 55.1175190],
          [-2.3796290, 55.1175160],
          [-2.3796900, 55.1175160],
          [-2.3797550, 55.1175120],
          [-2.3798190, 55.1175120],
          [-2.3798810, 55.1175080],
          [-2.3799440, 55.1175040],
          [-2.3800070, 55.1175000],
          [-2.3800670, 55.1175000],
          [-2.3801310, 55.1175000],
          [-2.3801930, 55.1175040],
          [-2.3802540, 55.1175040],
          [-2.3803160, 55.1175000],
          [-2.3803750, 55.1175000],
          [-2.3804370, 55.1175000],
          [-2.3804990, 55.1175000],
          [-2.3805610, 55.1175000],
          [-2.3806260, 55.1175000],
          [-2.3806910, 55.1175000],
          [-2.3807560, 55.1175000],
          [-2.3808220, 55.1175040],
          [-2.3808910, 55.1175040],
          [-2.3809560, 55.1175080],
          [-2.3810270, 55.1175120],
          [-2.3810940, 55.1175120],
          [-2.3811600, 55.1175120],
          [-2.3812300, 55.1175120],
          [-2.3813030, 55.1175120],
          [-2.3813760, 55.1175160],
          [-2.3814500, 55.1175190],
          [-2.3815270, 55.1175230],
          [-2.3816070, 55.1175230],
          [-2.3816840, 55.1175310],
          [-2.3817600, 55.1175340],
          [-2.3818460, 55.1175380],
          [-2.3819280, 55.1175420],
          [-2.3820070, 55.1175460],
          [-2.3820890, 55.1175500],
          [-2.3821700, 55.1175500],
          [-2.3822490, 55.1175540],
          [-2.3823270, 55.1175570],
          [-2.3824060, 55.1175580],
          [-2.3824870, 55.1175610],
          [-2.3825650, 55.1175650],
          [-2.3826470, 55.1175650],
          [-2.3827280, 55.1175690],
          [-2.3828060, 55.1175690],
          [-2.3828870, 55.1175760],
          [-2.3829640, 55.1175770],
          [-2.3830390, 55.1175800],
          [-2.3831150, 55.1175840],
          [-2.3831900, 55.1175880],
          [-2.3832700, 55.1175920],
          [-2.3833480, 55.1175920],
          [-2.3834290, 55.1175880],
          [-2.3835090, 55.1175800],
          [-2.3835870, 55.1175770],
          [-2.3836690, 55.1175770],
          [-2.3837460, 55.1175730],
          [-2.3838240, 55.1175650],
          [-2.3838980, 55.1175580],
          [-2.3839730, 55.1175460],
          [-2.3840480, 55.1175350],
          [-2.3841240, 55.1175240],
          [-2.3841950, 55.1175120],
          [-2.3842650, 55.1175010],
          [-2.3843320, 55.1174850],
          [-2.3844010, 55.1174740],
          [-2.3844700, 55.1174660],
          [-2.3845380, 55.1174590],
          [-2.3846050, 55.1174470],
          [-2.3846740, 55.1174390],
          [-2.3847410, 55.1174280],
          [-2.3848090, 55.1174170],
          [-2.3848790, 55.1174050],
          [-2.3849490, 55.1173970],
          [-2.3850200, 55.1173860],
          [-2.3850920, 55.1173710],
          [-2.3851630, 55.1173590],
          [-2.3852370, 55.1173480],
          [-2.3853080, 55.1173330],
          [-2.3853770, 55.1173210],
          [-2.3854450, 55.1173060],
          [-2.3855150, 55.1172880],
          [-2.3855870, 55.1172650],
          [-2.3856640, 55.1172380],
          [-2.3857340, 55.1172120],
          [-2.3858110, 55.1171730],
          [-2.3858870, 55.1171330],
          [-2.3859650, 55.1170890],
          [-2.3860420, 55.1170510],
          [-2.3861200, 55.1170090],
          [-2.3861960, 55.1169640],
          [-2.3862710, 55.1169210],
          [-2.3863400, 55.1168830],
          [-2.3864120, 55.1168450],
          [-2.3864760, 55.1168080],
          [-2.3865450, 55.1167690],
          [-2.3866120, 55.1167280],
          [-2.3866740, 55.1166920],
          [-2.3867290, 55.1166600],
          [-2.3867870, 55.1166200],
          [-2.3868440, 55.1165820],
          [-2.3868960, 55.1165410],
          [-2.3869530, 55.1164980],
          [-2.3870080, 55.1164600],
          [-2.3870570, 55.1164140],
          [-2.3871100, 55.1163680],
          [-2.3871700, 55.1163190],
          [-2.3872240, 55.1162730],
          [-2.3872740, 55.1162280],
          [-2.3873290, 55.1161740],
          [-2.3873850, 55.1161250],
          [-2.3874420, 55.1160830],
          [-2.3874980, 55.1160350],
          [-2.3875600, 55.1159790],
          [-2.3876220, 55.1159260],
          [-2.3876810, 55.1158760],
          [-2.3877350, 55.1158240],
          [-2.3877890, 55.1157710],
          [-2.3878470, 55.1157200],
          [-2.3879020, 55.1156630],
          [-2.3879560, 55.1156120],
          [-2.3880200, 55.1155490],
          [-2.3880770, 55.1154920],
          [-2.3881310, 55.1154340],
          [-2.3881830, 55.1153810],
          [-2.3882370, 55.1153240],
          [-2.3882880, 55.1152750],
          [-2.3883390, 55.1152170],
          [-2.3883900, 55.1151520],
          [-2.3884470, 55.1150910],
          [-2.3885030, 55.1150320],
          [-2.3885640, 55.1149700],
          [-2.3886280, 55.1149040],
          [-2.3886890, 55.1148430],
          [-2.3887550, 55.1147800],
          [-2.3888200, 55.1147210],
          [-2.3888900, 55.1146580],
          [-2.3889640, 55.1145920],
          [-2.3890310, 55.1145230],
          [-2.3891080, 55.1144470],
          [-2.3891800, 55.1143750],
          [-2.3892710, 55.1142900],
          [-2.3893370, 55.1142310],
          [-2.3894370, 55.1141450],
          [-2.3895140, 55.1140770],
          [-2.3895970, 55.1140070],
          [-2.3896830, 55.1139390],
          [-2.3897700, 55.1138700],
          [-2.3898480, 55.1138050],
          [-2.3899370, 55.1137360],
          [-2.3900160, 55.1136700],
          [-2.3901020, 55.1135990],
          [-2.3901850, 55.1135330],
          [-2.3902750, 55.1134660],
          [-2.3903630, 55.1134010],
          [-2.3904510, 55.1133450],
          [-2.3905390, 55.1132910],
          [-2.3906300, 55.1132400],
          [-2.3907130, 55.1131950],
          [-2.3907910, 55.1131560],
          [-2.3908670, 55.1131230],
          [-2.3909460, 55.1130920],
          [-2.3910190, 55.1130600],
          [-2.3910880, 55.1130300],
          [-2.3911520, 55.1130020],
          [-2.3912240, 55.1129730],
          [-2.3912850, 55.1129500],
          [-2.3913490, 55.1129270],
          [-2.3914130, 55.1129080],
          [-2.3914770, 55.1128890],
          [-2.3915470, 55.1128730],
          [-2.3916150, 55.1128590],
          [-2.3916840, 55.1128400],
          [-2.3917530, 55.1128310],
          [-2.3918200, 55.1128240],
          [-2.3918870, 55.1128090],
          [-2.3919580, 55.1127970],
          [-2.3920330, 55.1127820],
          [-2.3921060, 55.1127740],
          [-2.3921800, 55.1127700],
          [-2.3922490, 55.1127630],
          [-2.3923120, 55.1127590],
          [-2.3923830, 55.1127510],
          [-2.3924470, 55.1127470],
          [-2.3925120, 55.1127440],
          [-2.3925830, 55.1127430],
          [-2.3926570, 55.1127430],
          [-2.3927290, 55.1127430],
          [-2.3928080, 55.1127400],
          [-2.3928840, 55.1127360],
          [-2.3929610, 55.1127360],
          [-2.3930500, 55.1127360],
          [-2.3931200, 55.1127360],
          [-2.3932340, 55.1127360],
          [-2.3933250, 55.1127390],
          [-2.3934270, 55.1127400],
          [-2.3935170, 55.1127430],
          [-2.3936180, 55.1127430],
          [-2.3937150, 55.1127400],
          [-2.3938140, 55.1127430],
          [-2.3939160, 55.1127400],
          [-2.3940230, 55.1127320],
          [-2.3941270, 55.1127280],
          [-2.3942320, 55.1127240],
          [-2.3943480, 55.1127210],
          [-2.3944730, 55.1127130],
          [-2.3945820, 55.1127130],
          [-2.3946990, 55.1127170],
          [-2.3948130, 55.1127170],
          [-2.3949240, 55.1127090],
          [-2.3950350, 55.1127090],
          [-2.3951570, 55.1127050],
          [-2.3952800, 55.1126940],
          [-2.3954170, 55.1126790],
          [-2.3955460, 55.1126680],
          [-2.3956900, 55.1126560],
          [-2.3958290, 55.1126370],
          [-2.3959710, 55.1126140],
          [-2.3961030, 55.1125890],
          [-2.3962500, 55.1125500],
          [-2.3963810, 55.1125100],
          [-2.3965310, 55.1124640],
          [-2.3966780, 55.1124190],
          [-2.3968390, 55.1123640],
          [-2.3969960, 55.1123080],
          [-2.3971560, 55.1122390],
          [-2.3973120, 55.1121630],
          [-2.3974740, 55.1120870],
          [-2.3976450, 55.1120040],
          [-2.3978150, 55.1119160],
          [-2.3979810, 55.1118240],
          [-2.3981800, 55.1117200],
          [-2.3983750, 55.1116280],
          [-2.3985730, 55.1115360],
          [-2.3987720, 55.1114460],
          [-2.3989640, 55.1113610],
          [-2.3991540, 55.1112830],
          [-2.3993440, 55.1111930],
          [-2.3995300, 55.1111160],
          [-2.3997220, 55.1110410],
          [-2.3999120, 55.1109690],
          [-2.4000800, 55.1109010],
          [-2.4002700, 55.1108220],
          [-2.4004330, 55.1107550],
          [-2.4006120, 55.1106940],
          [-2.4007840, 55.1106300],
          [-2.4009790, 55.1105690],
          [-2.4011390, 55.1105040],
          [-2.4012940, 55.1104430],
          [-2.4013930, 55.1104050],
          [-2.4015360, 55.1103520],
          [-2.4016390, 55.1103160],
          [-2.4017580, 55.1102690],
          [-2.4018660, 55.1102270],
          [-2.4019750, 55.1101880],
          [-2.4020760, 55.1101560],
          [-2.4021910, 55.1101160],
          [-2.4023000, 55.1100780],
          [-2.4024090, 55.1100400],
          [-2.4025140, 55.1100000],
          [-2.4026300, 55.1099520],
          [-2.4027300, 55.1099200],
          [-2.4028530, 55.1098790],
          [-2.4029670, 55.1098380],
          [-2.4030800, 55.1098000],
          [-2.4031940, 55.1097580],
          [-2.4033090, 55.1097080],
          [-2.4034080, 55.1096690],
          [-2.4035210, 55.1096240],
          [-2.4036230, 55.1095830],
          [-2.4037250, 55.1095440],
          [-2.4038250, 55.1095070],
          [-2.4039300, 55.1094670],
          [-2.4040270, 55.1094240],
          [-2.4041310, 55.1093800],
          [-2.4042250, 55.1093400],
          [-2.4043240, 55.1092880],
          [-2.4044120, 55.1092430],
          [-2.4044970, 55.1092010],
          [-2.4045820, 55.1091540],
          [-2.4046750, 55.1091090],
          [-2.4047560, 55.1090650],
          [-2.4048410, 55.1090180],
          [-2.4049160, 55.1089780],
          [-2.4049970, 55.1089300],
          [-2.4050710, 55.1088900],
          [-2.4051550, 55.1088420],
          [-2.4052280, 55.1088030],
          [-2.4053080, 55.1087580],
          [-2.4053780, 55.1087180],
          [-2.4054570, 55.1086780],
          [-2.4055270, 55.1086420],
          [-2.4056040, 55.1086050],
          [-2.4056730, 55.1085670],
          [-2.4057500, 55.1085290],
          [-2.4058250, 55.1084950],
          [-2.4059030, 55.1084610],
          [-2.4059750, 55.1084280],
          [-2.4060560, 55.1083960],
          [-2.4061330, 55.1083630],
          [-2.4062200, 55.1083270],
          [-2.4062930, 55.1082990],
          [-2.4063800, 55.1082690],
          [-2.4064560, 55.1082430],
          [-2.4065300, 55.1082120],
          [-2.4065960, 55.1081860],
          [-2.4066610, 55.1081660],
          [-2.4067230, 55.1081400],
          [-2.4067850, 55.1081150],
          [-2.4068600, 55.1080900],
          [-2.4069260, 55.1080680],
          [-2.4069990, 55.1080450],
          [-2.4070750, 55.1080300],
          [-2.4071750, 55.1080130],
          [-2.4072760, 55.1079920],
          [-2.4073990, 55.1079680],
          [-2.4075280, 55.1079390],
          [-2.4076800, 55.1079070],
          [-2.4078280, 55.1078730],
          [-2.4079810, 55.1078400],
          [-2.4081360, 55.1078060],
          [-2.4082990, 55.1077680],
          [-2.4084650, 55.1077340],
          [-2.4086360, 55.1076980],
          [-2.4087970, 55.1076690],
          [-2.4089760, 55.1076370],
          [-2.4091380, 55.1076050],
          [-2.4093120, 55.1075700],
          [-2.4094820, 55.1075360],
          [-2.4096590, 55.1075020],
          [-2.4098440, 55.1074630],
          [-2.4100280, 55.1074330],
          [-2.4102260, 55.1073980],
          [-2.4104060, 55.1073570],
          [-2.4105900, 55.1073150],
          [-2.4107690, 55.1072730],
          [-2.4109150, 55.1072280],
          [-2.4110660, 55.1071760],
          [-2.4112250, 55.1071320],
          [-2.4113770, 55.1070910],
          [-2.4115640, 55.1070420],
          [-2.4117360, 55.1070020],
          [-2.4119070, 55.1069700],
          [-2.4120830, 55.1069390],
          [-2.4122420, 55.1069050],
          [-2.4123930, 55.1068740],
          [-2.4125470, 55.1068540],
          [-2.4126880, 55.1068320],
          [-2.4128390, 55.1068010],
          [-2.4129760, 55.1067690],
          [-2.4131320, 55.1067220],
          [-2.4132750, 55.1066720],
          [-2.4134220, 55.1066040],
          [-2.4135710, 55.1065290],
          [-2.4137150, 55.1064520],
          [-2.4138380, 55.1063750],
          [-2.4139650, 55.1062840],
          [-2.4140910, 55.1062010],
          [-2.4142230, 55.1061160],
          [-2.4143580, 55.1060330],
          [-2.4144920, 55.1059540],
          [-2.4146100, 55.1058810],
          [-2.4147410, 55.1057970],
          [-2.4148520, 55.1057220],
          [-2.4149660, 55.1056580],
          [-2.4150810, 55.1055860],
          [-2.4151940, 55.1055130],
          [-2.4153080, 55.1054390],
          [-2.4154180, 55.1053680],
          [-2.4155260, 55.1053030],
          [-2.4156350, 55.1052370],
          [-2.4157440, 55.1051730],
          [-2.4158360, 55.1051160],
          [-2.4159370, 55.1050510],
          [-2.4160170, 55.1050010],
          [-2.4161190, 55.1049390],
          [-2.4161990, 55.1048910],
          [-2.4162800, 55.1048400],
          [-2.4163550, 55.1047960],
          [-2.4164330, 55.1047490],
          [-2.4164990, 55.1047110],
          [-2.4165800, 55.1046610],
          [-2.4166480, 55.1046210],
          [-2.4167200, 55.1045730],
          [-2.4167850, 55.1045320],
          [-2.4168640, 55.1044820],
          [-2.4169330, 55.1044380],
          [-2.4170070, 55.1043940],
          [-2.4170730, 55.1043510],
          [-2.4171450, 55.1043060],
          [-2.4172090, 55.1042660],
          [-2.4172780, 55.1042260],
          [-2.4173540, 55.1041940],
          [-2.4174460, 55.1041720],
          [-2.4175300, 55.1041720],
          [-2.4176200, 55.1041830],
          [-2.4176960, 55.1042040],
          [-2.4177770, 55.1042360],
          [-2.4178430, 55.1042720],
          [-2.4179190, 55.1043080],
          [-2.4179900, 55.1043450],
          [-2.4180700, 55.1043880],
          [-2.4181470, 55.1044290],
          [-2.4182310, 55.1044720],
          [-2.4183060, 55.1045110],
          [-2.4183950, 55.1045560],
          [-2.4184770, 55.1045960],
          [-2.4185660, 55.1046440],
          [-2.4186490, 55.1046840],
          [-2.4187430, 55.1047280],
          [-2.4188250, 55.1047670],
          [-2.4189160, 55.1048110],
          [-2.4189960, 55.1048510],
          [-2.4190850, 55.1048950],
          [-2.4191640, 55.1049340],
          [-2.4192570, 55.1049790],
          [-2.4193350, 55.1050180],
          [-2.4194300, 55.1050630],
          [-2.4195100, 55.1051040],
          [-2.4196100, 55.1051480],
          [-2.4196930, 55.1051910],
          [-2.4197850, 55.1052390],
          [-2.4198650, 55.1052800],
          [-2.4199630, 55.1053270],
          [-2.4200440, 55.1053680],
          [-2.4201430, 55.1054140],
          [-2.4202270, 55.1054540],
          [-2.4203200, 55.1055060],
          [-2.4204000, 55.1055440],
          [-2.4204960, 55.1055940],
          [-2.4205810, 55.1056340],
          [-2.4206700, 55.1056780],
          [-2.4207490, 55.1057160],
          [-2.4208410, 55.1057650],
          [-2.4209230, 55.1058060],
          [-2.4210050, 55.1058490],
          [-2.4210760, 55.1058840],
          [-2.4211610, 55.1059260],
          [-2.4212350, 55.1059650],
          [-2.4213150, 55.1060090],
          [-2.4213830, 55.1060470],
          [-2.4214640, 55.1060930],
          [-2.4215320, 55.1061290],
          [-2.4216090, 55.1061770],
          [-2.4216720, 55.1062150],
          [-2.4217490, 55.1062690],
          [-2.4218120, 55.1063120],
          [-2.4218820, 55.1063640],
          [-2.4219410, 55.1064110],
          [-2.4220040, 55.1064670],
          [-2.4220600, 55.1065160],
          [-2.4221270, 55.1065780],
          [-2.4221830, 55.1066300],
          [-2.4222550, 55.1066960],
          [-2.4223140, 55.1067530],
          [-2.4223900, 55.1068290],
          [-2.4224550, 55.1069020],
          [-2.4225300, 55.1069810],
          [-2.4225920, 55.1070570],
          [-2.4226630, 55.1071440],
          [-2.4227380, 55.1072290],
          [-2.4228110, 55.1073110],
          [-2.4228660, 55.1074010],
          [-2.4229090, 55.1074900],
          [-2.4229570, 55.1075780],
          [-2.4230130, 55.1076620],
          [-2.4230690, 55.1077440],
          [-2.4231100, 55.1078140],
          [-2.4231590, 55.1078930],
          [-2.4231980, 55.1079580],
          [-2.4232410, 55.1080380],
          [-2.4232760, 55.1081040],
          [-2.4233220, 55.1081830],
          [-2.4233650, 55.1082460],
          [-2.4234120, 55.1083200],
          [-2.4234500, 55.1083840],
          [-2.4234940, 55.1084620],
          [-2.4235330, 55.1085260],
          [-2.4235770, 55.1086060],
          [-2.4236150, 55.1086750],
          [-2.4236610, 55.1087520],
          [-2.4236960, 55.1088150],
          [-2.4237410, 55.1088970],
          [-2.4237800, 55.1089610],
          [-2.4238240, 55.1090370],
          [-2.4238600, 55.1091050],
          [-2.4238990, 55.1091790],
          [-2.4239320, 55.1092440],
          [-2.4239690, 55.1093240],
          [-2.4239990, 55.1093910],
          [-2.4240300, 55.1094670],
          [-2.4240570, 55.1095320],
          [-2.4240890, 55.1096140],
          [-2.4241160, 55.1096810],
          [-2.4241420, 55.1097490],
          [-2.4241740, 55.1098260],
          [-2.4242030, 55.1099000],
          [-2.4242260, 55.1099720],
          [-2.4242480, 55.1100490],
          [-2.4242700, 55.1101180],
          [-2.4242890, 55.1101920],
          [-2.4243110, 55.1102660],
          [-2.4243340, 55.1103410],
          [-2.4243560, 55.1104220],
          [-2.4243750, 55.1104990],
          [-2.4243910, 55.1105780],
          [-2.4244110, 55.1106620],
          [-2.4244280, 55.1107390],
          [-2.4244430, 55.1108190],
          [-2.4244560, 55.1108980],
          [-2.4244730, 55.1109780],
          [-2.4244910, 55.1110590],
          [-2.4245110, 55.1111440],
          [-2.4245300, 55.1112280],
          [-2.4245460, 55.1113090],
          [-2.4245700, 55.1113950],
          [-2.4245930, 55.1114790],
          [-2.4246180, 55.1115630],
          [-2.4246430, 55.1116470],
          [-2.4246710, 55.1117350],
          [-2.4246960, 55.1118190],
          [-2.4247230, 55.1119100],
          [-2.4247560, 55.1120010],
          [-2.4247910, 55.1120960],
          [-2.4248210, 55.1121950],
          [-2.4248610, 55.1122990],
          [-2.4248890, 55.1123980],
          [-2.4249190, 55.1125040],
          [-2.4249450, 55.1126080],
          [-2.4249780, 55.1127260],
          [-2.4250210, 55.1128330],
          [-2.4250230, 55.1129430],
          [-2.4250250, 55.1130620],
          [-2.4250200, 55.1131720],
          [-2.4250050, 55.1132750],
          [-2.4249840, 55.1133820],
          [-2.4249960, 55.1134880],
          [-2.4250260, 55.1135880],
          [-2.4250730, 55.1136960],
          [-2.4251390, 55.1137950],
          [-2.4252370, 55.1138870],
          [-2.4253480, 55.1139780],
          [-2.4254670, 55.1140700],
          [-2.4255860, 55.1141540],
          [-2.4257170, 55.1142530],
          [-2.4258370, 55.1143400],
          [-2.4259550, 55.1144290],
          [-2.4260840, 55.1145180],
          [-2.4262080, 55.1146080],
          [-2.4263110, 55.1146830],
          [-2.4263960, 55.1147630],
          [-2.4264670, 55.1148400],
          [-2.4265260, 55.1149200],
          [-2.4265480, 55.1150120],
          [-2.4265560, 55.1150920],
          [-2.4265680, 55.1151720],
          [-2.4265700, 55.1152480],
          [-2.4265730, 55.1153240],
          [-2.4265780, 55.1154010],
          [-2.4265830, 55.1154690],
          [-2.4265880, 55.1155380],
          [-2.4265900, 55.1156070],
          [-2.4265920, 55.1156720],
          [-2.4265970, 55.1157410],
          [-2.4265980, 55.1158060],
          [-2.4265980, 55.1158690],
          [-2.4265980, 55.1159350],
          [-2.4266000, 55.1159960],
          [-2.4266050, 55.1160600],
          [-2.4266120, 55.1161220],
          [-2.4266180, 55.1161790],
          [-2.4266230, 55.1162370],
          [-2.4266270, 55.1162940],
          [-2.4266300, 55.1163470],
          [-2.4266350, 55.1164040],
          [-2.4266380, 55.1164610],
          [-2.4266430, 55.1165150],
          [-2.4266470, 55.1165680],
          [-2.4266500, 55.1166260],
          [-2.4266530, 55.1166790],
          [-2.4266550, 55.1167330],
          [-2.4266600, 55.1167900],
          [-2.4266630, 55.1168430],
          [-2.4266650, 55.1168970],
          [-2.4266670, 55.1169500],
          [-2.4266650, 55.1170030],
          [-2.4266630, 55.1170570],
          [-2.4266620, 55.1171100],
          [-2.4266600, 55.1171600],
          [-2.4266580, 55.1172130],
          [-2.4266570, 55.1172630],
          [-2.4266550, 55.1173160],
          [-2.4266530, 55.1173660],
          [-2.4266500, 55.1174190],
          [-2.4266480, 55.1174690],
          [-2.4266470, 55.1175180],
          [-2.4266450, 55.1175720],
          [-2.4266420, 55.1176250],
          [-2.4266420, 55.1176750],
          [-2.4266400, 55.1177280],
          [-2.4266350, 55.1177820],
          [-2.4266330, 55.1178340],
          [-2.4266330, 55.1178890],
          [-2.4266320, 55.1179460],
          [-2.4266300, 55.1179970],
          [-2.4266270, 55.1180560],
          [-2.4266270, 55.1181130],
          [-2.4266250, 55.1181700],
          [-2.4266230, 55.1182310],
          [-2.4266180, 55.1182890],
          [-2.4266130, 55.1183460],
          [-2.4266120, 55.1184030],
          [-2.4266070, 55.1184600],
          [-2.4266000, 55.1185140],
          [-2.4265980, 55.1185670],
          [-2.4265970, 55.1186210],
          [-2.4265930, 55.1186740],
          [-2.4265900, 55.1187240],
          [-2.4265870, 55.1187730],
          [-2.4265850, 55.1188190],
          [-2.4265830, 55.1188650],
          [-2.4265780, 55.1189110],
          [-2.4265770, 55.1189610],
          [-2.4265730, 55.1190020],
          [-2.4265700, 55.1190520],
          [-2.4265680, 55.1190930],
          [-2.4265680, 55.1191430],
          [-2.4265650, 55.1191890],
          [-2.4265620, 55.1192310],
          [-2.4265600, 55.1192760],
          [-2.4265570, 55.1193230],
          [-2.4265550, 55.1193720],
          [-2.4265530, 55.1194220],
          [-2.4265500, 55.1194670],
          [-2.4265470, 55.1195140],
          [-2.4265430, 55.1195590],
          [-2.4265420, 55.1196090],
          [-2.4265420, 55.1196510],
          [-2.4265400, 55.1197000],
          [-2.4265380, 55.1197430],
          [-2.4265330, 55.1197880],
          [-2.4265300, 55.1198340],
          [-2.4265280, 55.1198760],
          [-2.4265250, 55.1199220],
          [-2.4265220, 55.1199670],
          [-2.4265170, 55.1200130],
          [-2.4265170, 55.1200590],
          [-2.4265150, 55.1201050],
          [-2.4265130, 55.1201500],
          [-2.4265080, 55.1202000],
          [-2.4265020, 55.1202460],
          [-2.4264950, 55.1202990],
          [-2.4264900, 55.1203530],
          [-2.4264840, 55.1204060],
          [-2.4264770, 55.1204630],
          [-2.4264720, 55.1205200],
          [-2.4264640, 55.1205810],
          [-2.4264540, 55.1206450],
          [-2.4264440, 55.1207070],
          [-2.4264320, 55.1207690],
          [-2.4264240, 55.1208260],
          [-2.4264180, 55.1208830],
          [-2.4264100, 55.1209370],
          [-2.4264000, 55.1209860],
          [-2.4263930, 55.1210360],
          [-2.4263800, 55.1210820],
          [-2.4263670, 55.1211310],
          [-2.4263600, 55.1211730],
          [-2.4263550, 55.1212160],
          [-2.4263520, 55.1212650],
          [-2.4263440, 55.1213100],
          [-2.4263350, 55.1213600],
          [-2.4263290, 55.1214060],
          [-2.4263200, 55.1214590],
          [-2.4263100, 55.1215160],
          [-2.4263020, 55.1215700],
          [-2.4262940, 55.1216310],
          [-2.4262850, 55.1216910],
          [-2.4262750, 55.1217490],
          [-2.4262670, 55.1218060],
          [-2.4262550, 55.1218630],
          [-2.4262450, 55.1219200],
          [-2.4262390, 55.1219730],
          [-2.4262330, 55.1220310],
          [-2.4262280, 55.1220840],
          [-2.4262250, 55.1221350],
          [-2.4262200, 55.1221910],
          [-2.4262120, 55.1222450],
          [-2.4262000, 55.1222980],
          [-2.4261900, 55.1223550],
          [-2.4261790, 55.1224080],
          [-2.4261690, 55.1224620],
          [-2.4261570, 55.1225160],
          [-2.4261440, 55.1225690],
          [-2.4261290, 55.1226180],
          [-2.4261120, 55.1226680],
          [-2.4260950, 55.1227180],
          [-2.4260790, 55.1227710],
          [-2.4260590, 55.1228210],
          [-2.4260350, 55.1228750],
          [-2.4260090, 55.1229240],
          [-2.4259810, 55.1229730],
          [-2.4259490, 55.1230270],
          [-2.4259160, 55.1230760],
          [-2.4258780, 55.1231300],
          [-2.4258340, 55.1231750],
          [-2.4257860, 55.1232260],
          [-2.4257310, 55.1232710],
          [-2.4256700, 55.1233190],
          [-2.4255990, 55.1233630],
          [-2.4255250, 55.1234050],
          [-2.4254460, 55.1234390],
          [-2.4253590, 55.1234700],
          [-2.4252650, 55.1234970],
          [-2.4251610, 55.1235200],
          [-2.4250480, 55.1235350],
          [-2.4249250, 55.1235430],
          [-2.4247900, 55.1235500],
          [-2.4246440, 55.1235540],
          [-2.4244870, 55.1235620],
          [-2.4243120, 55.1235660],
          [-2.4241340, 55.1235690],
          [-2.4239660, 55.1235810],
          [-2.4238160, 55.1236030],
          [-2.4236800, 55.1236560],
          [-2.4235590, 55.1237180],
          [-2.4234650, 55.1237970],
          [-2.4233920, 55.1238800],
          [-2.4233390, 55.1239640],
          [-2.4233020, 55.1240480],
          [-2.4232760, 55.1241250],
          [-2.4232540, 55.1242050],
          [-2.4232370, 55.1242740],
          [-2.4232220, 55.1243460],
          [-2.4232050, 55.1244120],
          [-2.4231900, 55.1244760],
          [-2.4231820, 55.1245370],
          [-2.4231850, 55.1245950],
          [-2.4232040, 55.1246430],
          [-2.4232340, 55.1246860],
          [-2.4232670, 55.1247170],
          [-2.4233060, 55.1247400],
          [-2.4233400, 55.1247590],
          [-2.4233680, 55.1247750],
          [-2.4233930, 55.1247900],
          [-2.4234200, 55.1248050],
          [-2.4234430, 55.1248200],
          [-2.4234710, 55.1248280],
          [-2.4235030, 55.1248400],
          [-2.4235380, 55.1248430],
          [-2.4235740, 55.1248470],
          [-2.4236120, 55.1248470],
          [-2.4236510, 55.1248510],
          [-2.4236910, 55.1248550],
          [-2.4237290, 55.1248550],
          [-2.4237660, 55.1248590],
          [-2.4238020, 55.1248630],
          [-2.4238380, 55.1248660],
          [-2.4238730, 55.1248700],
          [-2.4239060, 55.1248780],
          [-2.4239350, 55.1248850],
          [-2.4239690, 55.1248930],
          [-2.4240030, 55.1248970],
          [-2.4240360, 55.1249080],
          [-2.4240670, 55.1249160],
          [-2.4241010, 55.1249230],
          [-2.4241320, 55.1249350],
          [-2.4241630, 55.1249430],
          [-2.4241940, 55.1249540],
          [-2.4242230, 55.1249650],
          [-2.4242540, 55.1249770],
          [-2.4242840, 55.1249880],
          [-2.4243130, 55.1250040],
          [-2.4243410, 55.1250190],
          [-2.4243710, 55.1250340],
          [-2.4244000, 55.1250490],
          [-2.4244260, 55.1250650],
          [-2.4244520, 55.1250800],
          [-2.4244810, 55.1250950],
          [-2.4245100, 55.1251140],
          [-2.4245360, 55.1251330],
          [-2.4245630, 55.1251480],
          [-2.4245860, 55.1251710],
          [-2.4246060, 55.1251900],
          [-2.4246280, 55.1252090],
          [-2.4246550, 55.1252290],
          [-2.4246800, 55.1252470],
          [-2.4247060, 55.1252670],
          [-2.4247310, 55.1252890],
          [-2.4247580, 55.1253080],
          [-2.4247840, 55.1253310],
          [-2.4248130, 55.1253540],
          [-2.4248430, 55.1253730],
          [-2.4248730, 55.1253960],
          [-2.4248990, 55.1254190],
          [-2.4249280, 55.1254420],
          [-2.4249540, 55.1254610],
          [-2.4249820, 55.1254840],
          [-2.4250110, 55.1255070],
          [-2.4250410, 55.1255260],
          [-2.4250710, 55.1255530],
          [-2.4251010, 55.1255720],
          [-2.4251310, 55.1255940],
          [-2.4251600, 55.1256180],
          [-2.4251890, 55.1256400],
          [-2.4252190, 55.1256590],
          [-2.4252490, 55.1256860],
          [-2.4252780, 55.1257090],
          [-2.4253090, 55.1257320],
          [-2.4253420, 55.1257510],
          [-2.4253740, 55.1257780],
          [-2.4254030, 55.1258010],
          [-2.4254310, 55.1258230],
          [-2.4254620, 55.1258460],
          [-2.4254930, 55.1258660],
          [-2.4255230, 55.1258890],
          [-2.4255510, 55.1259070],
          [-2.4255810, 55.1259260],
          [-2.4256130, 55.1259490],
          [-2.4256410, 55.1259760],
          [-2.4256710, 55.1259950],
          [-2.4257030, 55.1260180],
          [-2.4257310, 55.1260410],
          [-2.4257600, 55.1260640],
          [-2.4257880, 55.1260870],
          [-2.4258190, 55.1261060],
          [-2.4258490, 55.1261290],
          [-2.4258810, 55.1261480],
          [-2.4259110, 55.1261710],
          [-2.4259410, 55.1261900],
          [-2.4259700, 55.1262130],
          [-2.4259990, 55.1262320],
          [-2.4260270, 55.1262540],
          [-2.4260580, 55.1262740],
          [-2.4260880, 55.1262930],
          [-2.4261140, 55.1263150],
          [-2.4261410, 55.1263350],
          [-2.4261700, 55.1263580],
          [-2.4261960, 55.1263770],
          [-2.4262260, 55.1263960],
          [-2.4262570, 55.1264150],
          [-2.4262860, 55.1264380],
          [-2.4263160, 55.1264570],
          [-2.4263430, 55.1264800],
          [-2.4263700, 55.1264990],
          [-2.4263990, 55.1265180],
          [-2.4264290, 55.1265370],
          [-2.4264590, 55.1265600],
          [-2.4264890, 55.1265820],
          [-2.4265200, 55.1266010],
          [-2.4265510, 55.1266250],
          [-2.4265790, 55.1266470],
          [-2.4266080, 55.1266670],
          [-2.4266380, 55.1266900],
          [-2.4266660, 55.1267090],
          [-2.4266940, 55.1267270],
          [-2.4267230, 55.1267500],
          [-2.4267520, 55.1267660],
          [-2.4267830, 55.1267850],
          [-2.4268100, 55.1268040],
          [-2.4268350, 55.1268230],
          [-2.4268560, 55.1268420],
          [-2.4268820, 55.1268610],
          [-2.4269140, 55.1268770],
          [-2.4269460, 55.1268880],
          [-2.4269740, 55.1269070],
          [-2.4270040, 55.1269260],
          [-2.4270340, 55.1269450],
          [-2.4270610, 55.1269680],
          [-2.4270880, 55.1269870],
          [-2.4271160, 55.1270060],
          [-2.4271460, 55.1270250],
          [-2.4271760, 55.1270440],
          [-2.4272080, 55.1270630],
          [-2.4272420, 55.1270860],
          [-2.4272760, 55.1271050],
          [-2.4273110, 55.1271280],
          [-2.4273450, 55.1271470],
          [-2.4273790, 55.1271660],
          [-2.4274140, 55.1271890],
          [-2.4274490, 55.1272080],
          [-2.4274860, 55.1272310],
          [-2.4275220, 55.1272500],
          [-2.4275590, 55.1272690],
          [-2.4275970, 55.1272880],
          [-2.4276330, 55.1273110],
          [-2.4276670, 55.1273300],
          [-2.4277010, 55.1273490],
          [-2.4277370, 55.1273680],
          [-2.4277720, 55.1273870],
          [-2.4278090, 55.1274030],
          [-2.4278440, 55.1274220],
          [-2.4278790, 55.1274410],
          [-2.4279140, 55.1274600],
          [-2.4279480, 55.1274790],
          [-2.4279830, 55.1274950],
          [-2.4280180, 55.1275130],
          [-2.4280530, 55.1275320],
          [-2.4280900, 55.1275480],
          [-2.4281230, 55.1275670],
          [-2.4281540, 55.1275900],
          [-2.4281890, 55.1276090],
          [-2.4282250, 55.1276320],
          [-2.4282590, 55.1276540],
          [-2.4282930, 55.1276770],
          [-2.4283230, 55.1277000],
          [-2.4283490, 55.1277270],
          [-2.4283730, 55.1277500],
          [-2.4283950, 55.1277730],
          [-2.4284130, 55.1277990],
          [-2.4284330, 55.1278260],
          [-2.4284530, 55.1278520],
          [-2.4284730, 55.1278790],
          [-2.4284910, 55.1279060],
          [-2.4285110, 55.1279330],
          [-2.4285310, 55.1279590],
          [-2.4285530, 55.1279900],
          [-2.4285730, 55.1280210],
          [-2.4285930, 55.1280510],
          [-2.4286110, 55.1280810],
          [-2.4286330, 55.1281160],
          [-2.4286560, 55.1281470],
          [-2.4286710, 55.1281800],
          [-2.4286810, 55.1282110],
          [-2.4286880, 55.1282460],
          [-2.4286870, 55.1282840],
          [-2.4286740, 55.1283210],
          [-2.4286470, 55.1283630],
          [-2.4286080, 55.1284020],
          [-2.4285530, 55.1284360],
          [-2.4284870, 55.1284670],
          [-2.4284050, 55.1284900],
          [-2.4283180, 55.1285130],
          [-2.4282290, 55.1285320],
          [-2.4281430, 55.1285550],
          [-2.4280550, 55.1285780],
          [-2.4279670, 55.1285970],
          [-2.4278820, 55.1286200],
          [-2.4277980, 55.1286390],
          [-2.4277160, 55.1286580],
          [-2.4276350, 55.1286770],
          [-2.4275580, 55.1286960],
          [-2.4274830, 55.1287110],
          [-2.4274160, 55.1287340],
          [-2.4273510, 55.1287500],
          [-2.4272850, 55.1287680],
          [-2.4272200, 55.1287880],
          [-2.4271550, 55.1288030],
          [-2.4270890, 55.1288220],
          [-2.4270310, 55.1288410],
          [-2.4269780, 55.1288560],
          [-2.4269200, 55.1288750],
          [-2.4268610, 55.1288940],
          [-2.4267960, 55.1289100],
          [-2.4267300, 55.1289250],
          [-2.4266580, 55.1289440],
          [-2.4265830, 55.1289630],
          [-2.4265020, 55.1289820],
          [-2.4264190, 55.1290050],
          [-2.4263300, 55.1290280],
          [-2.4262340, 55.1290540],
          [-2.4261430, 55.1290700],
          [-2.4260460, 55.1290890],
          [-2.4259360, 55.1291080],
          [-2.4258270, 55.1291350],
          [-2.4257150, 55.1291620],
          [-2.4256080, 55.1291840],
          [-2.4255050, 55.1292070],
          [-2.4254050, 55.1292300],
          [-2.4253090, 55.1292530],
          [-2.4252160, 55.1292760],
          [-2.4251220, 55.1292950],
          [-2.4250420, 55.1293180],
          [-2.4249570, 55.1293370],
          [-2.4248680, 55.1293600],
          [-2.4247820, 55.1293860],
          [-2.4246950, 55.1294090],
          [-2.4246100, 55.1294320],
          [-2.4245260, 55.1294510],
          [-2.4244440, 55.1294740],
          [-2.4243650, 55.1294930],
          [-2.4242820, 55.1295160],
          [-2.4241940, 55.1295360],
          [-2.4241080, 55.1295580],
          [-2.4240140, 55.1295810],
          [-2.4239150, 55.1296080],
          [-2.4238080, 55.1296300],
          [-2.4236870, 55.1296610],
          [-2.4235590, 55.1296840],
          [-2.4234320, 55.1297070],
          [-2.4233080, 55.1297300],
          [-2.4231850, 55.1297530],
          [-2.4230670, 55.1297760],
          [-2.4229490, 55.1298020],
          [-2.4228290, 55.1298290],
          [-2.4227150, 55.1298520],
          [-2.4226040, 55.1298790],
          [-2.4224960, 55.1299010],
          [-2.4223870, 55.1299240],
          [-2.4222790, 55.1299430],
          [-2.4221690, 55.1299630],
          [-2.4220640, 55.1299820],
          [-2.4219670, 55.1300040],
          [-2.4218660, 55.1300270],
          [-2.4217690, 55.1300500],
          [-2.4216780, 55.1300700],
          [-2.4215790, 55.1300850],
          [-2.4214790, 55.1301000],
          [-2.4213760, 55.1301190],
          [-2.4212730, 55.1301380],
          [-2.4211770, 55.1301640],
          [-2.4210760, 55.1301840],
          [-2.4209780, 55.1302070],
          [-2.4208820, 55.1302290],
          [-2.4207820, 55.1302560],
          [-2.4206800, 55.1302790],
          [-2.4205750, 55.1302940],
          [-2.4204720, 55.1303140],
          [-2.4203700, 55.1303290],
          [-2.4202640, 55.1303400],
          [-2.4201590, 55.1303480],
          [-2.4200520, 55.1303520],
          [-2.4199440, 55.1303560],
          [-2.4198370, 55.1303520],
          [-2.4197340, 55.1303480],
          [-2.4196290, 55.1303410],
          [-2.4195240, 55.1303330],
          [-2.4194220, 55.1303220],
          [-2.4193180, 55.1303060],
          [-2.4192160, 55.1302910],
          [-2.4191100, 55.1302800],
          [-2.4190020, 55.1302680],
          [-2.4188920, 55.1302570],
          [-2.4187770, 55.1302450],
          [-2.4186570, 55.1302300],
          [-2.4185330, 55.1302190],
          [-2.4184110, 55.1302150],
          [-2.4182850, 55.1302110],
          [-2.4181600, 55.1302150],
          [-2.4180400, 55.1302180],
          [-2.4179270, 55.1302180],
          [-2.4178190, 55.1302180],
          [-2.4177140, 55.1302180],
          [-2.4176080, 55.1302180],
          [-2.4175090, 55.1302150],
          [-2.4174100, 55.1302150],
          [-2.4173130, 55.1302110],
          [-2.4172200, 55.1302070],
          [-2.4171250, 55.1302070],
          [-2.4170300, 55.1302030],
          [-2.4169370, 55.1302030],
          [-2.4168460, 55.1302000],
          [-2.4167520, 55.1301990],
          [-2.4166580, 55.1301960],
          [-2.4165650, 55.1301920],
          [-2.4164720, 55.1301920],
          [-2.4163780, 55.1301880],
          [-2.4162850, 55.1301880],
          [-2.4161920, 55.1301840],
          [-2.4160960, 55.1301800],
          [-2.4160010, 55.1301800],
          [-2.4159020, 55.1301800],
          [-2.4158020, 55.1301800],
          [-2.4157010, 55.1301800],
          [-2.4155970, 55.1301800],
          [-2.4154930, 55.1301770],
          [-2.4153870, 55.1301770],
          [-2.4152850, 55.1301730],
          [-2.4151800, 55.1301690],
          [-2.4150770, 55.1301690],
          [-2.4149720, 55.1301650],
          [-2.4148700, 55.1301650],
          [-2.4147720, 55.1301650],
          [-2.4146680, 55.1301690],
          [-2.4145660, 55.1301690],
          [-2.4144690, 55.1301650],
          [-2.4143740, 55.1301610],
          [-2.4142820, 55.1301610],
          [-2.4141870, 55.1301610],
          [-2.4140960, 55.1301580],
          [-2.4140110, 55.1301570],
          [-2.4139310, 55.1301540],
          [-2.4138490, 55.1301500],
          [-2.4137710, 55.1301500],
          [-2.4136970, 55.1301460],
          [-2.4136250, 55.1301390],
          [-2.4135500, 55.1301380],
          [-2.4134820, 55.1301350],
          [-2.4134030, 55.1301350],
          [-2.4133360, 55.1301380],
          [-2.4132650, 55.1301380],
          [-2.4132010, 55.1301420],
          [-2.4131380, 55.1301420],
          [-2.4130760, 55.1301420],
          [-2.4130130, 55.1301460],
          [-2.4129510, 55.1301500],
          [-2.4128910, 55.1301570],
          [-2.4128310, 55.1301610],
          [-2.4127750, 55.1301650],
          [-2.4127180, 55.1301690],
          [-2.4126600, 55.1301760],
          [-2.4125980, 55.1301800],
          [-2.4125340, 55.1301920],
          [-2.4124750, 55.1301960],
          [-2.4124180, 55.1302030],
          [-2.4123640, 55.1302070],
          [-2.4123080, 55.1302150],
          [-2.4122490, 55.1302220],
          [-2.4121930, 55.1302300],
          [-2.4121340, 55.1302410],
          [-2.4120730, 55.1302530],
          [-2.4120090, 55.1302600],
          [-2.4119470, 55.1302680],
          [-2.4118840, 55.1302790],
          [-2.4118140, 55.1302870],
          [-2.4117430, 55.1302980],
          [-2.4116690, 55.1303100],
          [-2.4115930, 55.1303210],
          [-2.4115130, 55.1303330],
          [-2.4114330, 55.1303480],
          [-2.4113500, 55.1303560],
          [-2.4112650, 55.1303670],
          [-2.4111800, 55.1303780],
          [-2.4110920, 55.1303900],
          [-2.4110090, 55.1304050],
          [-2.4109170, 55.1304170],
          [-2.4108270, 55.1304280],
          [-2.4107340, 55.1304390],
          [-2.4106420, 55.1304510],
          [-2.4105510, 55.1304620],
          [-2.4104570, 55.1304780],
          [-2.4103640, 55.1304890],
          [-2.4102710, 55.1305040],
          [-2.4101820, 55.1305200],
          [-2.4100940, 55.1305310],
          [-2.4100060, 55.1305460],
          [-2.4099120, 55.1305580],
          [-2.4098170, 55.1305730],
          [-2.4097180, 55.1305850],
          [-2.4096180, 55.1306000],
          [-2.4095180, 55.1306150],
          [-2.4094210, 55.1306300],
          [-2.4093260, 55.1306450],
          [-2.4092250, 55.1306610],
          [-2.4091270, 55.1306800],
          [-2.4090310, 55.1306990],
          [-2.4089330, 55.1307170],
          [-2.4088290, 55.1307370],
          [-2.4087280, 55.1307560],
          [-2.4086290, 55.1307740],
          [-2.4085240, 55.1307940],
          [-2.4084170, 55.1308140],
          [-2.4083090, 55.1308360],
          [-2.4082040, 55.1308590],
          [-2.4080910, 55.1308860],
          [-2.4079750, 55.1309120],
          [-2.4078590, 55.1309390],
          [-2.4077430, 55.1309650],
          [-2.4076160, 55.1309920],
          [-2.4074840, 55.1310150],
          [-2.4073490, 55.1310380],
          [-2.4072210, 55.1310690],
          [-2.4070890, 55.1310950],
          [-2.4069600, 55.1311180],
          [-2.4068340, 55.1311490],
          [-2.4067040, 55.1311790],
          [-2.4065730, 55.1312060],
          [-2.4064340, 55.1312330],
          [-2.4063010, 55.1312580],
          [-2.4061590, 55.1312820],
          [-2.4060210, 55.1313010],
          [-2.4058780, 55.1313240],
          [-2.4057350, 55.1313400],
          [-2.4055820, 55.1313590],
          [-2.4054290, 55.1313810],
          [-2.4052610, 55.1314120],
          [-2.4051080, 55.1314540],
          [-2.4049680, 55.1315030],
          [-2.4048470, 55.1315600],
          [-2.4047570, 55.1316210],
          [-2.4046810, 55.1316820],
          [-2.4046220, 55.1317350],
          [-2.4045760, 55.1317890],
          [-2.4045350, 55.1318320],
          [-2.4045020, 55.1318730],
          [-2.4044710, 55.1319150],
          [-2.4044370, 55.1319530],
          [-2.4044040, 55.1319880],
          [-2.4043720, 55.1320300],
          [-2.4043410, 55.1320720],
          [-2.4043110, 55.1321140],
          [-2.4042750, 55.1321600],
          [-2.4042350, 55.1322020],
          [-2.4041960, 55.1322430],
          [-2.4041490, 55.1322810],
          [-2.4041020, 55.1323220],
          [-2.4040530, 55.1323650],
          [-2.4040030, 55.1324070],
          [-2.4039540, 55.1324500],
          [-2.4039130, 55.1324870],
          [-2.4038740, 55.1325280],
          [-2.4038340, 55.1325680],
          [-2.4037910, 55.1326060],
          [-2.4037500, 55.1326480],
          [-2.4037120, 55.1326820],
          [-2.4036660, 55.1327200],
          [-2.4036180, 55.1327550],
          [-2.4035720, 55.1327890],
          [-2.4035230, 55.1328230],
          [-2.4034760, 55.1328580],
          [-2.4034210, 55.1328950],
          [-2.4033670, 55.1329250],
          [-2.4033060, 55.1329600],
          [-2.4032510, 55.1329880],
          [-2.4031930, 55.1330180],
          [-2.4031410, 55.1330480],
          [-2.4030910, 55.1330750],
          [-2.4030430, 55.1331060],
          [-2.4029940, 55.1331360],
          [-2.4029490, 55.1331620],
          [-2.4029010, 55.1331900],
          [-2.4028580, 55.1332160],
          [-2.4028110, 55.1332430],
          [-2.4027640, 55.1332700],
          [-2.4027160, 55.1332960],
          [-2.4026640, 55.1333190],
          [-2.4026180, 55.1333420],
          [-2.4025810, 55.1333650],
          [-2.4025460, 55.1333880],
          [-2.4025130, 55.1334110],
          [-2.4024710, 55.1334300],
          [-2.4024310, 55.1334490],
          [-2.4023890, 55.1334680],
          [-2.4023500, 55.1334910],
          [-2.4023090, 55.1335140],
          [-2.4022610, 55.1335330],
          [-2.4022110, 55.1335480],
          [-2.4021670, 55.1335710],
          [-2.4021240, 55.1335900],
          [-2.4020830, 55.1336090],
          [-2.4020420, 55.1336320],
          [-2.4020040, 55.1336590],
          [-2.4019680, 55.1336810],
          [-2.4019310, 55.1337120],
          [-2.4018930, 55.1337390],
          [-2.4018540, 55.1337690],
          [-2.4018180, 55.1338030],
          [-2.4017880, 55.1338350],
          [-2.4017510, 55.1338740],
          [-2.4017130, 55.1339220],
          [-2.4016710, 55.1339630],
          [-2.4016260, 55.1340130],
          [-2.4015820, 55.1340660],
          [-2.4015340, 55.1341240],
          [-2.4014840, 55.1341770],
          [-2.4014350, 55.1342370],
          [-2.4013750, 55.1342980],
          [-2.4013110, 55.1343560],
          [-2.4012410, 55.1344180],
          [-2.4011640, 55.1344730],
          [-2.4010830, 55.1345280],
          [-2.4010000, 55.1345810],
          [-2.4009190, 55.1346310],
          [-2.4008380, 55.1346800],
          [-2.4007590, 55.1347260],
          [-2.4006820, 55.1347690],
          [-2.4006110, 55.1348180],
          [-2.4005410, 55.1348660],
          [-2.4004660, 55.1349170],
          [-2.4003950, 55.1349700],
          [-2.4003160, 55.1350230],
          [-2.4002270, 55.1350770],
          [-2.4001450, 55.1351340],
          [-2.4000780, 55.1351960],
          [-2.4000070, 55.1352560],
          [-2.3999430, 55.1353140],
          [-2.3998760, 55.1353670],
          [-2.3998080, 55.1354180],
          [-2.3997330, 55.1354660],
          [-2.3996660, 55.1355160],
          [-2.3996010, 55.1355700],
          [-2.3995410, 55.1356230],
          [-2.3994870, 55.1356710],
          [-2.3994280, 55.1357180],
          [-2.3993710, 55.1357680],
          [-2.3993010, 55.1358290],
          [-2.3992180, 55.1358900],
          [-2.3991350, 55.1359530],
          [-2.3990380, 55.1360230],
          [-2.3989220, 55.1360920],
          [-2.3987890, 55.1361550],
          [-2.3986360, 55.1362170],
          [-2.3984820, 55.1362790],
          [-2.3983060, 55.1363510],
          [-2.3981170, 55.1364350],
          [-2.3979450, 55.1365140],
          [-2.3977590, 55.1365910],
          [-2.3975830, 55.1366880],
          [-2.3973990, 55.1367780],
          [-2.3972270, 55.1368690],
          [-2.3970840, 55.1369500],
          [-2.3969600, 55.1370380],
          [-2.3968830, 55.1371290],
          [-2.3968160, 55.1372140],
          [-2.3967590, 55.1372940],
          [-2.3967020, 55.1373700],
          [-2.3966350, 55.1374400],
          [-2.3965570, 55.1375090],
          [-2.3964630, 55.1375750],
          [-2.3963620, 55.1376440],
          [-2.3962510, 55.1377100],
          [-2.3961270, 55.1377670],
          [-2.3959940, 55.1378160],
          [-2.3958430, 55.1378510],
          [-2.3956840, 55.1378890],
          [-2.3955260, 55.1379420],
          [-2.3953830, 55.1379990],
          [-2.3952650, 55.1380710],
          [-2.3951780, 55.1381400],
          [-2.3951540, 55.1382060],
          [-2.3951660, 55.1382630],
          [-2.3952150, 55.1383080],
          [-2.3952850, 55.1383360],
          [-2.3953840, 55.1383290],
          [-2.3954920, 55.1383100],
          [-2.3956100, 55.1382830],
          [-2.3957410, 55.1382680],
          [-2.3958750, 55.1382600],
          [-2.3960260, 55.1382670],
          [-2.3961680, 55.1382900],
          [-2.3963040, 55.1383230],
          [-2.3964500, 55.1383590],
          [-2.3965930, 55.1383970],
          [-2.3967390, 55.1384270],
          [-2.3968770, 55.1384440],
          [-2.3970490, 55.1384280],
          [-2.3971860, 55.1383870],
          [-2.3973110, 55.1383360],
          [-2.3974370, 55.1382760],
          [-2.3975650, 55.1382230],
          [-2.3976990, 55.1381630],
          [-2.3978300, 55.1381100],
          [-2.3979710, 55.1380560],
          [-2.3981120, 55.1380090],
          [-2.3982680, 55.1379600],
          [-2.3984030, 55.1379180],
          [-2.3985500, 55.1378790],
          [-2.3986860, 55.1378420],
          [-2.3988260, 55.1378100],
          [-2.3989350, 55.1377890],
          [-2.3990580, 55.1377720],
          [-2.3991650, 55.1377680],
          [-2.3992710, 55.1377680],
          [-2.3993650, 55.1377680],
          [-2.3994690, 55.1377640],
          [-2.3995620, 55.1377530],
          [-2.3996560, 55.1377380],
          [-2.3997380, 55.1377190],
          [-2.3998150, 55.1376920],
          [-2.3998920, 55.1376620],
          [-2.3999650, 55.1376310],
          [-2.4000380, 55.1375970],
          [-2.4001080, 55.1375640],
          [-2.4001790, 55.1375240],
          [-2.4002420, 55.1374860],
          [-2.4003010, 55.1374440],
          [-2.4003570, 55.1374030],
          [-2.4004150, 55.1373640],
          [-2.4004750, 55.1373240],
          [-2.4005360, 55.1372820],
          [-2.4006020, 55.1372420],
          [-2.4006640, 55.1372080],
          [-2.4007360, 55.1371780],
          [-2.4008100, 55.1371470],
          [-2.4008860, 55.1371200],
          [-2.4009610, 55.1370900],
          [-2.4010370, 55.1370630],
          [-2.4011130, 55.1370360],
          [-2.4011960, 55.1370090],
          [-2.4012720, 55.1369840],
          [-2.4013570, 55.1369640],
          [-2.4014360, 55.1369410],
          [-2.4015150, 55.1369220],
          [-2.4015970, 55.1369060],
          [-2.4016800, 55.1368870],
          [-2.4017650, 55.1368680],
          [-2.4018530, 55.1368490],
          [-2.4019410, 55.1368340],
          [-2.4020300, 55.1368140],
          [-2.4021190, 55.1367950],
          [-2.4022050, 55.1367810],
          [-2.4023020, 55.1367610],
          [-2.4023910, 55.1367470],
          [-2.4024870, 55.1367270],
          [-2.4025690, 55.1367160],
          [-2.4026470, 55.1367040],
          [-2.4027170, 55.1366920],
          [-2.4027880, 55.1366880],
          [-2.4028560, 55.1366770],
          [-2.4029240, 55.1366690],
          [-2.4029940, 55.1366620],
          [-2.4030610, 55.1366580],
          [-2.4031340, 55.1366500],
          [-2.4032010, 55.1366430],
          [-2.4032730, 55.1366350],
          [-2.4033410, 55.1366280],
          [-2.4034140, 55.1366200],
          [-2.4034810, 55.1366200],
          [-2.4035570, 55.1366160],
          [-2.4036340, 55.1366080],
          [-2.4037130, 55.1366010],
          [-2.4038000, 55.1365930],
          [-2.4038840, 55.1365820],
          [-2.4039710, 55.1365700],
          [-2.4040590, 55.1365630],
          [-2.4041360, 55.1365560],
          [-2.4042240, 55.1365440],
          [-2.4042990, 55.1365320],
          [-2.4043780, 55.1365240],
          [-2.4044520, 55.1365170],
          [-2.4045250, 55.1365130],
          [-2.4046000, 55.1365050],
          [-2.4046720, 55.1364940],
          [-2.4047410, 55.1364860],
          [-2.4048060, 55.1364790],
          [-2.4048770, 55.1364670],
          [-2.4049410, 55.1364600],
          [-2.4050060, 55.1364520],
          [-2.4050650, 55.1364480],
          [-2.4051310, 55.1364400],
          [-2.4051900, 55.1364370],
          [-2.4052520, 55.1364290],
          [-2.4053130, 55.1364250],
          [-2.4053740, 55.1364180],
          [-2.4054330, 55.1364100],
          [-2.4054990, 55.1364100],
          [-2.4055670, 55.1364100],
          [-2.4056340, 55.1364140],
          [-2.4057000, 55.1364170],
          [-2.4057630, 55.1364250],
          [-2.4058190, 55.1364290],
          [-2.4058690, 55.1364360],
          [-2.4059190, 55.1364440],
          [-2.4059690, 55.1364520],
          [-2.4060220, 55.1364620],
          [-2.4060830, 55.1364710],
          [-2.4061420, 55.1364780],
          [-2.4062020, 55.1364900],
          [-2.4062590, 55.1365010],
          [-2.4063150, 55.1365090],
          [-2.4063680, 55.1365160],
          [-2.4064200, 55.1365280],
          [-2.4064730, 55.1365390],
          [-2.4065270, 55.1365510],
          [-2.4065770, 55.1365660],
          [-2.4066260, 55.1365740],
          [-2.4066740, 55.1365850],
          [-2.4067210, 55.1365960],
          [-2.4067700, 55.1366000],
          [-2.4068210, 55.1366080],
          [-2.4068730, 55.1366080],
          [-2.4069270, 55.1366160],
          [-2.4069820, 55.1366230],
          [-2.4070390, 55.1366270],
          [-2.4070940, 55.1366350],
          [-2.4071510, 55.1366390],
          [-2.4072060, 55.1366420],
          [-2.4072620, 55.1366460],
          [-2.4073170, 55.1366500],
          [-2.4073740, 55.1366460],
          [-2.4074310, 55.1366460],
          [-2.4074960, 55.1366460],
          [-2.4075570, 55.1366460],
          [-2.4076220, 55.1366460],
          [-2.4076840, 55.1366460],
          [-2.4077530, 55.1366430],
          [-2.4078190, 55.1366390],
          [-2.4078880, 55.1366350],
          [-2.4079550, 55.1366310],
          [-2.4080270, 55.1366240],
          [-2.4080950, 55.1366160],
          [-2.4081670, 55.1366080],
          [-2.4082340, 55.1366010],
          [-2.4083010, 55.1365930],
          [-2.4083690, 55.1365820],
          [-2.4084360, 55.1365700],
          [-2.4084980, 55.1365590],
          [-2.4085610, 55.1365470],
          [-2.4086200, 55.1365400],
          [-2.4086830, 55.1365320],
          [-2.4087460, 55.1365240],
          [-2.4088120, 55.1365170],
          [-2.4088790, 55.1365170],
          [-2.4089460, 55.1365170],
          [-2.4090100, 55.1365170],
          [-2.4090720, 55.1365170],
          [-2.4091340, 55.1365240],
          [-2.4092040, 55.1365280],
          [-2.4092680, 55.1365320],
          [-2.4093350, 55.1365360],
          [-2.4094020, 55.1365390],
          [-2.4094650, 55.1365430],
          [-2.4095260, 55.1365470],
          [-2.4095890, 55.1365580],
          [-2.4096520, 55.1365660],
          [-2.4097150, 55.1365740],
          [-2.4097780, 55.1365810],
          [-2.4098400, 55.1365890],
          [-2.4099020, 55.1365970],
          [-2.4099620, 55.1366040],
          [-2.4100250, 55.1366120],
          [-2.4100870, 55.1366230],
          [-2.4101480, 55.1366310],
          [-2.4102120, 55.1366390],
          [-2.4102750, 55.1366500],
          [-2.4103370, 55.1366580],
          [-2.4104000, 55.1366650],
          [-2.4104640, 55.1366730],
          [-2.4105250, 55.1366800],
          [-2.4105850, 55.1366880],
          [-2.4106500, 55.1366960],
          [-2.4107160, 55.1367030],
          [-2.4107790, 55.1367070],
          [-2.4108420, 55.1367150],
          [-2.4109050, 55.1367220],
          [-2.4109720, 55.1367300],
          [-2.4110350, 55.1367340],
          [-2.4111040, 55.1367380],
          [-2.4111670, 55.1367410],
          [-2.4112350, 55.1367490],
          [-2.4112970, 55.1367530],
          [-2.4113670, 55.1367570],
          [-2.4114300, 55.1367570],
          [-2.4114990, 55.1367610],
          [-2.4115640, 55.1367610],
          [-2.4116300, 55.1367640],
          [-2.4116930, 55.1367680],
          [-2.4117550, 55.1367680],
          [-2.4118140, 55.1367650],
          [-2.4118740, 55.1367610],
          [-2.4119320, 55.1367570],
          [-2.4119960, 55.1367570],
          [-2.4120600, 55.1367570],
          [-2.4121250, 55.1367570],
          [-2.4121900, 55.1367570],
          [-2.4122530, 55.1367570],
          [-2.4123170, 55.1367570],
          [-2.4123820, 55.1367530],
          [-2.4124480, 55.1367490],
          [-2.4125140, 55.1367490],
          [-2.4125760, 55.1367490],
          [-2.4126400, 55.1367460],
          [-2.4127020, 55.1367450],
          [-2.4127680, 55.1367420],
          [-2.4128310, 55.1367420],
          [-2.4128960, 55.1367340],
          [-2.4129570, 55.1367300],
          [-2.4130250, 55.1367260],
          [-2.4130890, 55.1367230],
          [-2.4131560, 55.1367190],
          [-2.4132180, 55.1367150],
          [-2.4132830, 55.1367070],
          [-2.4133450, 55.1367040],
          [-2.4134100, 55.1366960],
          [-2.4134740, 55.1366920],
          [-2.4135400, 55.1366880],
          [-2.4136080, 55.1366810],
          [-2.4136750, 55.1366770],
          [-2.4137420, 55.1366730],
          [-2.4138090, 55.1366650],
          [-2.4138760, 55.1366620],
          [-2.4139450, 55.1366580],
          [-2.4140100, 55.1366500],
          [-2.4140740, 55.1366500],
          [-2.4141370, 55.1366430],
          [-2.4142040, 55.1366350],
          [-2.4142660, 55.1366310],
          [-2.4143310, 55.1366240],
          [-2.4143900, 55.1366200],
          [-2.4144620, 55.1366160],
          [-2.4145240, 55.1366160],
          [-2.4145870, 55.1366160],
          [-2.4146440, 55.1366190],
          [-2.4147070, 55.1366230],
          [-2.4147670, 55.1366270],
          [-2.4148220, 55.1366380],
          [-2.4148770, 55.1366540],
          [-2.4149290, 55.1366720],
          [-2.4149770, 55.1366870],
          [-2.4150310, 55.1367070],
          [-2.4150810, 55.1367300],
          [-2.4151300, 55.1367410],
          [-2.4151790, 55.1367600],
          [-2.4152290, 55.1367760],
          [-2.4152760, 55.1367940],
          [-2.4153260, 55.1368100],
          [-2.4153740, 55.1368330],
          [-2.4154240, 55.1368480],
          [-2.4154730, 55.1368670],
          [-2.4155240, 55.1368860],
          [-2.4155730, 55.1369050],
          [-2.4156240, 55.1369240],
          [-2.4156700, 55.1369430],
          [-2.4157190, 55.1369630],
          [-2.4157620, 55.1369770],
          [-2.4158140, 55.1369970],
          [-2.4158620, 55.1370180],
          [-2.4159170, 55.1370390],
          [-2.4159620, 55.1370570],
          [-2.4160140, 55.1370770],
          [-2.4160640, 55.1370950],
          [-2.4161150, 55.1371110],
          [-2.4161660, 55.1371340],
          [-2.4162170, 55.1371500],
          [-2.4162630, 55.1371680],
          [-2.4163140, 55.1371880],
          [-2.4163630, 55.1372030],
          [-2.4164140, 55.1372220],
          [-2.4164580, 55.1372410],
          [-2.4165040, 55.1372560],
          [-2.4165510, 55.1372710],
          [-2.4166030, 55.1372870],
          [-2.4166500, 55.1373050],
          [-2.4167010, 55.1373210],
          [-2.4167480, 55.1373400],
          [-2.4167990, 55.1373630],
          [-2.4168500, 55.1373780],
          [-2.4168990, 55.1373940],
          [-2.4169480, 55.1374120],
          [-2.4169970, 55.1374280],
          [-2.4170460, 55.1374430],
          [-2.4171010, 55.1374580],
          [-2.4171560, 55.1374730],
          [-2.4172140, 55.1374930],
          [-2.4172660, 55.1375110],
          [-2.4173200, 55.1375340],
          [-2.4173730, 55.1375530],
          [-2.4174240, 55.1375720],
          [-2.4174780, 55.1375920],
          [-2.4175320, 55.1376180],
          [-2.4175870, 55.1376410],
          [-2.4176440, 55.1376640],
          [-2.4176980, 55.1376830],
          [-2.4177520, 55.1377060],
          [-2.4178030, 55.1377210],
          [-2.4178540, 55.1377370],
          [-2.4179030, 55.1377560],
          [-2.4179540, 55.1377710],
          [-2.4180050, 55.1377860],
          [-2.4180570, 55.1378050],
          [-2.4181070, 55.1378240],
          [-2.4181570, 55.1378400],
          [-2.4182050, 55.1378580],
          [-2.4182610, 55.1378780],
          [-2.4183120, 55.1378970],
          [-2.4183740, 55.1379240],
          [-2.4184280, 55.1379420],
          [-2.4184900, 55.1379650],
          [-2.4185460, 55.1379840],
          [-2.4186040, 55.1380080],
          [-2.4186570, 55.1380270],
          [-2.4187090, 55.1380500],
          [-2.4187590, 55.1380720],
          [-2.4188050, 55.1380880],
          [-2.4188480, 55.1381060],
          [-2.4188940, 55.1381290],
          [-2.4189380, 55.1381450],
          [-2.4189810, 55.1381640],
          [-2.4190180, 55.1381830],
          [-2.4190560, 55.1382020],
          [-2.4190900, 55.1382210],
          [-2.4191310, 55.1382370],
          [-2.4191710, 55.1382560],
          [-2.4192090, 55.1382750],
          [-2.4192450, 55.1382900],
          [-2.4192840, 55.1383090],
          [-2.4193210, 55.1383280],
          [-2.4193580, 55.1383470],
          [-2.4193930, 55.1383660],
          [-2.4194350, 55.1383860],
          [-2.4194710, 55.1384040],
          [-2.4195140, 55.1384200],
          [-2.4195520, 55.1384390],
          [-2.4195860, 55.1384580],
          [-2.4196170, 55.1384730],
          [-2.4196560, 55.1384880],
          [-2.4196920, 55.1385000],
          [-2.4197280, 55.1385190],
          [-2.4197610, 55.1385300],
          [-2.4197980, 55.1385490],
          [-2.4198340, 55.1385600],
          [-2.4198720, 55.1385760],
          [-2.4199110, 55.1385880],
          [-2.4199460, 55.1385990],
          [-2.4199740, 55.1386090],
          [-2.4200120, 55.1386180],
          [-2.4200440, 55.1386300],
          [-2.4200740, 55.1386370],
          [-2.4201060, 55.1386450],
          [-2.4201320, 55.1386560],
          [-2.4201610, 55.1386680],
          [-2.4201910, 55.1386790],
          [-2.4202190, 55.1386870],
          [-2.4202460, 55.1386980],
          [-2.4202740, 55.1387100],
          [-2.4203020, 55.1387210],
          [-2.4203300, 55.1387320],
          [-2.4203580, 55.1387480],
          [-2.4203800, 55.1387590],
          [-2.4204040, 55.1387740],
          [-2.4204320, 55.1387860],
          [-2.4204610, 55.1387970],
          [-2.4204880, 55.1388080],
          [-2.4205160, 55.1388200],
          [-2.4205430, 55.1388360],
          [-2.4205680, 55.1388500],
          [-2.4205960, 55.1388660],
          [-2.4206230, 55.1388810],
          [-2.4206440, 55.1388950],
          [-2.4206690, 55.1389160],
          [-2.4206930, 55.1389310],
          [-2.4207160, 55.1389480],
          [-2.4207420, 55.1389680],
          [-2.4207670, 55.1389880],
          [-2.4207910, 55.1390070],
          [-2.4208130, 55.1390260],
          [-2.4208360, 55.1390420],
          [-2.4208590, 55.1390600],
          [-2.4208810, 55.1390790],
          [-2.4209010, 55.1391020],
          [-2.4209220, 55.1391210],
          [-2.4209410, 55.1391450],
          [-2.4209610, 55.1391630],
          [-2.4209810, 55.1391870],
          [-2.4210010, 55.1392090],
          [-2.4210230, 55.1392250],
          [-2.4210430, 55.1392470],
          [-2.4210660, 55.1392700],
          [-2.4210840, 55.1392890],
          [-2.4211030, 55.1393080],
          [-2.4211230, 55.1393310],
          [-2.4211380, 55.1393500],
          [-2.4211560, 55.1393690],
          [-2.4211780, 55.1393890],
          [-2.4211960, 55.1394080],
          [-2.4212170, 55.1394260],
          [-2.4212380, 55.1394460],
          [-2.4212580, 55.1394640],
          [-2.4212790, 55.1394840],
          [-2.4213010, 55.1395010],
          [-2.4213250, 55.1395220],
          [-2.4213490, 55.1395410],
          [-2.4213700, 55.1395590],
          [-2.4213910, 55.1395790],
          [-2.4214100, 55.1395980],
          [-2.4214300, 55.1396210],
          [-2.4214510, 55.1396400],
          [-2.4214730, 55.1396600],
          [-2.4214930, 55.1396780],
          [-2.4215150, 55.1396980],
          [-2.4215330, 55.1397160],
          [-2.4215560, 55.1397360],
          [-2.4215740, 55.1397530],
          [-2.4215950, 55.1397740],
          [-2.4216130, 55.1397930],
          [-2.4216330, 55.1398120],
          [-2.4216560, 55.1398270],
          [-2.4216760, 55.1398470],
          [-2.4216980, 55.1398690],
          [-2.4217210, 55.1398850],
          [-2.4217430, 55.1399040],
          [-2.4217640, 55.1399190],
          [-2.4217860, 55.1399380],
          [-2.4218080, 55.1399570],
          [-2.4218240, 55.1399780],
          [-2.4218470, 55.1399990],
          [-2.4218690, 55.1400160],
          [-2.4218930, 55.1400410],
          [-2.4219160, 55.1400560],
          [-2.4219380, 55.1400750],
          [-2.4219600, 55.1400940],
          [-2.4219810, 55.1401130],
          [-2.4220030, 55.1401320],
          [-2.4220280, 55.1401480],
          [-2.4220520, 55.1401660],
          [-2.4220780, 55.1401900],
          [-2.4221010, 55.1402090],
          [-2.4221210, 55.1402280],
          [-2.4221440, 55.1402500],
          [-2.4221660, 55.1402700],
          [-2.4221890, 55.1402890],
          [-2.4222140, 55.1403080],
          [-2.4222350, 55.1403260],
          [-2.4222560, 55.1403460],
          [-2.4222770, 55.1403680],
          [-2.4223000, 55.1403880],
          [-2.4223210, 55.1404100],
          [-2.4223410, 55.1404300],
          [-2.4223620, 55.1404520],
          [-2.4223850, 55.1404720],
          [-2.4224060, 55.1404910],
          [-2.4224280, 55.1405100],
          [-2.4224480, 55.1405290],
          [-2.4224680, 55.1405480],
          [-2.4224890, 55.1405670],
          [-2.4225140, 55.1405860],
          [-2.4225360, 55.1406050],
          [-2.4225580, 55.1406240],
          [-2.4225800, 55.1406480],
          [-2.4226040, 55.1406620],
          [-2.4226280, 55.1406820],
          [-2.4226510, 55.1407010],
          [-2.4226740, 55.1407190],
          [-2.4226970, 55.1407390],
          [-2.4227210, 55.1407580],
          [-2.4227440, 55.1407730],
          [-2.4227680, 55.1407920],
          [-2.4227900, 55.1408100],
          [-2.4228140, 55.1408300],
          [-2.4228380, 55.1408490],
          [-2.4228630, 55.1408650],
          [-2.4228860, 55.1408800],
          [-2.4229070, 55.1408970],
          [-2.4229320, 55.1409140],
          [-2.4229540, 55.1409330],
          [-2.4229750, 55.1409550],
          [-2.4229960, 55.1409750],
          [-2.4230170, 55.1409940],
          [-2.4230410, 55.1410170],
          [-2.4230640, 55.1410360],
          [-2.4230880, 55.1410590],
          [-2.4231070, 55.1410830],
          [-2.4231330, 55.1411130],
          [-2.4231530, 55.1411390],
          [-2.4231710, 55.1411660],
          [-2.4231880, 55.1411920],
          [-2.4232060, 55.1412160],
          [-2.4232250, 55.1412430],
          [-2.4232390, 55.1412650],
          [-2.4232540, 55.1412920],
          [-2.4232700, 55.1413220],
          [-2.4232810, 55.1413530],
          [-2.4232930, 55.1413860],
          [-2.4233070, 55.1414190],
          [-2.4233230, 55.1414560],
          [-2.4233390, 55.1414930],
          [-2.4233610, 55.1415280],
          [-2.4233840, 55.1415580],
          [-2.4234080, 55.1415860],
          [-2.4234270, 55.1416120],
          [-2.4234460, 55.1416390],
          [-2.4234610, 55.1416610],
          [-2.4234780, 55.1416920],
          [-2.4234950, 55.1417230],
          [-2.4235120, 55.1417540],
          [-2.4235460, 55.1417650],
          [-2.4235760, 55.1417730],
          [-2.4236060, 55.1417810],
          [-2.4236300, 55.1418010],
          [-2.4236430, 55.1418250],
          [-2.4236660, 55.1418520],
          [-2.4236830, 55.1418680],
          [-2.4237010, 55.1418900],
          [-2.4237210, 55.1419140],
          [-2.4237330, 55.1419330],
          [-2.4237500, 55.1419560],
          [-2.4237670, 55.1419780],
          [-2.4237880, 55.1420020],
          [-2.4238050, 55.1420240],
          [-2.4238220, 55.1420510],
          [-2.4238360, 55.1420780],
          [-2.4238500, 55.1421050],
          [-2.4238580, 55.1421350],
          [-2.4238630, 55.1421620],
          [-2.4238650, 55.1421960],
          [-2.4238650, 55.1422170],
          [-2.4238600, 55.1422570],
          [-2.4238520, 55.1422880],
          [-2.4238400, 55.1423220],
          [-2.4238300, 55.1423560],
          [-2.4238190, 55.1423910],
          [-2.4238040, 55.1424250],
          [-2.4237890, 55.1424590],
          [-2.4237740, 55.1425010],
          [-2.4237570, 55.1425390],
          [-2.4237420, 55.1425770],
          [-2.4237290, 55.1426110],
          [-2.4237170, 55.1426500],
          [-2.4237050, 55.1426840],
          [-2.4236950, 55.1427220],
          [-2.4236840, 55.1427570],
          [-2.4236700, 55.1427910],
          [-2.4236590, 55.1428290],
          [-2.4236470, 55.1428630],
          [-2.4236370, 55.1429020],
          [-2.4236270, 55.1429360],
          [-2.4236150, 55.1429740],
          [-2.4236030, 55.1430090],
          [-2.4235890, 55.1430430],
          [-2.4235750, 55.1430730],
          [-2.4235600, 55.1431070],
          [-2.4235470, 55.1431460],
          [-2.4235310, 55.1431790],
          [-2.4235120, 55.1432180],
          [-2.4234920, 55.1432560],
          [-2.4234710, 55.1432940],
          [-2.4234470, 55.1433360],
          [-2.4234200, 55.1433820],
          [-2.4233890, 55.1434240],
          [-2.4233540, 55.1434770],
          [-2.4233170, 55.1435270],
          [-2.4232740, 55.1435840],
          [-2.4232260, 55.1436410],
          [-2.4231760, 55.1437060],
          [-2.4231240, 55.1437710],
          [-2.4230690, 55.1438440],
          [-2.4230150, 55.1439150],
          [-2.4229550, 55.1439920],
          [-2.4228970, 55.1440650],
          [-2.4228400, 55.1441450],
          [-2.4227880, 55.1442350],
          [-2.4227410, 55.1443240],
          [-2.4226970, 55.1444160],
          [-2.4226620, 55.1445070],
          [-2.4226360, 55.1445980],
          [-2.4226090, 55.1446940],
          [-2.4225790, 55.1447930],
          [-2.4225410, 55.1448960],
          [-2.4224960, 55.1449990],
          [-2.4224410, 55.1451060],
          [-2.4223780, 55.1452090],
          [-2.4223100, 55.1453150],
          [-2.4222430, 55.1454180],
          [-2.4221740, 55.1455250],
          [-2.4221080, 55.1456280],
          [-2.4220430, 55.1457270],
          [-2.4219750, 55.1458260],
          [-2.4219140, 55.1459260],
          [-2.4218630, 55.1460170],
          [-2.4218170, 55.1461090],
          [-2.4217880, 55.1461920],
          [-2.4217720, 55.1462690],
          [-2.4217650, 55.1463530],
          [-2.4217600, 55.1464440],
          [-2.4217490, 55.1465360],
          [-2.4217290, 55.1466350],
          [-2.4216960, 55.1467340],
          [-2.4216490, 55.1468370],
          [-2.4215830, 55.1469360],
          [-2.4215020, 55.1470280],
          [-2.4214110, 55.1471170],
          [-2.4213080, 55.1472120],
          [-2.4211990, 55.1472950],
          [-2.4210850, 55.1473750],
          [-2.4209670, 55.1474560],
          [-2.4208480, 55.1475290],
          [-2.4207270, 55.1476020],
          [-2.4206070, 55.1476700],
          [-2.4204850, 55.1477350],
          [-2.4203640, 55.1478030],
          [-2.4202470, 55.1478660],
          [-2.4201260, 55.1479280],
          [-2.4200090, 55.1479940],
          [-2.4198950, 55.1480500],
          [-2.4197820, 55.1481120],
          [-2.4196740, 55.1481660],
          [-2.4195700, 55.1482230],
          [-2.4194690, 55.1482760],
          [-2.4193720, 55.1483300],
          [-2.4192760, 55.1483800],
          [-2.4191810, 55.1484290],
          [-2.4190910, 55.1484780],
          [-2.4190020, 55.1485280],
          [-2.4189130, 55.1485780],
          [-2.4188250, 55.1486240],
          [-2.4187410, 55.1486690],
          [-2.4186600, 55.1487150],
          [-2.4185790, 55.1487570],
          [-2.4184970, 55.1487950],
          [-2.4184110, 55.1488300],
          [-2.4183300, 55.1488600],
          [-2.4182510, 55.1488910],
          [-2.4181770, 55.1489210],
          [-2.4181020, 55.1489480],
          [-2.4180280, 55.1489750],
          [-2.4179560, 55.1490020],
          [-2.4178860, 55.1490250],
          [-2.4178190, 55.1490440],
          [-2.4177540, 55.1490670],
          [-2.4176910, 55.1490860],
          [-2.4176280, 55.1491010],
          [-2.4175710, 55.1491150],
          [-2.4175060, 55.1491310],
          [-2.4174510, 55.1491470],
          [-2.4173960, 55.1491580],
          [-2.4173430, 55.1491690],
          [-2.4172850, 55.1491810],
          [-2.4172270, 55.1491930],
          [-2.4171730, 55.1492000],
          [-2.4171200, 55.1492080],
          [-2.4170640, 55.1492160],
          [-2.4170120, 55.1492230],
          [-2.4169580, 55.1492270],
          [-2.4169030, 55.1492270],
          [-2.4168490, 55.1492270],
          [-2.4167980, 55.1492270],
          [-2.4167480, 55.1492270],
          [-2.4166970, 55.1492270],
          [-2.4166470, 55.1492310],
          [-2.4165940, 55.1492310],
          [-2.4165410, 55.1492310],
          [-2.4164900, 55.1492310],
          [-2.4164410, 55.1492270],
          [-2.4163970, 55.1492230],
          [-2.4163610, 55.1492230],
          [-2.4163230, 55.1492230],
          [-2.4162810, 55.1492230],
          [-2.4162420, 55.1492230],
          [-2.4161990, 55.1492200],
          [-2.4161580, 55.1492160],
          [-2.4161150, 55.1492160],
          [-2.4160710, 55.1492120],
          [-2.4160280, 55.1492120],
          [-2.4159830, 55.1492080],
          [-2.4159410, 55.1492080],
          [-2.4158980, 55.1492040],
          [-2.4158520, 55.1492040],
          [-2.4158070, 55.1492040],
          [-2.4157600, 55.1492040],
          [-2.4157090, 55.1492040],
          [-2.4156590, 55.1492040],
          [-2.4156070, 55.1492080],
          [-2.4155520, 55.1492120],
          [-2.4154960, 55.1492190],
          [-2.4154400, 55.1492270],
          [-2.4153860, 55.1492380],
          [-2.4153330, 55.1492500],
          [-2.4152830, 55.1492610],
          [-2.4152300, 55.1492760],
          [-2.4151750, 55.1492880],
          [-2.4151190, 55.1493030],
          [-2.4150610, 55.1493220],
          [-2.4150020, 55.1493410],
          [-2.4149420, 55.1493560],
          [-2.4148770, 55.1493720],
          [-2.4148080, 55.1493910],
          [-2.4147390, 55.1494060],
          [-2.4146650, 55.1494250],
          [-2.4145850, 55.1494440],
          [-2.4145000, 55.1494560],
          [-2.4144080, 55.1494710],
          [-2.4143050, 55.1494790],
          [-2.4141950, 55.1494860],
          [-2.4140710, 55.1494900],
          [-2.4139480, 55.1494980],
          [-2.4138210, 55.1495020],
          [-2.4136930, 55.1495090],
          [-2.4135750, 55.1495210],
          [-2.4134700, 55.1495400],
          [-2.4133860, 55.1495660],
          [-2.4133100, 55.1495970],
          [-2.4132490, 55.1496300],
          [-2.4131940, 55.1496690],
          [-2.4131500, 55.1497100],
          [-2.4131120, 55.1497570],
          [-2.4130760, 55.1498020],
          [-2.4130390, 55.1498440],
          [-2.4130010, 55.1498940],
          [-2.4129620, 55.1499400],
          [-2.4129240, 55.1499850],
          [-2.4128810, 55.1500310],
          [-2.4128430, 55.1500800],
          [-2.4128050, 55.1501260],
          [-2.4127660, 55.1501720],
          [-2.4127240, 55.1502180],
          [-2.4126840, 55.1502640],
          [-2.4126440, 55.1503130],
          [-2.4126010, 55.1503590],
          [-2.4125560, 55.1504120],
          [-2.4125070, 55.1504620],
          [-2.4124580, 55.1505160],
          [-2.4124080, 55.1505730],
          [-2.4123560, 55.1506290],
          [-2.4123070, 55.1506880],
          [-2.4122580, 55.1507480],
          [-2.4122060, 55.1508130],
          [-2.4121510, 55.1508810],
          [-2.4120950, 55.1509540],
          [-2.4120330, 55.1510260],
          [-2.4119690, 55.1511020],
          [-2.4119100, 55.1511790],
          [-2.4118490, 55.1512510],
          [-2.4117880, 55.1513160],
          [-2.4117190, 55.1513730],
          [-2.4116320, 55.1514160],
          [-2.4115220, 55.1514430],
          [-2.4113980, 55.1514470],
          [-2.4112770, 55.1514290],
          [-2.4111720, 55.1513910],
          [-2.4110770, 55.1513450],
          [-2.4109880, 55.1513000],
          [-2.4109000, 55.1512500],
          [-2.4108140, 55.1512040],
          [-2.4107250, 55.1511550],
          [-2.4106320, 55.1511130],
          [-2.4105390, 55.1510670],
          [-2.4104420, 55.1510210],
          [-2.4103420, 55.1509750],
          [-2.4102430, 55.1509290],
          [-2.4101450, 55.1508830],
          [-2.4100450, 55.1508380],
          [-2.4099460, 55.1507920],
          [-2.4098420, 55.1507500],
          [-2.4097310, 55.1507110],
          [-2.4096170, 55.1506850],
          [-2.4094990, 55.1506660],
          [-2.4093800, 55.1506540],
          [-2.4092670, 55.1506500],
          [-2.4091530, 55.1506500],
          [-2.4090450, 55.1506500],
          [-2.4089420, 55.1506500],
          [-2.4088390, 55.1506500],
          [-2.4087310, 55.1506500],
          [-2.4086280, 55.1506540],
          [-2.4085240, 55.1506540],
          [-2.4084170, 55.1506580],
          [-2.4083140, 55.1506610],
          [-2.4082030, 55.1506610],
          [-2.4080920, 55.1506690],
          [-2.4079770, 55.1506770],
          [-2.4078590, 55.1506800],
          [-2.4077420, 55.1506880],
          [-2.4076230, 55.1506960],
          [-2.4075060, 55.1507070],
          [-2.4073890, 55.1507190],
          [-2.4072710, 55.1507340],
          [-2.4071560, 55.1507490],
          [-2.4070420, 55.1507680],
          [-2.4069340, 55.1507870],
          [-2.4068250, 55.1508060],
          [-2.4067210, 55.1508250],
          [-2.4066180, 55.1508440],
          [-2.4065150, 55.1508630],
          [-2.4064130, 55.1508860],
          [-2.4063110, 55.1509010],
          [-2.4062060, 55.1509130],
          [-2.4061040, 55.1509210],
          [-2.4060040, 55.1509280],
          [-2.4059000, 55.1509280],
          [-2.4057970, 55.1509280],
          [-2.4056900, 55.1509210],
          [-2.4055860, 55.1509130],
          [-2.4054860, 55.1508950],
          [-2.4053880, 55.1508720],
          [-2.4052970, 55.1508450],
          [-2.4052040, 55.1508150],
          [-2.4051110, 55.1507800],
          [-2.4050230, 55.1507460],
          [-2.4049350, 55.1507160],
          [-2.4048480, 55.1506770],
          [-2.4047650, 55.1506430],
          [-2.4046860, 55.1506090],
          [-2.4046100, 55.1505780],
          [-2.4045380, 55.1505440],
          [-2.4044660, 55.1505130],
          [-2.4043950, 55.1504790],
          [-2.4043230, 55.1504490],
          [-2.4042490, 55.1504180],
          [-2.4041730, 55.1503870],
          [-2.4040970, 55.1503610],
          [-2.4040180, 55.1503380],
          [-2.4039400, 55.1503190],
          [-2.4038600, 55.1503030],
          [-2.4037780, 55.1502840],
          [-2.4036950, 55.1502690],
          [-2.4036120, 55.1502540],
          [-2.4035250, 55.1502380],
          [-2.4034400, 55.1502270],
          [-2.4033550, 55.1502120],
          [-2.4032700, 55.1501960],
          [-2.4031850, 55.1501810],
          [-2.4031020, 55.1501700],
          [-2.4030180, 55.1501580],
          [-2.4029300, 55.1501430],
          [-2.4028490, 55.1501350],
          [-2.4027660, 55.1501200],
          [-2.4026870, 55.1501090],
          [-2.4026090, 55.1501010],
          [-2.4025320, 55.1500970],
          [-2.4024560, 55.1500930],
          [-2.4023790, 55.1500890],
          [-2.4023030, 55.1500890],
          [-2.4022320, 55.1500890],
          [-2.4021610, 55.1500890],
          [-2.4020970, 55.1500890],
          [-2.4020320, 55.1500970],
          [-2.4019680, 55.1500970],
          [-2.4019050, 55.1501010],
          [-2.4018410, 55.1501040],
          [-2.4017780, 55.1501080],
          [-2.4017070, 55.1501160],
          [-2.4016390, 55.1501230],
          [-2.4015730, 55.1501310],
          [-2.4015060, 55.1501390],
          [-2.4014400, 55.1501460],
          [-2.4013750, 55.1501580],
          [-2.4013150, 55.1501650],
          [-2.4012570, 55.1501770],
          [-2.4012030, 55.1501920],
          [-2.4011540, 55.1502110],
          [-2.4011140, 55.1502370],
          [-2.4010820, 55.1502630],
          [-2.4010610, 55.1502950],
          [-2.4010450, 55.1503330],
          [-2.4010370, 55.1503670],
          [-2.4010380, 55.1504050],
          [-2.4010480, 55.1504390],
          [-2.4010580, 55.1504780],
          [-2.4010700, 55.1505120],
          [-2.4010810, 55.1505460],
          [-2.4010910, 55.1505800],
          [-2.4011010, 55.1506190],
          [-2.4011130, 55.1506560],
          [-2.4011210, 55.1506940],
          [-2.4011310, 55.1507370],
          [-2.4011410, 55.1507750],
          [-2.4011500, 55.1508130],
          [-2.4011610, 55.1508550],
          [-2.4011750, 55.1508970],
          [-2.4011860, 55.1509380],
          [-2.4011960, 55.1509810],
          [-2.4012030, 55.1510190],
          [-2.4012100, 55.1510610],
          [-2.4012200, 55.1511030],
          [-2.4012300, 55.1511450],
          [-2.4012400, 55.1511870],
          [-2.4012500, 55.1512290],
          [-2.4012600, 55.1512710],
          [-2.4012700, 55.1513130],
          [-2.4012780, 55.1513550],
          [-2.4012900, 55.1513970],
          [-2.4013000, 55.1514380],
          [-2.4013060, 55.1514800],
          [-2.4013160, 55.1515230],
          [-2.4013280, 55.1515650],
          [-2.4013370, 55.1516070],
          [-2.4013480, 55.1516480],
          [-2.4013570, 55.1516910],
          [-2.4013650, 55.1517320],
          [-2.4013750, 55.1517750],
          [-2.4013830, 55.1518160],
          [-2.4013900, 55.1518590],
          [-2.4013950, 55.1519000],
          [-2.4014020, 55.1519380],
          [-2.4014100, 55.1519770],
          [-2.4014230, 55.1520150],
          [-2.4014410, 55.1520490],
          [-2.4014610, 55.1520840],
          [-2.4014930, 55.1521140],
          [-2.4015410, 55.1521410],
          [-2.4015960, 55.1521600],
          [-2.4016520, 55.1521720],
          [-2.4017140, 55.1521830],
          [-2.4017770, 55.1521950],
          [-2.4018440, 55.1522020],
          [-2.4019130, 55.1522100],
          [-2.4019840, 55.1522140],
          [-2.4020550, 55.1522210],
          [-2.4021270, 55.1522290],
          [-2.4022010, 55.1522370],
          [-2.4022730, 55.1522480],
          [-2.4023410, 55.1522590],
          [-2.4024070, 55.1522750],
          [-2.4024670, 55.1522860],
          [-2.4025250, 55.1523010],
          [-2.4025800, 55.1523130],
          [-2.4026340, 55.1523280],
          [-2.4026870, 55.1523430],
          [-2.4027420, 55.1523590],
          [-2.4027990, 55.1523700],
          [-2.4028580, 55.1523820],
          [-2.4029170, 55.1523930],
          [-2.4029730, 55.1524040],
          [-2.4030330, 55.1524160],
          [-2.4030920, 55.1524270],
          [-2.4031520, 55.1524430],
          [-2.4032150, 55.1524580],
          [-2.4032810, 55.1524730],
          [-2.4033450, 55.1524880],
          [-2.4034080, 55.1525070],
          [-2.4034710, 55.1525230],
          [-2.4035350, 55.1525380],
          [-2.4036010, 55.1525530],
          [-2.4036650, 55.1525680],
          [-2.4037310, 55.1525840],
          [-2.4037970, 55.1525990],
          [-2.4038640, 55.1526140],
          [-2.4039290, 55.1526290],
          [-2.4039970, 55.1526450],
          [-2.4040640, 55.1526600],
          [-2.4041320, 55.1526790],
          [-2.4042000, 55.1526980],
          [-2.4042700, 55.1527130],
          [-2.4043380, 55.1527290],
          [-2.4044060, 55.1527470],
          [-2.4044740, 55.1527630],
          [-2.4045440, 55.1527750],
          [-2.4046130, 55.1527900],
          [-2.4046800, 55.1528050],
          [-2.4047460, 55.1528240],
          [-2.4048090, 55.1528470],
          [-2.4048700, 55.1528730],
          [-2.4049250, 55.1528990],
          [-2.4049830, 55.1529270],
          [-2.4050370, 55.1529610],
          [-2.4050890, 55.1529880],
          [-2.4051410, 55.1530220],
          [-2.4051890, 55.1530530],
          [-2.4052390, 55.1530870],
          [-2.4052870, 55.1531170],
          [-2.4053340, 55.1531480],
          [-2.4053800, 55.1531780],
          [-2.4054290, 55.1532130],
          [-2.4054770, 55.1532430],
          [-2.4055260, 55.1532780],
          [-2.4055740, 55.1533120],
          [-2.4056230, 55.1533450],
          [-2.4056720, 55.1533810],
          [-2.4057220, 55.1534150],
          [-2.4057740, 55.1534490],
          [-2.4058240, 55.1534870],
          [-2.4058740, 55.1535250],
          [-2.4059220, 55.1535630],
          [-2.4059720, 55.1535980],
          [-2.4060220, 55.1536360],
          [-2.4060710, 55.1536740],
          [-2.4061190, 55.1537120],
          [-2.4061660, 55.1537540],
          [-2.4062110, 55.1538000],
          [-2.4062540, 55.1538460],
          [-2.4062960, 55.1538910],
          [-2.4063390, 55.1539370],
          [-2.4063810, 55.1539830],
          [-2.4064220, 55.1540290],
          [-2.4064670, 55.1540820],
          [-2.4065110, 55.1541280],
          [-2.4065560, 55.1541780],
          [-2.4066020, 55.1542310],
          [-2.4066510, 55.1542870],
          [-2.4066990, 55.1543410],
          [-2.4067560, 55.1544100],
          [-2.4068120, 55.1544780],
          [-2.4068720, 55.1545470],
          [-2.4069390, 55.1546240],
          [-2.4070100, 55.1546960],
          [-2.4070930, 55.1547780],
          [-2.4072080, 55.1548630],
          [-2.4073410, 55.1549440],
          [-2.4074780, 55.1550120],
          [-2.4076210, 55.1550820],
          [-2.4077590, 55.1551460],
          [-2.4079020, 55.1552110],
          [-2.4080400, 55.1552800],
          [-2.4081750, 55.1553440],
          [-2.4083190, 55.1554060],
          [-2.4084540, 55.1554710],
          [-2.4085790, 55.1555310],
          [-2.4087020, 55.1555890],
          [-2.4088240, 55.1556390],
          [-2.4089310, 55.1556920],
          [-2.4090380, 55.1557450],
          [-2.4091450, 55.1557990],
          [-2.4092480, 55.1558480],
          [-2.4093510, 55.1558980],
          [-2.4094530, 55.1559480],
          [-2.4095530, 55.1559970],
          [-2.4096580, 55.1560470],
          [-2.4097610, 55.1560960],
          [-2.4098710, 55.1561500],
          [-2.4099760, 55.1561990],
          [-2.4100780, 55.1562490],
          [-2.4101760, 55.1562950],
          [-2.4102690, 55.1563400],
          [-2.4103630, 55.1563820],
          [-2.4104550, 55.1564280],
          [-2.4105420, 55.1564700],
          [-2.4106270, 55.1565120],
          [-2.4107180, 55.1565580],
          [-2.4108080, 55.1566000],
          [-2.4109000, 55.1566460],
          [-2.4109930, 55.1566910],
          [-2.4110880, 55.1567370],
          [-2.4111830, 55.1567830],
          [-2.4112770, 55.1568280],
          [-2.4113740, 55.1568700],
          [-2.4114690, 55.1569130],
          [-2.4115580, 55.1569560],
          [-2.4116560, 55.1570040],
          [-2.4117510, 55.1570500],
          [-2.4118470, 55.1570960],
          [-2.4119460, 55.1571410],
          [-2.4120420, 55.1571870],
          [-2.4121390, 55.1572330],
          [-2.4122370, 55.1572790],
          [-2.4123400, 55.1573240],
          [-2.4124490, 55.1573700],
          [-2.4125620, 55.1574090],
          [-2.4126810, 55.1574400],
          [-2.4127960, 55.1574660],
          [-2.4129090, 55.1574850],
          [-2.4130170, 55.1574970],
          [-2.4131250, 55.1575050],
          [-2.4132310, 55.1575120],
          [-2.4133370, 55.1575160],
          [-2.4134410, 55.1575160],
          [-2.4135440, 55.1575130],
          [-2.4136410, 55.1575090],
          [-2.4137410, 55.1575050],
          [-2.4138380, 55.1574980],
          [-2.4139350, 55.1574900],
          [-2.4140300, 55.1574820],
          [-2.4141220, 55.1574750],
          [-2.4142120, 55.1574640],
          [-2.4143040, 55.1574520],
          [-2.4143880, 55.1574400],
          [-2.4144700, 55.1574290],
          [-2.4145500, 55.1574140],
          [-2.4146320, 55.1574020],
          [-2.4147120, 55.1573870],
          [-2.4147920, 55.1573760],
          [-2.4148720, 55.1573640],
          [-2.4149530, 55.1573560],
          [-2.4150290, 55.1573490],
          [-2.4151090, 55.1573450],
          [-2.4151860, 55.1573410],
          [-2.4152630, 55.1573410],
          [-2.4153420, 55.1573410],
          [-2.4154170, 55.1573450],
          [-2.4154950, 55.1573490],
          [-2.4155700, 55.1573560],
          [-2.4156430, 55.1573600],
          [-2.4157160, 55.1573640],
          [-2.4157880, 55.1573710],
          [-2.4158600, 55.1573750],
          [-2.4159300, 55.1573790],
          [-2.4160020, 55.1573830],
          [-2.4160700, 55.1573870],
          [-2.4161380, 55.1573870],
          [-2.4162030, 55.1573830],
          [-2.4162670, 55.1573790],
          [-2.4163290, 55.1573750],
          [-2.4163870, 55.1573720],
          [-2.4164430, 55.1573680],
          [-2.4165000, 55.1573600],
          [-2.4165570, 55.1573530],
          [-2.4166140, 55.1573450],
          [-2.4166710, 55.1573410],
          [-2.4167220, 55.1573340],
          [-2.4167730, 55.1573260],
          [-2.4168260, 55.1573150],
          [-2.4168790, 55.1573070],
          [-2.4169260, 55.1572990],
          [-2.4169700, 55.1572950],
          [-2.4170200, 55.1572920],
          [-2.4170700, 55.1572880],
          [-2.4171200, 55.1572840],
          [-2.4171720, 55.1572800],
          [-2.4172290, 55.1572720],
          [-2.4172820, 55.1572690],
          [-2.4173370, 55.1572650],
          [-2.4173950, 55.1572610],
          [-2.4174520, 55.1572570],
          [-2.4175100, 55.1572530],
          [-2.4175660, 55.1572530],
          [-2.4176200, 55.1572530],
          [-2.4176750, 55.1572530],
          [-2.4177270, 55.1572530],
          [-2.4177770, 55.1572530],
          [-2.4178270, 55.1572570],
          [-2.4178760, 55.1572610],
          [-2.4179240, 55.1572650],
          [-2.4179720, 55.1572680],
          [-2.4180190, 55.1572720],
          [-2.4180650, 55.1572800],
          [-2.4181110, 55.1572840],
          [-2.4181560, 55.1572910],
          [-2.4181990, 55.1573030],
          [-2.4182420, 55.1573100],
          [-2.4182860, 55.1573180],
          [-2.4183300, 55.1573290],
          [-2.4183730, 55.1573410],
          [-2.4184160, 55.1573490],
          [-2.4184590, 55.1573600],
          [-2.4185030, 55.1573670],
          [-2.4185470, 55.1573750],
          [-2.4185910, 55.1573870],
          [-2.4186350, 55.1573980],
          [-2.4186790, 55.1574060],
          [-2.4187240, 55.1574170],
          [-2.4187690, 55.1574280],
          [-2.4188140, 55.1574400],
          [-2.4188600, 55.1574510],
          [-2.4189070, 55.1574630],
          [-2.4189560, 55.1574780],
          [-2.4190040, 55.1574900],
          [-2.4190540, 55.1574970],
          [-2.4191040, 55.1575090],
          [-2.4191540, 55.1575200],
          [-2.4192050, 55.1575310],
          [-2.4192570, 55.1575430],
          [-2.4193070, 55.1575580],
          [-2.4193590, 55.1575730],
          [-2.4194100, 55.1575880],
          [-2.4194610, 55.1576040],
          [-2.4195120, 55.1576190],
          [-2.4195660, 55.1576380],
          [-2.4196190, 55.1576530],
          [-2.4196740, 55.1576690],
          [-2.4197290, 55.1576880],
          [-2.4197770, 55.1577010],
          [-2.4198390, 55.1577260],
          [-2.4198910, 55.1577450],
          [-2.4199450, 55.1577670],
          [-2.4200020, 55.1577870],
          [-2.4200620, 55.1578100],
          [-2.4201240, 55.1578290],
          [-2.4201850, 55.1578520],
          [-2.4202510, 55.1578750],
          [-2.4203150, 55.1579010],
          [-2.4203830, 55.1579280],
          [-2.4204530, 55.1579510],
          [-2.4205250, 55.1579770],
          [-2.4205930, 55.1580040],
          [-2.4206650, 55.1580310],
          [-2.4207390, 55.1580580],
          [-2.4208090, 55.1580840],
          [-2.4208810, 55.1581110],
          [-2.4209520, 55.1581380],
          [-2.4210270, 55.1581640],
          [-2.4211080, 55.1581910],
          [-2.4211900, 55.1582180],
          [-2.4212830, 55.1582410],
          [-2.4213850, 55.1582600],
          [-2.4214980, 55.1582790],
          [-2.4216240, 55.1582940],
          [-2.4217610, 55.1583100],
          [-2.4219010, 55.1583210],
          [-2.4220360, 55.1583400],
          [-2.4221740, 55.1583630],
          [-2.4223180, 55.1583890],
          [-2.4224690, 55.1584200],
          [-2.4226200, 55.1584570],
          [-2.4227880, 55.1585000],
          [-2.4229710, 55.1585380],
          [-2.4231640, 55.1585840],
          [-2.4233680, 55.1586180],
          [-2.4235940, 55.1586490],
          [-2.4238230, 55.1586720],
          [-2.4240500, 55.1586910],
          [-2.4242760, 55.1587060],
          [-2.4244980, 55.1587290],
          [-2.4247100, 55.1587560],
          [-2.4249190, 55.1587750],
          [-2.4251390, 55.1587940],
          [-2.4253600, 55.1588060],
          [-2.4255830, 55.1588130],
          [-2.4258120, 55.1588210],
          [-2.4260360, 55.1588210],
          [-2.4262620, 55.1588170],
          [-2.4264990, 55.1588210],
          [-2.4267320, 55.1588360],
          [-2.4269500, 55.1588480],
          [-2.4271660, 55.1588550],
          [-2.4273750, 55.1588670],
          [-2.4275630, 55.1588930],
          [-2.4277390, 55.1589190],
          [-2.4279230, 55.1589690],
          [-2.4280990, 55.1590220],
          [-2.4282580, 55.1590680],
          [-2.4284070, 55.1591200],
          [-2.4285550, 55.1591710],
          [-2.4286880, 55.1592280],
          [-2.4288040, 55.1592870],
          [-2.4289140, 55.1593500],
          [-2.4289990, 55.1594140],
          [-2.4290810, 55.1594760],
          [-2.4291680, 55.1595260],
          [-2.4292620, 55.1595670],
          [-2.4293730, 55.1595870],
          [-2.4294830, 55.1595880],
          [-2.4295920, 55.1595690],
          [-2.4296910, 55.1595390],
          [-2.4297760, 55.1595050],
          [-2.4298620, 55.1594780],
          [-2.4299470, 55.1594470],
          [-2.4300290, 55.1594210],
          [-2.4301120, 55.1593940],
          [-2.4301940, 55.1593630],
          [-2.4302780, 55.1593330],
          [-2.4303600, 55.1593060],
          [-2.4304420, 55.1592830],
          [-2.4305200, 55.1592610],
          [-2.4305980, 55.1592420],
          [-2.4306790, 55.1592220],
          [-2.4307520, 55.1591990],
          [-2.4308240, 55.1591840],
          [-2.4308910, 55.1591610],
          [-2.4309530, 55.1591420],
          [-2.4310110, 55.1591230],
          [-2.4310690, 55.1591040],
          [-2.4311240, 55.1590810],
          [-2.4311790, 55.1590580],
          [-2.4312330, 55.1590360],
          [-2.4312870, 55.1590120],
          [-2.4313390, 55.1589890],
          [-2.4313870, 55.1589630],
          [-2.4314230, 55.1589410],
          [-2.4314720, 55.1589090],
          [-2.4315130, 55.1588790],
          [-2.4315530, 55.1588520],
          [-2.4315920, 55.1588260],
          [-2.4316340, 55.1587990],
          [-2.4316760, 55.1587720],
          [-2.4317170, 55.1587490],
          [-2.4317590, 55.1587230],
          [-2.4318020, 55.1587000],
          [-2.4318460, 55.1586770],
          [-2.4318870, 55.1586500],
          [-2.4319340, 55.1586270],
          [-2.4319810, 55.1586000],
          [-2.4320300, 55.1585780],
          [-2.4320820, 55.1585550],
          [-2.4321360, 55.1585350],
          [-2.4321890, 55.1585130],
          [-2.4322420, 55.1584940],
          [-2.4323010, 55.1584740],
          [-2.4323600, 55.1584590],
          [-2.4324210, 55.1584440],
          [-2.4324820, 55.1584250],
          [-2.4325430, 55.1584090],
          [-2.4326080, 55.1583940],
          [-2.4326720, 55.1583790],
          [-2.4327350, 55.1583680],
          [-2.4328020, 55.1583560],
          [-2.4328680, 55.1583450],
          [-2.4329370, 55.1583290],
          [-2.4330050, 55.1583220],
          [-2.4330770, 55.1583100],
          [-2.4331510, 55.1582950],
          [-2.4332320, 55.1582830],
          [-2.4333120, 55.1582720],
          [-2.4333910, 55.1582570],
          [-2.4334740, 55.1582490],
          [-2.4335560, 55.1582380],
          [-2.4336350, 55.1582300],
          [-2.4337140, 55.1582190],
          [-2.4337900, 55.1582040],
          [-2.4338690, 55.1581960],
          [-2.4339460, 55.1581840],
          [-2.4340250, 55.1581730],
          [-2.4341040, 55.1581610],
          [-2.4341870, 55.1581500],
          [-2.4342700, 55.1581350],
          [-2.4343650, 55.1581270],
          [-2.4344620, 55.1581120],
          [-2.4345670, 55.1581040],
          [-2.4346740, 55.1580930],
          [-2.4347890, 55.1580850],
          [-2.4349120, 55.1580770],
          [-2.4350360, 55.1580700],
          [-2.4351630, 55.1580660],
          [-2.4352880, 55.1580580],
          [-2.4354060, 55.1580540],
          [-2.4355130, 55.1580470],
          [-2.4356120, 55.1580350],
          [-2.4357010, 55.1580210],
          [-2.4357860, 55.1580020],
          [-2.4358700, 55.1579820],
          [-2.4359490, 55.1579560],
          [-2.4360200, 55.1579290],
          [-2.4360880, 55.1578990],
          [-2.4361560, 55.1578640],
          [-2.4362180, 55.1578300],
          [-2.4362720, 55.1577950],
          [-2.4363280, 55.1577580],
          [-2.4363890, 55.1577230],
          [-2.4364470, 55.1576850],
          [-2.4365060, 55.1576510],
          [-2.4365620, 55.1576130],
          [-2.4366220, 55.1575780],
          [-2.4366800, 55.1575480],
          [-2.4367420, 55.1575130],
          [-2.4368050, 55.1574830],
          [-2.4368640, 55.1574480],
          [-2.4369220, 55.1574180],
          [-2.4369820, 55.1573870],
          [-2.4370450, 55.1573540],
          [-2.4371100, 55.1573190],
          [-2.4371710, 55.1572810],
          [-2.4372380, 55.1572500],
          [-2.4373000, 55.1572120],
          [-2.4373660, 55.1571740],
          [-2.4374260, 55.1571400],
          [-2.4374870, 55.1571050],
          [-2.4375480, 55.1570710],
          [-2.4376130, 55.1570440],
          [-2.4376840, 55.1570250],
          [-2.4377620, 55.1570090],
          [-2.4378430, 55.1570020],
          [-2.4379260, 55.1570090],
          [-2.4380020, 55.1570270],
          [-2.4380760, 55.1570540],
          [-2.4381410, 55.1570810],
          [-2.4382020, 55.1571150],
          [-2.4382540, 55.1571460],
          [-2.4383080, 55.1571800],
          [-2.4383640, 55.1572110],
          [-2.4384220, 55.1572450],
          [-2.4384840, 55.1572760],
          [-2.4385460, 55.1573060],
          [-2.4386130, 55.1573330],
          [-2.4386770, 55.1573600],
          [-2.4387430, 55.1573860],
          [-2.4388160, 55.1574090],
          [-2.4388920, 55.1574280],
          [-2.4389760, 55.1574400],
          [-2.4390640, 55.1574510],
          [-2.4391540, 55.1574630],
          [-2.4392470, 55.1574670],
          [-2.4393430, 55.1574670],
          [-2.4394400, 55.1574630],
          [-2.4395360, 55.1574590],
          [-2.4396350, 55.1574440],
          [-2.4397330, 55.1574250],
          [-2.4398230, 55.1574060],
          [-2.4399170, 55.1573870],
          [-2.4400110, 55.1573680],
          [-2.4401050, 55.1573490],
          [-2.4401980, 55.1573340],
          [-2.4402800, 55.1573200],
          [-2.4403850, 55.1573070],
          [-2.4404800, 55.1572920],
          [-2.4405880, 55.1572730],
          [-2.4407070, 55.1572540],
          [-2.4408330, 55.1572270],
          [-2.4409660, 55.1572040],
          [-2.4411110, 55.1571740],
          [-2.4412480, 55.1571450],
          [-2.4413950, 55.1571160],
          [-2.4415310, 55.1570860],
          [-2.4416660, 55.1570630],
          [-2.4417980, 55.1570480],
          [-2.4419300, 55.1570440],
          [-2.4420740, 55.1570510],
          [-2.4422160, 55.1570770],
          [-2.4423510, 55.1571230],
          [-2.4424670, 55.1571870],
          [-2.4425730, 55.1572520],
          [-2.4426710, 55.1573120],
          [-2.4427710, 55.1573730],
          [-2.4428770, 55.1574360],
          [-2.4429810, 55.1574850],
          [-2.4431120, 55.1575300],
          [-2.4432390, 55.1575620],
          [-2.4433570, 55.1575840],
          [-2.4434760, 55.1576110],
          [-2.4435860, 55.1576300],
          [-2.4436880, 55.1576530],
          [-2.4437790, 55.1576800],
          [-2.4438620, 55.1577060],
          [-2.4439410, 55.1577330],
          [-2.4440180, 55.1577600],
          [-2.4440870, 55.1577830],
          [-2.4441510, 55.1578100],
          [-2.4442140, 55.1578330],
          [-2.4442800, 55.1578520],
          [-2.4443430, 55.1578710],
          [-2.4444100, 55.1578860],
          [-2.4444800, 55.1578940],
          [-2.4445590, 55.1578940],
          [-2.4446360, 55.1578940],
          [-2.4447140, 55.1578870],
          [-2.4447850, 55.1578830],
          [-2.4448590, 55.1578680],
          [-2.4449220, 55.1578600],
          [-2.4449850, 55.1578490],
          [-2.4450450, 55.1578370],
          [-2.4451010, 55.1578190],
          [-2.4451580, 55.1577960],
          [-2.4452120, 55.1577690],
          [-2.4452650, 55.1577470],
          [-2.4453210, 55.1577230],
          [-2.4453760, 55.1577050],
          [-2.4454340, 55.1576810],
          [-2.4454880, 55.1576620],
          [-2.4455380, 55.1576460],
          [-2.4455900, 55.1576390],
          [-2.4456460, 55.1576310],
          [-2.4457000, 55.1576310],
          [-2.4457520, 55.1576350],
          [-2.4458030, 55.1576420],
          [-2.4458530, 55.1576540],
          [-2.4458910, 55.1576720],
          [-2.4459160, 55.1576920],
          [-2.4459300, 55.1577150],
          [-2.4459370, 55.1577370],
          [-2.4459290, 55.1577640],
          [-2.4459190, 55.1577910],
          [-2.4459040, 55.1578100],
          [-2.4458840, 55.1578290],
          [-2.4458650, 55.1578480],
          [-2.4458420, 55.1578600],
          [-2.4458170, 55.1578750],
          [-2.4457910, 55.1578940],
          [-2.4457640, 55.1579050],
          [-2.4457360, 55.1579200],
          [-2.4457110, 55.1579360],
          [-2.4456850, 55.1579470],
          [-2.4456610, 55.1579590],
          [-2.4456360, 55.1579740],
          [-2.4456070, 55.1579890],
          [-2.4455790, 55.1580040],
          [-2.4455490, 55.1580190],
          [-2.4455200, 55.1580310],
          [-2.4454890, 55.1580460],
          [-2.4454590, 55.1580580],
          [-2.4454270, 55.1580770],
          [-2.4453920, 55.1580890],
          [-2.4453600, 55.1581070],
          [-2.4453270, 55.1581260],
          [-2.4452970, 55.1581420],
          [-2.4452660, 55.1581530],
          [-2.4452310, 55.1581680],
          [-2.4451970, 55.1581800],
          [-2.4451620, 55.1581910],
          [-2.4451280, 55.1582030],
          [-2.4450930, 55.1582180],
          [-2.4450570, 55.1582260],
          [-2.4450210, 55.1582370],
          [-2.4449860, 55.1582480],
          [-2.4449510, 55.1582640],
          [-2.4449140, 55.1582790],
          [-2.4448750, 55.1582900],
          [-2.4448320, 55.1583020],
          [-2.4447920, 55.1583170],
          [-2.4447460, 55.1583290],
          [-2.4447020, 55.1583370],
          [-2.4446590, 55.1583520],
          [-2.4446140, 55.1583630],
          [-2.4445630, 55.1583750],
          [-2.4445100, 55.1583860],
          [-2.4444580, 55.1583970],
          [-2.4444020, 55.1584050],
          [-2.4443450, 55.1584200],
          [-2.4442840, 55.1584320],
          [-2.4442210, 55.1584430],
          [-2.4441580, 55.1584550],
          [-2.4440970, 55.1584660],
          [-2.4440330, 55.1584740],
          [-2.4439660, 55.1584890],
          [-2.4438960, 55.1584930],
          [-2.4438180, 55.1585040],
          [-2.4437440, 55.1585190],
          [-2.4436670, 55.1585420],
          [-2.4435910, 55.1585730],
          [-2.4435240, 55.1586140],
          [-2.4434760, 55.1586640],
          [-2.4434620, 55.1587170],
          [-2.4434940, 55.1587780],
          [-2.4435470, 55.1588420],
          [-2.4436150, 55.1589110],
          [-2.4436890, 55.1589800],
          [-2.4437640, 55.1590510],
          [-2.4438410, 55.1591270],
          [-2.4439250, 55.1592090],
          [-2.4440120, 55.1593040],
          [-2.4441010, 55.1594000],
          [-2.4441950, 55.1594850],
          [-2.4442810, 55.1595690],
          [-2.4443650, 55.1596510],
          [-2.4444580, 55.1597270],
          [-2.4445450, 55.1598080],
          [-2.4446260, 55.1598840],
          [-2.4447220, 55.1599720],
          [-2.4448060, 55.1600640],
          [-2.4448640, 55.1601540],
          [-2.4449020, 55.1602490],
          [-2.4449230, 55.1603410],
          [-2.4449380, 55.1604290],
          [-2.4449700, 55.1605080],
          [-2.4450280, 55.1605770],
          [-2.4451060, 55.1606360],
          [-2.4451880, 55.1606710],
          [-2.4452830, 55.1607130],
          [-2.4453680, 55.1607430],
          [-2.4454470, 55.1607770],
          [-2.4455230, 55.1608040],
          [-2.4455980, 55.1608310],
          [-2.4456600, 55.1608550],
          [-2.4457270, 55.1608840],
          [-2.4457750, 55.1609090],
          [-2.4458300, 55.1609340],
          [-2.4458750, 55.1609600],
          [-2.4459170, 55.1609830],
          [-2.4459540, 55.1610100],
          [-2.4459880, 55.1610300],
          [-2.4460210, 55.1610520],
          [-2.4460590, 55.1610750],
          [-2.4460910, 55.1610980],
          [-2.4461210, 55.1611170],
          [-2.4461520, 55.1611360],
          [-2.4461880, 55.1611590],
          [-2.4462210, 55.1611780],
          [-2.4462560, 55.1612010],
          [-2.4462900, 55.1612240],
          [-2.4463300, 55.1612470],
          [-2.4463640, 55.1612700],
          [-2.4463960, 55.1612870],
          [-2.4464330, 55.1613080],
          [-2.4464670, 55.1613230],
          [-2.4465020, 55.1613420],
          [-2.4465370, 55.1613640],
          [-2.4465760, 55.1613840],
          [-2.4466090, 55.1614030],
          [-2.4466440, 55.1614260],
          [-2.4466790, 55.1614410],
          [-2.4467150, 55.1614610],
          [-2.4467480, 55.1614760],
          [-2.4467830, 55.1614940],
          [-2.4468190, 55.1615100],
          [-2.4468560, 55.1615330],
          [-2.4468910, 55.1615520],
          [-2.4469260, 55.1615710],
          [-2.4469610, 55.1615860],
          [-2.4469930, 55.1616060],
          [-2.4470280, 55.1616250],
          [-2.4470610, 55.1616400],
          [-2.4470930, 55.1616550],
          [-2.4471210, 55.1616700],
          [-2.4471530, 55.1616860],
          [-2.4471810, 55.1616970],
          [-2.4472060, 55.1617110],
          [-2.4472330, 55.1617280],
          [-2.4472580, 55.1617430],
          [-2.4472840, 55.1617540],
          [-2.4473090, 55.1617700],
          [-2.4473320, 55.1617840],
          [-2.4473560, 55.1617960],
          [-2.4473830, 55.1618110],
          [-2.4474110, 55.1618230],
          [-2.4474380, 55.1618340],
          [-2.4474630, 55.1618490],
          [-2.4474910, 55.1618610],
          [-2.4475160, 55.1618760],
          [-2.4475410, 55.1618880],
          [-2.4475650, 55.1619020],
          [-2.4475900, 55.1619140],
          [-2.4476160, 55.1619300],
          [-2.4476430, 55.1619450],
          [-2.4476660, 55.1619640],
          [-2.4476880, 55.1619790],
          [-2.4477080, 55.1619940],
          [-2.4477300, 55.1620100],
          [-2.4477500, 55.1620290],
          [-2.4477660, 55.1620480],
          [-2.4477810, 55.1620670],
          [-2.4477940, 55.1620850],
          [-2.4478050, 55.1621050],
          [-2.4478130, 55.1621280],
          [-2.4478180, 55.1621470],
          [-2.4478200, 55.1621660],
          [-2.4478210, 55.1621870],
          [-2.4478180, 55.1622120],
          [-2.4478150, 55.1622350],
          [-2.4478120, 55.1622580],
          [-2.4478020, 55.1622830],
          [-2.4477890, 55.1623110],
          [-2.4477740, 55.1623380],
          [-2.4477560, 55.1623640],
          [-2.4477360, 55.1623940],
          [-2.4477140, 55.1624250],
          [-2.4476900, 55.1624560],
          [-2.4476670, 55.1624870],
          [-2.4476420, 55.1625210],
          [-2.4476160, 55.1625510],
          [-2.4475920, 55.1625770],
          [-2.4475660, 55.1626080],
          [-2.4475410, 55.1626390],
          [-2.4475170, 55.1626640],
          [-2.4474900, 55.1626930],
          [-2.4474650, 55.1627190],
          [-2.4474390, 55.1627450],
          [-2.4474140, 55.1627690],
          [-2.4473940, 55.1627950],
          [-2.4473790, 55.1628180],
          [-2.4473640, 55.1628440],
          [-2.4473490, 55.1628720],
          [-2.4473340, 55.1628980],
          [-2.4473220, 55.1629250],
          [-2.4473140, 55.1629470],
          [-2.4473050, 55.1629750],
          [-2.4473000, 55.1630050],
          [-2.4473000, 55.1630320],
          [-2.4472970, 55.1630570],
          [-2.4472950, 55.1630850],
          [-2.4472970, 55.1631160],
          [-2.4473000, 55.1631430],
          [-2.4472980, 55.1631720],
          [-2.4473000, 55.1632000],
          [-2.4472980, 55.1632300],
          [-2.4473000, 55.1632610],
          [-2.4472980, 55.1632860],
          [-2.4472970, 55.1633100],
          [-2.4472930, 55.1633300],
          [-2.4472920, 55.1633520],
          [-2.4472900, 55.1633730],
          [-2.4472870, 55.1633980],
          [-2.4472820, 55.1634210],
          [-2.4472800, 55.1634440],
          [-2.4472740, 55.1634660],
          [-2.4472680, 55.1634860],
          [-2.4472650, 55.1635050],
          [-2.4472620, 55.1635240],
          [-2.4472530, 55.1635470],
          [-2.4472490, 55.1635680],
          [-2.4472390, 55.1635930],
          [-2.4472300, 55.1636110],
          [-2.4472200, 55.1636310],
          [-2.4472190, 55.1636540],
          [-2.4472090, 55.1636720],
          [-2.4472050, 55.1636960],
          [-2.4472000, 55.1637150],
          [-2.4471940, 55.1637380],
          [-2.4471920, 55.1637530],
          [-2.4471850, 55.1637720],
          [-2.4471800, 55.1637870],
          [-2.4471770, 55.1638070],
          [-2.4471600, 55.1638220],
          [-2.4471490, 55.1638410],
          [-2.4471470, 55.1638550],
          [-2.4471420, 55.1638670],
          [-2.4471350, 55.1638830],
          [-2.4471280, 55.1638980],
          [-2.4471230, 55.1639100],
          [-2.4471200, 55.1639250],
          [-2.4471120, 55.1639400],
          [-2.4470970, 55.1639580],
          [-2.4470870, 55.1639780],
          [-2.4470690, 55.1639970],
          [-2.4470600, 55.1640160],
          [-2.4470540, 55.1640280],
          [-2.4470520, 55.1640420],
          [-2.4470450, 55.1640550],
          [-2.4470370, 55.1640700],
          [-2.4470370, 55.1640850],
          [-2.4470330, 55.1641040],
          [-2.4470270, 55.1641190],
          [-2.4470150, 55.1641350],
          [-2.4470010, 55.1641490],
          [-2.4469920, 55.1641690],
          [-2.4469800, 55.1641880],
          [-2.4469690, 55.1642030],
          [-2.4469600, 55.1642190],
          [-2.4469510, 55.1642360],
          [-2.4469390, 55.1642490],
          [-2.4469300, 55.1642680],
          [-2.4469200, 55.1642870],
          [-2.4469080, 55.1643030],
          [-2.4468920, 55.1643140],
          [-2.4468750, 55.1643330],
          [-2.4468590, 55.1643520],
          [-2.4468420, 55.1643710],
          [-2.4468320, 55.1643940],
          [-2.4468190, 55.1644130],
          [-2.4468050, 55.1644280],
          [-2.4467920, 55.1644470],
          [-2.4467770, 55.1644660],
          [-2.4467670, 55.1644850],
          [-2.4467540, 55.1645050],
          [-2.4467450, 55.1645270],
          [-2.4467300, 55.1645430],
          [-2.4467200, 55.1645580],
          [-2.4466990, 55.1645770],
          [-2.4466800, 55.1645960],
          [-2.4466670, 55.1646120],
          [-2.4466520, 55.1646230],
          [-2.4466390, 55.1646380],
          [-2.4466200, 55.1646540],
          [-2.4466070, 55.1646690],
          [-2.4465950, 55.1646880],
          [-2.4465800, 55.1647030],
          [-2.4465600, 55.1647220],
          [-2.4465420, 55.1647410],
          [-2.4465300, 55.1647560],
          [-2.4465150, 55.1647750],
          [-2.4465040, 55.1647940],
          [-2.4464890, 55.1648100],
          [-2.4464720, 55.1648250],
          [-2.4464570, 55.1648440],
          [-2.4464450, 55.1648630],
          [-2.4464270, 55.1648820],
          [-2.4464150, 55.1649050],
          [-2.4464020, 55.1649320],
          [-2.4463880, 55.1649550],
          [-2.4463750, 55.1649740],
          [-2.4463620, 55.1649930],
          [-2.4463490, 55.1650160],
          [-2.4463320, 55.1650350],
          [-2.4463150, 55.1650540],
          [-2.4463040, 55.1650730],
          [-2.4462920, 55.1650960],
          [-2.4462840, 55.1651150],
          [-2.4462740, 55.1651340],
          [-2.4462570, 55.1651530],
          [-2.4462450, 55.1651760],
          [-2.4462300, 55.1651950],
          [-2.4462150, 55.1652140],
          [-2.4462020, 55.1652290],
          [-2.4461870, 55.1652520],
          [-2.4461750, 55.1652710],
          [-2.4461610, 55.1652900],
          [-2.4461470, 55.1653100],
          [-2.4461390, 55.1653290],
          [-2.4461300, 55.1653510],
          [-2.4461230, 55.1653710],
          [-2.4461140, 55.1653900],
          [-2.4461040, 55.1654090],
          [-2.4460980, 55.1654280],
          [-2.4460980, 55.1654470],
          [-2.4460950, 55.1654620],
          [-2.4460900, 55.1654810],
          [-2.4460820, 55.1654970],
          [-2.4460770, 55.1655160],
          [-2.4460770, 55.1655340],
          [-2.4460800, 55.1655500],
          [-2.4460830, 55.1655650],
          [-2.4460820, 55.1655830],
          [-2.4460780, 55.1656030],
          [-2.4460770, 55.1656220],
          [-2.4460830, 55.1656380],
          [-2.4460880, 55.1656530],
          [-2.4460900, 55.1656720],
          [-2.4460950, 55.1656910],
          [-2.4461000, 55.1657060],
          [-2.4461030, 55.1657220],
          [-2.4460980, 55.1657330],
          [-2.4461020, 55.1657520],
          [-2.4461100, 55.1657710],
          [-2.4461180, 55.1657860],
          [-2.4461260, 55.1657980],
          [-2.4461380, 55.1658130],
          [-2.4461480, 55.1658280],
          [-2.4461580, 55.1658460],
          [-2.4461680, 55.1658620],
          [-2.4461770, 55.1658780],
          [-2.4461850, 55.1658890],
          [-2.4461910, 55.1659050],
          [-2.4461960, 55.1659160],
          [-2.4462030, 55.1659310],
          [-2.4462110, 55.1659430],
          [-2.4462200, 55.1659580],
          [-2.4462290, 55.1659730],
          [-2.4462360, 55.1659890],
          [-2.4462430, 55.1660000],
          [-2.4462500, 55.1660120],
          [-2.4462580, 55.1660230],
          [-2.4462660, 55.1660380],
          [-2.4462760, 55.1660500],
          [-2.4462880, 55.1660650],
          [-2.4462960, 55.1660800],
          [-2.4463080, 55.1660950],
          [-2.4463230, 55.1661110],
          [-2.4463350, 55.1661260],
          [-2.4463500, 55.1661410],
          [-2.4463600, 55.1661600],
          [-2.4463680, 55.1661750],
          [-2.4463810, 55.1661940],
          [-2.4463940, 55.1662100],
          [-2.4464060, 55.1662250],
          [-2.4464130, 55.1662370],
          [-2.4464260, 55.1662520],
          [-2.4464430, 55.1662710],
          [-2.4464580, 55.1662860],
          [-2.4464700, 55.1663010],
          [-2.4464810, 55.1663130],
          [-2.4464950, 55.1663280],
          [-2.4465030, 55.1663430],
          [-2.4465130, 55.1663620],
          [-2.4465230, 55.1663780],
          [-2.4465360, 55.1663930],
          [-2.4465460, 55.1664040],
          [-2.4465580, 55.1664160],
          [-2.4465710, 55.1664310],
          [-2.4465850, 55.1664460],
          [-2.4466000, 55.1664730],
          [-2.4466110, 55.1664920],
          [-2.4466230, 55.1665110],
          [-2.4466360, 55.1665410],
          [-2.4466480, 55.1665720],
          [-2.4466610, 55.1665950],
          [-2.4466730, 55.1666180],
          [-2.4466860, 55.1666440],
          [-2.4466980, 55.1666670],
          [-2.4467080, 55.1666900],
          [-2.4467130, 55.1667130],
          [-2.4467210, 55.1667360],
          [-2.4467300, 55.1667620],
          [-2.4467330, 55.1667850],
          [-2.4467320, 55.1668050],
          [-2.4467300, 55.1668270],
          [-2.4467180, 55.1668540],
          [-2.4467090, 55.1668770],
          [-2.4467050, 55.1669000],
          [-2.4467000, 55.1669190],
          [-2.4466920, 55.1669380],
          [-2.4466900, 55.1669610],
          [-2.4466870, 55.1669840],
          [-2.4466820, 55.1670110],
          [-2.4466750, 55.1670380],
          [-2.4466670, 55.1670680],
          [-2.4466590, 55.1670980],
          [-2.4466500, 55.1671290],
          [-2.4466440, 55.1671590],
          [-2.4466370, 55.1671900],
          [-2.4466300, 55.1672170],
          [-2.4466220, 55.1672510],
          [-2.4466120, 55.1672810],
          [-2.4466050, 55.1673120],
          [-2.4466020, 55.1673420],
          [-2.4465930, 55.1673690],
          [-2.4465900, 55.1673960],
          [-2.4465870, 55.1674190],
          [-2.4465750, 55.1674460],
          [-2.4465590, 55.1674720],
          [-2.4465450, 55.1674980],
          [-2.4465370, 55.1675220],
          [-2.4465300, 55.1675450],
          [-2.4465170, 55.1675720],
          [-2.4465090, 55.1675980],
          [-2.4465070, 55.1676250],
          [-2.4465020, 55.1676480],
          [-2.4464950, 55.1676660],
          [-2.4464850, 55.1676860],
          [-2.4464750, 55.1677120],
          [-2.4464650, 55.1677390],
          [-2.4464540, 55.1677620],
          [-2.4464420, 55.1677850],
          [-2.4464300, 55.1678080],
          [-2.4464190, 55.1678310],
          [-2.4464130, 55.1678540],
          [-2.4464050, 55.1678810],
          [-2.4463890, 55.1679110],
          [-2.4463670, 55.1679340],
          [-2.4463490, 55.1679610],
          [-2.4463270, 55.1679870],
          [-2.4463090, 55.1680140],
          [-2.4462920, 55.1680400],
          [-2.4462740, 55.1680600],
          [-2.4462490, 55.1680860],
          [-2.4462190, 55.1681210],
          [-2.4461900, 55.1681510],
          [-2.4461590, 55.1681850],
          [-2.4461290, 55.1682240],
          [-2.4460890, 55.1682610],
          [-2.4460420, 55.1683040],
          [-2.4459950, 55.1683450],
          [-2.4459460, 55.1683910],
          [-2.4458980, 55.1684370],
          [-2.4458460, 55.1684870],
          [-2.4457890, 55.1685320],
          [-2.4457410, 55.1685820],
          [-2.4456910, 55.1686390],
          [-2.4456360, 55.1686960],
          [-2.4455820, 55.1687570],
          [-2.4455310, 55.1688180],
          [-2.4454890, 55.1688750],
          [-2.4454540, 55.1689330],
          [-2.4454240, 55.1689940],
          [-2.4454000, 55.1690470],
          [-2.4453870, 55.1691120],
          [-2.4453780, 55.1691700],
          [-2.4453750, 55.1692270],
          [-2.4453700, 55.1692840],
          [-2.4453670, 55.1693450],
          [-2.4453750, 55.1694060],
          [-2.4453910, 55.1694730],
          [-2.4454090, 55.1695600],
          [-2.4454390, 55.1696430],
          [-2.4454710, 55.1697330],
          [-2.4455040, 55.1698330],
          [-2.4455380, 55.1699310],
          [-2.4455550, 55.1700420],
          [-2.4455650, 55.1701490],
          [-2.4455710, 55.1702420],
          [-2.4455860, 55.1703500],
          [-2.4456000, 55.1704620],
          [-2.4456020, 55.1705650],
          [-2.4455910, 55.1706540],
          [-2.4455750, 55.1707550],
          [-2.4455510, 55.1708550],
          [-2.4455290, 55.1709570],
          [-2.4455150, 55.1710720],
          [-2.4455050, 55.1711860],
          [-2.4455100, 55.1713020],
          [-2.4455400, 55.1714230],
          [-2.4455950, 55.1715370],
          [-2.4456520, 55.1716860],
          [-2.4457530, 55.1717960],
          [-2.4458760, 55.1718890],
          [-2.4459980, 55.1719650],
          [-2.4461240, 55.1720380],
          [-2.4462470, 55.1721100],
          [-2.4463580, 55.1721710],
          [-2.4464670, 55.1722210],
          [-2.4465630, 55.1722690],
          [-2.4466690, 55.1723280],
          [-2.4467680, 55.1723890],
          [-2.4468680, 55.1724420],
          [-2.4469650, 55.1725000],
          [-2.4470580, 55.1725550],
          [-2.4471570, 55.1726100],
          [-2.4472600, 55.1726590],
          [-2.4473590, 55.1727090],
          [-2.4474580, 55.1727590],
          [-2.4475600, 55.1728040],
          [-2.4476540, 55.1728490],
          [-2.4477520, 55.1728990],
          [-2.4478480, 55.1729500],
          [-2.4479410, 55.1729990],
          [-2.4480350, 55.1730410],
          [-2.4481300, 55.1730870],
          [-2.4482280, 55.1731360],
          [-2.4483300, 55.1731860],
          [-2.4484340, 55.1732360],
          [-2.4485430, 55.1732840],
          [-2.4486650, 55.1733390],
          [-2.4487920, 55.1733880],
          [-2.4489240, 55.1734340],
          [-2.4490600, 55.1734840],
          [-2.4492080, 55.1735330],
          [-2.4493480, 55.1735820],
          [-2.4494710, 55.1736280],
          [-2.4495970, 55.1736780],
          [-2.4497210, 55.1737240],
          [-2.4498320, 55.1737700],
          [-2.4499390, 55.1738120],
          [-2.4500340, 55.1738500],
          [-2.4501170, 55.1738810],
          [-2.4501980, 55.1739110],
          [-2.4502670, 55.1739420],
          [-2.4503300, 55.1739690],
          [-2.4503860, 55.1739950],
          [-2.4504400, 55.1740140],
          [-2.4504950, 55.1740340],
          [-2.4505470, 55.1740490],
          [-2.4506000, 55.1740610],
          [-2.4506550, 55.1740760],
          [-2.4507080, 55.1740910],
          [-2.4507560, 55.1740990],
          [-2.4507980, 55.1741060],
          [-2.4508430, 55.1741140],
          [-2.4508860, 55.1741220],
          [-2.4509270, 55.1741220],
          [-2.4509680, 55.1741290],
          [-2.4510120, 55.1741300],
          [-2.4510560, 55.1741330],
          [-2.4510940, 55.1741370],
          [-2.4511380, 55.1741450],
          [-2.4511800, 55.1741450],
          [-2.4512230, 55.1741480],
          [-2.4512690, 55.1741560],
          [-2.4513130, 55.1741560],
          [-2.4513570, 55.1741560],
          [-2.4513990, 55.1741520],
          [-2.4514410, 55.1741520],
          [-2.4514790, 55.1741520],
          [-2.4515200, 55.1741520],
          [-2.4515640, 55.1741520],
          [-2.4516060, 55.1741560],
          [-2.4516460, 55.1741560],
          [-2.4516890, 55.1741520],
          [-2.4517310, 55.1741490],
          [-2.4517710, 55.1741490],
          [-2.4518120, 55.1741490],
          [-2.4518540, 55.1741490],
          [-2.4518980, 55.1741450],
          [-2.4519420, 55.1741410],
          [-2.4519850, 55.1741370],
          [-2.4520320, 55.1741330],
          [-2.4520760, 55.1741300],
          [-2.4521130, 55.1741260],
          [-2.4521560, 55.1741220],
          [-2.4522010, 55.1741220],
          [-2.4522450, 55.1741180],
          [-2.4522930, 55.1741180],
          [-2.4523400, 55.1741180],
          [-2.4523900, 55.1741220],
          [-2.4524390, 55.1741290],
          [-2.4524880, 55.1741330],
          [-2.4525380, 55.1741410],
          [-2.4525930, 55.1741480],
          [-2.4526540, 55.1741520],
          [-2.4527140, 55.1741600],
          [-2.4527720, 55.1741670],
          [-2.4528290, 55.1741750],
          [-2.4528820, 55.1741860],
          [-2.4529360, 55.1741980],
          [-2.4529900, 55.1742130],
          [-2.4530470, 55.1742250],
          [-2.4531050, 55.1742400],
          [-2.4531660, 55.1742590],
          [-2.4532050, 55.1742690],
          [-2.4532780, 55.1742890],
          [-2.4533380, 55.1743010],
          [-2.4534010, 55.1743160],
          [-2.4534650, 55.1743270],
          [-2.4535360, 55.1743430],
          [-2.4536020, 55.1743540],
          [-2.4536660, 55.1743690],
          [-2.4537200, 55.1743820],
          [-2.4537940, 55.1743960],
          [-2.4538590, 55.1744110],
          [-2.4539270, 55.1744270],
          [-2.4539930, 55.1744380],
          [-2.4540660, 55.1744540],
          [-2.4541340, 55.1744650],
          [-2.4542040, 55.1744730],
          [-2.4542660, 55.1744840],
          [-2.4543320, 55.1744950],
          [-2.4544040, 55.1745070],
          [-2.4544730, 55.1745180],
          [-2.4545390, 55.1745260],
          [-2.4546110, 55.1745340],
          [-2.4546790, 55.1745410],
          [-2.4547540, 55.1745530],
          [-2.4548260, 55.1745600],
          [-2.4549010, 55.1745640],
          [-2.4549770, 55.1745720],
          [-2.4550610, 55.1745830],
          [-2.4551430, 55.1745950],
          [-2.4552300, 55.1746100],
          [-2.4553180, 55.1746280],
          [-2.4554130, 55.1746550],
          [-2.4555100, 55.1746970],
          [-2.4556090, 55.1747390],
          [-2.4557030, 55.1747900],
          [-2.4558200, 55.1748550],
          [-2.4559430, 55.1749240],
          [-2.4561110, 55.1749950],
          [-2.4563020, 55.1750480],
          [-2.4564520, 55.1751090],
          [-2.4565900, 55.1751430],
          [-2.4567350, 55.1751700],
          [-2.4568820, 55.1751780],
          [-2.4570280, 55.1751820],
          [-2.4571610, 55.1751790],
          [-2.4573000, 55.1751630],
          [-2.4574270, 55.1751410],
          [-2.4575660, 55.1751140],
          [-2.4576960, 55.1750840],
          [-2.4578330, 55.1750570],
          [-2.4579660, 55.1750300],
          [-2.4580950, 55.1750040],
          [-2.4582320, 55.1749730],
          [-2.4583640, 55.1749460],
          [-2.4584950, 55.1749200],
          [-2.4586250, 55.1748900],
          [-2.4587570, 55.1748630],
          [-2.4588920, 55.1748360],
          [-2.4590220, 55.1748050],
          [-2.4591520, 55.1747780],
          [-2.4592790, 55.1747520],
          [-2.4593890, 55.1747240],
          [-2.4595370, 55.1746880],
          [-2.4596700, 55.1746540],
          [-2.4598080, 55.1746220],
          [-2.4599400, 55.1746000],
          [-2.4600870, 55.1745840],
          [-2.4602330, 55.1745690],
          [-2.4603900, 55.1745640],
          [-2.4605500, 55.1745640],
          [-2.4607100, 55.1745680],
          [-2.4608680, 55.1745830],
          [-2.4610150, 55.1746090],
          [-2.4611590, 55.1746400],
          [-2.4612880, 55.1746880],
          [-2.4614090, 55.1747470],
          [-2.4615030, 55.1748150],
          [-2.4615780, 55.1748810],
          [-2.4616310, 55.1749600],
          [-2.4616460, 55.1750420],
          [-2.4616320, 55.1751350],
          [-2.4615740, 55.1752390],
          [-2.4614890, 55.1753150],
          [-2.4613740, 55.1753950],
          [-2.4612310, 55.1754640],
          [-2.4611260, 55.1755320],
          [-2.4610050, 55.1756270],
          [-2.4609350, 55.1757180],
          [-2.4608470, 55.1758360],
          [-2.4608140, 55.1759650],
          [-2.4607740, 55.1760890],
          [-2.4607650, 55.1762030],
          [-2.4607430, 55.1763040],
          [-2.4607580, 55.1764010],
          [-2.4607680, 55.1764780],
          [-2.4607810, 55.1765460],
          [-2.4608010, 55.1766040],
          [-2.4608350, 55.1766400],
          [-2.4608890, 55.1766760],
          [-2.4609610, 55.1766970],
          [-2.4610520, 55.1767010],
          [-2.4611500, 55.1766930],
          [-2.4612560, 55.1766790],
          [-2.4613680, 55.1766630],
          [-2.4614820, 55.1766440],
          [-2.4615970, 55.1766210],
          [-2.4617130, 55.1766020],
          [-2.4618270, 55.1765760],
          [-2.4619470, 55.1765490],
          [-2.4620640, 55.1765220],
          [-2.4621740, 55.1764970],
          [-2.4622940, 55.1764730],
          [-2.4624080, 55.1764470],
          [-2.4625300, 55.1764310],
          [-2.4626440, 55.1764440],
          [-2.4627290, 55.1764810],
          [-2.4627890, 55.1765520],
          [-2.4627720, 55.1766270],
          [-2.4627100, 55.1766920],
          [-2.4626180, 55.1767480],
          [-2.4625300, 55.1768020],
          [-2.4624390, 55.1768560],
          [-2.4623510, 55.1769020],
          [-2.4622570, 55.1769540],
          [-2.4621600, 55.1770040],
          [-2.4620650, 55.1770530],
          [-2.4619670, 55.1771020],
          [-2.4618660, 55.1771530],
          [-2.4617650, 55.1772060],
          [-2.4616670, 55.1772560],
          [-2.4615710, 55.1773050],
          [-2.4614740, 55.1773540],
          [-2.4613770, 55.1774040],
          [-2.4612760, 55.1774550],
          [-2.4611830, 55.1775040],
          [-2.4610910, 55.1775540],
          [-2.4610050, 55.1776060],
          [-2.4609230, 55.1776610],
          [-2.4608570, 55.1777130],
          [-2.4607870, 55.1777760],
          [-2.4607240, 55.1778360],
          [-2.4606720, 55.1778990],
          [-2.4606270, 55.1779660],
          [-2.4605890, 55.1780300],
          [-2.4605590, 55.1780950],
          [-2.4605340, 55.1781630],
          [-2.4605170, 55.1782280],
          [-2.4605070, 55.1782980],
          [-2.4605030, 55.1783690],
          [-2.4605050, 55.1784460],
          [-2.4605160, 55.1785180],
          [-2.4605360, 55.1785940],
          [-2.4605620, 55.1786650],
          [-2.4605950, 55.1787390],
          [-2.4606250, 55.1788110],
          [-2.4606540, 55.1788840],
          [-2.4606810, 55.1789570],
          [-2.4607040, 55.1790320],
          [-2.4607290, 55.1791040],
          [-2.4607500, 55.1791760],
          [-2.4607730, 55.1792550],
          [-2.4607920, 55.1793250],
          [-2.4608100, 55.1794000],
          [-2.4608290, 55.1794750],
          [-2.4608460, 55.1795480],
          [-2.4608650, 55.1796210],
          [-2.4608810, 55.1796890],
          [-2.4608970, 55.1797590],
          [-2.4609100, 55.1798260],
          [-2.4609240, 55.1798810],
          [-2.4609390, 55.1799560],
          [-2.4609510, 55.1800170],
          [-2.4609660, 55.1800900],
          [-2.4609790, 55.1801510],
          [-2.4609900, 55.1802150],
          [-2.4610000, 55.1802770],
          [-2.4610100, 55.1803420],
          [-2.4610200, 55.1804030],
          [-2.4610300, 55.1804630],
          [-2.4610380, 55.1805250],
          [-2.4610460, 55.1805850],
          [-2.4610570, 55.1806440],
          [-2.4610670, 55.1807010],
          [-2.4610760, 55.1807610],
          [-2.4610850, 55.1808190],
          [-2.4610920, 55.1808770],
          [-2.4610980, 55.1809330],
          [-2.4611050, 55.1809950],
          [-2.4611120, 55.1810520],
          [-2.4611230, 55.1811130],
          [-2.4611330, 55.1811730],
          [-2.4611410, 55.1812310],
          [-2.4611490, 55.1812900],
          [-2.4611550, 55.1813430],
          [-2.4611610, 55.1814140],
          [-2.4611680, 55.1814750],
          [-2.4611730, 55.1815310],
          [-2.4611780, 55.1815950],
          [-2.4611810, 55.1816610],
          [-2.4611870, 55.1817230],
          [-2.4611900, 55.1817830],
          [-2.4611930, 55.1818450],
          [-2.4611970, 55.1819040],
          [-2.4611980, 55.1819670],
          [-2.4611980, 55.1820280],
          [-2.4612030, 55.1820850],
          [-2.4612070, 55.1821500],
          [-2.4612100, 55.1822070],
          [-2.4612130, 55.1822680],
          [-2.4612130, 55.1823290],
          [-2.4612130, 55.1823880],
          [-2.4612150, 55.1824520],
          [-2.4612130, 55.1825130],
          [-2.4612130, 55.1825740],
          [-2.4612150, 55.1826330],
          [-2.4612130, 55.1826920],
          [-2.4612130, 55.1827520],
          [-2.4612120, 55.1828130],
          [-2.4612100, 55.1828750],
          [-2.4612080, 55.1829320],
          [-2.4612070, 55.1829900],
          [-2.4612050, 55.1830490],
          [-2.4612020, 55.1831110],
          [-2.4612000, 55.1831680],
          [-2.4611970, 55.1832290],
          [-2.4611920, 55.1832870],
          [-2.4611870, 55.1833490],
          [-2.4611790, 55.1834050],
          [-2.4611700, 55.1834650],
          [-2.4611620, 55.1835240],
          [-2.4611570, 55.1835840],
          [-2.4611530, 55.1836450],
          [-2.4611500, 55.1837040],
          [-2.4611470, 55.1837710],
          [-2.4611450, 55.1838330],
          [-2.4611380, 55.1838930],
          [-2.4611300, 55.1839510],
          [-2.4611220, 55.1840110],
          [-2.4611130, 55.1840690],
          [-2.4611050, 55.1841300],
          [-2.4610970, 55.1841870],
          [-2.4610890, 55.1842480],
          [-2.4610770, 55.1843040],
          [-2.4610670, 55.1843630],
          [-2.4610600, 55.1844190],
          [-2.4610520, 55.1844810],
          [-2.4610420, 55.1845380],
          [-2.4610300, 55.1845990],
          [-2.4610190, 55.1846570],
          [-2.4610070, 55.1847130],
          [-2.4609940, 55.1847700],
          [-2.4609800, 55.1848330],
          [-2.4609670, 55.1848840],
          [-2.4609570, 55.1849460],
          [-2.4609450, 55.1850030],
          [-2.4609370, 55.1850560],
          [-2.4609270, 55.1851150],
          [-2.4609150, 55.1851710],
          [-2.4609020, 55.1852240],
          [-2.4608870, 55.1852780],
          [-2.4608740, 55.1853310],
          [-2.4608600, 55.1853890],
          [-2.4608450, 55.1854420],
          [-2.4608300, 55.1854970],
          [-2.4608170, 55.1855530],
          [-2.4608020, 55.1856070],
          [-2.4607870, 55.1856630],
          [-2.4607720, 55.1857170],
          [-2.4607560, 55.1857700],
          [-2.4607390, 55.1858280],
          [-2.4607220, 55.1858810],
          [-2.4607070, 55.1859370],
          [-2.4606910, 55.1859910],
          [-2.4606720, 55.1860490],
          [-2.4606560, 55.1860990],
          [-2.4606320, 55.1861590],
          [-2.4606120, 55.1862090],
          [-2.4605890, 55.1862700],
          [-2.4605690, 55.1863270],
          [-2.4605490, 55.1863840],
          [-2.4605260, 55.1864410],
          [-2.4605010, 55.1864990],
          [-2.4604740, 55.1865520],
          [-2.4604530, 55.1866090],
          [-2.4604340, 55.1866660],
          [-2.4604170, 55.1867200],
          [-2.4603990, 55.1867760],
          [-2.4603790, 55.1868380],
          [-2.4603590, 55.1868910],
          [-2.4603360, 55.1869490],
          [-2.4603160, 55.1870050],
          [-2.4602940, 55.1870630],
          [-2.4602720, 55.1871130],
          [-2.4602510, 55.1871630],
          [-2.4602280, 55.1872120],
          [-2.4602060, 55.1872620],
          [-2.4601870, 55.1873110],
          [-2.4601800, 55.1873640],
          [-2.4601870, 55.1874150],
          [-2.4602050, 55.1874650],
          [-2.4602280, 55.1875080],
          [-2.4602680, 55.1875520],
          [-2.4603270, 55.1875800],
          [-2.4603920, 55.1876060],
          [-2.4604530, 55.1876300],
          [-2.4605270, 55.1876620],
          [-2.4605980, 55.1876900],
          [-2.4606650, 55.1877160],
          [-2.4607310, 55.1877440],
          [-2.4607970, 55.1877670],
          [-2.4608650, 55.1877890],
          [-2.4609300, 55.1878150],
          [-2.4609940, 55.1878430],
          [-2.4610490, 55.1878690],
          [-2.4610960, 55.1879000],
          [-2.4611250, 55.1879370],
          [-2.4611310, 55.1879750],
          [-2.4611220, 55.1880160],
          [-2.4610940, 55.1880560],
          [-2.4610500, 55.1880830],
          [-2.4609950, 55.1881020],
          [-2.4609350, 55.1881100],
          [-2.4608760, 55.1881070],
          [-2.4608240, 55.1880960],
          [-2.4607710, 55.1880800],
          [-2.4607210, 55.1880620],
          [-2.4606710, 55.1880420],
          [-2.4606250, 55.1880270],
          [-2.4605740, 55.1880110],
          [-2.4605270, 55.1879930],
          [-2.4604760, 55.1879770],
          [-2.4604310, 55.1879600],
          [-2.4603710, 55.1879430],
          [-2.4603150, 55.1879280],
          [-2.4602590, 55.1879080],
          [-2.4602010, 55.1878930],
          [-2.4601440, 55.1878740],
          [-2.4600920, 55.1878560],
          [-2.4600330, 55.1878400],
          [-2.4599730, 55.1878210],
          [-2.4599140, 55.1878090],
          [-2.4598560, 55.1877910],
          [-2.4597950, 55.1877680],
          [-2.4597320, 55.1877480],
          [-2.4596710, 55.1877260],
          [-2.4596090, 55.1877070],
          [-2.4595420, 55.1876830],
          [-2.4594780, 55.1876610],
          [-2.4594060, 55.1876420],
          [-2.4593360, 55.1876190],
          [-2.4592630, 55.1876000],
          [-2.4591840, 55.1875770],
          [-2.4591060, 55.1875540],
          [-2.4590190, 55.1875340],
          [-2.4589340, 55.1875090],
          [-2.4588530, 55.1874840],
          [-2.4587480, 55.1874550],
          [-2.4586500, 55.1874290],
          [-2.4585360, 55.1873970],
          [-2.4584220, 55.1873670],
          [-2.4583020, 55.1873300],
          [-2.4581750, 55.1872940],
          [-2.4580490, 55.1872570],
          [-2.4579140, 55.1872180],
          [-2.4577830, 55.1871730],
          [-2.4576420, 55.1871270],
          [-2.4575040, 55.1870790],
          [-2.4573560, 55.1870320],
          [-2.4572080, 55.1869900],
          [-2.4570520, 55.1869480],
          [-2.4568940, 55.1869130],
          [-2.4567360, 55.1868790],
          [-2.4565750, 55.1868560],
          [-2.4564120, 55.1868400],
          [-2.4562530, 55.1868400],
          [-2.4560840, 55.1868440],
          [-2.4559130, 55.1868550],
          [-2.4557400, 55.1868700],
          [-2.4555710, 55.1868930],
          [-2.4553860, 55.1869200],
          [-2.4552030, 55.1869430],
          [-2.4550100, 55.1869660],
          [-2.4548260, 55.1869810],
          [-2.4546320, 55.1869960],
          [-2.4544410, 55.1870110],
          [-2.4542550, 55.1870230],
          [-2.4540870, 55.1870370],
          [-2.4538890, 55.1870570],
          [-2.4536980, 55.1870760],
          [-2.4535040, 55.1870990],
          [-2.4533140, 55.1871180],
          [-2.4531390, 55.1871300],
          [-2.4529370, 55.1871340],
          [-2.4527600, 55.1871490],
          [-2.4526060, 55.1871590],
          [-2.4524490, 55.1871670],
          [-2.4522700, 55.1871720],
          [-2.4521250, 55.1871790],
          [-2.4519870, 55.1871830],
          [-2.4518160, 55.1871870],
          [-2.4516860, 55.1871940],
          [-2.4515300, 55.1872020],
          [-2.4513900, 55.1872140],
          [-2.4512520, 55.1872250],
          [-2.4511280, 55.1872360],
          [-2.4509990, 55.1872440],
          [-2.4508680, 55.1872520],
          [-2.4507490, 55.1872630],
          [-2.4506310, 55.1872690],
          [-2.4505140, 55.1872750],
          [-2.4504030, 55.1872790],
          [-2.4502890, 55.1872820],
          [-2.4501750, 55.1872860],
          [-2.4500580, 55.1872860],
          [-2.4499450, 55.1872860],
          [-2.4498300, 55.1872830],
          [-2.4497170, 55.1872830],
          [-2.4496070, 55.1872790],
          [-2.4494980, 55.1872750],
          [-2.4493910, 55.1872750],
          [-2.4492870, 55.1872670],
          [-2.4491790, 55.1872560],
          [-2.4490770, 55.1872480],
          [-2.4489740, 55.1872330],
          [-2.4488720, 55.1872220],
          [-2.4487700, 55.1872140],
          [-2.4486720, 55.1872060],
          [-2.4485800, 55.1871990],
          [-2.4484850, 55.1871950],
          [-2.4483920, 55.1871990],
          [-2.4483020, 55.1872060],
          [-2.4482200, 55.1872140],
          [-2.4481520, 55.1872290],
          [-2.4480930, 55.1872370],
          [-2.4480420, 55.1872480],
          [-2.4479920, 55.1872560],
          [-2.4479470, 55.1872670],
          [-2.4479010, 55.1872750],
          [-2.4478560, 55.1872820],
          [-2.4478110, 55.1872900],
          [-2.4477700, 55.1872980],
          [-2.4477260, 55.1873020],
          [-2.4476840, 55.1873050],
          [-2.4476410, 55.1873130],
          [-2.4475990, 55.1873170],
          [-2.4475570, 55.1873210],
          [-2.4475160, 55.1873280],
          [-2.4474720, 55.1873320],
          [-2.4474310, 55.1873400],
          [-2.4473890, 55.1873400],
          [-2.4473470, 55.1873430],
          [-2.4473030, 55.1873470],
          [-2.4472620, 55.1873470],
          [-2.4472190, 55.1873510],
          [-2.4471810, 55.1873550],
          [-2.4471430, 55.1873590],
          [-2.4471060, 55.1873630],
          [-2.4470710, 55.1873660],
          [-2.4470380, 55.1873660],
          [-2.4470040, 55.1873660],
          [-2.4469710, 55.1873660],
          [-2.4469370, 55.1873660],
          [-2.4469040, 55.1873700],
          [-2.4468710, 55.1873700],
          [-2.4468380, 55.1873700],
          [-2.4468040, 55.1873700],
          [-2.4467700, 55.1873740],
          [-2.4467390, 55.1873740],
          [-2.4467090, 55.1873780],
          [-2.4466770, 55.1873820],
          [-2.4466490, 55.1873780],
          [-2.4466210, 55.1873780],
          [-2.4465900, 55.1873780],
          [-2.4465570, 55.1873820],
          [-2.4465260, 55.1873820],
          [-2.4464960, 55.1873850],
          [-2.4464660, 55.1873930],
          [-2.4464340, 55.1873930],
          [-2.4464040, 55.1873930],
          [-2.4463730, 55.1873930],
          [-2.4463450, 55.1873970],
          [-2.4463170, 55.1874040],
          [-2.4462860, 55.1874080],
          [-2.4462560, 55.1874160],
          [-2.4462270, 55.1874240],
          [-2.4461990, 55.1874310],
          [-2.4461710, 55.1874350],
          [-2.4461440, 55.1874350],
          [-2.4461160, 55.1874430],
          [-2.4460890, 55.1874500],
          [-2.4460640, 55.1874610],
          [-2.4460390, 55.1874730],
          [-2.4460130, 55.1874770],
          [-2.4459820, 55.1874850],
          [-2.4459540, 55.1874920],
          [-2.4459260, 55.1875000],
          [-2.4459010, 55.1875070],
          [-2.4458740, 55.1875190],
          [-2.4458470, 55.1875270],
          [-2.4458200, 55.1875380],
          [-2.4457980, 55.1875450],
          [-2.4457740, 55.1875570],
          [-2.4457520, 55.1875680],
          [-2.4457260, 55.1875800],
          [-2.4456990, 55.1875910],
          [-2.4456700, 55.1875990],
          [-2.4456440, 55.1876100],
          [-2.4456190, 55.1876220],
          [-2.4455940, 55.1876330],
          [-2.4455690, 55.1876410],
          [-2.4455450, 55.1876520],
          [-2.4455200, 55.1876600],
          [-2.4454960, 55.1876680],
          [-2.4454710, 55.1876750],
          [-2.4454470, 55.1876830],
          [-2.4454240, 55.1876940],
          [-2.4454010, 55.1877060],
          [-2.4453770, 55.1877170],
          [-2.4453510, 55.1877250],
          [-2.4453240, 55.1877290],
          [-2.4452990, 55.1877360],
          [-2.4452750, 55.1877480],
          [-2.4452510, 55.1877550],
          [-2.4452240, 55.1877670],
          [-2.4451990, 55.1877740],
          [-2.4451700, 55.1877820],
          [-2.4451400, 55.1877900],
          [-2.4451130, 55.1877970],
          [-2.4450840, 55.1878050],
          [-2.4450590, 55.1878130],
          [-2.4450320, 55.1878200],
          [-2.4450070, 55.1878320],
          [-2.4449800, 55.1878390],
          [-2.4449560, 55.1878470],
          [-2.4449310, 55.1878550],
          [-2.4449040, 55.1878660],
          [-2.4448770, 55.1878700],
          [-2.4448520, 55.1878780],
          [-2.4448300, 55.1878890],
          [-2.4448060, 55.1878970],
          [-2.4447810, 55.1879040],
          [-2.4447540, 55.1879120],
          [-2.4447270, 55.1879190],
          [-2.4447040, 55.1879230],
          [-2.4446810, 55.1879350],
          [-2.4446590, 55.1879420],
          [-2.4446340, 55.1879500],
          [-2.4446080, 55.1879540],
          [-2.4445810, 55.1879610],
          [-2.4445560, 55.1879650],
          [-2.4445270, 55.1879730],
          [-2.4445020, 55.1879800],
          [-2.4444770, 55.1879880],
          [-2.4444510, 55.1879960],
          [-2.4444260, 55.1880030],
          [-2.4443990, 55.1880110],
          [-2.4443720, 55.1880150],
          [-2.4443460, 55.1880220],
          [-2.4443170, 55.1880260],
          [-2.4442880, 55.1880340],
          [-2.4442560, 55.1880380],
          [-2.4442260, 55.1880420],
          [-2.4441960, 55.1880490],
          [-2.4441660, 55.1880530],
          [-2.4441370, 55.1880570],
          [-2.4441070, 55.1880640],
          [-2.4440810, 55.1880680],
          [-2.4440510, 55.1880720],
          [-2.4440220, 55.1880760],
          [-2.4439930, 55.1880800],
          [-2.4439590, 55.1880840],
          [-2.4439290, 55.1880870],
          [-2.4439010, 55.1880910],
          [-2.4438710, 55.1880950],
          [-2.4438390, 55.1880990],
          [-2.4438090, 55.1881030],
          [-2.4437800, 55.1881060],
          [-2.4437470, 55.1881100],
          [-2.4437160, 55.1881180],
          [-2.4436860, 55.1881220],
          [-2.4436560, 55.1881290],
          [-2.4436240, 55.1881370],
          [-2.4435920, 55.1881440],
          [-2.4435600, 55.1881480],
          [-2.4435290, 55.1881520],
          [-2.4434990, 55.1881600],
          [-2.4434690, 55.1881670],
          [-2.4434400, 55.1881750],
          [-2.4434110, 55.1881860],
          [-2.4433810, 55.1881980],
          [-2.4433540, 55.1882050],
          [-2.4433270, 55.1882170],
          [-2.4433010, 55.1882320],
          [-2.4432740, 55.1882440],
          [-2.4432450, 55.1882590],
          [-2.4432170, 55.1882780],
          [-2.4431910, 55.1882930],
          [-2.4431640, 55.1883080],
          [-2.4431340, 55.1883230],
          [-2.4431000, 55.1883380],
          [-2.4430660, 55.1883580],
          [-2.4430340, 55.1883770],
          [-2.4429990, 55.1884000],
          [-2.4429610, 55.1884190],
          [-2.4429130, 55.1884380],
          [-2.4428580, 55.1884530],
          [-2.4427900, 55.1884650],
          [-2.4427210, 55.1884730],
          [-2.4426530, 55.1884730],
          [-2.4425900, 55.1884690],
          [-2.4425340, 55.1884650],
          [-2.4424830, 55.1884610],
          [-2.4424380, 55.1884540],
          [-2.4423970, 55.1884460],
          [-2.4423570, 55.1884350],
          [-2.4423180, 55.1884270],
          [-2.4422770, 55.1884190],
          [-2.4422380, 55.1884120],
          [-2.4421970, 55.1884080],
          [-2.4421560, 55.1884000],
          [-2.4421170, 55.1883970],
          [-2.4420760, 55.1883890],
          [-2.4420340, 55.1883850],
          [-2.4419910, 55.1883850],
          [-2.4419460, 55.1883850],
          [-2.4419010, 55.1883850],
          [-2.4418570, 55.1883850],
          [-2.4418120, 55.1883850],
          [-2.4417730, 55.1883850],
          [-2.4417240, 55.1883890],
          [-2.4416780, 55.1883960],
          [-2.4416320, 55.1884040],
          [-2.4415840, 55.1884150],
          [-2.4415380, 55.1884230],
          [-2.4414920, 55.1884310],
          [-2.4414490, 55.1884380],
          [-2.4414100, 55.1884490],
          [-2.4413710, 55.1884570],
          [-2.4413320, 55.1884690],
          [-2.4412970, 55.1884800],
          [-2.4412620, 55.1884920],
          [-2.4412240, 55.1885030],
          [-2.4411860, 55.1885140],
          [-2.4411490, 55.1885260],
          [-2.4411120, 55.1885370],
          [-2.4410740, 55.1885450],
          [-2.4410340, 55.1885570],
          [-2.4409960, 55.1885680],
          [-2.4409610, 55.1885790],
          [-2.4409260, 55.1885910],
          [-2.4408940, 55.1886060],
          [-2.4408590, 55.1886180],
          [-2.4408260, 55.1886290],
          [-2.4407940, 55.1886400],
          [-2.4407620, 55.1886480],
          [-2.4407290, 55.1886630],
          [-2.4406940, 55.1886750],
          [-2.4406610, 55.1886860],
          [-2.4406260, 55.1886980],
          [-2.4405920, 55.1887130],
          [-2.4405610, 55.1887280],
          [-2.4405270, 55.1887390],
          [-2.4404950, 55.1887540],
          [-2.4404590, 55.1887700],
          [-2.4404240, 55.1887810],
          [-2.4403910, 55.1887930],
          [-2.4403570, 55.1888080],
          [-2.4403220, 55.1888200],
          [-2.4402870, 55.1888310],
          [-2.4402510, 55.1888390],
          [-2.4402170, 55.1888500],
          [-2.4401840, 55.1888620],
          [-2.4401490, 55.1888730],
          [-2.4401160, 55.1888880],
          [-2.4400810, 55.1889000],
          [-2.4400510, 55.1889070],
          [-2.4400190, 55.1889150],
          [-2.4399860, 55.1889230],
          [-2.4399510, 55.1889340],
          [-2.4399120, 55.1889420],
          [-2.4398780, 55.1889530],
          [-2.4398430, 55.1889570],
          [-2.4398040, 55.1889650],
          [-2.4397660, 55.1889760],
          [-2.4397270, 55.1889880],
          [-2.4396880, 55.1889910],
          [-2.4396480, 55.1889990],
          [-2.4396080, 55.1890070],
          [-2.4395690, 55.1890140],
          [-2.4395270, 55.1890180],
          [-2.4394880, 55.1890180],
          [-2.4394480, 55.1890150],
          [-2.4394070, 55.1890110],
          [-2.4393650, 55.1890070],
          [-2.4393240, 55.1890070],
          [-2.4392830, 55.1890070],
          [-2.4392390, 55.1889990],
          [-2.4391980, 55.1889950],
          [-2.4391540, 55.1889840],
          [-2.4391130, 55.1889760],
          [-2.4390690, 55.1889650],
          [-2.4390230, 55.1889540],
          [-2.4389760, 55.1889390],
          [-2.4389280, 55.1889240],
          [-2.4388740, 55.1889050],
          [-2.4388200, 55.1888820],
          [-2.4387650, 55.1888590],
          [-2.4387080, 55.1888320],
          [-2.4386460, 55.1888050],
          [-2.4385830, 55.1887780],
          [-2.4385180, 55.1887520],
          [-2.4384510, 55.1887210],
          [-2.4383870, 55.1886910],
          [-2.4383250, 55.1886650],
          [-2.4382670, 55.1886300],
          [-2.4382180, 55.1885960],
          [-2.4381690, 55.1885570],
          [-2.4381280, 55.1885200],
          [-2.4380890, 55.1884780],
          [-2.4380520, 55.1884350],
          [-2.4380120, 55.1883930],
          [-2.4379760, 55.1883480],
          [-2.4379380, 55.1883020],
          [-2.4378980, 55.1882600],
          [-2.4378560, 55.1882140],
          [-2.4378140, 55.1881760],
          [-2.4377720, 55.1881340],
          [-2.4377290, 55.1880960],
          [-2.4376840, 55.1880580],
          [-2.4376360, 55.1880160],
          [-2.4375870, 55.1879780],
          [-2.4375360, 55.1879360],
          [-2.4374810, 55.1879010],
          [-2.4374160, 55.1878670],
          [-2.4373510, 55.1878320],
          [-2.4372850, 55.1878060],
          [-2.4372160, 55.1877750],
          [-2.4371470, 55.1877520],
          [-2.4370730, 55.1877330],
          [-2.4369980, 55.1877250],
          [-2.4369140, 55.1877180],
          [-2.4368300, 55.1877170],
          [-2.4367480, 55.1877170],
          [-2.4366810, 55.1877250],
          [-2.4366250, 55.1877360],
          [-2.4365730, 55.1877510],
          [-2.4365230, 55.1877630],
          [-2.4364780, 55.1877740],
          [-2.4364360, 55.1877900],
          [-2.4363970, 55.1878050],
          [-2.4363620, 55.1878160],
          [-2.4363240, 55.1878320],
          [-2.4362890, 55.1878470],
          [-2.4362570, 55.1878620],
          [-2.4362290, 55.1878810],
          [-2.4362000, 55.1878960],
          [-2.4361780, 55.1879120],
          [-2.4361490, 55.1879270],
          [-2.4361210, 55.1879460],
          [-2.4360940, 55.1879610],
          [-2.4360690, 55.1879800],
          [-2.4360470, 55.1879990],
          [-2.4360270, 55.1880180],
          [-2.4360070, 55.1880410],
          [-2.4359890, 55.1880640],
          [-2.4359690, 55.1880800],
          [-2.4359520, 55.1881020],
          [-2.4359380, 55.1881210],
          [-2.4359240, 55.1881410],
          [-2.4359090, 55.1881630],
          [-2.4358900, 55.1881820],
          [-2.4358720, 55.1882020],
          [-2.4358530, 55.1882210],
          [-2.4358390, 55.1882390],
          [-2.4358220, 55.1882620],
          [-2.4358070, 55.1882780],
          [-2.4357900, 55.1882970],
          [-2.4357750, 55.1883200],
          [-2.4357570, 55.1883350],
          [-2.4357370, 55.1883540],
          [-2.4357150, 55.1883730],
          [-2.4356940, 55.1883890],
          [-2.4356750, 55.1884070],
          [-2.4356560, 55.1884230],
          [-2.4356390, 55.1884420],
          [-2.4356220, 55.1884570],
          [-2.4356040, 55.1884720],
          [-2.4355850, 55.1884880],
          [-2.4355670, 55.1885030],
          [-2.4355490, 55.1885180],
          [-2.4355300, 55.1885300],
          [-2.4355120, 55.1885450],
          [-2.4354940, 55.1885560],
          [-2.4354740, 55.1885720],
          [-2.4354490, 55.1885830],
          [-2.4354260, 55.1885980],
          [-2.4354040, 55.1886100],
          [-2.4353820, 55.1886210],
          [-2.4353610, 55.1886330],
          [-2.4353400, 55.1886480],
          [-2.4353220, 55.1886670],
          [-2.4353040, 55.1886860],
          [-2.4352890, 55.1887010],
          [-2.4352740, 55.1887170],
          [-2.4352590, 55.1887320],
          [-2.4352440, 55.1887510],
          [-2.4352290, 55.1887660],
          [-2.4352140, 55.1887810],
          [-2.4352020, 55.1887970],
          [-2.4351900, 55.1888120],
          [-2.4351790, 55.1888270],
          [-2.4351700, 55.1888460],
          [-2.4351600, 55.1888620],
          [-2.4351470, 55.1888770],
          [-2.4351370, 55.1888920],
          [-2.4351270, 55.1889070],
          [-2.4351190, 55.1889230],
          [-2.4351070, 55.1889380],
          [-2.4350970, 55.1889530],
          [-2.4350900, 55.1889680],
          [-2.4350820, 55.1889840],
          [-2.4350740, 55.1889950],
          [-2.4350620, 55.1890100],
          [-2.4350570, 55.1890220],
          [-2.4350550, 55.1890370],
          [-2.4350500, 55.1890520],
          [-2.4350430, 55.1890640],
          [-2.4350390, 55.1890790],
          [-2.4350330, 55.1890940],
          [-2.4350270, 55.1891090],
          [-2.4350200, 55.1891210],
          [-2.4350170, 55.1891360],
          [-2.4350150, 55.1891510],
          [-2.4350130, 55.1891660],
          [-2.4350070, 55.1891820],
          [-2.4350000, 55.1891940],
          [-2.4349930, 55.1892050],
          [-2.4349880, 55.1892160],
          [-2.4349830, 55.1892310],
          [-2.4349780, 55.1892470],
          [-2.4349720, 55.1892580],
          [-2.4349600, 55.1892740],
          [-2.4349490, 55.1892850],
          [-2.4349390, 55.1892960],
          [-2.4349320, 55.1893120],
          [-2.4349290, 55.1893270],
          [-2.4349240, 55.1893460],
          [-2.4349180, 55.1893610],
          [-2.4349120, 55.1893730],
          [-2.4348990, 55.1893880],
          [-2.4348920, 55.1894030],
          [-2.4348880, 55.1894180],
          [-2.4348830, 55.1894340],
          [-2.4348830, 55.1894450],
          [-2.4348800, 55.1894600],
          [-2.4348730, 55.1894720],
          [-2.4348680, 55.1894830],
          [-2.4348630, 55.1894980],
          [-2.4348550, 55.1895130],
          [-2.4348480, 55.1895290],
          [-2.4348370, 55.1895440],
          [-2.4348270, 55.1895590],
          [-2.4348200, 55.1895750],
          [-2.4348130, 55.1895900],
          [-2.4348040, 55.1896050],
          [-2.4347950, 55.1896210],
          [-2.4347880, 55.1896360],
          [-2.4347850, 55.1896510],
          [-2.4347830, 55.1896700],
          [-2.4347800, 55.1896850],
          [-2.4347750, 55.1896970],
          [-2.4347670, 55.1897120],
          [-2.4347570, 55.1897270],
          [-2.4347470, 55.1897430],
          [-2.4347370, 55.1897580],
          [-2.4347270, 55.1897730],
          [-2.4347150, 55.1897880],
          [-2.4347090, 55.1898040],
          [-2.4347030, 55.1898230],
          [-2.4346980, 55.1898380],
          [-2.4346930, 55.1898530],
          [-2.4346880, 55.1898690],
          [-2.4346820, 55.1898880],
          [-2.4346720, 55.1899030],
          [-2.4346640, 55.1899180],
          [-2.4346570, 55.1899330],
          [-2.4346480, 55.1899520],
          [-2.4346390, 55.1899680],
          [-2.4346300, 55.1899830],
          [-2.4346230, 55.1899950],
          [-2.4346180, 55.1900140],
          [-2.4346150, 55.1900290],
          [-2.4346080, 55.1900440],
          [-2.4346020, 55.1900590],
          [-2.4345950, 55.1900750],
          [-2.4345900, 55.1900900],
          [-2.4345840, 55.1901050],
          [-2.4345790, 55.1901160],
          [-2.4345700, 55.1901320],
          [-2.4345600, 55.1901470],
          [-2.4345500, 55.1901620],
          [-2.4345390, 55.1901780],
          [-2.4345300, 55.1901920],
          [-2.4345250, 55.1902080],
          [-2.4345200, 55.1902200],
          [-2.4345120, 55.1902340],
          [-2.4345050, 55.1902500],
          [-2.4345000, 55.1902650],
          [-2.4344950, 55.1902810],
          [-2.4344900, 55.1902960],
          [-2.4344830, 55.1903110],
          [-2.4344750, 55.1903230],
          [-2.4344650, 55.1903380],
          [-2.4344570, 55.1903490],
          [-2.4344520, 55.1903640],
          [-2.4344470, 55.1903800],
          [-2.4344400, 55.1903910],
          [-2.4344320, 55.1904060],
          [-2.4344250, 55.1904220],
          [-2.4344200, 55.1904410],
          [-2.4344140, 55.1904560],
          [-2.4344070, 55.1904710],
          [-2.4343990, 55.1904830],
          [-2.4343900, 55.1904980],
          [-2.4343790, 55.1905130],
          [-2.4343700, 55.1905250],
          [-2.4343620, 55.1905400],
          [-2.4343550, 55.1905550],
          [-2.4343490, 55.1905710],
          [-2.4343420, 55.1905860],
          [-2.4343340, 55.1906010],
          [-2.4343280, 55.1906160],
          [-2.4343250, 55.1906320],
          [-2.4343220, 55.1906430],
          [-2.4343200, 55.1906580],
          [-2.4343170, 55.1906740],
          [-2.4343150, 55.1906850],
          [-2.4343120, 55.1907000],
          [-2.4343030, 55.1907150],
          [-2.4342970, 55.1907270],
          [-2.4342970, 55.1907420],
          [-2.4342950, 55.1907530],
          [-2.4342900, 55.1907650],
          [-2.4342840, 55.1907800],
          [-2.4342780, 55.1907920],
          [-2.4342750, 55.1908070],
          [-2.4342680, 55.1908190],
          [-2.4342630, 55.1908340],
          [-2.4342570, 55.1908450],
          [-2.4342490, 55.1908600],
          [-2.4342450, 55.1908720],
          [-2.4342370, 55.1908870],
          [-2.4342270, 55.1909020],
          [-2.4342200, 55.1909180],
          [-2.4342140, 55.1909330],
          [-2.4342040, 55.1909480],
          [-2.4341970, 55.1909600],
          [-2.4341900, 55.1909750],
          [-2.4341820, 55.1909900],
          [-2.4341720, 55.1910050],
          [-2.4341650, 55.1910200],
          [-2.4341580, 55.1910360],
          [-2.4341490, 55.1910510],
          [-2.4341400, 55.1910670],
          [-2.4341370, 55.1910780],
          [-2.4341300, 55.1910930],
          [-2.4341230, 55.1911080],
          [-2.4341170, 55.1911240],
          [-2.4341080, 55.1911390],
          [-2.4341020, 55.1911500],
          [-2.4341000, 55.1911650],
          [-2.4340980, 55.1911810],
          [-2.4341000, 55.1911960],
          [-2.4341030, 55.1912110],
          [-2.4341070, 55.1912230],
          [-2.4341110, 55.1912380],
          [-2.4341160, 55.1912530],
          [-2.4341230, 55.1912720],
          [-2.4341330, 55.1912840],
          [-2.4341460, 55.1912990],
          [-2.4341660, 55.1913110],
          [-2.4341830, 55.1913290],
          [-2.4341930, 55.1913410],
          [-2.4342080, 55.1913520],
          [-2.4342260, 55.1913640],
          [-2.4342500, 55.1913760],
          [-2.4342740, 55.1913830],
          [-2.4342990, 55.1913950],
          [-2.4343290, 55.1914100],
          [-2.4343630, 55.1914250],
          [-2.4343990, 55.1914440],
          [-2.4344340, 55.1914630],
          [-2.4344720, 55.1914890],
          [-2.4345150, 55.1915120],
          [-2.4345630, 55.1915390],
          [-2.4346120, 55.1915660],
          [-2.4346670, 55.1915960],
          [-2.4347240, 55.1916310],
          [-2.4347810, 55.1916610],
          [-2.4348400, 55.1916950],
          [-2.4348990, 55.1917300],
          [-2.4349560, 55.1917630],
          [-2.4350170, 55.1917980],
          [-2.4350740, 55.1918360],
          [-2.4351350, 55.1918780],
          [-2.4351970, 55.1919170],
          [-2.4352620, 55.1919580],
          [-2.4353280, 55.1920040],
          [-2.4353970, 55.1920500],
          [-2.4354660, 55.1921000],
          [-2.4355320, 55.1921470],
          [-2.4356000, 55.1922060],
          [-2.4356620, 55.1922630],
          [-2.4357150, 55.1923170],
          [-2.4357620, 55.1923660],
          [-2.4358010, 55.1924080],
          [-2.4358360, 55.1924430],
          [-2.4358640, 55.1924770],
          [-2.4358900, 55.1925080],
          [-2.4359150, 55.1925390],
          [-2.4359380, 55.1925690],
          [-2.4359610, 55.1925960],
          [-2.4359880, 55.1926260],
          [-2.4360110, 55.1926570],
          [-2.4360330, 55.1926870],
          [-2.4360540, 55.1927180],
          [-2.4360760, 55.1927440],
          [-2.4361010, 55.1927680],
          [-2.4361250, 55.1927940],
          [-2.4361450, 55.1928210],
          [-2.4361650, 55.1928440],
          [-2.4361860, 55.1928670],
          [-2.4362080, 55.1928900],
          [-2.4362280, 55.1929200],
          [-2.4362500, 55.1929470],
          [-2.4362730, 55.1929770],
          [-2.4362980, 55.1930000],
          [-2.4363250, 55.1930270],
          [-2.4363510, 55.1930460],
          [-2.4363780, 55.1930690],
          [-2.4364010, 55.1930920],
          [-2.4364230, 55.1931190],
          [-2.4364450, 55.1931450],
          [-2.4364680, 55.1931720],
          [-2.4364910, 55.1931990],
          [-2.4365180, 55.1932250],
          [-2.4365460, 55.1932560],
          [-2.4365740, 55.1932820],
          [-2.4366010, 55.1933090],
          [-2.4366280, 55.1933280],
          [-2.4366530, 55.1933510],
          [-2.4366780, 55.1933780],
          [-2.4367020, 55.1934010],
          [-2.4367280, 55.1934190],
          [-2.4367560, 55.1934430],
          [-2.4367830, 55.1934660],
          [-2.4368080, 55.1934920],
          [-2.4368320, 55.1935110],
          [-2.4368590, 55.1935340],
          [-2.4368860, 55.1935610],
          [-2.4369140, 55.1935880],
          [-2.4369430, 55.1936110],
          [-2.4369690, 55.1936330],
          [-2.4369960, 55.1936600],
          [-2.4370210, 55.1936800],
          [-2.4370460, 55.1937060],
          [-2.4370710, 55.1937250],
          [-2.4371010, 55.1937480],
          [-2.4371310, 55.1937740],
          [-2.4371630, 55.1937980],
          [-2.4371960, 55.1938200],
          [-2.4372280, 55.1938470],
          [-2.4372610, 55.1938700],
          [-2.4372970, 55.1938930],
          [-2.4373360, 55.1939160],
          [-2.4373730, 55.1939380],
          [-2.4374140, 55.1939580],
          [-2.4374550, 55.1939800],
          [-2.4375020, 55.1940030],
          [-2.4375530, 55.1940220],
          [-2.4376090, 55.1940450],
          [-2.4376650, 55.1940640],
          [-2.4377250, 55.1940830],
          [-2.4377870, 55.1941030],
          [-2.4378510, 55.1941260],
          [-2.4379190, 55.1941520],
          [-2.4379870, 55.1941750],
          [-2.4380550, 55.1942060],
          [-2.4381280, 55.1942320],
          [-2.4382020, 55.1942630],
          [-2.4382770, 55.1942900],
          [-2.4383530, 55.1943200],
          [-2.4384300, 55.1943470],
          [-2.4385120, 55.1943770],
          [-2.4385970, 55.1944120],
          [-2.4386810, 55.1944490],
          [-2.4387700, 55.1944880],
          [-2.4388610, 55.1945300],
          [-2.4389550, 55.1945680],
          [-2.4390510, 55.1946060],
          [-2.4391480, 55.1946480],
          [-2.4392460, 55.1946900],
          [-2.4393460, 55.1947360],
          [-2.4394450, 55.1947880],
          [-2.4395530, 55.1948420],
          [-2.4396650, 55.1948990],
          [-2.4397820, 55.1949570],
          [-2.4399050, 55.1950130],
          [-2.4400400, 55.1950790],
          [-2.4401810, 55.1951400],
          [-2.4403280, 55.1952000],
          [-2.4404770, 55.1952650],
          [-2.4406250, 55.1953190],
          [-2.4407200, 55.1953540],
          [-2.4408920, 55.1954130],
          [-2.4410400, 55.1954610],
          [-2.4411600, 55.1955010],
          [-2.4412810, 55.1955400],
          [-2.4413870, 55.1955760],
          [-2.4415010, 55.1956170],
          [-2.4416020, 55.1956510],
          [-2.4416960, 55.1956840],
          [-2.4417990, 55.1957160],
          [-2.4418960, 55.1957390],
          [-2.4419890, 55.1957580],
          [-2.4420850, 55.1957700],
          [-2.4421850, 55.1957780],
          [-2.4422880, 55.1957740],
          [-2.4423900, 55.1957700],
          [-2.4425080, 55.1957700],
          [-2.4426110, 55.1957670],
          [-2.4427250, 55.1957660],
          [-2.4428380, 55.1957630],
          [-2.4429520, 55.1957630],
          [-2.4430560, 55.1957590],
          [-2.4431610, 55.1957550],
          [-2.4432680, 55.1957510],
          [-2.4433790, 55.1957480],
          [-2.4434980, 55.1957440],
          [-2.4436190, 55.1957330],
          [-2.4437440, 55.1957280],
          [-2.4438650, 55.1957280],
          [-2.4439930, 55.1957280],
          [-2.4441130, 55.1957430],
          [-2.4442330, 55.1957580],
          [-2.4443340, 55.1957880],
          [-2.4444010, 55.1958160],
          [-2.4444820, 55.1958610],
          [-2.4445370, 55.1958920],
          [-2.4445790, 55.1959260],
          [-2.4446140, 55.1959530],
          [-2.4446380, 55.1959830],
          [-2.4446540, 55.1960130],
          [-2.4446680, 55.1960400],
          [-2.4446750, 55.1960670],
          [-2.4446800, 55.1960900],
          [-2.4446800, 55.1961130],
          [-2.4446770, 55.1961360],
          [-2.4446770, 55.1961550],
          [-2.4446770, 55.1961780],
          [-2.4446740, 55.1961970],
          [-2.4446700, 55.1962160],
          [-2.4446630, 55.1962390],
          [-2.4446600, 55.1962620],
          [-2.4446530, 55.1962810],
          [-2.4446480, 55.1963040],
          [-2.4446430, 55.1963230],
          [-2.4446400, 55.1963460],
          [-2.4446370, 55.1963650],
          [-2.4446350, 55.1963880],
          [-2.4446320, 55.1964070],
          [-2.4446330, 55.1964300],
          [-2.4446380, 55.1964490],
          [-2.4446410, 55.1964680],
          [-2.4446450, 55.1964910],
          [-2.4446480, 55.1965100],
          [-2.4446480, 55.1965320],
          [-2.4446550, 55.1965520],
          [-2.4446600, 55.1965750],
          [-2.4446660, 55.1965940],
          [-2.4446710, 55.1966130],
          [-2.4446780, 55.1966320],
          [-2.4446860, 55.1966510],
          [-2.4446950, 55.1966740],
          [-2.4447030, 55.1966930],
          [-2.4447110, 55.1967120],
          [-2.4447230, 55.1967270],
          [-2.4447350, 55.1967420],
          [-2.4447450, 55.1967580],
          [-2.4447530, 55.1967770],
          [-2.4447620, 55.1967960],
          [-2.4447720, 55.1968150],
          [-2.4447800, 55.1968300],
          [-2.4447880, 55.1968490],
          [-2.4447950, 55.1968680],
          [-2.4448030, 55.1968860],
          [-2.4448130, 55.1969030],
          [-2.4448210, 55.1969180],
          [-2.4448310, 55.1969370],
          [-2.4448410, 55.1969560],
          [-2.4448510, 55.1969720],
          [-2.4448610, 55.1969910],
          [-2.4448700, 55.1970060],
          [-2.4448780, 55.1970250],
          [-2.4448880, 55.1970440],
          [-2.4449000, 55.1970630],
          [-2.4449080, 55.1970760],
          [-2.4449200, 55.1970980],
          [-2.4449320, 55.1971130],
          [-2.4449430, 55.1971280],
          [-2.4449530, 55.1971470],
          [-2.4449610, 55.1971660],
          [-2.4449710, 55.1971810],
          [-2.4449830, 55.1972000],
          [-2.4449930, 55.1972160],
          [-2.4450010, 55.1972350],
          [-2.4450100, 55.1972500],
          [-2.4450160, 55.1972650],
          [-2.4450230, 55.1972810],
          [-2.4450310, 55.1972960],
          [-2.4450410, 55.1973150],
          [-2.4450500, 55.1973300],
          [-2.4450580, 55.1973490],
          [-2.4450660, 55.1973640],
          [-2.4450750, 55.1973830],
          [-2.4450830, 55.1973990],
          [-2.4450900, 55.1974180],
          [-2.4451000, 55.1974330],
          [-2.4451100, 55.1974480],
          [-2.4451200, 55.1974670],
          [-2.4451300, 55.1974830],
          [-2.4451390, 55.1974940],
          [-2.4451500, 55.1975090],
          [-2.4451660, 55.1975240],
          [-2.4451830, 55.1975400],
          [-2.4451950, 55.1975590],
          [-2.4452030, 55.1975740],
          [-2.4452110, 55.1975900],
          [-2.4452210, 55.1976050],
          [-2.4452310, 55.1976240],
          [-2.4452410, 55.1976430],
          [-2.4452530, 55.1976580],
          [-2.4452610, 55.1976730],
          [-2.4452700, 55.1976890],
          [-2.4452780, 55.1977080],
          [-2.4452870, 55.1977230],
          [-2.4452920, 55.1977420],
          [-2.4452980, 55.1977570],
          [-2.4453070, 55.1977760],
          [-2.4453150, 55.1977920],
          [-2.4453250, 55.1978100],
          [-2.4453360, 55.1978300],
          [-2.4453450, 55.1978490],
          [-2.4453540, 55.1978670],
          [-2.4453630, 55.1978870],
          [-2.4453730, 55.1979060],
          [-2.4453830, 55.1979210],
          [-2.4453910, 55.1979400],
          [-2.4454000, 55.1979590],
          [-2.4454080, 55.1979750],
          [-2.4454150, 55.1979940],
          [-2.4454220, 55.1980090],
          [-2.4454310, 55.1980240],
          [-2.4454440, 55.1980390],
          [-2.4454560, 55.1980580],
          [-2.4454660, 55.1980740],
          [-2.4454760, 55.1980930],
          [-2.4454850, 55.1981080],
          [-2.4454910, 55.1981190],
          [-2.4454980, 55.1981350],
          [-2.4455040, 55.1981490],
          [-2.4455130, 55.1981620],
          [-2.4455210, 55.1981770],
          [-2.4455280, 55.1981920],
          [-2.4455350, 55.1982110],
          [-2.4455430, 55.1982300],
          [-2.4455500, 55.1982490],
          [-2.4455580, 55.1982680],
          [-2.4455680, 55.1982870],
          [-2.4455760, 55.1983030],
          [-2.4455850, 55.1983250],
          [-2.4455960, 55.1983410],
          [-2.4456060, 55.1983600],
          [-2.4456160, 55.1983750],
          [-2.4456280, 55.1983900],
          [-2.4456380, 55.1984060],
          [-2.4456470, 55.1984210],
          [-2.4456550, 55.1984360],
          [-2.4456650, 55.1984520],
          [-2.4456750, 55.1984670],
          [-2.4456850, 55.1984820],
          [-2.4456950, 55.1985010],
          [-2.4457030, 55.1985160],
          [-2.4457110, 55.1985350],
          [-2.4457200, 55.1985550],
          [-2.4457280, 55.1985700],
          [-2.4457360, 55.1985890],
          [-2.4457460, 55.1986080],
          [-2.4457580, 55.1986230],
          [-2.4457700, 55.1986420],
          [-2.4457810, 55.1986610],
          [-2.4457900, 55.1986810],
          [-2.4457980, 55.1986960],
          [-2.4458090, 55.1987140],
          [-2.4458230, 55.1987340],
          [-2.4458350, 55.1987530],
          [-2.4458460, 55.1987680],
          [-2.4458560, 55.1987870],
          [-2.4458680, 55.1988060],
          [-2.4458830, 55.1988290],
          [-2.4458980, 55.1988520],
          [-2.4459130, 55.1988750],
          [-2.4459250, 55.1988980],
          [-2.4459330, 55.1989240],
          [-2.4459400, 55.1989510],
          [-2.4459480, 55.1989810],
          [-2.4459610, 55.1990110],
          [-2.4459780, 55.1990430],
          [-2.4459950, 55.1990770],
          [-2.4460130, 55.1991110],
          [-2.4460330, 55.1991460],
          [-2.4460550, 55.1991800],
          [-2.4460800, 55.1992130],
          [-2.4461090, 55.1992480],
          [-2.4461350, 55.1992790],
          [-2.4461560, 55.1993130],
          [-2.4461760, 55.1993480],
          [-2.4461940, 55.1993820],
          [-2.4462130, 55.1994160],
          [-2.4462310, 55.1994540],
          [-2.4462510, 55.1994890],
          [-2.4462710, 55.1995230],
          [-2.4462940, 55.1995580],
          [-2.4463140, 55.1995910],
          [-2.4463320, 55.1996280],
          [-2.4463530, 55.1996680],
          [-2.4463710, 55.1997060],
          [-2.4463910, 55.1997440],
          [-2.4464130, 55.1997790],
          [-2.4464340, 55.1998160],
          [-2.4464530, 55.1998540],
          [-2.4464690, 55.1998880],
          [-2.4464810, 55.1999240],
          [-2.4464970, 55.1999570],
          [-2.4465160, 55.1999920],
          [-2.4465360, 55.2000230],
          [-2.4465550, 55.2000580],
          [-2.4465760, 55.2000920],
          [-2.4465940, 55.2001250],
          [-2.4466120, 55.2001550],
          [-2.4466330, 55.2001910],
          [-2.4466540, 55.2002250],
          [-2.4466720, 55.2002580],
          [-2.4466930, 55.2002900],
          [-2.4467150, 55.2003240],
          [-2.4467350, 55.2003570],
          [-2.4467580, 55.2003890],
          [-2.4467780, 55.2004230],
          [-2.4468000, 55.2004570],
          [-2.4468270, 55.2004880],
          [-2.4468530, 55.2005230],
          [-2.4468760, 55.2005520],
          [-2.4469020, 55.2005860],
          [-2.4469290, 55.2006250],
          [-2.4469550, 55.2006590],
          [-2.4469880, 55.2006980],
          [-2.4470160, 55.2007320],
          [-2.4470470, 55.2007660],
          [-2.4470820, 55.2008010],
          [-2.4471170, 55.2008350],
          [-2.4471500, 55.2008680],
          [-2.4471890, 55.2009040],
          [-2.4472290, 55.2009370],
          [-2.4472710, 55.2009710],
          [-2.4473170, 55.2010110],
          [-2.4473670, 55.2010480],
          [-2.4474190, 55.2010820],
          [-2.4474770, 55.2011210],
          [-2.4475310, 55.2011550],
          [-2.4475890, 55.2011940],
          [-2.4476500, 55.2012240],
          [-2.4477190, 55.2012580],
          [-2.4477840, 55.2012950],
          [-2.4478530, 55.2013310],
          [-2.4479180, 55.2013680],
          [-2.4479820, 55.2014040],
          [-2.4480390, 55.2014400],
          [-2.4481020, 55.2014800],
          [-2.4481560, 55.2015130],
          [-2.4482130, 55.2015520],
          [-2.4482650, 55.2015860],
          [-2.4483130, 55.2016210],
          [-2.4483590, 55.2016580],
          [-2.4484020, 55.2017010],
          [-2.4484390, 55.2017360],
          [-2.4484820, 55.2017810],
          [-2.4485220, 55.2018180],
          [-2.4485630, 55.2018580],
          [-2.4485920, 55.2018910],
          [-2.4486260, 55.2019320],
          [-2.4486600, 55.2019730],
          [-2.4486960, 55.2020070],
          [-2.4487330, 55.2020410],
          [-2.4487720, 55.2020790],
          [-2.4488110, 55.2021100],
          [-2.4488480, 55.2021410],
          [-2.4488960, 55.2021750],
          [-2.4489460, 55.2022050],
          [-2.4489930, 55.2022340],
          [-2.4490470, 55.2022660],
          [-2.4490980, 55.2022920],
          [-2.4491550, 55.2023230],
          [-2.4492110, 55.2023500],
          [-2.4492650, 55.2023790],
          [-2.4493220, 55.2024070],
          [-2.4493770, 55.2024380],
          [-2.4494320, 55.2024640],
          [-2.4494860, 55.2024910],
          [-2.4495450, 55.2025220],
          [-2.4496040, 55.2025520],
          [-2.4496590, 55.2025820],
          [-2.4497170, 55.2026090],
          [-2.4497720, 55.2026400],
          [-2.4498280, 55.2026690],
          [-2.4498920, 55.2027010],
          [-2.4499520, 55.2027310],
          [-2.4500130, 55.2027650],
          [-2.4500770, 55.2027990],
          [-2.4501480, 55.2028340],
          [-2.4502180, 55.2028710],
          [-2.4502920, 55.2029140],
          [-2.4503590, 55.2029510],
          [-2.4504290, 55.2029950],
          [-2.4504870, 55.2030340],
          [-2.4505540, 55.2030780],
          [-2.4506130, 55.2031220],
          [-2.4506790, 55.2031690],
          [-2.4507440, 55.2032140],
          [-2.4508120, 55.2032570],
          [-2.4508820, 55.2033010],
          [-2.4509620, 55.2033450],
          [-2.4510380, 55.2033860],
          [-2.4511210, 55.2034290],
          [-2.4512020, 55.2034650],
          [-2.4512970, 55.2035090],
          [-2.4513840, 55.2035490],
          [-2.4514840, 55.2035970],
          [-2.4515790, 55.2036420],
          [-2.4516730, 55.2036920],
          [-2.4517600, 55.2037400],
          [-2.4518500, 55.2037950],
          [-2.4519280, 55.2038480],
          [-2.4519960, 55.2039040],
          [-2.4520530, 55.2039590],
          [-2.4521040, 55.2040200],
          [-2.4521380, 55.2040710],
          [-2.4521610, 55.2041270],
          [-2.4521760, 55.2041800],
          [-2.4521850, 55.2042260],
          [-2.4521930, 55.2042700],
          [-2.4521970, 55.2043180],
          [-2.4521980, 55.2043630],
          [-2.4521970, 55.2044060],
          [-2.4521930, 55.2044440],
          [-2.4521890, 55.2044830],
          [-2.4521820, 55.2045240],
          [-2.4521750, 55.2045580],
          [-2.4521700, 55.2045940],
          [-2.4521650, 55.2046310],
          [-2.4521590, 55.2046690],
          [-2.4521540, 55.2047030],
          [-2.4521490, 55.2047420],
          [-2.4521420, 55.2047760],
          [-2.4521360, 55.2048110],
          [-2.4521300, 55.2048490],
          [-2.4521240, 55.2048830],
          [-2.4521140, 55.2049200],
          [-2.4521040, 55.2049550],
          [-2.4520950, 55.2049900],
          [-2.4520890, 55.2050270],
          [-2.4520830, 55.2050620],
          [-2.4520770, 55.2050960],
          [-2.4520690, 55.2051300],
          [-2.4520620, 55.2051650],
          [-2.4520550, 55.2051990],
          [-2.4520490, 55.2052290],
          [-2.4520440, 55.2052560],
          [-2.4520400, 55.2052840],
          [-2.4520370, 55.2053070],
          [-2.4520330, 55.2053320],
          [-2.4520300, 55.2053560],
          [-2.4520250, 55.2053830],
          [-2.4520200, 55.2054090],
          [-2.4520120, 55.2054300],
          [-2.4520070, 55.2054550],
          [-2.4520000, 55.2054740],
          [-2.4519930, 55.2055010],
          [-2.4519870, 55.2055190],
          [-2.4519820, 55.2055430],
          [-2.4519740, 55.2055620],
          [-2.4519640, 55.2055810],
          [-2.4519520, 55.2056040],
          [-2.4519390, 55.2056230],
          [-2.4519240, 55.2056420],
          [-2.4519070, 55.2056610],
          [-2.4518900, 55.2056840],
          [-2.4518720, 55.2057000],
          [-2.4518520, 55.2057220],
          [-2.4518300, 55.2057410],
          [-2.4518040, 55.2057610],
          [-2.4517770, 55.2057800],
          [-2.4517490, 55.2058020],
          [-2.4517190, 55.2058250],
          [-2.4516870, 55.2058480],
          [-2.4516560, 55.2058700],
          [-2.4516220, 55.2058890],
          [-2.4515870, 55.2059130],
          [-2.4515560, 55.2059360],
          [-2.4515240, 55.2059550],
          [-2.4514960, 55.2059730],
          [-2.4514650, 55.2059930],
          [-2.4514360, 55.2060120],
          [-2.4514070, 55.2060310],
          [-2.4513790, 55.2060530],
          [-2.4513490, 55.2060700],
          [-2.4513170, 55.2060890],
          [-2.4512870, 55.2061080],
          [-2.4512600, 55.2061260],
          [-2.4512250, 55.2061460],
          [-2.4511920, 55.2061610],
          [-2.4511620, 55.2061800],
          [-2.4511370, 55.2061970],
          [-2.4511020, 55.2062180],
          [-2.4510730, 55.2062370],
          [-2.4510460, 55.2062560],
          [-2.4510190, 55.2062790],
          [-2.4509940, 55.2062980],
          [-2.4509670, 55.2063210],
          [-2.4509440, 55.2063400],
          [-2.4509250, 55.2063590],
          [-2.4509020, 55.2063820],
          [-2.4508810, 55.2064050],
          [-2.4508610, 55.2064280],
          [-2.4508450, 55.2064460],
          [-2.4508240, 55.2064700],
          [-2.4508050, 55.2064930],
          [-2.4507860, 55.2065190],
          [-2.4507640, 55.2065420],
          [-2.4507410, 55.2065690],
          [-2.4507160, 55.2065950],
          [-2.4506920, 55.2066220],
          [-2.4506710, 55.2066480],
          [-2.4506490, 55.2066760],
          [-2.4506270, 55.2067030],
          [-2.4506040, 55.2067290],
          [-2.4505800, 55.2067560],
          [-2.4505560, 55.2067830],
          [-2.4505340, 55.2068090],
          [-2.4505140, 55.2068360],
          [-2.4504950, 55.2068590],
          [-2.4504750, 55.2068780],
          [-2.4504540, 55.2069010],
          [-2.4504340, 55.2069190],
          [-2.4504110, 55.2069430],
          [-2.4503900, 55.2069660],
          [-2.4503700, 55.2069850],
          [-2.4503520, 55.2070060],
          [-2.4503320, 55.2070270],
          [-2.4503140, 55.2070500],
          [-2.4502950, 55.2070690],
          [-2.4502760, 55.2070870],
          [-2.4502540, 55.2071110],
          [-2.4502320, 55.2071300],
          [-2.4502110, 55.2071490],
          [-2.4501910, 55.2071670],
          [-2.4501670, 55.2071870],
          [-2.4501450, 55.2072060],
          [-2.4501240, 55.2072250],
          [-2.4501030, 55.2072440],
          [-2.4500800, 55.2072640],
          [-2.4500590, 55.2072860],
          [-2.4500370, 55.2073060],
          [-2.4500150, 55.2073240],
          [-2.4499910, 55.2073440],
          [-2.4499690, 55.2073630],
          [-2.4499470, 55.2073820],
          [-2.4499300, 55.2074000],
          [-2.4499100, 55.2074160],
          [-2.4498900, 55.2074320],
          [-2.4498730, 55.2074490],
          [-2.4498490, 55.2074690],
          [-2.4498280, 55.2074850],
          [-2.4498050, 55.2075000],
          [-2.4497820, 55.2075150],
          [-2.4497640, 55.2075340],
          [-2.4497450, 55.2075530],
          [-2.4497230, 55.2075640],
          [-2.4497010, 55.2075840],
          [-2.4496780, 55.2075950],
          [-2.4496570, 55.2076140],
          [-2.4496400, 55.2076340],
          [-2.4496230, 55.2076480],
          [-2.4495990, 55.2076640],
          [-2.4495740, 55.2076830],
          [-2.4495500, 55.2077020],
          [-2.4495240, 55.2077180],
          [-2.4495040, 55.2077310],
          [-2.4494810, 55.2077520],
          [-2.4494610, 55.2077670],
          [-2.4494410, 55.2077860],
          [-2.4494240, 55.2078020],
          [-2.4494090, 55.2078200],
          [-2.4493970, 55.2078400],
          [-2.4493850, 55.2078590],
          [-2.4493780, 55.2078810],
          [-2.4493700, 55.2079040],
          [-2.4493640, 55.2079270],
          [-2.4493570, 55.2079460],
          [-2.4493550, 55.2079690],
          [-2.4493560, 55.2079920],
          [-2.4493600, 55.2080150],
          [-2.4493680, 55.2080370],
          [-2.4493780, 55.2080610],
          [-2.4493880, 55.2080830],
          [-2.4493980, 55.2081030],
          [-2.4494110, 55.2081220],
          [-2.4494250, 55.2081400],
          [-2.4494450, 55.2081600],
          [-2.4494610, 55.2081790],
          [-2.4494800, 55.2081940],
          [-2.4494980, 55.2082130],
          [-2.4495160, 55.2082310],
          [-2.4495380, 55.2082510],
          [-2.4495580, 55.2082700],
          [-2.4495810, 55.2082900],
          [-2.4496030, 55.2083090],
          [-2.4496220, 55.2083270],
          [-2.4496430, 55.2083430],
          [-2.4496680, 55.2083580],
          [-2.4496910, 55.2083770],
          [-2.4497180, 55.2083930],
          [-2.4497410, 55.2084110],
          [-2.4497690, 55.2084270],
          [-2.4497940, 55.2084420],
          [-2.4498180, 55.2084610],
          [-2.4498430, 55.2084730],
          [-2.4498650, 55.2084870],
          [-2.4498920, 55.2085070],
          [-2.4499180, 55.2085220],
          [-2.4499410, 55.2085340],
          [-2.4499630, 55.2085530],
          [-2.4499810, 55.2085700],
          [-2.4500030, 55.2085910],
          [-2.4500230, 55.2086060],
          [-2.4500390, 55.2086280],
          [-2.4500580, 55.2086480],
          [-2.4500750, 55.2086700],
          [-2.4500910, 55.2086900],
          [-2.4501080, 55.2087090],
          [-2.4501210, 55.2087280],
          [-2.4501330, 55.2087480],
          [-2.4501450, 55.2087650],
          [-2.4501580, 55.2087860],
          [-2.4501660, 55.2088040],
          [-2.4501780, 55.2088200],
          [-2.4501910, 55.2088390],
          [-2.4502070, 55.2088610],
          [-2.4502210, 55.2088850],
          [-2.4502350, 55.2089080],
          [-2.4502530, 55.2089300],
          [-2.4502700, 55.2089490],
          [-2.4502910, 55.2089720],
          [-2.4503130, 55.2089950],
          [-2.4503360, 55.2090180],
          [-2.4503560, 55.2090400],
          [-2.4503760, 55.2090600],
          [-2.4503980, 55.2090830],
          [-2.4504220, 55.2091050],
          [-2.4504470, 55.2091270],
          [-2.4504740, 55.2091520],
          [-2.4504960, 55.2091780],
          [-2.4505160, 55.2092010],
          [-2.4505430, 55.2092260],
          [-2.4505660, 55.2092550],
          [-2.4505920, 55.2092810],
          [-2.4506180, 55.2093080],
          [-2.4506420, 55.2093360],
          [-2.4506730, 55.2093690],
          [-2.4507000, 55.2093960],
          [-2.4507270, 55.2094250],
          [-2.4507560, 55.2094530],
          [-2.4507860, 55.2094870],
          [-2.4508160, 55.2095160],
          [-2.4508460, 55.2095480],
          [-2.4508760, 55.2095790],
          [-2.4509060, 55.2096060],
          [-2.4509440, 55.2096400],
          [-2.4509790, 55.2096740],
          [-2.4510100, 55.2097020],
          [-2.4510460, 55.2097390],
          [-2.4510790, 55.2097700],
          [-2.4511120, 55.2098000],
          [-2.4511510, 55.2098340],
          [-2.4511920, 55.2098720],
          [-2.4512260, 55.2099000],
          [-2.4512670, 55.2099370],
          [-2.4513090, 55.2099680],
          [-2.4513410, 55.2099970],
          [-2.4513850, 55.2100360],
          [-2.4514240, 55.2100670],
          [-2.4514680, 55.2101000],
          [-2.4515190, 55.2101390],
          [-2.4515720, 55.2101700],
          [-2.4516170, 55.2102000],
          [-2.4516810, 55.2102390],
          [-2.4517350, 55.2102670],
          [-2.4517990, 55.2103000],
          [-2.4518720, 55.2103210],
          [-2.4519640, 55.2103340],
          [-2.4520480, 55.2103200],
          [-2.4521240, 55.2102820],
          [-2.4521750, 55.2102280],
          [-2.4522170, 55.2101570],
          [-2.4522490, 55.2100900],
          [-2.4522870, 55.2100050],
          [-2.4523170, 55.2099350],
          [-2.4523590, 55.2098380],
          [-2.4523910, 55.2097630],
          [-2.4524360, 55.2096650],
          [-2.4524690, 55.2095860],
          [-2.4524950, 55.2094960],
          [-2.4525120, 55.2093970],
          [-2.4525200, 55.2092940],
          [-2.4525240, 55.2091890],
          [-2.4525290, 55.2090710],
          [-2.4525470, 55.2089570],
          [-2.4525840, 55.2088390],
          [-2.4526400, 55.2087250],
          [-2.4527230, 55.2086200],
          [-2.4528430, 55.2085440],
          [-2.4529660, 55.2084850],
          [-2.4531020, 55.2084340],
          [-2.4531990, 55.2084010],
          [-2.4533240, 55.2083650],
          [-2.4534200, 55.2083380],
          [-2.4535410, 55.2083030],
          [-2.4536270, 55.2082750],
          [-2.4537490, 55.2082380],
          [-2.4538570, 55.2082070],
          [-2.4539630, 55.2081760],
          [-2.4540630, 55.2081460],
          [-2.4541630, 55.2081160],
          [-2.4542720, 55.2080910],
          [-2.4543810, 55.2080650],
          [-2.4544940, 55.2080390],
          [-2.4546080, 55.2080200],
          [-2.4547210, 55.2080010],
          [-2.4548420, 55.2079840],
          [-2.4549640, 55.2079660],
          [-2.4550790, 55.2079550],
          [-2.4551940, 55.2079430],
          [-2.4553060, 55.2079360],
          [-2.4554230, 55.2079280],
          [-2.4555410, 55.2079220],
          [-2.4556590, 55.2079160],
          [-2.4557760, 55.2079130],
          [-2.4558920, 55.2079120],
          [-2.4560150, 55.2079120],
          [-2.4561380, 55.2079120],
          [-2.4562570, 55.2079160],
          [-2.4563770, 55.2079200],
          [-2.4564940, 55.2079260],
          [-2.4566110, 55.2079310],
          [-2.4567220, 55.2079350],
          [-2.4568330, 55.2079470],
          [-2.4569370, 55.2079500],
          [-2.4570490, 55.2079560],
          [-2.4571610, 55.2079620],
          [-2.4572720, 55.2079660],
          [-2.4573830, 55.2079700],
          [-2.4574930, 55.2079730],
          [-2.4576000, 55.2079730],
          [-2.4577110, 55.2079770],
          [-2.4578180, 55.2079810],
          [-2.4579260, 55.2079850],
          [-2.4580290, 55.2079890],
          [-2.4581320, 55.2079960],
          [-2.4582360, 55.2080000],
          [-2.4583430, 55.2080060],
          [-2.4584490, 55.2080120],
          [-2.4585560, 55.2080150],
          [-2.4586650, 55.2080190],
          [-2.4587710, 55.2080230],
          [-2.4588780, 55.2080270],
          [-2.4589830, 55.2080310],
          [-2.4590900, 55.2080340],
          [-2.4591930, 55.2080380],
          [-2.4592960, 55.2080420],
          [-2.4593980, 55.2080460],
          [-2.4595000, 55.2080500],
          [-2.4596030, 55.2080530],
          [-2.4597060, 55.2080570],
          [-2.4598060, 55.2080610],
          [-2.4599110, 55.2080650],
          [-2.4600160, 55.2080730],
          [-2.4601230, 55.2080760],
          [-2.4602330, 55.2080840],
          [-2.4603430, 55.2080920],
          [-2.4604510, 55.2080990],
          [-2.4605620, 55.2081070],
          [-2.4606680, 55.2081180],
          [-2.4607740, 55.2081330],
          [-2.4608720, 55.2081450],
          [-2.4609670, 55.2081600],
          [-2.4610610, 55.2081750],
          [-2.4611480, 55.2081940],
          [-2.4612360, 55.2082100],
          [-2.4613230, 55.2082290],
          [-2.4614060, 55.2082480],
          [-2.4614870, 55.2082630],
          [-2.4615700, 55.2082820],
          [-2.4616490, 55.2083010],
          [-2.4617260, 55.2083240],
          [-2.4618020, 55.2083430],
          [-2.4618740, 55.2083620],
          [-2.4619480, 55.2083810],
          [-2.4620200, 55.2084000],
          [-2.4620920, 55.2084160],
          [-2.4621590, 55.2084310],
          [-2.4622260, 55.2084460],
          [-2.4622950, 55.2084650],
          [-2.4623620, 55.2084820],
          [-2.4624300, 55.2084990],
          [-2.4624970, 55.2085110],
          [-2.4625570, 55.2085260],
          [-2.4626170, 55.2085490],
          [-2.4626750, 55.2085680],
          [-2.4627290, 55.2085910],
          [-2.4627810, 55.2086100],
          [-2.4628320, 55.2086290],
          [-2.4628830, 55.2086520],
          [-2.4629320, 55.2086740],
          [-2.4629860, 55.2086940],
          [-2.4630440, 55.2087130],
          [-2.4630990, 55.2087250],
          [-2.4631570, 55.2087400],
          [-2.4632130, 55.2087550],
          [-2.4632750, 55.2087740],
          [-2.4633550, 55.2087780],
          [-2.4634350, 55.2087900],
          [-2.4635140, 55.2088010],
          [-2.4635910, 55.2088120],
          [-2.4636590, 55.2088310],
          [-2.4637190, 55.2088470],
          [-2.4637760, 55.2088660],
          [-2.4638290, 55.2088810],
          [-2.4638790, 55.2089030],
          [-2.4639350, 55.2089230],
          [-2.4639920, 55.2089380],
          [-2.4640510, 55.2089610],
          [-2.4641090, 55.2089800],
          [-2.4641500, 55.2089910],
          [-2.4642320, 55.2090140],
          [-2.4642980, 55.2090300],
          [-2.4643480, 55.2090440],
          [-2.4644310, 55.2090640],
          [-2.4644970, 55.2090790],
          [-2.4645420, 55.2090880],
          [-2.4646170, 55.2091060],
          [-2.4646800, 55.2091210],
          [-2.4647310, 55.2091300],
          [-2.4648120, 55.2091520],
          [-2.4648800, 55.2091670],
          [-2.4649270, 55.2091780],
          [-2.4650100, 55.2092010],
          [-2.4650770, 55.2092170],
          [-2.4651250, 55.2092280],
          [-2.4652050, 55.2092510],
          [-2.4652650, 55.2092660],
          [-2.4653050, 55.2092800],
          [-2.4653740, 55.2093040],
          [-2.4654320, 55.2093200],
          [-2.4654830, 55.2093310],
          [-2.4655680, 55.2093460],
          [-2.4656390, 55.2093580],
          [-2.4656890, 55.2093750],
          [-2.4657730, 55.2094030],
          [-2.4658420, 55.2094230],
          [-2.4658920, 55.2094370],
          [-2.4659770, 55.2094570],
          [-2.4660430, 55.2094760],
          [-2.4660910, 55.2094870],
          [-2.4661820, 55.2095070],
          [-2.4662480, 55.2095210],
          [-2.4663020, 55.2095360],
          [-2.4663910, 55.2095560],
          [-2.4664600, 55.2095750],
          [-2.4665120, 55.2095890],
          [-2.4665970, 55.2096090],
          [-2.4666670, 55.2096250],
          [-2.4667150, 55.2096390],
          [-2.4668000, 55.2096590],
          [-2.4668750, 55.2096780],
          [-2.4669230, 55.2096920],
          [-2.4670130, 55.2097160],
          [-2.4670850, 55.2097320],
          [-2.4671370, 55.2097430],
          [-2.4672290, 55.2097620],
          [-2.4673060, 55.2097810],
          [-2.4673630, 55.2097950],
          [-2.4674640, 55.2098190],
          [-2.4675210, 55.2098300],
          [-2.4676230, 55.2098610],
          [-2.4676810, 55.2098750],
          [-2.4677840, 55.2099030],
          [-2.4678480, 55.2099210],
          [-2.4679520, 55.2099490],
          [-2.4680110, 55.2099680],
          [-2.4681150, 55.2099990],
          [-2.4681760, 55.2100150],
          [-2.4682790, 55.2100400],
          [-2.4683400, 55.2100540],
          [-2.4684500, 55.2100820],
          [-2.4685120, 55.2100960],
          [-2.4686220, 55.2101240],
          [-2.4686830, 55.2101440],
          [-2.4687920, 55.2101730],
          [-2.4688560, 55.2101900],
          [-2.4689680, 55.2102160],
          [-2.4690320, 55.2102300],
          [-2.4691440, 55.2102580],
          [-2.4692110, 55.2102690],
          [-2.4693260, 55.2102920],
          [-2.4693930, 55.2103060],
          [-2.4695080, 55.2103300],
          [-2.4695760, 55.2103450],
          [-2.4696920, 55.2103690],
          [-2.4697570, 55.2103800],
          [-2.4698720, 55.2104030],
          [-2.4699440, 55.2104170],
          [-2.4700630, 55.2104410],
          [-2.4701330, 55.2104580],
          [-2.4702620, 55.2104830],
          [-2.4703410, 55.2105000],
          [-2.4704780, 55.2105330],
          [-2.4705610, 55.2105470],
          [-2.4707100, 55.2105710],
          [-2.4707990, 55.2105850],
          [-2.4709600, 55.2106130],
          [-2.4710600, 55.2106290],
          [-2.4712360, 55.2106580],
          [-2.4713450, 55.2106750],
          [-2.4715370, 55.2107040],
          [-2.4716470, 55.2107230],
          [-2.4718470, 55.2107540],
          [-2.4719680, 55.2107740],
          [-2.4721130, 55.2107970],
          [-2.4722840, 55.2108220],
          [-2.4724630, 55.2108470],
          [-2.4726420, 55.2108770],
          [-2.4728230, 55.2109050],
          [-2.4730070, 55.2109310],
          [-2.4731900, 55.2109570],
          [-2.4733820, 55.2109800],
          [-2.4735760, 55.2109990],
          [-2.4737710, 55.2110130],
          [-2.4739660, 55.2110250],
          [-2.4741570, 55.2110320],
          [-2.4743360, 55.2110260],
          [-2.4745740, 55.2109990],
          [-2.4747040, 55.2109750],
          [-2.4748660, 55.2109390],
          [-2.4750550, 55.2108980],
          [-2.4752460, 55.2108550],
          [-2.4754390, 55.2108100],
          [-2.4756440, 55.2107610],
          [-2.4758510, 55.2107110],
          [-2.4760590, 55.2106600],
          [-2.4762690, 55.2106100],
          [-2.4764840, 55.2105600],
          [-2.4766960, 55.2105100],
          [-2.4769070, 55.2104590],
          [-2.4771140, 55.2104120],
          [-2.4773140, 55.2103670],
          [-2.4775070, 55.2103250],
          [-2.4776960, 55.2102820],
          [-2.4779040, 55.2102350],
          [-2.4781040, 55.2101910],
          [-2.4782990, 55.2101480],
          [-2.4784700, 55.2101070],
          [-2.4786440, 55.2100730],
          [-2.4788170, 55.2100310],
          [-2.4789850, 55.2099890],
          [-2.4791330, 55.2099370],
          [-2.4792930, 55.2098640],
          [-2.4794180, 55.2097820],
          [-2.4795300, 55.2096800],
          [-2.4796250, 55.2095740],
          [-2.4797110, 55.2094610],
          [-2.4798020, 55.2093390],
          [-2.4798890, 55.2092150],
          [-2.4799810, 55.2090890],
          [-2.4800750, 55.2089630],
          [-2.4801650, 55.2088400],
          [-2.4802580, 55.2087120],
          [-2.4803470, 55.2085850],
          [-2.4804340, 55.2084500],
          [-2.4805180, 55.2083190],
          [-2.4805980, 55.2081820],
          [-2.4806700, 55.2080540],
          [-2.4807360, 55.2079290],
          [-2.4808190, 55.2077640],
          [-2.4808860, 55.2076310],
          [-2.4809540, 55.2074950],
          [-2.4810190, 55.2073680],
          [-2.4810860, 55.2072410],
          [-2.4811570, 55.2071020],
          [-2.4812210, 55.2069810],
          [-2.4813000, 55.2068440],
          [-2.4813820, 55.2067160],
          [-2.4814840, 55.2065860],
          [-2.4816110, 55.2064630],
          [-2.4817600, 55.2063480],
          [-2.4819190, 55.2062460],
          [-2.4820830, 55.2061500],
          [-2.4822340, 55.2060520],
          [-2.4823810, 55.2059430],
          [-2.4825130, 55.2058370],
          [-2.4826350, 55.2057290],
          [-2.4827540, 55.2056170],
          [-2.4828790, 55.2054980],
          [-2.4830010, 55.2053800],
          [-2.4831160, 55.2052670],
          [-2.4832290, 55.2051480],
          [-2.4833620, 55.2050140],
          [-2.4834810, 55.2048900],
          [-2.4835970, 55.2047690],
          [-2.4837070, 55.2046510],
          [-2.4838230, 55.2045250],
          [-2.4839120, 55.2044110],
          [-2.4839970, 55.2043020],
          [-2.4840740, 55.2041820],
          [-2.4841340, 55.2040610],
          [-2.4841830, 55.2039350],
          [-2.4842220, 55.2038030],
          [-2.4842030, 55.2036680],
          [-2.4841820, 55.2035330],
          [-2.4841600, 55.2033970],
          [-2.4841640, 55.2032510],
          [-2.4841680, 55.2031490],
          [-2.4841720, 55.2030550],
          [-2.4842020, 55.2029440],
          [-2.4842420, 55.2028340],
          [-2.4843070, 55.2027450],
          [-2.4843930, 55.2026680],
          [-2.4844880, 55.2026070],
          [-2.4845990, 55.2025570],
          [-2.4847280, 55.2025200],
          [-2.4848620, 55.2024890],
          [-2.4850200, 55.2024770],
          [-2.4851780, 55.2024770],
          [-2.4853590, 55.2024910],
          [-2.4855410, 55.2025370],
          [-2.4856840, 55.2025840],
          [-2.4858720, 55.2026480],
          [-2.4860390, 55.2027080],
          [-2.4862210, 55.2027690],
          [-2.4863980, 55.2028110],
          [-2.4865740, 55.2028500],
          [-2.4867470, 55.2028840],
          [-2.4869160, 55.2029070],
          [-2.4870800, 55.2029230],
          [-2.4872300, 55.2029190],
          [-2.4873690, 55.2029000],
          [-2.4874890, 55.2028740],
          [-2.4875990, 55.2028410],
          [-2.4877050, 55.2028060],
          [-2.4877980, 55.2027640],
          [-2.4878870, 55.2027190],
          [-2.4879760, 55.2026730],
          [-2.4880640, 55.2026300],
          [-2.4881480, 55.2025880],
          [-2.4882270, 55.2025470],
          [-2.4883070, 55.2025040],
          [-2.4883850, 55.2024620],
          [-2.4884550, 55.2024250],
          [-2.4885260, 55.2023820],
          [-2.4885880, 55.2023400],
          [-2.4886450, 55.2023000],
          [-2.4887120, 55.2022560],
          [-2.4887740, 55.2022140],
          [-2.4888350, 55.2021760],
          [-2.4889000, 55.2021340],
          [-2.4889630, 55.2020960],
          [-2.4890290, 55.2020570],
          [-2.4890900, 55.2020180],
          [-2.4891590, 55.2019780],
          [-2.4892230, 55.2019400],
          [-2.4892890, 55.2019010],
          [-2.4893530, 55.2018630],
          [-2.4894180, 55.2018300],
          [-2.4894850, 55.2017950],
          [-2.4895470, 55.2017600],
          [-2.4896100, 55.2017260],
          [-2.4896760, 55.2016920],
          [-2.4897430, 55.2016620],
          [-2.4898120, 55.2016300],
          [-2.4898810, 55.2016010],
          [-2.4899480, 55.2015710],
          [-2.4900200, 55.2015420],
          [-2.4900900, 55.2015160],
          [-2.4901610, 55.2014860],
          [-2.4902330, 55.2014550],
          [-2.4902940, 55.2014320],
          [-2.4903780, 55.2013980],
          [-2.4904560, 55.2013750],
          [-2.4905390, 55.2013520],
          [-2.4906280, 55.2013290],
          [-2.4907200, 55.2013100],
          [-2.4908090, 55.2012910],
          [-2.4909000, 55.2012710],
          [-2.4909900, 55.2012560],
          [-2.4910810, 55.2012410],
          [-2.4911760, 55.2012300],
          [-2.4912710, 55.2012220],
          [-2.4913690, 55.2012180],
          [-2.4914690, 55.2012140],
          [-2.4915660, 55.2012100],
          [-2.4916670, 55.2012100],
          [-2.4917720, 55.2012140],
          [-2.4918820, 55.2012140],
          [-2.4919950, 55.2012140],
          [-2.4921150, 55.2012100],
          [-2.4922360, 55.2011990],
          [-2.4923560, 55.2011840],
          [-2.4924730, 55.2011580],
          [-2.4925840, 55.2011310],
          [-2.4926900, 55.2010930],
          [-2.4927750, 55.2010570],
          [-2.4928580, 55.2010210],
          [-2.4929350, 55.2009780],
          [-2.4929840, 55.2009430],
          [-2.4930340, 55.2009060],
          [-2.4930770, 55.2008710],
          [-2.4931190, 55.2008410],
          [-2.4931590, 55.2008100],
          [-2.4931970, 55.2007800],
          [-2.4932360, 55.2007490],
          [-2.4932750, 55.2007230],
          [-2.4933180, 55.2006960],
          [-2.4933590, 55.2006690],
          [-2.4934040, 55.2006420],
          [-2.4934450, 55.2006160],
          [-2.4934890, 55.2005890],
          [-2.4935340, 55.2005660],
          [-2.4935820, 55.2005430],
          [-2.4936310, 55.2005210],
          [-2.4936850, 55.2005010],
          [-2.4937370, 55.2004780],
          [-2.4937860, 55.2004550],
          [-2.4938350, 55.2004290],
          [-2.4938850, 55.2004060],
          [-2.4939330, 55.2003800],
          [-2.4939850, 55.2003520],
          [-2.4940320, 55.2003300],
          [-2.4940840, 55.2003030],
          [-2.4941330, 55.2002770],
          [-2.4941840, 55.2002530],
          [-2.4942310, 55.2002300],
          [-2.4942910, 55.2002000],
          [-2.4943410, 55.2001780],
          [-2.4943990, 55.2001500],
          [-2.4944530, 55.2001200],
          [-2.4945110, 55.2000930],
          [-2.4945700, 55.2000660],
          [-2.4946300, 55.2000360],
          [-2.4946910, 55.2000090],
          [-2.4947550, 55.1999820],
          [-2.4948220, 55.1999570],
          [-2.4948930, 55.1999290],
          [-2.4949650, 55.1999030],
          [-2.4950350, 55.1998760],
          [-2.4951090, 55.1998490],
          [-2.4951870, 55.1998260],
          [-2.4952630, 55.1998000],
          [-2.4953440, 55.1997760],
          [-2.4954260, 55.1997530],
          [-2.4955060, 55.1997310],
          [-2.4955900, 55.1997040],
          [-2.4956720, 55.1996810],
          [-2.4957560, 55.1996580],
          [-2.4958430, 55.1996390],
          [-2.4959270, 55.1996200],
          [-2.4960060, 55.1996020],
          [-2.4960960, 55.1995850],
          [-2.4961780, 55.1995660],
          [-2.4962580, 55.1995510],
          [-2.4963380, 55.1995330],
          [-2.4964200, 55.1995210],
          [-2.4965040, 55.1995020],
          [-2.4965890, 55.1994860],
          [-2.4966690, 55.1994710],
          [-2.4967510, 55.1994560],
          [-2.4968370, 55.1994400],
          [-2.4969190, 55.1994290],
          [-2.4970050, 55.1994140],
          [-2.4970890, 55.1994020],
          [-2.4971700, 55.1993940],
          [-2.4972500, 55.1993830],
          [-2.4973350, 55.1993750],
          [-2.4974180, 55.1993710],
          [-2.4974980, 55.1993640],
          [-2.4975820, 55.1993600],
          [-2.4976670, 55.1993520],
          [-2.4977510, 55.1993480],
          [-2.4978300, 55.1993480],
          [-2.4979130, 55.1993480],
          [-2.4979900, 55.1993450],
          [-2.4980720, 55.1993450],
          [-2.4981480, 55.1993480],
          [-2.4982140, 55.1993520],
          [-2.4982990, 55.1993600],
          [-2.4983620, 55.1993630],
          [-2.4984470, 55.1993710],
          [-2.4985170, 55.1993750],
          [-2.4985910, 55.1993870],
          [-2.4986580, 55.1993970],
          [-2.4987330, 55.1994060],
          [-2.4988040, 55.1994170],
          [-2.4988740, 55.1994280],
          [-2.4989460, 55.1994400],
          [-2.4990190, 55.1994510],
          [-2.4990870, 55.1994660],
          [-2.4991620, 55.1994780],
          [-2.4992300, 55.1994890],
          [-2.4993050, 55.1995010],
          [-2.4993740, 55.1995120],
          [-2.4994470, 55.1995270],
          [-2.4995120, 55.1995380],
          [-2.4995880, 55.1995540],
          [-2.4996550, 55.1995730],
          [-2.4997270, 55.1995880],
          [-2.4997950, 55.1996030],
          [-2.4998680, 55.1996190],
          [-2.4999330, 55.1996370],
          [-2.5000060, 55.1996570],
          [-2.5000760, 55.1996760],
          [-2.5001450, 55.1996910],
          [-2.5002070, 55.1997050],
          [-2.5002810, 55.1997250],
          [-2.5003420, 55.1997420],
          [-2.5004190, 55.1997640],
          [-2.5004850, 55.1997820],
          [-2.5005550, 55.1998060],
          [-2.5006240, 55.1998210],
          [-2.5006940, 55.1998430],
          [-2.5007650, 55.1998630],
          [-2.5008300, 55.1998850],
          [-2.5009000, 55.1999090],
          [-2.5009650, 55.1999280],
          [-2.5010320, 55.1999510],
          [-2.5010990, 55.1999730],
          [-2.5011650, 55.1999960],
          [-2.5012320, 55.2000190],
          [-2.5012990, 55.2000420],
          [-2.5013660, 55.2000670],
          [-2.5014410, 55.2000920],
          [-2.5015070, 55.2001180],
          [-2.5015750, 55.2001440],
          [-2.5016440, 55.2001670],
          [-2.5017140, 55.2001940],
          [-2.5017830, 55.2002170],
          [-2.5018500, 55.2002440],
          [-2.5019170, 55.2002670],
          [-2.5019710, 55.2002850],
          [-2.5020510, 55.2003160],
          [-2.5021120, 55.2003370],
          [-2.5021900, 55.2003630],
          [-2.5022590, 55.2003890],
          [-2.5023270, 55.2004160],
          [-2.5023920, 55.2004380],
          [-2.5024590, 55.2004580],
          [-2.5025210, 55.2004810],
          [-2.5025850, 55.2005000],
          [-2.5026470, 55.2005220],
          [-2.5027160, 55.2005420],
          [-2.5027800, 55.2005640],
          [-2.5028510, 55.2005880],
          [-2.5029170, 55.2006090],
          [-2.5029920, 55.2006330],
          [-2.5030620, 55.2006600],
          [-2.5031350, 55.2006830],
          [-2.5032050, 55.2007060],
          [-2.5032750, 55.2007290],
          [-2.5033440, 55.2007520],
          [-2.5034100, 55.2007740],
          [-2.5034790, 55.2007980],
          [-2.5035450, 55.2008200],
          [-2.5036080, 55.2008400],
          [-2.5036720, 55.2008610],
          [-2.5037420, 55.2008820],
          [-2.5038000, 55.2009040],
          [-2.5038760, 55.2009310],
          [-2.5039460, 55.2009540],
          [-2.5040140, 55.2009760],
          [-2.5040810, 55.2009990],
          [-2.5041500, 55.2010260],
          [-2.5042200, 55.2010490],
          [-2.5042880, 55.2010680],
          [-2.5043570, 55.2010910],
          [-2.5044000, 55.2011060],
          [-2.5044910, 55.2011370],
          [-2.5045560, 55.2011600],
          [-2.5046220, 55.2011830],
          [-2.5046870, 55.2012060],
          [-2.5047540, 55.2012250],
          [-2.5048220, 55.2012440],
          [-2.5048900, 55.2012630],
          [-2.5049570, 55.2012820],
          [-2.5050210, 55.2013050],
          [-2.5050890, 55.2013280],
          [-2.5051550, 55.2013500],
          [-2.5052250, 55.2013740],
          [-2.5052950, 55.2013960],
          [-2.5053640, 55.2014190],
          [-2.5054330, 55.2014460],
          [-2.5055020, 55.2014690],
          [-2.5055700, 55.2014880],
          [-2.5056370, 55.2015150],
          [-2.5057050, 55.2015340],
          [-2.5057720, 55.2015560],
          [-2.5058420, 55.2015800],
          [-2.5059070, 55.2016060],
          [-2.5059750, 55.2016330],
          [-2.5060450, 55.2016560],
          [-2.5061150, 55.2016790],
          [-2.5061840, 55.2016980],
          [-2.5062520, 55.2017210],
          [-2.5063190, 55.2017440],
          [-2.5063830, 55.2017660],
          [-2.5064480, 55.2017930],
          [-2.5065130, 55.2018160],
          [-2.5065790, 55.2018350],
          [-2.5066470, 55.2018580],
          [-2.5067150, 55.2018810],
          [-2.5067810, 55.2019040],
          [-2.5068480, 55.2019270],
          [-2.5069160, 55.2019500],
          [-2.5069840, 55.2019760],
          [-2.5070520, 55.2019950],
          [-2.5071220, 55.2020180],
          [-2.5071900, 55.2020410],
          [-2.5072620, 55.2020680],
          [-2.5073300, 55.2020900],
          [-2.5074010, 55.2021100],
          [-2.5074720, 55.2021330],
          [-2.5075420, 55.2021590],
          [-2.5076140, 55.2021820],
          [-2.5076840, 55.2022050],
          [-2.5077540, 55.2022280],
          [-2.5078270, 55.2022510],
          [-2.5079020, 55.2022700],
          [-2.5079770, 55.2022930],
          [-2.5080520, 55.2023120],
          [-2.5081270, 55.2023350],
          [-2.5082000, 55.2023540],
          [-2.5082740, 55.2023730],
          [-2.5083500, 55.2024000],
          [-2.5084230, 55.2024230],
          [-2.5084970, 55.2024450],
          [-2.5085690, 55.2024720],
          [-2.5086440, 55.2024990],
          [-2.5087220, 55.2025260],
          [-2.5088050, 55.2025560],
          [-2.5088880, 55.2025830],
          [-2.5089730, 55.2026090],
          [-2.5090610, 55.2026360],
          [-2.5091530, 55.2026630],
          [-2.5092510, 55.2026930],
          [-2.5093510, 55.2027240],
          [-2.5094530, 55.2027540],
          [-2.5095580, 55.2027810],
          [-2.5096630, 55.2028190],
          [-2.5097690, 55.2028570],
          [-2.5098830, 55.2028950],
          [-2.5100080, 55.2029250],
          [-2.5101550, 55.2029560],
          [-2.5103080, 55.2029860],
          [-2.5104750, 55.2030060],
          [-2.5106390, 55.2030100],
          [-2.5108010, 55.2029960],
          [-2.5109650, 55.2029690],
          [-2.5111340, 55.2029430],
          [-2.5113100, 55.2029090],
          [-2.5114870, 55.2028740],
          [-2.5116610, 55.2028440],
          [-2.5118070, 55.2027940],
          [-2.5119230, 55.2027300],
          [-2.5120280, 55.2026420],
          [-2.5121080, 55.2025370],
          [-2.5121760, 55.2024340],
          [-2.5122420, 55.2023470],
          [-2.5123370, 55.2022270],
          [-2.5124120, 55.2021200],
          [-2.5125010, 55.2020200],
          [-2.5125960, 55.2019250],
          [-2.5126590, 55.2018100],
          [-2.5126990, 55.2016870],
          [-2.5127310, 55.2015690],
          [-2.5127630, 55.2014340],
          [-2.5127980, 55.2013200],
          [-2.5128430, 55.2012010],
          [-2.5128870, 55.2010870],
          [-2.5129380, 55.2009870],
          [-2.5130150, 55.2008900],
          [-2.5131330, 55.2007920],
          [-2.5132370, 55.2007020],
          [-2.5133170, 55.2006160],
          [-2.5133690, 55.2005350],
          [-2.5134430, 55.2004490],
          [-2.5135240, 55.2003780],
          [-2.5136200, 55.2003020],
          [-2.5136970, 55.2002200],
          [-2.5137480, 55.2001440],
          [-2.5137870, 55.2000480],
          [-2.5138070, 55.1999720],
          [-2.5138250, 55.1998760],
          [-2.5138300, 55.1997980],
          [-2.5138320, 55.1997160],
          [-2.5138330, 55.1996380],
          [-2.5138450, 55.1995590],
          [-2.5138590, 55.1994960],
          [-2.5138860, 55.1994260],
          [-2.5139210, 55.1993720],
          [-2.5139610, 55.1993080],
          [-2.5140030, 55.1992560],
          [-2.5140560, 55.1992040],
          [-2.5141120, 55.1991570],
          [-2.5141750, 55.1991090],
          [-2.5142330, 55.1990650],
          [-2.5142890, 55.1990190],
          [-2.5143410, 55.1989710],
          [-2.5144020, 55.1989220],
          [-2.5144600, 55.1988750],
          [-2.5145240, 55.1988300],
          [-2.5145740, 55.1987900],
          [-2.5146370, 55.1987430],
          [-2.5146900, 55.1986990],
          [-2.5147530, 55.1986510],
          [-2.5148060, 55.1986080],
          [-2.5148760, 55.1985520],
          [-2.5149290, 55.1985070],
          [-2.5149900, 55.1984500],
          [-2.5150480, 55.1983980],
          [-2.5151090, 55.1983390],
          [-2.5151610, 55.1982890],
          [-2.5152220, 55.1982350],
          [-2.5152750, 55.1981860],
          [-2.5153290, 55.1981290],
          [-2.5153700, 55.1980770],
          [-2.5154110, 55.1980190],
          [-2.5154400, 55.1979650],
          [-2.5154750, 55.1979070],
          [-2.5155040, 55.1978580],
          [-2.5155380, 55.1977930],
          [-2.5155680, 55.1977410],
          [-2.5156080, 55.1976710],
          [-2.5156440, 55.1976160],
          [-2.5156790, 55.1975530],
          [-2.5157040, 55.1974930],
          [-2.5157260, 55.1974190],
          [-2.5157370, 55.1973590],
          [-2.5157480, 55.1972860],
          [-2.5157620, 55.1972230],
          [-2.5157750, 55.1971480],
          [-2.5157870, 55.1970870],
          [-2.5158080, 55.1970110],
          [-2.5158260, 55.1969510],
          [-2.5158460, 55.1968750],
          [-2.5158680, 55.1968110],
          [-2.5158980, 55.1967410],
          [-2.5159230, 55.1966830],
          [-2.5159540, 55.1966110],
          [-2.5159870, 55.1965500],
          [-2.5160240, 55.1964810],
          [-2.5160600, 55.1964260],
          [-2.5161040, 55.1963630],
          [-2.5161480, 55.1963110],
          [-2.5161960, 55.1962710],
          [-2.5162370, 55.1962530],
          [-2.5162680, 55.1962580],
          [-2.5162730, 55.1962770],
          [-2.5162590, 55.1963030],
          [-2.5162390, 55.1963300],
          [-2.5162210, 55.1963640],
          [-2.5162040, 55.1963990],
          [-2.5161870, 55.1964440],
          [-2.5161790, 55.1964900],
          [-2.5161710, 55.1965440],
          [-2.5161650, 55.1966120],
          [-2.5161670, 55.1966830],
          [-2.5161720, 55.1967680],
          [-2.5161720, 55.1968410],
          [-2.5161680, 55.1969280],
          [-2.5161650, 55.1970050],
          [-2.5161770, 55.1970920],
          [-2.5162020, 55.1971660],
          [-2.5162300, 55.1972450],
          [-2.5162550, 55.1973140],
          [-2.5162800, 55.1973870],
          [-2.5163000, 55.1974530],
          [-2.5163130, 55.1975310],
          [-2.5163120, 55.1975960],
          [-2.5163000, 55.1976680],
          [-2.5162830, 55.1977340],
          [-2.5162570, 55.1978020],
          [-2.5162280, 55.1978690],
          [-2.5161840, 55.1979320],
          [-2.5161350, 55.1979890],
          [-2.5160790, 55.1980470],
          [-2.5160330, 55.1980950],
          [-2.5159770, 55.1981560],
          [-2.5159270, 55.1982050],
          [-2.5158630, 55.1982680],
          [-2.5158000, 55.1983160],
          [-2.5157230, 55.1983780],
          [-2.5156670, 55.1984400],
          [-2.5156100, 55.1985110],
          [-2.5155550, 55.1985740],
          [-2.5154770, 55.1986340],
          [-2.5154160, 55.1986910],
          [-2.5153580, 55.1987520],
          [-2.5153290, 55.1988060],
          [-2.5153100, 55.1988850],
          [-2.5152950, 55.1989520],
          [-2.5152690, 55.1990190],
          [-2.5152370, 55.1990700],
          [-2.5151990, 55.1991220],
          [-2.5151570, 55.1991670],
          [-2.5151030, 55.1992100],
          [-2.5150630, 55.1992490],
          [-2.5150140, 55.1992940],
          [-2.5149710, 55.1993360],
          [-2.5149190, 55.1993890],
          [-2.5148820, 55.1994460],
          [-2.5148450, 55.1995150],
          [-2.5148270, 55.1995800],
          [-2.5147960, 55.1996560],
          [-2.5147610, 55.1997220],
          [-2.5147170, 55.1997860],
          [-2.5146790, 55.1998420],
          [-2.5146440, 55.1999010],
          [-2.5146230, 55.1999550],
          [-2.5146020, 55.2000180],
          [-2.5145940, 55.2000790],
          [-2.5145870, 55.2001330],
          [-2.5145820, 55.2001810],
          [-2.5145800, 55.2002400],
          [-2.5145770, 55.2002980],
          [-2.5145620, 55.2003580],
          [-2.5145180, 55.2004110],
          [-2.5144570, 55.2004800],
          [-2.5144110, 55.2005460],
          [-2.5144020, 55.2006220],
          [-2.5144270, 55.2006900],
          [-2.5144740, 55.2007740],
          [-2.5145240, 55.2008420],
          [-2.5145860, 55.2009300],
          [-2.5146300, 55.2010040],
          [-2.5146620, 55.2010930],
          [-2.5146660, 55.2011770],
          [-2.5146470, 55.2012690],
          [-2.5146140, 55.2013460],
          [-2.5145660, 55.2014250],
          [-2.5145170, 55.2014900],
          [-2.5144560, 55.2015630],
          [-2.5144120, 55.2016180],
          [-2.5143770, 55.2016740],
          [-2.5143680, 55.2017300],
          [-2.5143630, 55.2017920],
          [-2.5143570, 55.2018440],
          [-2.5143450, 55.2018990],
          [-2.5143370, 55.2019490],
          [-2.5143410, 55.2020100],
          [-2.5143590, 55.2020750],
          [-2.5143940, 55.2021470],
          [-2.5144420, 55.2021980],
          [-2.5144980, 55.2022460],
          [-2.5145350, 55.2023000],
          [-2.5145450, 55.2023690],
          [-2.5145240, 55.2024260],
          [-2.5144790, 55.2024750],
          [-2.5144380, 55.2025070],
          [-2.5143880, 55.2025520],
          [-2.5143530, 55.2025830],
          [-2.5143160, 55.2026160],
          [-2.5142890, 55.2026470],
          [-2.5142680, 55.2026720],
          [-2.5142490, 55.2027010],
          [-2.5142290, 55.2027240],
          [-2.5142060, 55.2027510],
          [-2.5141830, 55.2027710],
          [-2.5141590, 55.2028000],
          [-2.5141370, 55.2028190],
          [-2.5141140, 55.2028420],
          [-2.5140930, 55.2028590],
          [-2.5140690, 55.2028800],
          [-2.5140420, 55.2029000],
          [-2.5140160, 55.2029140],
          [-2.5139960, 55.2029320],
          [-2.5139740, 55.2029450],
          [-2.5139560, 55.2029640],
          [-2.5139390, 55.2029830],
          [-2.5139270, 55.2029990],
          [-2.5139180, 55.2030160],
          [-2.5139090, 55.2030370],
          [-2.5139040, 55.2030560],
          [-2.5139030, 55.2030780],
          [-2.5139020, 55.2031010],
          [-2.5138970, 55.2031220],
          [-2.5138950, 55.2031510],
          [-2.5138950, 55.2031820],
          [-2.5138920, 55.2032120],
          [-2.5138860, 55.2032440],
          [-2.5138740, 55.2032810],
          [-2.5138640, 55.2033110],
          [-2.5138490, 55.2033460],
          [-2.5138320, 55.2033910],
          [-2.5138170, 55.2034370],
          [-2.5138060, 55.2034780],
          [-2.5137960, 55.2035330],
          [-2.5137850, 55.2035810],
          [-2.5137740, 55.2036390],
          [-2.5137580, 55.2036880],
          [-2.5137300, 55.2037530],
          [-2.5136990, 55.2038150],
          [-2.5136530, 55.2038830],
          [-2.5136100, 55.2039260],
          [-2.5135590, 55.2039670],
          [-2.5135230, 55.2040030],
          [-2.5134870, 55.2040360],
          [-2.5134520, 55.2040700],
          [-2.5134230, 55.2040980],
          [-2.5133910, 55.2041280],
          [-2.5133650, 55.2041510],
          [-2.5133500, 55.2041740],
          [-2.5133320, 55.2042080],
          [-2.5133300, 55.2042450],
          [-2.5133450, 55.2042830],
          [-2.5133820, 55.2043330],
          [-2.5134320, 55.2043740],
          [-2.5134950, 55.2044170],
          [-2.5135530, 55.2044450],
          [-2.5136250, 55.2044780],
          [-2.5136900, 55.2045030],
          [-2.5137790, 55.2045360],
          [-2.5138550, 55.2045670],
          [-2.5139610, 55.2046050],
          [-2.5140480, 55.2046350],
          [-2.5141670, 55.2046810],
          [-2.5142700, 55.2047240],
          [-2.5143890, 55.2047760],
          [-2.5144800, 55.2048180],
          [-2.5146030, 55.2048710],
          [-2.5147030, 55.2049140],
          [-2.5148410, 55.2049660],
          [-2.5149660, 55.2050020],
          [-2.5151030, 55.2050360],
          [-2.5152090, 55.2050570],
          [-2.5153200, 55.2050780],
          [-2.5154030, 55.2051010],
          [-2.5155050, 55.2051240],
          [-2.5155860, 55.2051440],
          [-2.5156880, 55.2051650],
          [-2.5157750, 55.2051860],
          [-2.5158650, 55.2052070],
          [-2.5159430, 55.2052320],
          [-2.5160320, 55.2052720],
          [-2.5161040, 55.2053190],
          [-2.5161940, 55.2053820],
          [-2.5162580, 55.2054350],
          [-2.5163350, 55.2054930],
          [-2.5164030, 55.2055350],
          [-2.5165000, 55.2055770],
          [-2.5165800, 55.2056050],
          [-2.5166760, 55.2056340],
          [-2.5167600, 55.2056560],
          [-2.5168500, 55.2056800],
          [-2.5169260, 55.2057000],
          [-2.5170230, 55.2057260],
          [-2.5171010, 55.2057500],
          [-2.5171960, 55.2057830],
          [-2.5172750, 55.2058080],
          [-2.5173640, 55.2058440],
          [-2.5174360, 55.2058690],
          [-2.5175190, 55.2059010],
          [-2.5175880, 55.2059290],
          [-2.5176680, 55.2059620],
          [-2.5177350, 55.2059970],
          [-2.5178080, 55.2060380],
          [-2.5178640, 55.2060770],
          [-2.5179170, 55.2061190],
          [-2.5179540, 55.2061570],
          [-2.5179930, 55.2062020],
          [-2.5180190, 55.2062380],
          [-2.5180470, 55.2062780],
          [-2.5180730, 55.2063170],
          [-2.5180920, 55.2063500],
          [-2.5181150, 55.2063930],
          [-2.5181350, 55.2064320],
          [-2.5181530, 55.2064660],
          [-2.5181730, 55.2065020],
          [-2.5181960, 55.2065380],
          [-2.5182160, 55.2065680],
          [-2.5182480, 55.2066030],
          [-2.5182810, 55.2066380],
          [-2.5183120, 55.2066650],
          [-2.5183550, 55.2066980],
          [-2.5184020, 55.2067290],
          [-2.5184500, 55.2067530],
          [-2.5185190, 55.2067830],
          [-2.5185820, 55.2068090],
          [-2.5186310, 55.2068410],
          [-2.5186690, 55.2068820],
          [-2.5186830, 55.2069300],
          [-2.5186860, 55.2069760],
          [-2.5186900, 55.2070450],
          [-2.5187030, 55.2070940],
          [-2.5187360, 55.2071480],
          [-2.5187840, 55.2071800],
          [-2.5188470, 55.2072020],
          [-2.5189000, 55.2071810],
          [-2.5189360, 55.2071610],
          [-2.5189790, 55.2071350],
          [-2.5190210, 55.2071080],
          [-2.5190520, 55.2070890],
          [-2.5190950, 55.2070740],
          [-2.5191300, 55.2070730],
          [-2.5191490, 55.2070920],
          [-2.5191440, 55.2071150],
          [-2.5191260, 55.2071330],
          [-2.5191090, 55.2071530],
          [-2.5190990, 55.2071750],
          [-2.5191000, 55.2071910],
          [-2.5191040, 55.2072020],
          [-2.5191130, 55.2072180],
          [-2.5191200, 55.2072330],
          [-2.5191350, 55.2072480],
          [-2.5191540, 55.2072600],
          [-2.5191820, 55.2072670],
          [-2.5192130, 55.2072610],
          [-2.5192440, 55.2072490],
          [-2.5192640, 55.2072340],
          [-2.5192810, 55.2072180],
          [-2.5192970, 55.2071930],
          [-2.5193100, 55.2071750],
          [-2.5193230, 55.2071500],
          [-2.5193360, 55.2071280],
          [-2.5193540, 55.2070980],
          [-2.5193750, 55.2070690],
          [-2.5194090, 55.2070400],
          [-2.5194510, 55.2070090],
          [-2.5195070, 55.2069880],
          [-2.5195910, 55.2069780],
          [-2.5196740, 55.2069780],
          [-2.5197530, 55.2069780],
          [-2.5198620, 55.2069740],
          [-2.5199650, 55.2069710],
          [-2.5200970, 55.2069700],
          [-2.5202210, 55.2069700],
          [-2.5203780, 55.2069810],
          [-2.5205170, 55.2069920],
          [-2.5206890, 55.2070080],
          [-2.5208410, 55.2070290],
          [-2.5209910, 55.2070570],
          [-2.5211580, 55.2070920],
          [-2.5213140, 55.2071300],
          [-2.5214630, 55.2071750],
          [-2.5216060, 55.2072250],
          [-2.5217570, 55.2072870],
          [-2.5218940, 55.2073440],
          [-2.5220310, 55.2073960],
          [-2.5221540, 55.2074560],
          [-2.5222670, 55.2075190],
          [-2.5223870, 55.2075820],
          [-2.5224980, 55.2076420],
          [-2.5226020, 55.2077130],
          [-2.5226710, 55.2077790],
          [-2.5227320, 55.2078530],
          [-2.5227780, 55.2079210],
          [-2.5228290, 55.2079910],
          [-2.5228720, 55.2080460],
          [-2.5229350, 55.2081160],
          [-2.5229950, 55.2081760],
          [-2.5230780, 55.2082430],
          [-2.5231500, 55.2082920],
          [-2.5232480, 55.2083530],
          [-2.5233280, 55.2084010],
          [-2.5234300, 55.2084570],
          [-2.5235170, 55.2084940],
          [-2.5236270, 55.2085400],
          [-2.5237200, 55.2085780],
          [-2.5238320, 55.2086280],
          [-2.5239180, 55.2086700],
          [-2.5240130, 55.2087210],
          [-2.5240960, 55.2087600],
          [-2.5242070, 55.2088040],
          [-2.5242980, 55.2088360],
          [-2.5244030, 55.2088690],
          [-2.5244940, 55.2088930],
          [-2.5246210, 55.2089150],
          [-2.5247300, 55.2089220],
          [-2.5248650, 55.2089270],
          [-2.5249750, 55.2089370],
          [-2.5251050, 55.2089610],
          [-2.5252010, 55.2089830],
          [-2.5253290, 55.2090250],
          [-2.5254150, 55.2090600],
          [-2.5255240, 55.2091190],
          [-2.5255900, 55.2091650],
          [-2.5256680, 55.2092380],
          [-2.5257070, 55.2092820],
          [-2.5257550, 55.2093450],
          [-2.5257840, 55.2093870],
          [-2.5258200, 55.2094360],
          [-2.5258530, 55.2094700],
          [-2.5259060, 55.2095210],
          [-2.5259500, 55.2095650],
          [-2.5260110, 55.2096230],
          [-2.5260670, 55.2096740],
          [-2.5261430, 55.2097270],
          [-2.5262090, 55.2097650],
          [-2.5262910, 55.2098100],
          [-2.5263630, 55.2098420],
          [-2.5264500, 55.2098860],
          [-2.5265210, 55.2099150],
          [-2.5266200, 55.2099410],
          [-2.5266840, 55.2099420],
          [-2.5267320, 55.2099210],
          [-2.5267400, 55.2098910],
          [-2.5267340, 55.2098480],
          [-2.5267260, 55.2098150],
          [-2.5267280, 55.2097760],
          [-2.5267380, 55.2097420],
          [-2.5267530, 55.2097150],
          [-2.5267890, 55.2096840],
          [-2.5268390, 55.2096640],
          [-2.5268890, 55.2096570],
          [-2.5269450, 55.2096630],
          [-2.5269860, 55.2096780],
          [-2.5270170, 55.2096970],
          [-2.5270330, 55.2097190],
          [-2.5270410, 55.2097490],
          [-2.5270460, 55.2097770],
          [-2.5270550, 55.2098000],
          [-2.5270630, 55.2098190],
          [-2.5270760, 55.2098460],
          [-2.5270880, 55.2098650],
          [-2.5271010, 55.2098880],
          [-2.5271130, 55.2099140],
          [-2.5271290, 55.2099440],
          [-2.5271410, 55.2099750],
          [-2.5271530, 55.2100050],
          [-2.5271600, 55.2100280],
          [-2.5271720, 55.2100580],
          [-2.5271840, 55.2100850],
          [-2.5271940, 55.2101150],
          [-2.5272050, 55.2101400],
          [-2.5272140, 55.2101690],
          [-2.5272260, 55.2101960],
          [-2.5272360, 55.2102190],
          [-2.5272450, 55.2102420],
          [-2.5272530, 55.2102720],
          [-2.5272610, 55.2102990],
          [-2.5272700, 55.2103220],
          [-2.5272780, 55.2103450],
          [-2.5272910, 55.2103720],
          [-2.5273010, 55.2103980],
          [-2.5273090, 55.2104250],
          [-2.5273180, 55.2104480],
          [-2.5273290, 55.2104780],
          [-2.5273410, 55.2105050],
          [-2.5273510, 55.2105310],
          [-2.5273600, 55.2105550],
          [-2.5273730, 55.2105890],
          [-2.5273890, 55.2106120],
          [-2.5274040, 55.2106390],
          [-2.5274150, 55.2106680],
          [-2.5274250, 55.2106970],
          [-2.5274330, 55.2107190],
          [-2.5274440, 55.2107440],
          [-2.5274550, 55.2107690],
          [-2.5274630, 55.2107920],
          [-2.5274680, 55.2108150],
          [-2.5274690, 55.2108380],
          [-2.5274700, 55.2108610],
          [-2.5274750, 55.2108760],
          [-2.5274790, 55.2108970],
          [-2.5274830, 55.2109180],
          [-2.5274850, 55.2109480],
          [-2.5274820, 55.2109790],
          [-2.5274800, 55.2110090],
          [-2.5274700, 55.2110360],
          [-2.5274510, 55.2110630],
          [-2.5274270, 55.2110860],
          [-2.5274070, 55.2111090],
          [-2.5273840, 55.2111280],
          [-2.5273620, 55.2111500],
          [-2.5273440, 55.2111810],
          [-2.5273240, 55.2112120],
          [-2.5273050, 55.2112420],
          [-2.5272770, 55.2112730],
          [-2.5272490, 55.2112990],
          [-2.5272180, 55.2113300],
          [-2.5271740, 55.2113600],
          [-2.5271370, 55.2113910],
          [-2.5270990, 55.2114170],
          [-2.5270670, 55.2114480],
          [-2.5270370, 55.2114860],
          [-2.5270070, 55.2115240],
          [-2.5269790, 55.2115590],
          [-2.5269550, 55.2115890],
          [-2.5269420, 55.2116190],
          [-2.5269300, 55.2116610],
          [-2.5269280, 55.2117110],
          [-2.5269280, 55.2117530],
          [-2.5269330, 55.2117940],
          [-2.5269380, 55.2118320],
          [-2.5269370, 55.2118670],
          [-2.5269370, 55.2119090],
          [-2.5269460, 55.2119510],
          [-2.5269550, 55.2120010],
          [-2.5269580, 55.2120470],
          [-2.5269650, 55.2120920],
          [-2.5269780, 55.2121340],
          [-2.5269900, 55.2121760],
          [-2.5270010, 55.2122150],
          [-2.5270130, 55.2122530],
          [-2.5270280, 55.2122910],
          [-2.5270410, 55.2123290],
          [-2.5270420, 55.2123670],
          [-2.5270530, 55.2124020],
          [-2.5270610, 55.2124310],
          [-2.5270710, 55.2124580],
          [-2.5270900, 55.2124740],
          [-2.5271160, 55.2124900],
          [-2.5271460, 55.2125120],
          [-2.5271810, 55.2125280],
          [-2.5272100, 55.2125540],
          [-2.5272540, 55.2125770],
          [-2.5273070, 55.2125960],
          [-2.5273660, 55.2125970],
          [-2.5274360, 55.2125930],
          [-2.5275030, 55.2125860],
          [-2.5275720, 55.2125740],
          [-2.5276350, 55.2125630],
          [-2.5277120, 55.2125550],
          [-2.5277920, 55.2125440],
          [-2.5278710, 55.2125290],
          [-2.5279520, 55.2125170],
          [-2.5280380, 55.2125020],
          [-2.5281150, 55.2124870],
          [-2.5281940, 55.2124750],
          [-2.5282710, 55.2124640],
          [-2.5283450, 55.2124520],
          [-2.5284160, 55.2124300],
          [-2.5284990, 55.2124140],
          [-2.5285890, 55.2124060],
          [-2.5286700, 55.2124060],
          [-2.5287580, 55.2124170],
          [-2.5288510, 55.2124360],
          [-2.5289580, 55.2124630],
          [-2.5290790, 55.2124860],
          [-2.5292150, 55.2125090],
          [-2.5293570, 55.2125280],
          [-2.5295080, 55.2125430],
          [-2.5296680, 55.2125620],
          [-2.5298320, 55.2125780],
          [-2.5299900, 55.2125890],
          [-2.5301410, 55.2126080],
          [-2.5302950, 55.2126380],
          [-2.5304430, 55.2126730],
          [-2.5305930, 55.2127100],
          [-2.5307640, 55.2127550],
          [-2.5309370, 55.2128170],
          [-2.5311040, 55.2128700],
          [-2.5312780, 55.2129240],
          [-2.5314430, 55.2129630],
          [-2.5316210, 55.2129970],
          [-2.5317800, 55.2130500],
          [-2.5319230, 55.2131100],
          [-2.5320830, 55.2131530],
          [-2.5322510, 55.2131800],
          [-2.5324250, 55.2131990],
          [-2.5325930, 55.2132070],
          [-2.5327540, 55.2131920],
          [-2.5329190, 55.2131730],
          [-2.5330740, 55.2131730],
          [-2.5332180, 55.2131880],
          [-2.5333420, 55.2132280],
          [-2.5334480, 55.2132710],
          [-2.5335280, 55.2133240],
          [-2.5335850, 55.2133810],
          [-2.5336230, 55.2134390],
          [-2.5336440, 55.2134960],
          [-2.5336580, 55.2135600],
          [-2.5336700, 55.2136210],
          [-2.5336890, 55.2136780],
          [-2.5337270, 55.2137290],
          [-2.5337820, 55.2137710],
          [-2.5338480, 55.2138020],
          [-2.5339150, 55.2138210],
          [-2.5339810, 55.2138360],
          [-2.5340480, 55.2138480],
          [-2.5341100, 55.2138630],
          [-2.5341690, 55.2138860],
          [-2.5342200, 55.2139050],
          [-2.5342670, 55.2139280],
          [-2.5343110, 55.2139540],
          [-2.5343520, 55.2139880],
          [-2.5343930, 55.2140230],
          [-2.5344310, 55.2140570],
          [-2.5344710, 55.2140920],
          [-2.5345070, 55.2141220],
          [-2.5345470, 55.2141450],
          [-2.5345860, 55.2141570],
          [-2.5346290, 55.2141680],
          [-2.5346680, 55.2141800],
          [-2.5347070, 55.2141840],
          [-2.5347470, 55.2141880],
          [-2.5347860, 55.2141950],
          [-2.5348260, 55.2142030],
          [-2.5348650, 55.2142070],
          [-2.5349040, 55.2142180],
          [-2.5349440, 55.2142220],
          [-2.5349860, 55.2142290],
          [-2.5350260, 55.2142410],
          [-2.5350630, 55.2142480],
          [-2.5350990, 55.2142600],
          [-2.5351350, 55.2142710],
          [-2.5351690, 55.2142830],
          [-2.5352020, 55.2142900],
          [-2.5352340, 55.2142980],
          [-2.5352660, 55.2143060],
          [-2.5352960, 55.2143130],
          [-2.5353260, 55.2143210],
          [-2.5353580, 55.2143250],
          [-2.5353880, 55.2143360],
          [-2.5354180, 55.2143440],
          [-2.5354480, 55.2143510],
          [-2.5354780, 55.2143630],
          [-2.5355100, 55.2143670],
          [-2.5355430, 55.2143780],
          [-2.5355740, 55.2143820],
          [-2.5356060, 55.2143900],
          [-2.5356380, 55.2143970],
          [-2.5356680, 55.2144050],
          [-2.5356980, 55.2144090],
          [-2.5357270, 55.2144160],
          [-2.5357570, 55.2144280],
          [-2.5357860, 55.2144320],
          [-2.5358160, 55.2144390],
          [-2.5358440, 55.2144470],
          [-2.5358720, 55.2144580],
          [-2.5359000, 55.2144620],
          [-2.5359290, 55.2144700],
          [-2.5359580, 55.2144740],
          [-2.5359870, 55.2144770],
          [-2.5360180, 55.2144850],
          [-2.5360470, 55.2144850],
          [-2.5360780, 55.2144810],
          [-2.5361090, 55.2144780],
          [-2.5361390, 55.2144780],
          [-2.5361710, 55.2144740],
          [-2.5362030, 55.2144700],
          [-2.5362290, 55.2144660],
          [-2.5362540, 55.2144590],
          [-2.5362790, 55.2144470],
          [-2.5363000, 55.2144360],
          [-2.5363230, 55.2144210],
          [-2.5363430, 55.2144050],
          [-2.5363600, 55.2143860],
          [-2.5363730, 55.2143710],
          [-2.5363850, 55.2143520],
          [-2.5363970, 55.2143330],
          [-2.5364050, 55.2143140],
          [-2.5364130, 55.2142990],
          [-2.5364200, 55.2142800],
          [-2.5364270, 55.2142610],
          [-2.5364330, 55.2142410],
          [-2.5364400, 55.2142230],
          [-2.5364430, 55.2142030],
          [-2.5364450, 55.2141840],
          [-2.5364480, 55.2141650],
          [-2.5364500, 55.2141470],
          [-2.5364530, 55.2141230],
          [-2.5364570, 55.2141040],
          [-2.5364600, 55.2140850],
          [-2.5364600, 55.2140620],
          [-2.5364630, 55.2140470],
          [-2.5364650, 55.2140240],
          [-2.5364650, 55.2140090],
          [-2.5364670, 55.2139860],
          [-2.5364680, 55.2139710],
          [-2.5364710, 55.2139480],
          [-2.5364760, 55.2139290],
          [-2.5364850, 55.2139060],
          [-2.5364910, 55.2138870],
          [-2.5365000, 55.2138640],
          [-2.5365100, 55.2138450],
          [-2.5365230, 55.2138260],
          [-2.5365400, 55.2138030],
          [-2.5365580, 55.2137840],
          [-2.5365780, 55.2137690],
          [-2.5365980, 55.2137460],
          [-2.5366180, 55.2137260],
          [-2.5366390, 55.2137070],
          [-2.5366630, 55.2136920],
          [-2.5366860, 55.2136690],
          [-2.5367110, 55.2136500],
          [-2.5367360, 55.2136350],
          [-2.5367610, 55.2136160],
          [-2.5367870, 55.2136010],
          [-2.5368140, 55.2135850],
          [-2.5368420, 55.2135700],
          [-2.5368680, 55.2135550],
          [-2.5368940, 55.2135400],
          [-2.5369210, 55.2135240],
          [-2.5369480, 55.2135090],
          [-2.5369740, 55.2134980],
          [-2.5370010, 55.2134860],
          [-2.5370260, 55.2134710],
          [-2.5370500, 55.2134560],
          [-2.5370710, 55.2134330],
          [-2.5370890, 55.2134180],
          [-2.5371100, 55.2133980],
          [-2.5371300, 55.2133760],
          [-2.5371460, 55.2133570],
          [-2.5371650, 55.2133340],
          [-2.5371830, 55.2133150],
          [-2.5371980, 55.2132920],
          [-2.5372110, 55.2132690],
          [-2.5372230, 55.2132420],
          [-2.5372310, 55.2132160],
          [-2.5372400, 55.2131850],
          [-2.5372470, 55.2131580],
          [-2.5372550, 55.2131320],
          [-2.5372600, 55.2130980],
          [-2.5372620, 55.2130600],
          [-2.5372590, 55.2130290],
          [-2.5372540, 55.2129950],
          [-2.5372460, 55.2129610],
          [-2.5372350, 55.2129260],
          [-2.5372220, 55.2128960],
          [-2.5372060, 55.2128610],
          [-2.5371820, 55.2128270],
          [-2.5371520, 55.2127930],
          [-2.5371150, 55.2127660],
          [-2.5370700, 55.2127390],
          [-2.5370250, 55.2127120],
          [-2.5369810, 55.2126860],
          [-2.5369480, 55.2126620],
          [-2.5369210, 55.2126360],
          [-2.5369020, 55.2126130],
          [-2.5368870, 55.2125860],
          [-2.5368720, 55.2125590],
          [-2.5368570, 55.2125330],
          [-2.5368450, 55.2125020],
          [-2.5368400, 55.2124760],
          [-2.5368380, 55.2124450],
          [-2.5368400, 55.2124190],
          [-2.5368420, 55.2123920],
          [-2.5368430, 55.2123580],
          [-2.5368420, 55.2123270],
          [-2.5368370, 55.2122930],
          [-2.5368320, 55.2122630],
          [-2.5368240, 55.2122280],
          [-2.5368060, 55.2122010],
          [-2.5367810, 55.2121700],
          [-2.5367510, 55.2121400],
          [-2.5367150, 55.2121130],
          [-2.5366760, 55.2120870],
          [-2.5366380, 55.2120600],
          [-2.5365980, 55.2120370],
          [-2.5365590, 55.2120140],
          [-2.5365220, 55.2119910],
          [-2.5364860, 55.2119680],
          [-2.5364520, 55.2119410],
          [-2.5364260, 55.2119150],
          [-2.5364060, 55.2118880],
          [-2.5363920, 55.2118570],
          [-2.5363850, 55.2118230],
          [-2.5363870, 55.2117860],
          [-2.5363970, 55.2117510],
          [-2.5364150, 55.2117160],
          [-2.5364330, 55.2116820],
          [-2.5364530, 55.2116440],
          [-2.5364680, 55.2116100],
          [-2.5364780, 55.2115750],
          [-2.5364830, 55.2115410],
          [-2.5364900, 55.2115030],
          [-2.5364950, 55.2114690],
          [-2.5364970, 55.2114310],
          [-2.5364950, 55.2113920],
          [-2.5364930, 55.2113550],
          [-2.5364930, 55.2113170],
          [-2.5364950, 55.2112820],
          [-2.5364980, 55.2112470],
          [-2.5365030, 55.2112090],
          [-2.5365100, 55.2111790],
          [-2.5365200, 55.2111440],
          [-2.5365280, 55.2111140],
          [-2.5365360, 55.2110870],
          [-2.5365460, 55.2110610],
          [-2.5365560, 55.2110340],
          [-2.5365680, 55.2110070],
          [-2.5365800, 55.2109800],
          [-2.5365950, 55.2109530],
          [-2.5366140, 55.2109310],
          [-2.5366390, 55.2109110],
          [-2.5366660, 55.2108880],
          [-2.5366940, 55.2108650],
          [-2.5367260, 55.2108470],
          [-2.5367660, 55.2108310],
          [-2.5368100, 55.2108200],
          [-2.5368570, 55.2108080],
          [-2.5368980, 55.2107970],
          [-2.5369310, 55.2107810],
          [-2.5369620, 55.2107700],
          [-2.5369980, 55.2107550],
          [-2.5370360, 55.2107430],
          [-2.5370710, 55.2107320],
          [-2.5371040, 55.2107200],
          [-2.5371350, 55.2107090],
          [-2.5371640, 55.2106940],
          [-2.5371910, 55.2106780],
          [-2.5372180, 55.2106630],
          [-2.5372410, 55.2106480],
          [-2.5372680, 55.2106290],
          [-2.5372910, 55.2106140],
          [-2.5373130, 55.2105980],
          [-2.5373310, 55.2105790],
          [-2.5373430, 55.2105600],
          [-2.5373530, 55.2105450],
          [-2.5373610, 55.2105260],
          [-2.5373650, 55.2105070],
          [-2.5373670, 55.2104880],
          [-2.5373670, 55.2104650],
          [-2.5373620, 55.2104460],
          [-2.5373500, 55.2104310],
          [-2.5373400, 55.2104110],
          [-2.5373270, 55.2103960],
          [-2.5373140, 55.2103810],
          [-2.5372970, 55.2103690],
          [-2.5372770, 55.2103540],
          [-2.5372590, 55.2103390],
          [-2.5372410, 55.2103240],
          [-2.5372190, 55.2103120],
          [-2.5372000, 55.2102970],
          [-2.5371820, 55.2102820],
          [-2.5371650, 55.2102660],
          [-2.5371440, 55.2102510],
          [-2.5371210, 55.2102360],
          [-2.5370970, 55.2102280],
          [-2.5370730, 55.2102170],
          [-2.5370500, 55.2102090],
          [-2.5370290, 55.2101980],
          [-2.5370070, 55.2101830],
          [-2.5369860, 55.2101710],
          [-2.5369590, 55.2101560],
          [-2.5369310, 55.2101410],
          [-2.5369060, 55.2101290],
          [-2.5368860, 55.2101140],
          [-2.5368650, 55.2100990],
          [-2.5368470, 55.2100870],
          [-2.5368310, 55.2100760],
          [-2.5368120, 55.2100640],
          [-2.5367940, 55.2100500],
          [-2.5367770, 55.2100300],
          [-2.5367590, 55.2100110],
          [-2.5367420, 55.2099960],
          [-2.5367220, 55.2099800],
          [-2.5366990, 55.2099690],
          [-2.5366790, 55.2099580],
          [-2.5366560, 55.2099420],
          [-2.5366270, 55.2099200],
          [-2.5366010, 55.2098930],
          [-2.5365710, 55.2098660],
          [-2.5365340, 55.2098360],
          [-2.5364940, 55.2098090],
          [-2.5364530, 55.2097710],
          [-2.5364130, 55.2097310],
          [-2.5363630, 55.2096910],
          [-2.5363130, 55.2096460],
          [-2.5362620, 55.2096000],
          [-2.5361940, 55.2095540],
          [-2.5361220, 55.2095080],
          [-2.5360500, 55.2094550],
          [-2.5359670, 55.2094010],
          [-2.5358840, 55.2093520],
          [-2.5357950, 55.2093060],
          [-2.5357010, 55.2092560],
          [-2.5355990, 55.2092030],
          [-2.5355190, 55.2091520],
          [-2.5354330, 55.2090810],
          [-2.5353710, 55.2090200],
          [-2.5352910, 55.2089400],
          [-2.5352120, 55.2088680],
          [-2.5351370, 55.2087940],
          [-2.5350520, 55.2087150],
          [-2.5349730, 55.2086350],
          [-2.5348960, 55.2085630],
          [-2.5348060, 55.2084690],
          [-2.5347250, 55.2083730],
          [-2.5346610, 55.2082820],
          [-2.5346050, 55.2081970],
          [-2.5345650, 55.2081040],
          [-2.5345360, 55.2080060],
          [-2.5345070, 55.2079110],
          [-2.5344890, 55.2078500],
          [-2.5344530, 55.2077300],
          [-2.5344320, 55.2076400],
          [-2.5344090, 55.2075580],
          [-2.5343960, 55.2074630],
          [-2.5343950, 55.2073670],
          [-2.5344060, 55.2072640],
          [-2.5344520, 55.2071680],
          [-2.5345240, 55.2070860],
          [-2.5346200, 55.2070130],
          [-2.5347230, 55.2069580],
          [-2.5348510, 55.2069210],
          [-2.5349800, 55.2068960],
          [-2.5351230, 55.2068830],
          [-2.5352460, 55.2068860],
          [-2.5353630, 55.2068930],
          [-2.5354510, 55.2069010],
          [-2.5354920, 55.2068910],
          [-2.5355030, 55.2068600],
          [-2.5354830, 55.2068160],
          [-2.5354410, 55.2067630],
          [-2.5353940, 55.2067100],
          [-2.5353290, 55.2066470],
          [-2.5352440, 55.2065790],
          [-2.5351640, 55.2065210],
          [-2.5350610, 55.2064420],
          [-2.5349910, 55.2063650],
          [-2.5349490, 55.2062980],
          [-2.5349290, 55.2062300],
          [-2.5349380, 55.2061630],
          [-2.5349740, 55.2061010],
          [-2.5350410, 55.2060560],
          [-2.5351360, 55.2060300],
          [-2.5352580, 55.2060060],
          [-2.5353760, 55.2059980],
          [-2.5354760, 55.2059970],
          [-2.5355830, 55.2060050],
          [-2.5356640, 55.2060220],
          [-2.5357640, 55.2060430],
          [-2.5358480, 55.2060690],
          [-2.5359330, 55.2060920],
          [-2.5360100, 55.2061180],
          [-2.5360900, 55.2061380],
          [-2.5361680, 55.2061610],
          [-2.5362500, 55.2061880],
          [-2.5363360, 55.2062100],
          [-2.5364300, 55.2062220],
          [-2.5365260, 55.2062370],
          [-2.5366180, 55.2062600],
          [-2.5366900, 55.2062940],
          [-2.5367410, 55.2063420],
          [-2.5367900, 55.2064020],
          [-2.5368320, 55.2064690],
          [-2.5368690, 55.2065350],
          [-2.5369100, 55.2066090],
          [-2.5369560, 55.2066750],
          [-2.5370100, 55.2067390],
          [-2.5370770, 55.2067970],
          [-2.5371540, 55.2068470],
          [-2.5372330, 55.2068970],
          [-2.5373150, 55.2069420],
          [-2.5373940, 55.2069860],
          [-2.5374800, 55.2070300],
          [-2.5375580, 55.2070740],
          [-2.5376430, 55.2071260],
          [-2.5377260, 55.2071670],
          [-2.5378090, 55.2072040],
          [-2.5378920, 55.2072420],
          [-2.5379820, 55.2072820],
          [-2.5380770, 55.2073170],
          [-2.5381740, 55.2073470],
          [-2.5382700, 55.2073820],
          [-2.5383670, 55.2074160],
          [-2.5384540, 55.2074450],
          [-2.5385500, 55.2074830],
          [-2.5386430, 55.2075210],
          [-2.5387360, 55.2075550],
          [-2.5388380, 55.2075940],
          [-2.5389470, 55.2076290],
          [-2.5390630, 55.2076680],
          [-2.5391730, 55.2077080],
          [-2.5392980, 55.2077620],
          [-2.5394300, 55.2078150],
          [-2.5395720, 55.2078750],
          [-2.5397090, 55.2079400],
          [-2.5398350, 55.2079980],
          [-2.5399490, 55.2080470],
          [-2.5400590, 55.2080900],
          [-2.5401610, 55.2081250],
          [-2.5402530, 55.2081520],
          [-2.5403450, 55.2081750],
          [-2.5404210, 55.2081930],
          [-2.5404940, 55.2082060],
          [-2.5405720, 55.2082140],
          [-2.5406460, 55.2082210],
          [-2.5407280, 55.2082250],
          [-2.5408100, 55.2082360],
          [-2.5408980, 55.2082400],
          [-2.5409870, 55.2082440],
          [-2.5410800, 55.2082480],
          [-2.5411820, 55.2082450],
          [-2.5412800, 55.2082280],
          [-2.5413880, 55.2081930],
          [-2.5414690, 55.2081470],
          [-2.5415320, 55.2081120],
          [-2.5415950, 55.2080780],
          [-2.5416570, 55.2080540],
          [-2.5417210, 55.2080430],
          [-2.5417950, 55.2080380],
          [-2.5418590, 55.2080420],
          [-2.5419250, 55.2080500],
          [-2.5419810, 55.2080640],
          [-2.5420430, 55.2080760],
          [-2.5421010, 55.2080910],
          [-2.5421590, 55.2081070],
          [-2.5422090, 55.2081210],
          [-2.5422620, 55.2081370],
          [-2.5423170, 55.2081520],
          [-2.5423800, 55.2081640],
          [-2.5424400, 55.2081680],
          [-2.5425080, 55.2081720],
          [-2.5425620, 55.2081720],
          [-2.5426240, 55.2081750],
          [-2.5427070, 55.2081760],
          [-2.5427920, 55.2081680],
          [-2.5428710, 55.2081620],
          [-2.5429860, 55.2081600],
          [-2.5431060, 55.2081600],
          [-2.5432220, 55.2081670],
          [-2.5433610, 55.2081720],
          [-2.5434970, 55.2081790],
          [-2.5436320, 55.2081980],
          [-2.5437670, 55.2082200],
          [-2.5439140, 55.2082480],
          [-2.5440430, 55.2082820],
          [-2.5441840, 55.2083270],
          [-2.5442970, 55.2083780],
          [-2.5444090, 55.2084330],
          [-2.5445080, 55.2084870],
          [-2.5446220, 55.2085410],
          [-2.5447240, 55.2085810],
          [-2.5448360, 55.2086250],
          [-2.5449310, 55.2086560],
          [-2.5450490, 55.2086940],
          [-2.5451570, 55.2087230],
          [-2.5452740, 55.2087510],
          [-2.5453760, 55.2087860],
          [-2.5454970, 55.2088230],
          [-2.5456020, 55.2088590],
          [-2.5457140, 55.2089000],
          [-2.5458080, 55.2089320],
          [-2.5459030, 55.2089760],
          [-2.5459780, 55.2090150],
          [-2.5460560, 55.2090630],
          [-2.5461170, 55.2090960],
          [-2.5461800, 55.2091320],
          [-2.5462290, 55.2091640],
          [-2.5462880, 55.2091940],
          [-2.5463440, 55.2092200],
          [-2.5463970, 55.2092420],
          [-2.5464590, 55.2092740],
          [-2.5465160, 55.2093040],
          [-2.5465710, 55.2093390],
          [-2.5466260, 55.2093870],
          [-2.5466710, 55.2094300],
          [-2.5467190, 55.2094720],
          [-2.5467660, 55.2095040],
          [-2.5468150, 55.2095370],
          [-2.5468670, 55.2095640],
          [-2.5469180, 55.2095850],
          [-2.5469810, 55.2096170],
          [-2.5470430, 55.2096440],
          [-2.5471000, 55.2096680],
          [-2.5471700, 55.2096970],
          [-2.5472370, 55.2097200],
          [-2.5472990, 55.2097430],
          [-2.5473850, 55.2097730],
          [-2.5474580, 55.2097980],
          [-2.5475430, 55.2098270],
          [-2.5476140, 55.2098520],
          [-2.5476960, 55.2098800],
          [-2.5477740, 55.2099020],
          [-2.5478630, 55.2099300],
          [-2.5479390, 55.2099580],
          [-2.5480280, 55.2099910],
          [-2.5481030, 55.2100160],
          [-2.5481860, 55.2100520],
          [-2.5482550, 55.2100800],
          [-2.5483370, 55.2101130],
          [-2.5484050, 55.2101380],
          [-2.5484780, 55.2101660],
          [-2.5485390, 55.2101890],
          [-2.5486150, 55.2102160],
          [-2.5486750, 55.2102370],
          [-2.5487470, 55.2102570],
          [-2.5488240, 55.2102730],
          [-2.5488980, 55.2102950],
          [-2.5489770, 55.2103190],
          [-2.5490530, 55.2103370],
          [-2.5491370, 55.2103570],
          [-2.5492090, 55.2103780],
          [-2.5492950, 55.2104030],
          [-2.5493580, 55.2104210],
          [-2.5494210, 55.2104410],
          [-2.5494720, 55.2104640],
          [-2.5495180, 55.2104820],
          [-2.5495700, 55.2105060],
          [-2.5496210, 55.2105290],
          [-2.5496700, 55.2105460],
          [-2.5497300, 55.2105710],
          [-2.5497860, 55.2105860],
          [-2.5498390, 55.2106040],
          [-2.5499020, 55.2106270],
          [-2.5499610, 55.2106470],
          [-2.5500140, 55.2106710],
          [-2.5500720, 55.2106960],
          [-2.5501230, 55.2107230],
          [-2.5501730, 55.2107430],
          [-2.5502370, 55.2107690],
          [-2.5502910, 55.2107950],
          [-2.5503430, 55.2108180],
          [-2.5503940, 55.2108380],
          [-2.5504370, 55.2108570],
          [-2.5504830, 55.2108750],
          [-2.5505370, 55.2108990],
          [-2.5505840, 55.2109180],
          [-2.5506270, 55.2109400],
          [-2.5506670, 55.2109630],
          [-2.5507070, 55.2109860],
          [-2.5507450, 55.2110080],
          [-2.5507890, 55.2110360],
          [-2.5508310, 55.2110660],
          [-2.5508660, 55.2110980],
          [-2.5509060, 55.2111270],
          [-2.5509410, 55.2111580],
          [-2.5509700, 55.2111930],
          [-2.5510060, 55.2112300],
          [-2.5510490, 55.2112650],
          [-2.5510820, 55.2112940],
          [-2.5511200, 55.2113360],
          [-2.5511550, 55.2113710],
          [-2.5511860, 55.2114020],
          [-2.5512310, 55.2114400],
          [-2.5512740, 55.2114820],
          [-2.5513210, 55.2115220],
          [-2.5513760, 55.2115650],
          [-2.5514260, 55.2116030],
          [-2.5514810, 55.2116420],
          [-2.5515380, 55.2116830],
          [-2.5515940, 55.2117190],
          [-2.5516510, 55.2117600],
          [-2.5517230, 55.2118170],
          [-2.5517790, 55.2118700],
          [-2.5518450, 55.2119320],
          [-2.5519290, 55.2119860],
          [-2.5520300, 55.2120380],
          [-2.5521230, 55.2120800],
          [-2.5522390, 55.2121190],
          [-2.5523570, 55.2121520],
          [-2.5524760, 55.2121840],
          [-2.5525800, 55.2122280],
          [-2.5526890, 55.2122830],
          [-2.5527470, 55.2123400],
          [-2.5527820, 55.2124000],
          [-2.5527920, 55.2124480],
          [-2.5527930, 55.2124960],
          [-2.5527960, 55.2125280],
          [-2.5528010, 55.2125680],
          [-2.5528030, 55.2126040],
          [-2.5528080, 55.2126370],
          [-2.5528100, 55.2126800],
          [-2.5528120, 55.2127180],
          [-2.5528090, 55.2127580],
          [-2.5528120, 55.2128020],
          [-2.5528330, 55.2128440],
          [-2.5528640, 55.2128750],
          [-2.5529240, 55.2129130],
          [-2.5530090, 55.2129510],
          [-2.5531100, 55.2129820],
          [-2.5532480, 55.2130160],
          [-2.5533760, 55.2130490],
          [-2.5535180, 55.2130810],
          [-2.5536460, 55.2131050],
          [-2.5537930, 55.2131340],
          [-2.5539220, 55.2131590],
          [-2.5540650, 55.2131840],
          [-2.5541910, 55.2131980],
          [-2.5543280, 55.2132140],
          [-2.5544500, 55.2132290],
          [-2.5545730, 55.2132600],
          [-2.5546640, 55.2132950],
          [-2.5547520, 55.2133360],
          [-2.5548220, 55.2133740],
          [-2.5548990, 55.2134120],
          [-2.5549730, 55.2134450],
          [-2.5550460, 55.2134770],
          [-2.5551270, 55.2135000],
          [-2.5552150, 55.2135160],
          [-2.5553090, 55.2135270],
          [-2.5554130, 55.2135390],
          [-2.5555200, 55.2135460],
          [-2.5556300, 55.2135540],
          [-2.5557380, 55.2135580],
          [-2.5558280, 55.2135550],
          [-2.5559050, 55.2135470],
          [-2.5559790, 55.2135430],
          [-2.5560570, 55.2135390],
          [-2.5561370, 55.2135430],
          [-2.5562130, 55.2135580],
          [-2.5562790, 55.2135760],
          [-2.5563430, 55.2135990],
          [-2.5564020, 55.2136250],
          [-2.5564550, 55.2136520],
          [-2.5565110, 55.2136750],
          [-2.5565630, 55.2137020],
          [-2.5566160, 55.2137250],
          [-2.5566690, 55.2137480],
          [-2.5567200, 55.2137710],
          [-2.5567680, 55.2137930],
          [-2.5568140, 55.2138100],
          [-2.5568590, 55.2138240],
          [-2.5569050, 55.2138470],
          [-2.5569530, 55.2138690],
          [-2.5570010, 55.2138890],
          [-2.5570500, 55.2139090],
          [-2.5571140, 55.2139310],
          [-2.5571760, 55.2139530],
          [-2.5572410, 55.2139730],
          [-2.5573030, 55.2139950],
          [-2.5573720, 55.2140190],
          [-2.5574360, 55.2140450],
          [-2.5575070, 55.2140680],
          [-2.5575820, 55.2140880],
          [-2.5576610, 55.2141100],
          [-2.5577480, 55.2141260],
          [-2.5578460, 55.2141520],
          [-2.5579570, 55.2141820],
          [-2.5580790, 55.2142090],
          [-2.5582030, 55.2142390],
          [-2.5583310, 55.2142700],
          [-2.5584670, 55.2143050],
          [-2.5585960, 55.2143420],
          [-2.5587290, 55.2143870],
          [-2.5588580, 55.2144290],
          [-2.5589880, 55.2144710],
          [-2.5591180, 55.2145170],
          [-2.5592460, 55.2145660],
          [-2.5593700, 55.2146160],
          [-2.5594780, 55.2146640],
          [-2.5595830, 55.2147110],
          [-2.5596740, 55.2147490],
          [-2.5597610, 55.2147800],
          [-2.5598460, 55.2148040],
          [-2.5599320, 55.2148280],
          [-2.5600150, 55.2148430],
          [-2.5601040, 55.2148620],
          [-2.5601860, 55.2148810],
          [-2.5602660, 55.2149040],
          [-2.5603390, 55.2149290],
          [-2.5604180, 55.2149570],
          [-2.5604990, 55.2149830],
          [-2.5605880, 55.2150130],
          [-2.5606810, 55.2150450],
          [-2.5607640, 55.2150710],
          [-2.5608510, 55.2150980],
          [-2.5609350, 55.2151270],
          [-2.5610170, 55.2151550],
          [-2.5610970, 55.2151820],
          [-2.5611750, 55.2152090],
          [-2.5612580, 55.2152350],
          [-2.5613410, 55.2152540],
          [-2.5614330, 55.2152740],
          [-2.5615250, 55.2153010],
          [-2.5616100, 55.2153270],
          [-2.5616970, 55.2153500],
          [-2.5617750, 55.2153800],
          [-2.5618490, 55.2154100],
          [-2.5619090, 55.2154470],
          [-2.5619440, 55.2154980],
          [-2.5619560, 55.2155470],
          [-2.5619660, 55.2155900],
          [-2.5619840, 55.2156310],
          [-2.5620120, 55.2156700],
          [-2.5620490, 55.2157070],
          [-2.5620940, 55.2157440],
          [-2.5621300, 55.2157860],
          [-2.5621550, 55.2158360],
          [-2.5621710, 55.2158900],
          [-2.5621730, 55.2159400],
          [-2.5621600, 55.2159870],
          [-2.5621440, 55.2160320],
          [-2.5621210, 55.2160880],
          [-2.5620910, 55.2161360],
          [-2.5620410, 55.2161790],
          [-2.5619820, 55.2162100],
          [-2.5619210, 55.2162380],
          [-2.5618670, 55.2162640],
          [-2.5618050, 55.2162840],
          [-2.5617370, 55.2163070],
          [-2.5616570, 55.2163290],
          [-2.5615640, 55.2163530],
          [-2.5614710, 55.2163780],
          [-2.5613670, 55.2164030],
          [-2.5612860, 55.2164350],
          [-2.5612220, 55.2164860],
          [-2.5611950, 55.2165340],
          [-2.5611840, 55.2165900],
          [-2.5612070, 55.2166250],
          [-2.5612570, 55.2166430],
          [-2.5613190, 55.2166480],
          [-2.5613790, 55.2166580],
          [-2.5614510, 55.2166740],
          [-2.5615180, 55.2166890],
          [-2.5615850, 55.2166930],
          [-2.5616590, 55.2166940],
          [-2.5617310, 55.2166900],
          [-2.5618020, 55.2166800],
          [-2.5618810, 55.2166680],
          [-2.5619670, 55.2166560],
          [-2.5620460, 55.2166490],
          [-2.5621460, 55.2166440],
          [-2.5622300, 55.2166440],
          [-2.5623180, 55.2166550],
          [-2.5623860, 55.2166690],
          [-2.5624670, 55.2166860],
          [-2.5625410, 55.2167010],
          [-2.5626150, 55.2167130],
          [-2.5626860, 55.2167200],
          [-2.5627590, 55.2167170],
          [-2.5628270, 55.2166980],
          [-2.5628860, 55.2166750],
          [-2.5629580, 55.2166600],
          [-2.5630390, 55.2166560],
          [-2.5631300, 55.2166590],
          [-2.5632250, 55.2166710],
          [-2.5633250, 55.2167010],
          [-2.5634340, 55.2167280],
          [-2.5635470, 55.2167540],
          [-2.5636550, 55.2167770],
          [-2.5637670, 55.2168040],
          [-2.5638650, 55.2168260],
          [-2.5639620, 55.2168500],
          [-2.5640510, 55.2168760],
          [-2.5641330, 55.2169070],
          [-2.5642100, 55.2169330],
          [-2.5642830, 55.2169600],
          [-2.5643530, 55.2169910],
          [-2.5644130, 55.2170250],
          [-2.5644730, 55.2170590],
          [-2.5645430, 55.2170940],
          [-2.5646280, 55.2171200],
          [-2.5647160, 55.2171430],
          [-2.5648080, 55.2171550],
          [-2.5649010, 55.2171590],
          [-2.5649870, 55.2171630],
          [-2.5650810, 55.2171670],
          [-2.5651720, 55.2171780],
          [-2.5652650, 55.2171930],
          [-2.5653500, 55.2172040],
          [-2.5654520, 55.2172120],
          [-2.5655450, 55.2172130],
          [-2.5656410, 55.2172050],
          [-2.5657360, 55.2171980],
          [-2.5658360, 55.2171900],
          [-2.5659340, 55.2171790],
          [-2.5660330, 55.2171750],
          [-2.5661260, 55.2171670],
          [-2.5662310, 55.2171630],
          [-2.5663250, 55.2171670],
          [-2.5664200, 55.2171710],
          [-2.5665040, 55.2171740],
          [-2.5665880, 55.2171820],
          [-2.5666620, 55.2171900],
          [-2.5667330, 55.2171970],
          [-2.5667970, 55.2172010],
          [-2.5668550, 55.2172080],
          [-2.5669170, 55.2172130],
          [-2.5669760, 55.2172200],
          [-2.5670310, 55.2172240],
          [-2.5670890, 55.2172320],
          [-2.5671450, 55.2172350],
          [-2.5672000, 55.2172390],
          [-2.5672540, 55.2172470],
          [-2.5673070, 55.2172510],
          [-2.5673520, 55.2172580],
          [-2.5673990, 55.2172620],
          [-2.5674450, 55.2172700],
          [-2.5674910, 55.2172810],
          [-2.5675380, 55.2172890],
          [-2.5675890, 55.2172930],
          [-2.5676370, 55.2172970],
          [-2.5676850, 55.2173040],
          [-2.5677320, 55.2173080],
          [-2.5677790, 55.2173120],
          [-2.5678270, 55.2173230],
          [-2.5678750, 55.2173310],
          [-2.5679170, 55.2173380],
          [-2.5679610, 55.2173460],
          [-2.5680080, 55.2173540],
          [-2.5680600, 55.2173610],
          [-2.5681120, 55.2173730],
          [-2.5681670, 55.2173800],
          [-2.5682200, 55.2173880],
          [-2.5682680, 55.2173990],
          [-2.5683140, 55.2174030],
          [-2.5683640, 55.2174150],
          [-2.5684120, 55.2174300],
          [-2.5684590, 55.2174410],
          [-2.5685050, 55.2174520],
          [-2.5685560, 55.2174680],
          [-2.5686000, 55.2174820],
          [-2.5686530, 55.2174950],
          [-2.5687060, 55.2175100],
          [-2.5687570, 55.2175210],
          [-2.5688090, 55.2175370],
          [-2.5688600, 55.2175520],
          [-2.5689140, 55.2175670],
          [-2.5689710, 55.2175820],
          [-2.5690290, 55.2175940],
          [-2.5690890, 55.2176090],
          [-2.5691460, 55.2176240],
          [-2.5692060, 55.2176400],
          [-2.5692620, 55.2176550],
          [-2.5693190, 55.2176660],
          [-2.5693790, 55.2176820],
          [-2.5694400, 55.2176970],
          [-2.5695050, 55.2177120],
          [-2.5695740, 55.2177270],
          [-2.5696400, 55.2177380],
          [-2.5697270, 55.2177540],
          [-2.5698070, 55.2177690],
          [-2.5698950, 55.2177850],
          [-2.5699830, 55.2178030],
          [-2.5700700, 55.2178260],
          [-2.5701470, 55.2178560],
          [-2.5702200, 55.2178910],
          [-2.5702860, 55.2179290],
          [-2.5703520, 55.2179670],
          [-2.5704150, 55.2180000],
          [-2.5704890, 55.2180360],
          [-2.5705600, 55.2180690],
          [-2.5706430, 55.2180970],
          [-2.5707260, 55.2181260],
          [-2.5708250, 55.2181620],
          [-2.5709130, 55.2181990],
          [-2.5710010, 55.2182410],
          [-2.5710810, 55.2182830],
          [-2.5711590, 55.2183300],
          [-2.5712270, 55.2183760],
          [-2.5713020, 55.2184280],
          [-2.5713700, 55.2184780],
          [-2.5714350, 55.2185310],
          [-2.5714940, 55.2185870],
          [-2.5715500, 55.2186460],
          [-2.5715940, 55.2187060],
          [-2.5716290, 55.2187640],
          [-2.5716590, 55.2188170],
          [-2.5716860, 55.2188740],
          [-2.5717110, 55.2189240],
          [-2.5717380, 55.2189810],
          [-2.5717610, 55.2190350],
          [-2.5717830, 55.2190920],
          [-2.5718060, 55.2191480],
          [-2.5718290, 55.2192070],
          [-2.5718480, 55.2192700],
          [-2.5718580, 55.2193320],
          [-2.5718620, 55.2193950],
          [-2.5718620, 55.2194610],
          [-2.5718600, 55.2195240],
          [-2.5718520, 55.2195920],
          [-2.5718290, 55.2196550],
          [-2.5717930, 55.2197130],
          [-2.5717540, 55.2197650],
          [-2.5717270, 55.2198170],
          [-2.5717220, 55.2198690],
          [-2.5717300, 55.2199160],
          [-2.5717440, 55.2199580],
          [-2.5717660, 55.2199930],
          [-2.5717920, 55.2200230],
          [-2.5718230, 55.2200500],
          [-2.5718530, 55.2200690],
          [-2.5718830, 55.2200920],
          [-2.5719110, 55.2201110],
          [-2.5719380, 55.2201300],
          [-2.5719650, 55.2201450],
          [-2.5719940, 55.2201610],
          [-2.5720240, 55.2201730],
          [-2.5720590, 55.2201880],
          [-2.5720900, 55.2202020],
          [-2.5721230, 55.2202150],
          [-2.5721510, 55.2202300],
          [-2.5721780, 55.2202410],
          [-2.5722040, 55.2202560],
          [-2.5722300, 55.2202710],
          [-2.5722560, 55.2202860],
          [-2.5722860, 55.2202980],
          [-2.5723170, 55.2203140],
          [-2.5723490, 55.2203290],
          [-2.5723790, 55.2203430],
          [-2.5724090, 55.2203590],
          [-2.5724360, 55.2203750],
          [-2.5724660, 55.2203860],
          [-2.5724980, 55.2204010],
          [-2.5725310, 55.2204200],
          [-2.5725630, 55.2204320],
          [-2.5725890, 55.2204430],
          [-2.5726140, 55.2204590],
          [-2.5726340, 55.2204740],
          [-2.5726560, 55.2204910],
          [-2.5726830, 55.2205050],
          [-2.5727090, 55.2205160],
          [-2.5727330, 55.2205310],
          [-2.5727550, 55.2205430],
          [-2.5727800, 55.2205540],
          [-2.5728060, 55.2205620],
          [-2.5728310, 55.2205730],
          [-2.5728580, 55.2205850],
          [-2.5728840, 55.2206000],
          [-2.5729070, 55.2206110],
          [-2.5729300, 55.2206190],
          [-2.5729510, 55.2206300],
          [-2.5729750, 55.2206420],
          [-2.5729990, 55.2206530],
          [-2.5730220, 55.2206650],
          [-2.5730390, 55.2206760],
          [-2.5730590, 55.2206910],
          [-2.5730830, 55.2207030],
          [-2.5731060, 55.2207180],
          [-2.5731240, 55.2207260],
          [-2.5731410, 55.2207330],
          [-2.5731550, 55.2207410],
          [-2.5731760, 55.2207520],
          [-2.5731950, 55.2207680],
          [-2.5732160, 55.2207830],
          [-2.5732300, 55.2207940],
          [-2.5732440, 55.2208060],
          [-2.5732680, 55.2208240],
          [-2.5732910, 55.2208400],
          [-2.5733090, 55.2208510],
          [-2.5733300, 55.2208660],
          [-2.5733500, 55.2208820],
          [-2.5733700, 55.2208930],
          [-2.5733960, 55.2209050],
          [-2.5734200, 55.2209200],
          [-2.5734410, 55.2209320],
          [-2.5734680, 55.2209470],
          [-2.5734900, 55.2209580],
          [-2.5735140, 55.2209660],
          [-2.5735390, 55.2209740],
          [-2.5735610, 55.2209810],
          [-2.5735810, 55.2209930],
          [-2.5736030, 55.2210040],
          [-2.5736260, 55.2210200],
          [-2.5736580, 55.2210420],
          [-2.5736790, 55.2210580],
          [-2.5737040, 55.2210730],
          [-2.5737280, 55.2210880],
          [-2.5737530, 55.2211070],
          [-2.5737770, 55.2211260],
          [-2.5737970, 55.2211490],
          [-2.5738120, 55.2211680],
          [-2.5738250, 55.2211860],
          [-2.5738430, 55.2212170],
          [-2.5738550, 55.2212440],
          [-2.5738630, 55.2212750],
          [-2.5738700, 55.2213080],
          [-2.5738780, 55.2213460],
          [-2.5738930, 55.2213850],
          [-2.5739200, 55.2214290],
          [-2.5739630, 55.2214800],
          [-2.5740100, 55.2215280],
          [-2.5740580, 55.2215820],
          [-2.5741100, 55.2216320],
          [-2.5741690, 55.2216820],
          [-2.5742280, 55.2217280],
          [-2.5742840, 55.2217740],
          [-2.5743320, 55.2218100],
          [-2.5743850, 55.2218540],
          [-2.5744270, 55.2218860],
          [-2.5744790, 55.2219230],
          [-2.5745270, 55.2219500],
          [-2.5745760, 55.2219710],
          [-2.5746420, 55.2219920],
          [-2.5747030, 55.2220110],
          [-2.5747630, 55.2220300],
          [-2.5748270, 55.2220450],
          [-2.5748950, 55.2220610],
          [-2.5749610, 55.2220720],
          [-2.5750270, 55.2220840],
          [-2.5750950, 55.2220920],
          [-2.5751620, 55.2220990],
          [-2.5752400, 55.2221070],
          [-2.5753120, 55.2221140],
          [-2.5753800, 55.2221220],
          [-2.5754540, 55.2221300],
          [-2.5755290, 55.2221340],
          [-2.5755940, 55.2221370],
          [-2.5756710, 55.2221410],
          [-2.5757430, 55.2221450],
          [-2.5758050, 55.2221480],
          [-2.5758800, 55.2221530],
          [-2.5759440, 55.2221600],
          [-2.5760030, 55.2221710],
          [-2.5760630, 55.2221790],
          [-2.5761150, 55.2221940],
          [-2.5761580, 55.2222090],
          [-2.5762020, 55.2222250],
          [-2.5762470, 55.2222400],
          [-2.5762870, 55.2222590],
          [-2.5763240, 55.2222740],
          [-2.5763610, 55.2222950],
          [-2.5763990, 55.2223160],
          [-2.5764360, 55.2223500],
          [-2.5764720, 55.2223850],
          [-2.5764940, 55.2224340],
          [-2.5765150, 55.2224910],
          [-2.5765540, 55.2225480],
          [-2.5766230, 55.2225980],
          [-2.5767200, 55.2226440],
          [-2.5768130, 55.2226970],
          [-2.5768940, 55.2227660],
          [-2.5769750, 55.2228340],
          [-2.5770560, 55.2229060],
          [-2.5771410, 55.2229710],
          [-2.5772420, 55.2230290],
          [-2.5773530, 55.2230670],
          [-2.5774810, 55.2230940],
          [-2.5776240, 55.2231170],
          [-2.5777780, 55.2231360],
          [-2.5779440, 55.2231590],
          [-2.5781040, 55.2232010],
          [-2.5782710, 55.2232540],
          [-2.5784180, 55.2233110],
          [-2.5785570, 55.2233760],
          [-2.5786830, 55.2234550],
          [-2.5787750, 55.2235400],
          [-2.5788450, 55.2236150],
          [-2.5789100, 55.2236880],
          [-2.5789720, 55.2237620],
          [-2.5790350, 55.2238330],
          [-2.5790950, 55.2239100],
          [-2.5791550, 55.2239780],
          [-2.5792100, 55.2240360],
          [-2.5792640, 55.2240930],
          [-2.5793150, 55.2241430],
          [-2.5793670, 55.2241960],
          [-2.5794170, 55.2242540],
          [-2.5794670, 55.2243110],
          [-2.5795240, 55.2243640],
          [-2.5795800, 55.2244210],
          [-2.5796390, 55.2244790],
          [-2.5796910, 55.2245310],
          [-2.5797450, 55.2245850],
          [-2.5797990, 55.2246390],
          [-2.5798500, 55.2246920],
          [-2.5799060, 55.2247490],
          [-2.5799580, 55.2247990],
          [-2.5800060, 55.2248510],
          [-2.5800560, 55.2249020],
          [-2.5801060, 55.2249550],
          [-2.5801560, 55.2250050],
          [-2.5802060, 55.2250550],
          [-2.5802590, 55.2251040],
          [-2.5803110, 55.2251540],
          [-2.5803590, 55.2252000],
          [-2.5804040, 55.2252450],
          [-2.5804460, 55.2252870],
          [-2.5804860, 55.2253250],
          [-2.5805240, 55.2253600],
          [-2.5805690, 55.2253980],
          [-2.5806120, 55.2254360],
          [-2.5806570, 55.2254750],
          [-2.5807020, 55.2255130],
          [-2.5807450, 55.2255540],
          [-2.5807860, 55.2255930],
          [-2.5808260, 55.2256310],
          [-2.5808670, 55.2256730],
          [-2.5809090, 55.2257110],
          [-2.5809500, 55.2257450],
          [-2.5809970, 55.2257790],
          [-2.5810400, 55.2258180],
          [-2.5810850, 55.2258550],
          [-2.5811270, 55.2258940],
          [-2.5811660, 55.2259320],
          [-2.5812000, 55.2259630],
          [-2.5812380, 55.2259970],
          [-2.5812780, 55.2260360],
          [-2.5813160, 55.2260770],
          [-2.5813520, 55.2261150],
          [-2.5813940, 55.2261500],
          [-2.5814370, 55.2261800],
          [-2.5814790, 55.2262180],
          [-2.5815160, 55.2262530],
          [-2.5815510, 55.2262910],
          [-2.5815930, 55.2263290],
          [-2.5816340, 55.2263750],
          [-2.5816690, 55.2264170],
          [-2.5817140, 55.2264590],
          [-2.5817580, 55.2264960],
          [-2.5818050, 55.2265340],
          [-2.5818480, 55.2265730],
          [-2.5818940, 55.2266190],
          [-2.5819370, 55.2266610],
          [-2.5819790, 55.2266990],
          [-2.5820190, 55.2267330],
          [-2.5820540, 55.2267640],
          [-2.5820870, 55.2267940],
          [-2.5821340, 55.2268290],
          [-2.5822050, 55.2268630],
          [-2.5822820, 55.2268790],
          [-2.5823570, 55.2268750],
          [-2.5824180, 55.2268640],
          [-2.5824750, 55.2268600],
          [-2.5825220, 55.2268600],
          [-2.5825660, 55.2268790],
          [-2.5826010, 55.2269160],
          [-2.5826160, 55.2269700],
          [-2.5826080, 55.2270190],
          [-2.5825920, 55.2270690],
          [-2.5825610, 55.2271220],
          [-2.5825190, 55.2271760],
          [-2.5824660, 55.2272360],
          [-2.5824080, 55.2272900],
          [-2.5823510, 55.2273470],
          [-2.5822930, 55.2274080],
          [-2.5822280, 55.2274690],
          [-2.5821580, 55.2275340],
          [-2.5820850, 55.2275980],
          [-2.5820100, 55.2276630],
          [-2.5819310, 55.2277200],
          [-2.5818420, 55.2277820],
          [-2.5817520, 55.2278420],
          [-2.5816540, 55.2279000],
          [-2.5815520, 55.2279570],
          [-2.5814430, 55.2280070],
          [-2.5813170, 55.2280530],
          [-2.5812020, 55.2281030],
          [-2.5810880, 55.2281520],
          [-2.5809790, 55.2281940],
          [-2.5808670, 55.2282440],
          [-2.5807560, 55.2282900],
          [-2.5806440, 55.2283350],
          [-2.5805440, 55.2283810],
          [-2.5804460, 55.2284270],
          [-2.5803490, 55.2284650],
          [-2.5802540, 55.2285030],
          [-2.5801560, 55.2285410],
          [-2.5800690, 55.2285790],
          [-2.5799840, 55.2286140],
          [-2.5799110, 55.2286550],
          [-2.5798430, 55.2286980],
          [-2.5797830, 55.2287470],
          [-2.5797330, 55.2287930],
          [-2.5796960, 55.2288460],
          [-2.5796850, 55.2289070],
          [-2.5796880, 55.2289680],
          [-2.5796960, 55.2290290],
          [-2.5797130, 55.2290940],
          [-2.5797330, 55.2291630],
          [-2.5797600, 55.2292280],
          [-2.5797960, 55.2292890],
          [-2.5798370, 55.2293530],
          [-2.5798940, 55.2294150],
          [-2.5799510, 55.2294720],
          [-2.5800080, 55.2295280],
          [-2.5800590, 55.2295820],
          [-2.5801100, 55.2296360],
          [-2.5801650, 55.2296890],
          [-2.5802190, 55.2297430],
          [-2.5802750, 55.2297950],
          [-2.5803290, 55.2298550],
          [-2.5803850, 55.2299110],
          [-2.5804390, 55.2299640],
          [-2.5804910, 55.2300180],
          [-2.5805410, 55.2300700],
          [-2.5805970, 55.2301240],
          [-2.5806490, 55.2301780],
          [-2.5806990, 55.2302270],
          [-2.5807470, 55.2302800],
          [-2.5807920, 55.2303260],
          [-2.5808390, 55.2303760],
          [-2.5808850, 55.2304250],
          [-2.5809360, 55.2304720],
          [-2.5809900, 55.2305170],
          [-2.5810490, 55.2305590],
          [-2.5811170, 55.2305980],
          [-2.5811830, 55.2306350],
          [-2.5812510, 55.2306770],
          [-2.5813210, 55.2307120],
          [-2.5813920, 55.2307460],
          [-2.5814590, 55.2307810],
          [-2.5815220, 55.2308190],
          [-2.5815830, 55.2308560],
          [-2.5816500, 55.2308870],
          [-2.5817200, 55.2309180],
          [-2.5817900, 55.2309490],
          [-2.5818580, 55.2309790],
          [-2.5819260, 55.2310090],
          [-2.5819920, 55.2310440],
          [-2.5820510, 55.2310710],
          [-2.5821090, 55.2311010],
          [-2.5821610, 55.2311280],
          [-2.5822140, 55.2311590],
          [-2.5822690, 55.2311890],
          [-2.5823280, 55.2312160],
          [-2.5823850, 55.2312420],
          [-2.5824390, 55.2312690],
          [-2.5824850, 55.2312960],
          [-2.5825180, 55.2313300],
          [-2.5825460, 55.2313650],
          [-2.5825730, 55.2314030],
          [-2.5825930, 55.2314410],
          [-2.5826050, 55.2314790],
          [-2.5826160, 55.2315200],
          [-2.5826310, 55.2315590],
          [-2.5826330, 55.2315930],
          [-2.5826320, 55.2316280],
          [-2.5826280, 55.2316660],
          [-2.5826220, 55.2317000],
          [-2.5826150, 55.2317380],
          [-2.5826080, 55.2317720],
          [-2.5826000, 55.2318060],
          [-2.5825900, 55.2318410],
          [-2.5825790, 55.2318760],
          [-2.5825580, 55.2319090],
          [-2.5825310, 55.2319440],
          [-2.5825040, 55.2319750],
          [-2.5824810, 55.2320090],
          [-2.5824490, 55.2320360],
          [-2.5824160, 55.2320660],
          [-2.5823780, 55.2320970],
          [-2.5823360, 55.2321270],
          [-2.5822940, 55.2321510],
          [-2.5822530, 55.2321810],
          [-2.5822100, 55.2322040],
          [-2.5821710, 55.2322310],
          [-2.5821310, 55.2322570],
          [-2.5820910, 55.2322840],
          [-2.5820530, 55.2323110],
          [-2.5820160, 55.2323370],
          [-2.5819770, 55.2323640],
          [-2.5819410, 55.2323910],
          [-2.5819000, 55.2324140],
          [-2.5818580, 55.2324370],
          [-2.5818170, 55.2324620],
          [-2.5817690, 55.2324820],
          [-2.5817260, 55.2325020],
          [-2.5816830, 55.2325170],
          [-2.5816420, 55.2325350],
          [-2.5816050, 55.2325510],
          [-2.5815610, 55.2325660],
          [-2.5815230, 55.2325890],
          [-2.5815060, 55.2326080],
          [-2.5815070, 55.2326270],
          [-2.5815180, 55.2326420],
          [-2.5815450, 55.2326620],
          [-2.5815790, 55.2326810],
          [-2.5816210, 55.2327040],
          [-2.5816690, 55.2327270],
          [-2.5817190, 55.2327530],
          [-2.5817720, 55.2327800],
          [-2.5818230, 55.2328020],
          [-2.5818720, 55.2328290],
          [-2.5819220, 55.2328560],
          [-2.5819730, 55.2328790],
          [-2.5820250, 55.2329060],
          [-2.5820790, 55.2329320],
          [-2.5821300, 55.2329590],
          [-2.5821800, 55.2329890],
          [-2.5822180, 55.2330240],
          [-2.5822330, 55.2330580],
          [-2.5822300, 55.2331000],
          [-2.5822140, 55.2331410],
          [-2.5821800, 55.2331790],
          [-2.5821330, 55.2332140],
          [-2.5820760, 55.2332410],
          [-2.5820090, 55.2332640],
          [-2.5819360, 55.2332840],
          [-2.5818620, 55.2333060],
          [-2.5817900, 55.2333290],
          [-2.5817170, 55.2333560],
          [-2.5816500, 55.2333820],
          [-2.5815870, 55.2334090],
          [-2.5815240, 55.2334360],
          [-2.5814650, 55.2334630],
          [-2.5814150, 55.2334930],
          [-2.5813760, 55.2335240],
          [-2.5813460, 55.2335580],
          [-2.5813270, 55.2335920],
          [-2.5813140, 55.2336230],
          [-2.5813090, 55.2336460],
          [-2.5813070, 55.2336730],
          [-2.5813080, 55.2336990],
          [-2.5813150, 55.2337260],
          [-2.5813200, 55.2337520],
          [-2.5813230, 55.2337750],
          [-2.5813250, 55.2338060],
          [-2.5813300, 55.2338330],
          [-2.5813400, 55.2338590],
          [-2.5813480, 55.2338860],
          [-2.5813550, 55.2339090],
          [-2.5813630, 55.2339360],
          [-2.5813700, 55.2339590],
          [-2.5813800, 55.2339820],
          [-2.5813900, 55.2340040],
          [-2.5814000, 55.2340310],
          [-2.5814080, 55.2340580],
          [-2.5814080, 55.2340840],
          [-2.5814080, 55.2341070],
          [-2.5814120, 55.2341340],
          [-2.5814130, 55.2341600],
          [-2.5814120, 55.2341880],
          [-2.5814050, 55.2342140],
          [-2.5813970, 55.2342410],
          [-2.5813800, 55.2342710],
          [-2.5813620, 55.2343020],
          [-2.5813490, 55.2343360],
          [-2.5813340, 55.2343670],
          [-2.5813170, 55.2343940],
          [-2.5813040, 55.2344230],
          [-2.5812840, 55.2344500],
          [-2.5812620, 55.2344740],
          [-2.5812330, 55.2344920],
          [-2.5811960, 55.2345080],
          [-2.5811560, 55.2345200],
          [-2.5811160, 55.2345240],
          [-2.5810810, 55.2345200],
          [-2.5810520, 55.2345090],
          [-2.5810270, 55.2344940],
          [-2.5810010, 55.2344820],
          [-2.5809690, 55.2344780],
          [-2.5809320, 55.2344700],
          [-2.5808970, 55.2344630],
          [-2.5808660, 55.2344590],
          [-2.5808340, 55.2344590],
          [-2.5808090, 55.2344590],
          [-2.5807950, 55.2344590],
          [-2.5807930, 55.2344590],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807920, 55.2344630],
          [-2.5807700, 55.2344140],
          [-2.5807800, 55.2344060],
          [-2.5807850, 55.2343940],
          [-2.5807870, 55.2343790],
          [-2.5807880, 55.2343640],
          [-2.5807920, 55.2343490],
          [-2.5807930, 55.2343370],
          [-2.5807900, 55.2343260],
          [-2.5807830, 55.2343140],
          [-2.5807780, 55.2343100],
          [-2.5807700, 55.2343030],
          [-2.5807700, 55.2343030],
          [-2.5807590, 55.2342910],
          [-2.5807520, 55.2342800],
          [-2.5807450, 55.2342720],
          [-2.5807400, 55.2342720],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680],
          [-2.5807320, 55.2342680]
        ]
    }
};