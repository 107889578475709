export const bradleyFell = {
    'type': 'Feature',
    'properties': {
        'color': '#C70039',
        'description': 'Dusty Gravel 😎</br> Bradley Fell'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.8248100, 54.9489500],
          [-1.8248500, 54.9488400],
          [-1.8257600, 54.9485100],
          [-1.8263100, 54.9482200],
          [-1.8268300, 54.9477000],
          [-1.8280000, 54.9471800],
          [-1.8291200, 54.9467500],
          [-1.8297000, 54.9463200],
          [-1.8298000, 54.9460000],
          [-1.8302700, 54.9458300],
          [-1.8302700, 54.9458400],
          [-1.8314167, 54.9456100],
          [-1.8325633, 54.9453800],
          [-1.8337100, 54.9451500],
          [-1.8350533, 54.9451133],
          [-1.8363967, 54.9450767],
          [-1.8377400, 54.9450400],
        ]
    }
};