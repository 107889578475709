export const consettSunderlandRailwayPath = {
    'type': 'Feature',
    'properties': {
        'color': '#CB3709',
        'description': 'Railway Path 👌 </br> Consett Sunderland Railway Path'
    },
    'geometry':{
        //Derwent Walk (Waggonway)
        'type': 'LineString',
        'coordinates': [
          [-1.5084600, 54.8906400],
          [-1.5092300, 54.8902000],
          [-1.5101100, 54.8897500],
          [-1.5108600, 54.8894600],
          [-1.5116100, 54.8891700],
          [-1.5117700, 54.8891500],
          [-1.5120400, 54.8891400],
          [-1.5122400, 54.8890800],
          [-1.5124300, 54.8890000],
          [-1.5125800, 54.8888800],
          [-1.5128900, 54.8886900],
          [-1.5136700, 54.8884250],
          [-1.5144500, 54.8881600],
          [-1.5153100, 54.8879600],
          [-1.5161700, 54.8877600],
          [-1.5173467, 54.8875100],
          [-1.5185233, 54.8872600],
          [-1.5197000, 54.8870100],
          [-1.5198300, 54.8870100],
          [-1.5198400, 54.8869600],
          [-1.5200400, 54.8868900],
          [-1.5212740, 54.8866220],
          [-1.5225080, 54.8863540],
          [-1.5237420, 54.8860860],
          [-1.5249760, 54.8858180],
          [-1.5262100, 54.8855500],
          [-1.5269800, 54.8853650],
          [-1.5277500, 54.8851800],
          [-1.5291600, 54.8849200],
          [-1.5303200, 54.8846100],
          [-1.5314400, 54.8844000],
          [-1.5325600, 54.8841900],
          [-1.5333850, 54.8840850],
          [-1.5342100, 54.8839800],
          [-1.5357350, 54.8838634],
          [-1.5372600, 54.8837467],
          [-1.5387850, 54.8836301],
          [-1.5403100, 54.8835134],
          [-1.5418350, 54.8833967],
          [-1.5433600, 54.8832800],
          [-1.5446500, 54.8832300],
          [-1.5457150, 54.8831000],
          [-1.5467800, 54.8829700],
          [-1.5483250, 54.8828650],
          [-1.5498700, 54.8827600],
          [-1.5507650, 54.8826650],
          [-1.5516600, 54.8825700],
          [-1.5524550, 54.8825250],
          [-1.5532500, 54.8824800],
          [-1.5543050, 54.8823600],
          [-1.5553600, 54.8822400],
          [-1.5562400, 54.8822400],
          [-1.5571200, 54.8822400],
          [-1.5574200, 54.8821800],
          [-1.5585150, 54.8818750],
          [-1.5596100, 54.8815700],
          [-1.5603600, 54.8813400],
          [-1.5613900, 54.8809200],
          [-1.5621300, 54.8806800],
          [-1.5634350, 54.8801950],
          [-1.5647400, 54.8797100],
          [-1.5660450, 54.8792625],
          [-1.5673501, 54.8788150],
          [-1.5686550, 54.8783675],
          [-1.5699600, 54.8779200],
          [-1.5705800, 54.8776600],
          [-1.5716667, 54.8773067],
          [-1.5727534, 54.8769533],
          [-1.5738400, 54.8766000],
          [-1.5748350, 54.8762650],
          [-1.5758300, 54.8759300],
          [-1.5762400, 54.8758400],
          [-1.5764800, 54.8757400],
          [-1.5766400, 54.8756100],
          [-1.5767900, 54.8754300],
          [-1.5770700, 54.8749900],
          [-1.5773500, 54.8745500],
          [-1.5777000, 54.8741700],
          [-1.5778700, 54.8738000],
          [-1.5781300, 54.8734900],
          [-1.5781300, 54.8731800],
          [-1.5782200, 54.8729800],
          [-1.5787200, 54.8728200],
          [-1.5787500, 54.8727800],
          [-1.5787200, 54.8726300],
          [-1.5789500, 54.8721600],
          [-1.5792800, 54.8717700],
          [-1.5799850, 54.8711200],
          [-1.5806900, 54.8704700],
          [-1.5813950, 54.8698200],
          [-1.5821000, 54.8691700],
          [-1.5828800, 54.8686500],
          [-1.5830100, 54.8683900],
          [-1.5831500, 54.8682200],
          [-1.5831600, 54.8681200],
          [-1.5836200, 54.8677800],
          [-1.5845200, 54.8672200],
          [-1.5854200, 54.8666600],
          [-1.5860800, 54.8662900],
          [-1.5867400, 54.8659200],
          [-1.5870500, 54.8657800],
          [-1.5872700, 54.8657800],
          [-1.5878600, 54.8656600],
          [-1.5883700, 54.8655100],
          [-1.5892150, 54.8653050],
          [-1.5900600, 54.8651000],
          [-1.5911100, 54.8649400],
          [-1.5925600, 54.8648688],
          [-1.5940100, 54.8647976],
          [-1.5954600, 54.8647264],
          [-1.5969100, 54.8646551],
          [-1.5983600, 54.8645839],
          [-1.5998100, 54.8645126],
          [-1.6012600, 54.8644413],
          [-1.6027100, 54.8643700],
          [-1.6039150, 54.8643050],
          [-1.6051200, 54.8642400],
          [-1.6062000, 54.8642300],
          [-1.6065900, 54.8642400],
          [-1.6072600, 54.8643100],
          [-1.6076600, 54.8644100],
          [-1.6086700, 54.8646800],
          [-1.6092800, 54.8649800],
          [-1.6098600, 54.8653700],
          [-1.6103800, 54.8659800],
          [-1.6108400, 54.8667020],
          [-1.6113000, 54.8674240],
          [-1.6117600, 54.8681460],
          [-1.6122200, 54.8688680],
          [-1.6126800, 54.8695900],
          [-1.6132900, 54.8703900],
          [-1.6140300, 54.8710000],
          [-1.6147800, 54.8715100],
          [-1.6154350, 54.8718400],
          [-1.6160900, 54.8721700],
          [-1.6167700, 54.8724350],
          [-1.6174500, 54.8727000],
          [-1.6186400, 54.8730700],
          [-1.6197900, 54.8733800],
          [-1.6211599, 54.8736251],
          [-1.6225299, 54.8738701],
          [-1.6238999, 54.8741151],
          [-1.6252699, 54.8743601],
          [-1.6266399, 54.8746051],
          [-1.6280099, 54.8748501],
          [-1.6293799, 54.8750951],
          [-1.6307500, 54.8753400],
          [-1.6320425, 54.8755650],
          [-1.6333350, 54.8757900],
          [-1.6346275, 54.8760150],
          [-1.6359200, 54.8762400],
          [-1.6368700, 54.8763500],
          [-1.6381000, 54.8764050],
          [-1.6393300, 54.8764600],
          [-1.6405600, 54.8765150],
          [-1.6417900, 54.8765700],
          [-1.6429550, 54.8766050],
          [-1.6441200, 54.8766400],
          [-1.6452167, 54.8766900],
          [-1.6463133, 54.8767400],
          [-1.6474100, 54.8767900],
          [-1.6486600, 54.8768200],
          [-1.6499100, 54.8768500],
          [-1.6511600, 54.8768800],
          [-1.6524100, 54.8769100],
          [-1.6536600, 54.8769400],
          [-1.6544400, 54.8769400],
          [-1.6557900, 54.8768900],
          [-1.6572600, 54.8766800],
          [-1.6577500, 54.8766400],
          [-1.6585400, 54.8765100],
          [-1.6595900, 54.8764300],
          [-1.6611100, 54.8763700],
          [-1.6625600, 54.8764300],
          [-1.6629700, 54.8764700],
          [-1.6635900, 54.8765800],
          [-1.6635500, 54.8765800],
          [-1.6647700, 54.8768100],
          [-1.6659900, 54.8770400],
          [-1.6672100, 54.8772700],
          [-1.6684300, 54.8775000],
          [-1.6696500, 54.8777300],
          [-1.6710733, 54.8780234],
          [-1.6724966, 54.8783167],
          [-1.6739199, 54.8786101],
          [-1.6753433, 54.8789034],
          [-1.6767666, 54.8791967],
          [-1.6781900, 54.8794900],
          [-1.6792250, 54.8796100],
          [-1.6802600, 54.8797300],
          [-1.6812050, 54.8796700],
          [-1.6821500, 54.8796100],
          [-1.6827600, 54.8795300],
          [-1.6841800, 54.8793075],
          [-1.6856000, 54.8790850],
          [-1.6870200, 54.8788625],
          [-1.6884400, 54.8786400],
          [-1.6893100, 54.8785300],
          [-1.6896300, 54.8784400],
          [-1.6907500, 54.8783200],
          [-1.6915200, 54.8781950],
          [-1.6922900, 54.8780700],
          [-1.6931700, 54.8778950],
          [-1.6940500, 54.8777200],
          [-1.6943800, 54.8776800],
          [-1.6955700, 54.8774900],
          [-1.6965400, 54.8773150],
          [-1.6975100, 54.8771400],
          [-1.6983600, 54.8769300],
          [-1.6994800, 54.8765500],
          [-1.7000500, 54.8762000],
          [-1.7006300, 54.8758000],
          [-1.7009800, 54.8754800],
          [-1.7014600, 54.8749750],
          [-1.7019400, 54.8744700],
          [-1.7026700, 54.8737700],
          [-1.7032600, 54.8731700],
          [-1.7038500, 54.8725700],
          [-1.7044700, 54.8719533],
          [-1.7050900, 54.8713367],
          [-1.7057100, 54.8707200],
          [-1.7063500, 54.8702700],
          [-1.7069000, 54.8700100],
          [-1.7076500, 54.8697000],
          [-1.7086300, 54.8694500],
          [-1.7094450, 54.8693050],
          [-1.7102600, 54.8691600],
          [-1.7110700, 54.8690700],
          [-1.7118650, 54.8689200],
          [-1.7126600, 54.8687700],
          [-1.7138800, 54.8684600],
          [-1.7147600, 54.8681800],
          [-1.7156400, 54.8679000],
          [-1.7165333, 54.8675800],
          [-1.7174267, 54.8672600],
          [-1.7183200, 54.8669400],
          [-1.7192433, 54.8666267],
          [-1.7201667, 54.8663133],
          [-1.7210900, 54.8660000],
          [-1.7221700, 54.8656600],
          [-1.7232500, 54.8652750],
          [-1.7243300, 54.8648900],
          [-1.7254100, 54.8645050],
          [-1.7264900, 54.8641200],
          [-1.7275867, 54.8637600],
          [-1.7286834, 54.8634000],
          [-1.7297800, 54.8630400],
          [-1.7306200, 54.8626100],
          [-1.7313300, 54.8622800],
          [-1.7318100, 54.8619900],
          [-1.7323800, 54.8616900],
          [-1.7330750, 54.8611800],
          [-1.7337700, 54.8606700],
          [-1.7338100, 54.8605400],
          [-1.7340900, 54.8599600],
          [-1.7342500, 54.8591100],
          [-1.7341800, 54.8588500],
          [-1.7346600, 54.8580200],
          [-1.7348300, 54.8579300],
          [-1.7354100, 54.8573200]
        ]
    }
}