export const eastBeamish = {
    'type': 'Feature',
    'properties': {
        'color': '#0D1F22',
        'description': 'Bridleway 😎</br> East Beamish'
    },
    'geometry':{
        'type': 'LineString',
        'coordinates': [
          [-1.6473400, 54.8819000],
          [-1.6472800, 54.8817800],
          [-1.6472400, 54.8815200],
          [-1.6473800, 54.8812100],
          [-1.6475200, 54.8810700],
          [-1.6477900, 54.8810100],
          [-1.6481200, 54.8809600],
          [-1.6493400, 54.8809400],
          [-1.6495100, 54.8809100],
          [-1.6497800, 54.8808000],
          [-1.6500900, 54.8807300],
          [-1.6503400, 54.8806400],
          [-1.6505400, 54.8805200],
          [-1.6507000, 54.8802500],
          [-1.6507300, 54.8800700],
          [-1.6507000, 54.8799100],
          [-1.6505300, 54.8797800],
          [-1.6502300, 54.8796800],
          [-1.6499900, 54.8796500],
          [-1.6491000, 54.8796200],
          [-1.6478200, 54.8793200],
          [-1.6465100, 54.8791100],
          [-1.6455300, 54.8790300],
          [-1.6455400, 54.8790300],
          [-1.6447400, 54.8789800],
          [-1.6434800, 54.8789800],
          [-1.6428400, 54.8790800],
          [-1.6421400, 54.8793000],
          [-1.6411850, 54.8795500],
          [-1.6402300, 54.8798000],
          [-1.6400000, 54.8799000],
          [-1.6398500, 54.8800500],
          [-1.6397500, 54.8802000],
          [-1.6396200, 54.8805100],
          [-1.6394100, 54.8806600],
          [-1.6392100, 54.8807200],
          [-1.6386900, 54.8807900],
          [-1.6381300, 54.8808900],
          [-1.6377300, 54.8809900],
          [-1.6374800, 54.8811400],
          [-1.6373500, 54.8812700],
          [-1.6373100, 54.8813800],
          [-1.6373100, 54.8815000],
          [-1.6374100, 54.8815900],
          [-1.6374600, 54.8817000],
          [-1.6374600, 54.8817800],
          [-1.6373500, 54.8819100],
          [-1.6371100, 54.8820600],
          [-1.6366800, 54.8822600],
          [-1.6364600, 54.8824100],
          [-1.6362300, 54.8826000],
        ]
    }
};